<template>
  <a
    href="#"
    @click.prevent="onClick"
  >
    <i class="fa fa-sign-out-alt" />
    <span>Wyloguj</span>
  </a>
</template>

<script>
export default {
  methods: {
    onClick() {
      this.$bvModal.show("logout-modal");
    },
  }
}
</script>
