var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('request-list-filters',{attrs:{"status":_vm.filters.status,"creator":_vm.filters.creator,"request-type":_vm.filters.requestType},on:{"input":_vm.changeFilters}}),_c('card',{attrs:{"title":"","loading":_vm.loading},scopedSlots:_vm._u([{key:"control",fn:function(){return [_c('b-btn',{attrs:{"size":"sm","variant":"primary"},on:{"click":_vm.fetchData}},[_c('i',{staticClass:"fa fa-sync-alt"}),_vm._v(" Odśwież listę wniosków ")])]},proxy:true}])},[_c('error-message',{attrs:{"errors":_vm.errors}}),_c('b-table',{attrs:{"empty-text":"Brak wniosków","fields":_vm.fields,"hover":true,"items":_vm.items,"responsive":true,"show-empty":""},scopedSlots:_vm._u([{key:"cell(command)",fn:function(ref){
var command = ref.item.command;
return [_c('i18n',{attrs:{"msgid":("@request-type." + command)}})]}},{key:"cell(status)",fn:function(ref){
var status = ref.item.status;
return [_c('b-badge',{attrs:{"variant":_vm.getStatusVariant(status)}},[_c('i18n',{attrs:{"msgid":("@request-status." + status)}})],1)]}},{key:"cell(options)",fn:function(ref){
var ref_item = ref.item;
var changeRequestId = ref_item.changeRequestId;
var command = ref_item.command;
var subjectId = ref_item.subjectId;
return [_c('b-dd',{staticClass:"text-left",staticStyle:{"width":"6rem"},attrs:{"boundary":"$el","right":"","size":"sm","text":"Opcje","variant":"outline-primary"}},[_c('b-dd-item-btn',{on:{"click":function($event){return _vm.$bvModal.show(("preview-request-" + changeRequestId))}}},[_c('span',{staticClass:"fas fa-eye"}),_vm._v(" Podgląd ")]),_c('preview-request-modal',{attrs:{"change-request-id":changeRequestId,"command":command,"subject-id":subjectId}}),_c('is-granted',{attrs:{"privileges":['ACCEPT_CHANGE_REQUEST'],"subject":changeRequestId}},[_c('b-dd-item-btn',{on:{"click":function($event){return _vm.$bvModal.show(("accept-request-" + changeRequestId))}}},[_c('span',{staticClass:"fas fa-check"}),_vm._v(" Akceptuj ")]),_c('accept-request-modal',{attrs:{"change-request-id":changeRequestId,"command":command,"subject-id":subjectId},on:{"accepted":_vm.fetchData}})],1),_c('is-granted',{attrs:{"privileges":['CANCEL_CHANGE_REQUEST'],"subject":changeRequestId}},[_c('b-dd-item-btn',{on:{"click":function($event){return _vm.$bvModal.show(("cancel-request-" + changeRequestId))}}},[_c('span',{staticClass:"fas fa-ban"}),_vm._v(" Odrzuć ")]),_c('cancel-request-modal',{attrs:{"change-request-id":changeRequestId,"command":command,"subject-id":subjectId},on:{"canceled":_vm.fetchData}})],1)],1)]}}])}),_c('pagination-description',{attrs:{"items-count":_vm.items.length,"page":_vm.pagination.currentPage,"per-page":_vm.pagination.perPage,"total-count":_vm.pagination.totalRows}},[_c('b-pagination',{staticClass:"mb-0",attrs:{"total-rows":_vm.pagination.totalRows,"per-page":_vm.pagination.perPage,"align":"right"},on:{"input":_vm.changePage},model:{value:(_vm.pagination.currentPage),callback:function ($$v) {_vm.$set(_vm.pagination, "currentPage", $$v)},expression:"pagination.currentPage"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }