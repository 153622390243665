import { render, staticRenderFns } from "./SurveyResults.vue?vue&type=template&id=34f5e72f&scoped=true&"
import script from "./SurveyResults.vue?vue&type=script&lang=ts&"
export * from "./SurveyResults.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34f5e72f",
  null
  
)

export default component.exports