<template>
  <object-select
    :value="selectedValues"
    :options="options"
    :state="state"
    :placeholder="placeholder"
    multiple
    @input="update"
  />
</template>

<script>
import ObjectSelect from "./ObjectSelect";
import t from "../../../i18n";

export default {
  components: {
    ObjectSelect,
  },
  props: {
    value: {type: Array, required: true},
    state: ObjectSelect.props.state, // eslint-disable-line
    placeholder: {type: String, default: "Wybierz zawód dodatkowy"},
  },
  data() {
    return {
      optionValues: [
        {value: "dietitian"},
        {value: "pediatrician"}
      ],
    };
  },
  computed: {
    options() {
      return this.optionValues.map(option => ({
        ...option,
        name: t(option.value),
      }));
    },
    selectedValues() {
      return this.value.map(item => this.options.find(option => option.value === item));
    },
  },
  methods: {
    update(selectedOptions) {
      const selectedValues = selectedOptions.map(option => option.value);
      this.$emit("input", selectedValues);
    },
  },
}
</script>
