






















































































import Component from "vue-class-component";
import {Mixins, Prop} from "vue-property-decorator";
import {generateUuid} from "../../../utils/uuid/generateUuid";
import create from "../../../rest/create";
import stringifyDate from "../../../utils/date/stringifyDate";
import I18n from "../../i18n.vue";
import t from "../../../i18n";
import processResponseException from "../../../utils/errors/processResponseException";
import {mapState} from "vuex";

import bFormTextarea from "bootstrap-vue";
import bFormInput from "bootstrap-vue";
import bFormRadioGroup from "bootstrap-vue";
import {BasicEntryItem, default as getEntry} from "./EntryField";
import ErrorMessage from "../../Form/ErrorMessage.vue";
import ErrorsMixin from "../../../mixins/ErrorsMixin";

const inputTypeNumberKeys = [
  "PED_PH_HEIGHT",
  "PED_PH_BODY_WEIGHT",
];

const numberRegex = /^\d+$/;

@Component({
  components: {
    ErrorMessage,
    I18n,
  },
  computed: mapState({
    workerId: (state: any): string => state.currentUser.user.workerId,
    workerName: (state: any): string => state.currentUser.user.name,
    surname: (state: any): string => state.currentUser.user.surname,
  }),
})
export default class RadioField extends Mixins(ErrorsMixin) {
  @Prop({type: String, required: true}) readonly diagnosisId!: string;
  @Prop({type: Object, required: true}) readonly field!: RadioFieldData;
  @Prop({type: Boolean, required: true}) readonly readOnly!: boolean;

  entries: Array<EntryItem> = this.field.entries || [];
  additional: Record<string, any> = {};// unknown dynamic interface
  additionalSelected: any = null;// unknown dynamic interface
  saveLoading: boolean = false;
  selected: string | null = null;
  workerId?: string;
  name?: string;
  surname?: string;
  numberRegex = numberRegex;

  get isAdditionalInputTypeNumber(): boolean {
    return inputTypeNumberKeys.includes(this.field.entryType.key);
  }

  get additionalInputValueUnits(): string {
    switch(this.field.entryType.key) {
      case "PED_PH_HEIGHT":
        return "centymetrach";
      case "PED_PH_BODY_WEIGHT":
        return "kilogramach";
      default:
        return "";
    }
  }

  get entry(): EntryItem | null {
    return getEntry(this.entries) as EntryItem;
  }

  get entryContentAdditionalIsNotNumber(): boolean {
    return !!this.entry?.content?.additional && !numberRegex.test(this.entry.content.additional);
  }

  get isSavingNotAllowed() {
    if (this.readOnly) {
      return true;
    }
    if (this.selected == null) {
      return true;
    }
    return this.entry !== null
      && this.entry.content.value === this.selected
      && this.entry.content.additional === this.additional[this.selected]
      && this.entry.content.additional === this.additionalSelected;
  }

  mounted() {
    this.additional = {};
    this.field.entryType.options.forEach(
      ({value}) => this.additional[value] = this.entry && value === this.entry.content.value
        ? this.entry.content.additional
        : null
    );
    this.selected = this.entry ? this.entry.content.value : null;
    this.additionalSelected = this.additional[this.selected];
  }

  updateAdditionalSelected(event) {
    this.additionalSelected = event;
  }

  async saveEntry(): Promise<void> {
    if (this.isSavingNotAllowed) {
      return;
    }
    this.saveLoading = true;
    try {
      const additionalKey = Object.keys(this.additional).find(
        (key: string) => this.additional[key] !== null
      ) || "";
      const newEntry = {
        content: {
          additional: this.additional[additionalKey] || null,
          type: "radio",
          value: this.selected,
        },
        entryId: generateUuid(),
        type: this.field.entryType.key,
        createdAt: stringifyDate(new Date()),
        workerId: this.workerId,
      };
      await create(`/api/diagnosis/${this.diagnosisId}/entry`, newEntry);
      const entry = {
        ...newEntry,
        creator: {
          name: this.name,
          surname: this.surname,
        },
      } as EntryItem;
      this.entries = this.entries.concat([entry]);

      this.errors = [];
      this.$emit("new-entry-added");
    } catch (exception) {
      this.errors = processResponseException(exception);
    }
    this.saveLoading = false;
  }

  resolveAdditionalFieldType(additional: Additional): any {
    const type = additional.type;
    switch (type) {
      case "text":
        return "bFormTextarea";
      case "string":
        return "bFormInput";
      case "radio":
        return "bFormRadioGroup";
      default:
        throw Error(`Not supported field type: '${type}'`);
    }
  }

  resolveAdditionalFieldTypeOptions(additional: Additional): object {
    switch (additional.type) {
      case "text":
        return {};
      case "string":
        return {};
      case "radio":
        return {
          options: (additional.options || []).map((o: OptionItem) => ({
            value: o.value,
            text: t(o.label),
          })),
        };
    }
  }
}

interface RadioFieldData {
  entries: Array<EntryItem>;
  entryType: EntryType;
}
interface EntryType {
  name: string;
  key: string;
  options: Array<OptionItem>;
}
interface EntryItem extends BasicEntryItem{
  content: ContentItem;
}
interface ContentItem {
  type: string;
  value: string;
  additional?: null | string;
}

type Additional  = AdditionalText | AdditionalRadio;

interface AdditionalText {
  label?: string;
  type: "text" | "string";
}

interface AdditionalRadio {
  label?: string;
  type: "radio";
  options: Array<{value: string; label: string}>;
}

interface OptionItem {
  value: string;
  label: string;
}
