<template>
  <b-table
    :items="items"
    :fields="fields"
    empty-text="Brak wyników"
    hover
    responsive
    show-empty
  >
    <template #cell(email)="data">
      {{ data.item.email }}
    </template>
    <template #cell(createdAt)="data">
      {{ data.item.createdAt }}
    </template>
    <template #cell(class)="data">
      {{ data.item.class }}
    </template>
    <template #cell(params)="data">
      <pre>{{ data.item.params }}</pre>
    </template>
  </b-table>
</template>

<script>
export default {
  name: "AuditLogTable",
  props: {
    items: {type: Array, default: ()=>[]},
  },
  data() {
    const fields = [
      {key: "email", label: "Użytkownik"},
      {key: "createdAt", label: "Data"},
      {key: "class", label: "Klasa"},
      {key: "params", label: "Parametry"},
    ];
    return {
      fields,
    };
  },
}
</script>
