<template>
  <tr>
    <td>
      <span>{{ getDayFrequencyString(recurringRange) }} </span>
      {{ getHour(recurringRange.rangeStart) }} - {{ getHour(recurringRange.rangeEnd) }}
      <span v-if="endIsNextDay(recurringRange.rangeStart, recurringRange.rangeEnd)"> następnego dnia</span><br>
      od {{ getDate(recurringRange.rangeStart, 'YYYY-MM-DD HH:mm') }}
      {{ recurringRange.dateEnd ? 'do ' + getEndDatePresentation() : '' }} <br>
      <span> komórka organizacyjna: {{ recurringRange.branchName }} </span>
    </td>
    <td v-if="editMode">
      <tooltip
        class="btn btn-sm btn-outline-secondary"
        component="button"
        type="button"
        tooltip-content="Usuń"
        @click="$emit('deleteRange', recurringRange)"
      >
        <i
          class="fa fa-trash"
        />
      </tooltip>
    </td>
  </tr>
</template>

<script>
import parseDate from "../../../utils/date/parseDate";
import Tooltip from "../../Tooltip";

export default {
  name: "RecurringRange",
  components: {Tooltip},
  props: {
    recurringRange:  {type: Object, required: true},
    editMode: {type: Boolean, required: false, default: true}
  },
  methods: {
    getDate(datetime, format) {
      return parseDate(datetime, format).toLocaleDateString("pl-PL", {year: "numeric", month: "long", day: "numeric"});
    },
    endIsNextDay(start, end) {
      const startDay = new Date(start).getDay();
      const endDay = new Date(end).getDay();

      if (startDay === 6 && endDay === 0) {
        return true;
      }

      return endDay - startDay === 1;
    },
    getDayFrequencyString(recuRange) {
      const dateTimeStart = parseDate(recuRange.rangeStart, "YYYY-MM-DD HH:mm");
      const dayName = dateTimeStart.toLocaleDateString("pl-PL", {weekday: "long"});
      const interval = Number(recuRange.rangeIntervalDays);

      if (interval === 1) {
        return "codziennie";
      }
      if (dayName.substr(-1) === "a" && interval === 7) {
        return `każda ${dayName}`
      }
      if (dayName.substr(-1) === "a" && interval === 14) {
        return `co druga ${dayName}`
      }
      if (dayName.substr(-1) === "a" && interval === 21) {
        return `co trzecia ${dayName}`
      }
      if (dayName.substr(-1) !== "a" && interval === 7) {
        return `każdy ${dayName}`
      }
      if (dayName.substr(-1) !== "a" && interval === 14) {
        return `co drugi ${dayName}`
      }
      if (dayName.substr(-1) !== "a" && interval === 21) {
        return `co trzeci ${dayName}`
      }
    },
    getHour(datetimeStr) {
      const dateTime = parseDate(datetimeStr, "YYYY-MM-DD HH:mm");
      const hour = dateTime.getHours();
      const minute = dateTime.getMinutes().toLocaleString("en", {minimumIntegerDigits:2});
      return `${hour}:${minute}`
    },
    getEndDatePresentation() {
      if (this.getHour(this.recurringRange.dateEnd) === "0:00") {
        const date = parseDate(this.recurringRange.dateEnd, "YYYY-MM-DD");
        date.setDate(date.getDate()-1);
        return date.toLocaleDateString("pl-PL", {year: "numeric", month: "long", day: "numeric"});
      } else {
        return `${this.getDate(this.recurringRange.dateEnd, "YYYY-MM-DD")  } ${ 
          this.getHour(this.recurringRange.dateEnd, "YYYY-MM-DD HH:mm")}`
      }
    }
  }
}
</script>
