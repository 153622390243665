import {initialState} from "./toastNotifications/initialState";
import {getters} from "./toastNotifications/getters";
import {mutations} from "./toastNotifications/mutations";
import {actions} from "./toastNotifications/actions";
import {Module} from "vuex";
import {ToastNotificationState} from "./toastNotifications/types";
import {RootState} from "../types";

const toastNotification: Module<ToastNotificationState, RootState> = {
  namespaced: true,
  state: {...initialState},
  getters,
  mutations,
  actions,
};

export default toastNotification;
