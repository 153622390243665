<template>
  <object-select
    :clearable="false"
    :disabled="disabled || options.length === 0"
    :options="options"
    :state="state"
    :value="value"
    placeholder="Wybierz procedurę"
    @input="update"
    @open="open"
    @close="close"
    @search-change="searchChange"
    @select="select"
  />
</template>

<script>
import ObjectSelect from "../../../Form/Select/ObjectSelect";
import read from "../../../../rest/read";
import t from "../../../../i18n";

export default {
  name: "ProcedureSelect",
  components: {ObjectSelect},
  props: {
    disabled: {type: Boolean, default: false},
    state: {type: Boolean, default: null},
    value: {type: Object, default: null},
  },
  data() {
    return {
      items: [],
    };
  },
  computed: {
    options() {
      return this.items.map(item => ({
        ...item,
        value: item.type,
      }));
    },
  },
  async mounted() {
    await this.loadOptions();
  },
  methods: {
    async loadOptions() {
      const {procedures} = await read("/api/procedure-templates");
      this.items = procedures;
    },
    update(option) {
      this.$emit("input", option);
    },
    searchChange(phrase) {
      this.$emit("search-change", phrase);
    },
    open(){
      this.$emit("open");
    },
    close() {
      this.$emit("close");
    },
    select(option) {
      this.$emit("select", option);
    },
  },
}
</script>
