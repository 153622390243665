<template>
  <b-modal
    id="ue-modal"
    class="ue-modal"
    size="lg"
    hide-footer
  >
    <div class="ue-modal-wrapper">
      <div class="ue-modal-content">
        <img
          src="../../images/FE_POWER.jpg"
          class="d-block my-2 img-fluid"
        >
        <p class="ue-text-main">
          EDGETEQ sp. z o.o. jest partnerem projektu zintegrowanego
          „Deinstytucjonalizacja szansą na dobrą zmianę: projekt horyzontalny”,
          który jest realizowany w ramach Działania 4.1 Innowacje społeczne w Ministerstwie Inwestycji i Rozwoju
          (nr projektu POWR.04.01.00-IZ.00-00-014/17)
        </p>
        <p class="ue-text-additional">
          Informacje o projekcie
        </p>
        <p class="ue-text-additional">
          Okres realizacji: do 28.02.2021 r.<br>
          Wartość projektu: 14 982 276,27 zł<br>
          Obszar działania: województwa MAZOWIECKIE (Dzielnica Bielany m.st. Warszawa), MAŁOPOLSKIE (powiat wielicki),
          ZACHODNIOPOMORSKIE (m. Koszalin i powiat koszaliński)
        </p>
        <p class="ue-text-additional">
          Cel projektu: głównym celem projektu horyzontalnego jest poprawa jakości działań w obszarze zdrowia
          psychicznego poprzez przetestowanie środowiskowego modelu wsparcia osób z zaburzeniami i chorobami
          psychicznymi wypracowanego w konkursie I etapu (D003/15). Model będzie realizowany w poszczególnych projektach
          wdrożeniowych (projektach zintegrowanych) w wybranych obszarach testowania. Partnerzy są podmiotami
          realizującymi poszczególne procesyhoryzontalne w ramach innowacji.
        </p>
        <p class="ue-text-additional">
          Strona projektu:
          <a
            href="http://www.psychologia.wfch.uksw.edu.pl/deinstytucjonalizacja_projekt_horyzontalny"
            class="ue-link"
            target="_blank"
          >
            http://www.psychologia.wfch.uksw.edu.pl/deinstytucjonalizacja_projekt_horyzontalny
          </a>
        </p>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "UeModal"
}
</script>

<style scoped lang="scss">
  .ue-modal {
    color: #000;
    white-space: normal;
    font-family: "Calibri", "Arial", "Ubuntu", sans-serif;
  }

  .ue-modal-wrapper {
    overflow: auto;
  }

  .ue-modal-content {
    width: 758px;
  }

  .ue-text-main {
    text-align: center;
    font-size: 0.9375rem;
    margin: 20px;
  }

  .ue-text-additional {
    text-align: left;
    font-size: 0.8125rem;
    margin: 20px;
  }

  .ue-link {
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
  }
</style>
