import { render, staticRenderFns } from "./ReportTreatmentTypeModal.vue?vue&type=template&id=0b33d3a2&scoped=true&"
import script from "./ReportTreatmentTypeModal.vue?vue&type=script&lang=js&"
export * from "./ReportTreatmentTypeModal.vue?vue&type=script&lang=js&"
import style0 from "./ReportTreatmentTypeModal.vue?vue&type=style&index=0&id=0b33d3a2&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b33d3a2",
  null
  
)

export default component.exports