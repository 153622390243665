import {Module} from "vuex";
import {ScheduleAppointmentState} from "./scheduleAppointment/types";
import {actions} from "./scheduleAppointment/actions";
import {getters} from "./scheduleAppointment/getters";
import {mutations} from "./scheduleAppointment/mutations";
import {state} from "./scheduleAppointment/state";
import {RootState} from "../types";

const scheduleAppointment: Module<ScheduleAppointmentState, RootState> = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};

export default scheduleAppointment;
