<template>
  <div>
    <div v-if="value.length">
      <div class="patient-continuous-stay-select font-weight-bold">
        <div class="patient">
          Pacjent
        </div>
        <div class="continuous-stay">
          Pobyt ciągły
        </div>
        <div
          v-if="allowRemove"
          class="remove"
        >
          Usuń
        </div>
      </div>
      <div
        v-for="(item, index) in value"
        :key="item.patientId"
        class="patient-continuous-stay-select"
      >
        <div class="patient">
          <b-input
            :state="state(`patientContinuousStays[${index}].patientId`)"
            :value="item.displayName"
            readonly
          />
          <error-message
            :errors="getSubErrors(`patientContinuousStays[${index}]`)"
            field="patientId"
          />
        </div>
        <div class="continuous-stay">
          <continuous-stay-id-select
            :value="item.continuousStayId"
            :end="end"
            :patient-id="item.patientId"
            :start="start"
            :state="state(`patientContinuousStays[${index}].continuousStayId`)"
            @input="changeContinuousStay(item.patientId, $event)"
          >
            <template #noOptions>
              W przypadku braku otwartego Pobytu Ciągłego, należy umówić usługę "Przyjęcie na OD"
            </template>
          </continuous-stay-id-select>
          <error-message
            :errors="getSubErrors(`patientContinuousStays[${index}]`)"
            field="continuousStayId"
          />
        </div>
        <div
          v-if="allowRemove"
          class="remove text-center"
        >
          <button
            class="btn btn-danger btn-sm"
            type="button"
            @click="removePatient(item.patientId)"
          >
            <i class="fas fa-times" />
          </button>
        </div>
      </div>
    </div>
    <patient-select
      v-if="allowAdd"
      class="mt-2"
      clear-on-click
      placeholder="Wybierz specjalistę"
      :state="state('patientIds')"
      @input="selectPatient"
    />
  </div>
</template>

<script>
import ErrorMessage from "@/components/Form/ErrorMessage";
import ContinuousStayIdSelect from "@/components/Patient/PatientCard/ContinuousStay/ContinuousStayIdSelect";
import subErrors from "@/utils/errors/subErrors";
import PatientSelect from "@/components/Patient/PatientSelect";
import {errorsMixin} from "../../../../mixins/errorsMixin.js";

export default {
  name: "PatientContinuousStaySelect",
  components: {PatientSelect, ContinuousStayIdSelect, ErrorMessage},
  mixins: [errorsMixin],
  props: {
    value: {type: Array, required: true},
    errorsProp: {type: Array, default: ()=>[]},
    allowRemove: {type: Boolean, default: true},
    allowAdd: {type: Boolean, default: true},
    start: {type: Date, default: null},
    end: {type: Date, default: null},
  },
  data() {
    return {
      ContinuousStays: [],
    };
  },
  watch: {
    errorsProp: {
      immediate: true,
      handler() {
        this.errors = this.errorsProp;
      },
    },
  },
  methods: {
    getSubErrors(field) {
      return subErrors(this.errors, field);
    },
    changeContinuousStay(patientId, continuousStayId) {
      const input = this.value.map((item) => item.patientId === patientId ? ({
        ...item,
        continuousStayId,
      }) : item);
      this.$emit("input", input);
    },
    selectPatient(patient) {
      const patientContinuousStay = {
        ...patient,
        continuousStayId: null,
      };
      const old = this.value.find((item) => item.patientId === patient.patientId);
      if (!old) {
        const value = this.value.concat([patientContinuousStay]);
        this.$emit("input", value);
      }
    },
    removePatient(selectedPatientId) {
      const value = this.value.filter(({patientId}) => patientId !== selectedPatientId);
      this.errors = [];
      this.$emit("input", value);
    },
  },
}
</script>

<style scoped lang="scss">
$remove-width: 36px;
$select-margin: 5px;

.patient-continuous-stay-select {
  display: flex;
  align-content: center;
  margin: -$select-margin;
}

.patient {
  width: 35%;
  margin: $select-margin;
}

.continuous-stay {
  width: calc(65% - #{$remove-width});
  margin: $select-margin;
  border-collapse: initial;
}

.remove {
  width: $remove-width;
  margin: $select-margin;
}
</style>
