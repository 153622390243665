<template>
  <div>
    <div
      v-for="worker in workers"
      :key="worker.workerId"
      class="list-group mb-3"
    >
      <h4>
        {{ worker.name }} {{ worker.surname }}
        <br>
        <small>{{ worker.treatmentTypeName }}</small>
      </h4>
      <a
        v-for="doc in workerDocumentsData(worker.workerId)"
        :key="doc.appointmentDocumentId"
        :class="{'active': documentsData[selectedDocument].appointmentDocumentId === doc.appointmentDocumentId}"
        class="list-group-item list-group-item-action"
        href="#"
        @click.prevent="changeDocument(doc.appointmentDocumentId)"
      >
        <h4 class="mb-0">
          <i
            :class="doc.readOnly ? 'fa-eye' : 'fa-pencil-alt'"
            class="fa mr-1"
          />
          {{ doc.patient ? doc.patient.displayName : "Dokument" }}
        </h4>
      </a>
    </div>
  </div>
</template>

<script>
import uniqWith from "lodash/uniqWith";

export default {
  props: {
    documentsData: {required: true, type: Array},
    selectedDocument: {default: null, type: Number},
  },
  computed: {
    workers() {
      const workers = this.documentsData.map((document) => ({
        ...document.mainWorker,
        treatmentTypeName: document.treatmentName,
      }));

      return uniqWith(workers, (a, b) => a.workerId === b.workerId);
    }
  },
  methods: {
    changeDocument(appointmentDocId) {
      this.$router.replace({
        path: this.$router.path,
        hash: `#appointmentDocumentId=${appointmentDocId}`,
      });
    },
    workerDocumentsData(workerId) {
      return this.documentsData.filter(doc => doc.mainWorker.workerId === workerId);
    }
  },
};
</script>
