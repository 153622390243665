













































































































































































import {Component, Mixins, Prop, Watch} from "vue-property-decorator";
import DatePicker from "../../../Form/DatePicker/DatePicker.vue";
import LoadingMask from "../../../Loading/LoadingMask.vue";
import ErrorMessage from "../../../Form/ErrorMessage.vue";
import processResponseException from "../../../../utils/errors/processResponseException";
import stringifyDate from "../../../../utils/date/stringifyDate";
import DATE_FORMAT from "../../../../utils/date/DATE_FORMAT";
import TextareaCounter from "../../../Form/Textarea/TextareaCounter.vue";
import update from "../../../../rest/update";
import read from "../../../../rest/read";
import ErrorsMixin from "../../../../mixins/ErrorsMixin";
import HospitalWidget from "./HospitalWidget.vue";
import FileUpload from "../../../File/FileUpload.vue";
import HospitalizationDocuments from "./HospitalizationDocuments.vue";
import type {
  HospitalEntry,
  Hospitalization,
  HospitalizationDocument,
  HospitalizationProjection,
  UploadedFile
} from "../../../../types/HospitalizationTypes";
import {HospitalizationType} from "../../../../types/HospitalizationTypes";
import HospitalizationTypeSelect from "./HospitalizationTypeSelect.vue";

@Component({
  components: {
    HospitalizationTypeSelect,
    FileUpload,
    HospitalWidget,
    TextareaCounter,
    ErrorMessage,
    LoadingMask,
    HospitalizationDocuments,
    DatePicker,
  }
})
export default class PatientPsychiatricHospitalizationsUpdateModal extends Mixins(ErrorsMixin) {

  @Prop({type: String, default: null}) readonly hospitalizationId!: string|null;

  @Watch("hospitalizationId", {immediate: true}) onHospitalizationIdChanged(newId: string, oldId: string)
  {
    if (newId && newId !== oldId) {
      this.loadData();
    }
  }

  loading: boolean = false;
  hospital: HospitalEntry|null = null;
  comment: string = "";
  dateFrom: string|null = null;
  dateTo: string|null = null;
  datesUnknown: boolean = false;
  duration: number|null = null;
  documents: Array<HospitalizationDocument> = [];
  uploadedFiles: Array<UploadedFile> = [];
  type: HospitalizationType = HospitalizationType.UNKNOWN;

  get modalVisible(): boolean {
    return !!this.hospitalizationId;
  }

  async loadData(): Promise<void> {
    this.loading = true;
    try {
      const {
        hospital,
        comment,
        start,
        end,
        datesUnknown,
        duration,
        files,
        type,
      } = await read<HospitalizationProjection>(`/api/hospitalizations/${this.hospitalizationId}`);
      this.hospital = hospital as HospitalEntry;
      this.comment = comment;
      this.dateFrom = start;
      this.dateTo = end;
      this.datesUnknown = datesUnknown;
      this.duration = duration;
      this.documents = files;
      this.type = type;
    } catch (exception) {
      this.errors = processResponseException(exception);
    }
    this.loading = false;
  }
  closeModal(): void {
    this.clearForm();
    this.$emit("closeModal");
  }
  async submit(): Promise<void> {
    this.loading = true;
    try {
      await update<Omit<Hospitalization, "hospitalizationId">>(`/api/hospitalizations/${this.hospitalizationId}`, {
        start: !!this.dateFrom ? stringifyDate(new Date(this.dateFrom), DATE_FORMAT.DATE) : null,
        end: !!this.dateTo ? stringifyDate(new Date(this.dateTo), DATE_FORMAT.DATE) : null,
        datesUnknown: this.datesUnknown,
        duration: this.duration,
        hospital: this.hospital,
        comment: this.comment,
        documents: [
          ...this.documents.map<string>((file): string => file.fileId),
          ...this.uploadedFiles.map((file: UploadedFile): string => file.fileId),
        ],
        type: this.type,
      });
      this.$emit("updated");
      this.clearForm();
    } catch (exception) {
      this.errors = processResponseException(exception);
    }
    this.loading = false;
  }
  clearForm(): void {
    this.hospital = null;
    this.comment = "";
    this.dateFrom = null;
    this.dateTo = null;
    this.datesUnknown = false;
    this.duration = null;
    this.documents = [];
    this.uploadedFiles = [];
    this.type = HospitalizationType.UNKNOWN;
    this.errors = [];
  }
  addFile(file: UploadedFile): void {
    this.uploadedFiles.push(file);
  }
  removeFile(file: UploadedFile): void {
    this.uploadedFiles = this.uploadedFiles.filter(f => f.fileId !== file.fileId);
  }
  updateAttachmentFilename(fileId: string, name: string): void {
    this.documents = this.documents.map<HospitalizationDocument>(item => item.fileId === fileId
      ? {...item, name}
      : item,
    );
    this.$emit("filenameChange", this.hospitalizationId, fileId, name);
  }
  async removeDocument(file: HospitalizationDocument) {
    this.documents = this.documents.filter(f => f.fileId !== file.fileId);
  }
  setNullDates(event: boolean): void {
    if (event) {
      this.dateFrom = null;
      this.dateTo = null;
    } else {
      this.duration = null;
    }
  }
}
