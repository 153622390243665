import { render, staticRenderFns } from "./PayerIdTypeSelect.vue?vue&type=template&id=4da8c398&scoped=true&"
import script from "./PayerIdTypeSelect.vue?vue&type=script&lang=ts&"
export * from "./PayerIdTypeSelect.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4da8c398",
  null
  
)

export default component.exports