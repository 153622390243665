










































import {Component, Prop, Watch} from "vue-property-decorator";
import Vue from "vue";
import type {Filters, WorkerOption} from "../../types/RegistrationSearchTypes";
import WorkerSelect from "../Worker/WorkerSelect.vue";
import ErrorMessage from "../Form/ErrorMessage.vue";
import DatePicker from "../Form/DatePicker/DatePicker.vue";
import isEqual from "lodash/isEqual";
import {updatePageQuery} from "../../utils/pageUrl/handlePageQuery";
import stringifyDate from "../../utils/date/stringifyDate";

@Component({
  name: "RegistrationSearchFilters",
  components: {ErrorMessage, WorkerSelect, DatePicker},
})
export default class RegistrationSearchFilters extends Vue {
  @Prop({type: Object, required: true}) readonly filters!: Filters;

  private workers: Filters["workers"]|Array<WorkerOption> = this.filters.workers;
  private start: Filters["start"] = this.filters.start;
  private end: Filters["end"] = this.filters.end;

  private get workerIds(): Filters["workers"] {
    if (!this.workers.length) {
      return [];
    }
    if ("string" === typeof this.workers[0]) {
      return this.workers;
    }
    return this.workers.map(worker => worker.value);
  }

  @Watch("filters.workers") private onWorkersChange(val: Filters["workers"]): void {
    this.workers = val;
  }

  @Watch("filters.start") private onStartChange(val: Filters["start"]): void {
    this.start = val;
  }

  @Watch("filters.end") private onEndChange(val: Filters["end"]): void {
    this.end = val;
  }

  private async submit(): Promise<void> {
    const query = {
      workers: this.workerIds.join(",") || undefined,
      start: stringifyDate(this.start),
      end: stringifyDate(this.end),
    }

    if (isEqual(this.$route.query, query)) {
      this.$emit("reload")
      return
    }
    await updatePageQuery(query)
  }

  private disabledStartDate(start: Date): boolean {
    const today = new Date();
    today.setHours(0,0,0,0);
    return today.getTime() > start.getTime();
  }

  private disabledEndDate(end: Date): boolean {
    return this.start.getTime() > end.getTime();
  }
}
