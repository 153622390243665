









































































































































































































import {Component, Mixins, Prop} from "vue-property-decorator";
import ErrorsMixin from "../../mixins/ErrorsMixin";
import processResponseException from "../../utils/errors/processResponseException";
import TestSelect from "./TestSelect.vue";
import type {Test, TestResultSubmitData, TestScale} from "../../types/TestResult";
import {TestResultStatus} from "../../types/TestResult";
import {generateUuid} from "../../utils/uuid/generateUuid";
import create from "../../rest/create";
import FormRow from "../Form/FormRow.vue";
import ErrorMessage from "../Form/ErrorMessage.vue";
import DatePicker from "../Form/DatePicker/DatePicker.vue";
import TextareaCounter from "../Form/Textarea/TextareaCounter.vue";
import stringifyDate from "../../utils/date/stringifyDate";
import DATE_FORMAT from "../../utils/date/DATE_FORMAT";
import DateTime from "../DateTime.vue";
import {getLabel, getScaleInputs} from "../../utils/tests/TestLabels";

@Component({
  name: "CreateTestResultModal",
  components: {
    DateTime,
    TextareaCounter,
    ErrorMessage,
    FormRow,
    TestSelect,
    DatePicker,
  },
})
export default class CreateTestResultModal extends Mixins(ErrorsMixin) {
  @Prop({type: String, required: true}) readonly id!: string;
  @Prop({type: String, required: true}) readonly patientId!: string;
  @Prop({type: String, default: null}) readonly continuousStayId!: string | null;
  @Prop({type: String, default: null}) readonly appointmentDocumentId!: string | null;
  @Prop({type: Date, default: null}) readonly appointmentDate!: Date|null;

  private loading: boolean = false;
  private test: Test|null = null;
  private rawResults: Record<TestScale["scaleId"], string> = {};
  private results: Record<TestScale["scaleId"], string> = {};
  private date: Date|null = this.appointmentDate || new Date();
  private summary: string = "";
  TestResultStatus = TestResultStatus;
  DATE_FORMAT = DATE_FORMAT;
  getLabel = getLabel;
  getScaleInputs = getScaleInputs;

  private get initialStatus(): TestResultStatus {
    return this.appointmentDocumentId
      ? TestResultStatus.DRAFT
      : TestResultStatus.OPENED;
  }

  private displaySingle(test: Test|null): boolean {
    return test?.labelType === "raw" || test?.labelType === "algorithm";
  }

  private scaleAsLabel(test: Test|null) : boolean {
    return test?.labelType === "scale-as-label";
  }

  private updateRawResult($event: string, scaleId: string): void {
    if (!$event.trim()) {
      this.$delete(this.rawResults, scaleId);
    }
  }

  private async submit(status: TestResultStatus): Promise<void> {
    this.loading = true;
    try {
      await create<TestResultSubmitData>("/api/test-results", {
        testResultId: generateUuid(),
        patientId: this.patientId,
        testId: this.test?.testId || null,
        rawResults: this.rawResults,
        results: this.results,
        date: this.date ? stringifyDate(this.date) : null,
        summary: this.summary,
        status,
        appointmentDocumentId: this.appointmentDocumentId,
        continuousStayId: this.continuousStayId,
        labelType: this.test?.labelType || null
      });
      this.$emit("created");
      this.hideModal();
    } catch (e) {
      this.errors = processResponseException(e);
    }
    this.loading = false;
  }

  private hideModal(): void {
    this.$bvModal.hide(this.id);
  }

  private onModalHide(): void {
    this.errors = [];
    this.loading = false;
    this.test = null;
    this.date = new Date();
    this.clearResults();
    this.summary = "";
  }

  private clearResults(): void {
    this.results = {};
    this.rawResults = {};
  }

  private scaleResultCount(scale: string) :number {
    const inputs = this.getScaleInputs(scale);
    return inputs.length;
  }
}

