<template>
  <b-modal
    :id="modalId"
    no-close-on-backdrop
    title="Edycja case manager'a OPS"
    title-tag="h3"
    @hide="$emit('close')"
  >
    <template #default>
      <error-message :errors="errors" />
      <worker-select
        v-model="newCaseManager"
        clearable
      />
      <div
        v-show="caseManagerHistory.length"
        v-b-toggle="'caseManagerHistory'"
        class="text-center m-2 cursor-pointer"
      >
        ---
        <span class="when-closed">Pokaż historię</span>
        <span class="when-opened">Ukryj historię</span>
        ---
      </div>
      <b-collapse id="caseManagerHistory">
        <table class="table table-sm">
          <thead>
            <tr>
              <th>Data zmiany</th>
              <th>Osoba zmieniająca</th>
              <th>Nowy case manager</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(entry, index) in caseManagerHistory"
              :key="index"
              class="p-3"
            >
              <td>{{ entry.dateOfChange }}</td>
              <td>{{ entry.changerFullName }}</td>
              <td>{{ entry.caseManagerFullName ? entry.caseManagerFullName : 'brak' }}</td>
            </tr>
          </tbody>
        </table>
      </b-collapse>
    </template>
    <template #modal-footer>
      <button
        type="button"
        class="btn btn-secondary"
        @click="hideModal"
      >
        Anuluj
      </button>
      <button
        :disabled="loading"
        class="btn btn-primary"
        @click="submit"
      >
        <i
          :class="loading ? 'fa-spin fa-spinner' : 'fa-check'"
          class="fa"
        />
        Zapisz
      </button>
    </template>
  </b-modal>
</template>

<script>
import update from "../../rest/update";
import read from "../../rest/read";
import WorkerSelect from "../Worker/WorkerSelect";
import processResponseException from "../../utils/errors/processResponseException";
import ErrorMessage from "@/components/Form/ErrorMessage";

export default {
  name: "CaseManagerOpsModal",
  components: {
    ErrorMessage,
    WorkerSelect,
  },
  props: {
    patientId: {type: String, default: null},
    modalId: {type: String, required: true},
  },
  data() {
    return {
      errors: [],
      loading: false,
      caseManager: null,
      caseManagerOps: null,
      newCaseManager: null,
      caseManagerHistory: [],
    };
  },
  watch: {
    patientId: {
      immediate: true,
      handler(val) {
        if (val) {
          this.loadData();
        } else {
          this.caseManager = null;
          this.caseManagerOps = null;
          this.newCaseManager = null;
          this.caseManagerHistory = [];
        }
      },
    }
  },
  methods: {
    async submit() {
      this.loading = true;
      try {
        const resource = `/api/patients/${this.patientId}/case-manager-ops`;
        await update(resource, {workerId: this.newCaseManager ? this.newCaseManager.workerId : null});
        await this.loadData();
        this.hideModal();
        this.$emit("changed")
      } catch (exception) {
        this.errors = processResponseException(exception);
      }
      this.loading = false;
    },
    hideModal() {
      this.$bvModal.hide(this.modalId);
    },
    resetData() {
      this.newCaseManager = this.caseManager;
    },
    async loadData() {
      this.loading = true;
      const response = await read(`/api/patients/${this.patientId}/case-manager-ops`);
      this.caseManagerHistory = response.caseManagerHistory.reverse();
      this.caseManager = response.workerId ? response : null;
      this.resetData();
      this.loading = false;
    }
  },
};
</script>


<style scoped>
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}
</style>
