import Vue from "vue";
import * as Sentry from "@sentry/vue";
import currentUser from "./store/modules/currentUser";

export default function initSentry() {
  const dsn = `${window.location.protocol}//public@${window.location.host}/api/sentry/0`;
  Sentry.init({
    dsn: dsn,
    Vue: Vue,
    release: process.env.APP_VERSION,
    environment: process.env.NODE_ENV,
    beforeSend(event) {
      if (!event.user) {
        event.user = {};
      }
      event.user.id = currentUser.state.user.userId;
      event.tags = {...event.tags, partner: currentUser.state.user.settings.partnerId};

      return event;
    },
  });
}
