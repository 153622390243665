











































import ChildIpzForm from "./ChildIpzForm.vue";
import {Component, Mixins, Prop, Watch} from "vue-property-decorator";
import type {ChildIpzItem, ChildIpzItemFormData} from "../../../types/ChildIpzs";
import processResponseException from "../../../utils/errors/processResponseException";
import ErrorsMixin from "../../../mixins/ErrorsMixin.ts";
import modify from "../../../rest/modify";

@Component({
  name: "ChildIpzEditModal",
  components: {ChildIpzForm},
})

export default class ChildIpzEditModal extends Mixins(ErrorsMixin) {
  @Prop({type: String, required: true}) readonly ipzId!: string;
  @Prop({type: Object, default: null}) readonly item!: ChildIpzItem|null;

  loading: boolean = false;
  formData: ChildIpzItemFormData = {
    description: this.item ? this.item.description : null,
    goals: this.item ? this.item.goals : null,
    formsOfInteractions: this.item ? this.item.formsOfInteractions : null,
    signatures: this.item ? this.item.signatures : [],
  };

  get title(): string {
    return "Edycja IPZ dziecięcego";
  }

  @Watch("item", {immediate: true}) onItemChange(): void {
    this.resetData();
  }

  closeModal(): void {
    this.$bvModal.hide("child-ipz-modal");
  }

  async submit(): Promise<void> {
    this.resetErrors();
    this.loading = true;
    try {
      const data = {...this.formData};
      await modify("/api/child-ipzs", {
        ipzId: this.ipzId,
        childIpzId: this.item ? this.item.childIpzId : null,
        ...data,
      });
      this.$emit("edited");
      this.closeModal();
    } catch (e) {
      this.errors = processResponseException(e);
    }
    this.loading = false;
  }

  resetErrors(): void {
    this.errors = [];
  }

  resetData(): void {
    this.formData = {
      description: this.item ? this.item.description : null,
      goals: this.item ? this.item.goals : null,
      formsOfInteractions: this.item ? this.item.formsOfInteractions : null,
      signatures: this.item ? this.item.signatures : [],
    }
  }

  onHideModal(): void {
    this.resetErrors();
    this.resetData();
  }
}
