<template>
  <b-modal
    id="ipz-edit"
    :visible="visible"
    no-close-on-backdrop
    title="Edycja daty"
    title-tag="h3"
    @hide="closeModal"
  >
    <template v-if="ipz">
      <FormRow
        :errors="errors"
        label="Data otwarcia IPZ"
        field="openAt"
      >
        <date-picker
          id="start-date"
          v-model="startDate"
          :disabled-date="disabledDateStart"
          :minute-step="1"
          :state="state('openAt')"
          type="datetime"
          format="YYYY-MM-DD HH:mm"
        />
      </FormRow>
      <FormRow
        v-if="ipz.closedAt"
        :errors="errors"
        label="Data zamknięcia IPZ"
        field="closeAt"
      >
        <date-picker
          id="end-date"
          v-model="endDate"
          :disabled-date="disabledDateEnd"
          :minute-step="1"
          :state="state('closeAt')"
          type="datetime"
          format="YYYY-MM-DD HH:mm"
        />
      </FormRow>
    </template>
    <template #modal-footer>
      <button
        class="btn btn-secondary"
        type="button"
        @click="closeModal"
      >
        Zamknij
      </button>
      <button
        :disabled="!ipz || loading"
        class="btn btn-primary"
        @click="updateIpz"
      >
        <i
          :class="loading ? 'fa-spin fa-spinner' : 'fa-check'"
          class="fa"
        />
        Zapisz
      </button>
    </template>
  </b-modal>
</template>

<script>
import processResponseException from "../../utils/errors/processResponseException";
import stringifyDate from "../../utils/date/stringifyDate";
import DATE_FORMAT from "../../utils/date/DATE_FORMAT";
import DatePicker from "../Form/DatePicker/DatePicker";
import {errorsMixin} from "../../mixins/errorsMixin.js";
import modify from "../../rest/modify";
import FormRow from "../Form/FormRow";

export default {
  name: "UnificationIpzEditModal",
  components: {FormRow, DatePicker},
  mixins: [errorsMixin],
  props: {
    ipz: {type: Object, default: null},
  },
  data() {
    const startDate = null === this.ipz ? new Date() : new Date(this.ipz.createdAt);
    const endDate = null !== this.ipz && null !== this.ipz.closedAt ? new Date(this.ipz.closedAt) : null;

    return {
      startDate,
      endDate,
      loading: false,
    };
  },
  computed: {
    visible() {
      return this.ipz !== null;
    },
    startDateString() {
      return this.startDate ? stringifyDate(this.startDate, DATE_FORMAT.DATE_TIME) : null;
    },
    endDateString() {
      return this.endDate ? stringifyDate(this.endDate, DATE_FORMAT.DATE_TIME) : null;
    },
  },
  watch: {
    ipz: function (val) {
      if (null === val) {
        return;
      }
      this.startDate = new Date(val.createdAt);

      if (null !== val.closedAt) {
        this.endDate = new Date(val.closedAt);
      }
    }
  },
  methods: {
    disabledDateStart(date) {
      const now = new Date();

      if (null !== this.endDate) {
        return date > this.endDate;
      }
      return date > now;
    },
    disabledDateEnd(date) {
      const now = new Date();

      return date > now && date < this.startDate;
    },
    closeModal(ipz) {
      this.errors = [];
      this.startDate = null;
      this.endDate = null;
      this.$emit("close", ipz);
    },
    async updateIpz() {
      this.loading = true;
      try {
        await modify(`/api/ipzs/${this.ipz.ipzId}`, {
          openAt: this.startDateString,
          closeAt: this.endDateString,
        });

        this.closeModal({...this.ipz, closedAt: this.endDateString, createdAt: this.startDateString});
      } catch (e) {
        this.errors = processResponseException(e);
      }
      this.loading = false;
    },
  },
}
</script>
