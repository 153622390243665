<template>
  <div class="card-box">
    <h3 class="m-b-20">
      {{ headerTitle }}
    </h3>
    <div
      id="evaluatorType"
      class="form-group"
    >
      <label
        class="col-form-label"
      >
        Osoba ewaluująca
      </label>
      <evaluator-type-select
        v-model="evaluatorType"
        :state="state('evaluatorType')"
        @input="updateForm"
      />
      <error-message
        :errors="errors"
        field="evaluatorType"
      />
    </div>
    <div
      v-for="component in components"
      :key="component.symbol"
      class="card-box m-t-10"
    >
      <div class="row m-b-30">
        <div class="col-md-12">
          <h3>{{ component.name }}</h3>
          {{ component.description }}
        </div>
      </div>
      <div class="row">
        <evaluation-category
          v-for="category in component.categories"
          :key="category.category"
          :category="category"
          :qualifiers="component.qualifiers"
          :errors="errors"
          :saved-data="getSavedData(category.category)"
          :symbol="component.symbol"
          :previous-evaluation="previousEvaluation"
          @update="updateAnswers"
        />
      </div>
    </div>
    <error-message :errors="errors" />
    <error-message
      :errors="errors"
      field="overwriteWarning"
    />
    <div
      v-if="forceUpdate || (hasOverwriteError)"
      :class="{'border-danger': state('') === false}"
      class="alert alert-purple"
    >
      Podczas trwania ewaluacji formularz został edytowany przez innego użytkownika.
      <br>
      Kliknij
      <tooltip tooltip-content="Otwórz ewaluację w nowym oknie">
        <a
          class="text-primary font-weight-bold"
          :href="$route.fullPath"
          target="_blank"
          @click.stop
        >
          <span class="fas fa-external-link-alt mr-1" />tutaj
        </a>
        aby otworzyć aktualny stan formularza ewaluacji w nowym oknie.
      </tooltip>
      <b-checkbox
        v-model="forceUpdate"
        @input="updateForm"
      >
        Zaznacz jeśli chcesz nadpisać dane wprowadzone do formularza.
      </b-checkbox>
    </div>
    <template v-if="evaluationStatus === 'opened'">
      <b-btn
        id="answers"
        v-b-modal.finishEvaluationModal
        :disabled="loading"
        class="btn btn-sm btn-success"
      >
        <i
          v-if="loading"
          class="fa fa-spin fa-spinner"
        />
        Zakończ ewaluację
      </b-btn>
      <b-btn
        :disabled="loading"
        class="btn btn-sm btn-success m-l-5"
        @click="submitEvaluation(false, true)"
      >
        <i
          v-if="loading"
          class="fa fa-spin fa-spinner"
        />
        Zapisz ewaluację
      </b-btn>
      <b-btn
        v-b-modal.cancelEvaluationModal
        :disabled="loading"
        class="btn btn-sm btn-success m-l-5"
      >
        <i
          v-if="loading"
          class="fa fa-spin fa-spinner"
        />
        Anuluj ewaluację
      </b-btn>
    </template>
    <error-message
      :errors="errors"
      :root="true"
      field="answers"
    />
    <cancel-evaluation-modal
      :ipz-id="overview.ipzId"
      :evaluation-id="evaluationId"
    />
    <finish-evaluation-modal
      @finished="submitEvaluation(true, true)"
    />
  </div>
</template>

<script>
import EvaluatorTypeSelect from "./EvaluatorTypeSelect";
import EvaluationCategory from "./EvaluationCategory";
import {BadRequestException} from "../../../rest";
import ErrorMessage from "../../Form/ErrorMessage";
import scrollPageToElement from "../../../utils/scrollPageToElement";
import {errorsMixin} from "../../../mixins/errorsMixin.js";
import processResponseException from "../../../utils/errors/processResponseException";
import CancelEvaluationModal from "./CancelEvaluationModal";
import t from "../../../i18n";
import FinishEvaluationModal from "./FinishEvaluationModal";
import update from "../../../rest/update";
import debounce from "lodash/debounce";
import stringifyDate from "@/utils/date/stringifyDate";
import {mapActions, mapMutations} from "vuex";
import Tooltip from "@/components/Tooltip";
import noParallel from "@/utils/noParralel";

const deferred = debounce((f) => f(), 2000, {maxWait: 2000});
const saveSemaphore = noParallel();

export default {
  components: {
    Tooltip,
    FinishEvaluationModal,
    CancelEvaluationModal,
    ErrorMessage,
    EvaluationCategory,
    EvaluatorTypeSelect
  },
  mixins: [errorsMixin],
  props: {
    ipzId: {type: String, required: true},
    evaluationId: {type: String, required: true},
    components: {type: Array, required: true},
    overview: {type: Object, required: true},
    savedAnswers: {type: Array, required: true},
    savedEvaluatorType: {type: String, default: null},
    evaluationStatus: {type: String, required: true},
    evaluationCreatedAt: {type: String, required: true},
    evaluationEditedAt: {type: Date, required: true},
    previousEvaluation: {type: Object, default: null},
    evaluationCoreSet: {type: Array, required: true}
  },
  data() {
    return {
      evaluatorType: this.savedEvaluatorType || null,
      answers: this.savedAnswers || [],
      loading: false,
      editedAt: this.evaluationEditedAt,
      currentEditDate: null,
      forceUpdate: false,
    };
  },
  computed: {
    headerTitle() {
      return `Ewaluacja ICF - ${this.evaluationCreatedAt} - ${
        this.evaluationCoreSet.map(name => t(name)).join(", ")
      }`;
    },
    hasOverwriteError() {
      return this.errors?.some((error) => {
        return error?.field === "overwriteWarning";
      });
    },
  },
  methods: {
    ...mapActions({
      showToast: "toastNotification/showToast",
    }),
    ...mapMutations({
      hideToast: "toastNotification/hideToast",
    }),
    updateForm() {
      deferred(() => {
        this.submitEvaluation(false, false);
      });
    },
    updateAnswers(answer) {
      const index = this.answers.findIndex(element => element.category === answer.category);

      if (index === -1) {
        this.answers.push(answer);
      } else if (this.isAnswerEmpty(answer)) {
        this.answers.splice(index, 1);
      } else {
        this.answers[index] = answer;
      }

      this.loading = true;
      this.updateForm();
    },

    submitEvaluation(close, redirect) {
      saveSemaphore(this.handleSubmitEvaluation.bind(this))(close, redirect);
    },
    async handleSubmitEvaluation(close, redirect) {
      this.loading = true;
      this.errors = [];
      this.hideToast();

      try {
        this.currentEditDate = new Date();
        await update(`/api/ipzs/${this.ipzId}/evaluations/${this.evaluationId}`, {
          answers: this.answers,
          evaluatorType: this.evaluatorType,
          evaluationEditedAt: stringifyDate(this.editedAt),
          currentEditDate:  stringifyDate(this.currentEditDate),
          close,
          forceUpdate: this.forceUpdate,
        });
        this.editedAt = this.currentEditDate;
        if (redirect) {
          await this.$router.push({name: "ipz_evaluations_list", params: {ipzId: this.overview.ipzId}});
        }
        this.forceUpdate = false;
      } catch (exception) {
        this.errors = processResponseException(exception);
        if (exception instanceof BadRequestException) {
          const warning = this.errors.find(error => error.field === "overwriteWarning");
          if (warning) {
            this.showToast({
              message: warning.message,
              variant: "danger",
            });
            return;
          }
          const element = document.getElementById(this.errors[0]?.field) || null;
          if (element) {
            scrollPageToElement(document.getElementById(this.errors[0].field));
          }
        }
      }
      this.loading = false;
    },
    isAnswerEmpty(answer) {
      if (!!answer.description.length || !!answer.sourcesOfInformation.length) {
        return false;
      }
      return !Object.keys(answer.qualifiersAnswers).some(function (qualifier) {
        return !!answer.qualifiersAnswers[qualifier].length;
      });
    },
    getSavedData(category) {
      return this.savedAnswers.find(item => item.category === category) || null;
    },
  },
}
</script>

<style scoped lang="scss">
@import "../../../styles/variables";

.alert-purple {
  border: 2px $primary solid;
}
</style>
