import Vue from "vue";

const vue = new Vue();

export const EVENT = {
  SAVE: 1,
};

export default {
  emit(event, payload = null) {
    vue.$emit(`${event}`, payload);
  },
  on(event, handler) {
    vue.$on(`${event}`, handler);
  },
  off(event, handler) {
    vue.$off(`${event}`, handler);
  }
};
