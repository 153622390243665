<template>
  <object-select
    :value="selectedValue"
    :options="options"
    :placeholder="placeholder"
    :state="state"
    :disabled="disabled"
    @input="update"
  />
</template>

<script>
import ObjectSelect from "@/components/Form/Select/ObjectSelect";
import t from "@/i18n";

export default {
  name: "TreatmentTypeScopeSelect",
  components: {
    ObjectSelect,
  },
  props: {
    value: {type: String, default: null},
    state: ObjectSelect.props.state, // eslint-disable-line
    placeholder: {type: String, default: "Wybierz rodzaj usługi"},
    disabled: {type: Boolean, default: false},
  },
  data() {
    return {
      optionValues: [
        {value: "all"},
        {value: "ambulatory"},
        {value: "continuous-stay"},
      ],
    };
  },
  computed: {
    options() {
      return this.optionValues.map(option => ({
        ...option,
        name: t(`@treatment-type-scope.${option.value}`),
      }));
    },
    selectedValue() {
      if (!this.value) {
        return this.options.find((option) => option.value === "all");
      } else {
        return this.options.find((option) => option.value === this.value);
      }
    },
  },
  methods: {
    update(option) {
      if(option && option.value === "all"){
        this.$emit("input",null);
      } else {
        this.$emit("input", option ? option.value : null);
      }
    },
  },
}
</script>

<style scoped>

</style>
