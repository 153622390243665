<template>
  <object-select
    :value="selectedValue"
    :options="options"
    :placeholder="placeholder"
    :state="state"
    :multiple="multiple"
    @input="update"
  />
</template>

<script>
import ObjectSelect from "../Form/Select/ObjectSelect";
import t from "../../i18n";

export default {
  name: "RequestTypeSelect",
  components: {
    ObjectSelect,
  },
  props: {
    value: {type: Array, default: () => {}},
    state: ObjectSelect.props.state, // eslint-disable-line
    placeholder: {type: String, default: "Wybierz status wniosku"},
    multiple: {type: Boolean, default: false}
  },
  data() {
    return {
      optionValues: [
        {value: "CancelAppointmentCommand"},
      ],
    };
  },
  computed: {
    options() {
      return this.optionValues.map(option => ({
        ...option,
        name: t(`@request-type.${option.value}`),
      }));
    },
    selectedValue() {
      return this.options.filter((option) => (this.value.includes(option.value)));
    },
  },
  methods: {
    update(option) {
      this.$emit("input", option.map((opt)=> (opt.value)));
    },
  },
}
</script>

<style scoped>

</style>
