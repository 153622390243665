<template>
  <dl class="row">
    <dt class="col-6 col-sm-4 col-lg-12 col-xl-5">
      Pacjent
    </dt>
    <dd class="col-6 col-sm-8 col-lg-12 col-xl-7">
      {{ patientFullName }}
    </dd>

    <dt class="col-6 col-sm-4 col-lg-12 col-xl-5">
      Status
    </dt>
    <dd class="col-6 col-sm-8 col-lg-12 col-xl-7">
      <b-badge
        class="status-badge"
        :variant="getBadgeVariant(continuousStayStatus)"
      >
        <i18n
          component="fragment"
          :msgid="`@continuousStay.${continuousStayStatus}`"
        />
      </b-badge>
    </dd>

    <dt class="col-6 col-sm-4 col-lg-12 col-xl-5">
      Otworzył
    </dt>
    <dd class="col-6 col-sm-8 col-lg-12 col-xl-7">
      {{ workerFullName }}
    </dd>

    <dt class="col-6 col-sm-4 col-lg-12 col-xl-5">
      Kategoria
    </dt>
    <i18n
      :msgid="`@continuousStay.${continuousStayCategory}`"
      class="col-6 col-sm-8 col-lg-12 col-xl-7"
      component="dd"
    />

    <dt class="col-6 col-sm-4 col-lg-12 col-xl-5">
      Komórka
    </dt>
    <dd class="col-6 col-sm-8 col-lg-12 col-xl-7">
      {{ branch ? branch.name : "" }}
    </dd>

    <dt class="col-6 col-sm-4 col-lg-12 col-xl-5">
      Data rozpoczęcia
    </dt>
    <date-time
      v-if="startDate"
      :value="parseDate(startDate, DATE_FORMAT.DATE_TIME_SEC)"
      :format="DATE_FORMAT.DATE_TIME"
      class="col-6 col-sm-8 col-lg-12 col-xl-7"
      component="dd"
    />

    <dt class="col-6 col-sm-4 col-lg-12 col-xl-5">
      Data zakończenia
    </dt>
    <date-time
      v-if="endDate"
      :value="parseDate(endDate, DATE_FORMAT.DATE_TIME_SEC)"
      :format="DATE_FORMAT.DATE_TIME"
      class="col-6 col-sm-8 col-lg-12 col-xl-7"
      component="dd"
    />
  </dl>
</template>

<script>
import parseDate from "../../../../utils/date/parseDate";
import DATE_FORMAT from "../../../../utils/date/DATE_FORMAT";
import DateTime from "../../../DateTime";
import I18n from "../../../i18n";

export default {
  components: {I18n, DateTime},
  props: {
    patientFullName: {type: String, default: null},
    continuousStayStatus: {type: String, default: null},
    workerFullName: {type: String, default: null},
    continuousStayCategory: {type: String, default: null},
    startDate: {type: String, default: null},
    endDate: {type: String, default: null},
    branch: {type: Object, default: null},
  },
  data() {
    return {
      parseDate,
      DATE_FORMAT,
    };
  },
  methods: {
    getBadgeVariant(status) {
      switch (status) {
        case "in_progress":
          return "success";
        case "closed":
          return "primary";
        case "canceled":
          return "danger";
        default:
          throw Error(`Not supported status: ${status}`);
      }
    },
  },
}
</script>

<style scoped lang="scss">
.status-badge{
  width: 5rem;
}
</style>
