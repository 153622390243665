<template>
  <div class="card-box">
    <error-message :errors="errors" />
    <b-form-group
      label-for="cooperating-unit-name"
      label="Nazwa jednostki"
    >
      <b-form-input
        id="cooperating-unit-name"
        v-model.trim="name"
        :state="state('name')"
      />
      <error-message
        :errors="errors"
        field="name"
      />
    </b-form-group>
    <address-form
      v-model="address"
      :errors="subErrors('address')"
      :visible-country="false"
      horizontal
    />
    <b-form-group
      label-for="cooperating-unit-country"
      label="Kraj"
    >
      <b-form-input
        id="cooperating-unit-country"
        :value="countryName"
        readonly
      />
    </b-form-group>
    <div class="form-group">
      <b-form-checkbox
        id="cooperating-unit-active"
        v-model="active"
        :state="state('active')"
      >
        <span :class="{'font-weight-bold': active}">jednostka aktywna</span>
      </b-form-checkbox>
      <error-message
        :errors="errors"
        field="active"
      />
    </div>
    <div class="text-right">
      <router-link
        :to="{name: 'cooperatingUnits'}"
        class="btn btn-secondary"
      >
        Powrót
      </router-link>
      <button
        class="btn btn-primary ml-2"
        @click="submit"
      >
        <i
          :class="loading ? 'fa-spin fa-spinner' : 'fa-check'"
          class="fa"
        />
        Zapisz
      </button>
    </div>
  </div>
</template>

<script>
import ErrorMessage from "../../components/Form/ErrorMessage";
import AddressForm from "../../components/Address/AddressForm";
import countriesJson from "../../countries.json"
import {generateUuid} from "../../utils/uuid/generateUuid";
import create from "../../rest/create";
import getSubErrors from "../../utils/errors/subErrors";
import {errorsMixin} from "../../mixins/errorsMixin.js";
import processResponseException from "../../utils/errors/processResponseException";

export default {
  components: {AddressForm, ErrorMessage},
  mixins: [errorsMixin],
  data() {
    return {
      name: null,
      address: {country: "PL"},
      active: true,
      loading: false,
    };
  },
  computed: {
    countryName: function () {
      if (!this.address.country) {
        return null;
      }
      const country = countriesJson.find((c) => c.code === this.address.country);
      return country.name;
    },
  },
  methods: {
    async submit() {
      this.errors = [];
      this.loading = true;
      const cooperatingUnitId = generateUuid();
      const formData = {
        cooperatingUnitId: cooperatingUnitId,
        name: this.name,
        address: this.address,
        active: this.active,
      };

      try {
        await create("/api/cooperating-unit/create", formData);
        await this.$router.push({name: "cooperatingUnits"});
      } catch(exception) {
        this.errors = processResponseException(exception);
      }
      this.loading = false;
    },
    subErrors(field) {
      return getSubErrors(this.errors, field);
    },
  },
}
</script>
