










import Vue from "vue";
import Component from "vue-class-component";
import {Prop} from "vue-property-decorator";

@Component({
  name: "CalendarColor"
})
export default class CalendarColor extends Vue {
  @Prop({type: String, required: true}) readonly colorName!: string;
  @Prop({type: String, required: true}) readonly colorClass!: string;

}
