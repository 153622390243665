<template>
  <div class="row m-b-30">
    <div class="col-2 text-right">
      <span>{{ typeTrans }}</span>
    </div>
    <div class="col-10">
      <div
        v-if="!!entries.length"
        class="card m-b-10"
      >
        <div class="card-body">
          <div
            v-for="entry in entries"
            :key="entry.createdAt"
            class="card-text m-b-15"
          >
            <read-more
              :text="entry.content.text ? entry.content.text : entry.content"
              :max-chars="400"
              class="text-justify"
              more-str="Czytaj dalej"
              less-str="Mniej"
            />
            <span
              class="font-weight-bold"
              :title="entry.createdAt"
            >
              {{ stringifyDate(entry.createdAt) }} - {{ entry.creator.name }} {{ entry.creator.surname }}
            </span>
          </div>
        </div>
      </div>
      <diagnosis-entry-add
        v-if="!readOnly"
        :create-entry-url="createEntryUrl"
        :type="type"
        :type-trans="typeTrans"
        @new-diagnosis-entry-added="entryAdded"
      />
    </div>
  </div>
</template>

<script>
import DiagnosisEntryAdd from "./DiagnosisEntryAdd";
import ReadMore from "../../ReadMore";
import stringifyDate from "../../../utils/date/stringifyDate";
import DATE_FORMAT from "../../../utils/date/DATE_FORMAT";

export default {
  components: {
    DiagnosisEntryAdd,
    ReadMore,
  },
  props: {
    createEntryUrl: {type: String, required: true},
    injectedEntries: {type: Array, required: true},
    type: {type: String, required: true},
    typeTrans: {type: String, required: true},
    readOnly: {type: Boolean, required: true},
  },
  data() {
    return {
      entries: this.injectedEntries || [],
    };
  },
  methods: {
    entryAdded(entry) {
      this.entries = this.entries.concat([entry]);
      this.$emit("new-entry-added");
    },
    stringifyDate(date) {
      return stringifyDate(new Date(date), DATE_FORMAT.DATE);
    }
  },
};
</script>
