<template>
  <b-modal
    id="force-finish-appointment"
    no-close-on-backdrop
    title="Zapisz i zamknij"
    title-tag="h3"
    @hide="onHide"
    @show="$emit('show')"
  >
    <b-form-group>
      <template #label>
        Aby zakończyć podaj przybliżoną długość czasu pracy z pacjentem (w&nbsp;minutach):
      </template>
      <b-form-input
        v-model.lazy="scheduledDuration"
        :number="true"
        :state="state('timeSpent')"
        :min="1"
        :max="480"
        type="number"
      />
      <b-form-checkbox
        v-if="scheduledDuration < 15"
        v-model="scheduledDurationConfirm"
      >
        Potwierdzam że czas pracy był faktycznie mniejszy niż 15 minut.
      </b-form-checkbox>
      <error-message
        :errors="errors"
        field="timeSpent"
      />
    </b-form-group>
    <div
      v-for="(doc, index) in finishedDocuments"
      :key="doc.appointmentDocumentId"
      :class="{'mt-2 pt-2': finishedDocuments.length > 0, 'border-top': index > 0}"
      class="form-row mb-3"
    >
      <div class="col-sm-8">
        <b-input
          :value="workerName(doc.mainWorker)"
          readonly
        />
      </div>
      <div class="col-sm-4">
        <b-input
          :value="doc.timeSpent"
          readonly
        />
      </div>
    </div>
    <b-form-group label="Komórka organizacyjna:">
      <branch-select
        v-model="branchId"
        :check-if-any-branch-exists="true"
        :state="state('branchId')"
      />
      <error-message
        :errors="errors"
        field="branchId"
      />
    </b-form-group>
    <template #modal-footer>
      <button
        type="button"
        class="btn btn-secondary"
        @click="hideModal"
      >
        Anuluj
      </button>
      <button
        :disabled="loading || (!scheduledDurationConfirm && scheduledDuration < 15)"
        type="submit"
        class="btn btn-primary"
        @click="confirm"
      >
        <i
          :class="loading ? 'fa-spin fa-spinner' : 'fa-check'"
          class="fa"
        />
        Zapisz
      </button>
    </template>
  </b-modal>
</template>
<script>
import {errorsMixin} from "../../../mixins/errorsMixin.js";
import ErrorMessage from "../../Form/ErrorMessage";
import BranchSelect from "@/components/Branch/BranchSelect";

export default {
  name: "FinishAppointmentModal",
  components: {BranchSelect, ErrorMessage},
  mixins: [errorsMixin],
  props: {
    value: {type: Object, default: ()=>({})},
    documentErrors: {type: Array, default: ()=>[]},
    loading: {type: Boolean, default: false},
    finishedDocuments: {type: Array, default: ()=>[]},
  },
  data() {
    return {
      scheduledDuration: this.value?.timeSpent,
      branchId: this.value?.branchId,
      scheduledDurationConfirm: false,
    };
  },
  watch: {
    documentErrors(val) {
      this.errors = val;
    },
  },
  destroyed() {
    this.onHide();
  },
  methods: {
    confirm() {
      this.$emit("input", {
        timeSpent: this.scheduledDuration,
        branchId: this.branchId,
      });
      this.$emit("forceFinishAppointment");
    },
    hideModal() {
      this.$bvModal.hide("force-finish-appointment");
    },
    onHide() {
      this.$emit("hide");
      this.errors = [];
      this.scheduledDuration = this.value.timeSpent;
      this.branchId = this.value.branchId;
      this.scheduledDurationConfirm = false;
    },
    workerName({name, surname}) {
      return `${name} ${surname}`;
    },
  }
}
</script>
