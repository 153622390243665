












import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";
import {InsurerType} from "../../types/Zla";
import ObjectSelect from "../Form/Select/ObjectSelect.vue";

interface Option {
  value: InsurerType;
  name: string;
}

@Component({
  name: "InsurerTypeSelect",
  components: {ObjectSelect},
})
export default class InsurerTypeSelect extends Vue {
  @Prop({type: Boolean, default: false}) readonly clearable!: boolean;
  @Prop({type: Boolean, default: false}) readonly disabled!: boolean;
  @Prop({type: String, default: null}) readonly placeholder!: string;
  @Prop({type: Boolean, default: null}) readonly state!: boolean;
  @Prop({type: String, default: null}) readonly value!: InsurerType;

  private get selected(): Option|null {
    return this.value && this.options.find(option => option.value === this.value) || null;
  }

  get options(): Option[] {
    return [
      // Types used with insured person PESEL
      {
        value: InsurerType.ZUS,
        name: "ZUS"
      },
      {
        value: InsurerType.KRUS,
        name: "KRUS"
      },
      // Types used with insured person passport number
      // {
      //   value: InsurerType.OTHER_PL,
      //   name: "Inne w Polse"
      // },
      // {
      //   value: InsurerType.OTHER_ABROAD,
      //   name: "W innym państwie"
      // },
    ]
  }

  private update(option: Option):void {
    this.$emit("input", option?.value);
  }
}
