
























































































import {Component, Mixins, Prop, Ref} from "vue-property-decorator";
import type {ActiveSubstance, Drug} from "../../types/Prescription";
import read from "../../rest/read";
import VueMultiselect from "vue-multiselect/src/Multiselect.vue";
import processResponseException from "../../utils/errors/processResponseException";
import ErrorsMixin from "../../mixins/ErrorsMixin";
import ErrorMessage from "../Form/ErrorMessage.vue";

@Component({
  name: "MedicationSelect",
  components: {ErrorMessage, VueMultiselect},
  directives: {
    tabindex: {
      inserted(el) {
        el.setAttribute("tabindex", "0");
      },
    },
  },
})
export default class MedicationSelect extends Mixins(ErrorsMixin) {
  @Prop({type: Object, default: null}) readonly value!: Drug;
  @Prop({type: Boolean, default: false}) readonly disabled!: boolean;
  @Prop({type: String, default: "Wybierz lek"}) readonly placeholder!: string;
  @Prop({type: String, default: null}) readonly patient!: string;

  @Ref("multiselect") readonly multiselect!: VueMultiselect;

  private options: Array<Drug> =  [];
  private loading: boolean =  false;
  private typingTimeout?: number;
  private isTop10: boolean = true;

  async mounted(): Promise<void> {
    await this.loadOptions();
  }

  private update(drug: Drug|null): void {
    this.$emit("input", drug);
  }

  private onSelect(drug: Drug|null): void {
    this.$emit("select", drug);
    this.options = [];
  }

  private onBlur(): void {
    this.$emit("blur");
  }

  public activate(): void {
    this.multiselect.activate();
  }

  public deactivate(): void {
    this.multiselect.deactivate();
  }

  private async loadOptions(phrase: string = ""): Promise<void> {
    this.errors = [];
    this.isTop10 = true;
    clearTimeout(this.typingTimeout);
    if ("" !== phrase && 3 > phrase.length) {
      return;
    }
    if("" !== phrase){
      this.isTop10 = false;
    }
    const params = {
      name: phrase.trim(),
      patientId: this.patient
    }
    this.typingTimeout = window.setTimeout(async () => {
      this.loading = true;
      try {
        const {items} = await read("/api/drugs", params)
        this.options = items ? items : [];
      } catch(e) {
        this.errors = processResponseException(e);
      }
      this.loading = false;
    }, 1000);
  }

  private cancelLoading(): void {
    this.loading = false;
    clearTimeout(this.typingTimeout);
  }

  private getSubstanceData(substance: ActiveSubstance): string {
    const {name, unit, value, denominator} = substance;
    const core = `${name} ${value} ${unit}`;
    return denominator
      ? `${core} / ${denominator.value} ${denominator.unit}`
      : `${core}`;
  }

  private withInternationalName({name, internationalName}: Drug): string {
    return `${name} (${internationalName})`;
  }
}
