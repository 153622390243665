<template>
  <treatment-type-list
    :treatment-types="treatmentTypes"
  />
</template>

<script>
import TreatmentTypeList from "../../components/SetupPage/TreatmentTypeList";
import read from "../../rest/read";

export default {
  components: {TreatmentTypeList},
  data() {
    return {
      treatmentTypes: [],
    };
  },
  async mounted() {
    const {items} = await read("/api/treatment-types", {active: true});
    this.treatmentTypes = items;
  }
}
</script>
