













import Vue from "vue";
import Component from "vue-class-component";
import {Prop} from "vue-property-decorator";

type AppointmentStatusName = "created" | "checkedin" | "opened" | "finished" | "canceled";

@Component
export default class AppointmentStatus extends Vue {


  public static getNames(): Array<AppointmentStatusName> {
    return [
      "created",
      "checkedin",
      "opened",
      "finished",
      "canceled",
    ];
  }

  public static getLabel(name: AppointmentStatusName): string {
    switch (name) {
      case "created":
        return "Utworzona";
      case "checkedin":
        return "Zarejestrowana";
      case "opened":
        return "Rozpoczęta";
      case "finished":
        return "Zakończona";
      case "canceled":
        return "Odwołana";
      default:
        throw new Error(`Unknown appointment status '${name}'`);
    }
  }

  @Prop({type: String, required: true}) readonly name!: AppointmentStatusName;
  @Prop({type: String, default: "span"}) readonly component!: string;
  @Prop({type: Boolean, default: false}) readonly badge!: boolean;

  get label(): string {
    return AppointmentStatus.getLabel(this.name);
  }

  get variant(): string {
    switch (this.name) {
      case "created":
        return "secondary";
      case "checkedin":
        return "primary";
      case "opened":
        return "success";
      case "finished":
        return "dark";
      case "canceled":
        return "light";
      default:
        throw new Error(`Unknown appointment status '${name}'`);
    }
  }
}
