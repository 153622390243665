export const specialistRoles: string[] = [
  "ROLE_SPECIALIST",
  "ROLE_SPECIALIST_MANAGER",
  "ROLE_SPECIALIST_OF_SUPPORT_NETWORK",
  "ROLE_SPECIALIST_NON_MEDICAL",
  "ROLE_SPECIALIST_COORDINATOR",
  "ROLE_SPECIALIST_FOUNDATION",
  "ROLE_SPECIALIST_OPS",
  "ROLE_EXTERNAL_SPECIALIST",
]

export const coordinatorRoles = [
  "ROLE_SPECIALIST",
  "ROLE_SPECIALIST_MANAGER",
  "ROLE_ADMIN",
  "ROLE_MANAGER"
];
