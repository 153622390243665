<template>
  <div>
    <address-form
      v-model="formData"
      :disabled="loading"
      :errors="getSubErrors('address')"
      :horizontal="true"
      :visible-country="false"
      :in-modal="true"
    />
    <button
      v-if="!equalAddresses"
      class="btn btn-success m-b-20 ml-auto mr-3 d-block"
      @click="getAddressFromLiving"
    >
      <i class="fa fa-home" />
      Pobierz dane z adresu zamieszkania
    </button>
    <error-message :errors="errors" />
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-secondary"
        @click="cancel"
      >
        Anuluj
      </button>
      <button
        :disabled="loading"
        class="btn btn-primary"
        @click="onSubmit"
      >
        <i
          :class="loading ? 'fa-spin fa-spinner' : 'fa-check'"
          class="fa"
        />
        Zapisz
      </button>
    </div>
  </div>
</template>

<script>
import update from "../../rest/update";
import read from "../../rest/read";
import AddressForm from "../Address/AddressForm";
import isEqual from "lodash/isEqual";
import processResponseException from "../../utils/errors/processResponseException";
import ErrorMessage from "../Form/ErrorMessage";
import subErrors from "../../utils/errors/subErrors";

export default {
  components: {
    ErrorMessage,
    AddressForm,
  },
  props: {
    patientId: {type: String, required: true},
    livingAddress: {type: Object, default: null},
    correspondenceAddress: {type: Object, default: null},
  },
  data() {
    return {
      address: this.correspondenceAddress ? this.correspondenceAddress : {},
      formData: {},
      errors: [],
      equalAddresses: false,
      loading: false,
    };
  },
  watch: {
    correspondenceAddress() {
      this.address = {...this.correspondenceAddress};
      this.formData = {...this.address};
      this.equalAddresses = this.isAddressesEqual();
    },
    async patientId() {
      if (!this.correspondenceAddress) {
        await this.getCorrespondenceAddress();
        this.formData = {...this.address};
        this.equalAddresses = this.isAddressesEqual();
      }
    },
  },
  async mounted() {
    if (!this.correspondenceAddress) {
      await this.getCorrespondenceAddress();
    }
    this.formData = {...this.address};
    this.equalAddresses = this.isAddressesEqual();
  },
  methods: {
    async getCorrespondenceAddress() {
      this.loading = true;
      try {
        this.address = await read(`/api/patients/${this.patientId}/correspondence_address`);
      } catch(exception) {
        this.errors = processResponseException(exception);
      }
      this.loading = false;
    },
    getSubErrors(field) {
      return subErrors(this.errors, field);
    },
    async onSubmit() {
      this.loading = true;
      try {
        await update(`/api/patients/${this.patientId}/correspondence_address`, {address: this.formData});
        this.address = await read(`/api/patients/${this.patientId}/correspondence_address`);
        this.$emit("updateCorrespondenceAddress", this.address);
        this.cancel();
      } catch (exception) {
        this.errors = processResponseException(exception);
      }
      this.loading = false;
    },
    cancel() {
      this.resetData();
      this.$emit("cancel");
    },
    resetData(){
      this.formData = {...this.address};
      this.errors = [];
      this.equalAddresses = this.isAddressesEqual();
    },
    getAddressFromLiving(){
      this.formData = {...this.livingAddress};
      this.equalAddresses = true;
    },
    isAddressesEqual(){
      return isEqual(this.livingAddress, this.address);
    },
  },
};
</script>
