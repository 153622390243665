<template>
  <div>
    <b-btn
      v-b-modal="modalId"
      class="btn btn-success btn-sm"
    >
      Zmień status zgody na przetwarzanie danych osobowych
    </b-btn>
    <b-modal
      :id="modalId"
      title="Zmień status zgody na przetwarzanie danych osobowych"
      class="text-center"
      ok-title="Tak, chcę zmienić status zgody"
      ok-variant="danger"
      cancel-title="Anuluj"
      @ok="changePermissionForProcessingPersonalDataStatus"
    >
      Aktualnie
      <b>
        {{ patient.displayName }}
        <span
          v-if="permissionForProcessingPersonalDataStatus"
          class="text-success"
        >wyraża zgodę</span>
        <span
          v-else
          class="text-danger"
        >nie wyraża zgody</span>
      </b>
      na przetwarzanie danych osobowych.
    </b-modal>
  </div>
</template>

<script>
import update from "@/rest/update";

export default {
  props: {
    patient: {type: Object, required: true},
    permissionForProcessingPersonalDataStatus: {type: Boolean, required: true},
  },
  computed: {
    modalId() {
      return `changePermission-${this.patient.patientId}`;
    }
  },
  methods: {
    async changePermissionForProcessingPersonalDataStatus() {
      await update(`/api/patient/${this.patient.patientId}/permission-for-processing-personal-data-status`, {});
      this.$emit("permissionForProcessingPersonalDataStatusChanged");
    }
  }
}
</script>
