
























































import ErrorMessage from "../../Form/ErrorMessage.vue";
import {Error} from "../../../rest"
import ResizableTextarea from "../../Form/Textarea/ResizableTextarea.vue";
import {Component, Prop} from "vue-property-decorator";
import type {ChildIpzItemFormData} from "../../../types/ChildIpzs";
import Vue from "vue";
import TextareaCounter from "../../Form/Textarea/TextareaCounter.vue";
import ChildIpzSignaturesCheckbox from "./ChildIpzSignaturesCheckbox.vue";
import striptags from "striptags";

@Component({
  name: "ChildIpzForm",
  components: {
    ChildIpzSignaturesCheckbox,
    TextareaCounter,
    ResizableTextarea,
    ErrorMessage,
  }
})

export default class ChildIpzForm extends Vue {
  @Prop({type: Object, default: ()=>({})}) readonly value!: ChildIpzItemFormData;
  @Prop({type: String, required: true}) readonly ipzId!: string;
  @Prop({type: Boolean, required: true}) readonly readonly!: boolean;
  @Prop({type: Boolean, required: true}) readonly loading!: boolean;
  @Prop({type: Array, default: ()=>[]}) readonly propErrors!: Array<Error>;

  update($event: string, field: string, trim: boolean = false): void {
    const newValue: ChildIpzItemFormData = {
      ...this.value,
      [field]: trim ? this.trimText($event) : $event,
    };
    this.$emit("input", newValue);
  }

  trimText(text:string): string|null {
    return text ? text.trim() : null;
  }

  state(field: string): boolean|null {
    return this.propErrors.find((error) => error.field === field) ? false : null;
  }

  onPaste(event: ClipboardEvent):void {
    if (!event.clipboardData) {
      return;
    }
    let paste = (event.clipboardData).getData("text/html");

    event.preventDefault();

    let parser = new DOMParser();

    const stripped = striptags(paste, ["div", "i", "b", "ul", "ol", "li", "span", "style", "br"]);
    const doc = parser.parseFromString(stripped, "text/html");

    let styles = doc.querySelectorAll("style");
    styles.forEach(style => style.parentNode && style.parentNode.removeChild(style));

    const all = doc.querySelectorAll("*");
    let i = all.length;
    while (i--) {
      all[i].removeAttribute("style");
      all[i].removeAttribute("class");
    }

    setTimeout(function () {
      document.execCommand("insertHtml", false, doc.body.innerHTML);
    }, 10);
  }
}
