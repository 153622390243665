import Vue from "vue";
import App from "./App.vue";
import "./styles/style.scss";
import axios from "axios";
import Vuex from "vuex";
import BootstrapVue from "bootstrap-vue";
import {createStore} from "./store/store";
import {initTranslations} from "./i18n";
import Fragment from "./components/Fragment";
import eventBus, {EVENT} from "./eventBus";
import initSentry from "./sentry";
import registerServiceWorker from "./sw/registerServiceWorker";
import router from "./router";
import {isProduction} from "./isProduction";
import {parseSearchParams} from "./utils/searchParams";
// @ts-ignore: no @types/vue-wysiwyg
import wysiwyg from "vue-wysiwyg";

Vue.use(BootstrapVue);
Vue.use(wysiwyg, {
  hideModules: {
    underline: true,
    justifyLeft: true,
    justifyCenter: true,
    justifyRight: true,
    headings: true,
    link: true,
    code: true,
    image: true,
    table: true,
    removeFormat: true,
    separator: true,
  },
});
Vue.use(Vuex);
Vue.component("Fragment", Fragment);
initTranslations("Clinic");

export const store = createStore();

const params = parseSearchParams();
// @ts-ignore
const token =  params.pdfToken;
if(token){
  store.dispatch("session/authenticate", token);
  axios.defaults.headers.common["x-authorization"] = `Bearer ${  token}`;
}

axios.defaults.headers.common.Accept = "application/json";
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
axios.interceptors.response.use((response) => response, (error) => {
  if (null != error.response && 401 === error.response.status) {
    store.dispatch("session/clear");
  }

  throw error;
});
axios.interceptors.request.use((request) => {
  if (
    request.method !== "get"
    && request.method !== "options"
    && request.url !== "/api/accesses"
  ) {
    eventBus.emit(EVENT.SAVE);
  }
  return request;
});

export const serviceWorkerRegistration = registerServiceWorker();

serviceWorkerRegistration.then((res) => {
  if(isProduction){
    setUpdateTimeout(res);
  }
});

const checkUpdate = (registration: any) => {
  registration.update();
  setUpdateTimeout(registration);
};

const setUpdateTimeout = (registration: any)  => {
  setTimeout(() => {
    checkUpdate(registration);
  }, 30000); // 30 seconds
};

if(isProduction){
  initSentry();
}

// tslint:disable-next-line:no-unused-expression
new Vue({
  router,
  store,
  created() {
    if (this.$store.state.session.isAuthenticated) {
      this.$store.dispatch("currentUser/loadCurrentUser");
    }
    if (this.$store.state.currentUser.user.establishmentId && !this.$store.state.clinicParameters.parameters) {
      this.$store.dispatch("clinicParameters/loadClinicParameters");
    }
  },
  render: h => h(App),
}).$mount("#app");
eventBus.on(EVENT.SAVE, () => store.dispatch("security/refresh"));
