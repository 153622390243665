// Enum types
export enum InsurerType {
  ZUS = "ZUS",
  KRUS = "KRUS",
  OTHER_PL = "INNE_W_POLSCE",
  OTHER_ABROAD = "W_INNYM_PANSTWIE",
}

export enum PlaceOfInsuranceType {
  ZUS = 1,
  KRUS = 2,
  INNE_W_POLSCE = 3,
  W_INNYM_PANSTWIE = 4,
}

export enum PersonOfCareType {
  CHILD = 1,
  PARENT = 2,
  OTHER = 3,
}

export enum ICD10Category {
  A = "A",
  B = "B",
  C = "C",
  D = "D",
  E = "E",
}

export enum MedicalIndicationType {
  STAY_AT_HOME= 1,
  CAN_LEAVE_HOME = 2,
}

export enum PayerIdStringType {
  NIP = "NIP",
  PESEL = "PESEL",
}

export enum PayerIdType {
  NIP = 1,
  PESEL = 2,
  PASSPORT = 3,
}

// Fetched data

export interface Patient {
  displayName: string;
  name: string;
  pesel: string;
  patientId: string;
  surname: string;
}

export interface InsuredPerson {
  name: string;
  surname: string;
  birthDate: string; //date string
  address: InsuredPersonAddress[];
}

export interface InsuredPersonAddress {
  zipCode: string;
  cityName: string;
  streetName: string;
  streetNo: string;
}

export interface Payer {
  nip?: string;
  pesel?: string;
  companyName: string;
  name: string;
  surname: string;
}

// Form data

export interface InsuredPersonFormData {
  name: string;
  surname: string;
  pesel: string;
  birthDate: string,
  insurer: InsurerType | null,
}

export interface PersonOfCareFormData {
  type: PersonOfCareType | null;
  birthDate: Date | null;
}

export interface ICD10CodeOption {
  value: string;
  name?: string;
  description?: string;
}

export interface ICD10FormData {
  icd10code: ICD10CodeOption | null;
  icd10categories: ICD10Category[];
}

export interface ZlaDurationFormData {
  from: Date;
  to: Date;
}

// Submitted data
export interface InsuredPersonData {
  name: string;
  surname: string;
  pesel: string;
  placeOfInsurance: PlaceOfInsuranceType | null;
}

export interface PersonOfCare {
  type: PersonOfCareType | null;
  birthDate: string | null;
}

export interface ICD10Data {
  icd10code: string | null;
  icd10categories: ICD10Category[] | null;
}

export interface ZlaDuration {
  from: string;
  to: string;
}

export interface PayerData {
  idType: PayerIdType;
  payerId: string;
}

export interface ZlaData {
  patientId: string;
  password: string;
  documentId: string;
  appointmentDocumentId: string;
  insured: InsuredPersonData | null;
  address: InsuredPersonAddress | null;
  duration: ZlaDuration;
  medicalIndication: MedicalIndicationType;
  icd10: ICD10Data;
  personOfCare: null | PersonOfCare;
  retroactiveJustification?: string;
  stationaryZOZstay?: boolean;
  doNotInformPayer: boolean;
  payerData: PayerData | null;
}

export interface CancelZlaReason {
  code: CancelZlaCode|null;
  description: string;
}

export interface CancelZlaCode {
  value: CancelZlaCodeType;
  name: string;
  description: string;
}

export enum CancelZlaCodeType {
  U = "U",
  P = "P",
  N = "N",
  M = "M",
  I = "I",
  B = "B",
  A = "A",
  E = "E",
  X = "X",
}

export interface CancelZlaData {
  password: string;
  sickLeaveId: string; // uuid
  reason: {
    code: CancelZlaCodeType;
    description: string;
  };
}
