
























































import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";
import {
  InternalReferralDirection,
  InternalReferralPageQuery,
  InternalReferralStatus,
  Patient,
  Worker
} from "../../types/InternalReferral";
import WorkerSelect from "../Worker/WorkerSelect.vue";
import PatientSelect from "../Patient/PatientSelect.vue";
import InternalReferralStatusSelect from "./InternalReferralStatusSelect.vue";
import InternalReferralDirectionSelect from "./InternalReferralDirectionSelect.vue";
import {isEmpty, isEqual} from "lodash";
import {resetPageQuery, updatePageQuery} from "../../utils/pageUrl/handlePageQuery";

interface Filters {
  direction: InternalReferralDirection|null;
  status: InternalReferralStatus|null;
  worker: Worker|null;
  patient: Patient|null;
}

const filtersInitialState = {
  direction: null,
  status: null,
  worker: null,
  patient: null,
}

@Component({
  name: "InternalReferralsFilters",
  components: {InternalReferralDirectionSelect, InternalReferralStatusSelect, WorkerSelect, PatientSelect}
})
export default class InternalReferralsFilters extends Vue {
  @Prop({type: String, default: null}) readonly direction!: InternalReferralDirection;
  @Prop({type: String, default: null}) readonly status!: InternalReferralStatus;
  @Prop({type: String, default: null}) readonly workerId!: Worker["workerId"];
  @Prop({type: String, default: null}) readonly patientId!: Patient["patientId"];

  private filters: Filters = {
    direction: this.direction,
    status: this.status,
    worker: this.workerId ? {workerId: this.workerId} : null,
    patient: this.patientId ? {patientId: this.patientId} : null,
  }

  private async clearFilters(): Promise<void> {
    this.filters = {...filtersInitialState};
    if (isEmpty(this.$route.query)) {
      this.$emit("refresh");
    } else {
      await resetPageQuery();
    }
  }

  private async submit(): Promise<void> {
    const query: InternalReferralPageQuery = {
      ...this.$route.query,
      direction: this.filters?.direction || undefined,
      patientId: this.filters?.patient?.patientId,
      status: this.filters?.status || undefined,
      workerId: this.filters?.worker?.workerId,
    }
    if (isEqual(query, this.$route.query)) {
      this.$emit("refresh");
    } else {
      await updatePageQuery({
        ...query,
        page: 1
      });
    }
  }
}
