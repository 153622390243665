<template>
  <form>
    <div class="form-row">
      <div class="col-md-4">
        <label 
          for="education-level-select" 
          class="col-form-label"
        >Wykształcenie</label>
        <education-level-select
          id="education-level-select"
          :state="state('educationLevel')"
          :value="value.educationLevel"
          @input="update($event, 'educationLevel')"
        />
        <error-message
          :errors="errors"
          field="educationLevel"
        />
      </div>
      <div class="col-md-4">
        <label
          for="marital-status-select"
          class="col-form-label"
        >Stan cywilny</label>
        <marital-status-select
          id="marital-status-select"
          :state="state('maritalStatus')"
          :value="value.maritalStatus"
          @input="update($event, 'maritalStatus')"
        />
        <error-message
          :errors="errors"
          field="maritalStatus"
        />
      </div>
      <div class="col-md-4">
        <label
          for="disability-level-select"
          class="col-form-label"
        >Poziom niepełnosprawności</label>
        <disability-level-select
          id="disability-level-select"
          :state="state('disabilityLevel')"
          :value="value.disabilityLevel"
          @input="update($event, 'disabilityLevel')"
        />
        <error-message
          :errors="errors"
          field="disabilityLevel"
        />
      </div>
    </div>
  </form>
</template>
<script>
import ErrorMessage from "../Form/ErrorMessage";
import EducationLevelSelect from "../Form/Select/EducationLevelSelect";
import MaritalStatusSelect from "../Form/Select/MaritalStatusSelect";
import DisabilityLevelSelect from "../Form/Select/DisabilityLevelSelect";

export default {
  components: {DisabilityLevelSelect, MaritalStatusSelect, EducationLevelSelect, ErrorMessage},
  props: {
    value: {type: Object, required: true},
    errors: {type: Array, default: () => []},
  },
  methods: {
    state(field) {
      return this.errors.find((error) => error.field === field) ? false : null;
    },
    clearError(field) {
      this.$emit("clearError", field);
    },
    update(event, field) {
      this.$emit("input", {...this.value, [field]: event});
    }
  }
}
</script>
