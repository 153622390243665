<template>
  <b-modal
    id="delete-attachment-modal"
    title="Usuń załącznik"
    hide-footer
    no-close-on-backdrop
    title-tag="h3"
    @hide="resetModalData"
  >
    <error-message :errors="errors" />
    <form @submit.prevent="deleteAttachment">
      <form-row
        :errors="errors"
        label="Uwagi"
        field="deleteReason"
      >
        <resizable-textarea
          id="cancelComment-id"
          v-model="deleteReason"
          :state="state('deleteReason')"
          rows="3"
        />
      </form-row>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          @click="closeModal"
        >
          Zamknij
        </button>
        <button
          :disabled="loading"
          type="submit"
          class="btn btn-primary"
        >
          <i
            v-show="loading"
            class="fas fa-spin fa-spinner"
          />
          Usuń załącznik
        </button>
      </div>
    </form>
  </b-modal>
</template>

<script>
import FormRow from "../../../Form/FormRow";
import {errorsMixin} from "../../../../mixins/errorsMixin.js";
import remove from "../../../../rest/remove";
import ErrorMessage from "../../../Form/ErrorMessage";
import processResponseException from "../../../../utils/errors/processResponseException";
import ResizableTextarea from "../../../Form/Textarea/ResizableTextarea";

export default {
  components: {
    ResizableTextarea,
    ErrorMessage,
    FormRow
  },
  mixins: [errorsMixin],
  props: {
    attachmentId: {type: String, default: null},
  },
  data() {
    return {
      deleteReason: "",
      loading: false
    }
  },
  methods: {
    async deleteAttachment() {
      this.loading = true;
      const deleteUrl = `/api/attachments/${this.attachmentId}`;
      try {
        await remove(deleteUrl, {
          deleteReason: this.deleteReason.trim()
        });
        this.$emit("attachmentDeleted", this.attachmentId);
        this.closeModal();
      } catch (exception) {
        this.errors = processResponseException(exception);
      }
      this.loading = false;
    },
    closeModal() {
      this.$bvModal.hide("delete-attachment-modal");
    },
    resetModalData() {
      this.errors = [];
      this.deleteReason = "";
    }
  },
};
</script>
