export const deferredMixin = {
  props: {
    deferred: {type: Boolean, default: false},
  },
  data() {
    return {
      deferredNote: "",
    };
  },
};
