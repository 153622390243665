

























































import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";
import {Error} from "../../rest";
import ErrorMessage from "../Form/ErrorMessage.vue";
import read from "../../rest/read";
import type {
  InternalReferral,
  InternalReferralFormData,
  InternalReferralSubmitData
} from "../../types/InternalReferral";
import InternalReferralForm from "./InternalReferralForm.vue";
import processResponseException from "../../utils/errors/processResponseException";
import update from "../../rest/update";
import IsGranted from "../IsGranted.vue";
import modify from "../../rest/modify";
import LoadingMask from "../Loading/LoadingMask.vue";
import {map} from "lodash";

@Component({
  name: "UpdateInternalReferralModal",
  components: {LoadingMask, IsGranted, InternalReferralForm, ErrorMessage}
})
export default class UpdateInternalReferralModal extends Vue {
  @Prop({type: String, required: true}) readonly internalReferralId!: string;

  private loading: boolean = false;
  private saving: boolean = false;
  public errors: Error[] = [];
  private data: InternalReferralFormData = {};
  private files: File[] = [];

  private get modalId(): string {
    return `update-internal-referral-${this.internalReferralId}`;
  }

  private hideModal(): void {
    this.$bvModal.hide(this.modalId);
  }

  private async fetchData(): Promise<void> {
    this.loading = true;
    this.errors = [];
    try {
      const {
        patientId,
        referringEstablishment,
        receivingEstablishment,
        description,
        rejectReason,
        author,
        files
      } = await read<InternalReferral>(`/api/internal-referrals/${this.internalReferralId}`);
      this.files = files;
      this.data = {
        patient: patientId,
        referringEstablishment: referringEstablishment,
        receivingEstablishment: receivingEstablishment,
        description,
        rejectReason,
        author,
        files: files.map(file => file.fileId)
      }
    } catch (e) {
      this.errors = processResponseException(e);
    }
    this.loading = false;
  }

  private async updateReferral(): Promise<void> {
    const data = {
      internalReferralId: this.internalReferralId,
      patientId: this.isString(this.data?.patient)
        ? this.data.patient
        : this.data?.patient?.patientId,
      referringEstablishment: this.isString(this.data?.referringEstablishment)
        ? this.data.referringEstablishment
        : this.data?.referringEstablishment?.branchId,
      receivingEstablishment: this.isString(this.data?.receivingEstablishment)
        ? this.data.receivingEstablishment
        : this.data?.receivingEstablishment?.branchId,
      description: this.data?.description || undefined,
      files: this.data.files
    };
    await update<InternalReferralSubmitData>(`/api/internal-referrals/${this.internalReferralId}`, data);
  }

  isString(value: any): boolean {
    return "string" === typeof value;
  }

  private async save(): Promise<void> {
    this.saving = true;
    this.errors = [];
    try {
      await this.updateReferral();
      this.hideModal();
      this.$emit("updated");
    } catch (e) {
      this.errors = processResponseException(e);
    }
    this.saving = false;
  }

  private async send(): Promise<void> {
    this.saving = true;
    this.errors = [];
    try {
      await this.updateReferral();
      await modify(`/api/internal-referrals/${this.internalReferralId}/send`, {});
      this.hideModal();
      this.$emit("updated");
    } catch (e) {
      this.errors = processResponseException(e);
    }
    this.saving = false;
  }

  private resetData(): void {
    this.data = {};
    this.errors = [];
    this.saving = false;
  }
}
