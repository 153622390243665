import {getInitialState} from "./session/initialState";
import {actions} from "./session/actions";
import {mutations} from "./session/mutations";
import {getters} from "./session/getters";
import {Module} from "vuex";
import {SessionState} from "./session/types";
import {RootState} from "../types";

export const IS_AUTHENTICATED = "auxilio.authenticated";
export const TOKEN = "auxilio.token";
export const storage = localStorage;

const session: Module<SessionState, RootState> = {
  namespaced: true,
  state: getInitialState(),
  actions,
  mutations,
  getters,
};

export default session;
