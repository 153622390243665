<template>
  <b-checkbox-group
    :checked="selected"
    :state="state"
    buttons
    class="d-flex flex-wrap justify-content-start"
    @input="update"
  >
    <b-form-checkbox
      v-for="room in branchRooms"
      :key="room.roomId"
      :value="room"
      :button-variant="room.isOccupied ? 'outline-danger' :'outline-success'"
      class="rounded ungroup-button wrap-text cursor-pointer"
    >
      {{ room.number }} (piętro&nbsp;{{ room.floor }})<br>
      <small>{{ room.isOccupied ? 'zajęty' :'wolny' }}</small>
    </b-form-checkbox>
    <div
      v-if="!branchRooms.length"
      class="card-box disabled-data w-100"
    >
      Brak zdefiniowanych pokoi w jednostce
    </div>
  </b-checkbox-group>
</template>

<script>
import read from "../../rest/read";

export default {
  props: {
    value: {type: Object, default: null},
    state: {type: Boolean, default: null},
    startDate: {type: Date, required: true},
    endDate: {type: Date, required: true},
    branchId: {type: String, default: null},
  },
  data() {
    return {
      rooms: [],
      optionsLoaded: false,
      selected: [],
    };
  },
  computed: {
    branchRooms() {
      return this.rooms.filter(room => room.branchId === this.branchId);
    },
  },
  watch: {
    value() {
      if (!this.optionsLoaded) {
        return;
      }
      this.selected = !this.value ? [] : [this.branchRooms.find(room => room.roomId === this.value.roomId)];
    },
    branchId() {
      this.getRoomsList();
    },
    startDate() {
      this.getRoomsList();
    },
    endDate() {
      this.getRoomsList();
    },
    branchRooms() {
      this.$emit("roomsLoaded", this.branchRooms.length !== 0)
    },
  },
  async mounted() {
    await this.getRoomsList();
    this.selected = !this.value ? [] : [this.branchRooms.find(room => room.roomId === this.value.roomId)];
    this.optionsLoaded = true;
  },
  methods: {
    async getRoomsList() {
      if (!this.branchId) {
        this.rooms = []
        return
      }

      const {items} = await read("/api/rooms/availability", {
        startDate: this.startDate,
        endDate: this.endDate,
      });
      this.rooms = items;
    },
    update(selectedValue) {
      const emmitedValue = (selectedValue.length === 0)
        ? null
        : (selectedValue.length === 2)
          ? selectedValue[1]
          : selectedValue[0];
      this.$emit("input", emmitedValue);
    },
  },
}
</script>

<style scoped lang="scss">
  .ungroup-button {
    flex: 0 1 153px;
    margin: 0.25rem 0.25rem 0 0!important;
    line-height: 1rem;
  }
  .wrap-text {
    white-space: normal;
  }
</style>
