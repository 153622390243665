<template>
  <div>
    <b-form-group label="Kategoria zdarzenia">
      <b-form-radio-group
        id="notification_category_radio_1"
        :options="options"
        :checked="input"
        name="category"
        required
        stacked
        @input="(v) => $emit('input', v)"
      />
    </b-form-group>
  </div>
</template>

<script>
import t from "../../i18n";

export default {
  model: {
    prop: "input",
  },
  props: {
    input: {required: true, type: String},
  },
  computed: {
    options() {
      return ["success", "info", "warning", "danger"].map((value) => ({
        value,
        text: t(value)
      }));
    }
  }
};
</script>
