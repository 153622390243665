<template>
  <form
    class="form-row"
    @submit.prevent="submit"
  >
    <b-form-group
      class="col-lg-6 col-xl-3"
      label="Data początkowa"
    >
      <date-picker
        v-model="formData.startDate"
        @input="update"
      />
    </b-form-group>
    <b-form-group
      class="col-lg-6 col-xl-3"
      label="Data końcowa"
    >
      <date-picker
        v-model="formData.endDate"
        clearable
        @input="update"
      />
    </b-form-group>
    <b-form-group
      class="col-lg-6 col-xl-3"
      label="Typ usługi"
    >
      <treatment-type-select
        v-model="formData.treatmentType"
        @input="update"
      />
    </b-form-group>
    <b-form-group
      label="Pacjent"
      class="col-lg-6 col-xl-3"
    >
      <patient-select
        disabled
        :value="patientId"
      />
    </b-form-group>
    <div class="col">
      <patient-options-checkbox
        v-model="formData.patientOptions"
        @input="update"
      />
    </div>
    <b-form-group
      class="col-lg-6 col-xl-3"
      label="Specjaliści"
    >
      <worker-select
        v-model="formData.workers"
        :disabled="!isManager"
        multiple
        @input="update"
      />
    </b-form-group>
    <b-form-group
      class="col-lg-6 col-xl-3"
      label="Plan wizyty"
    >
      <b-form-input
        v-model.trim="formData.plan"
        placeholder="Wpisz fragment tekstu planu"
        @input="update"
      />
    </b-form-group>
    <div class="col">
      <meeting-type-checkbox
        v-model="formData.remote"
        @input="update"
      />
    </div>
    <div class="col-12 text-right mb-2">
      <b-btn
        :disabled="loading"
        variant="primary"
        type="submit"
      >
        <i
          :class="loading ? 'fa-spin fa-spinner' : 'fa-search'"
          class="fas"
        />
        Szukaj
      </b-btn>
    </div>
  </form>
</template>

<script>
import TreatmentTypeSelect from "../../../TreatmentType/TreatmentTypeSelect";
import PatientSelect from "../../PatientSelect";
import PatientOptionsCheckbox from "../../../PatientPresenceOptions/PatientOptionsCheckbox";
import WorkerSelect from "../../../Worker/WorkerSelect";
import MeetingTypeCheckbox from "../../../Form/Checkbox/MeetingTypeCheckbox";
import DatePicker from "../../../../components/Form/DatePicker/DatePicker.vue";

export default {
  name: "AttachmentAppointmentFilters",
  components: {
    DatePicker,
    MeetingTypeCheckbox,
    WorkerSelect,
    PatientOptionsCheckbox,
    PatientSelect,
    TreatmentTypeSelect,
  },
  props: {
    patientId: {type: String, required: true},
    loading: {type: Boolean, default: false},
    isManager: {type: Boolean, default: false},
    value: {type: Object, default: ()=>({})},
  },
  data() {
    return {
      formData: this.value,
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.formData = val;
      }
    },
  },
  methods: {
    update() {
      this.$emit("input", this.formData);
    },
    submit() {
      this.$emit("submit");
    },
  },
}
</script>
