<template>
  <b-card no-body>
    <b-card-header class="d-flex flex-nowrap">
      <date-time
        :value="parseDate(item.date)"
        :format="`DD.MM YYYY`"
        class="appointment-date"
        component="div"
      />
      <div class="appointment-worker-treatment">
        <div
          v-for="worker in item.workers"
          :key="worker.workerId"
          class="d-flex flex-nowrap"
        >
          <div class="appointment-worker d-flex flex-nowrap mr-2">
            <i
              :class="{'text-muted': worker.workerId !== item.mainWorkerId}"
              class="fa fa-user-tie mt-1 mr-2"
            />
            <div>{{ worker.name }}</div>
          </div>
          <treatment-type-name
            :value="worker.reportedTreatmentType"
            class="appointment-treatment d-block"
          />
        </div>
      </div>
      <div class="appointment-visibility-status text-right">
        <span v-if="currentAppointmentId === item.appointmentId">
          Aktualna wizyta
        </span>
        <button
          v-else
          class="btn btn-sm btn-secondary m-1"
          @click="toggleVisibility"
        >
          {{ isDocumentVisible ? 'Zamknij dokument' : 'Pokaż dokument' }}
        </button>
      </div>
    </b-card-header>
    <b-collapse
      :id="`details-${currentAppointmentId}`"
      :visible="isDocumentVisible"
      @show="onCollapseShow"
    >
      <b-card-body>
        <loading-mask :loading="loading">
          <error-message :errors="errors" />
          <appointment-document-carousel
            v-if="appointmentDocuments.length"
            v-model="selectedDocumentIndex"
            :documents="appointmentDocuments"
          />
        </loading-mask>
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>
import DateTime from "../../DateTime";
import TreatmentTypeName from "../../TreatmentType/TreatmentTypeName";
import parseDate from "../../../utils/date/parseDate";
import read from "../../../rest/read";
import processResponseException from "../../../utils/errors/processResponseException";
import {errorsMixin} from "../../../mixins/errorsMixin.js";
import ErrorMessage from "../../Form/ErrorMessage";
import LoadingMask from "../../Loading/LoadingMask";
import AppointmentDocumentCarousel from "../AppointmentDocumentCarousel";

export default {
  name: "FinishedAppointmentPreview",
  components: {
    AppointmentDocumentCarousel,
    LoadingMask,
    ErrorMessage,
    DateTime,
    TreatmentTypeName,
  },
  mixins: [errorsMixin],
  props: {
    item: {type: Object, required: true},
    currentAppointmentId: {type: String, required: true},
    value: {type: String, default: null},
    patientId: {type: String, default: null},
  },
  data() {
    return {
      parseDate,
      loading: false,
      appointmentDocuments: [],
      selectedDocumentIndex: 0,
    };
  },
  computed: {
    isDocumentVisible() {
      return this.value === this.item.appointmentId;
    },
  },
  methods: {
    toggleVisibility() {
      const visible = this.value === this.item.appointmentId ? null : this.item.appointmentId;
      this.$emit("input", visible);
    },
    async onCollapseShow() {
      if (this.appointmentDocuments.length && !this.errors.length) {
        return;
      }
      this.loading = true;
      this.errors = [];
      try {
        const {documents} = await read(`/api/appointment/${this.item.appointmentId}/appointment`);
        this.appointmentDocuments = this.patientDocuments(documents);
      } catch (e) {
        this.errors = processResponseException(e);
      }
      this.loading = false;
    },
    patientDocuments(docs) {
      return docs.filter(doc => doc.patient?.patientId === this.patientId);
    }
  },
}
</script>

<style lang="scss" scoped>
  @import "../../../styles/variables";

  .appointment {
    &-date {
      flex: 0 0 100px;
    }

    &-worker-treatment {
      flex: 0 1 100%;
    }

    &-worker {
      flex: 0 0 25%;
    }

    &-treatment {
      flex: 0 0 75%;
    }

    &-visibility-status {
      flex: 0 0 145px;
    }
  }
</style>
