



























import Vue from "vue";
import Component from "vue-class-component";
import {Prop} from "vue-property-decorator";
import {Error} from "../../rest";
import t from "../../i18n";

@Component
export default class ErrorMessage extends Vue {
  @Prop({required: true, type: Array}) readonly errors!: Array<Error>;
  @Prop({default: null, type: String}) readonly field!: string;
  @Prop({default: false, type: Boolean}) readonly root!: boolean;
  @Prop({default: false, type: Boolean}) readonly bordered!: boolean;

  get currentErrors(): Array<Error> {
    return this.errors
      .filter((error) => {
        if (this.field === error.field) {
          return true;
        }
        if (this.root){
          return false;
        }
        if ( null == error.field || "" === error.field) {
          return null == this.field;
        }
        if (0 === error.field.indexOf(`${this.field}.`)) {
          return true;
        }
        const field = this.field ? this.field.replace("[", "\\[") : this.field;
        const pattern = new RegExp(`${field}\\[\\d+]`);
        return pattern.test(error.field);
      });
  }

  get messages(): Array<string> {
    return this.currentErrors
      .map((error) => t(error.message));
  }

  get fieldErrorClass(): string {
    if (this.messages.length === 0) {
      return "mb-0";
    }
    return this.bordered
      ? "border border-danger rounded p-2"
      : "p-0";
  }
}
