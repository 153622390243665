<template>
  <b-alert
    :show="toastVisible"
    :variant="toastVariant"
    class="alert-message"
    fade
    dismissible
    @dismissed="hideToast"
  >
    <div class="text-center">
      {{ toastMessage }}
    </div>
  </b-alert>
</template>

<script>
import {mapMutations, mapState} from "vuex";

export default {
  computed: {
    ...mapState("toastNotification", [
      "toastVisible",
      "toastMessage",
      "toastVariant",
    ]),
  },
  methods: {
    ...mapMutations("toastNotification", ["hideToast"]),
  },
}
</script>

<style scoped lang="scss">
@import "../styles/variables";

  .alert-message {
    z-index: $zindex-modal-backdrop;
    position: fixed;
    top: 10vh;
    left: 0;
    right: 0;
    max-width: 400px;
    margin: auto;
    white-space: pre-line;
  }
</style>
