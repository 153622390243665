<template>
  <div class="row">
    <date-picker
      :value="date"
      :disabled="disabled"
      :state="state"
      placeholder="Wybierz datę"
      class="col-7"
      @input="updateDate"
    />
    <hour-select
      :value="time"
      :disabled="disabled"
      :state="state"
      class="col-5"
      :time-span="timeSpan"
      @input="updateTime"
    />
  </div>
</template>

<script>
import DatePicker from "./DatePicker";
import HourSelect from "../Select/HourSelect";

const timeSpan = 15; // 15 minutes

export default {
  name: "DateTimePicker",
  components: {DatePicker, HourSelect},
  props: {
    state: {type: Boolean, default: null},
    disabled: {type: Boolean, default: false},
    value: {type: Date, default: null},
    timeSpan: {type: Number, default: timeSpan},
  },
  computed: {
    date() {
      const date = this.value || new Date();
      date.setHours(this.time.HH, this.time.mm, "00");
      return date;
    },
    time() {
      const date = this.value || new Date();
      return this.getTimeSliced(date);
    }
  },
  methods: {
    getMinutes(dateTime) {
      return this.timeSpan > 1
        ? (dateTime.getMinutes() / this.timeSpan) * this.timeSpan
        : dateTime.getMinutes();
    },
    getTimeSliced(dateTime) {
      return {
        HH: String(dateTime.getHours()).padStart(2, "0"),
        mm: String(this.getMinutes(dateTime)).padStart(2, "0"),
      }
    },
    updateDate(newDate) {
      const date = new Date(newDate);
      date.setHours(this.time.HH, this.time.mm);
      this.$emit("input", date);
    },
    updateTime(newTime) {
      const date = new Date(this.date);
      date.setHours(Number(newTime.HH), Number(newTime.mm));
      this.$emit("input", date);
    },
  }
}
</script>
