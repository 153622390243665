
















































import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";
import ErrorsMixin from "../../../mixins/ErrorsMixin.ts";
import LoadingMask from "../../Loading/LoadingMask.vue";
import Card from "../../Card.vue";
import read from "../../../rest/read";
import {Pagination} from "../../../types/Pagination";
import {ResponseCollection} from "../../../types/ResponseCollection";
import PaginationDescription from "../../Pagination/PaginationDescription.vue";
import AcceptedReferralsTable from "../../AcceptedReferrals/AcceptedReferralsTable.vue";
import AcceptReferralModal from "../../AcceptedReferrals/AcceptReferralModal.vue";

interface Document {
  appointmentId: string,
  scheduledDate: Date,
  doctor: string,
}

export interface AcceptedReferral {
  id: string,
  creator: string,
  createdAt: Date,
  status: string,
  extension: string,
  appointmentDocumentId: string,
  document: Document | null,
}

@Component({
  name: PatientCardAcceptedReferrals.name,
  components: {AcceptReferralModal, AcceptedReferralsTable, LoadingMask, ErrorsMixin, Card, PaginationDescription},
})
export default class PatientCardAcceptedReferrals extends Vue {
  @Prop({type: String, default: null}) readonly patientId!: string;
  private loading: boolean = false;

  private items: AcceptedReferral[] = [];
  private pagination: Pagination = {
    perPage: 50,
    currentPage: 1,
    totalRows: 0,
  };
  private page: number = 1;

  async accept(): Promise<void>
  {
    this.$bvModal.show("accept-referral");
  }

  async mounted(): Promise<void> {
    return await this.loadData();
  }

  async changePage(page: number): Promise<void> {
    this.page = page;
    return await this.loadData();
  }

  private async loadData(): Promise<void> {
    this.loading = true;
    const {items, pagination} = await read<ResponseCollection<AcceptedReferral>>("/api/find-accepted-referral", {
      patientId: this.patientId,
      page: this.page
    });
    this.items = items;
    this.pagination = pagination;
    this.loading = false;
  }

}
