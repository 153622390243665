<template>
  <form @submit.prevent="saveFormData">
    <b-form-group
      label="Czy osoba jest bierna zawodowo"
      label-for="isProfessionallyInactivePerson"
    >
      <boolean-refusal-select
        id="isProfessionallyInactivePerson"
        v-model="formData.isProfessionallyInactivePerson"
        :disabled="loading"
        :only-boolean="true"
        :state="state('laborMarketStatusData.isProfessionallyInactivePerson')"
      />
      <error-message
        :errors="errors"
        field="laborMarketStatusData.isProfessionallyInactivePerson"
      />
    </b-form-group>
    <b-form-group
      label="Czy osoba jest pracująca"
      label-for="doesWork"
    >
      <boolean-refusal-select
        id="doesWork"
        v-model="formData.doesWork"
        :disabled="loading"
        :only-boolean="true"
        :state="state('laborMarketStatusData.doesWork')"
        @input="clearDependencies"
      />
      <error-message
        :errors="errors"
        field="laborMarketStatusData.doesWork"
      />
    </b-form-group>
    <template v-if="formData.doesWork">
      <b-form-group
        label="Osoba pracująca w"
        label-for="workplace"
      >
        <workplace-select
          id="workplace"
          v-model="formData.workplace"
          :disabled="loading"
          :state="state('laborMarketStatusData.workplace')"
        />
        <error-message
          :errors="errors"
          field="laborMarketStatusData.workplace"
        />
      </b-form-group>

      <b-form-group
        label="Wykonywany zawód"
        label-for="profession"
      >
        <profession-select
          id="profession"
          v-model="formData.profession"
          :disabled="loading"
          :state="state('laborMarketStatusData.profession')"
        />
        <error-message
          :errors="errors"
          field="laborMarketStatusData.profession"
        />
      </b-form-group>
      <b-form-group
        label="Osoba zatrudniona w"
        label-for="worksIn"
      >
        <resizable-textarea
          id="worksIn"
          v-model="formData.worksIn"
          :disabled="loading"
          :rows="3"
          :state="state('laborMarketStatusData.worksIn')"
          placeholder="Zatrudniona w"
        />
        <error-message
          :errors="errors"
          field="laborMarketStatusData.worksIn"
        />
      </b-form-group>
    </template>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-secondary"
        @click="cancel"
      >
        Anuluj
      </button>
      <button
        :disabled="loading"
        type="submit"
        class="btn btn-primary"
      >
        <i
          :class="loading ? 'fa-spin fa-spinner' : 'fa-check'"
          class="fa"
        />
        Zapisz
      </button>
    </div>
  </form>
</template>

<script>
import read from "../../rest/read";
import {errorsMixin} from "../../mixins/errorsMixin.js";
import WorkplaceSelect from "../Form/Select/WorkplaceSelect";
import ProfessionSelect from "../Form/Select/ProfessionSelect";
import ErrorMessage from "../Form/ErrorMessage";
import processResponseException from "../../utils/errors/processResponseException";
import update from "../../rest/update";
import validate, {notEmptyRule} from "../../utils/validate";
import ResizableTextarea from "../Form/Textarea/ResizableTextarea";
import BooleanRefusalSelect from "../Form/Select/BooleanRefusalSelect";

export default {
  components: {BooleanRefusalSelect, ResizableTextarea, ErrorMessage, ProfessionSelect, WorkplaceSelect},
  mixins: [errorsMixin],
  props: {
    patientId: {type: String, required: true},
    status: {type: Object, default: null},
  },
  data() {
    return {
      laborMarketStatus: {},
      formData: {},
      loading: false,
      resource: `/api/patients/${this.patientId}/labor_market_status`
    }
  },
  watch: {
    async patientId() {
      await this.getLaborMarketStatus();
    },
  },
  async mounted() {
    await this.getLaborMarketStatus();
  },
  methods: {
    async getLaborMarketStatus() {
      this.loading = true;
      try {
        this.laborMarketStatus = await read(this.resource);
      } catch(exception) {
        this.errors = processResponseException(exception);
      }
      this.resetData();
      this.loading = false;
    },
    validateUnificationData() {
      const rules = [
        {field: "isProfessionallyInactivePerson", rule: notEmptyRule},
        {field: "doesWork", rule: notEmptyRule},
      ];
      if (this.laborMarketStatus.doesWork) {
        rules.push(
          {field: "workplace", rule: notEmptyRule},
          {field: "profession", rule: notEmptyRule},
          {field: "worksIn", rule: notEmptyRule},
        );
      }
      const root = "laborMarketStatusData";
      this.errors = validate(rules, root, this.errors)(this.laborMarketStatus);
    },
    async saveFormData() {
      this.loading = true;
      try {
        await update(this.resource, {laborMarketStatusData: this.formData});
        this.laborMarketStatus = await read(this.resource);
        this.$emit("updateStatus", this.laborMarketStatus);
        this.cancel();
      } catch (exception) {
        this.errors = processResponseException(exception);
        this.validateUnificationData();
      }
      this.loading = false;
    },
    cancel() {
      this.resetData();
      this.$emit("cancel");
    },
    resetData() {
      this.formData = {...this.laborMarketStatus};
      this.errors = [];
      this.validateUnificationData();
    },
    clearDependencies() {
      this.formData.profession = null;
      this.formData.workplace = null;
      this.formData.worksIn = null;
    }
  }
}
</script>
