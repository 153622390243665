import {UploadedFile} from "./HospitalizationTypes";

export interface InternalReferral {
  internalReferralId: string; // uuid
  patientId: string; // uuid
  displayName?: string; // uuid
  referringEstablishment: string; // uuid
  receivingEstablishment: string; // uuid
  description: string; // text
  rejectReason?: string;
  author?: string;
  files: File[]
}

export interface File {
  fileId: string,
  fileName: string
}

export interface InternalReferralFormData {
  internalReferralId?: string; // uuid
  patient?: Patient | string; // uuid
  referringEstablishment?: Branch | string; // uuid
  receivingEstablishment?: Branch | string; // uuid
  description?: string; // text
  rejectReason?: string;
  author?: string;
  files: string[]
}

export interface InternalReferralComment {
  createdDate: string; // YYYY-MM-DD HH:mm:ss
  author: string;
  content: string;
  direction: string
}

export interface InternalReferralSaveComment{
  comment: string;
}

export interface InternalReferralSubmitData {
  internalReferralId: string;
  patientId?: string;
  referringEstablishment?: string;
  receivingEstablishment?: string
  description?: string
}

export interface InternalReferralItem {
  direction: InternalReferralDirection;
  patient: Patient;
  internalReferralId: string; // uuid
  workerReferring: Worker;
  status: InternalReferralStatus;
  referringEstablishment: string; // uuid
  receivingEstablishment: string; // uuid
  receivingEstablishmentName: string
  description: string; // text
  createdAt: string; // YYYY-MM-DD HH:mm:ss
  sendDate?: string; // YYYY-MM-DD HH:mm:ss
  acceptDate?: string; // YYYY-MM-DD HH:mm:ss
  rejectDate?: string; // YYYY-MM-DD HH:mm:ss
  cancelDate?: string; // YYYY-MM-DD HH:mm:ss
  rejectReason?: string;
  lastCommentDate?: string;
}

export interface Patient {
  patientId: string;
  displayName?: string;
}

export interface Worker {
  name?: string;
  workerId: string;
}

export interface Branch {
  branchId: string;
  value?: string;
  name?: string;
}

export enum InternalReferralStatus {
  DRAFT = "draft",
  ACCEPTANCE = "acceptance",
  ACCEPTED = "accepted",
  CANCELED = "canceled",
  REJECTED = "rejected",
  FINISHED = "finished"
}

export enum InternalReferralDirection {
  SENT = "sent",
  RECEIVED = "received",
}

export interface InternalReferralPageQuery {
  direction?: InternalReferralDirection;
  page?: number;
  perPage?: number;
  patientId?: Patient["patientId"];
  status?: InternalReferralStatus;
  workerId?: Worker["workerId"];
}
