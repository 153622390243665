const DATE_FORMAT = {
  DATE: "YYYY-MM-DD",
  DATE_TIME: "YYYY-MM-DD HH:mm",
  DATE_TIME_SEC: "YYYY-MM-DD HH:mm:ss",
  DEFAULT: "YYYY-MM-DDTHH:mm:ss",
  FULL: "YYYY-MM-DDTHH:mm:ssZZ",
  TIME: "HH:mm",
  DATE_INVERSED: "DD.MM.YYYY",
  HOUR: "HH",
  MINUTE: "mm",
  YEAR: "YYYY",
  MONTH: "YYYY-MM",
};

export default DATE_FORMAT;
