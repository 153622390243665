



































































import ErrorsMixin from "../../../mixins/ErrorsMixin";
import {Component, Mixins, Prop, Watch} from "vue-property-decorator";
import ErrorMessage from "../../Form/ErrorMessage.vue";
import SimplifiedIpzEntryTextarea from "./SimplifiedIpzEntryTextarea.vue";
import {Error} from "../../../rest";
import type {EntryPart, EntryHour} from "../../../types/SimplifiedIpzTypes";
import HourSelect from "../../Form/Select/HourSelect.vue";
import stringifyDate from "../../../utils/date/stringifyDate";
import DatePicker from "../../Form/DatePicker/DatePicker.vue";

@Component({
  name: "SimplifiedIpzEntryForm",
  components: {SimplifiedIpzEntryTextarea, ErrorMessage, DatePicker, HourSelect},
})

export default class SimplifiedIpzEntryForm extends Mixins(ErrorsMixin) {
  @Prop({type: Array, required: true}) readonly value!: Array<EntryPart>;
  @Prop({type: Array, default: () => []}) readonly errorProps!: Array<Error>;
  @Prop({type: Boolean, default: false}) readonly loading!: boolean;
  @Prop({type: Boolean, default: false}) readonly cancelButton!: boolean;
  @Prop({type: Date, default: null}) readonly propEntryDate!: Date|null

  @Watch("propEntryDate") onPropEntryChange() {
    this.entryDate = null !== this.propEntryDate ? this.propEntryDate : new Date();
    this.entryHour = this.calculateEntryHour(this.entryDate);
  }

  private entryDate: Date = null !== this.propEntryDate ? this.propEntryDate : new Date();
  private entryHour: EntryHour|null = this.calculateEntryHour(this.entryDate);

  submit():void {
    this.$emit("submit");
  }

  private calculateEntryHour(entryDate: Date):EntryHour {
    let minutes = entryDate.getMinutes();
    const mod = minutes % 5;
    minutes = minutes - mod;
    if(mod >= 3) {
      minutes += 5;
    }

    return {
      HH: String(entryDate.getHours()).padStart(2, "0"),
      mm: String(minutes).padStart(2, "0")
    }
  }

  update($event: EntryPart["content"] = null, category: EntryPart["category"] = null): void {

    const entryDate = this.entryDate ? new Date(this.entryDate) : null;

    if(entryDate) {
      if(this.entryHour) {
        entryDate.setHours(Number(this.entryHour.HH));
        entryDate.setMinutes(Number(this.entryHour.mm));
      }
    }

    const stringifyEntryDate = entryDate ? stringifyDate(entryDate) : null;

    const newValue: Array<EntryPart> = this.value.map(part => {
      if (part.category === category) {
        return {
          category,
          content: $event,
          entryDate: stringifyEntryDate
        };
      }
      return {
        ...part,
        entryDate: stringifyEntryDate
      };
    });

    this.$emit("input", newValue);
  }

  cancel(): void {
    this.$emit("cancel");
  }
}
