<template>
  <object-select
    :value="selectedOption"
    :options="options"
    :disabled="disabled"
    :multiple="false"
    :placeholder="placeholder"
    :state="state"
    :loading="loading"
    @input="update"
  />
</template>

<script>
import read from "../../rest/read";
import ObjectSelect from "../Form/Select/ObjectSelect";

const worker2option = (worker) => ({
  value: worker.workerId,
  name: `${worker.surname} ${worker.name}`,
});

const option2worker = (option) => ({
  workerId: option.value,
  name: option.name
});

export default {
  components: {ObjectSelect},
  props: {
    value: {type: Object, default: null},
    disabled: {type: Boolean, default: false},
    placeholder: {type: String, default: "Wybierz specjalistę"},
    state: {type: Boolean, default: null},
  },

  data() {
    return {
      loading: false,
      options: [],
    }
  },

  computed: {
    selectedOption () {
      return this.value ? this.options.find(option => option.value === this.value.workerId) : null;
    }
  },

  mounted() {
    this.loadOptions();
  },
  methods: {
    async loadOptions() {
      this.loading = true;
      const {items} = await read("/api/case-managers");
      this.options = items.map(item => worker2option(item));
      this.loading = false;
    },
    update(option) {
      this.$emit("input", option ? option2worker(option) : null);
    },
  },
}
</script>

