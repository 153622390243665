











import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";
import {MedicalIndicationType} from "../../types/Zla";

@Component({
  name: "MedicalIndicationCheckbox"
})
export default class MedicalIndicationCheckbox extends Vue {
  @Prop({type: Number, required: true}) readonly value!: MedicalIndicationType;

  private checkedValue = MedicalIndicationType.STAY_AT_HOME;
  private uncheckedValue = MedicalIndicationType.CAN_LEAVE_HOME;

  private update(value: MedicalIndicationType): void {
    this.$emit("input", value);
  }
}
