<template>
  <b-card no-body>
    <b-table
      small
      striped
      hover
      :fields="fields"
      :items="files"
    >
      <template #cell(options)="data">
        <b-form-checkbox-group
          v-model="selected"
          stacked
        >
          <b-form-checkbox
            :value="data.item.attachmentId"
            @change="updateIds(data.item.attachmentId)"
          />
        </b-form-checkbox-group>
      </template>
    </b-table>
    <template #footer>
      <pagination-description
        :items-count="files.length"
        :page="pagination.currentPage"
        :per-page="pagination.perPage"
        :total-count="pagination.totalRows"
      >
        <b-pagination
          :value="pagination.currentPage"
          :total-rows="pagination.totalRows"
          :per-page="pagination.perPage"
          align="right"
          class="mb-0"
          @input="changePage"
        />
      </pagination-description>
    </template>
  </b-card>
</template>

<script>
import PaginationDescription from "../../../Pagination/PaginationDescription";

export default {
  name: "PatientRequestMedicalAttachmentsList",
  components: {
    PaginationDescription,
  },
  props: {
    pagination: {type: Object, required: true},
    files: {type: Array, required: false, default: null},
    selectedFiles: {type: Array, required: false, default: null},
  },
  data() {
    return {
      items: [],
      selected: [],
      fields: [
        {key: "options", label: "Opcje"},
        {key: "name", label: "Plik"},
        {key: "category", label: "Kategoria"},
      ],
    };
  },
  watch: {
    selectedFiles() {
      this.selected = this.selectedFiles
    },
  },
  mounted() {
    this.selected = this.selectedFiles;
  },
  methods: {
    updateIds(attachmentId) {
      this.$emit("updateUnselectedAttachmentIds", attachmentId);
    },
    changePage(page) {
      this.$emit("changePage", page);
    },
  },
};
</script>
