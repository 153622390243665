

























































































import {Prop} from "vue-property-decorator";
import Component, {mixins} from "vue-class-component";
import type {
  EntryPart,
  SimplifiedIpzEntryCreator,
  SimplifiedIpzEntryMetadata,
  SimplifiedIpzTypes,
} from "../../../types/SimplifiedIpzTypes";
import I18n from "../../i18n.vue";
import TreatmentTypeName from "../../TreatmentType/TreatmentTypeName.vue";
import Tooltip from "../../Tooltip.vue";
import ResizableTextarea from "../../Form/Textarea/ResizableTextarea.vue";
import ErrorMessage from "../../Form/ErrorMessage.vue";
import ErrorsMixin from "../../../mixins/ErrorsMixin.ts";
import processResponseException from "../../../utils/errors/processResponseException";
import update from "../../../rest/update";
import DATE_FORMAT from "../../../utils/date/DATE_FORMAT";
import DateTime from "../../DateTime.vue";
import SimplifiedIpzEntryForm from "./SimplifiedIpzEntryForm.vue";
import TreatmentTypeNameTooltip from "../../TreatmentType/TreatmentTypeNameTooltip.vue";
import SimplifiedIpzEntryParts from "./SimplifiedIpzEntryParts.vue";
import IsGranted from "../../IsGranted.vue";
import isEqual from "lodash/isEqual";
import TargetsAndTasksBadge from "../../TargetsAndTasks/TargetsAndTasksBadge.vue";

@Component({
  name: "SimplifiedIpzTimelineEntry",
  components: {
    TargetsAndTasksBadge,
    IsGranted,
    TreatmentTypeNameTooltip,
    SimplifiedIpzEntryForm,
    DateTime,
    SimplifiedIpzEntryParts,
    ErrorMessage,
    ResizableTextarea,
    Tooltip,
    TreatmentTypeName,
    I18n,
  },
})
export default class SimplifiedIpzTimelineEntry extends mixins(ErrorsMixin) {
  @Prop({type: String, required: true}) readonly entryId!: string;
  @Prop({type: Array, required: true}) readonly parts!: Array<EntryPart>;
  @Prop({type: String, required: true}) readonly createdAt!: string;
  @Prop({type: Object, required: true}) readonly createdBy!: SimplifiedIpzEntryCreator;
  @Prop({type: String, required: true}) readonly type!: SimplifiedIpzTypes;
  @Prop({type: Object, default: null}) readonly metadata: SimplifiedIpzEntryMetadata|null;
  @Prop({type: Boolean, default: false}) readonly noteEditing!: boolean;
  @Prop({type: Boolean, required: true}) readonly readonly!: boolean;
  @Prop({type: Date, default: null}) readonly entryDate!: Date|null

  editing: boolean = false;
  loading: boolean = false;
  formData: Array<EntryPart> = this.parts;
  DATE_FORMAT = DATE_FORMAT;

  startEditing(): void {
    this.formData = JSON.parse(JSON.stringify(this.parts));
    this.editing = true;
    this.$emit("startEditing");
  }

  stopEditing(): void {
    this.editing = false;
    this.$emit("stopEditing");
  }

  cancel(): void {
    this.errors = [];
    this.stopEditing();
  }

  remove(): void {
    this.$emit("updateList", this.entryId);
  }

  async edit(): Promise<void> {
    this.errors = [];
    if (isEqual(this.parts, this.formData)) {
      this.stopEditing();
      return;
    }
    this.loading = true;
    try {
      await update(`/api/simplified-ipz-entry/${this.entryId}`, {
        parts: this.formData,
        type: this.type,
        entryDate: this.formData[0].entryDate
      });
      this.stopEditing();
      this.$emit("updateList");
    } catch(e) {
      this.errors = processResponseException(e);
    }
    this.loading = false;
  }
}
