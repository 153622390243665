<template>
  <div class="row">
    <div class="col-md-3 p-3">
      <i18n
        :msgid="type"
      />
    </div>
    <div class="col-md-9 p-1">
      <div class="row m-0">
        <tooltip
          v-for="item in qualifierItems"
          :key="item.value"
          :class="answers.includes(item.value) ? 'btn-success' : 'btn-secondary'"
          class="btn col m-1"
          component="div"
          :tooltip-content="item.name"
        >
          {{ item.value }}
        </tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import I18n from "../../i18n";
import Tooltip from "../../Tooltip";

export default {
  components: {
    Tooltip,
    I18n},
  props: {
    type: {type: String, required: true},
    items: {type: Array, required: true},
    symbol: {type: String, required: true},
    qualifiersAnswers: {type: Array, required: true},
  },
  computed: {
    answers() {
      return this.qualifiersAnswers.map(answer => {
        if (/0/.test(answer)) {
          return "0"
        }
        return answer.replace("-", "");
      });
    },
    qualifierItems() {
      if (this.symbol !== "e") {
        return this.items;
      }
      return this.items.reduce((list, item) => {
        if (/0/.test(item.value)) {
          return (list.find(i => i.value === "0"))
            ? list.map(i => {
              return (i.value === "0")
                ? {...i,
                  name: `${i.name}, ${item.name}`}
                : i;
            })
            : [...list, {
              ...item,
              value: "0",
            }];
        }

        return [...list, item];
      }, []);
    }
  },
}
</script>
