<template>
  <div>
    <room-occupancy-filters
      :filters="{start, branch, type, number, description, floor}"
      @reload="fetchData"
    />
    <room-occupancy-widget
      ref="roomOccupancyWidget"
      :filters="{start, branch, type, number, description, floor}"
    />
  </div>
</template>

<script>
import {mapMutations} from "vuex";
import RoomOccupancyFilters from "../../components/RoomOccupancy/RoomOccupancyFilters";
import RoomOccupancyWidget from "../../components/RoomOccupancy/RoomOccupancyWidget";

export default {
  name: "RoomOccupancySchedule",
  components: {RoomOccupancyWidget, RoomOccupancyFilters},
  beforeRouteLeave(to, from, next) {
    this.saveAppointmentListItem(null);
    next();
  },
  props: {
    start: {type: String, default: null},
    branch: {type: String, default: null},
    type: {type: String, default: null},
    number: {type: String, default: null},
    description: {type: String, default: null},
    floor: {type: String, default: null},
  },
  watch: {
    async "$route"() {
      await this.fetchData();
    },
  },
  methods: {
    ...mapMutations("appointmentListItem", ["saveAppointmentListItem",]),
    async fetchData() {
      setTimeout(() => this.$refs.roomOccupancyWidget.fetchData());
    }
  },
}
</script>
