








































































import ErrorMessage from "../Form/ErrorMessage.vue";
import {BvTableFieldArray} from "bootstrap-vue/src/components/table";
import {Component, Mixins, Prop} from "vue-property-decorator";
import I18n from "../i18n.vue";
import type {Referral} from "../../types/Referrals";
import {ReferralStatus} from "../../types/Referrals";
import DateTime from "../DateTime.vue";
import parseDate from "../../utils/date/parseDate";
import DATE_FORMAT from "../../utils/date/DATE_FORMAT";
import IsGranted from "../IsGranted.vue";
import CancelReferralModal from "./CancelReferralModal.vue";
import ErrorsMixin from "../../mixins/ErrorsMixin";

@Component({
  name: "ReferralsTable",
  components: {CancelReferralModal, IsGranted, DateTime, I18n, ErrorMessage},
})
export default class ReferralsTable extends Mixins(ErrorsMixin) {
  @Prop({type: Array, default: ()=>[]}) readonly items!: Referral[];
  @Prop({type: Boolean, default: false}) readonly showDoctor!: boolean;

  public ReferralStatus = ReferralStatus;
  public DATE_FORMAT = DATE_FORMAT;
  public parseDate = parseDate;

  private get fields(): BvTableFieldArray {
    let fields = [
      {key: "createdAt", label: "Data skierowania"},
    ];
    if (this.showDoctor) {
      fields = fields.concat([{key: "doctor", label: "Wystawił"},]);
    }
    fields = fields.concat([{key: "type", label: "Typ"}]);
    if (this.items.some(item => !item.isElectronic)) {
      fields = fields.concat([{key: "unitName", label: "Nazwa jednostki/Szpitala "}]);
    }
    fields = fields.concat([{key: "unitDepartment", label: "Oddział"}]);
    fields = fields.concat([{key: "icd10Code", label: "Rozpoznanie"}]);
    if (this.items.some(item => item.isElectronic)) {
      fields = fields.concat([{key: "status", label: "Status"}]);
    }
    fields = fields.concat([{key: "options", label: "", class: "text-right"}]);
    return fields;
  }

  private typeTranslation(isElectronic: boolean, type: Referral["type"]): string {
    return isElectronic
      ? `@referral-type.${type}`
      : type
  }

  private getBadgeVariant(status: ReferralStatus): string {
    switch (status) {
      case ReferralStatus.CREATED:
        return "primary";
      case ReferralStatus.COMPLETED:
        return "success";
      case ReferralStatus.REJECTED:
        return "danger";
      case ReferralStatus.CANCELED:
        return "secondary";
      default:
        return "light";
    }
  }

  private refreshTable(): void {
    this.$emit("refresh");
  }
}
