





























import {Component, Prop} from "vue-property-decorator";
import Vue from "vue";
import type {IdsTarget, Target, Task} from "../../../types/TargetsAndTasks";

@Component({name: "TasksCheckboxGroup"})

export default class TasksCheckboxGroup extends Vue {
  @Prop({type: Array, default: () => []}) readonly value!: Array<string>
  @Prop({type: Object, required: true}) readonly target!: Target;
  @Prop({type: Array, default: () => []}) readonly options!: Array<Task>

  update(checked: IdsTarget["taskIds"]): void {
    this.$emit("input", checked);
  }
}
