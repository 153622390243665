




























































































import {Component, Mixins, Prop, Watch} from "vue-property-decorator";
import ErrorMessage from "../../../Form/ErrorMessage.vue";
import ErrorsMixin from "../../../../mixins/ErrorsMixin";
import processResponseException from "../../../../utils/errors/processResponseException";
import modify from "../../../../rest/modify";
import type {EligiblePerson, SurveyItem} from "../../../../types/SurveyTypes";
import {RequesterType} from "../../../../types/SurveyTypes";
import EligiblePersons from "../../../EligiblePersons/EligiblePersons.vue";
import DatePicker from "../../../Form/DatePicker/DatePicker.vue";
import DATE_FORMAT from "../../../../utils/date/DATE_FORMAT";
import parseDate from "../../../../utils/date/parseDate";
import stringifyDate from "../../../../utils/date/stringifyDate";
import {generateUuid} from "../../../../utils/uuid/generateUuid";

@Component({
  name: "UpdateSurveyAttributesModal",
  components: {
    DatePicker,
    EligiblePersons,
    ErrorMessage,
  },
})
export default class SendSurveyInvitationModal extends Mixins(ErrorsMixin) {
  @Prop({type: Object, default: null}) readonly survey!: SurveyItem;
  @Prop({type: String, required: true}) readonly patientId!: string;

  private loading: boolean = false;
  private startedAt: string|null = null;
  private endDate: string|null = null;
  private requesterType: RequesterType|null = null;
  private eligiblePersons: EligiblePerson[] = [];

  @Watch("survey", {immediate: true}) async onSurveyChange(survey: SurveyItem): void {
    if (!survey) {
      return;
    }
    this.startedAt = survey.startedAt ? parseDate(survey.startedAt, DATE_FORMAT.DATE_TIME_SEC) : null;
    this.endDate = survey.endDate ? parseDate(survey.endDate, DATE_FORMAT.DATE_TIME_SEC) : null;
    this.requesterType = survey.requesterType;
    this.eligiblePersons = survey.eligiblePerson ? [{...survey.eligiblePerson, id : generateUuid()}] : []
  }

  private async submit() {
    this.loading = true;
    try {
      const eligiblePerson = this.requesterType === RequesterType.ELIGIBLE_PERSON
        ? this.eligiblePersons[0] || {}
        : null;
      const data = {
        startedAt: this.startedAt ? stringifyDate(this.startedAt, DATE_FORMAT.DATE_TIME_SEC) : null,
        endAt: this.endDate ? stringifyDate(this.endDate, DATE_FORMAT.DATE_TIME_SEC) : null,
        requesterType: this.requesterType,
        eligiblePerson,
      };
      await modify<SubmitData>(`/api/surveys/${this.survey?.surveyId}`, data);
      this.$emit("updated");
      this.hideModal();
    } catch (exception) {
      this.errors = processResponseException(exception);
    }
    this.loading = false;
  }

  private setModalData() {
    this.errors = [];
    this.startedAt = this.survey?.startedAt || null;
    this.endDate = this.survey?.endDate || null;
    this.requesterType = this.survey?.requesterType || null;
    this.eligiblePersons = this.survey?.eligiblePerson ? [this.survey.eligiblePerson] : []
  }

  private hideModal() {
    this.$bvModal.hide("update-survey-attributes");
  }
}
