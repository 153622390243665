









































































































































import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";
import ErrorMessage from "../Form/ErrorMessage.vue";
import {Error} from "../../rest";
import BooleanRefusalSelect from "../Form/Select/BooleanRefusalSelect.vue";
import InputOrNotRememberRadio from "../Form/Radio/InputOrNotRememberRadio.vue";
import subErrors from "../../utils/errors/subErrors";
import type {HospitalizationsData} from "../../types/HospitalizationTypes";

@Component({
  name: "PatientHospitalizations",
  components: {InputOrNotRememberRadio, ErrorMessage, BooleanRefusalSelect},
})
export default class PatientHospitalizations extends Vue {
  @Prop({type: Object, required: true}) readonly value!: HospitalizationsData;
  @Prop({type: Boolean, default: false}) readonly loading!: boolean;
  @Prop({type: Array, default: () => []}) readonly errors!: Array<Error>;

  state(field: string): boolean|null {
    return this.errors.find((error) => error.field === field) ? false : null;
  }

  getSubErrors(field: string): Array<Error> {
    return subErrors(this.errors, field);
  }

  update(diff: Partial<HospitalizationsData>): void {
    this.$emit("input", {...this.value, ...diff});
  }

  get showHowManyHospitalizationsWarning(): boolean {
    if (!this.value.howManyHospitalizationsLastPeriod || !this.value.howManyHospitalizationsLastPeriod.value) {
      return false;
    }

    return this.value.howManyHospitalizationsLastPeriod.value > this.howManyHospitalizationsWarningValue &&
      this.value.howManyHospitalizationsLastPeriod.doesRemember === true;
  }

  get showHowLongHospitalizedWarning(): boolean {
    if (!this.value.howLongHospitalizedLastPeriod || !this.value.howLongHospitalizedLastPeriod.value) {
      return false;
    }

    return this.value.howLongHospitalizedLastPeriod.value > this.howLongHospitalizedWarningValue &&
      this.value.howLongHospitalizedLastPeriod.doesRemember === true;
  }

  get howLongHospitalizedWarningValue(): number {
    return 100;
  }

  get howManyHospitalizationsWarningValue(): number {
    return 20;
  }
}
