import {MutationTree} from "vuex";
import {ToastNotificationState} from "./types";
import {initialState} from "./initialState";

export const mutations: MutationTree<ToastNotificationState> = {
  showToast(state, {message, variant}) {
    state.toastVisible = 15;
    state.toastMessage = message;
    state.toastVariant = variant;
  },
  hideToast(state) {
    state.toastVisible = initialState.toastVisible;
    state.toastMessage = initialState.toastMessage;
    state.toastVariant = initialState.toastVariant;
  },
};
