<template>
  <card
    :loading="loading"
    title="Określ cele i zadania wizyty"
  >
    <template #control>
      <is-granted
        :privileges="['IPZ_DETAILS']"
      >
        <router-link
          :to="{name: 'ipz_details_targets', params: {ipzId: module.ipzId}}"
          class="btn btn-sm btn-primary m-1"
        >
          Przejdź do IPZ - Cele i Zadania
        </router-link>
      </is-granted>
    </template>
    <template #default>
      <error-message :errors="module.errors || []" />
      <error-message :errors="errors" />
      <targets-checkbox-group
        v-if="hasIpzTargets"
        :module-targets="module.targets"
        :patient-targets="patientTargets"
        :read-only="readOnly"
        @update="updateTargets"
      />
    </template>
  </card>
</template>

<script>
import IsGranted from "../../IsGranted";
import Card from "../../Card";
import read from "../../../rest/read";
import processResponseException from "../../../utils/errors/processResponseException";
import ErrorMessage from "../../Form/ErrorMessage";
import TargetsCheckboxGroup from "../../Ipz/TargetsAndTasks/TargetsCheckboxGroup";

export default {
  name: "TargetsModule",
  components: {TargetsCheckboxGroup, ErrorMessage, Card, IsGranted},
  props: {
    idx: {required: true, type: Number},
    module: {required: true, type: Object},
    readOnly: {required: true, type: Boolean}
  },
  data() {
    return {
      loading: false,
      patientTargets: [],
    };
  },
  computed: {
    hasIpzTargets() {
      return !!this.patientTargets.length;
    },
  },
  watch: {
    "module.ipzId": {
      immediate: true,
      async handler(val) {
        if (!val) {
          this.patientTargets = [];
        }
        await this.loadData();
      },
    },
    async readOnly() {
      this.patientTargets = [];
      await this.loadData();
    },
  },
  methods: {
    async loadData() {
      this.errors = [];
      this.loading = true;
      try {
        const {items} = await read(`/api/ipzs/${this.module.ipzId}/targets`, {
          onlyActive: !this.readOnly
        });
        this.patientTargets = items;
      } catch(e) {
        this.errors = processResponseException(e);
        this.patientTargets = [];
      }
      this.loading = false;
    },
    addTarget($event) {
      const module = {
        ...this.module,
        targets: [...this.module.targets, $event],
      };
      this.$emit("input", module);
    },
    removeTarget($event) {
      const module = {
        ...this.module,
        targets: this.module.targets.filter(target => target.targetId !== $event),
      };
      this.$emit("input", module);
    },
    updateTarget($event) {
      const module = {
        ...this.module,
        targets: this.module.targets.map(target => {
          if (target.targetId === $event.targetId) {
            return $event;
          }
          return target;
        }),
      };
      this.$emit("input", module);
    },
    updateTargets(targets) {
      const module = {
        ...this.module,
        targets,
      };
      this.$emit("input", module);
    }
  },
}
</script>
