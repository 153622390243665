<template>
  <div>
    <childPatientCreatePersonalData
      id="child"
      v-model="patientPersonalData"
      :errors="getSubErrors('childPatient')"
    />
    <div class="card-box">
      <h4>Dane społeczno-demograficzne</h4>
      <hr>
      <socio-demographic-data
        v-model="socioDemographicData"
        :errors="getSubErrors('socioDemographicData')"
      />
    </div>
    <div class="card-box">
      <h4>Status osoby na rynku pracy w chwili przystąpienia do projektu</h4>
      <hr>
      <labor-market-status
        v-model="laborMarketStatus"
        :errors="getSubErrors('laborMarketStatus')"
        :loading="loading"
      />
    </div>
    <div class="card-box">
      <h4>Status uczestnika w chwili przystąpienia do projektu</h4>
      <hr>
      <patient-participant-status-data
        v-model="patientParticipantStatusData"
        :errors="getSubErrors('patientParticipantStatusData')"
      />
    </div>
    <div class="card-box">
      <h4>Hospitalizacje</h4>
      <hr>
      <patient-hospitalizations
        v-model="patientHospitalizationsData"
        :errors="getSubErrors('hospitalizationsData')"
      />
    </div>
    <div class="card-box">
      <h4>Źródło informacji o ŚCZP</h4>
      <hr>
      <source-of-information-select
        v-model="sourceOfInformation"
        :errors="getSubErrors('sourceOfInformation.sourceOfInformation')"
        :state="state('sourceOfInformation.sourceOfInformation')"
      />
      <error-message
        :errors="errors"

        field="sourceOfInformation"
      />
    </div>
    <div class="card-box">
      <h4>Adres zamieszkania pacjenta</h4>
      <hr>
      <address-form
        v-model="patientLivingAddress"
        :errors="getSubErrors('childPatient.livingAddress')"
      />
      <div class="row">
        <b-form-checkbox v-model="patientDifferentCorrespondenceAddress">
          Adres korespondencyjny jest inny niż zamieszkania
        </b-form-checkbox>
      </div>
    </div>
    <div
      v-if="patientDifferentCorrespondenceAddress"
      class="card-box"
    >
      <h4>Adres korespondencyjny pacjenta</h4>
      <hr>
      <address-form
        v-model="patientCorrespondenceAddress"
        :errors="getSubErrors('childPatient.correspondenceAddress')"
      />
    </div>
    <div class="card-box">
      <h4>Dane Osobowe Opiekuna</h4>
      <eligible-persons
        v-model="persons"
        :disabled="loading"
        :errors="errors"
        :optional-pesel="true"
        form-expanded
        @addPatientAddress="addPatientAddress($event)"
      />
    </div>
    <div class="card-box">
      <create-patient-permissions
        v-model="permissions"
        :errors="getSubErrors('permissions')"
        :display-type="'child'"
      />
    </div>
    <error-message :errors="errors" />
    <div class="text-right">
      <router-link
        :to="{name: 'listPatients'}"
        class="btn btn-secondary"
      >
        Anuluj
      </router-link>
      <button
        :disabled="loading"
        type="submit"
        class="btn btn-primary ml-2"
        @click="submit"
      >
        <i
          :class="loading ? 'fa-spin fa-spinner' : 'fa-check'"
          class="fa"
        />
        Zapisz
      </button>
    </div>
  </div>
</template>

<script>
import childPatientCreatePersonalData from "../../components/Patient/ChildPatientCreatePersonalData.vue";
import PatientParticipantStatusData from "../../components/Patient/PatientParticipantStatusData";
import AddressForm from "../../components/Address/AddressForm";
import SocioDemographicData from "../../components/Patient/SocioDemographicData";
import create from "../../rest/create";
import {generateUuid} from "@/utils/uuid/generateUuid";
import stringifyDate from "../../utils/date/stringifyDate";
import DATE_FORMAT from "../../utils/date/DATE_FORMAT";
import EligiblePersons from "../../components/EligiblePersons/EligiblePersons";
import processResponseException from "../../utils/errors/processResponseException";
import subErrors from "../../utils/errors/subErrors";
import ErrorMessage from "../../components/Form/ErrorMessage";
import {mapActions} from "vuex";
import LaborMarketStatus from "../../components/Patient/LaborMarketStatus";
import SourceOfInformationSelect from "../../components/SourcesOfInformation/SourceOfInformationSelect";
import {BadRequestException} from "@/rest";
import {errorsMixin} from "../../mixins/errorsMixin.js";
import PatientHospitalizations from "../../components/Patient/PatientHospitalizations";
import initHospitalizationsData from "../../utils/initHospitalizationsData";
import CreatePatientPermissions from "@/components/Patient/CreatePatientPermissions";

export default {
  name: "ChildPatientCreate",
  components: {
    CreatePatientPermissions,
    SourceOfInformationSelect,
    LaborMarketStatus,
    ErrorMessage,
    childPatientCreatePersonalData,
    AddressForm,
    EligiblePersons,
    SocioDemographicData,
    PatientParticipantStatusData,
    PatientHospitalizations,
  },
  mixins: [errorsMixin],
  data() {
    return {
      patientDifferentCorrespondenceAddress: false,
      patientPersonalData: {},
      patientLivingAddress: {},
      socioDemographicData: {},
      patientCorrespondenceAddress: {},
      selectedAgreements: [],
      loading: false,
      persons: [],
      laborMarketStatus: {},
      patientParticipantStatusData: {},
      patientHospitalizationsData: initHospitalizationsData(),
      sourceOfInformation: {},
      permissions: {},
    };
  },
  mounted() {
    this.clearStickyPatient();
  },
  methods: {
    ...mapActions({
      showToast: "toastNotification/showToast",
      clearStickyPatient: "stickyPatient/clear",
    }),
    addPatientAddress(personIndex) {
      this.persons = this.persons.map((person, index) => {
        if (index === personIndex) {
          return {
            ...person,
            address: {...this.patientLivingAddress},
          };
        }
        return person;
      });
    },
    getSubErrors(field) {
      return subErrors(this.errors, field);
    },
    birthDateString(value) {
      if (value) {
        return stringifyDate(value, DATE_FORMAT.DATE);
      }
      return null
    },
    registeredDateString() {
      if (this.patientPersonalData.registeredDate) {
        return stringifyDate(this.patientPersonalData.registeredDate, DATE_FORMAT.DATE);
      }
      return null
    },
    getHospitalizationDataValue(dataField) {
      const fieldValue = this.patientHospitalizationsData[dataField].value;
      const value =
        this.patientHospitalizationsData[dataField].doesRemember && (fieldValue || 0 === fieldValue)
          ? fieldValue
          : null;

      return {
        ...this.patientHospitalizationsData[dataField],
        value,
      };
    },
    async submit() {
      this.loading = true;

      try {
        const patientId = generateUuid();
        const patientHospitalized = this.patientHospitalizationsData.wasHospitalized === "yes";
        const hospitalizationsData = {
          ...this.patientHospitalizationsData,
          whenFirstHospitalized: patientHospitalized
            ? this.getHospitalizationDataValue("whenFirstHospitalized") : null,
          whenLastHospitalized: patientHospitalized
            ? this.getHospitalizationDataValue("whenLastHospitalized") : null,
          howManyHospitalizationsLastPeriod: patientHospitalized
            ? this.getHospitalizationDataValue("howManyHospitalizationsLastPeriod") : null,
          howLongHospitalizedLastPeriod: patientHospitalized
            ? this.getHospitalizationDataValue("howLongHospitalizedLastPeriod") : null,
        };
        await create("/api/patient/child/create", {
          childPatient: {
            patientId: patientId,
            ...this.patientPersonalData,
            birthdate: this.birthDateString(this.patientPersonalData.birthdate),
            registeredDate: this.registeredDateString(),
            livingAddress: {...this.patientLivingAddress},
            correspondenceAddress:
              this.patientDifferentCorrespondenceAddress
                ? this.patientCorrespondenceAddress
                : this.patientLivingAddress,
          },
          eligiblePerson: this.persons.map(person => ({
            name: person.name,
            surname: person.surname,
            pesel: person.pesel,
            address: person.address,
            type: person.type,
            description: person.description,
            phoneNumber: person.phoneNumber,
            email: person.email,
          })),
          socioDemographicData: this.socioDemographicData,
          laborMarketStatus: this.laborMarketStatus,
          patientParticipantStatusData: this.patientParticipantStatusData,
          hospitalizationsData,
          sourceOfInformation: this.sourceOfInformation,
          permissions: this.permissions
        });
        this.loading = false;
        this.showToast({
          message: "Dane zostały zapisane",
          variant: "success",
        });
        await this.$router.push({name: "patientCard", params: {patientId}});
      } catch (exception) {
        this.errors = processResponseException(exception);
        if (exception instanceof BadRequestException) {
          this.showToast({
            message: "Formularz zawiera błędy.\nUzupełnij dane aby zarejestrować pacjenta",
            variant: "danger",
          });
        } else {
          this.showToast({
            message: "Wystąpił błąd.\nDane nie zostały zapisane",
            variant: "danger",
          });
        }
      }
      this.loading = false;
    },
  },
}
</script>


