import {MutationTree} from "vuex";
import {SidebarState} from "./types";

export const mutations: MutationTree<SidebarState> = {
  toggleMenu(state) {
    state.menuOpened = !state.menuOpened;
  },
  closeMenu(state) {
    state.menuOpened = false;
  }
};
