export enum BmiCategory {
  SEVERE_THINNESS = "severe_thinness",
  MODERATE_THINNESS = "moderate_thinness",
  MILD_THINNESS = "mild_thinness",
  NORMAL = "normal",
  PRE_OBESE = "pre_obese",
  OBESE_CLASS_I = "obese_class_i",
  OBESE_CLASS_II = "obese_class_ii",
  OBESE_CLASS_III = "obese_class_iii",
}
