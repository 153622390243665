export const resortCodes = {
  "0010": "Poradnia (Gabinet) Lekarza Poz",
  "0011": "Poradnia (Gabinet) Lekarza Poz Dla Dzieci",
  "0012": "Poradnia (Gabinet) Lekarza Rodzinnego",
  "0020": "Punkt Felczerski",
  "0030": "Poradnia (Gabinet) Pielęgniarki I Położnej Środowiskowej",
  "0032": "Poradnia (Gabinet) Pielęgniarki Środowiskowej - Rodzinnej",
  "0034": "Poradnia (Gabinet) Położnej Środowiskowej - Rodzinnej",
  "0041": "Gabinet Medycyny Szkolnej",
  "0050": "Punkt Szczepień",
  "0051": "Punkt Szczepień Dla Dzieci",
  "0060": "Gabinet Zabiegowy",
  "0061": "Gabinet Zabiegowy Dla Dzieci",
  "1000": "Poradnia Chorób Wewnętrznych",
  "1001": "Poradnia Chorób Wewnętrznych Dla Dzieci",
  "1008": "Poradnia Chorób Metabolicznych",
  "1009": "Poradnia Chorób Metabolicznych Dla Dzieci",
  "1010": "Poradnia Alergologiczna",
  "1011": "Poradnia Alergologiczna Dla Dzieci",
  "1012": "Poradnia Alergii Pokarmowych",
  "1013": "Poradnia Alergii Pokarmowych Dla Dzieci",
  "1014": "Poradnia Alergii Oddechowych",
  "1015": "Poradnia Alergii Oddechowych Dla Dzieci",
  "1016": "Poradnia Alergii Skórnych",
  "1017": "Poradnia Alergii Skórnych Dla Dzieci",
  "1020": "Poradnia Diabetologiczna",
  "1021": "Poradnia Diabetologiczna Dla Dzieci",
  "1030": "Poradnia Endokrynologiczna",
  "1031": "Poradnia Endokrynologiczna Dla Dzieci",
  "1032": "Poradnia Endokrynologiczno-ginekologiczna",
  "1033": "Poradnia Endokrynologiczno-ginekologiczna Dla Dzieci",
  "1034": "Poradnia Andrologiczna",
  "1035": "Poradnia Andrologiczna Dla Dzieci",
  "1036": "Poradnia Leczenia Niepłodności",
  "1037": "Poradnia Leczenia Niepłodności Dla Dzieci",
  "1038": "Poradnia Schorzeń Tarczycy",
  "1039": "Poradnia Schorzeń Tarczycy Dla Dzieci",
  "1040": "Poradnia Endokrynologiczna Osteoporozy",
  "1041": "Poradnia Endokrynologiczna Osteoporozy Dla Dzieci",
  "1050": "Poradnia Gastroenterologiczna",
  "1051": "Poradnia Gastroenterologiczna Dla Dzieci",
  "1052": "Poradnia Gastrologiczna",
  "1053": "Poradnia Gastrologiczna Dla Dzieci",
  "1054": "Poradnia Chorób Jelitowych",
  "1055": "Poradnia Chorób Jelitowych Dla Dzieci",
  "1056": "Poradnia Hepatologiczna",
  "1057": "Poradnia Hepatologiczna Dla Dzieci",
  "1060": "Poradnia Geriatryczna",
  "1061": "Poradnia Geriatryczna Dla Dzieci",
  "1070": "Poradnia Hematologiczna",
  "1071": "Poradnia Hematologiczna Dla Dzieci",
  "1072": "Poradnia Nowotworów Krwi",
  "1073": "Poradnia Nowotworów Krwi Dla Dzieci",
  "1080": "Poradnia Immunologiczna",
  "1081": "Poradnia Immunologiczna Dla Dzieci",
  "1100": "Poradnia Kardiologiczna",
  "1101": "Poradnia Kardiologiczna Dla Dzieci",
  "1102": "Poradnia Wad Serca",
  "1103": "Poradnia Wad Serca Dla Dzieci",
  "1104": "Poradnia Nadciśnienia Tętniczego",
  "1105": "Poradnia Nadciśnienia Tętniczego Dla Dzieci",
  "1106": "Poradnia Zaburzeń Rytmu Serca",
  "1120": "Poradnia Chorób Naczyń",
  "1121": "Poradnia Chorób Naczyń Dla Dzieci",
  "1130": "Poradnia Nefrologiczna",
  "1131": "Poradnia Nefrologiczna Dla Dzieci",
  "1140": "Poradnia Medycyny Nuklearnej",
  "1141": "Poradnia Medycyny Nuklearnej Dla Dzieci",
  "1150": "Poradnia Toksykologiczna",
  "1151": "Poradnia Toksykologiczna Dla Dzieci",
  "1160": "Poradnia Medycyny Pracy",
  "1161": "Poradnia Medycyny Pracy Dla Dzieci",
  "1180": "Poradnia Medycyny Paliatywnej",
  "1181": "Poradnia Medycyny Paliatywnej Dla Dzieci",
  "1200": "Poradnia Dermatologiczna",
  "1201": "Poradnia Dermatologiczna Dla Dzieci",
  "1202": "Poradnia Wenerologiczna",
  "1203": "Poradnia Wenerologiczna Dla Dzieci",
  "1210": "Poradnia Genetyczna",
  "1211": "Poradnia Genetyczna Dla Dzieci",
  "1220": "Poradnia Neurologiczna",
  "1221": "Poradnia Neurologiczna Dla Dzieci",
  "1222": "Poradnia Leczenia Bólu",
  "1223": "Poradnia Leczenia Bólu Dla Dzieci",
  "1224": "Poradnia Chorób Naczyniowych Mózgu",
  "1225": "Poradnia Chorób Naczyniowych Mózgu Dla Dzieci",
  "1226": "Poradnia Padaczki",
  "1227": "Poradnia Padaczki Dla Dzieci",
  "1228": "Poradnia Parkinsonizmu I Chorób Układu Pozapiramidowego",
  "1229": "Poradnia Parkinsonizmu I Chorób Układu Pozapiramidowego Dla Dzieci",
  "1230": "Poradnia Stwardnienia Rozsianego",
  "1231": "Poradnia Stwardnienia Rozsianego Dla Dzieci",
  "1232": "Poradnia Chorób Mięśni",
  "1233": "Poradnia Chorób Mięśni Dla Dzieci",
  "1240": "Poradnia Onkologiczna",
  "1241": "Poradnia Onkologiczna Dla Dzieci",
  "1242": "Poradnia Chemioterapii",
  "1243": "Poradnia Chemioterapii Dla Dzieci",
  "1244": "Poradnia Radioterapii",
  "1245": "Poradnia Radioterapii Dla Dzieci",
  "1270": "Poradnia Gruźlicy I Chorób Płuc",
  "1271": "Poradnia Gruźlicy I Chorób Płuc Dla Dzieci",
  "1272": "Poradnia Pulmonologiczna",
  "1273": "Poradnia Pulmonologiczna Dla Dzieci",
  "1274": "Poradnia Ftyzjatryczna",
  "1275": "Poradnia Ftyzjatryczna Dla Dzieci",
  "1276": "Poradnia Leczenia Mukowiscydozy",
  "1277": "Poradnia Leczenia Mukowiscydozy Dla Dzieci",
  "1280": "Poradnia Reumatologiczna",
  "1281": "Poradnia Reumatologiczna Dla Dzieci",
  "1300": "Poradnia Rehabilitacyjna",
  "1301": "Poradnia Rehabilitacyjna Dla Dzieci",
  "1302": "Poradnia Rehabilitacji Narządu Ruchu",
  "1303": "Poradnia Rehabilitacji Narządu Ruchu Dla Dzieci",
  "1304": "Poradnia Zaopatrzenia Ortopedycznego",
  "1305": "Poradnia Zaopatrzenia Ortopedycznego Dla Dzieci",
  "1306": "Poradnia Rehabilitacji Neurologicznej",
  "1307": "Poradnia Rehabilitacji Neurologicznej Dla Dzieci",
  "1308": "Poradnia Rehabilitacji Kardiologicznej",
  "1309": "Poradnia Rehabilitacji Kardiologicznej Dla Dzieci",
  "1310": "Dział (Pracownia) Fizjoterapii",
  "1311": "Dział (Pracownia) Fizjoterapii Dla Dzieci",
  "1312": "Dział (Pracownia) Fizykoterapii",
  "1313": "Dział (Pracownia) Fizykoterapii Dla Dzieci",
  "1314": "Dział (Pracownia) Kinezyterapii",
  "1315": "Dział (Pracownia) Kinezyterapii Dla Dzieci",
  "1316": "Dział (Pracownia) Hydroterapii",
  "1317": "Dział (Pracownia) Hydroterapii Dla Dzieci",
  "1318": "Dział (Pracownia) Krioterapii",
  "1319": "Dział (Pracownia) Krioterapii Dla Dzieci",
  "1320": "Dział (Pracownia) Masażu Leczniczego",
  "1321": "Dział (Pracownia) Masażu Leczniczego Dla Dzieci",
  "1330": "Dział (Pracownia) Balneoterapii",
  "1340": "Poradnia Chorób Zakaźnych",
  "1341": "Poradnia Chorób Zakaźnych Dla Dzieci",
  "1342": "Poradnia Profilaktyczno-lecznicza (Hiv/Aids)",
  "1343": "Poradnia Aids Dla Dzieci",
  "1344": "Poradnia Wzw",
  "1345": "Poradnia Wzw Dla Dzieci",
  "1346": "Poradnia Chorób Tropikalnych",
  "1347": "Poradnia Chorób Tropikalnych Dla Dzieci",
  "1348": "Poradnia Chorób Odzwierzęcych I Pasożytniczych",
  "1349": "Poradnia Chorób Odzwierzęcych I Pasożytniczych Dla Dzieci",
  "1370": "Poradnia Medycyny Sportowej",
  "1371": "Poradnia Medycyny Sportowej Dla Dzieci",
  "1380": "Poradnia Leczenia Ran",
  "1381": "Poradnia Leczenia Ran Dla Dzieci",
  "1390": "Poradnia Leczenia Obrzęku Limfatycznego",
  "1391": "Poradnia Leczenia Obrzęku Limfatycznego Dla Dzieci",
  "1401": "Poradnia Pediatryczna",
  "1405": "Poradnia Zaburzeń I Wad Rozwojowych Dzieci",
  "1407": "Poradnia Autyzmu Dzieci",
  "1409": "Poradnia Pediatryczna Szczepień Dla Dzieci Z Grup Wysokiego",
  "1421": "Poradnia Neonatologiczna",
  "1450": "Poradnia Ginekologiczno-położnicza",
  "1452": "Poradnia Ginekologiczna",
  "1453": "Poradnia Ginekologiczna Dla Dziewcząt",
  "1454": "Poradnia Patologii Ciąży",
  "1456": "Poradnia Okresu Przekwitania",
  "1458": "Poradnia Profilaktyki Chorób Piersi",
  "1460": "Poradnia Ginekologii Onkologicznej",
  "1470": "Poradnia Planowania Rodziny I Rozrodczości",
  "1472": "Szkoła Rodzenia",
  "1474": "Poradnia Laktacyjna",
  "1500": "Poradnia Chirurgii Ogólnej",
  "1501": "Poradnia Chirurgii Ogólnej Dla Dzieci",
  "1504": "Poradnia Chirurgii Przewodu Pokarmowego",
  "1505": "Poradnia Chirurgii Przewodu Pokarmowego Dla Dzieci",
  "1506": "Poradnia Proktologiczna",
  "1507": "Poradnia Proktologiczna Dla Dzieci",
  "1508": "Poradnia Chirurgii Endokrynologicznej",
  "1509": "Poradnia Chirurgii Endokrynologicznej Dla Dzieci",
  "1520": "Poradnia Chirurgii Klatki Piersiowej",
  "1521": "Poradnia Chirurgii Klatki Piersiowej Dla Dzieci",
  "1530": "Poradnia Chirurgii Naczyniowej",
  "1531": "Poradnia Chirurgii Naczyniowej Dla Dzieci",
  "1540": "Poradnia Chirurgii Onkologicznej",
  "1541": "Poradnia Chirurgii Onkologicznej Dla Dzieci",
  "1550": "Poradnia Chirurgii Plastycznej",
  "1551": "Poradnia Chirurgii Plastycznej Dla Dzieci",
  "1552": "Poradnia Chirurgii Ręki",
  "1553": "Poradnia Chirurgii Ręki Dla Dzieci",
  "1554": "Poradnia Oparzeń",
  "1555": "Poradnia Oparzeń Dla Dzieci",
  "1560": "Poradnia Kardiochirurgiczna",
  "1561": "Poradnia Kardiochirurgiczna Dla Dzieci",
  "1570": "Poradnia Neurochirurgiczna",
  "1571": "Poradnia Neurochirurgiczna Dla Dzieci",
  "1580": "Poradnia Chirurgii Urazowo-ortopedycznej",
  "1581": "Poradnia Chirurgii Urazowo-ortopedycznej Dla Dzieci",
  "1583": "Poradnia Wad Postawy",
  "1584": "Poradnia Osteoporozy",
  "1587": "Poradnia Preluksacyjna",
  "1600": "Poradnia Okulistyczna",
  "1601": "Poradnia Okulistyczna Dla Dzieci",
  "1602": "Poradnia Leczenia Jaskry",
  "1603": "Poradnia Leczenia Jaskry Dla Dzieci",
  "1604": "Poradnia Leczenia Zeza",
  "1605": "Poradnia Leczenia Zeza Dla Dzieci",
  "1610": "Poradnia Otolaryngologiczna",
  "1611": "Poradnia Otolaryngologiczna Dla Dzieci",
  "1612": "Poradnia Audiologiczna",
  "1613": "Poradnia Audiologiczna Dla Dzieci",
  "1614": "Poradnia Foniatryczna",
  "1615": "Poradnia Foniatryczna Dla Dzieci",
  "1616": "Poradnia Logopedyczna",
  "1617": "Poradnia Logopedyczna Dla Dzieci",
  "1630": "Poradnia Chirurgii Szczękowo-twarzowej",
  "1631": "Poradnia Chirurgii Szczękowo-twarzowej Dla Dzieci",
  "1640": "Poradnia Urologiczna",
  "1641": "Poradnia Urologiczna Dla Dzieci",
  "1650": "Poradnia Transplantologiczna",
  "1651": "Poradnia Transplantologiczna Dla Dzieci",
  "1652": "Poradnia Transplantacji Nerek",
  "1653": "Poradnia Transplantacji Nerek Dla Dzieci",
  "1654": "Poradnia Transplantacji Wątroby",
  "1655": "Poradnia Transplantacji Wątroby Dla Dzieci",
  "1656": "Poradnia Transplantacji Serca",
  "1657": "Poradnia Transplantacji Serca Dla Dzieci",
  "1658": "Poradnia Transplantacji Szpiku",
  "1659": "Poradnia Transplantacji Szpiku Dla Dzieci",
  "1660": "Poradnia Transplantacji Trzustki",
  "1662": "Poradnia Transplantacji Płuc",
  "1700": "Poradnia Zdrowia Psychicznego",
  "1701": "Poradnia Zdrowia Psychicznego Dla Dzieci I Młodzieży",
  "1703": "Poradnia Zdrowia Psychicznego Dla Młodzieży",
  "1704": "Poradnia Psychogeriatryczna",
  "1705": "Poradnia Zdrowia Psychicznego Dla Dzieci, Młodzieży I Ich Rodzin",
  "1706": "Poradnia Leczenia Nerwic",
  "1707": "Poradnia Dla Osób Z Autyzmem Dziecięcym",
  "1710": "Poradnia Psychosomatyczna",
  "1711": "Poradnia Psychosomatyczna Dla Dzieci",
  "1712": "Poradnia Psychogeriatryczna",
  "1740": "Poradnia Leczenia Uzależnień",
  "1741": "Poradnia Leczenia Uzależnień Dla Dzieci",
  "1742": "Poradnia Antynikotynowa",
  "1743": "Poradnia Terapii Uzależnień Od Alkoholu Dla Dzieci I Młodzieży",
  "1744": "Poradnia Terapii Uzależnienia I Współuzależnienia Od Alkoholu",
  "1745": "Poradnia Terapii Uzależnienia I Współuzależnienia Od Alkoholu Dla Dzieci",
  "1746": "Poradnia Terapii Uzależnienia Od Substancji Psychoaktywnych",
  "1747": "Poradnia Terapii Uzależnienia Od Substancji Psychoaktywnych Dla Dzieci",
  "1750": "Poradnia Promocji Zdrowia",
  "1751": "Poradnia Promocji Zdrowia Dla Dzieci",
  "1780": "Poradnia Seksuologiczna I Patologii Współżycia",
  "1790": "Poradnia Psychologiczna",
  "1791": "Poradnia Psychologiczna Dla Dzieci",
  "1792": "Poradnia Psychologiczna Dla Kobiet Ciężarnych",
  "1800": "Poradnia Stomatologiczna",
  "1801": "Poradnia Stomatologiczna Dla Dzieci",
  "1802": "Poradnia Stomatologiczna Dla Osób Z Grupy Ryzyka Hiv",
  "1804": "Poradnia Stomatologii Zachowawczej",
  "1810": "Poradnia Chorób Błon Śluzowych Przyzębia",
  "1811": "Poradnia Chorób Błon Śluzowych Przyzębia Dla Dzieci",
  "1820": "Poradnia Ortodontyczna",
  "1821": "Poradnia Ortodontyczna Dla Dzieci",
  "1830": "Poradnia Protetyki Stomatologicznej",
  "1831": "Poradnia Protetyki Stomatologicznej Dla Dzieci",
  "1840": "Poradnia Chirurgii Stomatologicznej",
  "1841": "Poradnia Chirurgii Stomatologicznej Dla Dzieci",
  "1850": "Poradnia Stomatologicznej Pomocy Doraźnej",
  "1860": "Poradnia Profilaktyki Stomatologicznej",
  "1890": "Poradnia Protetyki Ponowotworowej",
  "1900": "Poradnia Leczenia Mukowiscydozy",
  "1910": "Poradnia Szczepień Dla Dzieci Wysokiego Ryzyka",
  "1920": "Poradnia Pediatryczna Szczepień Dla Dzieci Z Grup Wysokiego",
  "2130": "Zespół Domowej Dializoterapii Otrzewnowej",
  "2131": "Zespół Domowej Dializoterapii Otrzewnowej Dla Dzieci",
  "2140": "Zespół Długoterminowej Opieki Domowej",
  "2141": "Zespół Długoterminowej Opieki Domowej Dla Dzieci",
  "2142": "Pielęgniarska Opieka Długoterminowa",
  "2143": "Pielęgniarska Opieka Długoterminowa Dla Dzieci",
  "2146": "Zespół Rehabilitacji Domowej",
  "2147": "Zespół Rehabilitacji Domowej Dla Dzieci",
  "2150": "Zespół Domowego Leczenia Tlenem",
  "2151": "Zespół Domowego Leczenia Tlenem Dla Dzieci",
  "2180": "Hospicja Domowe",
  "2181": "Hospicja Domowe Dla Dzieci",
  "2182": "Dzienny Ośrodek Opieki Paliatywnej Lub Hospicyjnej",
  "2183": "Dzienny Ośrodek Opieki Paliatywnej Lub Hospicyjnej Dla Dzieci",
  "2200": "Dzienny Ośrodek Opieki Geriatrycznej",
  "2201": "Dzienny Ośrodek Opieki Geriatrycznej Dla Dzieci",
  "2202": "Geriatryczny Zespół Opieki Domowej",
  "2203": "Geriatryczny Zespół Opieki Domowej Dla Dzieci",
  "2204": "Geriatryczny Zespół Konsultacyjny Dla Lecznictwa Zamkniętego",
  "2205": "Geriatryczny Zespół Konsultacyjny Dla Lecznictwa Zamkniętego Dla Dzieci",
  "2300": "Ośrodek Rehabilitacji Dziennej",
  "2301": "Ośrodek Rehabilitacji Dziennej Dla Dzieci",
  "2400": "Izba Chorych",
  "2500": "Oddział/Zespół Chirurgii Jednego Dnia",
  "2700": "Oddział Dzienny Psychiatryczny (Ogólny)",
  "2701": "Oddział Dzienny Psychiatryczny Dla Dzieci I Młodzieży",
  "2702": "Oddział Dzienny Psychiatryczny Rehabilitacyjny",
  "2703": "Oddział Dzienny Psychiatryczny Rehabilitacyjny Dla Dzieci",
  "2704": "Oddział Dzienny Psychiatryczny Geriatryczny",
  "2705": "Oddział Dzienny Psychiatryczny Geriatryczny Dla Dzieci",
  "2706": "Oddział Dzienny Zaburzeń Nerwicowych",
  "2707": "Oddział Dzienny Zaburzeń Nerwicowych Dla Dzieci I Młodzieży",
  "2709": "Oddział Dzienny Dla Osób Z Autyzmem Dziecięcym",
  "2710": "Oddział/Ośrodek Alzheimerowski",
  "2711": "Oddział Dzienny Terapii Uzależnienia Od Alkoholu Dla Dzieci I",
  "2712": "Oddział Dzienny Terapii Uzależnienia Od Alkoholu",
  "2713": "Oddział Dzienny Terapii Uzależnienia Od Środków Psychoaktywnych Dla Dzieci I Młodzieży",
  "2714": "Oddział Dzienny Terapii Uzależnienia Od Substancji",
  "2716": "Oddział Nocny Psychiatryczny",
  "2718": "Oddział Nocny Psychiatryczny Dla Uzależnionych",
  "2720": "Hostel Dla Osób Z Zaburzeniami Psychicznymi",
  "2721": "Hostel Dla Dzieci Z Zaburzeniami Psychicznymi",
  "2722": "Hostel Dla Osób Psychicznie Chorych",
  "2724": "Hostel Dla Uzależnionych Od Alkoholu",
  "2726": "Hostel Dla Uzależnionych Od Substancji Psychoaktywnych",
  "2727": "Hostel Dla Uzależnionych Od Substancji Psychoaktywnych Dla",
  "2730": "Zespół Leczenia Środowiskowego (Domowego)",
  "2731": "Zespół Leczenia Środowiskowego (Domowego) Dla Dzieci I Młodzieży",
  "2732": "Opieka Domowa, Rodzinna",
  "2733": "Zespół Leczenia Środowiskowego (Domowego) Dla Osób Z Autyzmem",
  "2740": "Ośrodek Leczenia Uzależnień, Bliżej Niescharakteryzowanych",
  "2741": "Ośrodek Leczenia Uzależnień, Bliżej Niescharakteryzowanych Dla Dzieci",
  "3010": "Ambulatorium Ogólne",
  "3011": "Ambulatorium Pediatryczne",
  "3012": "Ambulatorium Chirurgiczne",
  "3013": "Ambulatorium Chirurgiczne Dla Dzieci",
  "3014": "Ambulatorium Kardiologiczne",
  "3015": "Ambulatorium Kardiologiczne Dla Dzieci",
  "3016": "Ambulatorium Okulistyczne",
  "3017": "Ambulatorium Okulistyczne Dla Dzieci",
  "3040": "Ambulatorium Stomatologiczne",
  "3041": "Ambulatorium Stomatologiczne Dla Dzieci",
  "3110": "Zespół Wyjazdowy Ogólny",
  "3112": "Zespół Wyjazdowy Wypadkowy W",
  "3114": "Zespół Wyjazdowy Reanimacyjny R",
  "3116": "Zespół Wyjazdowy Kardiologiczny K",
  "3118": "Zespół Wyjazdowy Neonatologiczny N",
  "3150": "Wodne Pogotowie Ratunkowe",
  "3200": "Lotniczy Zespół Transportu Sanitarnego",
  "3210": "Lotniczy Zespół Ratownictwa Medycznego",
  "3220": "Lotnicza Grupa Poszukiwawczo-ratownicza",
  "3300": "Szpitalny Oddział Ratunkowy",
  "3301": "Szpitalny Oddział Ratunkowy Dla Dzieci",
  "3310": "Ewakuacyjna Izba Ratunkowa",
  "4000": "Oddział Chorób Wewnętrznych",
  "4001": "Oddział Chorób Wewnętrznych Dla Dzieci",
  "4008": "Oddział Chorób Metabolicznych",
  "4009": "Oddział Chorób Metabolicznych Dla Dzieci",
  "4010": "Oddział Alergologiczny",
  "4011": "Oddział Alergologiczny Dla Dzieci",
  "4013": "Oddział Alergologiczny Dziecięcy",
  "4020": "Oddział Diabetologiczny",
  "4021": "Oddział Diabetologiczny Dla Dzieci",
  "4030": "Oddział Endokrynologiczny",
  "4031": "Oddział Endokrynologiczny Dla Dzieci",
  "4050": "Oddział Gastroenterologiczny",
  "4051": "Oddział Gastroenterologiczny Dla Dzieci",
  "4052": "Oddział Gastrologiczny",
  "4053": "Oddział Gastrologiczny Dla Dzieci",
  "4056": "Oddział Hepatologiczny",
  "4057": "Oddział Hepatologiczny Dla Dzieci",
  "4060": "Oddział Geriatryczny",
  "4070": "Oddział Hematologiczny",
  "4071": "Oddział Hematologiczny Dla Dzieci",
  "4072": "Oddział Nowotworów Krwi",
  "4073": "Oddział Nowotworów Krwi Dla Dzieci",
  "4080": "Oddział Immunologii Klinicznej",
  "4081": "Oddział Immunologii Klinicznej Dla Dzieci",
  "4100": "Oddział Kardiologiczny",
  "4101": "Oddział Kardiologiczny Dla Dzieci",
  "4106": "Oddział Intensywnego Nadzoru Kardiologicznego",
  "4107": "Oddział Intensywnego Nadzoru Kardiologicznego Dla Dzieci",
  "4120": "Oddział Angiologiczny",
  "4121": "Oddział Angiologiczny Dla Dzieci",
  "4130": "Oddział Nefrologiczny",
  "4131": "Oddział Nefrologiczny Dla Dzieci",
  "4132": "Stacja Dializ",
  "4133": "Stacja Dializ Dla Dzieci",
  "4140": "Oddział Medycyny Nuklearnej",
  "4141": "Oddział Medycyny Nuklearnej Dla Dzieci",
  "4142": "Oddział Terapii Jodem",
  "4143": "Oddział Terapii Jodem Dla Dzieci",
  "4150": "Oddział Toksykologiczny",
  "4151": "Oddział Toksykologiczny Dla Dzieci",
  "4170": "Oddział Dla Przewlekle Chorych",
  "4171": "Oddział Dla Przewlekle Chorych Dla Dzieci",
  "4180": "Oddział Medycyny Paliatywnej",
  "4181": "Oddział Medycyny Paliatywnej Dla Dzieci",
  "4200": "Oddział Dermatologiczny",
  "4201": "Oddział Dermatologiczny Dla Dzieci",
  "4220": "Oddział Neurologiczny",
  "4221": "Oddział Neurologiczny Dla Dzieci",
  "4222": "Oddział Udarowy",
  "4240": "Oddział Onkologiczny",
  "4241": "Oddział Onkologiczny Dla Dzieci",
  "4242": "Oddział Onkologii Klinicznej/Chemioterapii",
  "4243": "Oddział Chemioterapii Dla Dzieci",
  "4244": "Oddział Radioterapii",
  "4245": "Oddział Radioterapii Dla Dzieci",
  "4246": "Oddział Onkologii Ginekologicznej",
  "4247": "Oddział Onkologii Ginekologicznej Dla Dzieci",
  "4250": "Oddział Ginekologii Onkologicznej",
  "4260": "Oddział Anestezjologii I Intensywnej Terapii",
  "4261": "Oddział Anestezjologii I Intensywnej Terapii Dla Dzieci",
  "4262": "Dział Anestezjologii I Intensywnej Terapii",
  "4264": "Oddział Intensywnej Terapii",
  "4265": "Oddział Intensywnej Terapii Dla Dzieci",
  "4266": "Dział Anestezjologii",
  "4267": "Dział Anestezjologii Dla Dzieci",
  "4270": "Oddział Gruźlicy I Chorób Płuc",
  "4271": "Oddział Gruźlicy I Chorób Płuc Dla Dzieci",
  "4272": "Oddział Pulmonologii",
  "4273": "Oddział Pulmonologii Dla Dzieci",
  "4274": "Oddział Gruźlicy",
  "4275": "Oddział Gruźlicy Dla Dzieci",
  "4280": "Oddział Reumatologiczny",
  "4281": "Oddział Reumatologiczny Dla Dzieci",
  "4300": "Oddział Rehabilitacyjny",
  "4301": "Oddział Rehabilitacyjny Dla Dzieci",
  "4302": "Oddział Rehabilitacji Narządu Ruchu",
  "4303": "Oddział Rehabilitacji Narządu Ruchu Dla Dzieci",
  "4304": "Oddział Paraplegii I Tetraplegii",
  "4305": "Oddział Paraplegii I Tetraplegii Dla Dzieci",
  "4306": "Oddział Rehabilitacji Neurologicznej",
  "4307": "Oddział Rehabilitacji Neurologicznej Dla Dzieci",
  "4308": "Oddział Rehabilitacji Kardiologicznej",
  "4309": "Oddział Rehabilitacji Kardiologicznej Dla Dzieci",
  "4340": "Oddział Chorób Zakaźnych",
  "4341": "Oddział Chorób Zakaźnych Dla Dzieci",
  "4342": "Oddział Aids",
  "4343": "Oddział Aids Dla Dzieci",
  "4344": "Oddział Wzw",
  "4345": "Oddział Wzw Dla Dzieci",
  "4346": "Oddział Chorób Tropikalnych",
  "4347": "Oddział Chorób Tropikalnych Dla Dzieci",
  "4348": "Oddział Obserwacyjno-zakaźny",
  "4349": "Oddział Obserwacyjno-zakaźny Dla Dzieci",
  "4401": "Oddział Pediatryczny",
  "4403": "Oddział Niemowlęcy",
  "4405": "Oddział Patologii Noworodka",
  "4421": "Oddział Neonatologiczny",
  "4423": "Oddział Intensywnej Terapii Noworodka",
  "4450": "Oddział Ginekologiczno-położniczy",
  "4452": "Oddział Ginekologiczny",
  "4453": "Oddział Ginekologiczny Dla Dzieci",
  "4454": "Oddział Patologii Ciąży",
  "4456": "Oddział Położniczy",
  "4458": "Oddział Położniczy Rooming-in",
  "4500": "Oddział Chirurgiczny Ogólny",
  "4501": "Oddział Chirurgiczny Dla Dzieci",
  "4503": "Oddział Chirurgii Noworodka",
  "4504": "Oddział Chirurgii Przewodu Pokarmowego",
  "4505": "Oddział Chirurgii Przewodu Pokarmowego Dla Dzieci",
  "4508": "Oddział Chirurgii Endokrynologicznej",
  "4509": "Oddział Chirurgii Endokrynologicznej Dla Dzieci",
  "4520": "Oddział Chirurgii Klatki Piersiowej",
  "4521": "Oddział Chirurgii Klatki Piersiowej Dla Dzieci",
  "4530": "Oddział Chirurgii Naczyniowej",
  "4531": "Oddział Chirurgii Naczyniowej Dla Dzieci",
  "4540": "Oddział Chirurgii Onkologicznej",
  "4541": "Oddział Chirurgii Onkologicznej Dla Dzieci",
  "4550": "Oddział Chirurgii Plastycznej",
  "4551": "Oddział Chirurgii Plastycznej Dla Dzieci",
  "4552": "Oddział Chirurgii Ręki",
  "4553": "Oddział Chirurgii Ręki Dla Dzieci",
  "4554": "Oddział Oparzeń",
  "4555": "Oddział Oparzeń Dla Dzieci",
  "4560": "Oddział Kardiochirurgiczny",
  "4561": "Oddział Kardiochirurgiczny Dla Dzieci",
  "4562": "Oddział Wszczepiania Rozruszników",
  "4563": "Oddział Wszczepiania Rozruszników Dla Dzieci",
  "4570": "Oddział Neurochirurgiczny",
  "4571": "Oddział Neurochirurgiczny Dla Dzieci",
  "4580": "Oddział Chirurgii Urazowo-ortopedycznej",
  "4581": "Oddział Chirurgii Urazowo-ortopedycznej Dla Dzieci",
  "4600": "Oddział Okulistyczny",
  "4601": "Oddział Okulistyczny Dla Dzieci",
  "4610": "Oddział Otolaryngologiczny",
  "4611": "Oddział Otolaryngologiczny Dla Dzieci",
  "4620": "Oddział Audiologiczno- Foniatryczny",
  "4630": "Oddział Chirurgii Szczękowo-twarzowej",
  "4631": "Oddział Chirurgii Szczękowo-twarzowej Dla Dzieci",
  "4640": "Oddział Urologiczny",
  "4641": "Oddział Urologiczny Dla Dzieci",
  "4650": "Oddział Transplantologiczny",
  "4651": "Oddział Transplantologiczny Dla Dzieci",
  "4652": "Oddział Transplantacji Nerek",
  "4653": "Oddział Transplantacji Nerek Dla Dzieci",
  "4654": "Oddział Transplantacji Wątroby",
  "4655": "Oddział Transplantacji Wątroby Dla Dzieci",
  "4656": "Oddział Transplantacji Serca",
  "4657": "Oddział Transplantacji Serca Dla Dzieci",
  "4658": "Oddział Transplantacji Szpiku",
  "4659": "Oddział Transplantacji Szpiku Dla Dzieci",
  "4660": "Oddział Transplantacji Trzustki",
  "4662": "Oddział Transplantacji Płuc",
  "4700": "Oddział Psychiatryczny(Ogólny)",
  "4701": "Oddział Psychiatryczny Dla Dzieci",
  "4702": "Oddział Rehabilitacji Psychiatrycznej",
  "4703": "Oddział Psychiatryczny Dla Młodzieży",
  "4704": "Oddział Leczenia Zaburzeń Nerwicowych",
  "4705": "Oddział Psychiatryczny Dla Dzieci I Młodzieży",
  "4706": "Oddział Leczenia Zaburzeń Nerwicowych",
  "4707": "Oddział Leczenia Zaburzeń Nerwicowych Dla Dzieci I Młodzieży",
  "4708": "Oddział - Hostel Psychiatryczny",
  "4710": "Oddział Psychiatryczny Dla Chorych Somatycznie",
  "4711": "Oddział Psychiatryczny Dla Chorych Somatycznie Dla Dzieci",
  "4712": "Oddział Psychogeriatryczny",
  "4714": "Oddział Psychiatryczny Dla Chorych Na Gruźlicę",
  "4715": "Oddział Psychiatryczny Dla Chorych Na Gruźlicę Dla Dzieci",
  "4716": "Oddział Psychiatryczny Dla Przewlekle Chorych",
  "4717": "Oddział Psychiatryczny Dla Przewlekle Chorych Dla Dzieci",
  "4730": "Oddział Psychiatrii Sądowej",
  "4731": "Oddział Psychiatrii Sądowej Dla Młodzieży",
  "4732": "Oddział Psychiatrii Sądowej O Wzmocnionym Zabezpieczeniu",
  "4733": "Oddział Psychiatrii Sądowej O Wzmocnionym Zabezpieczeniu Dla Młodzieży",
  "4734": "Oddział Psychiatrii Sądowej O Maksymalnym Zabezpieczeniu",
  "4735": "Oddział Psychiatrii Sądowej O Maksymalnym Zabezpieczeniu Dla Młodzieży",
  "4736": "Oddział Odwykowy O Wzmocnionym Zabezpieczeniu",
  "4737": "Oddział Odwykowy O Wzmocnionym Zabezpieczeniu Dla Młodzieży",
  "4740": "Oddział/Ośrodek Leczenia Uzależnień",
  "4741": "Oddział/Ośrodek Leczenia Uzależnień Dla Dzieci I Młodzieży",
  "4742": "Oddział/Ośrodek Leczenia Alkoholowych Zespołów Abstynencyjnych(Detoksykacji)",
  "4744": "Oddział/Ośrodek Terapii Uzależnienia Od Alkoholu",
  "4745": "Oddział/Ośrodek Terapii Uzależnienia Od Alkoholu Dla Dzieci I Młodzieży",
  "4746": "Oddział/Ośrodek Terapii Uzależnienia Od Substancji Psychoaktywnych",
  "4747": "Oddział/Ośrodek Terapii Uzależnienia Od Substancji Psychoaktywnych Dla Dzieci I Młodzieży",
  "4748": "Oddział/Ośrodek Leczenia Zespołów Abstynencyjnych Po Substancjach Psychoaktywnych (Detoksykacji)",
  "4750": "Oddział/Ośrodek Rehabilitacyjny Dla Uzależnionych Od Substancji Psychoaktywnych",
  "4751": "Oddział/Ośrodek Rehabilitacyjny Dla Uzależnionych Od Substancji Psychoaktywnych Dla Dzieci I Młodzie",
  "4752": "Oddział/Ośrodek Terapii Dla Uzależnionych Od Alkoholu Ze Współistniejącymi Zaburzeniami Psychotyczny",
  "4754": "Oddział/Ośrodek Terapii Dla Uzależnionych Od Substancji Psychoaktywnych Ze Współistniejącymi Zaburze",
  "4756": "Oddział/Ośrodek Rehabilitacji Dla Uzależnionych Od Substancji Psychoaktywnych Ze Współistniejącymi Z",
  "4840": "Oddział Chirurgii Stomatologicznej",
  "4841": "Oddział Chirurgii Stomatologicznej Dla Dzieci",
  "4880": "Zakład/Oddział Medycyny Sądowej",
  "4900": "Izba Przyjęć Szpitala",
  "4901": "Izba Przyjęć Szpitala Dla Dzieci",
  "4910": "Blok Operacyjny",
  "4911": "Blok Operacyjny Dla Dzieci",
  "4912": "Sala Porodowa",
  "4920": "Apteka Szpitalna/ Zakładowa",
  "4930": "Sterylizatornia",
  "4940": "Prosektorium",
  "4950": "Zakład Patomorfologii",
  "5160": "Zakład/Oddział Pielęgnacyjno-opiekuńczy",
  "5161": "Zakład/Oddział Pielęgnacyjno-opiekuńczy Dla Dzieci",
  "5162": "Zakład/Oddział Pielęgnacyjno Opiekuńczy Psychiatryczny",
  "5163": "Zakład/Oddział Pielęgnacyjno Opiekuńczy Psychiatryczny Dla Dzieci I Młodzieży",
  "5170": "Zakład/Oddział Opiekuńczo-leczniczy",
  "5171": "Zakład/Oddział Opiekuńczo-leczniczy Dla Dzieci I Młodzieży",
  "5172": "Zakład/Oddział Opiekuńczo-leczniczy Psychiatryczny",
  "5173": "Zakład/Oddział Opiekuńczo-leczniczy Psychiatryczny Dla Dzieci",
  "5180": "Hospicja Stacjonarne",
  "5181": "Hospicja Stacjonarne Dla Dzieci",
  "5260": "Zakład/Oddział Dzienny Pielęgnacyjno-opiekuńczy Psychiatryczny",
  "5272": "Zakład/Oddział Dzienny Opiekuńczo-leczniczy Psychiatryczny",
  "5360": "Zespół Opieki Domowej Przy Zakładzie/Oddziale Pielęgnacyjnoopiekuńczym Lub Opiekuńczo-leczniczym Ps",
  "6041": "Do Wyjaśnienia",
  "6100": "Szpital (Oddział) Uzdrowiskowy",
  "6101": "Szpital (Oddział) Uzdrowiskowy Dla Dzieci",
  "6500": "Sanatorium (Oddział) Uzdrowiskowe",
  "6501": "Sanatorium (Oddział) Uzdrowiskowe Dla Dzieci",
  "6600": "Przychodnia Uzdrowiskowa",
  "7000": "Pracownia Diagnostyczna",
  "7001": "Pracownia Diagnostyczna Dla Dzieci",
  "7100": "Pracownia Diagnostyki Laboratoryjnej (Laboratorium)",
  "7101": "Pracownia Diagnostyki Laboratoryjnej (Laboratorium) Dla Dzieci",
  "7110": "Punkt Pobrań Materiałów Do Badań",
  "7111": "Punkt Pobrań Materiałów Do Badań Dla Dzieci",
  "7120": "Pracownia Diagnostyki Mikrobiologicznej",
  "7200": "Pracownia Diagnostyki Obrazowej",
  "7201": "Pracownia Diagnostyki Obrazowej Dla Dzieci",
  "7210": "Pracownia Usg",
  "7211": "Pracownia Usg Dla Dzieci",
  "7220": "Pracownia Tomografii Komputerowej",
  "7221": "Pracownia Tomografii Komputerowej Dla Dzieci",
  "7230": "Pracownia Rentgenodiagnostyki Zabiegowej",
  "7231": "Pracownia Rentgenodiagnostyki Zabiegowej Dla Dzieci",
  "7240": "Pracownia Rentgenodiagnostyki Ogólnej",
  "7241": "Pracownia Rentgenodiagnostyki Ogólnej Dla Dzieci",
  "7250": "Pracownia Rezonansu Magnetycznego",
  "7251": "Pracownia Rezonansu Magnetycznego Dla Dzieci",
  "7260": "Pracownia Scyntygrafii",
  "7261": "Pracownia Scyntygrafii Dla Dzieci",
  "7270": "Pracownia Angiografii",
  "7271": "Pracownia Angiografii Dla Dzieci",
  "7900": "Pracownie Inne",
  "7901": "Pracownie Inne Dla Dzieci",
  "7910": "Pracownia Endoskopii",
  "7911": "Pracownia Endoskopii Dla Dzieci",
  "7920": "Pracownia Bronchoskopii",
  "7930": "Diagnostyka Mikrobiologiczna",
  "7940": "Pracownia Mammografii",
  "7950": "Zakład Medycyny Nuklearnej",
  "7958": "Pracownie Protetyczne, Środków Pomocniczych, Krwi I Tkanek",
  "8100": "Pracownia Protetyki Stomatologicznej",
  "8200": "Pracownia (Punkt Zaopatrzenia) Środków Pomocniczych",
  "8210": "Pracownia (Punkt Zaopatrzenia) W Środki Wspomagania Słuchu",
  "8220": "Pracownia (Punkt Zaopatrzenia) W Środki Optyczne",
  "8250": "Centrum Krwiodawstwa I Krwiolecznictwa",
  "8500": "Bank Krwi",
  "8502": "Punkt Pobrań Krwi",
  "8510": "Bank Tkanek",
  "8512": "Punkt Pobrań Tkanek I Szpiku Kostnego",
  "8520": "Dział Krwiodawstwa I Krwiolecznictwa",
  "8529": "Inne Komórki Działalności Medycznej W Zakładach Opieki",
  "9000": "Inna I Nieokreślona Komórka Działalności Medycznej",
  "9001": "Inna I Nieokreślona Komórka Działalności Medycznej Dla Dzieci",
  "9010": "Pracownia Akupresury I Akupunktury",
  "9012": "Pracownia Ziołolecznictwa",
  "9200": "Dział Higieny I Epidemiologii",
  "9202": "Dział Nadzoru Radiologicznego",
  "9204": "Dział Nadzoru Sanitarnego",
  "9206": "Dział Nadzoru Zapobiegawczego",
  "9210": "Dział Oświaty I Promocji Zdrowia",
  "9220": "Dział Żywności, Żywienia, Przedmiotów Użytku",
  "9230": "Dział Orzecznictwa O Stanie Zdrowia",
  "9240": "Zespół Transportu Sanitarnego",
  "9300": "Oddział Żłobkowy",
  "9400": "Szpilany Zespół Wspierający Opieki Paliatywnej",
  "9600": "Pielęgniarska Opieka Długoterminowa",
  "9740": "Poradnia Protez Twarzy",
  "9996": "Gabinet Położnej Poz",
  "9997": "Gabinet Pielęgniarski Poz",
  "9998": "Gabinet Profilaktyki Zdrowotnej I Pomocy Przedlekarskiej",
  "9999": "Realizator Zaopatrzenia W Wyroby Medyczne Będące Przedmiotami Ortopedycznymi Lub Środkami Pomocniczy",
}
