export const attachmentCategories: string[] = [
  "survey",
  "medical_examination",
  "outgoing_documents",
  "information_card",
  "registration_card",
  "opinion",
  "court_letters",
  "prescription",
  "authorization",
  "appointment",
  "consent_of_legal_guardians",
  "developmental_interview",
  "other",
];
