var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('card',{attrs:{"loading":_vm.loading,"title":""}},[_c('pagination-description',{attrs:{"items-count":_vm.items.length,"page":_vm.currentPage,"per-page":_vm.pagination.perPage,"total-count":_vm.pagination.totalRows}}),_c('b-table',{attrs:{"fields":_vm.fields,"items":_vm.itemsWithDetails,"empty-text":"Brak pacjentów","hover":"","responsive":true,"show-empty":""},scopedSlots:_vm._u([{key:"cell(show_details)",fn:function(ref){
var detailsShowing = ref.detailsShowing;
var toggleDetails = ref.toggleDetails;
return [_c('b-button',{staticClass:"mr-2",attrs:{"size":"sm"},on:{"click":function($event){$event.stopPropagation();return toggleDetails($event)}}},[_vm._v(" "+_vm._s(detailsShowing ? 'Ukryj' : 'Pokaż')+" Wizyty ")])]}},{key:"row-details",fn:function(ref){
var visitList = ref.item.visitList;
return [_c('div',{staticClass:"m-l-15"},[_c('b-table',{attrs:{"fields":_vm.visitFields,"items":visitList,"empty-text":"Brak wizyt","hover":"","responsive":true,"show-empty":""},scopedSlots:_vm._u([{key:"cell(status)",fn:function(ref){
var status = ref.item.status;
return [_c('b-badge',{staticClass:"status-badge",attrs:{"variant":_vm.getBadgeVariant(status)}},[_vm._v(" "+_vm._s(status)+" ")])]}}],null,true)})],1)]}}])}),_c('pagination-description',{attrs:{"items-count":_vm.items.length,"page":_vm.currentPage,"per-page":_vm.pagination.perPage,"total-count":_vm.pagination.totalRows}},[_c('b-pagination',{staticClass:"mb-0",attrs:{"value":_vm.currentPage,"per-page":_vm.pagination.perPage,"total-rows":_vm.pagination.totalRows,"align":"right"},on:{"input":_vm.changePage}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }