const labels = {
  "raw-converted" : ["Wynik surowy","Wynik przeliczony"],
  "centyl-devAge" : ["Centyl", "Wiek rozwojowy"],
  "raw-iq" : ["Wynik surowy", "IQ"],
  "raw-rating": ["Wynik surowy", "Ocena wyniku"],
  "communication-motorSkills" : ["Komunikacja", "Motoryka"],
  "converted-iq": ["Wynik przeliczony", "IQ"],
  "sumConverted-composite":["Suma wyników przeliczonych", "Wynik złożony"],
  "sumConverted-indicator":["Suma wyników przeliczonych", "Wynik we wskaźniku"],
  "raw-standardized": ["Wynik surowy", "Wynik standaryzowany"],
  "raw-sumStandardized": ["Wynik surowy", "Wynik przeliczony standaryzowany"],
  "raw-basicProportion": ["Wynik surowy", "Proporcja podstawowa"],
  "raw": ["Wynik surowy"],
  "algorithm": ["Algorytm"],
  "scale-as-label" : ["Wartość"]
};

export function getLabel(labelType: string, labelIndex: number): string{
  return labels[labelType][labelIndex];
}

export function getScaleInputs(labelType:string): []{
  return labels[labelType];
}
