<template>
  <div class="card-box">
    <b-form-group
      label="Wywiad"
    >
      <textarea-counter
        :id="'interview-'+id"
        v-model="interview"
        :max-characters-count="10000"
        :disabled="readOnly"
        :state="state('interview')"
        rows="3"
        @focus="clearErrors('interview')"
        @input="updateTextValue($event, 'interview')"
      />
      <module-error-message :messages="errors && errors.interview" />
      <template
        v-if="ipzOpened"
      >
        <b-checkbox
          :id="'syncInterview-'+id"
          v-model="syncInterview"
          :disabled="readOnly"
          :state="state('syncInterview')"
          rows="3"
          @focus="clearErrors('syncInterview')"
          @input="updateBooleanValue($event, 'syncInterview')"
        >
          Zleć synchronizację Wywiadu z zamkniętej wizyty do IPZ
        </b-checkbox>
        <module-error-message :messages="errors && errors.syncInterview" />
      </template>
    </b-form-group>
    <b-form-group
      label="Badanie"
    >
      <textarea-counter
        :id="'examination-'+id"
        v-model="examination"
        :max-characters-count="10000"
        :disabled="readOnly"
        :state="state('examination')"
        rows="3"
        @focus="clearErrors('examination')"
        @input="updateTextValue($event, 'examination')"
      />
      <module-error-message :messages="errors && errors.examination" />
      <template
        v-if="ipzOpened"
      >
        <b-checkbox
          :id="'syncExamination-'+id"
          v-model="syncExamination"
          :disabled="readOnly"
          :state="state('syncExamination')"
          rows="3"
          @focus="clearErrors('syncExamination')"
          @input="updateBooleanValue($event, 'syncExamination')"
        >
          Zleć synchronizację Badania z zamkniętej wizyty do IPZ
        </b-checkbox>
        <module-error-message :messages="errors && errors.syncExamination" />
      </template>
    </b-form-group>
    <is-granted
      v-if="appointmentStatus==='finished'"
      :privileges="['PRINT_APPOINTMENT_RECOMMENDATIONS']"
    >
      <a
        :href="`/api/appointment/document/recommendations/${appointmentDocumentId}/print`"
        class="btn btn-sm btn-secondary"
        target="_blank"
      >
        <i class="fa fa-print" />
        Wydrukuj
      </a>
    </is-granted>
    <b-form-group
      label="Zalecenia"
    >
      <textarea-counter
        :id="'recommendation-'+id"
        v-model="recommendation"
        :max-characters-count="10000"
        :disabled="readOnly"
        :state="state('recommendation')"
        rows="3"
        @focus="clearErrors('recommendation')"
        @input="updateTextValue($event, 'recommendation')"
      />
      <module-error-message :messages="errors && errors.recommendation" />
      <template
        v-if="ipzOpened"
      >
        <b-checkbox
          :id="'syncRecommendation-'+id"
          v-model="syncRecommendation"
          :disabled="readOnly"
          :state="state('syncRecommendation')"
          rows="3"
          @focus="clearErrors('syncRecommendation')"
          @input="updateBooleanValue($event, 'syncRecommendation')"
        >
          Zleć synchronizację Zaleceń z zamkniętej wizyty do IPZ
        </b-checkbox>
        <module-error-message :messages="errors && errors.syncRecommendation" />
      </template>
    </b-form-group>
  </div>
</template>
<script>
import TextareaCounter from "../../Form/Textarea/TextareaCounter";
import ModuleErrorMessage from "../../Form/ModuleErrorMessage";
import IsGranted from "@/components/IsGranted";

export default {
  components: {ModuleErrorMessage, TextareaCounter, IsGranted},
  props: {
    idx: {type: Number, required: true},
    module: {type: Object, required: true},
    readOnly: {type: Boolean, required: true},
    patientId: {type: String, default: null},
    appointmentDocumentId: {type: String, required: true},
    appointmentStatus: {type: String, default: null},
  },
  data() {
    return {
      interview: this.module.interview,
      syncInterview: null == this.module.syncInterview ? false : this.module.syncInterview,
      examination: this.module.examination,
      syncExamination: null == this.module.syncExamination ? false : this.module.syncExamination,
      recommendation: this.module.recommendation,
      syncRecommendation: null == this.module.syncRecommendation ? false : this.module.syncRecommendation,
      id: this.idx,
      vueReadOnly: this.readOnly,
      errors: this.module.errors,
      ipzOpened: false,
    };
  },
  watch: {
    "module.errors"() {
      this.errors = this.module.errors;
    },
    patientId: {
      immediate: true,
      async handler(newVal) {
        if (newVal) {
          await this.isIpzOpened();
        }
      }
    },
  },
  methods: {
    clearErrors: function (field) {
      if (this.errors) {
        this.errors[field] = null;
      }
    },
    updateTextValue(event, field) {
      const module = {...this.module};
      module[field] = this[field];
      this.$emit("input", module)
    },
    updateBooleanValue(event, field) {
      const module = {
        ...this.module,
        [field]: event,
      };
      this.$emit("input", module)
    },
    state(field) {
      return (this.errors && this.errors[field] ? false : null);
    },
    async isIpzOpened() {
      this.ipzOpened = false;
      const data = {
        patientId: this.patientId,
        status: "opened"
      };
      const response = await read("/api/ipzs", data);
      if(response.items.length > 0) {
        this.ipzOpened = true;
      }
    },
  },
}
</script>
