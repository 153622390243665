import {Module} from "vuex";
import {GeolocalizationState} from "./geolocalization/types";
import {RootState} from "../types";
import {state} from "./geolocalization/state";
import {getters} from "./geolocalization/getters";
import {actions} from "./geolocalization/actions";
import {mutations} from "./geolocalization/mutations";

const namespaced = true;

const geolocalization: Module<GeolocalizationState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};

export default geolocalization;