<template>
  <div class="card-box m-t-30 border-dark">
    <div class="border-bottom m-b-30 p-b-10">
      <h1>{{ name }} ({{ symbol }})</h1>
    </div>
    <div class="card-box p-0 border-dark">
      <div class="row m-0 border-bottom border-dark font-bold">
        <div class="col-md-1 border-right p-3">
          Kategoria ICF
        </div>
        <div class="col-md-2 border-right p-3">
          Nazwa kategorii
        </div>
        <div class="col-md-2 border-right p-3">
          Źródła informacji
        </div>
        <div class="col-md-7 p-3">
          Kwalifikatory i skale kategorii
        </div>
      </div>
      <evaluation-result-answer
        v-for="(answer, index) in answers"
        :key="index"
        :class="{
          'bg-muted': (index % 2) === 0,
          'border-none': index === (answers.length - 1)
        }"
        class="border-bottom border-dark"
        v-bind="answer"
        :symbol="symbol"
      />
    </div>
  </div>
</template>

<script>
import EvaluationResultAnswer from "./EvaluationResultAnswer";

export default {
  components: {
    EvaluationResultAnswer,
  },
  props: {
    answers: {type: Array, default: ()=>[]},
    description: {type: String, default: ""},
    name: {type: String, default: ""},
    symbol: {type: String, required: true},
  }
}
</script>
