
















import Vue from "vue";
import Component from "vue-class-component";
import {Prop} from "vue-property-decorator";
import type {EntryPart} from "../../../types/SimplifiedIpzTypes";
import I18n from "../../i18n.vue";

@Component({
  name: "SimplifiedIpzEntryParts",
  components: {
    I18n
  },
})
export default class SimplifiedIpzEntryParts extends Vue {
  @Prop({type: Array, required: true}) readonly parts!: Array<EntryPart>;
}
