export default function getEntry(entries: Array<BasicEntryItem>): null | BasicEntryItem {
  return entries.reduce((entry: null | BasicEntryItem, item: BasicEntryItem) => {
    if (null === entry) {
      return item;
    }
    if (item.createdAt > entry.createdAt) {
      return item;
    }
    return entry;
  }, null);
}

export interface BasicEntryItem {
  createdAt: string;
}
