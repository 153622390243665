<template>
  <object-select
    :value="selectedOption"
    :options="options"
    :disabled="loading"
    :loading="loading"
    :state="state"
    placeholder="Wybierz jednostkę współpracującą"
    @input="update"
  />
</template>

<script>
import read from "../../../rest/read";
import ObjectSelect from "./ObjectSelect";

export default {
  components: {ObjectSelect,},
  props: {
    value: {type: [Object, String], default: null},
    state: ObjectSelect.props.state, // eslint-disable-line
  },
  data() {
    return {
      loading: true,
      options: [],
    }
  },
  computed: {
    selectedOption() {
      if ("string" === typeof this.value) {
        return this.options.find(option => option.value === this.value);
      }
      return this.value;
    },
  },
  async mounted() {
    this.loadOptions();
  },
  methods: {
    async loadOptions() {
      const {items} = await read("/api/cooperating-units");
      this.options = items.map(item => ({
        ...item,
        value: item.cooperatingUnitId,
      }));
      this.loading = false;
    },
    update(option) {
      this.$emit("input", option ? option.value : null);
    },
  },
}
</script>
