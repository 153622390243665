
























import {Component, Prop} from "vue-property-decorator";
import Vue from "vue";
import read from "../../rest/read";
import {mapState} from "vuex";

interface SurveyRequiredData {
  closed: Number|null,
  opened: Number|null,
  hasActiveClosedSurvey: boolean
}

const REQUIRED_CLOSED_SURVEY: Number = 2;

@Component({
  computed: {...mapState({clinicParameters: state => state.clinicParameters.parameters})},
})
export default class SurveyRequired extends Vue {
  @Prop({type: String, required: true}) readonly patientId !: string;

  loading: boolean = false;

  data: SurveyRequiredData = {
    closed: null,
    opened: null,
    hasActiveClosedSurvey: false
  };

  async loadData(): Promise<void> {
    this.loading = true;
    this.data = await read<SurveyRequiredData>(`/api/survey-required/${this.patientId}`);
    this.loading = false;
  }

  get showFirstWarning(): boolean
  {
    return this.data.closed === 0 && this.data.opened === 0;
  }

  get showSecondWarning(): boolean
  {
    if(this.data.closed === REQUIRED_CLOSED_SURVEY) {
      return false;
    }

    return !this.data.hasActiveClosedSurvey && this.data.closed === 1;
  }

  get surveyType(): string  {
    if (this.clinicParameters && "children" === this.clinicParameters.ageGroupTarget){
      return "MHC-SF lub MHC-KIDS";
    }
    return "MHC-SF";
  }

  async mounted(): Promise<void> {
    this.loadData();
  };
}

