<template>
  <div 
    :id="`answers.${category.category}`"
    :class="{'border-danger' : !!categoryError}"
    class="col-md-12 m-b-25 border"
  >
    <div class="row">
      <div class="col-md-1 border-bottom">
        {{ category.category }}
      </div>
      <div class="col-md-5 border-bottom border-left">
        <span
          :class="{'text-danger' : !!categoryError}"
          class="font-bold"
        >
          {{ category.title }}
        </span>
        <p
          class="text-justify font-weight-light"
          style="font-size: 0.8125rem;"
        >
          {{ category.description }}
        </p>
      </div>
      <div class="col border-left border-bottom p-1">
        <component
          :is="componentType"
          v-for="(qualifier, index) in qualifiers"
          :key="index"
          v-model="qualifiersAnswers[qualifier.type]"
          :qualifier="qualifier"
          :previous-evaluation-category-data="previousEvaluationCategoryData"
          @input="update"
        />
      </div>
    </div>
    <sources-of-information
      v-model="sourcesOfInformation"
      @input="update"
    />
    <div class="row border-bottom">
      <div class="col-lg-1">
        Opis problemu
      </div>
      <div class="col-lg-11 border-left p-2">
        <textarea-counter
          v-model.trim="description"
          class="w-100"
          :max-characters-count="500"
          @input="update"
        />
        <div v-if="previousEvaluationCategoryData && previousEvaluationCategoryData.description">
          {{ previousEvaluationCategoryData.description }} <br>
          <span class="text-success">
            {{ previousEvaluationCategoryData.evaluationDate }}
            ({{ previousEvaluationCategoryData.workerName }})
          </span>
        </div>
      </div>
    </div>
    <error-message
      v-if="categoryError"
      class="p-1"
      :errors="errors"
      :field="categoryError.field"
    />
  </div>
</template>

<script>
import TextareaCounter from "../../Form/Textarea/TextareaCounter";
import SourcesOfInformation from "../../SourcesOfInformation/SourcesOfInformation";
import ErrorMessage from "../../Form/ErrorMessage";
import EvaluationQualifierSingleValue from "./EvaluationQualifierSingleValue";
import EvaluationQualifierDoubleValue from "./EvaluationQualifierDoubleValue";

export default {
  components: {
    EvaluationQualifierDoubleValue,
    EvaluationQualifierSingleValue,
    ErrorMessage,
    SourcesOfInformation,
    TextareaCounter,
  },
  props: {
    category: {type: Object, required: true},
    qualifiers: {type: Array, required: true},
    errors: {type: Array, required: true},
    symbol: {type: String, required: true},
    savedData: {type: Object, default: () => ({})},
    previousEvaluation: {type: Object, default: null}
  },
  data() {
    return {
      sourcesOfInformation: this.savedData ? this.savedData.sourcesOfInformation : [],
      description: this.savedData ? this.savedData.description : "",
      qualifiersAnswers: this.savedData
        ? this.savedData.qualifiersAnswers
        : this.getEmptyQualifiersAnswers(),
    };
  },
  computed: {
    categoryError() {
      return this.errors.find((error) => error.field === `answers.${this.category.category}`);
    },
    componentType() {
      return this.symbol === "e"
        ? EvaluationQualifierDoubleValue
        : EvaluationQualifierSingleValue;
    },
    previousEvaluationCategoryData() {
      if(this.previousEvaluation) {
        const answer = this.previousEvaluation.answers.find(item => item.category === this.category.category);

        if(answer) {
          return {
            ...answer,
            workerName: this.previousEvaluation.workerName,
            evaluationDate: this.previousEvaluation.evaluationDate,
          }
        }
      }
      return null;
    }
  },
  methods: {
    getEmptyQualifiersAnswers() {
      return this.qualifiers.reduce((obj, qualifier) => ({
        ...obj,
        [qualifier.type]: []
      }), {});
    },
    update() {
      this.$emit("update", {
        category: this.category.category,
        qualifiersAnswers: this.qualifiersAnswers,
        description: this.description,
        sourcesOfInformation: this.sourcesOfInformation,
      });
    },
  },
}
</script>
