





















































































import Vue from "vue";
import {Component,  Prop, Watch} from "vue-property-decorator";
import TargetForm from "./TargetForm.vue";
import TaskForm from "./TaskForm.vue";
import Card from "../../Card.vue";
import CreateTargetModal from "./CreateTargetModal.vue";
import type {Target} from "../../../types/TargetsAndTasks";

@Component({
  name: "TargetsList",
  components: {CreateTargetModal, Card, TaskForm, TargetForm},
})

export default class TargetsList extends Vue {
  @Prop({type: String, required: true}) readonly ipzId!: string;
  @Prop({type: Array, default: ()=>[]}) readonly targets!: Array<Target>;
  @Prop({type: Boolean, default: false}) readonly readonly!: boolean;

  selectedTarget: Target|null = null;
  updating: boolean = false;


  @Watch("targets") onTargetsChange(): void
  {
    const selected: Target|null = this.selectedTarget && this.targets
      .find(target => target.targetId === this.selectedTarget.targetId) || null;
    this.selectedTarget = selected || this.targets[0] || null;

    this.updating = false;
  }

  setSelectedTarget(target: Target): void {
    this.selectedTarget = target;
  }

  updateData(): void {
    this.$emit("update");
  }
}
