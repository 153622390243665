





































































































































































































































import ErrorsMixin from "../../../../mixins/ErrorsMixin";
import {Component, Mixins, Prop, Watch} from "vue-property-decorator";
import IsGranted from "../../../IsGranted.vue";
import LoadingMask from "../../../Loading/LoadingMask.vue";
import TreatmentTypeName from "../../../TreatmentType/TreatmentTypeName.vue";
import WorkerTreatmentTypes from "../../../Worker/WorkerTreatmentTypes.vue";
import ErrorMessage from "../../../Form/ErrorMessage.vue";
import TextareaCounter from "../../../Form/Textarea/TextareaCounter.vue";
import read from "../../../../rest/read";
import type {InformationCardData, InformationCardUpdateData} from "../../../../types/InformationCard";
import update from "../../../../rest/update";
import processResponseException from "../../../../utils/errors/processResponseException";
import debounce from "lodash/debounce";
import Icd10CodeSelect from "../../../Form/Select/Icd10CodeSelect.vue";
import type {Icd10Option} from "../../../../types/IcdCodes";
import {code2option} from "../../../../utils/icd10Code/code2option";
import stringifyDate from "../../../../utils/date/stringifyDate";
import Tooltip from "../../../Tooltip.vue";

const deferred = debounce((f) => f(), 20000, {maxWait: 25000});

@Component({
  name: "ContinuousStayInformationCardEdit",
  components: {
    Tooltip,
    Icd10CodeSelect,
    IsGranted,
    LoadingMask,
    TreatmentTypeName,
    WorkerTreatmentTypes,
    ErrorMessage,
    TextareaCounter,

  },
})
export default class ContinuousStayInformationCardEdit extends Mixins(ErrorsMixin) {
  @Prop({type: String, default: null}) readonly continuousStayId!: string | null;
  @Prop({type: Boolean, required: true}) readonly disabled!: boolean;

  private loading: boolean = false;
  private reason: string|null = null;
  private startState: string|null = null;
  private stayDescription: string|null = null;
  private recognitions: Array<Icd10Option> = [];
  sessions: string[] = [];
  private administeredDrugs: string|null = null;
  private leaveState: string|null = null;
  private recommendations: string|null = null;
  private isDirty: boolean = false;
  private currentEditDate: Date|null = null;
  private lastEditDate: Date|null = null;
  private forceUpdate: boolean = false;

  @Watch("continuousStayId", {immediate: true}) async onContinuousStayChange(newId: string, oldId: string) {
    if (this.isDirty) {
      await this.save(oldId);
    }
    this.loading = true;

    try {
      const cardInfo = await read<InformationCardData>(
        `/api/continuous-stays/${this.continuousStayId}/information-card`);

      this.reason = cardInfo.reason;
      this.startState = cardInfo.startState;
      this.stayDescription = cardInfo.stayDescription;
      this.recognitions = cardInfo.recognitions.map(code2option);
      this.sessions = cardInfo.sessions;
      this.administeredDrugs = cardInfo.administeredDrugs;
      this.leaveState = cardInfo.leaveState;
      this.recommendations = cardInfo.recommendations;
      this.isDirty = false;
      this.lastEditDate = new Date(cardInfo.updatedAt);
    } catch (e) {
      this.errors = processResponseException();
    }
    this.loading = false;
  }

  get hasOverwriteError() {
    return this.errors?.some((error) => {
      return error?.field === "overwriteWarning";
    });
  }

  private async autoSave(): Promise<void> {
    this.isDirty = true;
    deferred(() => {
      if(this.isDirty){
        this.save();
      }
    });
  }

  private async save(continuousStayId = this.continuousStayId): Promise<void> {
    if (this.disabled) {
      return;
    }
    this.loading = true;
    this.errors = [];
    this.currentEditDate = new Date();

    try {
      await update<Partial<InformationCardUpdateData>>(`/api/continuous-stays/${continuousStayId}/information-card`, {
        continuousStayId: this.continuousStayId,
        reason: this.reason ? this.reason.trim() : null,
        startState: this.startState ? this.startState.trim() : null,
        stayDescription: this.stayDescription ? this.stayDescription.trim() : null,
        recognitions: this.recognitions.map(({code, description}) => ({code, description})),
        sessions: this.sessions,
        administeredDrugs: this.administeredDrugs ? this.administeredDrugs.trim() : null,
        leaveState: this.leaveState ? this.leaveState.trim() : null,
        recommendations: this.recommendations ? this.recommendations.trim() : null,
        currentEditDate: stringifyDate(this.currentEditDate),
        lastEditDate: null !== this.lastEditDate ? stringifyDate(this.lastEditDate): null,
        forceUpdate: this.forceUpdate,
      });
      this.isDirty = false;
      this.lastEditDate = this.currentEditDate;
      this.forceUpdate = false;
    } catch (exception) {
      this.errors = processResponseException(exception);
    }

    this.loading = false;
  }
}
