<template>
  <div>
    <day-calendar-legend />
    <div class="table-scroll">
      <table class="table table-bordered p-0">
        <thead>
          <tr>
            <th class="align-middle">
              Specjaliści
            </th>
            <th
              v-for="hour in hours"
              :key="hour.getHours()"
              :colspan="milisecondsInHour / slotDuration"
              style="min-width: 100px;"
            >
              {{ stringifyHour(hour) }}
            </th>
          </tr>
        </thead>

        <tbody>
          <day-calendar-worker
            v-for="worker in workers"
            :key="`${worker.workerId}-${worker.branchId}`"
            :hours="hours"
            :worker="worker"
            :items="filteredItems(worker.workerId, worker.branchId)"
            :start-date="startDate"
            :end-date="endDate"
            :treatment-duration="treatmentDuration"
            :slot-duration="slotDuration"
            :filters="filters"
            :preferred-patient="preferredPatient"
          />
          <tr>
            <th
              class="p-0"
            />
            <td
              v-for="cell in rowCellsCount"
              :key="cell"
              class="p-0"
            />
          </tr>
        </tbody>
      </table>
    </div>
    <appointment-box-modals @appointmentScheduled="updateCalendar" />
  </div>
</template>

<script>
import DATE_FORMAT from "../../utils/date/DATE_FORMAT";
import stringifyDate from "../../utils/date/stringifyDate";
import DayCalendarWorker from "./DayCalendarWorker";
import DayCalendarLegend from "./DayCalendarLegend";
import AppointmentBoxModals from "../Appointment/AppointmentBox/AppointmentBoxModals";

export default {
  components: {
    AppointmentBoxModals,
    DayCalendarLegend,
    DayCalendarWorker,
  },
  props: {
    filters: {type: Object, required: true},
    workers: {type: Array, required: true},
    items: {type: Array, required: true},
    treatmentDuration: {type: Number, required: true},
    slotDuration: {type: Number, required: true},
    preferredPatient: {type: Object, default: null},
  },
  data() {
    return {
      milisecondsInHour: 60 * 60 * 1000,
    }
  },
  computed: {
    startDate() {
      return this.items.reduce((date, item) => {
        if (date.getTime() > item.start.getTime()) {
          const newStartDate = new Date(item.start.getTime());
          newStartDate.setMinutes(0, 0, 0);
          return newStartDate;
        }
        return date;
      }, this.filters.endDate);
    },
    endDate() {
      return this.items.reduce((date, item) => {
        if (item.end.getTime() > date.getTime()) {
          const newEndDate = new Date(item.end.getTime());
          newEndDate.getMinutes()
            ? newEndDate.setHours(newEndDate.getHours(), 0, 0, 0)
            : newEndDate.setMinutes(0, 0, 0);
          return newEndDate;
        }
        return date;
      }, this.filters.startDate);
    },
    hours() {
      const hours = [];
      const currDate = new Date(this.startDate.getTime());
      while (currDate.getTime() < this.endDate.getTime()) {
        hours.push(new Date(currDate.getTime()));
        currDate.setHours(currDate.getHours() + 1);
      }
      this.displayAnotherHour ? hours.push(new Date(currDate.getTime())) : null;
      return hours;
    },
    displayAnotherHour() {
      return this.items.find(item => item.end.getTime() > this.endDate.getTime());
    },
    rowCellsCount() {
      return this.hours.length * this.milisecondsInHour / this.slotDuration;
    },
  },
  methods: {
    stringifyHour(date) {
      return stringifyDate(date, DATE_FORMAT.TIME);
    },
    filteredItems(workerId, branchId) {
      return this.items.filter(item => item.workerId === workerId && item.branchId === branchId);
    },
    updateCalendar() {
      this.$emit("updateCalendar");
    },
  },
}
</script>
<style lang="scss" scoped>
  @import "../../styles/variables";

  .table-scroll {
    position: relative;
    z-index: 1;
    overflow-x: auto;
    max-height: 80vh;
  }

  thead th {
    position: sticky;
    top: -1px;
    background: $white;
    z-index: 2;
  }

  th:first-child {
    position: sticky;
    left: 0;
    z-index: 3;
    background-color: $white;
  }
</style>
