<template>
  <loading-mask :loading="isLoading">
    <form @submit.prevent="submit">
      <div class="row">
        <error-message
          class="col-12"
          :errors="errors"
        />
        <b-form-group
          class="col-md-6 col-xl-3"
          label="Specjalista"
        >
          <worker-select
            v-model="formData.worker"
            :state="state('worker')"
            :coordinated-by-current-user="!showSpecialistFilter"
            :with-current-user="!showSpecialistFilter"
            @input="clearWorkerError"
          />
          <error-message
            :errors="errors"
            field="worker"
          />
        </b-form-group>
        <b-form-group
          class="col-md-6 col-xl-3"
          label="Miesiąc"
        >
          <date-picker
            v-model="formData.month"
            :type="'month'"
            :format="'YYYY-MM'"
          />
        </b-form-group>
        <div class="col text-right no-label">
          <back-button :to="{name: 'reportsMainView'}" />
          <button
            type="submit"
            class="btn btn-primary m-1"
          >
            <i class="fas fa-file-excel" />
            Generuj
          </button>
        </div>
      </div>
    </form>
  </loading-mask>
</template>
<script>
import DatePicker from "../Form/DatePicker/DatePicker";
import WorkerSelect from "../Worker/WorkerSelect";
import {errorsMixin} from "../../mixins/errorsMixin.js";
import ErrorMessage from "../Form/ErrorMessage";
import {mapState} from "vuex";
import {isGranted} from "../../security/isGranted";
import LoadingMask from "../Loading/LoadingMask";
import BackButton from "../../components/BackButton";
import processResponseException from "../../utils/errors/processResponseException";

export default {
  name: "ScheduleSpecialistFilters",
  components: {BackButton,LoadingMask, ErrorMessage, DatePicker, WorkerSelect},
  mixins: [errorsMixin],
  props: {
    month: {type: Date, default: null},
    worker: {type: Object, default: null},
  },
  data() {
    return {
      showSpecialistFilter: false,
      isLoading: false,
      coordinatedBy: null,
      formData: {
        month: this.month,
        worker: this.worker,
      }
    }
  },
  computed: {
    ...mapState("currentUser", ["user"]),
  },
  async mounted(){
    this.isLoading = true;
    await this.loadData();
    this.isLoading = false;
  },
  methods: {
    async loadData () {
      try {
        if(await isGranted("GET_SCHEDULE_SPECIALIST")){
          this.showSpecialistFilter = true;
        }
      }  catch (exception) {
        this.errors = processResponseException(exception);
      }
      this.assignCurrentUserToWorker();
    },
    assignCurrentUserToWorker() {
      this.formData.worker = {
        value: this.user.workerId,
        name: this.user.name,
        surname: this.user.surname,
        workerId: this.user.workerId
      };
    },
    submit() {
      this.errors = [];
      if (!this.formData.worker) {
        this.errors = this.errors.concat([{
          field: "worker",
          message: "Wybierz specjalistę",
        }]);
        return;
      }
      this.$emit("submit", this.formData);
    },
    clearWorkerError(worker) {
      if (worker) {
        this.errors = this.errors.filter(error => error.field !== "worker");
      }
    },
  },
}
</script>
