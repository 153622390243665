












































































































































import ErrorsMixin from "../../mixins/ErrorsMixin";
import {Component, Mixins, Prop} from "vue-property-decorator";
import {EstablishmentSmtpConfig} from "../../types/Establishment";
import ErrorMessage from "../Form/ErrorMessage.vue";
import processResponseException from "../../utils/errors/processResponseException";
import update from "../../rest/update";
import SmtpEncryptionSelect from "../Form/Select/SmtpEncryptionSelect.vue";
import {TheMask} from "vue-the-mask";
import read from "../../rest/read";

interface Config {
  host: string;
  port: null|number;
  encryption: string;
  username: string;
  password: string;
  senderName: string;
  senderEmail: string;
}

@Component({
  name: "EstablishmentSmtpModal",
  components: {
    SmtpEncryptionSelect,
    ErrorMessage,
    TheMask,
  }
})
export default class EstablishmentSmtpModal extends Mixins(ErrorsMixin) {
  @Prop({type: String, required: true}) readonly branchId!: string;
  @Prop({type: String, required: true}) readonly name!: string;

  loading: boolean = false;
  host: string = "";
  port: null | number = null;
  encryption: string = "";
  username: string = "";
  password: string = "";
  senderName: string = "";
  senderEmail: string = "";

  get modalId(): string {
    return `establishment-smtp-${this.branchId}`
  }

  get title(): string {
    return `Edycja ustawień SMTP podmiotu ${this.name}`
  }

  closeModal(): void {
    this.$bvModal.hide(this.modalId);
  }

  async submit(): Promise<void> {
    this.errors = [];
    this.loading = true;
    const data: EstablishmentSmtpConfig = {
      host: this.host,
      port: this.port,
      encryption: null == this.encryption ? "" : this.encryption,
      username: this.username,
      password: this.password,
      senderName: this.senderName,
      senderEmail: this.senderEmail,
    };
    try {
      await update(`/api/clinic/${this.branchId}/smtp-configuration`, data);
      this.closeModal();
      this.$emit("edited");
    } catch (e) {
      this.errors = processResponseException(e);
    }
    this.loading = false;
  }

  async fetchData(): Promise<void> {
    this.loading = true;
    this.errors = [];
    try {
      const {
        host,
        port,
        encryption,
        username,
        password,
        senderName,
        senderEmail
      } = await read<Config>(`/api/clinic/${this.branchId}/smtp-configuration`);
      this.host = host || "";
      this.port = port || null;
      this.encryption = encryption || "";
      this.username = username || "";
      this.password = password || "";
      this.senderName = senderName || "";
      this.senderEmail = senderEmail || "";
    } catch(e) {
      this.errors = processResponseException(e);
    }
    this.loading = false;
  }
}

