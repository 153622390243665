<template>
  <b-modal
    id="cancelContinuousStayModal"
    no-close-on-backdrop
    title="Anuluj pobyt"
    title-tag="h3"
    @hide="clearModal"
  >
    <b-form-group
      label="Powód anulowania pobytu"
      label-for="cancelReason"
      class="col-md-12"
    >
      <continuous-stay-cancel-category-select
        :state="state('cancelReasonCategory')"
        :cancel-category="cancelReasonCategory"
        @input="updateCancelReasonCategory"
      />
      <error-message
        :errors="errors"
        field="cancelReasonCategory"
      />
      <template
        v-if="cancelReasonCategory === 'other'"
      >
        <textarea-counter
          v-model="cancelReason"
          :max-characters-count="300"
          :state="state('cancelReason')"
          rows="2"
          class="m-t-20"
        />
        <error-message
          :errors="errors"
          field="cancelReason"
        />
      </template>
    </b-form-group>
    <template #modal-footer>
      <button
        type="button"
        class="btn btn-secondary"
        @click="hideModal"
      >
        Anuluj
      </button>
      <button
        :disabled="loading"
        type="submit"
        class="btn btn-primary"
        @click="cancelContinuousStay"
      >
        <i
          :class="loading ? 'fa-spin fa-spinner' : 'fa-check'"
          class="fa"
        />
        Zapisz
      </button>
    </template>
  </b-modal>
</template>
<script>
import TextareaCounter from "../../../Form/Textarea/TextareaCounter";
import ErrorMessage from "../../../Form/ErrorMessage";
import processResponseException
  from "../../../../utils/errors/processResponseException";
import {errorsMixin} from "../../../../mixins/errorsMixin.js";
import modify from "../../../../rest/modify";
import ContinuousStayCancelCategorySelect from "./ContinuousStayCancelCategorySelect";

export default {
  components: {
    ContinuousStayCancelCategorySelect,
    ErrorMessage,
    TextareaCounter
  },
  mixins: [errorsMixin],
  props: {
    patientId: {type: String, required: true},
    continuousStayId: {type: String, required: true}
  },
  data() {
    return {
      cancelReasonCategory: null,
      cancelReason: "",
      loading: false,
    };
  },
  methods: {
    hideModal() {
      this.clearModal();
      this.$bvModal.hide("cancelContinuousStayModal");
    },
    async cancelContinuousStay() {
      this.loading = true;

      try {
        await modify(`/api/continuous-stays/${this.continuousStayId}/cancel`, {
          cancelReasonCategory: this.cancelReasonCategory,
          cancelReason: this.cancelReason.trim()
        });
        this.$emit("canceled");
        this.hideModal();
      } catch (exception) {
        this.errors = processResponseException(exception);
      }

      this.loading = false;
    },
    updateCancelReasonCategory(cancelReasonCategory) {
      this.clearModal();
      this.cancelReasonCategory = cancelReasonCategory;
    },
    clearModal() {
      this.cancelReason = "";
      this.errors = [];
    }
  }
}
</script>
