<template>
  <span>{{ disabilityLevelType }}</span>
</template>

<script>
import t from "../../i18n";

export default {

  props: {
    disabilityLevel: {type: String, required: true},
  },

  computed: {
    disabilityLevelType() {
      return null === this.disabilityLevel ? null : t(`@disability-level.${this.disabilityLevel}`);
    }
  },
}
</script>
