<template>
  <is-granted
    v-if="isHospitalizationDataUnknown"
    :privileges="['UPDATE_PATIENT_HOSPITALIZATIONS_DATA']"
    style="cursor: pointer"
    component="div"
  >
    <b-alert
      v-b-modal.hospitalizations-data
      :show="true"
      variant="warning"
    >
      PROSZĘ WYPEŁNIĆ SEKCJĘ
      <span class="font-weight-bold">HOSPITALIZACJE</span>
    </b-alert>
    <patient-card-hospitalizations-data
      :patient-id="patientId"
      @close="loadHospitalizationData"
    />
  </is-granted>
</template>

<script>
import PatientCardHospitalizationsData from "../Patient/PatientCard/PatientCardHospitalizationsData";
import read from "../../rest/read";
import processResponseException from "../../utils/errors/processResponseException";
import IsGranted from "../IsGranted";

export default {
  name: "AppointmentHospitalizations",
  components: {IsGranted, PatientCardHospitalizationsData},
  props: {
    patientId: {type: String, required: true},
  },
  data() {
    return {
      hospitalizationsData: {},
    }
  },
  computed: {
    isHospitalizationDataUnknown() {
      return (this.hospitalizationsData.wasHospitalized === null ||
        this.hospitalizationsData.wasHospitalized === "without_info");
    },
  },
  watch: {
    patientId: {
      immediate: true,
      async handler() {
        await this.loadHospitalizationData();
      }
    }
  },
  methods: {
    async loadHospitalizationData()
    {
      this.hospitalizationsData = {};
      try {
        this.hospitalizationsData = await read(`/api/patients/${this.patientId}/hospitalizations-data`);
      } catch(e) {
        this.errors = processResponseException(e);
      }
    },
  },
}
</script>
