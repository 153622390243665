<template>
  <vue-date-picker
    ref="datepicker"
    :type="type"
    :value="value"
    :placeholder="placeholder"
    :lang="lang"
    :input-name="name"
    :disabled="disabled"
    :clearable="clearable"
    :editable="editable"
    :format="format"
    :state="state"
    :class="{invalid: isInvalid}"
    :disabled-date="disabledDate"
    :minute-step="minuteStep"
    :value-type="valueType"
    :append-to-body="appendToBody"
    input-class="form-control"
    autocomplete="off"
    @change="(newValue) => $emit('input', newValue)"
    @calendar-change="(newValue, odlValue) => $emit('calendarChange', newValue, odlValue)"
    @open="() => $emit('open')"
  >
    <slot name="calendar-icon">
      <i class="fa fa-calendar" />
    </slot>
  </vue-date-picker>
</template>

<script>
import VueDatePicker from "vue2-datepicker";
import lang from "../../../i18n/datePicker.json";

export default {
  components: {VueDatePicker},
  props: {
    appendToBody: {type: Boolean, default: true},
    type: {type: String, default: "date"},
    name: {type: String, default: null},
    placeholder: {type: String, default: ""},
    disabled: {type: Boolean, default: false},
    clearable: {type: Boolean, default: false},
    value: {type: [String, Date], default: null},
    editable: {type: Boolean, default: false},
    format: {type: String, default: "YYYY-MM-DD"},
    state: {type: Boolean, default: null},
    disabledDate: {type: Function, default: ()=>{}},
    minuteStep: {type: Number, default: 0},
    valueType: {type: String, default: "date"},
  },
  data() {
    return {lang};
  },
  computed: {
    isInvalid() {
      return false === this.state;
    },
  },
  methods: {
    showPopup() {
      this.$refs.datepicker.showPopup();
    }
  }
};
</script>

<style lang="scss">
.mx-panel-date {
  td.today {
    border: 1px solid #1284e7;
  }
  tr.active {
    & > td.today {
      color: white;
      border-color: white;
    }
    &:hover > td {
      background: #1284e7;
    }
  }
}
</style>
