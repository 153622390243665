<template>
  <b-modal
    id="medicinesTakenModal"
    no-close-on-backdrop
    title="Edycja przyjmowanych leków"
    title-tag="h3"
    @hide="cancel"
  >
    <template #default>
      <textarea-counter
        id="medicinesTaken"
        v-model="newValue"
        :max-characters-count="3000"
        :state="state('medicinesTaken')"
        rows="2"
        clearable
      />
      <error-message
        :errors="errors"
        field="medicinesTaken"
      />
      <div
        v-show="history.length"
        v-b-toggle="'medicinesTakenHistory'"
        class="text-center m-2 cursor-pointer"
      >
        ---
        <span class="when-closed">Pokaż historię</span>
        <span class="when-opened">Ukryj historię</span>
        ---
      </div>
      <b-collapse id="medicinesTakenHistory">
        <table class="table table-sm">
          <thead>
            <tr>
              <th>Data zmiany</th>
              <th>Osoba zmieniająca</th>
              <th>Nowe przyjmowane leki</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(entry, index) in history"
              :key="index"
              class="p-3"
            >
              <td>{{ entry.dateOfChange }}</td>
              <td>{{ entry.changerFullName }}</td>
              <td>{{ entry.medicineTaken ? entry.medicineTaken : 'brak' }}</td>
            </tr>
          </tbody>
        </table>
      </b-collapse>
    </template>
    <template #modal-footer>
      <button
        type="button"
        class="btn btn-secondary"
        @click="close"
      >
        Anuluj
      </button>
      <button
        :disabled="loading"
        class="btn btn-primary"
        @click="submit"
      >
        <i
          :class="loading ? 'fa-spin fa-spinner' : 'fa-check'"
          class="fa"
        />
        Zapisz
      </button>
    </template>
  </b-modal>
</template>

<script>
import TextareaCounter from "../../../Form/Textarea/TextareaCounter";
import ErrorMessage from "../../../Form/ErrorMessage";
import {errorsMixin} from "../../../../mixins/errorsMixin.js";
import update from "../../../../rest/update";
import processResponseException from "../../../../utils/errors/processResponseException";

export default {
  name: "PatientCardMedicinesTakenModal",
  components: {
    TextareaCounter,
    ErrorMessage,
  },
  mixins: [errorsMixin],
  props: {
    patientId: {type: String, required: true},
    oldValue: {type: String, required: true},
    history: {type: Array, required: true},
  },
  data() {
    return {
      newValue: this.oldValue,
      loading: false,
    };
  },
  watch: {
    oldValue(val) {
      this.newValue = val;
    },
  },
  methods: {
    cancel() {
      this.resetData();
      this.errors = [];
    },
    close() {
      this.$bvModal.hide("medicinesTakenModal");
    },
    async submit() {
      this.$emit("submit-start");
      this.errors = [];
      this.loading = true;
      try {
        const resource = `/api/patients/${this.patientId}/medicines-taken`;
        await update(resource, {medicinesTaken: this.newValue});

        this.close();
      } catch (exception) {
        this.errors = processResponseException(exception);
      }

      this.loading = false;
      this.$emit("submit-end");
    },
    resetData() {
      this.newValue = this.oldValue;
    },
  },
}
</script>

<style scoped>
  .collapsed > .when-opened,
  :not(.collapsed) > .when-closed {
    display: none;
  }
</style>
