import {ScheduleAppointmentState} from "./types";

export const state: ScheduleAppointmentState = {
  modalOpened: false,
  modalType: null,
  appointmentData: null,
  scheduleStep: 0,
  summaryModalOpened: false,
  appointmentDetails: null,
};
