







































import {Component, Mixins, Prop} from "vue-property-decorator";
import ErrorsMixin from "../../../mixins/ErrorsMixin.ts";
import type {ChildIpzItem} from "../../../types/ChildIpzs";
import modify from "../../../rest/modify";
import processResponseException from "../../../utils/errors/processResponseException";
import t from "../../../i18n";
import ErrorMessage from "../../Form/ErrorMessage.vue";
import ChildIpzStatusBadge from "./ChildIpzStatusBadge.vue";

@Component({
  name: "ConfirmChildIpzStatusModal",
  components: {ChildIpzStatusBadge, ErrorMessage},
})

export default class ConfirmChildIpzStatusModal extends Mixins(ErrorsMixin) {
  @Prop({type: String, default: "opened"}) readonly type!: string;
  @Prop({type: String, required: true}) readonly ipzId!: string;
  @Prop({type: Object, default: null}) readonly item!: ChildIpzItem | null;

  loading: boolean = false;

  get openMode():boolean {
    return this.type === "opened";
  }

  get buttonName(): string {
    return this.openMode ? "Otwórz" : "Zamknij";
  }

  get oldStatus(): string {
    return this.openMode ? "edit" : "opened";
  }

  get transNewStatus(): string {
    return t(`@child-ipz-status.${this.type}`);
  }

  get title(): string {
    return `Zmiana statusu - ${this.transNewStatus}`
  }

  closeModal(): void {
    this.$bvModal.hide("confirm-child-ipz-modal");
  }

  async submit(): Promise<void> {
    this.resetErrors();
    this.loading = true;
    try {
      const childIpzId = this.item && this.item.childIpzId;

      if (this.openMode) {
        await modify("/api/child-ipzs/sign-and-scan", {childIpzId});
      } else {
        await modify("/api/child-ipzs/close", {childIpzId});
      }
      this.$emit("completed");
      this.closeModal();
    } catch (e) {
      this.errors = processResponseException(e);
    }
    this.loading = false;
  }

  resetErrors(): void {
    this.errors = [];
  }
}
