<template>
  <div class="card-box">
    <b-form-group label="Instytucja">
      <InstitutionSelect
        :selected-institutions="selectedInstitutions"
        :state="state('institutionList')"
        @input="updateInstitutionList"
      />
      <error-message
        :errors="errors"
        field="institutionList"
      />
    </b-form-group>
    <div class="row">
      <b-form-group
        class="col-md-6"
        label="Kategoria kursu"
      >
        <CourseTypeSelect
          :course-type="courseType"
          :state="state('courseType')"
          @input="changeCourseType"
        />
        <error-message
          :errors="errors"
          field="courseType"
        />
      </b-form-group>
      <b-form-group
        v-if="courseType === 'for_specialists'"
        class="col-md-6"
        label="Podkategoria kursu"
      >
        <CourseSubtypesSelect
          :course-subtypes="courseSubtypes"
          :state="state('courseSubtypes')"
          @input="changeCourseSubtypes"
        />
        <error-message
          :errors="errors"
          field="courseSubtype"
        />
      </b-form-group>
    </div>
    <div class="row">
      <b-form-group
        class="col-md-6"
        label="Nazwa grupy"
      >
        <b-form-input
          id="groupName"
          v-model.trim="groupName"
          :state="state('groupName')"
        />
        <error-message
          :errors="errors"
          field="groupName"
        />
      </b-form-group>
      <b-form-group
        class="col-md-6"
        label="Liczba uczestników"
      >
        <b-form-input
          id="numberOfParticipants"
          v-model.trim="numberOfParticipants"
          :state="state('numberOfParticipants')"
          :min="1"
          type="number"
        />
        <error-message
          :errors="errors"
          field="numberOfParticipants"
        />
      </b-form-group>
    </div>
    <div class="row">
      <b-form-group
        class="col-md-6"
        label="Data kursu"
      >
        <date-picker
          v-model="courseDate"
          :state="state('courseDate')"
        />
        <error-message
          :errors="errors"
          field="courseDate"
        />
      </b-form-group>
      <b-form-group
        class="col-md-6"
        label="Liczba godzin"
      >
        <b-form-input
          id="courseLength"
          v-model.trim="courseLength"
          :state="state('courseLength')"
          :min="1"
          type="number"
        />
        <error-message
          :errors="errors"
          field="courseLength"
        />
      </b-form-group>
    </div>
    <b-form-group label="Notatka ogólna">
      <textarea-counter
        id="note"
        v-model="note"
        :max-characters-count="1500"
        :state="state('note')"
        rows="2"
      />
      <error-message
        :errors="errors"
        field="note"
      />
    </b-form-group>
    <b-form-group label="Notatka obecności uczestników">
      <textarea-counter
        id="participantsAttendanceNote"
        v-model="participantsAttendanceNote"
        :max-characters-count="1500"
        :state="state('participantsAttendanceNote')"
        rows="2"
      />
      <error-message
        :errors="errors"
        field="participantsAttendanceNote"
      />
    </b-form-group>
    <b-form-group label="Notatka obecności trenerów">
      <textarea-counter
        id="coachesAttendanceNote"
        v-model="coachesAttendanceNote"
        :max-characters-count="1500"
        :state="state('coachesAttendanceNote')"
        rows="2"
      />
      <error-message
        :errors="errors"
        field="coachesAttendanceNote"
      />
    </b-form-group>
    <error-message :errors="errors" />
    <div class="text-right">
      <button
        class="btn btn-secondary waves-effect"
        @click="redirect"
      >
        Anuluj
      </button>
      <button
        :disabled="loading"
        class="btn btn-primary waves-effect ml-2"
        @click="createCourse"
      >
        <i
          :class="loading ? 'fa-spin fa-spinner' : 'fa-check'"
          class="fa"
        />
        Zapisz
      </button>
    </div>
  </div>
</template>

<script>
import DatePicker from "../../components/Form/DatePicker/DatePicker";
import {generateUuid} from "../../utils/uuid/generateUuid";
import create from "../../rest/create";
import stringifyDate from "../../utils/date/stringifyDate";
import InstitutionSelect from "../../components/Prevention/InstitutionSelect";
import CourseTypeSelect from "../../components/Prevention/CourseTypeSelect";
import {errorsMixin} from "../../mixins/errorsMixin.js";
import TextareaCounter from "../../components/Form/Textarea/TextareaCounter";
import processResponseException from "../../utils/errors/processResponseException";
import ErrorMessage from "../../components/Form/ErrorMessage";
import CourseSubtypesSelect from "../../components/Prevention/CourseSubtypesSelect";

export default {
  components: {
    CourseSubtypesSelect,
    ErrorMessage,
    TextareaCounter,
    CourseTypeSelect,
    InstitutionSelect,
    DatePicker,
  },
  mixins: [errorsMixin],
  data() {
    return {
      selectedInstitutions: [],
      groupName: null,
      numberOfParticipants: null,
      courseDate: null,
      courseLength: null,
      courseType: null,
      courseSubtypes: [],
      note: null,
      participantsAttendanceNote: null,
      coachesAttendanceNote: null,
      loading: false,
    }
  },
  methods: {
    updateInstitutionList(value) {
      this.selectedInstitutions = value;
    },
    changeCourseType(value) {
      this.courseType = value;
      this.courseSubtypes = [];
    },
    changeCourseSubtypes(value) {
      this.courseSubtypes = value;
    },
    async createCourse() {
      try {
        this.loading = true;
        this.errors = [];
        const courseId = generateUuid();


        await create("/api/courses", {
          courseId: courseId,
          institutionList: this.selectedInstitutions,
          groupName: this.groupName,
          numberOfParticipants: this.numberOfParticipants,
          courseDate: (this.courseDate instanceof Date) ? stringifyDate(this.courseDate) : null,
          courseLength: this.courseLength,
          courseType: this.courseType,
          courseSubtypes: this.courseSubtypes,
          note: null !== this.note ? this.note.trim() : null,
          participantsAttendanceNote: null !== this.participantsAttendanceNote
            ? this.participantsAttendanceNote.trim()
            : null,
          coachesAttendanceNote: null !== this.coachesAttendanceNote
            ? this.coachesAttendanceNote.trim()
            : null,
        });
        this.redirect();
      } catch (exception) {
        this.errors = processResponseException(exception);
      }
      this.loading = false;
    },
    redirect() {
      this.$router.push({name: "course_list"});
    },
  },
}
</script>
