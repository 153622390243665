














import Vue from "vue";
import Component from "vue-class-component";
import {Prop} from "vue-property-decorator";

@Component({
  name: "PatientOptionsBadge",
})
export default class PatientOptionsBadge extends Vue {
  @Prop({type: Number, default: 0}) patientsLength!: number;
  @Prop({type: Boolean, default: true}) patientPresence!: boolean;

  get badgeIconClass(): string | void {
    if (!this.patientsLength) {
      return "fa-user-times";
    } else if (!this.patientPresence) {
      return "fa-user-slash";
    }
    return;
  }

  get badgeText(): string | void {
    if (!this.patientsLength) {
      return "Bez pacjentów";
    } else if (!this.patientPresence) {
      return "Bez obecności";
    }
    return;
  }
}
