import read from "../rest/read";
import urlBase64ToUint8Array from "../utils/urlBase64ToUint8Array";
import {serviceWorkerRegistration} from "../main";

export default async () => {
  const vapid = await read("/api/vapid");
  if (!vapid.key) {
    console.error("No vapid key!");
  }
  const convertedVapidKey = urlBase64ToUint8Array(vapid.key);
  const registration = await serviceWorkerRegistration;
  return  await registration.pushManager.subscribe({
    userVisibleOnly: true,
    applicationServerKey: convertedVapidKey
  });
}
