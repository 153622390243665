import {store} from "../main";

export async function isGranted(attributes: string[] | string, subject: string|null = null): Promise<boolean> {
  if (!Array.isArray(attributes)) {
    return isGranted([attributes], subject);
  }

  const accesses: Array<boolean> = await Promise.all(
    attributes.map(async (attribute) => await store.dispatch("security/isGranted", {attribute, subject}))
  );

  return accesses.includes(true);
}
