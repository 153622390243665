<template>
  <div>
    <back-button :to="{name: 'reportsMainView'}" />
    <div class="row m-t-10">
      <div class="col-lg-6 col-xl-2">
        <b-form-group label="Data od">
          <date-picker
            v-model="formData.dateFrom"
            :state="state('dateFrom')"
            @input="validateReportDates"
          />
          <error-message
            :errors="errors"
            field="dateFrom"
          />
        </b-form-group>
      </div>
      <div class="col-lg-6 col-xl-2">
        <b-form-group label="Data do">
          <date-picker
            v-model="formData.dateTo"
            :state="state('dateTo')"
            @input="validateReportDates"
          />
          <error-message
            :errors="errors"
            field="dateTo"
          />
        </b-form-group>
      </div>
      <div class="col-lg-6 col-xl-3">
        <b-form-group label="Status">
          <appointment-status-select
            v-model="formData.statuses"
            :checked-in-enabled="false"
          />
        </b-form-group>
      </div>
      <div class="col text-right no-label">
        <button
          class="btn btn-primary m-1"
          :disabled="!!errors.length"
          @click="submit"
        >
          <i class="fa fa-search" />
          Szukaj
        </button>
        <button
          class="btn btn-primary m-1"
          :disabled="!!errors.length"
          @click="generatePDF"
        >
          <i class="far fa-file-pdf" />
          Generuj PDF
        </button>
        <button
          class="btn btn-primary m-1"
          :disabled="!!errors.length"
          @click="generateXLSX"
        >
          <i class="fas fa-file-excel" />
          Generuj XLSX
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from "../Form/DatePicker/DatePicker";
import AppointmentStatusSelect from "../Appointment/AppointmentStatusSelect";
import BackButton from "../BackButton";
import {errorsMixin} from "../../mixins/errorsMixin";
import ErrorMessage from "../Form/ErrorMessage";

export default {
  name: "AppointmentMonthlyStatsFilters",
  components: {ErrorMessage, BackButton, AppointmentStatusSelect, DatePicker},
  mixins: [errorsMixin],
  props: {
    dateFrom: {type: Date, default: null},
    dateTo: {type: Date, default: null},
    statuses: {type: Array, default: null},
  },
  data() {
    return {
      formData: {
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
        statuses: this.statuses,
      }
    }
  },
  methods: {
    submit() {
      this.$emit("submit", this.formData);
    },
    generatePDF(){
      this.$emit("generatePDF", this.formData);
    },
    generateXLSX(){
      this.$emit("generateXLSX", this.formData);
    },
    validateReportDates() {
      if (this.formData.dateFrom && this.formData.dateTo
        && this.formData.dateFrom.getTime() >= this.formData.dateTo.getTime()) {
        this.errors = [
          {
            message: "Data początkowa powinna być wcześniejsza niż data końcowa",
            field: "dateFrom"
          },
          {
            message: "Data końcowa powinna być późniejsza niż data początkowa",
            field: "dateTo"
          },
        ];
        return;
      }
      this.errors = [];
    },
  },
}
</script>
