<template>
  <div>
    <error-message :errors="errors" />
    <div class="form-row">
      <b-form-group
        label="Liczba wizyt w miesiącu"
        label-for="count"
        class="col-md-6 col-xl-3"
      >
        <b-form-input
          id="count"
          v-model="count"
          :disabled="loading"
          :state="state('count')"
          class="mb-2"
          placeholder="Liczba wizyt w miesiącu"
          type="number"
          required
          min="1"
        />
        <error-message
          :errors="errors"
          field="count"
        />
      </b-form-group>
      <b-form-group
        label="Typ usługi"
        label-for="treatmentType"
        class="col-md-6 col-xl-4"
      >
        <treatment-type-select
          id="treatmentType"
          v-model="selectedTreatmentType"
          :state="state('treatmentType')"
          class="mb-2"
        />
        <error-message
          :errors="errors"
          field="treatmentType"
        />
      </b-form-group>
      <b-form-group
        label="Sugerowany specjalista"
        label-for="preferredWorker"
        class="col-md-6 col-xl-4"
      >
        <worker-select
          id="preferredWorker"
          v-model="selectedWorker"
          :disabled="!selectedTreatmentType"
          :state="state('preferredWorker')"
          :treatment-type="selectedTreatmentType"
          placeholder="Wybierz specjalistę"
          class="mb-2"
        />
        <error-message
          :errors="errors"
          field="preferredWorker"
        />
      </b-form-group>
      <div class="col text-right">
        <button
          :disabled="loading"
          class="btn btn-primary submit"
          @click="addMeetingRequirement"
        >
          <span
            :class="loading ? 'fa-spin fa-spinner' : 'fa-plus'"
            class="fas"
          />&nbsp;Dodaj
        </button>
      </div>
    </div>
  </div>
</template>


<script>
import {generateUuid} from "@/utils/uuid/generateUuid";
import TreatmentTypeSelect from "../../../TreatmentType/TreatmentTypeSelect";
import WorkerSelect from "../../../Worker/WorkerSelect";
import ErrorMessage from "../../../Form/ErrorMessage";
import {errorsMixin} from "@/mixins/errorsMixin";
import processResponseException from "../../../../utils/errors/processResponseException";
import read from "../../../../rest/read";
import create from "../../../../rest/create";

export default {
  components: {
    ErrorMessage,
    TreatmentTypeSelect,
    WorkerSelect,
  },
  mixins: [errorsMixin],
  props: {
    ipzId: {type: String, required: true},
  },
  data() {
    return {
      count: null,
      loading: false,
      selectedTreatmentType: null,
      selectedWorker: null,
    };
  },
  computed: {
    preferredWorkerId() {
      if (!this.selectedWorker) {
        return null;
      }
      return this.selectedWorker.workerId;
    },
  },
  methods: {
    async addMeetingRequirement() {
      this.loading = true;
      this.errors = [];
      const meetingRequirementId = generateUuid();
      const newRequirement = {
        meetingRequirementId,
        count: this.count,
        ipzId: this.ipzId,
        preferredWorker: this.preferredWorkerId,
        treatmentType: this.selectedTreatmentType,
      };

      try {
        await create(`/api/ipzs/${this.ipzId}/meeting-requirements/create`, newRequirement);
        const data = await read(`/api/meeting-requirements/${meetingRequirementId}`);
        this.$emit("new-requirement-added", data);

        this.count = null;
        this.selectedTreatmentType = null;
        this.selectedWorker = null;
      } catch (exception) {
        this.errors = processResponseException(exception);
      }
      this.loading = false;
    },
  },
};
</script>

<style scoped lang="scss">
  @import "../../../../styles/variables";

  .submit {
    @include media-breakpoint-up(md) {
      margin-top: 1.8rem;
      margin-bottom: 1.5rem;
    }
  }
</style>
