<template>
  <treeselect
    :value="value"
    :options="options"
    :flat="true"
    :default-expand-level="0"
    :disabled="disabled"
    :name="name"
    :placeholder="placeholder"
    :no-options-text="noOptionsText"
    :class="{invalid: isInvalid}"
    clear-all-text="Odznacz wszystko"
    clear-value-text="Odznacz"
    disable-branch-nodes
    loading-text="Pobieranie opcji..."
    no-children-text="Brak rozwijanych opcji"
    no-results-text="Brak wyników wyszukiwania"
    retry-text="Pobrać ponownie?"
    retry-title="Kliknij by pobrać ponownie"
    search-prompt-text="Zacznij pisać..."
    show-count
    @input="update"
    @select="select"
  />
</template>

<script>
import read from "../../rest/read";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  name: "WorkerDefaultTreatmentTypeSelect",
  components: {Treeselect},
  props: {
    name: {default: "treatmentType", type: String},
    value: {type: [Object, String, Array], default: null},
    disabled: {default: false, type: Boolean},
    clearable: {default: true, type: Boolean},
    placeholder: {type: String, default: "Wybierz typ usługi"},
    multiple: {default: false, type: Boolean},
    state: {default: null, type: Boolean},
    clearOnOpen: {type: Boolean, default: false},
    noOptionsText: {type: String, default: "Brak opcji do wyświetlenia"},
    selectedTreatmentTypes: {type: Array, default: ()=>[]},
  },
  data() {
    return {
      allOptions: [],
    };
  },
  computed: {
    isInvalid() {
      return false === this.state;
    },
    options() {
      return this.getVisibleOptions(this.allOptions);
    },
  },
  watch: {
    selectedTreatmentTypes() {
      if (!this.selectedTreatmentTypes.includes(this.value)) {
        this.update();
      }
    },
  },
  mounted() {
    this.loadAllOptions();
  },
  methods: {
    getVisibleOptions(options) {
      return options
        .map(option => {
          return option.children
            ? {
              ...option,
              children: this.getVisibleOptions(option.children),
            }
            : option;
        })
        .filter(option => {
          return option.children
            ? !!option.children.length
            : this.selectedTreatmentTypes.includes(option.value);
        });
    },
    async loadAllOptions() {
      const {items} = await read("/api/treatment-types", {
        active: 1,
        treeStructure: true,
      });

      this.allOptions = items.map(item => this.treatmentTypeToOption(item));
    },
    treatmentTypeToOption(treatmentType) {
      return {
        ...treatmentType,
        label: treatmentType.name,
        id: treatmentType.value,
        children: treatmentType.children
          ? treatmentType.children.map(child => this.treatmentTypeToOption(child))
          : undefined,
      };
    },
    update(option) {
      if(option === undefined) {
        this.$emit("input", null);
        this.select(null)
      } else {
        this.$emit("input", option);
      }
    },
    select(option) {
      this.$emit("select", option);
    },
  },
}
</script>
