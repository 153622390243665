<template>
  <object-select
    :value="institutionList"
    :options="options"
    :state="state"
    :loading="loading"
    :multiple="true"
    placeholder="Wybierz instytucję"
    @input="update"
  />
</template>

<script>
import ObjectSelect from "../Form/Select/ObjectSelect";
import read from "../../rest/read";

export default {
  components: {
    ObjectSelect
  },
  model: {
    prop: "selectedInstitutions",
  },
  props: {
    selectedInstitutions: {type: Array, default: () => []},
    state: ObjectSelect.props.state, // eslint-disable-line
  },
  data() {
    return {
      loading: false,
      options: [],
    };
  },
  computed: {
    institutionList() {
      return this.options.filter((option) => this.selectedInstitutions.includes(option.institutionId));
    }
  },
  mounted() {
    this.getInstitutionList();
  },
  methods: {
    update(institutionList) {
      this.$emit("input", institutionList.reduce(function(list, institution) {
        return list.concat([institution.institutionId]);
      }, []),);
    },
    async getInstitutionList() {
      this.loading = true;
      const {items} = await read("/api/institutions");
      this.options = items.map(item => ({
        ...item,
        value: item.institutionId,
      })).sort(function(a, b) {
        return a.name.toLowerCase() >= b.name.toLowerCase() ? 1 : -1;
      });
      this.loading = false;
    },
  },
}
</script>
