

























































































import {Component, Mixins, Prop} from "vue-property-decorator";
import ErrorMessage from "../Form/ErrorMessage.vue";
import LoadingMask from "../Loading/LoadingMask.vue";
import ErrorsMixin from "../../mixins/ErrorsMixin.ts";
import read from "../../rest/read";
import processResponseException from "../../utils/errors/processResponseException";
import type {CancelZlaData, CancelZlaReason} from "../../types/Zla";
import CancelZlaCodeSelect from "./CancelZlaCodeSelect.vue";
import update from "../../rest/update";
import ResizableTextarea from "../Form/Textarea/ResizableTextarea.vue";

const cancelZlaReasonInitialState: CancelZlaReason  = {
  code: null,
  description: "",
}

@Component({
  name: "CancelZlaModal",
  components: {ResizableTextarea, CancelZlaCodeSelect, LoadingMask, ErrorMessage},
})
export default class CancelZlaModal extends Mixins(ErrorsMixin) {
  @Prop({type: String, default: null}) readonly sickLeaveId!: string;
  @Prop({type: String, required: true}) readonly modalId!: string;

  private loading: boolean = false;
  private password: string = "";
  private sessionId: string = "";
  private isCancellable: boolean = false;
  private reason: CancelZlaReason = {...cancelZlaReasonInitialState};

  get title(): string {
    return "Anuluj zwolnienie elektroniczne (e-ZLA)";
  }

  private async getSessionId(): Promise<void> {
    const {sessionId} = await read<{sessionId: string}>("/api/zla/get-session-id", {
      password: this.password,
    })
    this.sessionId = sessionId;
  }

  private async checkCancellable(): Promise<void> {
    const {result} = await read<{result: boolean}>("/api/zla/check-cancellable", {
      sessionId: this.sessionId,
      id: this.sickLeaveId,
    });
    this.isCancellable = result;
  }

  private async getZlaCancelData(): Promise<void> {
    this.errors = [];
    this.loading = true;
    try {
      await this.getSessionId();
      await this.checkCancellable();
    } catch(e) {
      this.errors = processResponseException(e);
    }
    this.loading = false;
  }

  private async cancelZla(): Promise<void> {
    this.errors = [];
    this.loading = true;
    const data: CancelZlaData = {
      sickLeaveId: this.sickLeaveId,
      password: this.password,
      reason: {
        code: this.reason.code?.value,
        description: this.reason.description.trim(),
      }
    };
    try {
      await update<CancelZlaData>(`/api/zla/cancel-document/${this.sessionId}`, data);
      this.hideModal();
      this.$emit("cancelled");
    } catch(e) {
      this.errors = processResponseException(e);
    }
    this.loading = false;
  }

  private hideModal(): void {
    this.$bvModal.hide(this.modalId);
  }

  private onHideModal(): void {
    this.password = "";
    this.sessionId = "";
    this.isCancellable = false;
    this.reason = {...cancelZlaReasonInitialState};
  }
}
