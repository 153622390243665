<template>
  <div>
    <room-occupancy-legend />
    <div
      ref="calendar"
    >
      <full-calendar
        ref="fullCalendar"
        scheduler-license-key="0622967769-fcs-1615507200"
        :all-day-slot="false"
        default-view="resourceTimeGridDay"
        :default-date="start"
        :events="items"
        :header="calendarHeader"
        height="auto"
        :locale="pl"
        :min-time="minTime"
        :plugins="calendarPlugins"
        :resources="visibleRooms"
        :slot-duration="slotDuration"
        :slot-label-format="slotLabelFormat"
        :title-format="titleFormat"
      />
    </div>
  </div>
</template>

<script>
import FullCalendar from "@fullcalendar/vue";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import pl from "@fullcalendar/core/locales/pl"
import stringifyDate from "../../utils/date/stringifyDate";
import DATE_FORMAT from "../../utils/date/DATE_FORMAT";
import RoomOccupancyLegend from "@/components/RoomOccupancy/RoomOccupancyLegend";

export default {
  name: "RoomOccupancyReportCalendar",
  components: {RoomOccupancyLegend, FullCalendar},
  props: {
    start: {type: String, default: null},
    items: {type: Array, default: ()=>[]},
    rooms: {type: Array, default: ()=>[]},
    showOther: {type: Boolean, default: false},
  },
  data() {
    return {
      viewportWidth: 0,
      pl,
      calendarHeader: {
        left: "",
        center: "title",
        right: "",
      },
      titleFormat: {year: "numeric", month: "long", day: "numeric", weekday: "long"},
      calendarPlugins: [resourceTimeGridPlugin],
      slotDuration: {minutes: 7, seconds: 30},
      slotLabelFormat: {
        hour: "numeric",
        minute: "2-digit",
      },
    };
  },
  computed: {
    visibleRooms() {
      return this.rooms.filter(room => room.active || this.items.find(item => item.roomId === room.id));
    },
    minTime() {
      if (this.items.length) {
        const firstHour = this.items
          .map(item => item.start)
          .reduce((start, date) => {
            return (start.getTime() < date.getTime())
              ? start
              : date;
          });
        return stringifyDate(firstHour, DATE_FORMAT.TIME);
      }
      return "00:00:00";
    },
  },
  created() {
    this.viewportWidth = window.innerWidth;
  },
}
</script>

<style scoped lang="scss">
  ::v-deep .fc-event {
    line-height: 1;
    font-size: 9px;
  }
  ::v-deep .fc th.fc-widget-header {
    padding-left: 4px;
    padding-right: 4px;
  }
  ::v-deep .fc-time-grid .fc-slats td {
    height: 18px;
  }
</style>
