import { render, staticRenderFns } from "./CaseManagerOps.vue?vue&type=template&id=924d3bc8&scoped=true&"
import script from "./CaseManagerOps.vue?vue&type=script&lang=js&"
export * from "./CaseManagerOps.vue?vue&type=script&lang=js&"
import style0 from "./CaseManagerOps.vue?vue&type=style&index=0&id=924d3bc8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "924d3bc8",
  null
  
)

export default component.exports