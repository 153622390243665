


























































import WorkerSelect from "../Worker/WorkerSelect.vue";
import WeekDatePicker from "../Form/DatePicker/WeekDatePicker.vue";
import BooleanRefusalSelect from "../Form/Select/BooleanRefusalSelect.vue";
import DATE_FORMAT from "../../utils/date/DATE_FORMAT";
import moveDateByDays from "../../utils/moveDateByDays";
import stringifyDate from "../../utils/date/stringifyDate";
import {updatePageQuery} from "../../utils/pageUrl/handlePageQuery";
import isEqual from "lodash/isEqual";
import Vue from "vue";
import {Component, Prop, Watch} from "vue-property-decorator";
import type {WeeklyScheduleFiltersQuery, WorkerOption} from "../../types/WeeklySchedule";

@Component({
  name: "WeeklyScheduleFilters",
  components: {BooleanRefusalSelect, WeekDatePicker, WorkerSelect},
})

export default class WeeklyScheduleFilters extends Vue {
  @Prop({type: Date, default: null}) readonly startDate!: Date;
  @Prop({type: Array, default: () => []}) readonly worker!: Array<string>;
  @Prop({type: Boolean, default: null}) readonly canceled!: boolean;

  start: Date = this.startDate;
  selectedWorkers: Array<WorkerOption|string> = this.worker;
  showCanceled: boolean = this.canceled;

  get startDateString(): string {
    return stringifyDate(this.start, DATE_FORMAT.DATE_TIME);
  }

  get workerIds(): Array<string> {
    return this.selectedWorkers.map(worker => (typeof worker === "string") ? worker : worker.value);
  }

  @Watch("startDate") onStartDateChange(val: Date): void
  {
    this.start = val;
  }

  @Watch("worker") onWorkerChange(val: Array<WorkerOption|string>): void
  {
    this.selectedWorkers = val;
  }

  @Watch("canceled") onCanceledChange(val: boolean): void
  {
    this.showCanceled = val;
  }

  async previousWeek(): Promise<void> {
    this.start = moveDateByDays(this.start, -7);
    await this.updateSchedule()
  }

  async nextWeek(): Promise<void> {
    this.start = moveDateByDays(this.start, 7);
    await this.updateSchedule()
  }

  async updateSchedule(): Promise<void> {
    const query:WeeklyScheduleFiltersQuery = {
      start: this.startDateString,
      worker: this.workerIds.join(",") || undefined,
      canceled: this.showCanceled || undefined,
    };
    if (isEqual(this.$route.query, query)) {
      this.$emit("reload");
      return;
    }
    await updatePageQuery(query);
  }

  async submit(): Promise<void> {
    await this.updateSchedule();
  }
}
