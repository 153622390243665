















import Vue from "vue";
import ObjectSelect from "../Form/Select/ObjectSelect.vue";
import Component from "vue-class-component";
import type {Refund, Repayment} from "../../types/Prescription";
import {Prop, Ref, Watch} from "vue-property-decorator";
import read from "../../rest/read";

@Component({
  name: "RepaymentSelect",
  components: {ObjectSelect}
})
export default class RepaymentSelect extends Vue {
  @Prop({type: String, default: null}) readonly value!: string;
  @Prop({type: String, default: "Wybierz..."}) readonly placeholder!: string;
  @Prop({type: Boolean, default: false}) readonly disabled!: false;
  @Prop({type: String, required: true}) readonly drugEan!: string;

  @Ref("multiselect") readonly multiselect!: ObjectSelect;

  @Watch("drugEan", {immediate: false}) onChange(): void {
    this.getOptions()
  }

  private loading: boolean = false;

  private options: Array<Repayment> = [
    {value: "100%", name: "100%"},
  ];

  private get selectedOption(): Repayment|null {
    return this.options.find((option) => option.value === this.value) || null;
  }

  async mounted(): Promise<void> {
    await this.getOptions();
  }

  private async getOptions(): Promise<void> {
    this.loading = true;
    try {
      const {items} = await read<{ items: Array<Refund> }>(`/api/refunds/${this.drugEan}`);
      this.options = items.map(item => ({
        ...item,
        name: item.value,
      }));
    } catch(e) {
      console.error(e);
    }
    this.loading = false;
  }

  private onBlur(): void {
    this.$emit("blur");
  }

  private update(option: Repayment|null): void {
    this.$emit("input", option ? option.value : null);
  }

  public activate(): void {
    this.multiselect.activate();
  }

  public deactivate(): void {
    this.multiselect.deactivate();
  }

  private onSelect(option: Repayment|null): void {
    this.$emit("select", option);
  }
}
