<template>
  <loading-mask :loading="loading">
    <evaluation-result
      v-if="overview"
      :creation-date="creationDate"
      :patient-full-name="overview.displayName"
      :worker-full-name="workerFullName"
      :evaluator-type="evaluatorType"
      :components="components"
      :core-set-names="coreSetNames"
    />
  </loading-mask>
</template>
<script>
import read from "../../../rest/read";
import EvaluationResult from "../../../components/Ipz/Evaluation/EvaluationResult";
import LoadingMask from "../../../components/Loading/LoadingMask";

export default {
  components: {LoadingMask, EvaluationResult},
  props: {
    ipzId: {type: String, required: true},
    evaluationId: {type: String, required: true},
  },
  data() {
    return {
      creationDate: null,
      overview: null,
      workerFullName: null,
      evaluatorType: null,
      components: null,
      coreSetNames: null,
      loading: false,
    }
  },
  watch: {
    async ipzId() {
      await this.loadData();
    },
    async evaluationId() {
      await this.loadData();
    },
  },
  async mounted() {
    await this.loadData();
  },
  methods: {
    async loadData() {
      this.loading = true;
      const {creationDate, overview, workerFullName, evaluatorType, components} =
        await read(`/api/ipzs/${this.ipzId}/evaluations/${this.evaluationId}/result`);
      this.creationDate = creationDate;
      this.overview = overview;
      this.workerFullName = workerFullName;
      this.evaluatorType = evaluatorType;
      this.components = components;
      this.coreSetNames = overview.coreSetNames;
      this.loading = false;
    },
  }
}
</script>
