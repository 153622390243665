











import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";
import ObjectSelect from "../../../Form/Select/ObjectSelect.vue";
import read from "../../../../rest/read";

interface HospitalEntry {
  name: string,
  address: string,
}

interface HospitalOption {
  name: string,
  value: HospitalEntry,
}

interface HospitalsResponse {
  items: Array<HospitalEntry>,
}

@Component({components: {
  ObjectSelect,
}})
export default class HospitalSelect extends Vue {
  @Prop({type: Object, default: null}) readonly value!: HospitalOption|null;
  @Prop({type: Boolean, default: null}) readonly state!: boolean;
  @Prop({type: String, default: null}) readonly placeholder!: string;

  options: Array<HospitalOption> = [];
  loading: boolean = false;

  async mounted(): Promise<void> {
    await this.loadOptions();
  }

  async loadOptions(): Promise<void> {
    this.loading = true;
    try {
      const {items} = await read<HospitalsResponse>("/api/hospitals");

      this.options = items.map((item: HospitalEntry) => this.buildOptionFromEntry(item));
      if (!!this.value) {
        const option = this.value;
        if (!this.optionFound(option)) {
          this.options.unshift(option);
        }
      }
    } catch (e) {
      console.error(e);
    }
    this.loading = false;
  }

  emitInput(option: HospitalOption|null): void {
    this.$emit("input", option);
  }

  select(option: HospitalOption|null): void {
    this.$emit("select", option);
  }

  buildOptionFromEntry(entry: HospitalEntry): HospitalOption {
    return {
      name: `${entry.name}, ${entry.address}`,
      value: {...entry},
    };
  }

  optionFound(value: HospitalOption): boolean {
    return !!this.options.find(
      (option: HospitalOption) => option.value.name === value.value.name
        && option.value.address === value.value.address
    );
  }
}
