<template>
  <object-select
    :value="selectedCourseSubtypes"
    :options="options"
    :state="state"
    :multiple="true"
    placeholder="Wybierz podkategorię kursu"
    @input="update"
  />
</template>

<script>
import ObjectSelect from "../Form/Select/ObjectSelect";
import t from "../../i18n";
export default {
  components: {
    ObjectSelect
  },
  props: {
    courseSubtypes: {type: Array, default: () => []},
    state: ObjectSelect.props.state, //eslint-disable-line
  },
  data() {
    const options = [
      {name: t("@prevention.social_workers"), value: "social_workers"},
      {name: t("@prevention.public_trust_workers"), value: "public_trust_workers"},
      {name: t("@prevention.POZ_doctors"), value: "POZ_doctors"},
      {name: t("@prevention.priests"), value: "priests"},
      {name: t("@prevention.police"), value: "police"},
      {name: t("@prevention.municipal_police"), value: "municipal_police"},
      {name: t("@prevention.judges"), value: "judges"},
      {name: t("@prevention.prosecutors"), value: "prosecutors"},
      {name: t("@prevention.psychologists"), value: "psychologists"},
      {name: t("@prevention.school_educators"), value: "school_educators"},
      {name: t("@prevention.others"), value: "others"},
      {name: t("@prevention.medical_caregivers"), value: "medical_caregivers"},
      {name: t("@prevention.family_assistants"), value: "family_assistants"},
      {name: t("@prevention.occupational_therapists"), value: "occupational_therapists"},
      {name: t("@prevention.medical_rescuers"), value: "medical_rescuers"},
      {name: t("@prevention.nurses"), value: "nurses"},
    ];
    return {
      options: options.sort((a,b) => {
        if(a.name < b.name) return -1;
        if(b.name > a.name) return 1;
        return 0;
      }),
    };
  },
  computed: {
    selectedCourseSubtypes () {
      return this.options.reduce((list, option) => {
        if(this.courseSubtypes.find((selectedOption) => {
          return option.value === selectedOption;}))
        {
          return list.concat([option])
        }
        return list;
      }, []);
    }
  },
  methods: {
    update(courseSubtypes) {
      this.$emit("input", courseSubtypes.reduce(function(list, courseSubtype) {
        return list.concat([courseSubtype.value]);
      }, []),);
    },
  },
}
</script>
