<template>
  <div>
    <loading-mask :loading="loading">
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th>
                Częstotliwość wykonania usługi
              </th>
              <th>
                Typ usługi
              </th>
              <th>
                Sugerowany specjalista
              </th>
              <th class="text-right">
                Opcje
              </th>
            </tr>
          </thead>
          <tbody>
            <MeetingRequirementRow
              v-for="(requirement, index) in computedRequirements"
              :key="requirement.meetingRequirementId"
              v-model="computedRequirements[index]"
              :read-only="readOnly"
              @requirement-removed="requirementRemoved"
              @requirement-updated="requirementUpdated"
            />
            <tr v-if="!computedRequirements.length">
              <td
                colspan="4"
                class="text-center text-muted"
              >
                Żadne zadanie nie pasuje do podanych kryteriów...
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <meeting-requirements-add
        v-if="!readOnly"
        :ipz-id="ipzId"
        @new-requirement-added="requirementAdded"
      />
    </loading-mask>
  </div>
</template>


<script>
import read from "../../../../rest/read";
import MeetingRequirementsAdd from "./MeetingRequirementsAdd";
import MeetingRequirementRow from "./MeetingRequirementRow";
import LoadingMask from "../../../Loading/LoadingMask";

export default {
  components: {LoadingMask, MeetingRequirementRow, MeetingRequirementsAdd},
  props: {
    ipzId: {type: String, required: true},
    readOnly: {type: Boolean, required: true},
  },
  data() {
    return {
      oldRequirements: [],
      allTreatmentTypes: [],
      loading: false,
    };
  },
  computed: {
    computedRequirements() {
      return this.oldRequirements.map(requirement => {
        const treatmentType = this.allTreatmentTypes
          .find(treatmentType => treatmentType.value === requirement.treatmentType);
        requirement.treatmentName = treatmentType.name;
        return requirement;
      });
    },
  },
  async mounted() {
    this.loading = true;

    await this.loadTreatmentTypes();
    await this.loadRequirements();

    this.loading = false;
  },
  methods: {
    async loadTreatmentTypes() {
      const {items} = await read("/api/treatment-types");
      this.allTreatmentTypes = items;
    },
    async loadRequirements() {
      const {items} = await read(`/api/ipzs/${this.ipzId}/details/meeting-requirements`);
      this.oldRequirements = items;
      if (items.length > 0) {
        this.$emit("new-entry-added", this.oldRequirements);
      }
    },
    requirementAdded(requirement) {
      this.oldRequirements = [requirement].concat(this.oldRequirements);
      this.$emit("entry-updated", this.oldRequirements);
    },
    requirementRemoved(requirementId) {
      this.oldRequirements = this.oldRequirements.filter(
        (requirement) => requirementId !== requirement.meetingRequirementId
      );
      this.$emit("entry-updated", this.oldRequirements);
    },
    requirementUpdated(requirement) {
      const index = this.oldRequirements.findIndex(
        (oldRequirement) => oldRequirement.meetingRequirementId === requirement.meetingRequirementId
      );
      if (index > -1) {
        this.oldRequirements = [
          ...this.oldRequirements.slice(0, index),
          requirement,
          ...this.oldRequirements.slice(index + 1)
        ]
      }
    },
  },
};
</script>
