<template>
  <div>
    <div class="d-flex justify-content-between align-items-start">
      <span class="font-16 mr-1">
        {{ treatmentName }} {{ dateInfo }}
      </span>
      <div class="text-right ml-auto">
        <b-btn
          v-if="!readOnly"
          :variant="isAutosave ? 'success' : 'warning'"
          class="m-1"
          size="sm"
          @click="$emit('autosaveToggle')"
        >
          {{ isAutosave ? 'Auto zapis Wł.' : 'Auto zapis Wył.' }}
        </b-btn>
        <b-btn
          v-if="!readOnly"
          :disabled="isSaving && close"
          class="m-1"
          size="sm"
          @click="$emit('save', false)"
        >
          <i
            :class="isSaving ? 'fa-spin fa-spinner' : (isDirty ? 'fa-history' : 'fa-check')"
            class="fas"
          />
          {{ isDirty ? 'Niezapisane zmiany' : 'Wszystko zapisane' }}
        </b-btn>
        <is-granted
          :privileges="['UPDATE_APPOINTMENT_PARAMETERS']"
          :subject="appointmentId"
          component="fragment"
        >
          <b-btn
            v-b-modal.change-appointment-parameters-modal
            class="m-1"
            size="sm"
            variant="primary"
          >
            <i class="fas fa-cog" />
            Określ parametry wizyty
          </b-btn>
        </is-granted>
        <is-granted
          :privileges="['CHANGE_BRANCH']"
          :subject="appointmentId"
          component="fragment"
        >
          <b-btn
            v-b-modal.change-branch-modal
            class="m-1"
            size="sm"
            variant="primary"
          >
            <i class="fas fa-cog" />
            Zmień komórkę organizacyjną
          </b-btn>
        </is-granted>
        <b-btn
          v-if="patient"
          v-b-modal="`finished-appointments-modal`"
          class="m-1"
          size="sm"
          variant="primary"
        >
          Poprzednie wizyty pacjenta
        </b-btn>
        <is-granted
          v-if="!readOnly"
          :privileges="['CANCEL_APPOINTMENT']"
          :subject="appointmentId"
          component="fragment"
        >
          <b-btn
            v-b-modal.cancel-reason-modal
            class="m-1"
            size="sm"
            variant="danger"
          >
            <i class="fas fa-ban" />
            Odwołaj wizytę
          </b-btn>
        </is-granted>
        <a
          v-if="printable"
          :href="`/api/appointment/document/${appointmentDocumentId}/print`"
          class="btn btn-sm btn-secondary m-1"
          target="_blank"
        >
          <i class="fas fa-print" />
          Drukuj kartę informacyjną
        </a>
      </div>
    </div>
    <div class="d-flex justify-content-between align-items-end">
      <h4
        v-if="patient"
        class="mr-1"
      >
        <component
          :is="accessToPatientCard ? 'router-link' : 'span'"
          :to="returnUrl"
        >
          {{ patient.displayName }}
        </component>
        <patient-bmi-alert
          class="ml-1"
          :patient-id="patient.patientId"
        />
      </h4>
      <appointment-document-save-buttons
        class="text-right ml-auto"
        :read-only="readOnly"
        :is-saving="isSaving"
        :close="close"
        :status="status"
        :scheduled-date="scheduledDate"
        @save="$emit('save', false)"
      />
    </div>
    <change-appointment-parameters
      :appointment-id="appointmentId"
      title="Określ parametry wizyty"
    />
    <change-branch-modal
      :appointment-document-id="appointmentDocumentId"
      :branch-id="branchId"
      @changed="$emit('updateBranchId', $event)"
    />
    <finished-appointments-list-modal
      v-if="patient"
      :patient-id="patient.patientId"
      :patient-display-name="patient.displayName"
      :current-appointment-id="appointmentId"
    />
    <cancel-reason-modal
      :appointment-id="appointmentId"
      title="Czy anulować wizytę?"
      @appointmentCanceled="$emit('fetchData')"
    />
  </div>
</template>

<script>
import IsGranted from "../IsGranted";
import ChangeBranchModal from "./AppointmentActions/ChangeBranchModal";
import FinishedAppointmentsListModal from "./AppointmentActions/FinishedAppointmentsListModal";
import ChangeAppointmentParameters from "./AppointmentActions/ChangeAppointmentParameters";
import CancelReasonModal from "./AppointmentActions/CancelReasonModal";
import AppointmentDocumentSaveButtons from "./AppointmentDocumentSaveButtons";
import PatientBmiAlert from "@/components/Patient/PatientBmiAlert";

export default {
  name: "AppointmentDocumentTitleBox",
  components: {
    PatientBmiAlert,
    AppointmentDocumentSaveButtons,
    CancelReasonModal,
    ChangeAppointmentParameters,
    FinishedAppointmentsListModal,
    ChangeBranchModal,
    IsGranted,
  },
  props: {
    accessToPatientCard: {type: Boolean, required: true},
    appointmentDocumentId: {type: String, required:true},
    appointmentId: {type: String, required: true},
    branchId: {type: String, default: null},
    close: {type: Boolean, required: true},
    dateInfo: {type: String, required: true},
    isAutosave: {type: Boolean, required: true},
    isDirty: {type: Boolean, required: true},
    isSaving: {type: Boolean, required: true},
    patient: {type: Object, default: null},
    printable: {type: Boolean, required: true},
    readOnly: {type: Boolean, required: true},
    returnUrl: {type: String, required: true},
    scheduledDate: {type: String, required: true},
    status: {type: String, required: true},
    treatmentName: {type: String, required: true},
  },
}
</script>
