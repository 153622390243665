<template>
  <collapse-list :items="sortedWorkers">
    <template slot-scope="data">
      <div 
        :key="data.item.workerId" 
        class="item"
      >
        {{ data.item.name }}
        <template v-if="data.item.workerId === mainWorkerId">
          <slot name="mainWorker" />
        </template>
      </div>
    </template>
    <template #show-more>
      <i class="fa fa-caret-down" /> Pokaż dodatkowych specjalistów
    </template>
    <template #show-less>
      <i class="fa fa-caret-up" /> Ukryj dodatkowych specjalistów
    </template>
  </collapse-list>
</template>

<script>
import CollapseList from "../Collapse/CollapseList";

export default {
  name: "WorkerCollapseList",
  components: {CollapseList},
  props: {
    workers: {type: Array, required: true},
    mainWorkerId: {type: String, default: null},
  },
  computed: {
    sortedWorkers() {
      return [...this.workers].sort((worker1) => {
        return worker1.workerId === this.mainWorkerId ? -1: 1;
      });
    },
  },
}
</script>

<style lang="scss" scoped>
.collapse-visible {
  .item {
    margin-top: 5px;
  }

  & > .item {
    font-weight: 700;
    margin-top: 0;
  }
}
</style>
