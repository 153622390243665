<template>
  <object-select
    :value="selectedValue"
    :options="options"
    :placeholder="placeholder"
    :state="state"
    aria-required
    @input="update"
  />
</template>

<script>
import ObjectSelect from "../../Form/Select/ObjectSelect";
import t from "../../../i18n";

export default {
  components: {
    ObjectSelect,
  },
  props: {
    value: {type: String, default: null},
        state: ObjectSelect.props.state, // eslint-disable-line
    placeholder: {type: String, default: "Wybierz powód odwołania wizyty"}
  },
  data() {
    return {
      optionValues: [
        {value: "patient_did_not_show_up"},
        {value: "patient_canceled"},
        {value: "appeal_due_to_the_fault_of_a_specialist"},
        {value: "reception_mistake"},
        {value: "doubled_visit"},
        {value: "other"}
      ],
    };
  },
  computed: {
    options() {
      return this.optionValues.map(option => ({
        ...option,
        name: t(`@cancel-reason.${option.value}`),
      }));
    },
    selectedValue() {
      return this.options.find((option) => option.value === this.value);
    },
  },
  methods: {
    update(option) {
      this.$emit("input", option ? option.value : null);
    },
  },
}
</script>

<style scoped>

</style>
