<template>
  <span>
    <the-mask
      :mask="['####-##-##']"
      :class="{'is-invalid': state === false}"
      :value="value"
      masked
      class="form-control"
      placeholder="YYYY-MM-DD"
      @input="update"
    />
    <small class="form-text text-muted">Podaj datę w formacie YYYY, YYYY-MM lub YYYY-MM-DD</small>
  </span>
</template>

<script>
import {TheMask} from "vue-the-mask"
import DATE_FORMAT from "../../../utils/date/DATE_FORMAT";

const YEAR = DATE_FORMAT.YEAR;
const MONTH = DATE_FORMAT.MONTH;
const DATE = DATE_FORMAT.DATE;

export default {
  components: {
    TheMask
  },
  props: {
    value: {type: String, default: null},
    state: {type: Boolean, default: null},
  },
  methods: {
    update(value) {
      this.$emit("input", value);
    }
  },
}
</script>
