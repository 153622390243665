<template>
  <b-modal
    :visible="visible"
    centered
    lazy
    no-close-on-backdrop
    size="lg"
    title="Dodaj załącznik do karty pacjenta"
    title-tag="h3"
    @ok="save"
    @hidden="close"
  >
    <file-upload
      accept="image/*, application/pdf"
      multiple
      :disabled="disabled"
      @removed="removeFile"
      @uploaded="addFile"
    />
    <branch-select
      v-model="branchId"
      :disabled="disabled"
      :state="state('branchId')"
    />
    <error-message
      :errors="errors"
      field="branchId"
    />
    <template #modal-footer="{}">
      <b-button
        variant="secondary"
        :disabled="disabled"
        @click="close()"
      >
        Anuluj
      </b-button>
      <b-button
        variant="primary"
        :disabled="disabled"
        @click="save()"
      >
        Dodaj wybrane pliki
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import FileUpload from "../../../File/FileUpload";
import BranchSelect from "@/components/Branch/BranchSelect";
import ErrorMessage from "@/components/Form/ErrorMessage";

export default {
  name: "AddPatientAttachmentModal",
  components: {ErrorMessage, BranchSelect, FileUpload},
  props: {
    visible: {required: true, type: Boolean},
    disabled: {required: true, type: Boolean},
    errors: {required: false, type: Array, default: () => []}
  },
  data() {
    return {files: [], branchId: null};
  },
  methods: {
    close() {
      if (this.disabled) return;

      this.$emit("close");
      this.files = [];
      this.branchId = null;
    },
    save() {
      if (this.disabled) return;
      this.$emit("save", {files: this.files, branchId: this.branchId});
      this.branchId = null;
    },
    addFile(file) {
      this.files.push(file);
    },
    removeFile(file) {
      this.files = this.files.filter(f => f.fileId !== file.fileId);
    },
    state(field) {
      return this.errors.find((error) => error.field === field) ? false : null;
    },
  },
}
</script>
