<template>
  <div>
    <div class="page-title-box">
      <div class="d-flex justify-content-between align-items-end">
        <h4 class="my-0 m-r-15">
          {{ $route.meta.title }}
        </h4>

        <button
          type="button"
          class="btn btn-secondary btn-sm"
          title="Opis i legenda raportu"
          @click="openReportTooltipModal(reportId)"
        >
          <i class="fa fa-question" />
        </button>
      </div>
    </div>

    <last-edited-appointment />

    <slot />

    <report-tooltip-modal
      :report="selectedReport"
      @close="closeTooltipModal"
    />
  </div>
</template>

<script>
import ReportTooltipModal from "./ReportTooltipModal";
import reportItems from "./reportsData.ts";
import LastEditedAppointment from "@/components/Appointment/LastEditedAppointment";

export default {
  name: "ReportContainer",
  components: {
    ReportTooltipModal,
    LastEditedAppointment
  },
  props: {
    reportId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      selectedReport: null
    }
  },
  methods: {
    openReportTooltipModal(reportId) {
      const report = reportItems.find(report => report.id === reportId)
      this.selectedReport = report ? report : null;
    },
    closeTooltipModal() {
      this.selectedReport = null;
    },
  }
}
</script>
