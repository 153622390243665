<template>
  <div id="app">
    <splash-screen />
    <toast-notification />
    <router-view />
  </div>
</template>

<script>
import SplashScreen from "./components/SplashScreen";
import ToastNotification from "./components/ToastNotification";

export default {
  name: "App",
  components: {ToastNotification, SplashScreen},
};
</script>
