



































import Vue from "vue";
import {Component,Prop} from "vue-property-decorator";
import type {ChildIpzItemFormData} from "../../../types/ChildIpzs";
import ChildIpzSignaturesCheckbox from "./ChildIpzSignaturesCheckbox.vue";

@Component({
  name: "ChildIpzContent",
  components: {ChildIpzSignaturesCheckbox}
})

export default class ChildIpzContent extends Vue {
  @Prop({type: Object, default: () => ({})}) readonly content!: ChildIpzItemFormData;
}
