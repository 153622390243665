var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"text-right"},[(_vm.overview.numberOfCategories > 0)?_c('button',{staticClass:"btn btn-success text-white",attrs:{"disabled":_vm.loading || _vm.overview.status !== 'opened'},on:{"click":_vm.createNewEvaluation}},[_c('i',{staticClass:"fa",class:_vm.loading ? 'fa-spinner fa-spin' : 'fa-plus'}),_vm._v(" Rozpocznij nową ewaluację ")]):_c('router-link',{staticClass:"btn btn-success text-white",attrs:{"to":{name: 'icf_creator', params: {ipzId: _vm.overview.ipzId}}}},[_c('i',{staticClass:"fa fa-plus"}),_vm._v(" Przejdź do kreatora ICF ")])],1),_c('div',[_c('div',{staticClass:"card-box p-0 m-t-10 table-responsive"},[_c('table',{staticClass:"table"},[_vm._m(0),_c('tbody',_vm._l((_vm.evaluations),function(evaluation){return _c('tr',{key:evaluation.id},[_c('td',[_vm._v(_vm._s(evaluation.workerName))]),_c('i18n',{attrs:{"msgid":evaluation.evaluatorType ? evaluation.evaluatorType : 'brak',"component":"td"}}),_c('td',[_c('b-badge',{staticClass:"status-badge",attrs:{"variant":_vm.getBadgeVariant(evaluation.evaluationStatus)}},[_c('i18n',{attrs:{"component":"fragment","msgid":evaluation.evaluationStatus}})],1)],1),_c('td',[_vm._v(_vm._s(_vm.evaluationDate(evaluation.createdAt)))]),_c('td',[_c('is-granted',{attrs:{"privileges":[
                  'GET_FINISHED_EVALUATION_BY_ID',
                  'PRINT_FINISHED_EVALUATION_BY_ID',
                  'EDIT_FINISHED_EVALUATION_BY_ID',
                  'EVALUATION_VIEW' ],"subject":evaluation.id,"component":"fragment"}},[_c('b-dropdown',{attrs:{"text":"Opcje","variant":"outline-secondary"}},[_c('is-granted',{attrs:{"privileges":['GET_FINISHED_EVALUATION_BY_ID'],"subject":evaluation.id,"component":"fragment"}},[_c('b-dropdown-item',{attrs:{"to":{
                        name: 'ipz_evaluation_result',
                        params: {ipzId: _vm.overview.ipzId, evaluationId: evaluation.id}
                      }}},[_c('i',{staticClass:"fa fa-eye"}),_vm._v(" Podgląd ")])],1),_c('is-granted',{attrs:{"privileges":['PRINT_FINISHED_EVALUATION_BY_ID'],"subject":evaluation.id,"component":"fragment"}},[_c('b-dropdown-item',{attrs:{"href":("/api/ipzs/" + (_vm.overview.ipzId) + "/evaluations/" + (evaluation.id) + "/result/pdf"),"target":"_blank"}},[_c('i',{staticClass:"fa fa-print"}),_vm._v(" Drukuj ")])],1),_c('is-granted',{attrs:{"privileges":['EDIT_FINISHED_EVALUATION_BY_ID'],"subject":evaluation.id,"component":"fragment"}},[_c('b-dropdown-item-btn',{on:{"click":function($event){return _vm.openEditEvaluationModal(evaluation)}}},[_c('i',{staticClass:"fas fa-pencil-alt"}),_vm._v(" Edytuj datę ewaluacji ")])],1),(_vm.overview.status === 'opened')?_c('is-granted',{attrs:{"privileges":['EVALUATION_VIEW'],"subject":evaluation.id,"component":"fragment"}},[_c('b-dropdown-item',{attrs:{"to":{
                        name: 'ipz_evaluation_view',
                        params: {ipzId: _vm.overview.ipzId, evaluationId: evaluation.id }
                      }}},[_c('i',{staticClass:"fa fa-edit"}),_vm._v(" Edytuj ewaluację ")])],1):_vm._e()],1)],1)],1)],1)}),0)]),_c('pagination-description',{attrs:{"items-count":_vm.evaluations.length,"page":_vm.evaluationPagination.currentPage,"per-page":_vm.evaluationPagination.perPage,"total-count":_vm.evaluationPagination.totalRows}},[_c('b-pagination',{staticClass:"mb-0",attrs:{"total-rows":_vm.evaluationPagination.totalRows,"per-page":_vm.evaluationPagination.perPage,"align":"right"},on:{"input":_vm.loadEvaluations},model:{value:(_vm.evaluationsPage),callback:function ($$v) {_vm.evaluationsPage=$$v},expression:"evaluationsPage"}})],1)],1)]),_c('EditEvaluationModal',{attrs:{"evaluation-id":_vm.evaluationId,"evaluation-created-at":_vm.evaluationCreatedAt},on:{"editEvaluationModalEvent":_vm.loadEvaluations}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',[_vm._v("Osoba przeprowadzająca badanie")]),_c('th',[_vm._v("Osoba ewaluująca")]),_c('th',[_vm._v("Status ewaluacji")]),_c('th',[_vm._v("Data ewaluacji")]),_c('th')])])}]

export { render, staticRenderFns }