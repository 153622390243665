<template>
  <div class="card-box">
    <room-occupancy-report-calendar
      :class="{'loaded-pdf-data': !isPrinting}"
      :start="filters.start"
      :items="items"
      :rooms="rooms"
    />
  </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import stringifyDate from "../../utils/date/stringifyDate";
import processResponseException from "../../utils/errors/processResponseException";
import {errorsMixin} from "../../mixins/errorsMixin.js";
import parseDate from "../../utils/date/parseDate";
import read from "../../rest/read";
import DATE_FORMAT from "../../utils/date/DATE_FORMAT";
import t from "../../i18n";
import RoomOccupancyReportCalendar from "./RoomOccupancyReportCalendar";
import colors from "../../styles/style.scss";

export default {
  name: "RoomOccupancyReportWidget",
  components: {RoomOccupancyReportCalendar},
  mixins: [errorsMixin],
  props: {
    title: {type: String, default: ""},
    filters: {type: Object, default: ()=>({})},
  },
  data() {
    return {
      items: [],
      rooms: [],
      DATE_FORMAT,
      isPrinting: true,
    };
  },
  computed: {
    ...mapState({
      clinicParameters: state => state.clinicParameters.parameters,
    }),
    startDate() {
      const date = this.filters.start ? parseDate(this.filters.start) : new Date();
      date.setHours(0, 0, 0, 0);
      return date;
    },
    endDate() {
      const end = new Date(this.startDate);
      end.setHours(23, 59, 59);
      return end;
    },
    startDateString() {
      return stringifyDate(this.startDate);
    },
    endDateString() {
      return stringifyDate(this.endDate);
    },
  },
  watch: {
    filters: {
      immediate: true,
      deep: true,
      async handler() {
        await this.fetchData();
      },
    },
  },
  methods: {
    ...mapMutations("appointmentListItem", ["saveAppointmentListItem",]),
    async fetchData() {
      this.errors = [];
      try {
        await Promise.all([
          this.loadItems(),
          this.loadRooms(),
        ]);
      } catch (exception) {
        this.errors = processResponseException(exception);
      }
      this.isPrinting = false;
    },
    async loadItems() {
      const params = {
        startDate: this.startDateString,
        endDate: this.endDateString,
        branchId: this.filters.branch,
        statuses: ["created", "opened", "finished", "canceled"],
        page: 1,
        perPage: 1000,
      };
      const {items} = await read("/api/appointments", params);
      this.items = items
        .filter(item => !item.mobile)
        .map(item => ({
          ...item,
          id: item.appointmentId,
          resourceId: item.roomId,
          start: parseDate(item.date),
          end: parseDate(item.scheduledEndDate),
          title: this.getAppointmentTitle(item),
          backgroundColor: this.getBackgroundColor(item.status),
          mainWorkerId: item.mainWorkerId,
          patientPresence: item.patientPresence,
          treatmentType: item.treatment_type,
          workers: item.workers,
          appointmentId: item.appointmentId,
          group: item.group,
          patients: item.patients,
          status: item.status ,
          mobile: item.mobile,
        }));
    },
    async loadRooms() {
      this.loading = true;
      const params = {
        branchId: this.filters.branch,
        type: this.filters.type,
        floor: this.filters.floor,
        description: this.filters.description,
        number: this.filters.number,
      };
      const {items} = await read("/api/rooms", params);

      const branches = items.reduce((list, room) => {
        if (!list.includes(room.branchId)) {
          list.push(room.branchId);
        }
        return list;
      }, []);

      this.rooms = items.map(room => ({
        ...room,
        title: this.getRoomTitle(room, branches.length),
      }));
      this.loading = false;
    },
    getAppointmentTitle(item) {
      let name = this.getPatientCaseNumber(item);
      if(null === name) {
        name = this.getPatientSurname(item);
      }
      const mainWorker = this.getAppointmentMainWorkerName(item);
      const info = `${name}\n-\n${mainWorker}`
      const additionalWorkersCount = item.workers.length - 1;
      const title = additionalWorkersCount
        ? `${info} (+${additionalWorkersCount})`
        : info;
      return `${this.getAppointmentStatus(item.status)} - ${title}`;
    },
    getPatientSurname(item){
      if (!item.patients.length) {
        return "Wizyta bez pacjentów";
      }
      if (item.patients.length > 1) {
        return "Wizyta grupowa";
      }
      return `${item.patients[0].surname}`;
    },
    getPatientName(item) {
      if (!item.patients.length) {
        return "Wizyta bez pacjentów";
      }
      if (item.patients.length > 1) {
        return "Wizyta grupowa";
      }
      return `${item.patients[0].displayName}`;
    },
    getPatientCaseNumber(item) {
      if ((this.clinicParameters && this.clinicParameters.patientAnonymous)
          || !item.patientPresence || item.patients.length !== 1) {
        return null;
      }
      return item.patients[0].fileNumber;
    },
    getAppointmentMainWorkerName(item) {
      const mainWorker = item.workers.find(worker => worker.workerId === item.mainWorkerId);
      return mainWorker ? mainWorker.surname : "";
    },
    getAppointmentStatus(status) {
      switch(status) {
        case "created":
          return "(U)";
        case "checkedin":
          return "(W)";
        case "opened":
          return "(O)";
        case "finished":
          return "(Z)";
        case "canceled":
          return "(A)";
        default:
          return undefined;
      }
    },
    getRoomTitle(room, branchesCount) {
      const roomType = t(`@room-type.${room.type}`);
      const roomName = `pokój: ${room.number} (${roomType})`;
      return branchesCount === 1
        ? roomName
        : `${room.branch}\npokój: ${room.number} (${roomType})`;
    },
    getBackgroundColor(status) {
      switch(status) {
        case "created":
          return colors.warning;
        // case "checkedin":
        //   return colors.primary;
        case "opened":
          return colors.success;
        case "finished":
          return colors.inverse;
        case "canceled":
          return colors.danger;
        default:
          return undefined;
      }
    },
  },
}
</script>
