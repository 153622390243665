<template>
  <div>
    <b-alert
      :show="!hasPatientCardAccess"
      variant="danger"
    >
      Brak dostępu.
    </b-alert>
    <template v-if="hasPatientCardAccess">
      <loading-mask
        v-show="hasPatientDataAccess == null"
        loading
      />
      <b-alert
        :show="hasPatientDataAccess === false"
        variant="danger"
      >
        Nie posiadasz dostępu do danych wybranego pacjenta.
        Aby uzyskać dostęp, skontaktuj się z kierownikiem placówki.
      </b-alert>
      <patient-card
        v-if="hasPatientDataAccess === true"
        :patient-id="patientId"
        :atc-continuous-stay="atcContinuousStay"
        :atc-desc="atcDesc"
        :atc-page="atcPage"
        :atc-per-page="atcPerPage"
        :atc-statuses="atcStatuses"
      />
    </template>
  </div>
</template>

<script>
import PatientCard from "@/views/Patient/PatientCard";
import {mapMutations} from "vuex";
import {isGranted} from "@/security/isGranted";
import LoadingMask from "@/components/Loading/LoadingMask";

export default {
  name: "PatientPage",
  components: {LoadingMask, PatientCard},
  beforeRouteLeave(to, from, next) {
    this.saveAppointmentListItem(null);
    next();
  },
  props: {
    patientId: {type: String, required: true},
    atcDesc: {type: Boolean, default: null},
    atcPage: {type: Number, default: null},
    atcPerPage: {type: Number, default: null},
    atcStatuses: {type: Array, default: null},
    atcContinuousStay: {type: Boolean, default: null},
  },
  data() {
    return {
      hasPatientCardAccess: true,
      hasPatientDataAccess: null,
    };
  },
  watch: {
    async patientId() {
      await this.getPatientDataAccess();
    },
  },
  async mounted() {
    await this.getPatientCardAccess();
    await this.getPatientDataAccess();
  },
  methods: {
    ...mapMutations("appointmentListItem", ["saveAppointmentListItem",]),
    async getPatientCardAccess() {
      this.hasPatientCardAccess = await isGranted(["PATIENT_CARD"]);
    },
    async getPatientDataAccess() {
      this.hasPatientDataAccess = null;
      this.hasPatientDataAccess = await isGranted(["PATIENT_CARD"], this.patientId);
    },
  },
}
</script>
