
























import Vue from "vue";
import Component from "vue-class-component";
import {Prop, Ref} from "vue-property-decorator";
import RepaymentSelect from "./RepaymentSelect.vue";
import {Error} from "../../rest";
import blur from "../../directives/blur";
import type {Drug} from "../../types/Prescription";

@Component({
  name: "MedicationRepayment",
  directives: {
    blur,
  },
  components: {RepaymentSelect}
})
export default class MedicationRepayment extends Vue {
  @Prop({type: String, required: true}) readonly value!: string;
  @Prop({type: Boolean, default: false}) readonly disabled!: boolean;
  @Prop({type: Array, default: ()=>[]}) readonly errors!: Array<Error>;
  @Prop({type: String, required: true}) readonly drugEan!: string;

  @Ref("multiselect") readonly multiselect!: RepaymentSelect;

  private edited: boolean = false;

  private get isInvalid(): boolean {
    return !!this.errors.find((error) => error.field === "repayment");
  }

  private update(option: string|null): void {
    this.$emit("input", option);
  }

  private startEdit(): void {
    if (!this.disabled) {
      this.edited = true;
      this.$nextTick(() => this.multiselect.activate());
    }
  }

  private stopEdit(deactivate: boolean = true): void {
    if (deactivate) {
      this.multiselect.deactivate();
    }
    this.edited = false;
  }
}
