



















import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";
import ObjectSelect from "../Form/Select/ObjectSelect.vue";
import {resortCodes} from "../../utils/resortCodes/resortCodes";
import type {Option} from "../../types/MultiSelectTypes";

@Component({
  name: "ResortCodeSelect",
  components: {ObjectSelect},
})
export default class ClinicTypeSelect extends Vue {
  @Prop({type: Boolean, default: true}) readonly clearable!: boolean;
  @Prop({type: Boolean, default: false}) readonly disabled!: boolean;
  @Prop({type: String , default: "Wybierz"}) readonly placeholder!: string;
  @Prop({type: Boolean, default: null}) readonly state!: boolean;
  @Prop({type: Object, default: null}) readonly value!: Option;

  private loading: boolean = false;
  public options: Option[] = Object.entries(resortCodes).map(([value, name]) => ({
    name,
    value,
  }));

  private update(option: Option): void {
    this.$emit("input", option);
  }
}
