

















































import Component from "vue-class-component";
import {Mixins, Prop} from "vue-property-decorator";
import {generateUuid} from "../../../utils/uuid/generateUuid";
import create from "../../../rest/create";
import stringifyDate from "../../../utils/date/stringifyDate";
import read from "../../../rest/read";
import processResponseException from "../../../utils/errors/processResponseException";
import I18n from "../../i18n.vue";
import ObjectSelect from "../../Form/Select/ObjectSelect.vue";
import ErrorMessage from "../../Form/ErrorMessage.vue";
import {BasicEntryItem, default as getEntry} from "./EntryField";
import {mapState} from "vuex";
import ErrorsMixin from "../../../mixins/ErrorsMixin";
import type {Option} from "../../../types/MultiSelectTypes";

@Component({
  components: {
    ErrorMessage,
    ObjectSelect,
    I18n,
  },
  computed: mapState({
    workerId: (state: any): string => state.currentUser.user.workerId,
    workerName: (state: any): string => state.currentUser.user.name,
    surname: (state: any): string => state.currentUser.user.surname,
  }),
})

export default class MultiselectField extends Mixins(ErrorsMixin) {
  @Prop({type: String, required: true}) readonly diagnosisId!: string;
  @Prop({type: Object, required: true}) readonly field!: SelectFieldData;
  @Prop({type: Boolean, required: true}) readonly readOnly!: boolean;
  @Prop(ObjectSelect.props.name) readonly name!: string;
  @Prop(ObjectSelect.props.placeholder) readonly placeholder!: string;

  entries: Array<EntryItem> = this.field.entries || [];
  saveLoading: boolean = false;

  value: Array<Option> = [];
  options: Array<Option> = [];

  workerId?: string;
  workerName?: string;
  surname?: string;

  loading: boolean = false;
  typingTimeout?: number;

  async mounted() {
    const entry = getEntry(this.entries) as EntryItem;
    const values = entry ? [...entry.content.value] : [];
    if (values.length > 0) {
      this.value = await this.getInjectedOptions() as Array<Option>;
    }
  }

  get entry(): Array<string> {
    const entry = getEntry(this.entries) as EntryItem;

    return entry ? [...entry.content.value] : [];
  }

  async getInjectedOptions(): Promise<Array<Option>> {
    const entry = getEntry(this.entries) as EntryItem || null;
    if (null === entry) {
      return [];
    }
    const entryId = entry.entryId;
    const response = await read<{ items: Array<Code> }>(`/api/diagnosis-entry/${entryId}/codes`);

    return response.items.map((item: Code) => ({
      value: item.code,
      name: `${item.code} / ${item.description}`,
    } as Option));
  }

  loadOptions(phrase = "") {
    const entryType = this.field.entryType as RemoteEntryType;
    if (entryType.datasource) {
      this.loading = true;
      clearTimeout(this.typingTimeout);
      this.typingTimeout = setTimeout(async () => {
        const params = {};
        params[entryType.datasource.searchParam] = phrase;
        const response = await read<{ items: Array<Code> }>(
          entryType.datasource.url, params
        );
        this.options = response.items.map((item: Code) => ({
          value: item.code,
          name: `${item.code} / ${item.description}`,
        }));
        this.loading = false;
      }, 1000) as any;
    }
  }

  isSavingNotAllowed() {
    if (this.readOnly) {
      return true;
    }

    if (this.value.length !== this.entry.length) {
      return false;// provided that elements are unique
    }
    return this.value.filter((s) => this.entry.indexOf(s.value) === -1).length === 0;
  }

  async saveEntry(): Promise<void> {
    if (this.isSavingNotAllowed()) {
      return;//TODO add some info
    }
    this.saveLoading = true;
    try {
      const newEntry = {
        content: {
          type: "multiselect",
          value: this.value.map((v: Option) => v.value),
        },
        entryId: generateUuid(),
        type: this.field.entryType.key,
        createdAt: stringifyDate(new Date()),
        workerId: this.workerId,
      };
      await create(`/api/diagnosis/${this.diagnosisId}/entry`, newEntry);
      const entry = {
        ...newEntry,
        creator: {
          name: this.workerName,
          surname: this.surname,
        },
      } as EntryItem;
      this.entries = this.entries.concat([entry]);

      this.errors = [];
      this.$emit("new-entry-added");
    } catch (exception) {
      this.errors = processResponseException(exception);
    }
    this.saveLoading = false;
  }

  cancelLoading() {
    this.loading = false;
    clearTimeout(this.typingTimeout);
  }
  update(options: Array<Option>) {
    this.value = options;
  }
}

interface SelectFieldData {
  entries: Array<EntryItem>;
  entryType: EntryType;
}
interface EntryItem extends BasicEntryItem {
  content: ContentItem;
  entryId: string;
}
interface ContentItem {
  type: string;
  value: Array<string>;
}

type EntryType = RemoteEntryType | StaticEntryType;

interface RemoteEntryType {
  name: string;
  key: string;
  datatype: "remote";
  datasource: {url: string; searchParam: string; fetchParam: string};
}
interface StaticEntryType {
  name: string;
  key: string;
  datatype: "static";
  options: Array<OptionItem>;
}

interface OptionItem {
  value: string;
  label: string;
}

interface Code {
  code: string;
  description: string;
}
