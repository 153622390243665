
























































import ErrorsMixin from "../../mixins/ErrorsMixin";
import {Component, Mixins, Prop} from "vue-property-decorator";
import type {EstablishmentGeolocalization} from "../../types/Establishment";
import ErrorMessage from "../Form/ErrorMessage.vue";
import processResponseException from "../../utils/errors/processResponseException";
import update from "../../rest/update";

@Component({
  name: "EstablishmentGeolocalizationModal",
  components: {ErrorMessage}
})
export default class EstablishmentGeolocalizationModal extends Mixins(ErrorsMixin) {
  @Prop({type: String, required: true}) readonly branchId!: string;
  @Prop({type: String, required: true}) readonly name!: string;
  @Prop({type: Object, default: ()=>({})}) readonly geolocalization!: EstablishmentGeolocalization;

  loading: boolean = false;
  longitude = this.geolocalization?.longitude || null;
  latitude = this.geolocalization?.latitude || null;

  get modalId(): string {
    return `establishment-geolocalization-${this.branchId}`
  }

  get title(): string {
    return `Edycja danych geolokalizacyjnych podmiotu ${this.name}`
  }

  closeModal(): void {
    this.$bvModal.hide(this.modalId);
  }

  async submit(): Promise<void> {
    this.errors = [];
    this.loading = true;
    const data: EstablishmentGeolocalization = {
      longitude: this.longitude,
      latitude: this.latitude,
    };
    try {
      await update(`/api/clinic-geolocalization/${this.branchId}`, data);
      this.closeModal();
      this.$emit("edited");
    } catch(e) {
      this.errors = processResponseException(e);
    }
    this.loading = false;
  }

  resetModalData(): void {
    this.latitude = this.geolocalization?.latitude || null;
    this.longitude = this.geolocalization?.longitude || null;
    this.loading = false;
    this.errors = [];
  }
}
