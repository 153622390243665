<template>
  <div>
    <div class="row">
      <div class="col-lg-6 col-md-12">
        <icf-change-comparison-chart
          :ipz-id="overview.ipzId"
        />
      </div>
      <div class="col-lg-6 col-md-12">
        <patient-evaluations-list
          :overview="overview"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 col-md-12 card-box p-0 m-t-10">
        <loading-mask :loading="loadingEntries">
          <table class="table">
            <thead>
              <tr>
                <th>Osoba przeprowadzająca badanie</th>
                <th>Typ diagnozy / grupa</th>
                <th>Data diagnozy</th>
                <th class="text-center">
                  Opcje
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="entry in entries"
                :key="entry.diagnosisEntryId"
              >
                <td>{{ entry.creator.name }} {{ entry.creator.surname }}</td>
                <td>
                  <i18n
                    :msgid="`@diagnosis_general_type_adjective.${entry.diagnosisType}`"
                  />
                  <br>
                  / {{ entry.entryGroupName }}
                </td>
                <td>{{ formatDate(entry.createdAt) }}</td>
                <td
                  class="text-center"
                  style="width: 5.6rem"
                >
                  <router-link
                    :to="`/ipzs/${overview.ipzId}/details/${subPathName(entry.diagnosisType)}`"
                    class="btn btn-sm btn-outline-primary"
                  >
                    <i class="fa fa-eye" />&nbsp;Podgląd
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
          <pagination-description
            :items-count="entries.length"
            :page="entriesPagination.currentPage"
            :per-page="entriesPagination.perPage"
            :total-count="entriesPagination.totalRows"
          >
            <b-pagination
              v-model="entriesPage"
              :total-rows="entriesPagination.totalRows"
              :per-page="entriesPagination.perPage"
              align="right"
              class="mb-0"
              @input="loadEntries"
            />
          </pagination-description>
        </loading-mask>
      </div>
    </div>
  </div>
</template>

<script>
import read from "../../../rest/read";
import LoadingMask from "../../Loading/LoadingMask";
import IcfChangeComparisonChart from "./IcfChangeComparisonChart";
import I18n from "../../i18n";
import stringifyDate from "../../../utils/date/stringifyDate";
import PatientEvaluationsList from "../Evaluation/PatientEvaluationsList";
import PaginationDescription from "../../Pagination/PaginationDescription";
import DATE_FORMAT from "@/utils/date/DATE_FORMAT";

export default {
  name: "DiagnosesEntriesDashboard",
  components: {PaginationDescription, PatientEvaluationsList, I18n, LoadingMask, IcfChangeComparisonChart},
  props: {
    overview: {type: Object, required: true},
    readOnly: {type: Boolean, required: true}
  },
  data() {
    return {
      entriesPage: 1,
      entriesPagination: {perPage: 10, totalRows: 0},
      entries: [],
      loadingEntries: false,
    };
  },
  watch: {
    "overview.ipzId"() {
      this.loadEntries();
    },
  },
  async mounted() {
    await this.loadEntries();
  },
  methods: {
    subPathName(diagnosisType) {
      if ("medical" === diagnosisType) {
        return "medical-diagnosis";
      }
      if ("functional" === diagnosisType) {
        return "functional-diagnosis";
      }
      if ("family_interview" === diagnosisType) {
        return "family-interview";
      }
      if ("psychiatric_child" === diagnosisType) {
        return "psychiatric";
      }
      if ("pediatric_child" === diagnosisType) {
        return "pediatric";
      }
      if ("neurological_child" === diagnosisType) {
        return "neurological";
      }
      throw new Error(`Invalid diagnosis type '${diagnosisType}'.`);
    },
    formatDate(string) {
      const date = new Date(string);

      return stringifyDate(date, DATE_FORMAT.DATE_TIME_SEC);
    },
    async loadEntries() {
      this.loadingEntries = true;
      const {items, pagination} = await read(`/api/ipzs/${this.overview.ipzId}/entries`, {
        page: this.entriesPage,
        perPage: this.entriesPagination.perPage,
      });

      this.entries = items;
      this.entriesPagination = pagination;
      this.loadingEntries = false;
    },
  },
}
</script>
