<template>
  <div class="card-box appointment-box">
    <div class="appointment-data appointment-date">
      <date-time
        :value="item.date"
        :format="`HH:mm`"
        class="d-block font-weight-bold"
      />
      <div
        class="font-weight-bold"
        style="line-height: 0.5rem"
      >
        &mdash;
      </div>
      <date-time
        :value="parseDate(item.scheduledEndDate)"
        :format="`HH:mm`"
        class="d-block font-weight-bold"
      />
      <date-time
        :value="item.date"
        :format="`DD.MM YYYY`"
      />
    </div>
    <div class="appointment-details">
      <div class="appointment-data appointment-patient">
        <patient-options-badge
          :patients-length="item.patients.length"
          :patient-presence="item.patientPresence"
        />
        <appointment-patients
          :collapsed="false"
          :items="item.patients"
        />
      </div>
      <div class="appointment-worker-treatment">
        <div
          v-for="worker in sortedWorkers"
          :key="worker.workerId"
          class="appointment-data d-flex flex-nowrap"
        >
          <div class="appointment-worker d-flex flex-nowrap mr-2">
            <i
              :class="{'text-muted': worker.workerId !== item.mainWorkerId}"
              class="fa fa-user-tie mt-1 mr-2"
            />
            <div>{{ worker.name }}</div>
          </div>
          <div class="appointment-treatment">
            <treatment-type-name
              :value="worker.reportedTreatmentType"
              class="d-block"
            />
            <i
              v-if="!worker.isTreatmentTypeActive || !worker.isTreatmentTypeChoosable"
              class="fa fa-flag m-r-5"
            />
            <b-badge
              v-if="item.status !== 'canceled' && worker.unfinishedDocuments > 0"
              variant="danger"
            >
              <i class="fa fa-times-circle" />
              Niezamknięte dokumenty: {{ worker.unfinishedDocuments }} / {{ worker.allDocuments }}
            </b-badge>
          </div>
        </div>
      </div>
      <div class="appointment-data appointment-statuses">
        <appointment-status
          :name="item.status"
          badge
          class="mr-1"
        />
        <b-badge
          v-if="item.mobile"
          class="mr-1"
          variant="light"
        >
          <i class="fa fa-car" /> Wyjazd
        </b-badge>
        <b-badge
          v-else
          class="mr-1"
          variant="light"
        >
          <i class="fa fa-hospital" /> Lokalna
        </b-badge>
        <b-badge
          v-if="item.group"
          class="mr-1"
          variant="light"
        >
          <i class="fa fa-users" /> Grupowa
        </b-badge>
      </div>
      <div class="appointment-data appointment-options">
        <appointment-item-actions
          v-bind="item"
          :date="item.date"
          :patient-id="patientId"
          :cancel-allowed-from-group="!patientId"
          :change-patients-allowed-from-group="!patientId && !item.continuousStay"
          :remove-patient-allowed-from-group="!!patientId"
          :action-buttons-visible="true"
          @appointmentUpdated="updateAppointmentList"
        />
      </div>
    </div>
  </div>
</template>

<script>
import DateTime from "../../DateTime";
import AppointmentPatients from "../AppointmentPatients";
import TreatmentTypeName from "../../TreatmentType/TreatmentTypeName";
import AppointmentStatus from "../AppointmentStatus";
import AppointmentItemActions from "../AppointmentActions/AppointmentItemActions";
import parseDate from "../../../utils/date/parseDate";
import PatientOptionsBadge from "../../PatientPresenceOptions/PatientOptionsBadge";

export default {
  components: {
    PatientOptionsBadge,
    AppointmentItemActions,
    AppointmentStatus,
    TreatmentTypeName,
    AppointmentPatients,
    DateTime,
  },
  props: {
    item: {type: Object, required: true},
    patientId: {type: String, default: null},
  },
  data() {
    return {
      parseDate,
    };
  },
  computed: {
    sortedWorkers() {
      return [...this.item.workers].sort((workerA, workerB) => {
        if (workerA.workerId === this.item.mainWorkerId) {
          return -1;
        }
        if (workerB.workerId === this.item.mainWorkerId) {
          return 1;
        }
        return 0;
      });
    },
  },
  methods: {
    updateAppointmentList() {
      this.$emit("updateAppointmentList");
    },
  }
}
</script>

<style scoped lang="scss">
  @import "../../../styles/variables";

  .appointment {

    &-box {
      display: flex;
      flex-direction: row;
      border-top: 1px solid $light;
    }
    &-data {
      padding: 10px;
    }
    /*appointment box items*/
    &-date {
      flex: 0 0 70px;
      text-align: center;
      font-size: 1.1rem;
    }

    &-details {
      flex: 0 1 calc(100% - 70px);
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
    /*appointment details items*/
    &-patient {
      flex: 0 0 15%;
    }

    &-worker-treatment {
      flex: 0 1 70%;
    }

    &-statuses {
      flex: 0 1 15%;
      align-self: flex-start;
      text-align: right;
      display: flex;
      flex-direction: row-reverse;
      flex-wrap: wrap;
    }

    &-options {
      flex-basis: 100%;
      order: 1;
    }

    /*worker treatment*/
    &-worker {
      flex: 0 1 30%;
    }

    &-treatment {
      flex: 1 1 70%;
    }

    @include media-breakpoint-down(lg) {
      &-box {
        border: 1px solid $light;
        margin-bottom: 5px;
      }
      /*appointment box items*/
      &-date {
        text-align: center;
      }

      /*appointment details items*/
      &-patient {
        flex: 1 0 70%;
      }

      &-worker-treatment {
        flex: 0 1 100%;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: nowrap;
        border-bottom: 1px solid $light;
        order: 1;
      }

      &-statuses {
        flex: 0 1 30%;
      }
    }
  }
</style>
