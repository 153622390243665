<template>
  <card
    :loading="isLoading"
    title="Badania"
  >
    <template #control>
      <is-granted
        :privileges="['CREATE_PATIENT_RATING_SURVEY']"
      >
        <b-btn
          class="m-b-10 ml-2"
          variant="secondary"
          size="sm"
          :disabled="isLoading || disabledCreateRatingSurvey"
          @click="openCreateRatingSurveyModal('relations')"
        >
          <i class="fa fa-plus" /> Stwórz Badanie Jakości Relacji
        </b-btn>
      </is-granted>
      <is-granted
        :privileges="['ADD_SURVEY']"
      >
        <b-btn
          v-b-modal.startSurveyModal
          class="btn btn-primary btn-sm m-b-10 ml-2"
        >
          <i class="fa fa-plus" /> Przypisz nowe badanie
        </b-btn>
      </is-granted>
    </template>
    <create-rating-survey-modal
      :patient-id="patientId"
      :category="ratingSurveyCategory"
      @survey-created="createdSurvey"
    />
    <patient-card-survey-modal
      :patient-id="patientId"
      @surveyStarted="getPatientSurveys"
    />
    <survey-token-modal
      :survey="modalSurvey"
      @close="clearModalData"
      @openInvitation="openSurveyInvitationSendModal"
    />
    <send-survey-invitation-modal
      :survey="modalSurvey"
      :patient-id="patientId"
      @close="clearModalData"
    />
    <update-survey-attributes-modal
      :survey="modalSurvey"
      :patient-id="patientId"
      @close="clearModalData"
      @updated="getPatientSurveys"
    />
    <b-table
      :fields="fields"
      :items="patientSurveys"
      hover
      empty-text="Brak badań"
      :responsive="true"
      show-empty
    >
      <template #cell(status)="{item: {status}}">
        <b-badge
          class="status-badge"
          :variant="getBadgeVariant(status)"
        >
          <i18n
            component="fragment"
            :msgid="`@survey-status.${status}`"
          />
        </b-badge>
      </template>
      <template #cell(startedAt)="{item: {startedAt}}">
        <date-time
          :format="DATE_FORMAT.DATE"
          :value="parseDate(startedAt, DATE_FORMAT.DATE_TIME_SEC)"
        />
      </template>
      <template #cell(eligiblePerson)="{item: survey}">
        <eligible-person-details
          v-if="survey.requesterType === 'eligiblePerson' && survey.eligiblePerson"
          :fields="['type', 'description']"
          :value="survey.eligiblePerson"
        />
        <span v-else-if="survey.requesterType === 'worker'"><b>Powiązanie:</b> specjalista</span>
        <span v-else>Pacjent</span>
      </template>
      <template #cell(options)="{item: survey}">
        <b-btn
          v-if="surveyBuilderUrl && survey.status !== 'closed' && survey.status !== 'canceled'"
          class="m-1"
          size="sm"
          variant="primary"
          @click="openSurvey(survey)"
        >
          <span class="fas fa-folder-open" /> Otwórz badanie
        </b-btn>
        <b-btn
          v-if="survey.endDate"
          class="m-1"
          :href="`/api/files/${survey.surveyId}`"
          size="sm"
          target="_blank"
          variant="secondary"
        >
          <span class="fas fa-print" /> Drukuj badanie
        </b-btn>
        <b-dropdown
          boundary="$el"
          class="m-1"
          right
          size="sm"
          style="width: 8rem"
          text="Opcje"
          variant="outline-secondary"
        >
          <is-granted :privileges="['PRINT_SURVEY_RESULT']">
            <b-dd-item
              :href="`/api/surveys/${survey.surveyId}/print`"
              target="_blank"
            >
              <span class="fas fa-print" /> Podgląd wydruku
            </b-dd-item>
          </is-granted>
          <is-granted
            :privileges="['UPDATE_SURVEY_ATTRIBUTES']"
            :subject="survey.surveyId"
          >
            <b-dd-item
              @click="openSurveyUpdateAttributesModal(survey)"
            >
              <span class="fas fa-pencil-alt" /> Edycja atrybutów
            </b-dd-item>
          </is-granted>
          <b-dd-item-btn @click="showInfo(survey)">
            <span class="fas fa-eye" /> Szczegóły badania
          </b-dd-item-btn>
          <b-dd-item-btn
            v-if="survey.status !== 'closed' && survey.status !== 'canceled'"
            @click="openSurveyInvitationSendModal(survey)"
          >
            <i class="fa fa-envelope" /> Wyślij zaproszenie do badania na e-mail
          </b-dd-item-btn>
        </b-dropdown>
      </template>
    </b-table>
    <pagination-description
      :items-count="patientSurveys.length"
      :page="page"
      :per-page="pagination.perPage"
      :total-count="pagination.totalRows"
    >
      <b-pagination
        :value="page"
        :total-rows="pagination.totalRows"
        :per-page="pagination.perPage"
        align="right"
        class="mb-0"
        @input="changePage"
      />
    </pagination-description>
  </card>
</template>

<script>
import PatientCardSurveyModal from "./PatientCardSurveyModal";
import read from "../../../../rest/read";
import {mapState} from "vuex";
import Card from "../../../Card";
import PaginationDescription from "../../../Pagination/PaginationDescription";
import eventBus from "../../../../eventBus";
import EligiblePersonDetails from "../../../EligiblePersons/EligiblePersonDetails";
import SurveyTokenModal from "./SurveyTokenModal";
import SendSurveyInvitationModal from "./SendSurveyInvitationModal";
import IsGranted from "../../../IsGranted";
import CreateRatingSurveyModal from "./CreateRatingSurveyModal";
import DateTime from "../../../DateTime";
import DATE_FORMAT from "../../../../utils/date/DATE_FORMAT";
import parseDate from "../../../../utils/date/parseDate";
import UpdateSurveyAttributesModal from "./UpdateSurveyAttributesModal";
import I18n from "../../../i18n";

export default {
  components: {
    I18n,
    UpdateSurveyAttributesModal,
    DateTime,
    CreateRatingSurveyModal,
    SendSurveyInvitationModal,
    SurveyTokenModal,
    EligiblePersonDetails,
    PaginationDescription,
    Card,
    PatientCardSurveyModal,
    IsGranted,
  },
  props: {
    patientId: {type: String, required: true},
  },
  data() {
    return {
      patientSurveys: [],
      isLoading: false,
      page: 1,
      pagination: {perPage: 5, totalRows: 0},
      modalSurvey: null,
      ratingSurveyCategory: "relations",
      disabledCreateRatingSurvey: false,
      parseDate,
      DATE_FORMAT,
    };
  },
  computed: {
    ...mapState({
      surveyBuilderUrl: state => state.currentUser.user.settings.surveyBuilderUrl,
    }),
    fields() {
      return [
        {key: "name", label: "Nazwa Badania", class: "w-25"},
        {key: "status", label: "Status"},
        {key: "startedAt", label: "Data Rozpoczęcia"},
        // {key: "eligiblePerson", label: "Osoba badana", class: "w-25"},
        {key: "options", label: "", class: "text-right"},
      ];
    },
  },
  watch: {
    patientId() {
      this.page = 1;
      this.getPatientSurveys();
    },
  },
  async mounted() {
    await this.getPatientSurveys();
  },
  methods: {
    async getPatientSurveys() {
      this.isLoading = true;
      const response = await read(`/api/patients/${this.patientId}/surveys`, {
        perPage: this.pagination.perPage,
        page: this.page,
      });
      this.patientSurveys = response.items;
      this.pagination = response.pagination;
      this.isLoading = false;
    },
    changePage(page) {
      this.page = page;
      this.getPatientSurveys();
    },
    openSurvey(survey) {
      const href=`${this.surveyBuilderUrl}/?token=${survey.token}`;
      window.open(href, "_blank");
      eventBus.on(survey.surveyId, () => {
        this.getPatientSurveys();
      });
    },
    showInfo(survey) {
      this.modalSurvey = survey;
      this.$bvModal.show("survey-token-modal");
    },
    openSurveyInvitationSendModal(survey) {
      this.modalSurvey = survey;
      this.$bvModal.show("send-survey-invitation-modal");
    },
    openCreateRatingSurveyModal(category) {
      this.ratingSurveyCategory = category;
      this.$bvModal.show("create-rating-survey-modal");
    },
    openSurveyUpdateAttributesModal(survey) {
      this.modalSurvey = survey;
      this.$bvModal.show("update-survey-attributes");
    },
    clearModalData() {
      this.modalSurvey = null;
    },
    createdSurvey(surveyId) {
      this.disabledCreateRatingSurvey = true;
      eventBus.on(surveyId, () => {
        this.getPatientSurveys();
        this.disabledCreateRatingSurvey = false;
      });
    },
    getBadgeVariant(status) {
      switch(status) {
        case "created":
          return "warning";
        case "invited":
          return "primary";
        case "opened":
          return "success";
        case "closed":
          return "dark";
        default:
          throw Error(`Not supported status: ${status}`);
      }
    }
  },
}
</script>

<style scoped lang="scss">
  .status-badge{
    width: 9rem;
  }
</style>
