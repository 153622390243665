<template>
  <b-modal
    id="remove-appointment-patient-modal"
    no-close-on-backdrop
    title="Wypisz pacjenta z wizyty grupowej"
    title-tag="h3"
    @hide="clearErrors"
  >
    <template #default>
      <error-message :errors="errors" />
      <p v-if="patient != null">
        Czy na pewno wypisać pacjenta {{ patient.displayName }} z wizyty grupowej?
      </p>
      <appointment-details
        :appointment-id="appointmentId"
        :date="date"
        :patients="patients"
        :main-worker="mainWorker"
      />
    </template>
    <template #modal-footer>
      <button
        type="button"
        class="btn btn-secondary"
        @click="closeModal"
      >
        Zamknij
      </button>
      <button
        :disabled="loading"
        type="submit"
        class="btn btn-primary"
        @click="removePatientFromAppointment"
      >
        Wypisz pacjenta
      </button>
    </template>
  </b-modal>
</template>

<script>
import AppointmentDetails from "../AppointmentDetails";
import processResponseException from "../../../utils/errors/processResponseException";
import ErrorMessage from "../../Form/ErrorMessage";
import remove from "../../../rest/remove";

export default {
  components: {
    ErrorMessage,
    AppointmentDetails,
  },
  props: {
    id: {type: String, default: null},
    appointmentId: {type: String, required: true},
    date: {type: Date, required: true},
    group: {type: Boolean, required: true},
    patients: {type: Array, required: true},
    patientId: {type: String, default: null},
    mainWorker: {type: Object, required: true},
  },
  data() {
    return {
      errors: [],
      loading: false,
    };
  },
  computed: {
    patient() {
      return this.patientId
        ? this.patients.find(patient => patient.patientId === this.patientId)
        : this.patients[0];
    },
  },
  methods: {
    closeModal() {
      this.$bvModal.hide("remove-appointment-patient-modal");
    },
    async removePatientFromAppointment() {
      this.loading = true;
      this.clearErrors();
      const data = {patients: [this.patientId]};
      try {
        await remove(`/api/appointment/${this.appointmentId}/patients`, data);
        this.closeModal();
        this.$emit("removePatient");
      } catch (e) {
        this.errors = processResponseException(e.response.data);
      }
      this.loading = false;
    },
    clearErrors() {
      this.errors = [];
    }
  },
}
</script>
