












import Vue from "vue";
import ObjectSelect from "../../components/Form/Select/ObjectSelect.vue";
import {PersonOfCareType} from "../../types/Zla";
import {Component, Prop} from "vue-property-decorator";

interface Option {
  value: PersonOfCareType;
  name: string;
}

@Component({
  name: "PersonOfCareSelect",
  components: {ObjectSelect},
})
export default class PersonOfCareSelect extends Vue {
  @Prop({type: Boolean, default: false}) readonly clearable!: boolean;
  @Prop({type: Boolean, default: false}) readonly disabled!: boolean;
  @Prop({type: String, default: null}) readonly placeholder!: string;
  @Prop({type: Boolean, default: null}) readonly state!: boolean;
  @Prop({type: Number, default: null}) readonly value!: PersonOfCareType;

  private get selected(): Option|null {
    return this.value && this.options.find(option => option.value === this.value) || null;
  }

  get options(): Option[] {
    return [
      {
        value: PersonOfCareType.CHILD,
        name: "Dziecko"},
      {
        value: PersonOfCareType.PARENT,
        name: "Małżonek,  rodzice,  ojczym,  macocha,  teściowie,  dziadkowie, wnuki, rodzeństwo"},
      {
        value: PersonOfCareType.OTHER,
        name: "Inne osoby"
      },
    ]
  }

  private update(option: Option):void {
    this.$emit("input", option?.value);
  }
}
