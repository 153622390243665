


























































import {Component, Mixins, Prop} from "vue-property-decorator";
import type {ChildIpzItem} from "../../../types/ChildIpzs";
import ChildIpzForm from "./ChildIpzForm.vue";
import ChildIpzEditModal from "./ChildIpzEditModal.vue";
import Card from "../../Card.vue";
import IsGranted from "../../IsGranted.vue";
import ConfirmChildIpzStatusModal from "./ConfirmChildIpzStatusModal.vue";
import ChildIpzContent from "./ChildIpzContent.vue";
import ChildIpzDetails from "./ChildIpzDetails.vue";
import {generateUuid} from "../../../utils/uuid/generateUuid";
import create from "../../../rest/create";
import processResponseException from "../../../utils/errors/processResponseException";
import ErrorsMixin from "../../../mixins/ErrorsMixin.ts";
import ErrorMessage from "../../Form/ErrorMessage.vue";

@Component({
  name: "ChildIpzWidget",
  components: {
    ErrorMessage,
    ChildIpzDetails,
    ChildIpzContent,
    ConfirmChildIpzStatusModal,
    IsGranted,
    Card,
    ChildIpzForm,
    ChildIpzEditModal,
  },
})

export default class ChildIpzWidget extends Mixins(ErrorsMixin) {
  @Prop({type: String, required: true}) readonly ipzId!: string;
  @Prop({type: Object, default: null}) readonly item!: ChildIpzItem;
  @Prop({type: Boolean, default: false}) readonly readonly!: boolean;

  confirmType: string = "open";
  loading: boolean = false;

  async createIpz(): Promise<void> {
    this.resetErrors();
    this.loading = true;
    try {
      const childIpzId = generateUuid();
      await create("/api/child-ipzs", {
        ipzId: this.ipzId,
        childIpzId
      });
      this.update();
      setTimeout(() => this.openEditModal());
    } catch (e) {
      this.errors = processResponseException(e);
    }
    this.loading = false;
  }

  openEditModal(): void {
    this.$bvModal.show("child-ipz-modal");
  }

  setConfirmType(type: string): void {
    this.confirmType = type;
  }

  openOpenModal() {
    this.setConfirmType("opened");
    this.$bvModal.show("confirm-child-ipz-modal");
  }
  openCloseModal() {
    this.setConfirmType("closed");
    this.$bvModal.show("confirm-child-ipz-modal");
  }

  update() {
    this.$emit("update");
  }

  resetErrors(): void {
    this.errors = [];
  }
}
