<template>
  <div class="table-responsive">
    <h2 class="text-center p-2">
      {{ dateString }}
    </h2>
    <table class="table table-bordered text-center m-0">
      <thead>
        <tr>
          <th
            v-for="(day, index) in days"
            :key="index"
          >
            {{ day }}
          </th>
        </tr>
      </thead>
      <tr
        v-for="(week, weekIndex) in weeks"
        :key="weekIndex"
      >
        <td
          v-for="(day, dayIndex) in week"
          :key="dayIndex"
          class="p-0"
        >
          <tooltip
            v-if="isItDayWithSelectedAppointment(day)"
            tooltip-content="Aktualna data wizyty"
          >
            <router-link
              :to="pathToDayCalendar(day)"
              class="btn btn-block btn-primary"
            >
              {{ day.getDate() }}
            </router-link>
          </tooltip>
          <tooltip
            v-else-if="dayAvailable(day)"
            tooltip-content="Dostępne wolne terminy"
          >
            <router-link
              :to="pathToDayCalendar(day)"
              class="btn btn-block btn-outline-success"
            >
              {{ day.getDate() }}
            </router-link>
          </tooltip>
          <div
            v-else
            class="btn btn-default btn-block disabled"
          >
            {{ displayDateOfSelectedMonth(day) }}
          </div>
        </td>
      </tr>
    </table>
  </div>
</template>
<script>
import datePicker from "../../i18n/datePicker";
import DATE_FORMAT from "../../utils/date/DATE_FORMAT";
import stringifyDate from "../../utils/date/stringifyDate";
import Tooltip from "../Tooltip";

export default {
  components: {Tooltip},
  props: {
    filters: {type: Object, required: true},
    items: {type: Array, required: true},
  },
  computed: {
    dateString() {
      const monthName = (datePicker.months[this.filters.startDate.getMonth()]);
      return `${monthName} ${this.filters.startDate.getFullYear()}`;
    },
    days() {
      return datePicker.days.slice(1).concat([datePicker.days[0]]);
    },
    month() {
      return this.filters.startDate.getMonth();
    },
    year() {
      return this.filters.startDate.getFullYear();
    },
    firstDay() {
      const date = new Date();
      date.setDate(1);
      date.setMonth(this.month);
      date.setFullYear(this.year);
      const day = date.getDay() || 7;
      date.setDate(date.getDate() + 1 - day);
      date.setHours(0, 0, 0, 0);
      return date;
    },
    mondays() {
      const mondays = [];
      for (let monday = this.firstDay; this.mondaysCondition(monday); monday = this.nextMonday(monday)) {
        mondays.push(monday);
      }
      return mondays;
    },
    weeks() {
      return this.mondays.map((monday) => [...Array(7).keys()].map((day) => {
        const date = new Date(monday.getTime());
        date.setDate(date.getDate() + day);
        return date;
      }));
    },
    availableDates() {
      const availableItems = this.items.filter(item => {
        return item.clickable;
      });
      return [...availableItems].reduce((list, item) => {
        const newDate = new Date(item.start);
        newDate.setHours(0, 0, 0, 0);
        return (list.find(date => date.getTime() === newDate.getTime())) ? list : list.concat([newDate]);
      }, []);
    },
    selectedAppointmentDay() {
      if (null == this.filters.appointmentId) {
        return null;
      }
      const item = this.items.find((item) => item.appointmentId === this.filters.appointmentId);
      if (null == item) {
        return null;
      }

      const date = new Date(item.start);
      date.setHours(0, 0, 0);

      return date;
    },
  },
  methods: {
    nextMonday(monday) {
      const date = new Date(monday.getTime());
      date.setDate(date.getDate() + 7);
      return date;
    },
    mondaysCondition(monday) {
      if (this.month === 11) {
        return monday.getMonth() > 0;
      } else if (this.month === 0) {
        return monday.getMonth() === 0 || monday.getMonth() === 11;
      } else {
        return monday.getMonth() <= this.month;
      }
    },
    dayAvailable(day) {
      return this.availableDates.find(date => date.getTime() === day.getTime());
    },
    pathToDayCalendar(day) {
      const startDateStr = stringifyDate(day, DATE_FORMAT.DATE_TIME);
      const endDate = new Date(day.getTime());
      endDate.setDate(endDate.getDate() + 1);
      endDate.setHours(0, 0, 0, 0);
      const endDateStr = stringifyDate(endDate, DATE_FORMAT.DATE_TIME);

      const treatmentType = typeof this.filters.treatmentType === "object" && this.filters.treatmentType
        ? this.filters.treatmentType.value
        : this.filters.treatmentType;

      const query = {
        startDate: startDateStr,
        endDate: endDateStr,
        treatmentType,
        view: "day",
        workers: this.filters.workersList
          ? this.filters.workersList.join(",") || undefined
          : undefined,
        appointmentId: this.filters.appointmentId || undefined,
        copyId: this.filters.copyId || undefined,
        continuousStay: this.filters.continuousStay != null ? this.filters.continuousStay : undefined,
      };

      return {name: "calendarView", query};
    },
    isItDayWithSelectedAppointment(day) {
      return null != this.selectedAppointmentDay && day.getTime() === this.selectedAppointmentDay.getTime();
    },
    displayDateOfSelectedMonth(day) {
      if (day.getMonth() === this.month) {
        return day.getDate();
      }
    },
  },
};
</script>
