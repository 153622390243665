import read from "../../../rest/read";
import {storageKey} from "../stickyPatient";
import {ActionTree} from "vuex";
import {Patient, StickyPatientState} from "./types";
import {RootState} from "../../types";

export const actions: ActionTree<StickyPatientState, RootState> = {
  async init(context) {
    const patientId = sessionStorage.getItem(storageKey);
    if (!patientId) {
      return;
    }
    try {
      await context.dispatch("load");
    } catch (e) {
      context.commit("clear");
      console.error(e);
    }
  },
  async load(context, patientId) {
    const patient: Patient|null = patientId ? await read(`/api/patients/${patientId}`) : null;
    if (patient) {
      patient.displayName = (!context.rootState.clinicParameters?.parameters?.patientAnonymous && patient.fileNumber)
        ? `${patient.displayName} | ${patient.fileNumber}`
        : patient.displayName;
    }
    context.commit("set", patient)
  },
  set(context, patient) {
    context.commit("set", patient);
  },
  clear(context) {
    context.commit("clear");
  },
};
