import read from "../../../rest/read";
import {ActionTree} from "vuex";
import {ClinicParametersState} from "./types";
import {CLINIC_PARAMETERS, storage} from "../clinicParameters";
import {RootState} from "../../types";

export const actions: ActionTree<ClinicParametersState, RootState> = {
  async loadClinicParameters({commit}) {
    try {
      const clinicParameters = await read("/api/clinic-parameters");
      const clinicParametersStr = JSON.stringify(clinicParameters);
      storage.setItem(CLINIC_PARAMETERS, clinicParametersStr);
      commit("setParameters", clinicParameters);
    } catch (e) {
      commit("clear");
      console.error("Cannot get clinic parameters", e);
    }
  }
};
