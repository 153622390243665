<template>
  <div class="card-box">
    <b-form-group label="Instytucja">
      <InstitutionSelect
        :selected-institutions="course.selectedInstitutions"
        :state="state('institutionList')"
        @input="updateInstitutionList"
      />
      <error-message
        :errors="errors"
        field="institutionList"
      />
    </b-form-group>
    <div class="row">
      <b-form-group
        class="col-md-6"
        label="Kategoria kursu"
      >
        <CourseTypeSelect
          :course-type="course.courseType"
          :state="state('courseType')"
          @input="changeCourseType"
        />
        <error-message
          :errors="errors"
          field="courseType"
        />
      </b-form-group>
      <b-form-group
        v-if="course.courseType === 'for_specialists'"
        class="col-md-6"
        label="Podkategoria kursu"
      >
        <CourseSubtypesSelect
          :course-subtypes="course.courseSubtypes"
          :state="state('courseSubtypes')"
          @input="changeCourseSubtypes"
        />
        <error-message
          :errors="errors"
          field="courseSubtypes"
        />
      </b-form-group>
    </div>
    <div class="row">
      <b-form-group
        class="col-md-6"
        label="Nazwa grupy"
      >
        <b-form-input
          id="groupName"
          v-model.trim="course.groupName"
          :state="state('groupName')"
        />
        <error-message
          :errors="errors"
          field="groupName"
        />
      </b-form-group>
      <b-form-group
        class="col-md-6"
        label="Liczba uczestników"
      >
        <b-form-input
          id="numberOfParticipants"
          v-model.trim="course.numberOfParticipants"
          :state="state('numberOfParticipants')"
          type="number"
        />
        <error-message
          :errors="errors"
          field="numberOfParticipants"
        />
      </b-form-group>
    </div>
    <div class="row">
      <b-form-group
        class="col-md-6"
        label="Data kursu"
      >
        <date-picker
          v-model="course.courseDate"
          :state="state('courseDate')"
          class="border-0"
        />
        <error-message
          :errors="errors"
          field="courseDate"
        />
      </b-form-group>
      <b-form-group
        class="col-md-6"
        label="Liczba godzin"
      >
        <b-form-input
          id="courseLength"
          v-model.trim="course.courseLength"
          :state="state('courseLength')"
          :min="1"
          type="number"
        />
        <error-message
          :errors="errors"
          field="courseLength"
        />
      </b-form-group>
    </div>
    <b-form-group label="Notatka ogólna">
      <textarea-counter
        id="note"
        v-model="course.note"
        :max-characters-count="1500"
        :state="state('note')"
        rows="2"
      />
      <error-message
        :errors="errors"
        field="note"
      />
    </b-form-group>

    <b-form-group label="Notatka obecności uczestników">
      <textarea-counter
        id="participantsAttendanceNote"
        v-model="course.participantsAttendanceNote"
        :max-characters-count="1500"
        :state="state('participantsAttendanceNote')"
        rows="2"
      />
      <error-message
        :errors="errors"
        field="participantsAttendanceNote"
      />
    </b-form-group>
    <b-form-group label="Notatka obecności trenerów">
      <textarea-counter
        id="coachesAttendanceNote"
        v-model="course.coachesAttendanceNote"
        :max-characters-count="1500"
        :state="state('coachesAttendanceNote')"
        rows="2"
      />
      <error-message
        :errors="errors"
        field="coachesAttendanceNote"
      />
    </b-form-group>
    <error-message :errors="errors" />
    <div class="text-right">
      <button
        class="btn btn-secondary waves-effect"
        @click="redirect"
      >
        Anuluj
      </button>
      <button
        :disabled="loading"
        class="btn btn-primary waves-effect ml-2"
        @click="updateCourse"
      >
        <i
          :class="loading ? 'fa-spin fa-spinner' : 'fa-check'"
          class="fa"
        />
        Zapisz
      </button>
    </div>
  </div>
</template>

<script>
import DatePicker from "../../components/Form/DatePicker/DatePicker";
import stringifyDate from "../../utils/date/stringifyDate";
import InstitutionSelect from "../../components/Prevention/InstitutionSelect";
import update from "../../rest/update";
import CourseTypeSelect from "../../components/Prevention/CourseTypeSelect";
import {errorsMixin} from "../../mixins/errorsMixin.js";
import TextareaCounter from "../../components/Form/Textarea/TextareaCounter";
import processResponseException from "../../utils/errors/processResponseException";
import ErrorMessage from "../../components/Form/ErrorMessage";
import CourseSubtypesSelect from "../../components/Prevention/CourseSubtypesSelect";
import read from "../../rest/read";

export default {
  components: {
    CourseSubtypesSelect,
    ErrorMessage,
    TextareaCounter,
    CourseTypeSelect,
    InstitutionSelect,
    DatePicker,
  },
  mixins: [errorsMixin],
  props: {
    courseId: {type: String, required: true},
  },
  data() {
    return {
      course: {
        courseId: this.courseId,
        selectedInstitutions: [],
        groupName: null,
        numberOfParticipants: null,
        courseDate: null,
        courseLength: null,
        courseType: null,
        courseSubtypes: [],
        note: null,
        participantsAttendanceNote: null,
        coachesAttendanceNote: null,
      },
      loading: false,
    };
  },
  mounted() {
    this.loadCourseData();
  },
  methods: {
    updateInstitutionList(value) {
      this.course.selectedInstitutions = value;
    },
    changeCourseType(value) {
      this.course.courseType = value;
      this.course.courseSubtypes = [];
    },
    changeCourseSubtypes(value) {
      this.course.courseSubtypes = value;
    },
    async loadCourseData() {
      this.loading = true;
      this.errors = [];
      const data = await read(`/api/course_update_view/${this.course.courseId}`);
      this.course.selectedInstitutions = data.institutionList;
      this.course.groupName = data.groupName;
      this.course.numberOfParticipants = data.numberOfParticipants;
      this.course.courseDate = data.courseDate;
      this.course.courseLength = data.courseLength;
      this.course.courseType = data.courseType;
      this.course.courseSubtypes = data.courseSubtypes;
      this.course.note = data.note;
      this.course.participantsAttendanceNote = data.participantsAttendanceNote;
      this.course.coachesAttendanceNote = data.coachesAttendanceNote;
      this.loading = false;
    },
    async updateCourse() {
      try {
        this.loading = true;
        this.errors = [];
        await update(`/api/courses/${this.course.courseId}`, {
          courseId: this.course.courseId,
          institutionList: this.course.selectedInstitutions,
          groupName: this.course.groupName,
          numberOfParticipants: this.course.numberOfParticipants,
          courseDate: stringifyDate(new Date(this.course.courseDate)),
          courseLength: this.course.courseLength,
          courseType: this.course.courseType,
          courseSubtypes: this.course.courseSubtypes,
          note: null !== this.course.note ? this.course.note.trim() : null,
          coachesAttendanceNote: null !== this.course.coachesAttendanceNote
            ? this.course.coachesAttendanceNote.trim()
            : null,
          participantsAttendanceNote: null !== this.course.participantsAttendanceNote
            ? this.course.participantsAttendanceNote.trim()
            : null,
        });
        this.redirect();
      } catch (exception) {
        this.errors = processResponseException(exception);
      }
      this.loading = false;
    },
    redirect() {
      this.$router.push({name: "course_list"});
    },
  },
}
</script>


