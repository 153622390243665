










































import {Component, Prop} from "vue-property-decorator";
import type {ChildIpzItem} from "../../../types/ChildIpzs";
import Vue from "vue";
import ChildIpzContent from "./ChildIpzContent.vue";
import ChildIpzDetails from "./ChildIpzDetails.vue";

@Component({
  name: "ChildIpzDetailsModal",
  components: {ChildIpzDetails, ChildIpzContent}
})

export default class ChildIpzDetailsModal extends Vue {
  @Prop({type: String, required: true}) readonly ipzId!: string;
  @Prop({type: Object, default: null}) readonly item!: ChildIpzItem|null;

  get title(): string {
    return "IPZ dziecięcy - Szczegóły";
  }

  closeModal(): void {
    this.$bvModal.hide("child-ipz-details-modal");
  }

  onHideModal(): void {
    this.$emit("hidden");
  }
}
