












import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";
import {PayerIdStringType} from "../../types/Zla";
import ObjectSelect from "../Form/Select/ObjectSelect.vue";

interface Option {
  value: PayerIdStringType;
  name: string;
}

@Component({
  name: "PayerIdTypeSelect",
  components: {ObjectSelect},
})
export default class PayerIdTypeSelect extends Vue {
  @Prop({type: Boolean, default: false}) readonly clearable!: boolean;
  @Prop({type: Boolean, default: false}) readonly disabled!: boolean;
  @Prop({type: String, default: null}) readonly placeholder!: string;
  @Prop({type: Boolean, default: null}) readonly state!: boolean;
  @Prop({type: String, default: null}) readonly value!: PayerIdStringType;

  private get selected(): Option|null {
    return this.value && this.options.find(option => option.value === this.value) || null;
  }

  get options(): Option[] {
    return [
      {
        value: PayerIdStringType.NIP,
        name: "NIP"
      },
      {
        value: PayerIdStringType.PESEL,
        name: "PESEL"
      },
    ]
  }

  private update(option: Option):void {
    this.$emit("input", option?.value);
  }
}
