











import DateTime from "../DateTime.vue";
import DATE_FORMAT from "../../utils/date/DATE_FORMAT";
import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";
import days from "../../utils/date/days";

@Component({
  name: "WeeklyScheduleDay",
  components: {DateTime},
})

export default class WeeklyScheduleDay extends Vue {
  @Prop({type: Date, required: true}) readonly date!: Date;

  DATE_FORMAT = DATE_FORMAT;

  get days(): Array<string> {
    return days;
  }

  get isToday(): boolean {
    const today: Date = new Date();
    today.setHours(0, 0, 0, 0);
    return today.getTime() === this.date.getTime();
  }
}
