































import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";
import {InternalReferralDirection} from "../../types/InternalReferral";
import t from "../../i18n";
import ObjectSelect from "../Form/Select/ObjectSelect.vue";
import type {Option} from "../../types/MultiSelectTypes";

@Component({
  name: "InternalReferralDirectionSelect",
  components: {ObjectSelect}
})
export default class InternalReferralDirectionSelect extends Vue {
  @Prop({type: String, default: null}) readonly value!: string;
  @Prop({type: Boolean, default: true}) readonly clearable!: boolean;
  @Prop({type: Boolean, default: false}) readonly disabled!: boolean;
  @Prop({type: String, default: "Wybierz kierunek"}) readonly placeholder!: string;
  @Prop({type: Boolean, default: null}) readonly state!: boolean;

  get options(): Option[] {
    return Object.values(InternalReferralDirection).map(value => ({
      value,
      name: t(`@internal-referral-direction.${value}`),
    }));
  }

  get selectedValue(): Option|null {
    return this.options.find(option => option.value === this.value) || null;
  }

  private update(option: Option): void {
    this.$emit("input", option?.value || null);
  }

  directionClass(direction: InternalReferralDirection){
    switch (direction) {
      case InternalReferralDirection.SENT:
        return "fa-arrow-alt-circle-right";
      case InternalReferralDirection.RECEIVED:
        return "fa-arrow-alt-circle-left";
      default:
        return "";
    }
  }
}
