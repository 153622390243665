<template>
  <div class="row m-b-5">
    <div class="col-auto">
      <span><i18n :msgid="qualifier.type" /></span>
    </div>
    <div class="col text-right">
      <b-form-checkbox-group
        :checked="checked"
        buttons
        button-variant="outline-primary"
        @change="emitChange"
      >
        <b-form-checkbox
          v-for="option in options"
          :key="option.value"
          v-b-tooltip.hover
          :value="option.val"
          :title="option.name"
        >
          {{ option.value }}
        </b-form-checkbox>
      </b-form-checkbox-group>
      <div
        v-if="previousEvaluationCategoryData && previousEvaluationCategoryData.qualifiersAnswers[qualifier.type][0]"
        class="m-t-5"
      >
        {{ previousValueLabel() }}
        <span class="text-success">
          {{ previousEvaluationCategoryData.evaluationDate }}
          ({{ previousEvaluationCategoryData.workerName }})
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import I18n from "../../i18n";

export default {
  name: "EvaluationQualifierDoubleValue",
  components: {
    I18n,
  },
  props: {
    qualifier: {type: Object, required: true},
    value: {type: Array, default: ()=>[]},
    previousEvaluationCategoryData: {type: Object, default: null},
  },
  computed: {
    options() {
      return this.qualifier.items.reduce((items, item) => {
        if (/[89]/.test(item.value)) {
          return [...items, {
            ...item,
            val: item.value,
          }]
        }
        if (!/0/.test(item.value)) {
          return [...items, {
            ...item,
            val: item.value.startsWith("+") ? item.value : `-${item.value}`,
          }];
        }
        // item.value === 0
        return !(items.find(i => i.value === "0"))
          ? [
            ...items,
            {
              ...item,
              val: "0",
              value: "0",
            }
          ]
          : items.map(i => {
            return (i.value === "0")
              ? {
                ...i,
                name: `${i.name}, ${item.name}`
              }
              : i;
          });
      }, []);
    },
    checked() {
      return this.value.map(val => (/0/).test(val) ?  "0" : val);
    }
  },
  methods: {
    emitChange(answers) {
      let qualifierAnswer;
      switch (answers.length) {
        case 0:
        case 1:
          qualifierAnswer = answers;
          break;
        case 2:
          qualifierAnswer = this.bothValuesPermitted(answers)
            ? answers
            : [answers[1]];
          break;
        default:
          const values = [
            answers[1],
            answers[2],
          ];
          qualifierAnswer = this.bothValuesPermitted(values)
            ? values
            : [answers[2]];
          break;
      }

      this.$emit("input", this.handleZeroValue(qualifierAnswer));
    },
    identicalSigns([first, second]) {
      return first.match(/^./)[0] === second.match(/^./)[0];
    },
    bothValuesPermitted(answers) {
      const includes8or9 = answers.find(answer => /[89]/.test(answer));
      const includes0 = answers.find(answer => /0/.test(answer));
      return (!includes8or9 && includes0)
        || (!includes8or9 && !this.identicalSigns(answers));
    },
    handleZeroValue(answers) {
      if (answers.length === 0 || !answers.includes("0")) {
        return answers;
      }

      if (answers.length === 1) {
        return answers.map(value => value === "0" ? "-0" : value);
      }

      const sign = answers.find(value => value !== "0").match(/^./)[0];
      return answers.map(value => {
        if (value === "0") {
          return sign === "-" ? "+0" : "-0";
        }
        return value;
      })
    },
    previousValueLabel() {
      return `Ocena: ${ this.previousEvaluationCategoryData.qualifiersAnswers[this.qualifier.type][0] }
        ${ this.previousEvaluationCategoryData.qualifiersAnswers[this.qualifier.type].length > 1 ?
    `\\ ${  this.previousEvaluationCategoryData.qualifiersAnswers[this.qualifier.type][1]}` :
    ""
} -`;
    },
  },
}
</script>
