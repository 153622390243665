<template>
  <is-granted 
    :privileges="['CREATE_COURSE','CREATE_INSTITUTION']" 
    component="div"
  >
    <card
      title="Przyciski skrótów profilaktyki"
      description="Aby dodać kurs lub instytucje kliknij w odpowiedni przycisk poniżej."
    >
      <div class="row">
        <is-granted 
          :privileges="['CREATE_COURSE']"
          component="div"
          class="col-md-6"
        >
          <router-link
            :to="{name: 'get_course_create_view'}"
            class="btn btn-success waves-effect w-100"
          >
            <i class="fa fa-plus" /> Dodaj kurs
          </router-link>
        </is-granted>
        <is-granted 
          :privileges="['CREATE_INSTITUTION']" 
          component="div" 
          class="col-md-6"
        >
          <router-link
            :to="{name: 'get_institution_create_view'}"
            class="btn btn-success waves-effect w-100"
          >
            <i class="fa fa-plus" /> Dodaj instytucję
          </router-link>
        </is-granted>
      </div>
    </card>
  </is-granted>
</template>
<script>
import Card from "../Card";
import IsGranted from "../IsGranted";

export default {
  components:{IsGranted, Card}
}
</script>
