<template>
  <div>
    <card
      :loading="loading"
      title="Zespół Mobilny"
    >
      <template #control>
        <is-granted
          :privileges="['UPDATE_PATIENT_MOBILE_TEAM']"
        >
          <b-btn
            :disabled="loading"
            variant="primary"
            class="btn-sm"
            @click="openModal()"
          >
            <i class="fa fa-pencil-alt" />
          </b-btn>
        </is-granted>
      </template>
      <p
        v-for="member in currentMobileTeamMembers"
        :key="member.id"
      >
        {{ member.workerFullName }}
      </p>
      <p v-if="currentMobileTeamMembers.length < 1">
        Brak
      </p>
    </card>
    <b-modal
      id="mobileTeamModal"
      :visible="modalVisible"
      hide-footer
      no-close-on-backdrop
      title="Edycja zespołu mobilnego"
      title-tag="h3"
      size="lg"
      @hide="closeModal"
    >
      <form @submit.prevent="submit">
        <worker-select
          v-model="newMobileTeamMember"
          clearable
          @input="updateMobile"
        />
        <div class="card m-t-20">
          <b-table
            :fields="fields"
            :items="modifyMobileTeamMembers"
            :show-empty="true"
            hover
            empty-text="Brak pracowników przypisanych do zespołu mobilnego"
            responsive
          >
            <template #cell(deleteRow)="row">
              <b-button
                class="btn-sm"
                @click="remove(row.item)"
              >
                <i class="fa fa-trash" />
              </b-button>
            </template>
          </b-table>
        </div>
        <div
          v-show="historyMobileTeamMembers.length"
          v-b-toggle="'mobileTeamHistory'"
          class="text-center m-2 cursor-pointer"
        >
          ---
          <span class="when-closed">Pokaż historię</span>
          <span class="when-opened">Ukryj historię</span>
          ---
        </div>
        <b-collapse id="mobileTeamHistory">
          <table class="table table-sm">
            <thead>
              <tr>
                <th>Data dodania</th>
                <th>Data usunięcia</th>
                <th>Pracownik zespołu</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(historyMember, index) in historyMobileTeamMembers"
                :key="index"
                class="p-3"
              >
                <td>{{ historyMember.addedDate }} {{ historyMember.addedByFullName }}</td>
                <td>{{ historyMember.removedDate }} {{ historyMember.removedByFullName }}</td>
                <td>{{ historyMember.workerFullName }}</td>
              </tr>
            </tbody>
          </table>
        </b-collapse>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            @click="closeModal"
          >
            Anuluj
          </button>
          <button
            :disabled="loading"
            class="btn btn-primary"
            type="submit"
          >
            <i
              :class="loading ? 'fa-spin fa-spinner' : 'fa-check'"
              class="fa"
            />
            Zapisz
          </button>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import update from "../../../rest/update";
import read from "../../../rest/read";
import WorkerSelect from "../../Worker/WorkerSelect";
import processResponseException from "../../../utils/errors/processResponseException";
import Card from "../../Card";
import IsGranted from "../../IsGranted";

export default {
  components: {
    IsGranted,
    Card,
    WorkerSelect,
  },
  props: {
    patientId: {type: String, required: true}
  },

  data() {
    return {
      currentMobileTeamMembers: [],
      modifyMobileTeamMembers: [],
      historyMobileTeamMembers: [],
      errors: [],
      loading: false,
      modalVisible: false,
      newMobileTeamMember: null
    };
  },
  computed: {
    fields() {
      return [
        {key: "workerFullName", label: "Specjalista"},
        {key: "deleteRow", label: "Usuń"}
      ];
    }
  },
  watch: {
    async patientId() {
      await this.fetchData();
    },
  },
  async mounted() {
    await this.fetchData();
  },
  methods: {
    async fetchData() {
      this.loading = true;
      await this.getMobileTeam();
      this.loading = false;
    },
    async submit () {
      this.loading = true;
      try {
        await update(`/api/patients/${this.patientId}/mobile_team`, {
          workers: {...this.modifyMobileTeamMembers}
        });
        this.getMobileTeam();
        this.closeModal();
      } catch (exception) {
        this.errors = processResponseException(exception);
      }
      this.loading = false;
    },
    async getMobileTeam(){
      const response = await read(`/api/patients/${this.patientId}/mobile_team`);
      this.currentMobileTeamMembers = [...response.currentlyMembers];
      this.modifyMobileTeamMembers = [...response.currentlyMembers];
      this.historyMobileTeamMembers = [...response.historyMembers];
    },
    closeModal() {
      this.modalVisible = false;
    },
    openModal() {
      this.modalVisible = true;
      this.newMobileTeamMember = null;
      this.modifyMobileTeamMembers = [...this.currentMobileTeamMembers];
    },
    updateMobile(worker){
      const reAddedWorker = this.currentMobileTeamMembers.find(member => {
        return member.workerId === worker.workerId
      });
      const isInList = this.modifyMobileTeamMembers.find(member => {
        return member.workerId === worker.workerId
      });
      if(!isInList && !reAddedWorker){
        this.modifyMobileTeamMembers.push({
          workerId: worker.workerId,
          workerFullName: worker.name.concat(" ", worker.surname),
          addedDate: null,
        });
      } else if (!isInList && reAddedWorker) {
        this.modifyMobileTeamMembers.push({...reAddedWorker});
      }
      this.newMobileTeamMember = null;
    },
    remove(worker){
      this.modifyMobileTeamMembers = this.modifyMobileTeamMembers.filter(item => item.workerId !== worker.workerId);
    },
  },
};
</script>


<style scoped>
  .card-box {
    overflow: hidden;
  }
  .collapsed > .when-opened,
  :not(.collapsed) > .when-closed {
    display: none;
  }
</style>
