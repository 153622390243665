








import Vue from "vue";
import Component from "vue-class-component";
import {Prop} from "vue-property-decorator";
import t from "../../i18n";

@Component
export default class EducationLevel extends Vue {
  @Prop({type: String, default: "span"}) readonly component?: string;
  @Prop({type: String}) readonly level?: string;

  get label(): null|string {
    return null == this.level ? null : t(`@education-level.${this.level}`);
  }
}
