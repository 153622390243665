<template>
  <b-input
    :value="value"
    :clearable="clearable"
    placeholder="Nazwa pliku"
    @change="update"
  />
</template>

<script>
export default {
  name: "AttachmentNameInput",
  props: {
    value: {type: String, required: true},
    clearable: {type: Boolean, default: true},
  },
  methods: {
    update(name) {
      this.$emit("input", name.trim());
    }
  },
}
</script>
