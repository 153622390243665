export enum HospitalizationsDataFields {
  WAS_HOSPITALIZED = "wasHospitalized",
  WHEN_FIRST_HOSPITALIZED = "whenFirstHospitalized",
  WHEN_LAST_HOSPITALIZED = "whenLastHospitalized",
  HOW_MANY_HOSPITALIZATIONS_LAST_PERIOD = "howManyHospitalizationsLastPeriod",
  HOW_LONG_HOSPITALIZED_LAST_PERIOD = "howLongHospitalizedLastPeriod"
}

export interface HospitalizationValues {
  doesRemember?: null|boolean;
  value?: null|string|number;
}

export interface HospitalizationsData {
  [HospitalizationsDataFields.WAS_HOSPITALIZED]: null|WasHospitalized;
  [HospitalizationsDataFields.WHEN_FIRST_HOSPITALIZED]: HospitalizationValues;
  [HospitalizationsDataFields.WHEN_LAST_HOSPITALIZED]: HospitalizationValues;
  [HospitalizationsDataFields.HOW_MANY_HOSPITALIZATIONS_LAST_PERIOD]: HospitalizationValues;
  [HospitalizationsDataFields.HOW_LONG_HOSPITALIZED_LAST_PERIOD]: HospitalizationValues;
}
export enum WasHospitalized {
  YES = "yes",
  NO = "no",
  DOES_NOT_REMEMBER = "does_not_remember",
}

export interface HospitalizationProjection {
  hospitalizationId: string;
  hospital: HospitalEntry;
  comment: string;
  start: string;
  end: string;
  datesUnknown: boolean;
  duration: number|null;
  createdAt: string;
  creatorName: string;
  files: Array<HospitalizationDocument>;
  type: HospitalizationType;
}

export interface HospitalizationMember {
  hospitalizationId: string;
  hospital: HospitalEntry;
  comment: string;
  start: string;
  end: string;
  datesUnknown: boolean;
  duration: number|null;
  files: Array<UploadedFile>;
  type: HospitalizationType;
}

export interface Hospitalization {
  hospitalizationId: string;
  hospital: HospitalEntry|null;
  comment: string;
  start: null|string;
  end: null|string;
  datesUnknown: boolean;
  duration: number|null;
  documents: Array<string>;
  type: HospitalizationType;
}

export interface HospitalEntry {
  name: string;
  address: string;
}

export interface UploadedFile {
  fileId: string;
  size: number;
  name: string;
  type: string;
}

export interface HospitalizationDocument {
  name: string;
  fileId: string;
  uploadDate: Date;
}

export enum HospitalizationType {
  UNKNOWN = "unknown",
  PSYCHIATRIC = "psychiatric",
  FULL_DAY = "full_day",
  DAILY = "daily",
}

