
























































import {Component, Prop} from "vue-property-decorator";
import Vue from "vue";
import DateTime from "../../../DateTime.vue";
import AttachmentNameForm from "../Attachment/Name/AttachmentNameForm.vue";
import type {HospitalizationDocument} from "../../../../types/HospitalizationTypes";

@Component({components: {
  AttachmentNameForm,
  DateTime,
}})
export default class HospitalizationDocuments extends Vue {
  @Prop({type: Array, required: true}) readonly documents!: Array<HospitalizationDocument>;

  fields: Object = {
    name: "Nazwa pliku",
    uploadDate: "Data dodania",
    options: "Opcje",
  };

  updateName(fileId: string, newName: string): void {
    this.$emit("filenameChange", fileId, newName);
  }
  async removeHospitalizationDocument(file: HospitalizationDocument) {
    this.$emit("removeDocument", file);
  }
}
