<template>
  <div>
    <!-- eslint-disable-next-line -->
    <div class="note">{{ isClipped ? clippedText : text }}</div>
    <div v-show="text.length > maxChars">
      <button
        :class="btnClass"
        type="button"
        class="btn btn-link"
        @click="triggerReadMore"
      >
        {{ isClipped ? moreStr : lessStr }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    btnClass: {
      default: "",
      type: String,
    },
    lessStr: {
      default: "",
      type: String,
    },
    maxChars: {
      default: 100,
      type: Number,
    },
    moreStr: {
      required: true,
      type: String,
    },
    text: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      isClipped: this.text.length > this.maxChars,
    };
  },
  computed: {
    clippedText() {
      return `${this.text.substring(0, this.maxChars)}...`;
    }
  },
  watch: {
    text() {
      this.isClipped = this.text.length > this.maxChars;
    },
  },
  methods: {
    triggerReadMore() {
      this.isClipped = !this.isClipped;
    },
  },
};
</script>

<style scoped>
  .note {
    white-space: pre-line;
  }
</style>
