import {ClinicParametersState} from "./types";
import {CLINIC_PARAMETERS, storage} from "../clinicParameters";

export const getInitialState = (): ClinicParametersState => {
  const clinicParametersStr = storage.getItem(CLINIC_PARAMETERS);
  return {
    parameters: clinicParametersStr
      ? JSON.parse(clinicParametersStr)
      : null,
  }
};
