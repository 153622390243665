<template>
  <div>
    <resizable-textarea
      :value="value"
      :disabled="disabled"
      :maxlength="maxCharactersCount"
      :placeholder="placeholder"
      :required="required"
      :rows="rows"
      :state="state"
      @input="update"
      @focus.native="focus"
    />
    <div class="font-weight-bold text-right small">
      {{ counter }} z {{ maxCharactersCount }} znaków
    </div>
  </div>
</template>

<script>
import ResizableTextarea from "./ResizableTextarea";
export default {
  components: {ResizableTextarea},
  props:{
    value: {type: String, default: null},
    disabled: {type: Boolean, default: false},
    maxCharactersCount: {type: Number, default: 100},
    maxRows: {type: [Number, String], default: 100},
    placeholder: {type: String, default: null},
    required: {type: Boolean, default: false},
    rows: {type: [Number, String], default: null},
    state: {type: Boolean, default: null},
  },
  computed: {
    counter() {
      return this.value ? this.value.length : 0;
    }
  },
  methods: {
    update(value) {
      this.$emit("input", value);
    },
    focus() {
      this.$emit("focus");
    },
  },
}
</script>
