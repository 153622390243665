<template>
  <tr>
    <td>
      <span v-show="!editing && !updating">{{ requirement.frequencyCount }} razy na miesiąc</span>
      <b-form-group
        v-show="editing || updating"
        label-for="count"
        class="meeting-control"
      >
        <div class="input-group">
          <b-form-input
            id="count"
            v-model.number="count"
            :disabled="updating"
            :state="state('count')"
            class="mb-2"
            placeholder="Liczba wizyt w miesiącu"
            type="number"
            required
            min="1"
          />
        </div>
        <error-message
          :errors="errors"
          field="count"
        />
      </b-form-group>
      <error-message :errors="errors" />
    </td>
    <td>{{ requirement.treatmentName }}</td>
    <td>{{ requirement.specialist }}</td>
    <td
      v-if="!readOnly"
      class="text-right"
    >
      <is-granted
        :privileges="['UPDATE_MEETING_REQUIREMENT']"
        :subject="requirement.meetingRequirementId"
      >
        <button
          v-show="editing"
          class="btn btn-link btn-sm"
          @click="cancel"
        >
          <i class="fa fa-times" /> Odrzuć
        </button>
        <button
          class="btn btn-outline-primary btn-sm"
          @click="edit"
        >
          <i
            :class="updating ? 'fa-spin fa-spinner' : editing ? 'fa-check' : 'fa-pencil-alt'"
            class="fas"
          /> {{ editing ? "Zapisz" : "Edytuj" }}
        </button>
        <button
          class="btn btn-outline-secondary btn-sm"
          @click="remove"
        >
          <i
            :class="removing ? 'fa-spin fa-spinner' : 'fa-trash'"
            class="fas"
          /> Usuń
        </button>
      </is-granted>
    </td>
  </tr>
</template>

<script>
import remove from "../../../../rest/remove";
import update from "../../../../rest/update";
import ErrorMessage from "../../../Form/ErrorMessage";
import {errorsMixin} from "@/mixins/errorsMixin";
import processResponseException from "../../../../utils/errors/processResponseException";
import IsGranted from "../../../IsGranted";

export default {
  name: "MeetingRequirementRow",
  components: {IsGranted, ErrorMessage},
  mixins: [errorsMixin],
  model: {
    prop: "requirement",
  },
  props: {
    requirement: {required: true, type: Object},
    readOnly: {type: Boolean, required: true},
  },
  data() {
    return {
      editing: false,
      updating: false,
      removing: false,
      count: this.requirement.frequencyCount,
    };
  },
  methods: {
    cancel () {
      this.count = this.requirement.frequencyCount;
      this.editing = false;
      this.errors = [];
    },
    async remove() {
      this.removing = true;
      this.errors = [];
      try {
        await remove(`/api/meeting-requirements/${this.requirement.meetingRequirementId}`, {});
        this.$emit("requirement-removed", this.requirement.meetingRequirementId);
      } catch (exception) {
        this.errors = processResponseException(exception);
      }
      this.removing = false;
    },
    async edit() {
      if (!this.editing) {
        this.editing = true;
        return;
      }
      await this.save();
    },
    async save() {
      this.updating = true;
      try {
        await update(`/api/meeting-requirements/${this.requirement.meetingRequirementId}`, {count: this.count});
        this.editing = false;
        await this.$emit("requirement-updated", {...this.requirement, frequencyCount: this.count});
        this.count = this.requirement.frequencyCount;
        this.errors = [];
      } catch (exception) {
        this.errors = processResponseException(exception);
      }
      this.updating = false;
    }
  },
}
</script>

