<template>
  <anonymous-layout>
    <div
      class="m-b-20 mx-auto text-center"
      style="width: 40vw; max-width: 100%;"
    >
      <logotype
        :width="290"
        class="img-fluid"
      />
      <b-alert
        :show="showInfoMessage"
        class="m-t-10"
        variant="info"
        dismissible
      >
        Jeżeli adres email istnieje, wiadomość została wysłana.
      </b-alert>
      <b-alert
        :show="showErrorMessage"
        class="m-t-10"
        variant="danger"
        dismissible
      >
        Niepoprawny adres email.
      </b-alert>
      <form
        class="form-horizontal m-t-20 text-white"
        @submit.prevent="submit"
      >
        <div class="input-group mb-2">
          <div class="input-group-prepend">
            <label
              class="input-group-text"
              for="email"
            >
              <i class="fa fa-envelope" />
            </label>
          </div>
          <input
            id="email"
            v-model.trim="email"
            :autofocus="true"
            type="email"
            class="form-control"
            name="_email"
            required
            placeholder="E-mail użytkownika"
          >
        </div>
        <div class="d-flex">
          <a
            class="back-to-login"
            href="/login"
          >
            Wróć do logowania
          </a>
          <button
            :disabled="disabled"
            class="btn btn-light w-md waves-effect waves-light ml-auto"
            type="submit"
          >
            <loading-icon
              :loading="loading"
              ready-icon="fa-unlock"
              size="sm"
            />
            Resetuj hasło
          </button>
        </div>
      </form>
    </div>
  </anonymous-layout>
</template>

<script>
import Logotype from "@/components/Layout/Logotype";
import AnonymousLayout from "@/components/Layout/AnonymousLayout";
import LoadingIcon from "@/components/Loading/LoadingIcon";
import create from "@/rest/create";
import processResponseException from "@/utils/errors/processResponseException";

export default {
  name: "UserRequestPasswordChange",
  components: {Logotype, AnonymousLayout, LoadingIcon},

  data() {
    return {
      email: "",
      loading: false,
      showInfoMessage: false,
      showErrorMessage: false,
      errors: []
    };
  },

  computed: {
    disabled() {
      return this.loading || "" === this.email;
    },
  },

  methods: {
    async submit(){
      this.showErrorMessage = false;
      this.showInfoMessage = false;
      this.loading = true;
      try{
        await create("/api/password-reset-by-email", {
          email: this.email,
          type: "email"
        });
        this.showInfoMessage = true;
      } catch (e) {
        this.errors = processResponseException(e);
        this.showErrorMessage = this.hasError("email");
      }
      this.loading = false;
    },
    hasError(field) {
      return !!this.errors.find((error) => error.field === field);
    },
  }
}
</script>

<style scoped>
.back-to-login {
  color: #fff;
  font-size: 15px;
  margin-top: 5px;
  margin-left: 5px;
}
</style>
