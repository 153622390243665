































































import {Component, Prop} from "vue-property-decorator";
import Vue from "vue";
import type {
  EntryPart,
  SimplifiedIpzEntryCreator,
  SimplifiedIpzEntryMetadata,
  SimplifiedIpzTypes
} from "../../../types/SimplifiedIpzTypes";
import DateTime from "../../DateTime.vue";
import parseDate from "../../../utils/date/parseDate";
import DATE_FORMAT from "../../../utils/date/DATE_FORMAT";
import SurveyResults from "./SimplifiedIpzTimelineEntry/SurveyResults.vue";
import I18n from "../../i18n.vue";

@Component({
  name: "SimplifiedIpzTimelineInfoEntry",
  components: {
    DateTime,
    SurveyResults,
    I18n,
  },
})
export default class SimplifiedIpzTimelineInfoEntry extends Vue {
  @Prop({type: String, required: true}) readonly entryId!: string;
  @Prop({type: Array, required: true}) readonly parts!: Array<EntryPart>;
  @Prop({type: String, required: true}) readonly createdAt!: string;
  @Prop({type: Object, required: true}) readonly createdBy!: SimplifiedIpzEntryCreator;
  @Prop({type: String, required: true}) readonly type!: SimplifiedIpzTypes;
  @Prop({type: Object, default: null}) readonly metadata: SimplifiedIpzEntryMetadata|null;

  parseDate = parseDate;
  DATE_FORMAT = DATE_FORMAT;
}
