













































































































import ErrorsMixin from "../../mixins/ErrorsMixin";
import {Component, Prop} from "vue-property-decorator";
import create from "../../rest/create";
import read from "../../rest/read";
import processResponseException from "../../utils/errors/processResponseException";
import ErrorMessage from "../Form/ErrorMessage.vue";
import {generateUuid} from "../../utils/uuid/generateUuid";

@Component({
  name: AcceptReferralModal.name,
  components: {ErrorMessage,}
})
export default class AcceptReferralModal extends (ErrorsMixin) {

  @Prop({type: String, default: null}) readonly patientId!: string;
  @Prop({type: String, default: null}) readonly appointmentDocumentId!: string;

  private code: string = "";
  private loading: boolean = false;
  private insured: boolean = false;
  private document: string = "";
  private documentUrl: string = "";

  hideModal(): void {
    this.errors = [];
    this.documentUrl = "";
    this.code = "";
    this.$bvModal.hide("accept-referral");
  }

  async accept(): Promise<void> {
    this.loading = true;
    try{
      await create("/api/accept-referral", {
        acceptReferralId: generateUuid(),
        patientId: this.patientId,
        code: this.code,
        insured: this.insured,
        appointmentDocumentId: this.appointmentDocumentId
      });
      this.$emit("created");
      this.hideModal();
    }catch(e) {
      this.errors = processResponseException(e);
    }
    this.loading = false;
  }

  async changeInsured(insured: boolean): Promise<void> {
    this.insured = insured;
  }

  async search(): Promise<void> {
    this.loading = true;
    try {
      const {document, token} = await read("/api/accepted-referral", {
        code: this.code,
        patientId: this.patientId,
      });
      this.documentUrl =
        `/api/print-accepted-referral?code=${this.code}&patientId=${this.patientId}&toke=${token}#zoom=120`;
      this.document = document;
      this.errors = [];
    }catch(e) {
      this.errors = processResponseException(e);
    }
    this.loading = false;
  }

}
