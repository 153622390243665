

















































import {Component, Mixins} from "vue-property-decorator";
import ErrorsMixin from "../../../mixins/ErrorsMixin";
import ReportContainer from "../../../components/Report/ReportContainer.vue";
import DatePicker from "../../../components/Form/DatePicker/DatePicker.vue";
import BackButton from "../../../components/BackButton.vue";
import ErrorMessage from "../../../components/Form/ErrorMessage.vue";
import stringifyDate from "../../../utils/date/stringifyDate";
import DATE_FORMAT from "../../../utils/date/DATE_FORMAT";
import {createUrl} from "../../../utils/pageUrl/createUrl";
import processResponseException from "../../../utils/errors/processResponseException";
import {isGranted} from "../../../security/isGranted";

@Component({
  name: "IKUPSummaryReport",
  components: {
    ErrorMessage,
    BackButton,
    ReportContainer,
    DatePicker,
  },
})
export default class IKUPSummaryReport extends Mixins(ErrorsMixin) {
  private startDate: Date|null = null;
  private endDate: Date|null = null;
  private loading: boolean = false;
  private canUseReport: boolean = false;

  get reportUrl() {
    let baseUrl = "/api/reports/ikup-summary-report-xlsx";
    return createUrl(baseUrl, this.filters);
  };

  get filters() {
    this.startDate ? this.startDate.setHours(0,0,0) : null;
    this.endDate ? this.endDate.setHours(23,59,59) : null;
    return {
      startDate: this.startDate ? stringifyDate(this.startDate) : null,
      endDate: this.endDate ? stringifyDate(this.endDate) : null,
    }
  }

  async mounted() {
    this.loading = true;
    try {
      this.canUseReport = await isGranted(["IKUP_SUMMARY_REPORT_XLSX"]);
    } catch (exception) {
      this.errors = processResponseException(exception);
    }
    this.loading = false;
  }

  validateReportDates(): void {
    if (this.startDate && this.endDate
      && this.startDate.getTime() >= this.endDate.getTime()) {
      this.errors = [
        {
          message: "Data początkowa powinna być wcześniejsza niż data końcowa",
          field: "startDate"
        },
        {
          message: "Data końcowa powinna być późniejsza niż data początkowa",
          field: "endDate"
        },
      ];
      return;
    } else {
      const errors = []
      if (!this.startDate) {
        errors.push({
          message: "Pole nie powinno być puste",
          field: "startDate"
        })
      }
      if (!this.endDate) {
        errors.push({
          message: "Pole nie powinno być puste",
          field: "endDate"
        })
      }
      this.errors = errors;
      return;
    }
    this.errors = [];
  }
}
