<template>
  <div class="row">
    <div class="col">
      <error-message :errors="errors" />
      <error-message
        :errors="errors"
        field="content.text"
      />
      <textarea-counter
        v-model="content"
        :disabled="loading"
        :state="state('content.text')"
        :max-characters-count="maxEntryLength"
        :rows="2"
        :placeholder="placeholder"
        required
        @input="update"
      />
    </div>
    <div class="col-auto">
      <button
        :disabled="emptyEntry || loading"
        class="btn-success btn"
        @click="addEntry"
      >
        <i
          :class="loading ? 'fa-spin fa-spinner' : 'fa-plus'"
          class="fa"
        />
        Zapisz
      </button>
    </div>
  </div>
</template>

<script>
import {generateUuid} from "@/utils/uuid/generateUuid";
import create from "../../../../rest/create";
import stringifyDate from "../../../../utils/date/stringifyDate";
import ErrorMessage from "../../../Form/ErrorMessage";
import TextareaCounter from "../../../Form/Textarea/TextareaCounter";
import {errorsMixin} from "../../../../mixins/errorsMixin.js";
import processResponseException from "../../../../utils/errors/processResponseException";
import {mapState} from "vuex";
import DATE_FORMAT from "../../../../utils/date/DATE_FORMAT";

export default {
  name: "TextEntryAdd",
  components: {TextareaCounter, ErrorMessage},
  mixins: [errorsMixin],
  props: {
    diagnosisId: {type: String, required: true},
    type: {type: String, required: true},
    typeName: {type: String, required: true},
    maxEntryLength: {type: Number, required: true},
  },
  data() {
    return {
      content: null,
      loading: false,
      emptyEntry: true,
    };
  },
  computed: {
    placeholder() {
      return `Treść nowego opisu do pola ${this.typeName}`;
    },
    ...mapState("currentUser", ["user"]),
  },
  methods: {
    async addEntry() {
      this.loading = true;
      const createdAt = new Date();
      try {
        const newEntry = {
          content: {
            type: "text",
            text: this.content,
          },
          entryId: generateUuid(),
          type: this.type,
          createdAt: stringifyDate(createdAt),
          workerId: this.user.workerId,
        };
        await create(`/api/diagnosis/${this.diagnosisId}/entry`, newEntry);
        const entry = {
          ...newEntry,
          createdAt: stringifyDate(createdAt, DATE_FORMAT.DATE_TIME_SEC),
          creator: {
            name: this.user.name,
            surname: this.user.surname,
          },
        };
        this.$emit("new-entry-added", entry);

        this.content = null;
        this.errors = [];
      } catch (exception) {
        this.errors = processResponseException(exception);
      }
      this.loading = false;
    },
    update(text) {
      if (!text) {
        this.emptyEntry = true;
      } else {
        this.content = text;
        this.emptyEntry = false;
      }
    },
  },
}
</script>
