<template>
  <div>
    <div v-if="value.type && isFieldToShow('type')">
      <b>Powiązanie:</b>&#32;
      <i18n
        :msgid="`@person-eligible.${value.type}`"
        component="span"
      />
    </div>
    <div v-if="(value.name || value.surname) && isFieldToShow('name')">
      <b>Imię i nazwisko:</b> {{ value.name }} {{ value.surname }}
    </div>
    <div v-if="value.pesel && isFieldToShow('pesel')">
      <b>PESEL:</b> {{ value.pesel }}
    </div>
    <div v-if="(value.phoneNumber || value.email) && isFieldToShow('contact')">
      <b>Telefon / E-mail:</b> {{ value.phoneNumber || "-" }} / {{ value.email || "-" }}
    </div>
    <div v-if="value.address && isFieldToShow('address')">
      <b>Adres:</b> {{ value.address.street }} {{ value.address.zipCode }} {{ value.address.city }}
    </div>
    <div v-if="value.description && isFieldToShow('description')">
      <b>Dodatkowy opis:</b> {{ value.description }}
    </div>
  </div>
</template>

<script>
import I18n from "../i18n";

const fieldTypes =[
  "type",
  "name",
  "pesel",
  "contact",
  "address",
  "description",
];

export default {
  name: "EligiblePersonDetails",
  components: {I18n},
  props: {
    value: {type: Object, required: true},
    fields: {type: Array, default: () => fieldTypes},
  },
  methods: {
    isFieldToShow(fieldName) {
      return this.fields.includes(fieldName);
    },
  },
}
</script>
