<template>
  <div>
    <appointment-item-actions-dropdown
      v-if="actionDropdownsVisible"
      v-bind="$props"
    />
    <appointment-item-actions-buttons
      v-if="actionButtonsVisible"
      v-bind="$props"
    />
    <appointment-item-actions-icons
      v-if="actionIconsVisible"
      v-bind="$props"
    />
  </div>
</template>
<script>
import AppointmentItemActionsIcons from "./AppointmentItemActionsIcons";
import AppointmentItemActionsButtons from "./AppointmentItemActionsButtons";
import AppointmentItemActionsDropdown from "./AppointmentItemActionsDropdown";

export default {
  name: "AppointmentItemActions",
  components: {
    AppointmentItemActionsDropdown,
    AppointmentItemActionsButtons,
    AppointmentItemActionsIcons,
  },
  props: {
    status: {required: true, type: String},
    mobile: {required: true, type: Boolean},
    appointmentId: {required: true, type: String},
    mainWorkerId: {required: true, type: String},
    workers: {required: true, type: Array},
    patients: {required: true, type: Array},
    group: {required: true, type: Boolean},
    date: {required: true, type: Date},
    cancelAllowedFromGroup: {required: true, type: Boolean},
    changePatientsAllowedFromGroup: {required: true, type: Boolean},
    removePatientAllowedFromGroup: {required: true, type: Boolean},
    patientId: {type: String, default: null},
    actionIconsVisible: {type: Boolean, default: false},
    actionButtonsVisible: {type: Boolean, default: false},
    actionDropdownsVisible: {type: Boolean, default: false},
    continuousStay: {type: Boolean, default: false},
    patientContinuousStays: {type: Array, default: ()=>[]},
    branchId: {type: String, required: true},
    branchName: {type: String, required: true},
  },
};
</script>
<style scoped lang="scss">
  @import "../../../styles/variables";

  ul li {
    display: inline-block;
    margin-right: 10px;
    cursor: pointer;

    &:empty {
      margin-right: 0;
    }
  }

  .btn-pink {
    background-color: $pink;
    color: $white;

    &:focus, &.focus,
    &:active, &.active,
    &:hover, &.hover {
      background-color: darken($pink, 5%);
    }
  }

  .appointment-action {
    white-space: normal;
  }
</style>
