











import Vue from "vue";
import {Component, Prop, Watch} from "vue-property-decorator";
import {BmiCategory} from "../../types/Bmi";
import I18n from "../i18n";
import read from "../../rest/read";
import getEntry from "../Ipz/DiagnosisStructure/EntryField";

const numberRegex = /^\d+$/;
const centimetersInMeter = 100;
const bmiCategories = [
  {name: BmiCategory.SEVERE_THINNESS, upperBound: 16},
  {name: BmiCategory.MODERATE_THINNESS, upperBound: 17},
  {name: BmiCategory.MILD_THINNESS, upperBound: 18},
  {name: BmiCategory.NORMAL, upperBound: 25},
  {name: BmiCategory.PRE_OBESE, upperBound: 30},
  {name: BmiCategory.OBESE_CLASS_I, upperBound: 35},
  {name: BmiCategory.OBESE_CLASS_II, upperBound: 40},
  {name: BmiCategory.OBESE_CLASS_III, upperBound: Infinity},
];

@Component({
  name: "PatientBmiAlert",
  components: {I18n}
})
export default class PatientBmiAlert extends Vue {
  @Prop({type: String, required: true}) readonly patientId!: string;

  weight: string|null = null;
  height: string|null = null;

  get bmi(): number|null {
    if (this.height && this.weight
      && numberRegex.test(this.height)
      && numberRegex.test(this.weight)) {
      return (this.weight / Math.pow(this.height/centimetersInMeter,2)).toFixed(2);
    }
    return null;
  }

  get bmiInterpretation(): BmiCategory|null {
    if (this.bmi === null) {
      return null;
    }

    const interpretation = bmiCategories.find(category => this.bmi < category.upperBound);

    return interpretation?.name || null;
  }

  get irregularWeight(): boolean {
    return !!this.bmiInterpretation && this.bmiInterpretation !== BmiCategory.NORMAL;
  }

  get bmiClass(): string {
    switch(this.bmiInterpretation) {
      case BmiCategory.SEVERE_THINNESS:
      case BmiCategory.MODERATE_THINNESS:
      case BmiCategory.OBESE_CLASS_I:
      case BmiCategory.OBESE_CLASS_II:
      case BmiCategory.OBESE_CLASS_III:
        return "btn-outline-danger"
      case BmiCategory.MILD_THINNESS:
      case BmiCategory.PRE_OBESE:
        return "btn-outline-warning"
      default:
        return "";
    }
  }

  @Watch("patientId", {immediate: true}) async onPatientIdChange(patientId: string): Promise<void> {
    const {items} = await read("/api/ipzs", {patientId, sort: "createdAt:desc"});

    const latestIpz = items[0];

    if (!latestIpz) {
      this.height = null;
      this.weight = null;
      return;
    }

    const {fields} = await read(`/api/ipzs/${latestIpz.ipzId}/details/pediatric`);
    this.height = this.getFieldAdditionalContent(fields, "PED_PH_HEIGHT")
    this.weight = this.getFieldAdditionalContent(fields, "PED_PH_BODY_WEIGHT")
  }

  getFieldAdditionalContent(fields, entryType) {
    const field = fields.find(fieldItem => fieldItem.entryType === entryType) || null;
    const entry = getEntry(field?.entries || []);
    return entry?.content?.additional || null;
  }
}
