






















































































































































































































































import {Component, Mixins, Prop, Watch} from "vue-property-decorator";
import processResponseException from "../../utils/errors/processResponseException";
import ErrorsMixin from "../../mixins/ErrorsMixin";
import ErrorMessage from "../Form/ErrorMessage.vue";
import type {TestResult, TestResultSubmitData} from "../../types/TestResult";
import {Test, TestResultModalType, TestResultStatus, TestScale} from "../../types/TestResult";
import read from "../../rest/read";
import DateTime from "../DateTime.vue";
import DATE_FORMAT from "../../utils/date/DATE_FORMAT";
import parseDate from "../../utils/date/parseDate";
import TestSelect from "./TestSelect.vue";
import TextareaCounter from "../Form/Textarea/TextareaCounter.vue";
import DatePicker from "../Form/DatePicker/DatePicker.vue";
import update from "../../rest/update";
import stringifyDate from "../../utils/date/stringifyDate";
import LoadingMask from "../Loading/LoadingMask.vue";
import {getLabel, getScaleInputs} from "../../utils/tests/TestLabels"

@Component({
  name: "TestResultPreviewEditModal",
  components: {
    LoadingMask,
    DatePicker,
    TextareaCounter,
    TestSelect,
    DateTime,
    ErrorMessage,
  },
})
export default class TestResultPreviewModal extends Mixins(ErrorsMixin) {
  @Prop({type: String, required: true}) readonly id!: string;
  @Prop({type: String, required: true}) readonly patientId!: string;
  @Prop({type: String, default: null}) readonly resultId!: string;
  @Prop({type: String, required: true}) readonly type!: TestResultModalType;

  private loading: boolean = false;
  private saving: boolean = false;
  private testResult: TestResult | null = null;
  private test: Test|null = null;
  private rawResults: Record<TestScale["scaleId"], string> = {};
  private results: Record<TestScale["scaleId"], string> = {};
  private date: Date|null = null;
  private summary: string = "";
  DATE_FORMAT = DATE_FORMAT;
  parseDate = parseDate;
  getLabel = getLabel;
  getScaleInputs = getScaleInputs;
  TestResultModalType = TestResultModalType;
  TestResultStatus = TestResultStatus;

  private get title(): string {
    if (this.type === TestResultModalType.EDIT) {
      return "Edycja wyników testu";
    }
    return "Wyniki testu";
  }

  @Watch("resultId", {immediate: true}) async onResultIdChange(resultId: string|null): Promise<void> {
    if (!resultId) {
      return;
    }
    this.loading = true;
    this.errors = [];
    try {
      const {items} = await read<{items: TestResult[]}>("/api/test-results", {
        testResultId: this.resultId,
      });
      this.testResult = items.length ? items[0] : null;
      if (this.testResult) {
        this.test = {
          value: this.testResult.testId,
          testId: this.testResult.testId,
          name: this.testResult.name,
          scales: this.testResult.scales,
          labelType: this.testResult.labelType,
          shortcut: this.testResult.shortcut
        };
        this.rawResults = Array.isArray(this.testResult.rawResults) ? {} : this.testResult.rawResults;
        this.results = Array.isArray(this.testResult.results) ? {} : this.testResult.results;
        this.date = parseDate(this.testResult.date, DATE_FORMAT.DATE_TIME_SEC);
        this.summary = this.testResult.summary;
      }
    } catch (e) {
      this.errors = processResponseException(e);
    }
    this.loading = false;
  }

  private displaySingle(test: Test|null): boolean {
    return test?.labelType === "raw" || test?.labelType === "algorithm";
  }

  private scaleAsLabel(test: Test|null) : boolean {
    return test?.labelType === "scale-as-label";
  }

  private updateRawResult($event: string, scaleId: string): void {
    if (!$event.trim()) {
      this.$delete(this.rawResults, scaleId);
    }
  }

  private async submit(status?: TestResultStatus): Promise<void> {
    this.errors = [];
    this.saving = true;
    try {
      await update<Partial<TestResultSubmitData>>("/api/test-results", {
        testResultId: this.resultId,
        testId: this.test?.testId || null,
        rawResults: this.rawResults,
        results: this.results,
        date: this.date ? stringifyDate(this.date) : null,
        summary: this.summary,
        status,
        labelType: this.test?.labelType || null
      });
      this.$emit("updated");
      this.hideModal();
    } catch (e) {
      this.errors = processResponseException(e);
    }
    this.saving = false;
  }

  private hideModal(): void {
    this.$bvModal.hide(this.id);
  }

  private onModalHidden(): void {
    this.test = null;
    this.rawResults = {};
    this.results = {};
    this.date = null;
    this.summary = "";
    this.$emit("closed");
  }

  private clearResults($event: Test|null): void {
    if ($event?.testId === this.testResult?.testId) {
      this.results = this.testResult?.results || {};
      this.rawResults = this.testResult?.rawResults || {};
    } else {
      this.results = {};
      this.rawResults = {};
    }
  }

  private scaleResultCount(scale: string) :number {
    const inputs = this.getScaleInputs(scale);
    return inputs.length;
  }
}
