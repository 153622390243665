<template>
  <div class="card-box role-list">
    <div class="row">
      <b-form-group
        class="col-md-6 col-xl-4"
      >
        <b-form-input
          v-model.trim="attributeFilterPhrase"
          placeholder="Wyszukaj po nazwie atrybutu"
        />
      </b-form-group>
      <b-form-group
        class="col-md-6 col-xl-4"
      >
        <role-type-select
          v-model="selectedRole"
          :with-computed-roles="true"
        />
      </b-form-group>
    </div>
    <div
      v-for="role in filteredRoleList"
      :key="role.roleName"
      class="d-flex flex-column align-items-start"
    >
      <div
        v-for="(attribute, key) in role.attributes"
        :key="key"
        class="m-b-5"
      >
        <!-- eslint-disable -->
        <b 
          v-b-tooltip.hover 
          :title="role.roleLabel"
        >{{ role.roleName }}: </b> <span v-html="highlightFilterPhrase(attribute)" />
      </div>
    </div>
  </div>
</template>

<script>
import RoleTypeSelect from "../Form/Select/RoleTypeSelect";

export default {
  components: {
    RoleTypeSelect
  },
  props: {
    roleList: {type: Array, required: true},
  },
  data() {
    return {
      selectedRole: null,
      attributeFilterPhrase: "",
    };
  },
  computed: {
    filteredRoleList() {
      return this.roleList
        .filter(role => !this.selectedRole || role.roleName === this.selectedRole.value)
        .map((role) => ({
          ...role,
          attributes: role.attributes.filter((attribute) => (
            !this.attributeFilterPhrase || attribute.includes(this.attributeFilterPhrase.toUpperCase())
          )),
        }));
    },
  },
  methods: {
    highlightFilterPhrase(value) {
      if (value && this.attributeFilterPhrase) {
        return value.replace(new RegExp(this.attributeFilterPhrase, "gi"), match => {
          return `<span class="highlight">${  match  }</span>`;
        });
      }
      return value;
    },
  },
}
</script>

<style lang="scss">
@import "../../styles/variables";

.role-list {
  .highlight {
    background-color: $gray-300;
  }
}
</style>
