<template>
  <div class="card-box">
    <error-message :errors="errors" />
    <b-form-group label="Nazwa jednostki">
      <b-form-input
        id="cooperating_unit_name"
        v-model.trim="name"
        :state="state('name')"
      />
      <error-message
        :errors="errors"
        field="name"
      />
    </b-form-group>
    <address-form
      v-model="address"
      :errors="subErrors('address')"
      :visible-country="false"
      horizontal
    />
    <div class="form-group">
      <b-form-input
        id="country"
        v-model="countryName"
        readonly
      />
    </div>
    <div class="form-group">
      <b-form-checkbox
        id="cooperating-unit-active"
        v-model="active"
        :state="state('active')"
      >
        <span :class="{'font-weight-bold': active}">jednostka aktywna</span>
      </b-form-checkbox>
      <error-message
        :errors="errors"
        field="active"
      />
    </div>
    <div class="text-right">
      <router-link
        :to="{name: 'cooperatingUnits'}"
        class="btn btn-secondary"
      >
        Powrót
      </router-link>
      <button
        class="btn btn-primary ml-2"
        @click="submit"
      >
        <i
          :class="loading ? 'fa-spin fa-spinner' : 'fa-check'"
          class="fa"
        />
        Zapisz
      </button>
    </div>
  </div>
</template>

<script>
import ErrorMessage from "../../components/Form/ErrorMessage";
import AddressForm from "../../components/Address/AddressForm";
import countriesJson from "../../countries.json"
import update from "../../rest/update";
import read from "../../rest/read";
import getSubErrors from "../../utils/errors/subErrors";
import {errorsMixin} from "../../mixins/errorsMixin.js";
import processResponseException from "../../utils/errors/processResponseException";

export default {
  components: {AddressForm, ErrorMessage},
  mixins: [errorsMixin],
  props: {
    cooperatingUnitId: {type: String, required: true},
  },
  data() {
    return {
      name: null,
      address: {
        country: null,
        zipCode: null,
        city: null,
        street: null,
      },
      countryName: null,
      active: null,
      loading: false,
    };
  },
  async mounted () {
    this.loadData();
  },
  methods: {
    async loadData() {
      const {
        name, country, zipCode, city, street, active
      } = await read(`/api/cooperating-unit/${this.cooperatingUnitId}`);
      this.name = name;
      this.countryName = countriesJson.find((c) => c.code === country).name;
      this.address.country = country;
      this.address.zipCode = zipCode;
      this.address.city = city;
      this.address.street = street;
      this.active = active;
    },
    async submit() {
      this.errors = [];
      this.loading = true;
      const formData = {
        name: this.name,
        address: this.address,
        active: this.active,
      };

      try {
        await update(`/api/cooperating-unit/${this.cooperatingUnitId}/update`, formData);
        await this.$router.push({name: "cooperatingUnits"});
      } catch(exception) {
        this.errors = processResponseException(exception);
      }
      this.loading = false;
    },
    subErrors(field) {
      return getSubErrors(this.errors, field);
    },
  },
}
</script>

