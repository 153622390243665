<template>
  <div>
    <template v-if="!value">
      <p>b.d.</p>
    </template>
    <template v-else>
      <template v-if="value.caseManager">
        <p><b>Case Manager: </b>{{ value.caseManager }}</p>
      </template>

      <template v-if="value.patients.length > 0">
        <p>
          <b>Pacjent: </b>
          <span
            v-for="(patient, index) in value.patients"
            :key="index"
          >
            {{ patient }}<span v-if="index !== value.patients.length-1">,</span>
          </span>
        </p>
      </template>

      <template v-if="value.patientPresence !== null">
        <p>
          <b>Obecność pacjenta: </b>{{ value.patientPresence ? 'Tak' : 'Nie' }}
        </p>
      </template>

      <template v-if="value.treatmentTypeCategory !== undefined && value.treatmentTypeCategory !== null">
        <p>
          <b>Kategoria usług: </b>{{ translate(value.treatmentTypeCategory) }}
        </p>
      </template>

      <template v-if="value.startDate">
        <p><b>Data początkowa: </b>{{ value.startDate }}</p>
      </template>

      <template v-if="value.endDate">
        <p><b>Data końcowa: </b>{{ value.endDate }}</p>
      </template>
      <template v-if="value.canceledAppointments !== null">
        <p><b>Uwzględnić wizyty odwołane: </b>{{ value.canceledAppointments ? 'Tak' : 'Nie' }}</p>
      </template>
    </template>
  </div>
</template>

<script>
import t from "../../../i18n";

export default {
  props: {
    value: {type: Object, default: null},
  },
  methods: {
    translate(val){
      return t(`@requestIKUPTreatmentCategory.${val}`)
    }
  }
}
</script>
