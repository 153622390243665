import Vue from "vue"
import {Component} from "vue-property-decorator";
import {Error} from "../rest";

@Component
export default class ErrorsMixin extends Vue {
  errors: Array<Error> = [];

  state(field: string): boolean|null {
    return this.errors.find((error) => error.field === field) ? false : null;
  }
}
