<template>
  <div>
    <b-dropdown
      v-if="bothPermissions"
      right
      variant="primary"
      class="h-100"
    >
      <template #button-content>
        <i class="fas fa-user-plus" />
      </template>
      <b-dropdown-item :to="{name: 'createPatientView'}">
        Zarejestruj pełnoletniego pacjenta
      </b-dropdown-item>
      <b-dropdown-item :to="{name: 'child_patient_create_view'}">
        Zarejestruj niepełnoletniego pacjenta
      </b-dropdown-item>
    </b-dropdown>
    <tooltip
      v-else-if="patientCreateAllowed"
      component="router-link"
      class="btn btn-primary d-inline-flex align-items-center h-100"
      tooltip-content="Zarejestruj pełnoletniego pacjenta"
      :to="{name: 'createPatientView'}"
    >
      <i class="fas fa-user-plus" />
    </tooltip>
    <tooltip
      v-else-if="childPatientCreateAllowed"
      component="router-link"
      class="btn btn-primary d-inline-flex align-items-center h-100"
      tooltip-content="Zarejestruj niepełnoletniego pacjenta"
      :to="{name: 'child_patient_create_view'}"
    >
      <i class="fas fa-user-plus" />
    </tooltip>
  </div>
</template>

<script>
import Tooltip from "../../Tooltip";
import {registerPatientButtonMixin} from "../../../mixins/registerPatientButtonMixin";

export default {
  name: "RegisterPatientTooltipButton",
  components: {Tooltip},
  mixins: [registerPatientButtonMixin],
}
</script>
