<template>
  <div class="card-box">
    <h4>Lista obecności</h4>
    <div
      v-for="(patient, index) in patients"
      :key="index"
    >
      <div class="form-row m-b-5">
        <div class="col-sm-3">
          <label :for="'patient-'+index">
            <input
              :id="'patient-'+index"
              v-model="patient.present"
              :disabled="readOnly"
              type="checkbox"
              value="case history"
            > {{ patient.name }} {{ patient.surname }} ({{ patient.pesel }})
          </label>
        </div>
        <div 
          v-if="patient.present" 
          class="col-sm-9"
        >
          <textarea-counter
            :id="'description-'+index"
            v-model="patient.appointmentDescription"
            :max-characters-count="1000"
            :disabled="readOnly"
            :state="state(index, 'appointmentDescription')"
            rows="3"
            placeholder="Notatka o pacjencie"
            @input="input"
            @focus="clearErrors(index, 'appointmentDescription')"
          />
          <module-error-message
            :messages="errors
              && errors[index]
              && errors[index].appointmentDescription"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TextareaCounter from "../../Form/Textarea/TextareaCounter";
import ModuleErrorMessage from "../../Form/ModuleErrorMessage";

export default {
  components: {ModuleErrorMessage, TextareaCounter},

  props: {
    idx: {required: true, type: Number},
    module: {required: true, type: Object},
    readOnly: {required: true, type: Boolean}
  },
  data() {
    return {
      patients: this.module.patients,
      errors: this.module.errors?.patients || [],
    };
  },
  watch: {
    "module.errors"() {
      this.errors = this.module.errors?.patients || [];
    },
  },
  methods: {
    input() {
      const module = {
        ...this.module,
        patients: this.patients,
      };
      this.$emit("input", module);
    },
    state(index, field) {
      return this.errors
      && this.errors[index]
      && this.errors[index][field]
        ? false
        : null;
    },
    clearErrors: function(index, field) {
      if (this.errors && this.errors[index]) {
        this.errors[index][field] = null;
      }
    },
  },
}
</script>
