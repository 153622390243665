

























import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";
import type {IdsTarget, Target} from "../../../types/TargetsAndTasks";
import Card from "../../Card.vue";
import TargetCheckbox from "./TargetCheckbox.vue";

@Component({
  name: "TargetsCheckboxGroup",
  components: {TargetCheckbox, Card}
})
export default class TargetsCheckboxGroup extends Vue {
  @Prop({type: Array, default: () => []}) readonly moduleTargets!: Array<IdsTarget>;
  @Prop({type: Array, default: () => []}) readonly patientTargets!: Array<Target>;
  @Prop({type: Boolean, default: false}) readonly readOnly!: boolean;

  private get checked(): Array<string> {
    return this.moduleTargets.map(target => target.targetId);
  }

  private updateTargets(checked: Array<string>): void {
    const targets: Array<IdsTarget> = checked.map(targetId => {
      const alreadySelected = this.moduleTargets.find(target => target.targetId === targetId);
      return alreadySelected || {
        targetId,
        taskIds: [],
      };
    });
    this.$emit("update", targets);
  }

  private updateTasks({taskIds, targetId}: IdsTarget): void {
    const targets = this.moduleTargets.map(target => {
      if (target.targetId === targetId) {
        return {
          targetId,
          taskIds,
        };
      }
      return target;
    });
    this.$emit("update", targets);
  }

  private moduleTargetTasks(targetId: Target["targetId"]) {
    const target =  this.moduleTargets.find(target => target.targetId === targetId);
    return target ? target.taskIds : [];
  }
}
