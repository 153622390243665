<template>
  <object-select
    :value="value"
    :options="options"
    :state="state"
    :placeholder="placeholder"
    :disabled="disabled"
    :clearable="clearable"
    @input="update"
  />
</template>

<script>
import ObjectSelect from "../Form/Select/ObjectSelect";
import t from "../../i18n";

export default {
  components: {
    ObjectSelect,
  },
  props: {
    value: {type: Object, default: null},
    state: {default: null, type: Boolean},
    placeholder: {type: String, default: "Wybierz osobę"},
    disabled: {type: Boolean, default: false},
    errors: {type: Array, default: () => []},
    clearable: {default: true, type: Boolean},
  },
  computed: {
    options() {
      return [
        "mother",
        "father",
        "grandmother_m",
        "grandfather_m",
        "grandmother_o",
        "grandfather_o",
        "son",
        "daughter",
        "sister",
        "brother",
        "stepfather",
        "stepmother",
        "colleague_m",
        "colleague_f",
        "husband",
        "wife",
        "partner_m",
        "partner_f",
        "grandson",
        "granddaughter",
        "extended_family",
        "close_person",
        "neighbors",
        "social_worker",
        "spiritual_person",
        "guardian",
        "therapist",
        "statutory_representative",
        "family_assistant",
        "foster_care_coordinator",
        "another_person_from_the_backrest_network",
        "policeman",
        "curator",
        "doctor",
        "nurse"
      ].map((value) => ({value, name: t(`@person-eligible.${value}`)}));
    },
  },
  methods: {
    update(option) {
      this.$emit("input", option);
    },
  },
};
</script>
