<template>
  <loading-mask :loading="loading">
    <error-message :errors="errors" />
    <ul
      v-if="items.length"
      class="list-group list-group-flush"
    >
      <li
        v-for="(item, index) in items"
        :key="index"
        class="list-group-item d-flex justify-content-between"
      >
        <div
          v-if="readOnly"
          class="d-flex job-details"
        >
          <i
            :class="item.confirmed ? 'fa-check-square text-primary' : 'fa-square text-light'"
            class="fas fa-lg mt-1 mr-2"
          />
          <div>
            {{ item.name }}
            <br>
            {{ item.description }}
          </div>
        </div>
        <b-checkbox
          v-else
          v-model="item.confirmed"
          class="job-details cursor-pointer"
          @change="changeStatus($event, index)"
        >
          {{ item.name }}
          <br>
          {{ item.description }}
        </b-checkbox>
        <div
          v-if="item.lastChangeBy && item.lastChangeAt"
          class="change-details"
        >
          {{ item.lastChangeBy }}
          (<date-time
            :value="parseDate(item.lastChangeAt)"
            component="fragment"
          />)
        </div>
      </li>
    </ul>
  </loading-mask>
</template>

<script>
import {errorsMixin} from "../../../../mixins/errorsMixin.js";
import LoadingMask from "../../../Loading/LoadingMask";
import ErrorMessage from "../../../Form/ErrorMessage";
import DateTime from "../../../DateTime";
import parseDate from "../../../../utils/date/parseDate";
import processResponseException from "../../../../utils/errors/processResponseException";
import read from "../../../../rest/read";
import modify from "../../../../rest/modify";
import {isGranted} from "../../../../security/isGranted";

export default {
  name: "ProcedureJobsList",
  components: {DateTime, ErrorMessage, LoadingMask},
  mixins: [errorsMixin],
  props: {
    procedureId: {type: String, required: true},
  },
  data() {
    return {
      items: [],
      loading: false,
      parseDate,
      readOnly: true,
    };
  },
  computed: {
    fields() {
      return [
        {key: "name", label: "Procedura"},
        {key: "lastChangeBy", label: "Wykonał"},
      ];
    }
  },

  async mounted() {
    this.loading = true;
    await this.fetchData();
    this.loading = false;
  },
  methods: {
    async fetchData() {
      try {
        const {jobs} = await read("/api/procedure-jobs", {procedureId: this.procedureId});
        this.items = jobs;
        this.checkPermission();
      } catch(e) {
        this.errors = [...this.errors, ...processResponseException(e)];
      }
    },
    async changeStatus($event, index) {
      this.loading = true;
      this.errors = [];
      try {
        await modify("/api/procedure-jobs", {
          procedureId: this.procedureId,
          jobIndex: index,
          confirm: $event,
        });
      } catch(e) {
        this.errors = processResponseException(e);
      }
      this.fetchData();
      this.loading = false;
    },
    async checkPermission() {
      this.readOnly = !(await isGranted("CONFIRM_PROCEDURE_JOB", this.procedureId));
    },
  },
}
</script>

<style scoped lang="scss">
  .job-details {
    margin-right: 0;
    flex: 0 0 47%;
  }

  .change-details {
    flex: 0 0 53%;
    padding-left: 12px;
  }
</style>
