












import Vue from "vue";
import ObjectSelect from "./ObjectSelect";
import {Component, Prop} from "vue-property-decorator";

interface Option {
  value: string|null;
  name: string;
}

@Component({
  name: "SmtpEncryptionSelect",
  components: {ObjectSelect}
})
export default class SmtpEncryptionSelect extends Vue {
  @Prop({type: Boolean, default: true}) readonly clearable!: boolean;
  @Prop({type: Boolean, default: false}) readonly disabled!: boolean;
  @Prop({type: String, default: "Wybierz"}) readonly placeholder!: string;
  @Prop({type: Boolean, default: null}) readonly state!: boolean|null;
  @Prop({type: String, default: null}) readonly value!: string|null;

  get options(): Option[] {
    return [
      {
        value: "tls",
        name: "TLS",
      },
      {
        value: "ssl",
        name: "SSL",
      },
      {
        value: null,
        name: "brak",
      },
    ]
  }

  private get selectedValue(): Option {
    return this.options.find(option => option.value === this.value) || null;
  }

  private update(option: Option): void {
    this.$emit("input", option?.value || null);
  }
}
