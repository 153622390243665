<template>
  <div>
    <b-form-group
      label="Specjaliści"
      label-for="additionalWorkers"
    >
      <worker-select
        id="additionalWorkers"
        :value="formData.additionalWorkers"
        :state="state('additionalWorkers')"
        multiple
        @input="updateFormData('additionalWorkers', $event)"
      />
      <error-message
        :errors="errors"
        field="additionalWorkers"
      />
    </b-form-group>
    <b-form-group
      label="Kategoria"
      label-for="categorySelect"
    >
      <interventionNotificationCategorySelect
        id="categorySelect"
        :value="formData.category"
        :state="state('category')"
        @input="updateFormData('category', $event)"
      />
      <error-message
        :errors="errors"
        field="category"
      />
    </b-form-group>

    <b-form-group
      label="Płeć"
      label-for="sexSelect"
    >
      <sexSelect
        id="sexSelect"
        :value="formData.sex"
        :state="state('sex')"
        @input="updateFormData('sex', $event)"
      />
      <error-message
        :errors="errors"
        field="sex"
      />
    </b-form-group>

    <b-form-group
      label="Przedział wiekowy"
      label-for="ageSelect"
    >
      <interventionNotificationAgeSelect
        id="ageSelect"
        :value="formData.age"
        :state="state('age')"
        @input="updateFormData('age', $event)"
      />
      <error-message
        :errors="errors"
        field="age"
      />
    </b-form-group>

    <b-form-group
      id="description"
      label="Opis"
      label-for="descriptionInput"
    >
      <b-form-textarea
        id="nameInput"
        :value="formData.description"
        :state="state('description')"
        :rows="5"
        @input="updateTextarea"
      />
      <error-message
        :errors="errors"
        field="description"
      />
    </b-form-group>

    <b-form-group
      label="Czas trwania"
    >
      <b-form-group>
        <b-form-checkbox
          v-if="!isEdit"
          :checked="formData.isDuration"
          :state="state('isDuration')"
          @input="updateIsDuration"
        >
          Określ czas trwania
        </b-form-checkbox>
        <error-message
          :errors="errors"
          field="isDuration"
        />
      </b-form-group>

      <b-form-group>
        <date-picker
          v-if="formData.isDuration"
          :state="state('startDate')"
          :minute-step="1"
          :value="formData.startDate"
          format="YYYY-MM-DD HH:mm"
          type="datetime"
          @input="updateFormData('startDate', $event)"
        />
        <error-message
          :errors="errors"
          field="startDate"
        />
      </b-form-group>


      <b-form-group>
        <appointment-duration-select
          v-if="formData.isDuration"
          :state="state('duration')"
          :default-duration="15"
          :value="formData.duration"
          placeholder="Wybierz czas trwania interwencji"
          @input="updateFormData('duration', $event)"
        />
        <error-message
          :errors="errors"
          field="duration"
        />
      </b-form-group>
    </b-form-group>
  </div>
</template>

<script>
import InterventionNotificationCategorySelect from "../Form/Select/InterventionNotificationCategorySelect.vue";
import SexSelect from "../Form/Select/SexSelect.vue";
import InterventionNotificationAgeSelect from "../Form/Select/InterventionNotificationAgeSelect.vue";
import ErrorMessage from "../Form/ErrorMessage";
import DatePicker from "../Form/DatePicker/DatePicker";
import AppointmentDurationSelect from "../Calendar/AppointmentDurationSelect";
import WorkerSelect from "../Worker/WorkerSelect.vue";

export default {
  components: {
    AppointmentDurationSelect,
    DatePicker,
    InterventionNotificationCategorySelect,
    SexSelect,
    InterventionNotificationAgeSelect,
    ErrorMessage,
    WorkerSelect
  },

  props:{
    value: {type: Object, required: true},
    errors: {type: Array, required: true},
    isEdit: {type: Boolean, default: false},
  },

  data() {
    return {
      formData: {
        additionalWorkers: [],
        isDuration: false
      },
    };
  },

  watch: {
    value(){
      this.formData = {...this.value};
    },
  },
  methods: {
    state(field) {
      return this.errors.find((error) => error.field === field) ? false : null;
    },
    update(){
      this.$emit("input", this.formData);
    },
    removeWhitespace(value) {
      this.formData.description = value;
      this.update();
    },
    updateTextarea(value) {
      this.removeWhitespace(value);
    },
    updateIsDuration(isDuration) {
      const startDate = isDuration ? this.formData.startDate || new Date() : undefined;
      const duration = isDuration ? this.formData.duration || 15 : undefined;
      this.formData = {
        ...this.formData,
        isDuration,
        startDate,
        duration,
      };
      this.update();
    },
    updateFormData(field, value) {
      this.formData = {
        ...this.formData,
        [field]: value,
      };
      this.update();
    }
  }
}
</script>
