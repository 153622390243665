











import Vue from "vue";
import ObjectSelect from "../../../Form/Select/ObjectSelect.vue";
import {Component, Prop} from "vue-property-decorator";
import type {Option} from "../../../../types/MultiSelectTypes";
import {HospitalizationType} from "../../../../types/HospitalizationTypes";
import t from "../../../../i18n";

@Component({
  name: "HospitalizationTypeSelect",
  components: {ObjectSelect},
})
export default class HospitalizationTypeSelect extends Vue {
  @Prop({type: Boolean, default: false}) readonly disabled!: boolean;
  @Prop({type: String, default: "Wybierz..."}) readonly placeholder!: string;
  @Prop({type: Boolean, default: null}) readonly state!: boolean;
  @Prop({type: String, default: null}) readonly value!: string;

  private get options(): Option[] {
    return Object.values(HospitalizationType).map((type) => ({
      name: t(`@hospitalization-type.${type}`),
      value: type,
    }))
  }

  private get selectedOption(): Option|null {
    if (!this.value) {
      return null;
    }
    return this.options.find(option => option.value === this.value) || null;
  }

  private update(option: Option): void {
    this.$emit("input", option?.value);
  }
}
