<template>
  <vue-multi-select
    ref="multiselect"
    v-tabindex
    :loading="loading"
    :disabled="disabled"
    :placeholder="placeholder"
    :options="options"
    :value="value"
    :allow-empty="clearable"
    :multiple="multiple"
    :close-on-select="!multiple"
    :class="{invalid: isInvalid}"
    :state="state"
    :taggable="taggable"
    deselect-group-label="Odznacz grupę"
    deselect-label="Odznacz"
    select-group-label="Wybierz grupę"
    tag-placeholder="Utwórz tag"
    select-label="Wybierz"
    selected-label="Wybrany"
    track-by="value"
    label="name"
    @input="update"
    @open="open"
    @close="close"
    @search-change="searchChange"
    @select="select"
    @tag="newTag"
    @blur="blur"
  >
    <template #noOptions>
      <slot name="noOptions">
        <span />
      </slot>
    </template>
    <template #noResult>
      <span>Brak wyników wyszukiwania</span>
    </template>
    <template #singleLabel="props">
      <slot
        name="singleLabel"
        v-bind="props"
      />
    </template>
    <template #option="props">
      <slot
        name="option"
        v-bind="props"
      />
    </template>
  </vue-multi-select>
</template>
<script>
import VueMultiSelect from "vue-multiselect";

export default {
  components: {VueMultiSelect},
  directives: {
    tabindex: {
      inserted(el) {
        el.setAttribute("tabindex", 0);
      },
    },
  },
  props: {
    placeholder: {default: "Wybierz...", type: String},
    options: {required: true, type: Array},
    value: {default: null, type: [Object, Array]},
    name: {default: null, type: String},
    loading: {default: false, type: Boolean},
    disabled: {default: false, type: Boolean},
    clearable: {default: true, type: Boolean},
    multiple: {default: false, type: Boolean},
    state: {default: null, type: Boolean},
    taggable: {default: false, type: Boolean},
  },
  computed: {
    isInvalid() {
      return false === this.state;
    },
  },
  methods: {
    update(option) {
      this.$emit("input", option);
    },
    searchChange(phrase) {
      this.$emit("search-change", phrase);
    },
    open(){
      this.$emit("open");
    },
    close() {
      this.$emit("close");
    },
    select(option) {
      this.$emit("select", option);
    },
    newTag(tag) {
      this.$emit("tag", tag);
    },
    activate() {
      this.$refs.multiselect.activate();
    },
    deactivate() {
      this.$refs.multiselect.deactivate();
    },
    blur() {
      this.$emit("blur");
    },
  },
};
</script>
