


































































import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";
import type {TestResult} from "../../types/TestResult";
import {TestResultStatus} from "../../types/TestResult";
import type {BvTableFieldArray} from "bootstrap-vue/src/components/table";
import DateTime from "../DateTime.vue";
import DATE_FORMAT from "../../utils/date/DATE_FORMAT";
import parseDate from "../../utils/date/parseDate";
import I18n from "../i18n.vue";
import IsGranted from "../IsGranted.vue";

@Component({
  name: "TestResultsList",
  components: {IsGranted, I18n, DateTime},
})
export default class TestResultsList extends Vue {
  @Prop({type: Array, default: ()=>[]}) readonly items!: TestResult[];
  @Prop({type: Boolean, default: false}) readonly readOnly!: boolean;

  DATE_FORMAT = DATE_FORMAT;
  parseDate = parseDate;

  get fields(): BvTableFieldArray {
    return [
      {key: "name", label: "Nazwa"},
      {key: "status", label: "Status"},
      {key: "date", label: "Data przeprowadzenia"},
      {key: "createdBy", label: "Osoba wprowadzająca"},
      {key: "options", label: "", class: "text-right"},
    ];
  }

  getBadgeVariant(status: TestResultStatus): string {
    switch(status) {
      case TestResultStatus.OPENED:
        return "success";
      case TestResultStatus.FINISHED:
        return "primary";
      case TestResultStatus.DRAFT:
        return "secondary"
      default:
        throw Error(`Not supported status: ${status}`)
    }
  }
}
