





























import parseDate from "../../utils/date/parseDate";
import WebNotificationsDropdown from "./WebNotificationsDropdown.vue";
import WebNotificationsModal from "./WebNotificationsModal.vue";
import modify from "../../rest/modify";
import eventBus from "../../eventBus";
import create from "../../rest/create";
import read from "../../rest/read";
import Vue from "vue";
import {Component} from "vue-property-decorator";
import type {
  WebNotification,
  FetchedNotification,
  FetchedData,
  ModifiedNotification,
} from "../../types/WebNotifications";
import type {Pagination} from "../../types/Pagination";

@Component({
  name: "WebNotifications",
  components: {WebNotificationsDropdown, WebNotificationsModal},
})

export default class WebNotifications extends Vue {
  unreadCounter: number = 0;
  notifications: Array<WebNotification> = [];
  loading: boolean = false;
  counterTimeout?: number;
  reading: boolean = false;
  pagination: Pagination = {
    perPage: 5,
    totalRows: 0,
    currentPage: 1,
  };

  get nextPage(): Pagination["currentPage"] {
    return Math.floor(this.notifications.length / this.pagination.perPage) + 1;
  }

  get hasMoreNotifications(): boolean {
    return this.pagination.totalRows !== this.notifications.length;
  }

  mounted(): void {
    this.loadUnreadCounterValue()
      .then(() => this.initCounterTimeout());
  }

  initCounterTimeout(): void {
    this.counterTimeout = window.setTimeout(() => {
      this.loadUnreadCounterValue();
      this.initCounterTimeout();
    }, 15000);
  }

  async loadUnreadCounterValue(): Promise<void> {
    const {items, pagination} = await read<FetchedData>("/api/web-notifications", {unread: true, perPage: 1}, false);
    items.map((item: FetchedNotification) => {
      if (item.event) {
        eventBus.emit(item.event);
        eventBus.off(item.event);
      }
    });
    this.unreadCounter = pagination.totalRows;
  }

  async onOpen(): Promise<void> {
    if (this.counterTimeout) {
      clearTimeout(this.counterTimeout);
    }
    if (this.unreadCounter !== 0 || this.notifications.length === 0) {
      await this.loadNotifications(1, true);
    }
  }

  async setNotificationsUnreadStatus(notifications: Array<WebNotification>): Promise<void> {
    const items = notifications.reduce((list: Array<ModifiedNotification>, notification: WebNotification) => {
      if (notification.unread) {
        list.push({
          notificationId: notification.notificationId,
          unread: false,
        });
      }
      return list;
    }, []);
    if (items.length) {
      await modify("/api/web-notifications", {items});
    }
  }

  onHide(): void {
    this.unreadCounter = 0;
    this.setVisibleNotificationsAsRead();
    this.notifications = this.notifications.slice(0, this.pagination.perPage);
    this.initCounterTimeout();
  }

  setVisibleNotificationsAsRead():void {
    this.notifications = this.notifications.map(notification => ({...notification, unread: false}));
  }

  async loadNotifications(page = this.nextPage, reload = false): Promise<void> {
    this.loading = true;
    const {items, pagination} = await read<FetchedData>("/api/web-notifications", {
      page,
      perPage: this.pagination.perPage,
    });
    const newNotifications: Array<WebNotification> = items.map((item: FetchedNotification) => ({
      notificationId: item.notificationId,
      type: item.type,
      icon: item.icon,
      content: item.content,
      date: parseDate(item.date),
      unread: item.unread,
    }));
    this.pagination = pagination;
    await this.setNotificationsUnreadStatus(newNotifications);
    this.notifications = reload ? newNotifications : this.notifications.concat(newNotifications);
    this.loading = false;
  }

  async readAll(): Promise<void> {
    this.reading = true;
    await create("/api/web-notifications/read-all", {});
    this.setVisibleNotificationsAsRead();
    this.reading = false;
  }
}
