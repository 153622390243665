export const errorsMixin = {
  data() {
    return {
      errors: [],
    };
  },
  methods: {
    state(field) {
      return this.errors.find((error) => error.field === field) ? false : null;
    },
  },
};
