<template>
  <div class="row border-bottom">
    <div class="col-lg-1">
      Zródło informacji
    </div>
    <div class="col-lg-11 border-left p-2">
      <b-form-checkbox-group
        :checked="value"
        class="d-flex flex-row"
        @change="update"
      >
        <b-form-checkbox
          v-for="option in options"
          :key="option.value"
          :value="option.value"
          class="flex-grow-1"
        >
          {{ option.name }}
        </b-form-checkbox>
      </b-form-checkbox-group>
    </div>
  </div>
</template>
<script>
import t from "../../i18n";

export default {
  props: {
    value: {type: Array, default: () => []},
  },
  data() {
    const options = [
      {name: t("interview"), value: "interview"},
      {name: t("questionnaire"), value:"questionnaire"},
      {name: t("clinical examination"), value:"clinical examination"},
      {name: t("specialist examination"), value:"specialist examination"},
    ];

    return {
      options,
    };
  },
  methods: {
    update(sources) {
      let emitValue;
      switch (sources.length) {
        case 0:
          emitValue = [];
          break;
        case 1:
          emitValue = [sources[0]];
          break;
        default:
          emitValue = [sources[1]];
          break;
      }
      this.$emit("input", emitValue);
    }
  }
}
</script>
