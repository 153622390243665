<template>
  <div>
    <b-form-group
      v-for="(patientPermission, index) in patientPermissionsList"
      :key="index"
    >
      <b-form-checkbox
        v-model="permissions[patientPermission.propertyName]"
        :state="state(patientPermission.propertyName)"
        @input="update"
      >
        {{ patientPermission.createLabel }}
      </b-form-checkbox>
      <error-message
        :errors="errors"
        :field="patientPermission.propertyName"
      />
    </b-form-group>
  </div>
</template>

<script>
import ErrorMessage from "../../components/Form/ErrorMessage.vue";
import permissionsList from "../../components/Patient/patientPermissions.ts";

export default {
  components: {
    ErrorMessage
  },
  props: {
    value: {type: Object, required: true},
    errors: {type: Array, default: () => []},
    displayType: {type: String, default: null}
  },
  data() {
    return {
      permissionsList,
      patientPermissionsList: permissionsList.filter(
        permission => permission.types.includes(this.displayType)
      ),
      permissions: this.value
    };
  },
  created() {
    this.patientPermissionsList.forEach(permission => this.permissions[permission.propertyName] = false);
  },
  methods: {
    update(){
      this.$emit("input", this.permissions);
    },
    state(field){
      return this.errors.find((error) => error.field === field) ? false : null;
    },
  }
}
</script>

<style scoped>

</style>
