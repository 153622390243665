































































































































































import ErrorsMixin from "../../mixins/ErrorsMixin.ts";
import {Component, Mixins, Prop} from "vue-property-decorator";
import type {
  ICD10FormData,
  InsuredPerson,
  InsuredPersonAddress,
  InsuredPersonFormData,
  Patient,
  Payer,
  PersonOfCareFormData,
  ZlaData,
  ZlaDurationFormData,
} from "../../types/Zla";
import {InsurerType, MedicalIndicationType, PayerIdType, PlaceOfInsuranceType,} from "../../types/Zla";
import read from "../../rest/read";
import {Error} from "../../rest";
import InsuredPersonForm from "../../components/Zla/InsuredPersonForm.vue";
import InsuredPersonAddressForm from "../../components/Zla/InsuredPersonAddressForm.vue";
import PersonOfCareForm from "../../components/Zla/PersonOfCareForm.vue";
import processResponseException from "../../utils/errors/processResponseException";
import ErrorMessage from "../../components/Form/ErrorMessage.vue";
import LastEditedAppointment from "../../components/Appointment/LastEditedAppointment.vue";
import Card from "../../components/Card.vue";
import Icd10ZlaForm from "../../components/Zla/Icd10ZlaForm.vue";
import PayerForm from "../../components/Zla/PayerForm.vue";
import ZlaDurationForm from "../../components/Zla/ZlaDurationForm.vue";
import ResizableTextarea from "../../components/Form/Textarea/ResizableTextarea.vue";
import MedicalIndicationCheckbox from "../../components/Zla/MedicalIndicationCheckbox.vue";
import LoadingMask from "../../components/Loading/LoadingMask.vue";
import create from "../../rest/create";
import stringifyDate from "../../utils/date/stringifyDate";
import DATE_FORMAT from "../../utils/date/DATE_FORMAT";
import {generateUuid} from "../../utils/uuid/generateUuid";
import subErrors from "../../utils/errors/subErrors";
import {Location} from "vue-router";

interface PayersResponseData {
  result: Payer[];
}

const today: Date = new Date();
const allowedRetroactiveZlaDate: Date = new Date();
allowedRetroactiveZlaDate.setDate(allowedRetroactiveZlaDate.getDate() - 3);
allowedRetroactiveZlaDate.setHours(0, 0, 0, 0);

const icd10initialState: ICD10FormData = {
  icd10code: null,
  icd10categories: [],
}

const durationInitialState: ZlaDurationFormData = {
  from: today,
  to: today,
}

@Component({
  name: "Zla",
  components: {
    LoadingMask,
    MedicalIndicationCheckbox,
    ResizableTextarea,
    ZlaDurationForm,
    PayerForm,
    Icd10ZlaForm,
    Card,
    LastEditedAppointment,
    ErrorMessage,
    PersonOfCareForm,
    InsuredPersonAddressForm,
    InsuredPersonForm,
  }
})
export default class Zla extends Mixins(ErrorsMixin) {
  @Prop({type: String, required: true}) readonly sessionId!: string;
  @Prop({type: String, required: true}) readonly patientId!: string;
  @Prop({type: String, required: true}) readonly appointmentId!: string;
  @Prop({type: String, required: true}) readonly appointmentDocumentId!: string;

  private loading: boolean = false;
  private patient: Patient|null = null;
  private pesel: string = "";
  private insured: InsuredPersonFormData|null = null;
  private address: InsuredPersonAddress | null = null;
  private personOfCare: PersonOfCareFormData|null = null;
  private icd10: ICD10FormData = {...icd10initialState};
  private payer: Payer|null = null;
  private duration: ZlaDurationFormData = {...durationInitialState};
  private retroactiveJustification: string = "";
  private medicalIndication: MedicalIndicationType = MedicalIndicationType.CAN_LEAVE_HOME;
  // private stationaryZOZstay: boolean|null = false;
  private doNotInformPayer: boolean = true;
  addresses: InsuredPersonAddress[] = [];
  private payers: Record<InsurerType, Payer[]> = {
    [InsurerType.ZUS]: [],
    [InsurerType.KRUS]: [],
    [InsurerType.OTHER_PL]: [],
    [InsurerType.OTHER_ABROAD]: [],
  }
  private password: string = "";
  private allowedRetroactiveZlaDate: Date = allowedRetroactiveZlaDate;

  private get systemErrors(): Error[] {
    return this.errors.reduce<Error[]>((list, error) => {
      if (error.field?.includes("KEDU")) {
        list.push({
          message: error.message
        });
      }
      return list;
    }, []);
  }

  private get appointmentDocumentPath(): Location {
    return {
      name: "viewDocumentAppointment",
      params: {appointmentId: this.appointmentId},
      hash: `#appointmentDocumentId=${this.appointmentDocumentId}`,
    };
  }

  async mounted(): Promise<void> {
    await this.fetchPatientData();
  }

  private async fetchPatientData(): Promise<void> {
    this.loading = true;
    try {
      this.patient = await read(`/api/patients/${this.patientId}`);
    } catch (e) {
      this.errors = processResponseException(e);
    }
    this.loading = false;
  }

  private async fetchInsuredPersonData(): Promise<void> {
    const data = await read<InsuredPerson>("/api/zla/get-insured-person", {
      sessionId: this.sessionId,
      pesel: this.pesel,
    })

    this.insured = {
      name: data.name,
      surname: data.surname,
      pesel: this!.pesel,
      birthDate: data.birthDate,
      insurer: null,
    };

    this.addresses = data.address;
  }

  private async fetchZlaData(): Promise<void> {
    this.errors = [];
    this.loading = true;
    try {
      await Promise.all([
        this.fetchInsuredPersonData(),
        this.fetchPayersData(),
      ]);
    } catch (e) {
      this.errors = processResponseException(e);
    }
    this.loading = false;
  }

  private async getPatientInsuranceData(): Promise<void> {
    if (this.patient?.pesel) {
      this.pesel = this.patient.pesel;
      await this.fetchZlaData();
    }
  }
  private async fetchPayersData(): Promise<void> {
    // Endpoint "/api/zla/get-payers" doesn't work with Promise.all
    // Types used with insured person PESEL
    const ZUS = await read<PayersResponseData>("/api/zla/get-payers", {
      sessionId: this.sessionId,
      pesel: this.pesel,
      placeOfInsurance: InsurerType.ZUS,
    });
    const KRUS = await read<PayersResponseData>("/api/zla/get-payers", {
      sessionId: this.sessionId,
      pesel: this.pesel,
      placeOfInsurance: InsurerType.KRUS,
    });
    // Types used with insured person passport number
    // const OTHER_PL = await read<PayersResponseData>("/api/zla/get-payers", {
    //   sessionId: this.sessionId,
    //   pesel: this.pesel,
    //   placeOfInsurance: InsurerType.OTHER_PL,
    // });
    // const OTHER_ABROAD = await read<PayersResponseData>("/api/zla/get-payers", {
    //   sessionId: this.sessionId,
    //   pesel: this.pesel,
    //   placeOfInsurance: InsurerType.OTHER_ABROAD,
    // });
    this.payers[InsurerType.ZUS] = ZUS.result;
    this.payers[InsurerType.KRUS] = KRUS.result;
    // this.payers[InsurerType.OTHER_PL] = OTHER_PL.result;
    // this.payers[InsurerType.OTHER_ABROAD] = OTHER_ABROAD.result;
  }

  private getSubErrors(field: string): Error[] {
    return subErrors(this.errors, field);
  }

  private getZlaData(): ZlaData {
    const insured = this.insured ? {
      name: this.insured.name,
      surname: this.insured.surname,
      pesel: this.pesel,
      placeOfInsurance: this.insured.insurer ? PlaceOfInsuranceType[this.insured.insurer] : null,
    } : null;
    const icd10code = this.icd10.icd10code?.value || null

    const idType = this.payer?.nip
      ? PayerIdType.NIP
      : PayerIdType.PESEL;
    const payerId = idType === PayerIdType.NIP ? this.payer?.nip : this.payer?.pesel;

    return {
      patientId: this.patientId,
      password: this.password,
      documentId: generateUuid(),
      appointmentDocumentId: this.appointmentDocumentId,
      insured,
      address: this.address,
      duration: {
        from: stringifyDate(this.duration.from, DATE_FORMAT.DATE),
        to: stringifyDate(this.duration.to, DATE_FORMAT.DATE),
      },
      medicalIndication: this.medicalIndication,
      icd10: {
        icd10code,
        icd10categories: this.icd10.icd10categories,
      },
      personOfCare: this.personOfCare ? {
        ...this.personOfCare,
        birthDate: this.personOfCare.birthDate && stringifyDate(this.personOfCare.birthDate, DATE_FORMAT.DATE),
      } : null,
      retroactiveJustification: this.retroactiveJustification || undefined,
      doNotInformPayer: this.doNotInformPayer,
      payerData: payerId ? {
        idType,
        payerId
      } : null,
    }
  }

  private async submit(): Promise<void> {
    this.errors = []
    this.loading = true;
    const data = this.getZlaData();
    try {
      await create(`/api/zla/document/${this.sessionId}`, data);
      await this.$router.push(this.appointmentDocumentPath);
    } catch(e) {
      this.errors = processResponseException(e);
    }
    this.loading = false;
  }
}
