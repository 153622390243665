<template>
  <card
    title="Pobyt ciągły"
  >
    <template #control>
      <b-btn
        v-show="!editing && !readOnly"
        size="sm"
        variant="primary"
        @click="editing = true"
      >
        <span class="fas fa-pencil-alt" />
        Edytuj
      </b-btn>
    </template>
    <template #default>
      <b-form-group
        v-if="editing"
        label="Czy chcesz przypisać pobyt ciągły?"
      >
        <b-form-radio-group
          :id="`has-reference-${id}`"
          :checked="module.hasReference"
          :disabled="readOnly"
          :state="state('hasReference')"
          @focus="clearErrors('hasReference')"
          @input="input($event, 'hasReference')"
        >
          <b-form-radio :value="true">
            Tak
          </b-form-radio>
          <b-form-radio :value="false">
            Nie
          </b-form-radio>
        </b-form-radio-group>
      </b-form-group>
      <div
        v-if="module.hasReference"
        class="row"
      >
        <b-form-group
          class="col-12 col-sm-6 col-xl-4"
          label="Przypisany pobyt ciągły"
        >
          <continuous-stay-id-select
            ref="continuousStayIdSelect"
            :disabled="readOnly || !editing"
            :patient-id="patientId"
            :start="start"
            :end="end"
            :state="state('continuousStayId')"
            :value="module.continuousStayId"
            @input="input($event, 'continuousStayId')"
          />
        </b-form-group>
        <div class="col-12 col-sm-auto no-label">
          <is-granted
            v-if="editing && !readOnly"
            :privileges="['CREATE_CONTINUOUS_STAY']"
            component="fragment"
          >
            <b-btn
              v-if="module.hasReference"
              v-b-modal.createContinuousStayModal
              size="sm"
              variant="primary"
            >
              <i class="fa fa-plus" /> Dodaj pobyt ciągły
            </b-btn>
          </is-granted>
        </div>
      </div>
      <div v-else-if="!editing">
        Nie przypisano pobytu ciągłego do wizyty
      </div>
      <module-error-message :messages="errors && errors.continuousStayId" />
      <patient-card-create-continuous-stay
        :appointment-id="appointmentId"
        :patient-id="patientId"
        @created="updateContinuousStay"
      />
    </template>
  </card>
</template>
<script>

import PatientCardCreateContinuousStay
  from "@/components/Patient/PatientCard/ContinuousStay/PatientCardCreateContinuousStay";
import ContinuousStayIdSelect from "@/components/Patient/PatientCard/ContinuousStay/ContinuousStayIdSelect";
import IsGranted from "@/components/IsGranted";
import Card from "@/components/Card";
import ModuleErrorMessage from "../../Form/ModuleErrorMessage";

export default {
  components: {ModuleErrorMessage, Card, IsGranted, ContinuousStayIdSelect, PatientCardCreateContinuousStay},
  props: {
    appointmentId: {required: true, type: String},
    continuousStayId: {default: null, type: String},
    idx: {required: true, type: Number},
    module: {required: true, type: Object},
    patientId: {required: true, type: String},
    readOnly: {required: true, type: Boolean},
    start: {required: true, type: Date},
    end: {required: true, type: Date},
  },
  data() {
    return {
      id: this.idx,
      vueReadOnly: this.readOnly,
      errors: this.module.errors || [],
      editing: false,
    };
  },
  watch: {
    "module.errors"() {
      this.errors = this.module.errors;
    },
    "module.hasReference"(newVal) {
      if (!newVal) {
        this.input(null, "continuousStayId");
      }
    },
  },
  mounted() {
    if (this.continuousStayId && this.module.hasReference == null) {
      const module = {
        ...this.module,
        hasReference: true,
        continuousStayId: this.continuousStayId,
      }
      this.updateModule(module);
    }
  },
  methods: {
    input($event, field) {
      const module = {...this.module};
      module[field] = $event;
      this.updateModule(module);
    },
    updateModule(module) {
      this.$emit("input", module);
      this.editing = module.hasReference == null || (module.hasReference && !module.continuousStayId);
    },
    clearErrors: function(field) {
      if (this.errors) {
        this.errors[field] = null;
      }
    },
    state(field) {
      return (this.errors && this.errors[field] ? false : null);
    },
    async updateContinuousStay($event) {
      await this.$refs.continuousStayIdSelect.loadOptions();
      setTimeout(() => {
        this.input($event, "continuousStayId");
      });
    },
  },
}
</script>
