































































































import {Component, Prop, Watch} from "vue-property-decorator";
import ErrorsMixin from "../../../mixins/ErrorsMixin.ts";
import {mixins} from "vue-class-component";
import ResizableTextarea from "../../Form/Textarea/ResizableTextarea.vue";
import processResponseException from "../../../utils/errors/processResponseException";
import ErrorMessage from "../../Form/ErrorMessage.vue";
import TargetStatusSelect from "./TargetStatusSelect.vue";
import Card from "../../Card.vue";
import CreateTaskModal from "./CreateTaskModal.vue";
import update from "../../../rest/update";
import type {Target, TargetFormData} from "../../../types/TargetsAndTasks";

@Component({
  name: "TargetForm",
  components: {CreateTaskModal, Card, TargetStatusSelect, ErrorMessage, ResizableTextarea},
})

export default class TargetForm extends mixins(ErrorsMixin) {
  @Prop({type: String, required: true}) readonly ipzId!: string;
  @Prop({type: Object, required: true}) readonly target!: Target;
  @Prop({type: Boolean, default: false}) readonly updating!: boolean;
  @Prop({type: Boolean, required: true}) readonly readonly!: boolean;

  name: TargetFormData["name"] = this.target.name;
  description: TargetFormData["description"] = this.target.description;
  status: TargetFormData["status"] = this.target.status;

  get disableActions(): boolean {
    return this.target.name === this.name
      && this.target.description === this.description
      && this.target.status === this.status;
  }

  @Watch("target", {immediate: true}) onTargetChange(): void {
    this.resetData();
  }

  async submit(): Promise<void> {
    this.resetErrors();
    this.$emit("setUpdating", true);
    try {
      const data = {
        name: this.name ? this.name.trim() : this.name,
        description: this.description ? this.description.trim() : this.description,
        status: this.status,
      };
      await update(`/api/targets/${this.target.targetId}`, data);
      this.$emit("updated");
    } catch (e) {
      this.errors = processResponseException(e);
    }
    this.$emit("setUpdating", false);
  }

  resetErrors() {
    this.errors = [];
  }

  resetData() {
    this.resetErrors();
    this.name = this.target.name;
    this.description = this.target.description;
    this.status = this.target.status;
  }
}
