











import Vue from "vue";
import {ICD10Category} from "../../types/Zla";
import {Component, Prop} from "vue-property-decorator";

interface Option {
  value: ICD10Category;
  text: string;
}

@Component({
  name: "Icd10CategoriesCheckboxGroup"
})
export default class Icd10CategoriesCheckboxGroup extends Vue {
  @Prop({type: Array, default: () => []}) readonly value!: ICD10Category[];
  @Prop({type: Boolean, default: null}) readonly state!: boolean;

  get options(): Option[] {
    return [
      {
        value: ICD10Category.A,
        text: "A"
      },
      {
        value: ICD10Category.B,
        text: "B"
      },
      {
        value: ICD10Category.C,
        text: "C"
      },
      {
        value: ICD10Category.D,
        text: "D"
      },
      {
        value: ICD10Category.E,
        text: "E"
      },
    ];
  }

  update(checked: ICD10Category[]): void {
    this.$emit("input", checked);
  }
}
