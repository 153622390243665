<template>
  <form
    class="form-row"
    @submit.prevent="submit"
  >
    <div class="col-md-6 col-xl-6">
      <template v-if="selectedView === 'month'">
        <span class="col-form-label">Miesiąc</span>
        <date-picker
          v-model="selectedStartDate"
          :type="'month'"
          :format="'YYYY-MM'"
        />
      </template>
      <template v-else>
        <span class="col-form-label">Data</span>
        <date-picker
          v-model="selectedStartDate"
        />
      </template>
    </div>
    <div class="col-md-6 col-xl-6">
      <span class="col-form-label">Typ usługi</span>
      <treatment-type-select
        v-model="selectedTreatment"
        :check-if-any-treatment-type-exists="true"
      />
    </div>
    <div class="col-md-6 col-xl-6">
      <span class="col-form-label">Preferowany specjalista</span>
      <worker-select
        v-model="selectedWorkers"
        :treatment-type="selectedTreatment"
        multiple
        name="workers"
      />
    </div>
    <div class="mx-2">
      <calendar-type-select
        v-model="selectedView"
        name="view"
        @change="changeCalendarView"
      />
    </div>
    <div class="ml-auto">
      <button
        type="submit"
        class="submit btn btn-primary"
      >
        <i class="fa fa-search" />
        Szukaj
      </button>
    </div>
  </form>
</template>
<script>
import DatePicker from "../Form/DatePicker/DatePicker";
import CalendarTypeSelect from "./CalendarTypeSelect.vue";
import WorkerSelect from "../Worker/WorkerSelect.vue";
import TreatmentTypeSelect from "../TreatmentType/TreatmentTypeSelect.vue";
import stringifyDate from "../../utils/date/stringifyDate";
import DATE_FORMAT from "../../utils/date/DATE_FORMAT";
import {updatePageQuery} from "../../utils/pageUrl/handlePageQuery";
import isEqual from "lodash/isEqual";

export default {
  components: {
    TreatmentTypeSelect,
    WorkerSelect,
    CalendarTypeSelect,
    DatePicker,
  },
  props: {
    view: {type: String, required: true},
    treatmentType: {type: String, default: null},
    appointmentId: {type: String, default: null},
    copyId: {type: String, default: null},
    workers: {type: Array, default: () => []},
    startDate: {type: Date, default: null},
  },
  data() {
    return {
      selectedView: this.view,
      selectedTreatment: this.treatmentType,
      selectedWorkers: this.workers,
      selectedStartDate: this.startDate,
    };
  },
  computed: {
    selectedStartDateString() {
      const date = new Date(this.selectedStartDate.getTime());
      if (this.selectedView === "month") {
        date.setDate(1);
      }
      date.setHours(0,0,0,0);
      return stringifyDate(date, DATE_FORMAT.DATE_TIME);
    },
    selectedEndDateString() {
      const date = new Date(this.selectedStartDate.getTime());
      date.setHours(0, 0, 0, 0);
      if (this.selectedView === "month") {
        date.setMonth(date.getMonth() + 1, 1);
        return stringifyDate(date, DATE_FORMAT.DATE_TIME);
      }
      date.setDate(date.getDate() + 1);
      return stringifyDate(date, DATE_FORMAT.DATE_TIME);
    },
    selectedWorkersIds() {
      if (this.selectedWorkers.length && "object" === typeof this.selectedWorkers[0]) {
        return this.selectedWorkers.map(worker => worker.value);
      }
      return this.selectedWorkers;
    },
    query() {
      return {
        view: this.selectedView || undefined,
        treatmentType: this.selectedTreatment || undefined,
        appointmentId: this.appointmentId || undefined,
        copyId: this.copyId || undefined,
        startDate: this.selectedStartDateString || undefined,
        endDate: this.selectedEndDateString || undefined,
        workers: this.selectedWorkersIds.join(",") || undefined,
      }
    },
  },
  watch: {
    view(val) {
      this.selectedView = val;
    },
    treatmentType(val) {
      this.selectedTreatment = val;
    },
    startDate(val) {
      this.selectedStartDate = val;
    },
    workers(val) {
      this.selectedWorkers = val;
    },
  },
  methods: {
    changeCalendarView(view) {
      if (this.selectedView === "month") {
        this.selectedStartDate = new Date();
      }
      this.selectedView = view;
    },
    submit() {
      if (isEqual(this.$route.query, this.query)) {
        this.$emit("reload");
        return;
      }
      updatePageQuery(this.query);
    }
  },
};
</script>
<style lang="scss" scoped>
  .col-form-label {
    display: inline-block;
    padding-top: 0;
  }

  .submit {
    margin-top: 1.8rem;
    margin-bottom: 1.5rem;
  }
</style>
