<template>
  <card
    :loading="loading"
    title=""
  >
    <error-message :errors="errors" />
    <form-row
      :errors="errors"
      field="roomNumber"
      label="Numer"
    >
      <b-form-input
        id="room-number"
        v-model.trim="roomNumber"
        :state="state('roomNumber')"
        placeholder="Podaj numer pokoju"
      />
    </form-row>
    <form-row
      :errors="errors"
      field="roomType"
      label="Typ pokoju"
    >
      <room-type-select
        id="room-type"
        v-model="roomType"
        :state="state('roomType')"
      />
    </form-row>
    <form-row
      :errors="errors"
      field="branchId"
      label="Komórka organizacyjna"
    >
      <branch-select
        v-model="branchId"
        :state="state('branchId')"
      />
    </form-row>

    <form-row
      :errors="errors"
      field="floor"
      label="Piętro"
    >
      <b-form-input
        id="room-floor"
        v-model.trim="floor"
        :state="state('floor')"
        placeholder="Podaj numer piętra"
      />
    </form-row>
    <form-row
      :errors="errors"
      field="description"
      label="Opis"
    >
      <b-form-textarea
        id="room-description"
        v-model="description"
        :rows="3"
        :state="state('description')"
        placeholder="Podaj opis pokoju"
        @input="removeExcessiveWhitespace"
      />
    </form-row>
    <b-checkbox
      id="room_active"
      v-model="active"
      :state="state('active')"
    >
      <span class="font-weight-bold">
        Pokój aktywny
      </span>
    </b-checkbox>
    <error-message
      :errors="errors"
      field="active"
    />
    <div class="text-right">
      <router-link
        :to="{name: 'room_list'}"
        class="btn btn-secondary waves-effect waves-float"
      >
        Anuluj
      </router-link>
      <button
        :disabled="loading"
        type="submit"
        class="btn btn-primary ml-2 waves-effect waves-float"
        @click="submit"
      >
        <i
          :class="loading ? 'fa-spin fa-spinner' : 'fa-check'"
          class="fa"
        />
        Zapisz
      </button>
    </div>
  </card>
</template>

<script>
import ErrorMessage from "../../components/Form/ErrorMessage";
import FormRow from "../../components/Form/FormRow";
import {generateUuid} from "../../utils/uuid/generateUuid";
import create from "../../rest/create";
import removeExceededWhitespace from "../../utils/removeExceededWhitespace";
import {errorsMixin} from "../../mixins/errorsMixin.js";
import processResponseException from "../../utils/errors/processResponseException";
import BranchSelect from "../../components/Branch/BranchSelect";
import RoomTypeSelect from "../../components/Rooms/RoomTypeSelect";
import Card from "../../components/Card";

export default {
  name: "RoomCreate",
  components: {Card, RoomTypeSelect, ErrorMessage, FormRow, BranchSelect},
  mixins: [errorsMixin],
  data() {
    return {
      loading: false,
      roomNumber: null,
      floor: null,
      roomType: null,
      description: null,
      branchId: null,
      active: true,
    };
  },
  methods: {
    removeExcessiveWhitespace(v) {
      this.description = removeExceededWhitespace(v);
    },
    async submit() {
      this.loading = true;

      const roomId = generateUuid();
      const formData = {
        roomId,
        roomNumber: this.roomNumber,
        floor: this.floor,
        roomType: this.roomType,
        description: this.description,
        branchId: this.branchId ? this.branchId : "",
        active: this.active,
      };

      try {
        await create("/api/rooms", formData);
        this.errors = [];
        await this.$router.push({name: "room_list"});
      } catch(exception) {
        this.errors = processResponseException(exception);
      }
      this.loading = false;
    },
  },
}
</script>
