<template>
  <li
    :data-test="`menu-item_${menuItem.id}`"
    :class="{active: isActiveLink}"
  >
    <router-link
      :to="menuItem.link"
      class="waves-effect waves-primary"
      exact
    >
      <i :class="menuItem.icon" />
      <span>{{ menuItem.name }}</span>
    </router-link>
  </li>
</template>

<script>
export default {
  props: {
    menuItem: {type: Object, required: true},
  },
  computed: {
    isActiveLink() {
      return this.menuItem.link === this.$route.path;
    }
  },
}
</script>

<style lang="scss" scoped>
  @import "../../styles/variables";

  #sidebar-menu ul li {
    & > a {
      padding: 8px 20px;
    }

    &.active > a {
      background: $lightdark5 !important;
      border-left: 3px solid $primary;
    }
  }
</style>
