import {MutationTree} from "vuex";
import {CurrentUserState, User} from "./types"
import {initialState} from "./initialState";

export const mutations: MutationTree<CurrentUserState> = {
  loading(state) {
    state.loading = true;
    state.user = initialState.user;
  },
  toggleMultiFactorAuth(state) {
    state.user.settings.multiFactorAuth = !state.user.settings.multiFactorAuth;
  },
  setCurrentUser(state, currentUser: User) {
    state.user = currentUser;
    state.loading = false;
  },
  clear(state) {
    state.loading = false;
    state.user = initialState.user;
  }
};
