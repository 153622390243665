<template>
  <div class="row">
    <div class="col">
      <error-message :errors="errors" />
      <error-message
        :errors="errors"
        field=""
      />
      <textarea-counter
        v-model="content"
        :disabled="editLoading"
        :state="state('')"
        :max-characters-count="2500"
        :rows="2"
        required
        @input="update"
      />
    </div>
    <div class="col-auto d-flex flex-column">
      <button
        :disabled="emptyEntry || editLoading"
        class="btn-success btn"
        @click="updateEntry"
      >
        <i
          :class="editLoading ? 'fa-spin fa-spinner' : 'fa-check'"
          class="fa"
        />
        Zapisz
      </button>
      <button
        :disabled="editLoading"
        class="btn-secondary btn"
        @click="cancelEdit"
      >
        Anuluj
      </button>
    </div>
  </div>
</template>

<script>
import update from "../../../rest/update";
import ErrorMessage from "../../Form/ErrorMessage";
import TextareaCounter from "../../Form/Textarea/TextareaCounter";
import processResponseException from "../../../utils/errors/processResponseException";

export default {
  name: "TextEntryEdit",
  components: {TextareaCounter, ErrorMessage},
  props: {
    entry: {type: Object, required: true},
  },
  data() {
    return {
      content: this.entry.content,
      editLoading: false,
      errors: [],
      emptyEntry: true,
    };
  },
  methods: {
    async updateEntry() {
      this.editLoading = true;
      try {
        const entry = {
          content: {
            type: "text",
            text: this.content,
          },
        };
        const location = `/api/ipzs/therapeutic-plans/entries/${this.entry.entryId}`;
        await update(location, entry);
        const readEntry = {
          ...this.entry,
          content: entry.content.text,
        };
        this.$emit("entry-edited", readEntry);

        this.errors = [];
      } catch (exception) {
        this.errors = processResponseException(exception);
      }
      this.editLoading = false;
    },
    cancelEdit() {
      this.$emit("cancel-edit");
      this.content = this.entry.content;
    },
    update(text) {
      if (!text) {
        this.emptyEntry = true;
      } else {
        this.content = text;
        this.emptyEntry = false;
      }
    },
    state(field) {
      return this.errors.find((error) => error.field === field) ? false : null;
    },
  },
}
</script>
