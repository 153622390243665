<template>
  <b-modal
    id="ipz-edit"
    :visible="visible"
    no-close-on-backdrop
    title="Edycja daty"
    title-tag="h3"
    @hide="closeModal"
  >
    <template v-if="ipz">
      <FormRow
        :errors="errors"
        label="Data otwarcia IPZ"
        field="start-date"
      >
        <date-picker
          id="start-date"
          v-model="startDate"
          :disabled-date="disabledStartDateDays"
        />
      </FormRow>
    </template>
    <template #modal-footer>
      <button
        class="btn btn-secondary"
        type="button"
        @click="closeModal"
      >
        Zamknij
      </button>
      <button
        :disabled="!ipz || loading"
        class="btn btn-primary"
        @click="updateIpzStartDate"
      >
        <i
          :class="loading ? 'fa-spin fa-spinner' : 'fa-check'"
          class="fa"
        />
        Zapisz
      </button>
    </template>
  </b-modal>
</template>

<script>
import processResponseException from "../../../utils/errors/processResponseException";
import stringifyDate from "../../../utils/date/stringifyDate";
import DATE_FORMAT from "../../../utils/date/DATE_FORMAT";
import DatePicker from "../../Form/DatePicker/DatePicker";
import {errorsMixin} from "../../../mixins/errorsMixin.js";
import modify from "../../../rest/modify";
import FormRow from "../../Form/FormRow";
export default {
  name: "IpzEditModal",
  components: {FormRow, DatePicker},
  mixins: [errorsMixin],
  props: {
    ipz: {type: Object, default: null},
  },
  data() {
    const startDate = null === this.ipz ? new Date() : new Date(this.ipz.createdAt);
    startDate.setHours(0,0,0,0);

    return {
      startDate,
      loading: false,
    };
  },
  computed: {
    visible() {
      return this.ipz !== null;
    },
    startDateString() {
      return this.startDate ? stringifyDate(this.startDate, DATE_FORMAT.DATE) : null;
    },
  },
  watch: {
    ipz: function (val) {
      if (null === val) {
        return;
      }
      const startDate = new Date(val.createdAt);
      startDate.setHours(0,0,0,0);
      this.startDate = startDate;
    }
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    disabledStartDateDays(date) {
      if (!this.ipz) {
        return false;
      }
      const today = new Date();
      today.setHours(23,59,59,0);

      const registeredDate = new Date(this.ipz.patientRegisteredDate);
      registeredDate.setHours(0,0,0,0);

      return (date > today) || (date < registeredDate);
    },
    async updateIpzStartDate() {
      this.loading = true;
      try {
        const ipzCreatedAt = new Date(this.ipz.createdAt);
        ipzCreatedAt.setHours(0,0,0,0);
        if (this.startDate.getDate() !== ipzCreatedAt.getDate()) {
          await modify(`/api/ipzs/${this.ipz.ipzId}/start-date`, {
            startDate: this.startDateString,
          });
          this.$emit("close");
          this.$emit("startDateUpdated");
        }
      } catch (e) {
        this.errors = processResponseException(e);
      }
      this.loading = false;
    },
  },
}
</script>
