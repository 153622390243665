<template>
  <div>
    <div class="card-box">
      <h4>Dane szpitala</h4>
      <b-form-group
        label="Nazwa"
      >
        <b-form-input
          v-model.trim="hospitalName"
          :disabled="createLoading"
          :state="state('hospitalName')"
          @focus.native="clearError('hospitalName')"
        />
        <error-message
          :errors="errors"
          field="hospitalName"
        />
      </b-form-group>
      <address-form
        v-model="hospitalAddress"
        :disabled="createLoading"
        :errors="getSubErrors('hospitalAddress')"
        :visible-country="false"
        horizontal
        @clearError="clearHospitalAddressError"
      />
      <error-message
        :errors="errors"
        field="hospitalAddress"
        root
      />
    </div>
    <div
      v-if="patient"
      class="card-box"
    >
      <div class="row">
        <h4 class="col-12">
          Dane pacjenta
        </h4>
        <b-form-group
          class="col-md-4"
          label="Imię"
        >
          <b-form-input
            :value="patient.name"
            readonly
          />
        </b-form-group>
        <b-form-group
          class="col-md-4"
          label="Nazwisko"
        >
          <b-form-input
            :value="patient.surname"
            readonly
          />
        </b-form-group>
        <b-form-group
          class="col-md-4"
          label="PESEL"
        >
          <b-form-input
            :value="patient.pesel"
            readonly
          />
        </b-form-group>
        <address-form
          v-model="patientAddress"
          :disabled="createLoading"
          :errors="getSubErrors('patientAddress')"
          :visible-country="false"
          class="col-12"
          horizontal
          @clearError="clearPatientAddressError"
        />
        <b-form-group
          class="col-12"
          label="Po osobistym zbadaniu:"
        >
          <b-form-radio-group
            id="patient-consent"
            v-model="patientConsent"
            :state="state('patientConsent')"
            name="patient-consent"
            @input="clearError('patientConsent')"
          >
            <b-form-radio :value="true">
              za zgodą
            </b-form-radio>
            <b-form-radio :value="false">
              bez zgody
            </b-form-radio>
          </b-form-radio-group>
          <error-message
            :errors="errors"
            field="patientConsent"
          />
        </b-form-group>
        <hr>
        <b-form-group
          class="col-12"
          label=""
        >
          <b-form-radio-group
            id="eligible-person-consent"
            v-model="eligiblePersonConsent"
            :state="state('eligiblePersonConsent')"
            name="eligible-person-consent"
            @input="clearError('eligiblePersonConsent')"
          >
            <b-form-radio :value="true">
              za zgodą przedstawiciela ustawowego
            </b-form-radio>
            <b-form-radio :value="false">
              bez zgody przedstawiciela ustawowego
            </b-form-radio>
          </b-form-radio-group>
          <error-message
            :errors="errors"
            field="eligiblePersonConsent"
          />
        </b-form-group>
        <b-form-group
          class="col-md-6"
          label="Stopień ubezwłasnowolnienia"
        >
          <incapacitation-degree-select
            v-model="degreeOfIncapacitation"
            :disabled="createLoading"
          />
        </b-form-group>
      </div>
    </div>
    <div class="card-box">
      <b-form-group
        label="Osoba sprawująca opiekę faktyczną, jeżeli dotyczy:"
      >
        <b-form-checkbox v-model="guardianVisible">
          Wyświetl formularz
        </b-form-checkbox>
        <transition name="fade">
          <eligible-person-form
            v-show="guardianVisible"
            :person="guardian"
            :disabled="createLoading"
            :errors="getSubErrors('guardian')"
            @updateEligiblePerson="guardian = $event"
            @addPatientAddress="addPatientAddress('guardian')"
          />
        </transition>
      </b-form-group>
      <b-form-group
        label="Przedstawiciel ustawowy, jeżeli dotyczy:"
      >
        <b-form-checkbox v-model="statutoryRepresentativeVisible">
          Wyświetl formularz
        </b-form-checkbox>
        <transition name="fade">
          <eligible-person-form
            v-show="statutoryRepresentativeVisible"
            :person="statutoryRepresentative"
            :disabled="createLoading"
            :errors="getSubErrors('statutoryRepresentative')"
            @updateEligiblePerson="statutoryRepresentative = $event"
            @addPatientAddress="addPatientAddress('statutoryRepresentative')"
          />
        </transition>
      </b-form-group>
    </div>
    <div class="card-box">
      <b-form-group
        label="Rozpoznanie:"
      >
        <recognitions
          v-model="recognitions"
          :disabled="createLoading"
          :errors="errors"
          @input="clearError('recognitions')"
        />
      </b-form-group>
    </div>
    <div class="card-box">
      <b-form-group
        label="Inne informacje uzasadniające potrzebę przyjęcia do szpitala psychiatrycznego,
         w tym dotyczące dotychczas stosowanego leczenia, jeżeli dotyczy:"
        label-for="otherInformation"
      >
        <textarea-counter
          id="otherInformation"
          v-model="otherInformation"
          :disabled="createLoading"
          :max-characters-count="25000"
          :state="state('otherInformation')"
          rows="3"
          @focus="clearError('otherInformation')"
        />
        <error-message
          :errors="errors"
          field="otherInformation"
        />
      </b-form-group>
    </div>
    <error-message :errors="errors" />
    <div class="text-right">
      <router-link
        :to="appointmentDocumentPath"
        class="btn btn-secondary"
      >
        Powrót do wizyty
      </router-link>
      <button
        :disabled="createLoading"
        class="btn btn-primary ml-2"
        @click="issueReferral"
      >
        <i
          :class="createLoading ? 'fa-spinner fa-spin' : 'fa-check'"
          class="fa"
        />
        Zapisz
      </button>
    </div>
  </div>
</template>
<script>
import {errorsMixin} from "../../mixins/errorsMixin.js";
import {generateUuid} from "../../utils/uuid/generateUuid";
import create from "../../rest/create";
import ErrorMessage from "../../components/Form/ErrorMessage";
import subErrors from "../../utils/errors/subErrors";
import Recognitions from "../../components/Referrals/Recognitions";
import TextareaCounter from "../../components/Form/Textarea/TextareaCounter";
import IncapacitationDegreeSelect from "../../components/Form/Select/IncapacitationDegreeSelect";
import AddressForm from "../../components/Address/AddressForm";
import processResponseException from "../../utils/errors/processResponseException";
import {BadRequestException} from "../../rest";
import EligiblePersonForm from "../../components/EligiblePersons/EligiblePersonForm";
import read from "../../rest/read";
import {mapActions, mapMutations} from "vuex";

export default {
  components: {
    EligiblePersonForm,
    AddressForm,
    IncapacitationDegreeSelect,
    TextareaCounter,
    ErrorMessage,
    Recognitions,
  },
  mixins: [errorsMixin],
  props: {
    appointmentDocumentId: {type: String, required: true},
  },
  data() {
    return {
      appointmentId: null,
      patient: null,
      createLoading: false,
      hospitalName: "",
      hospitalAddress: {
        country: "PL",
      },
      patientAddress: {
        country: "PL",
      },
      patientConsent: null,
      eligiblePersonConsent: null,
      degreeOfIncapacitation: "",
      guardianVisible: false,
      guardian: {
        type: "guardian",
        address: {
          country: "PL",
        },
      },
      statutoryRepresentativeVisible: false,
      statutoryRepresentative: {
        type: "statutory_representative",
        address: {
          country: "PL",
        },
      },
      recognitions: [],
      otherInformation: "",
    };
  },
  computed: {
    appointmentDocumentPath() {
      return {
        name: "viewDocumentAppointment",
        params: {appointmentId: this.appointmentId},
        hash: `#appointmentDocumentId=${this.appointmentDocumentId}`,
      };
    },
  },
  watch: {
    errors() {
      if (!this.errors.length) {
        this.hideToast();
      }
    },
    async appointmentDocumentId() {
      await this.fetchAppointmentDocumentData();
    },
  },
  async mounted() {
    await this.fetchAppointmentDocumentData();
  },
  methods: {
    ...mapMutations("toastNotification", ["hideToast"]),
    ...mapActions("toastNotification", ["showToast"]),
    async fetchAppointmentDocumentData() {
      const {patient, appointmentId} = await read(`/api/referral/${this.appointmentDocumentId}/hospital/patient`);
      this.patient = patient;
      this.patientAddress = patient.address;
      this.appointmentId = appointmentId;
    },
    async issueReferral() {
      this.createLoading = true;
      const referralId = generateUuid();
      const referral = {
        referralId,
        appointmentDocumentId: this.appointmentDocumentId,
        hospitalName: this.hospitalName,
        patientId: this.patient.patientId,
        patientAddress: this.patientAddress,
        patientConsent: this.patientConsent,
        eligiblePersonConsent: this.eligiblePersonConsent,
        degreeOfIncapacitation: this.degreeOfIncapacitation,
        guardian: this.guardianVisible ? this.guardian : null,
        statutoryRepresentative: this.statutoryRepresentativeVisible ? this.statutoryRepresentative : null,
        recognitions: this.recognitions.map(recognition => ({
          comments: "",
          ...recognition,
          icd10Code: recognition.icd10Code.value,
        })),
        otherInformation: this.otherInformation.trim(),
      };

      if (this.hospitalAddress.city || this.hospitalAddress.zipCode || this.hospitalAddress.street) {
        referral.hospitalAddress = this.hospitalAddress;
      }

      try {
        this.hideToast();
        await create(`/api/referral-for-hospital/${this.appointmentDocumentId}`, referral);
        this.$emit("created", referralId);
        await this.$router.push(this.appointmentDocumentPath);

      } catch (e) {
        this.errors = processResponseException(e);
        if (e instanceof BadRequestException) {
          this.showToast({
            message: "Formularz zawiera błędy.\nUzupełnij dane, aby wystawić skierowanie.",
            variant: "danger",
          });
        } else {
          this.showToast({
            message: "Wystąpił błąd.\nZmiany nie zostały zapisane",
            variant: "danger",
          });
        }
      }
      this.createLoading = false;
    },
    getSubErrors(field) {
      return subErrors(this.errors, field);
    },
    clearError(field) {
      this.errors = this.errors.filter(error => error.field !== field);
    },
    clearPatientAddressError(field) {
      this.clearError(`patientAddress.${field}`);
      if (this.getSubErrors("patientAddress").length === 0) {
        this.clearError("patientAddress");
      }
    },
    clearHospitalAddressError(field) {
      this.clearError(`hospitalAddress.${field}`);
      if (this.getSubErrors("hospitalAddress").length === 0) {
        this.clearError("hospitalAddress");
      }
    },
    addPatientAddress(valueName) {
      this[valueName] = {...this[valueName], address: this.patientAddress};
    }
  },
}
</script>
