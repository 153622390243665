




















































































































































import ErrorsMixin from "../../mixins/ErrorsMixin";
import {Component, Mixins, Prop} from "vue-property-decorator";
import ErrorMessage from "../Form/ErrorMessage.vue";
import processResponseException from "../../utils/errors/processResponseException";
import FileUpload from "../File/FileUpload.vue";
import read from "../../rest/read";
import FileSelect from "../File/FileSelect.vue";
import CertificateFileSelect from "./CertificateFileSelect.vue";
import postDataAndFiles from "../../rest/postDataAndFiles";

interface Overwrite {
  file: File;
  type: CertificateType;
}

interface Data {
  hasPkcs12WssFile: boolean;
  hasTlsPemFile: boolean;
  hasTlsPKeyFile: boolean;
  hasPkcs12WssPassword: boolean;
}

enum CertificateType {
  WSS = "pkcs12Wss",
  TLS = "tlsPem",
  TLS_KEY = "tlsPKey",
}

const MAX_FILE_SIZE_MB = 1;
const MAX_FILE_SIZE = {
  megaBytes: MAX_FILE_SIZE_MB,
  bytes: MAX_FILE_SIZE_MB * 1024 * 1024,
};

@Component({
  name: "EstablishmentCertificatesModal",
  components: {CertificateFileSelect, FileSelect, FileUpload, ErrorMessage}
})
export default class EstablishmentCertificatesModal extends Mixins(ErrorsMixin) {
  @Prop({type: String, required: true}) readonly branchId!: string;
  @Prop({type: String, required: true}) readonly branchName!: string;

  CertificateType = CertificateType;
  loading: boolean = false;

  pkcs12WssPassword: string = "";
  pkcs12WssFile: File|null = null;
  tlsPemFile: File|null = null;
  tlsPKeyFile: File|null = null;
  hasPkcs12WssPassword: boolean = false;
  hasPkcs12WssFile: boolean = false;
  hasTlsPemFile: boolean = false;
  hasTlsPKeyFile: boolean = false;
  pkcs12WssSizeError: string = "";
  tlsPemSizeError: string = "";
  tlsPKeySizeError: string = "";

  get modalId(): string {
    return `establishment-certificates-${this.branchId}`;
  }

  get title(): string {
    return `Certyfikaty podmiotu ${this.branchName}`
  }

  get incompleteData(): boolean {
    return !(this.pkcs12WssPassword && this.pkcs12WssFile && this.tlsPemFile && this.tlsPKeyFile);
  }

  get hasSizeErrors(): boolean {
    return !!(this.pkcs12WssSizeError || this.tlsPemSizeError || this.tlsPKeySizeError);
  }

  get disabled(): boolean {
    return this.incompleteData || this.hasSizeErrors;
  }

  addFile(file: File, type: CertificateType): void {
    this[`${type}File`] = file;

    this[`${type}SizeError`] = "";
    if (file.size > (MAX_FILE_SIZE.bytes)) {
      this[`${type}SizeError`] = `Plik ${file.name} jest zbyt duży.
        Maksymalna wielkość pliku to ${MAX_FILE_SIZE.megaBytes}MB`;
    }
  }

  removeFile(type: CertificateType): void {
    this[`${type}SizeError`] = "";
    this[`${type}File`] = null;
  }

  closeModal(): void {
    this.$bvModal.hide(this.modalId);
  }

  async submit(): Promise<void> {
    this.errors = [];
    this.loading = true;

    const data = new FormData();
    data.append("pkcs12WssPassword", this.pkcs12WssPassword);
    data.append("pkcs12WssFile", this.pkcs12WssFile as Blob || undefined);
    data.append("tlsPemFile", this.tlsPemFile as Blob || undefined);
    data.append("tlsPKeyFile", this.tlsPKeyFile as Blob || undefined);
    try {
      await postDataAndFiles(`/api/branches/${this.branchId}/p1-certificates`, data);
      this.closeModal();
    } catch(e) {
      this.errors = processResponseException(e);
    }

    this.loading = false;
  }

  private resetData() {
    this.errors = [];
    this.pkcs12WssPassword = "";
    this.pkcs12WssFile = null;
    this.tlsPemFile = null;
    this.tlsPKeyFile = null;
  }

  async fetchData(): Promise<void> {
    this.loading = true;
    this.resetData();
    try {
      const {
        hasPkcs12WssFile,
        hasTlsPemFile,
        hasTlsPKeyFile,
        hasPkcs12WssPassword,
      } = await read<Data>(`/api/branches/${this.branchId}/p1-certificates-status`);

      this.hasPkcs12WssFile = hasPkcs12WssFile;
      this.hasTlsPemFile = hasTlsPemFile;
      this.hasTlsPKeyFile = hasTlsPKeyFile;
      this.hasPkcs12WssPassword = hasPkcs12WssPassword;

      this.loading = false;

    } catch (e) {
      this.errors = processResponseException(e);
    }
  }
}
