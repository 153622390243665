<template>
  <transition name="fade">
    <div
      class="border-top mt-1 pt-2 pb-3 row"
      style="border-color: #4c4c4c"
    >
      <div class="col-md-6 col-lg-4">
        <label
          for="appointment-duration"
          class="col-form-label"
        >
          Czas trwania wizyty
        </label>
        <appointment-duration-select
          id="appointment-duration"
          :default-duration="defaultDuration"
          :value="value"
          @input="update"
        />
      </div>
    </div>
  </transition>
</template>

<script>
import AppointmentDurationSelect from "./AppointmentDurationSelect";

export default {
  components: {AppointmentDurationSelect},
  props: {
    value: {type: Number, default: null},
    defaultDuration: {type: Number, default: null},
  },
  methods: {
    update(duration) {
      this.$emit("input", duration);
    },
  },
}
</script>
