<template>
  <span>{{ maritalStatusType }}</span>
</template>

<script>
import t from "../../i18n";

export default {

  props: {
    status: {type: String, required: true},
  },

  computed: {
    maritalStatusType() {
      return null === this.status ? null : t(`@marital-status.${this.status}`);
    }
  },
}
</script>
