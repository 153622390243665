<template>
  <b-form-group
    id="multiple-presence"
    label-for="multiple-presence-field"
  >
    <template #description>
      <!-- eslint-disable vue/no-v-html-->
      <span v-html="description" />
      <!-- eslint-enable-->
    </template>
    <template #label>
      <span class="col-form-label">
        Ogranicz wizyty do
      </span>
    </template>
    <b-form-checkbox-group
      id="multiple-presence-field"
      v-model="presenceOptions"
      :options="options"
      buttons
      @input="input"
    />
  </b-form-group>
</template>

<script>
export default {
  name: "MultipleSpecialistsAndPatientsOptionsCheckbox",
  props: {
    value: {type: Array, default:() => []},
  },
  data() {
    return {
      presenceOptions: this.value
    }
  },
  computed: {
    description() {
      return this.options
        .reduce((descriptions, option) => {
          if (this.presenceOptions.includes(option.value)) {
            descriptions = [...descriptions, option.label];
          }
          return descriptions;
        }, [])
        .join(",<br>");
    },
    options() {
      return [
        {
          html: "<i class=\"fas fa-users\" title=\"Pokaż tylko z wieloma pacjentami\"></i>",
          value: "multiplePatientPresence",
          label: "Pokaż tylko z wieloma pacjentami",
        },
        {
          html: "<i class=\"fas fa-handshake\" title=\"Pokaż tylko z wieloma specjalistami\"></i>",
          value: "multipleSpecialistsPresence",
          label: "Pokaż tylko z wieloma specjalistami",
        },
      ];
    },
  },
  watch: {
    value() {
      this.presenceOptions = this.value;
    }
  },
  methods: {
    input($event) {
      this.$emit("input", $event);
    },
  },
}
</script>

<style scoped>

</style>
