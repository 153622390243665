<template>
  <div>
    <CaseManager :patient-id="patientId" />
    <is-granted
      :privileges="['GET_PATIENT_CASE_MANAGER_OPS']"
      component="fragment"
    >
      <CaseManagerOps :patient-id="patientId" />
    </is-granted>
    <is-granted
      :privileges="['GET_DAILY_CASE_MANAGER']"
      component="fragment"
    >
      <CaseManagerDaily
        :patient-id="patientId"
      />
    </is-granted>
  </div>
</template>

<script>
import CaseManager from "./CaseManager/CaseManager";
import CaseManagerOps from "./CaseManager/CaseManagerOps";
import IsGranted from "../../IsGranted";
import CaseManagerDaily from "@/components/Patient/PatientCard/CaseManager/CaseManagerDaily";

export default {
  name: "PatientCardCaseManager",
  components: {
    CaseManager,
    CaseManagerOps,
    IsGranted,
    CaseManagerDaily
  },
  props: {
    patientId: {type: String, required: true},
  }
};
</script>
