<template>
  <vue-multi-select
    ref="select"
    v-tabindex
    :internal-search="false"
    :value="value"
    :class="{invalid: state === false}"
    :options="options"
    :loading="loading"
    :close-on-select="false"
    :allow-empty="false"
    :show-no-results="false"
    deselect-label=""
    hide-selected
    preserve-search
    tag-placeholder=""
    select-label="Wybierz"
    label="label"
    track-by="id"
    placeholder=""
    @open="presetPhrase"
    @input="select"
    @search-change="searchChange"
  >
    <template #caret>
      <span />
    </template>
    <template #noOptions>
      <span />
    </template>
  </vue-multi-select>
</template>

<script>
import VueMultiSelect from "vue-multiselect";
import rest from "../../rest";

export default {
  components: {VueMultiSelect},
  directives: {
    tabindex: {
      inserted(el) {
        el.setAttribute("tabindex", 0);
      },
    },
  },
  props: {
    state: {type: Boolean, default: null},
    city: {type: String, default: ""},
    street: {type: String, default: ""},
  },
  data() {
    return {
      loading: false,
      typingTimeout: null,
      options: [],
    };
  },
  computed: {
    value() {
      return this.street ? {
        label: this.street,
      } : null;
    },
  },
  watch: {
    city() {
      this.options = [];
    },
    street() {
      this.options = [];
    },
  },
  methods: {
    searchChange(phrase) {
      this.$emit("address", {street: phrase});
      this.loadOptions(phrase);
    },
    async loadOptions(phrase) {
      if (!phrase || phrase.length <= 3) {
        return;
      }
      clearTimeout(this.typingTimeout);
      this.typingTimeout = setTimeout(async () => {
        this.loading = true;
        try {
          this.options = await this.fetchData([phrase, this.city].filter((p) => p).join(" "));
          this.options = this.options.filter(({street, city}) => street !== this.street || city !== this.city);
          this.options.push({
            id: phrase,
            label: phrase,
            street: phrase,
          });
        } catch (e) {
          console.error(e);
        }
        this.loading = false;
      }, 500);
    },
    async fetchData(phrase) {
      const include = ["street", "city", "commune", "province"];
      const params = {phrase, include: include.join(",")};
      const {data, included} = await rest.read("/api/addresses", params);

      const resolveIncluded = (relation) => included.find(({type, id}) => type === relation.type && id === relation.id);

      return data.map(({id, attributes,relationships}) => {
        const {apartmentNumber, buildingNumber} = attributes;
        const street = resolveIncluded(relationships.street.data).attributes;
        const city = resolveIncluded(relationships.city.data).attributes;
        const commune = resolveIncluded(relationships.commune.data).attributes;
        const province = resolveIncluded(relationships.province.data).attributes;

        const suffix = [buildingNumber, apartmentNumber].filter(noEmpty).join("/");
        const value = [street.prefix, street.name,suffix].filter(noEmpty).join(" ");
        const label = `${value}, ${city.name} (${commune.type} ${commune.name}, ${province.type} ${province.name})`;

        return Object.freeze({
          id,
          label,
          street: value,
          city: city.name
        });
      });
    },
    presetPhrase() {
      this.$refs.select.search = this.street;
    },
    select(option) {
      if (!option) {
        this.presetPhrase();
        return;
      }
      const {city, street, suffix} = option;
      const value = [street, suffix].filter((a) => a).join(" ");
      this.emit(city ? {city, street: value.trim()} : {street: value.trim()});
    },
    emit(address){
      this.$emit("address", address);
      address.hasOwnProperty("city") && this.$emit("city", address.city);
      address.hasOwnProperty("street") && this.$emit("street", address.street);
      setTimeout(() => this.$refs.select.search = address.street, 0);
    }
  },
};

const noEmpty = a => a;
</script>
