<template>
  <b-modal
    id="changeContinuousStayDatesModal"
    no-close-on-backdrop
    title="Zmień daty pobytu ciągłego"
    title-tag="h3"
    @hide="clearModal"
  >
    <b-form-group
      label="Data rozpoczęcia pobytu"
      label-for="startDate"
      class="col-md-12 col-xl-12"
    >
      <date-picker
        v-model="startDate"
        :state="state('startDate')"
        :minute-step="15"
        format="YYYY-MM-DD HH:mm"
        type="datetime"
        placeholder="Wybierz datę"
      />
      <error-message
        :errors="errors"
        field="startDate"
      />
    </b-form-group>

    <b-form-group
      label="Data zakończenia pobytu"
      label-for="endDate"
      class="col-md-12 col-xl-12"
    >
      <date-picker
        v-model="endDate"
        :state="state('endDate')"
        :minute-step="15"
        :clearable="true"
        format="YYYY-MM-DD HH:mm"
        type="datetime"
        placeholder="Wybierz datę"
      />
      <error-message
        :errors="errors"
        field="endDate"
      />
    </b-form-group>
    <error-message
      :errors="errors"
      field="appointmentDates"
    />
    <template #modal-footer>
      <button
        type="button"
        class="btn btn-secondary"
        @click="hideModal"
      >
        Anuluj
      </button>
      <button
        :disabled="loading"
        type="submit"
        class="btn btn-primary"
        @click="submit"
      >
        <i
          :class="loading ? 'fa-spin fa-spinner' : 'fa-check'"
          class="fa"
        />
        Zapisz
      </button>
    </template>
  </b-modal>
</template>

<script>
import DatePicker from "../../../Form/DatePicker/DatePicker";
import ErrorMessage from "../../../Form/ErrorMessage";
import stringifyDate from "../../../../utils/date/stringifyDate";
import update from "../../../../rest/update";
import processResponseException from "../../../../utils/errors/processResponseException";
import {errorsMixin} from "../../../../mixins/errorsMixin";
import DATE_FORMAT from "../../../../utils/date/DATE_FORMAT";
import parseDate from "../../../../utils/date/parseDate";

export default {
  name: "ChangeContinuousStayDatesModal",
  components: {DatePicker, ErrorMessage},
  mixins: [errorsMixin],
  props: {
    patientId: {type: String, default: null},
    continuousStayId: {type: String, required: true},
    start: {type: String, default: null},
    end: {type: String, default: null},
  },
  data() {
    return {
      startDate: this.start ? parseDate(this.start, DATE_FORMAT.DATE_TIME_SEC) : null,
      endDate: this.end ? parseDate(this.end, DATE_FORMAT.DATE_TIME_SEC) : null,
      loading: false,
    };
  },
  watch: {
    start(newVal) {
      this.startDate = newVal ? parseDate(newVal, DATE_FORMAT.DATE_TIME_SEC) : null;
    },
    end(newVal) {
      this.endDate = newVal ? parseDate(newVal, DATE_FORMAT.DATE_TIME_SEC) : null;
    },
  },
  methods: {
    hideModal() {
      this.$bvModal.hide("changeContinuousStayDatesModal");
      this.errors = [];
    },
    async submit() {
      this.loading = true;
      const startDate = this.startDate ? stringifyDate(this.startDate) : null;
      const endDate = this.endDate ? stringifyDate(this.endDate) : null;

      try {
        await update(`/api/continuous-stays/${this.continuousStayId}/change-dates`, {
          startDate,
          endDate,
        });
        this.$emit("closed");
        this.hideModal();
      } catch (e) {
        this.errors = processResponseException(e);
      }

      this.loading = false;
    },
    clearModal() {
      this.startDate = this.start ? parseDate(this.start, DATE_FORMAT.DATE_TIME_SEC) : null;
      this.endDate = this.end ? parseDate(this.end, DATE_FORMAT.DATE_TIME_SEC) : null;
      this.errors = [];
    }
  }
}
</script>
