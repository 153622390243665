<template>
  <report-container report-id="ikup_children">
    <div class="card-box row">
      <b-form-group
        class="col-sm-6"
        label="Pacjent"
      >
        <patient-select
          v-model="selectedPatient"
        />
      </b-form-group>
      <b-form-group
        class="col-sm-6"
        label="Obecność pacjenta (opcjonalnie)"
      >
        <boolean-refusal-select
          v-model="patientPresence"
          :only-boolean="true"
        />
      </b-form-group>
      <b-form-group
        class="col-sm-6"
        label="Uwzględnić wizyty odwołane"
      >
        <boolean-refusal-select
          v-model="canceledAppointments"
          :only-boolean="true"
        />
      </b-form-group>
      <b-form-group
        class="col-sm-6"
        label="Kategoria usług"
      >
        <request-i-k-u-p-treatment-category
          v-model="treatmentTypeCategory"
        />
      </b-form-group>
      <div class="col-sm-6 p-t-10">
        <b-form-group
          label="Data początkowa"
        >
          <date-picker
            v-model="startDate"
            :state="state('startDate')"
            clearable
            @input="validateReportDates"
          />
          <error-message
            :errors="errors"
            field="startDate"
          />
        </b-form-group>
      </div>
      <div class="col-sm-6 p-t-10">
        <b-form-group
          label="Data końcowa"
        >
          <date-picker
            v-model="endDate"
            :state="state('endDate')"
            clearable
            @input="validateReportDates"
          />
          <error-message
            :errors="errors"
            field="endDate"
          />
        </b-form-group>
      </div>
      <b-alert
        class="col-12"
        :show="true"
        variant="primary"
      >
        Uwaga - Zmieniona została reguła raportowania wizyt wielospecjalistycznych z tą samą usługą
        - takie wizyty są prezentowane jako jeden wiersz.
      </b-alert>
      <div class="col text-right">
        <back-button :to="{name: 'reportsMainView'}" />
        <a
          v-if="selectedPatient && !errors.length"
          :href="reportUrl"
          class="btn btn-primary m-1 m-b-0"
        >
          <i class="fa fas fa-file-excel" />
          Generuj
        </a>
        <button
          v-else
          disabled
          class="btn btn-primary m-1"
        >
          <i class="fa fas fa-file-excel" />
          Generuj
        </button>
      </div>
    </div>
  </report-container>
</template>

<script>
import PatientSelect from "../../../components/Patient/PatientSelect";
import BooleanRefusalSelect from "../../../components/Form/Select/BooleanRefusalSelect";
import DatePicker from "../../../components/Form/DatePicker/DatePicker";
import stringifyDate from "../../../utils/date/stringifyDate";
import {createUrl} from "../../../utils/pageUrl/createUrl";
import {errorsMixin} from "../../../mixins/errorsMixin.js";
import ErrorMessage from "../../../components/Form/ErrorMessage";
import BackButton from "../../../components/BackButton";
import ReportContainer from "../../../components/Report/ReportContainer";
import RequestIKUPTreatmentCategory from "@/components/Form/Select/RequestIKUPTreatmentCategory";
export default {
  components: {
    RequestIKUPTreatmentCategory, ReportContainer, BackButton, ErrorMessage, PatientSelect,
    BooleanRefusalSelect, DatePicker
  },
  mixins: [errorsMixin],
  data() {
    return {
      selectedPatient: null,
      patientPresence: null,
      canceledAppointments: false,
      startDate: null,
      endDate: null,
      treatmentTypeCategory: "all"
    };
  },
  computed: {
    parsedStartDate() {
      const date = new Date(this.startDate);
      date.setHours(0, 0, 0, 0);
      return this.startDate ? date : null;
    },
    parsedEndDate() {
      const date = new Date(this.endDate);
      date.setHours(23, 59, 59);
      return this.endDate ? date : null;
    },
    reportUrl() {
      let baseUrl = "/api/reports/ikup-children/";
      baseUrl = `${baseUrl}${this.selectedPatient ? this.selectedPatient.patientId : ""}`;
      return createUrl(baseUrl, this.filters);
    },
    filters() {
      return {
        startDate: this.parsedStartDate ? stringifyDate(this.parsedStartDate) : null,
        endDate: this.parsedEndDate ? stringifyDate(this.parsedEndDate) : null,
        canceledAppointments: this.canceledAppointments,
        patientPresence: this.patientPresence,
        treatmentTypeCategory: this.treatmentTypeCategory
      }
    },
  },
  methods: {
    validateReportDates() {
      if (this.parsedStartDate && this.parsedEndDate
                && this.parsedStartDate.getTime() >= this.parsedEndDate.getTime()) {
        this.errors = [
          {
            message: "Data początkowa powinna być wcześniejsza niż data końcowa",
            field: "startDate"
          },
          {
            message: "Data końcowa powinna być późniejsza niż data początkowa",
            field: "endDate"
          },
        ];
        return;
      }
      this.errors = [];
    },
  },
}
</script>
