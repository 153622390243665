































import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";
import {InternalReferralStatus} from "../../types/InternalReferral";
import t from "../../i18n";
import ObjectSelect from "../Form/Select/ObjectSelect.vue";
import type {Option} from "../../types/MultiSelectTypes";

@Component({
  name: "InternalReferralStatusSelect",
  components: {ObjectSelect}
})
export default class InternalReferralStatusSelect extends Vue {
  @Prop({type: String, default: null}) readonly value!: string;
  @Prop({type: Boolean, default: true}) readonly clearable!: boolean;
  @Prop({type: Boolean, default: false}) readonly disabled!: boolean;
  @Prop({type: String, default: "Wybierz status"}) readonly placeholder!: string;
  @Prop({type: Boolean, default: null}) readonly state!: boolean;

  get options(): Option[] {
    return Object.values(InternalReferralStatus).reduce<Option[]>((list, value) => {
      if (value !== InternalReferralStatus.CANCELED) {
        list.push({
          value,
          name: t(`@internal-referral-status.${value}`),
        });
      }
      return list;
    }, []);
  }

  get selectedValue(): Option|null {
    return this.options.find(option => option.value === this.value) || null;
  }

  private update(option: Option): void {
    this.$emit("input", option?.value || null);
  }

  statusClass(status: InternalReferralStatus): string {
    switch (status) {
      case InternalReferralStatus.DRAFT:
        return "text-warning";
      case InternalReferralStatus.ACCEPTANCE:
        return "text-success";
      case InternalReferralStatus.ACCEPTED:
        return "text-primary";
      case InternalReferralStatus.REJECTED:
        return "text-danger";
      default:
        return "text-secondary";
    }
  }
}
