<template>
  <report-container
    report-id="continuous_stay"
  >
    <div class="row card-box">
      <b-form-group
        class="col-lg-6 col-xl-3"
        label="Data od"
        label-for="start-date"
      >
        <date-picker
          id="start-date"
          v-model="startDate"
          :disabled-date="disabledStartDateDays"
        />
      </b-form-group>

      <b-form-group
        class="col-lg-6 col-xl-3"
        label="Data do"
        label-for="end-date"
      >
        <date-picker
          id="end-date"
          v-model="endDate"
          :disabled-date="disabledEndDateDays"
        />
      </b-form-group>

      <div class="col text-right no-label">
        <back-button :to="{name: 'reportsMainView'}" />

        <a
          :href="reportUrl"
          class="btn btn-primary m-1"
        >
          <i class="fas fa-file-excel" />
          Generuj
        </a>
      </div>
    </div>
  </report-container>
</template>

<script>
import DatePicker from "../../components/Form/DatePicker/DatePicker";
import stringifyDate from "../../utils/date/stringifyDate";
import DATE_FORMAT from "../../utils/date/DATE_FORMAT";
import {createUrl} from "../../utils/pageUrl/createUrl";
import BackButton from "../../components/BackButton";
import ReportContainer from "../../components/Report/ReportContainer";

export default {
  name: "ContinuousStayReport",
  components: {ReportContainer, BackButton, DatePicker},

  data() {
    const startDate = new Date();
    startDate.setDate(1);
    startDate.setHours(0,0,0,0);
    const endDate = new Date(startDate.getTime());
    endDate.setMonth(endDate.getMonth() + 1);
    endDate.setDate(endDate.getDate() - 1);
    endDate.setHours(23,59,59);
    return {
      startDate,
      endDate,
    };
  },

  computed: {
    startDateString() {
      return this.startDate ? stringifyDate(this.startDate, DATE_FORMAT.DATE) : null;
    },
    endDateString() {
      return this.endDate ? stringifyDate(this.endDate, DATE_FORMAT.DATE) : null;
    },
    reportUrl() {
      if (!this.startDateString || !this.endDateString) {
        return null;
      }
      return createUrl("/api/reports/continuous-stay", {
        startDate: this.startDateString,
        endDate: this.endDateString,
      });
    },
  },

  methods: {
    disabledEndDateDays(endDate) {
      return (this.startDate.getTime() - endDate.getTime() > 1000);
    },
    disabledStartDateDays(start) {
      return (start.getTime() - this.endDate.getTime() > 1000);
    },
  },
}
</script>
