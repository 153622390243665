<template>
  <div>
    <b-dropdown
      v-if="bothPermissions"
      class="no-caret"
      variant="success"
    >
      <template #button-content>
        <i class="fa fa-plus" />
        <span>&nbsp;Zarejestruj pacjenta</span>
      </template>
      <b-dropdown-item :to="{name: 'createPatientView'}">
        Zarejestruj pełnoletniego pacjenta
      </b-dropdown-item>
      <b-dropdown-item
        :to="{name: 'child_patient_create_view'}"
        component="b-dropdown-item"
      >
        Zarejestruj niepełnoletniego pacjenta
      </b-dropdown-item>
    </b-dropdown>
    <router-link
      v-else-if="patientCreateAllowed"
      class="btn btn-success"
      :to="{name: 'createPatientView'}"
    >
      <i class="fa fa-plus" />
      <span>&nbsp;Zarejestruj pacjenta</span>
    </router-link>
    <router-link
      v-else-if="childPatientCreateAllowed"
      class="btn btn-success"
      :to="{name: 'child_patient_create_view'}"
    >
      <i class="fa fa-plus" />
      <span>&nbsp;Zarejestruj pacjenta</span>
    </router-link>
  </div>
</template>

<script>
import {registerPatientButtonMixin} from "../../../mixins/registerPatientButtonMixin";

export default {
  name: "RegisterPatientButton",
  mixins: [registerPatientButtonMixin],
}
</script>
