<template>
  <object-select
    :options="options"
    :value="selectedValue"
    :placeholder="placeholder"
    :state="state"
    :disabled="disabled"
    @input="update"
  />
</template>

<script>
import ObjectSelect from "./ObjectSelect";
import t from "../../../i18n";

export default {
  components: {ObjectSelect},
  props: {
    value: {type: [String, Object], default: null},
    state: ObjectSelect.props.state, // eslint-disable-line
    placeholder: {type: String, default: "Wybierz wykonywany zawód"},
    disabled: {type: Boolean, default: false},
  },
  data() {
    return {
      optionValues: [
        {value: "others"},
        {value: "practical_training_intructor"},
        {value: "preschool_education_teacher"},
        {value: "vocational_education_teacher"},
        {value: "health_protection_support_institution_employee"},
        {value: "social_assistance_and_integration_institution_key_employee"},
        {value: "higher_education_institution_employee"},
        {value: "family_support_and_foster_case_system_institution_employee"},
        {value: "social_economy_support_center_employee"},
        {value: "psychological_and_pedagogical_counselor"},
        {value: "farmer"},
      ],
    };
  },
  computed: {
    options() {
      return this.optionValues.map(option => ({
        ...option,
        name: t(`@profession.${option.value}`),
      }));
    },
    selectedValue() {
      if (!this.value) {
        return null
      } else {
        return this.options.find((option) => option.value === this.value);
      }
    },
  },
  methods: {
    update(option) {
      this.$emit("input", option ? option.value : null);
    },
  },
}
</script>
