<template>
  <b-modal
    id="request-medical-record-modal"
    size="xs"
    no-close-on-backdrop
    title="Pobierz dokumentację medyczną"
    title-tag="h3"
  >
    <template #default>
      <div class="text-center">
        <h5 class="font-weight-bold">
          Hasło do archiwum
        </h5>
        <div class="password-wrap border">
          <span>
            {{ password }}
          </span>
        </div>
      </div>
    </template>
    <template #modal-footer>
      <a
        :href="`/api/files/${id}`"
        type="button"
        class="btn btn-success"
        @click="closeModal"
      >
        Pobierz archiwum
      </a>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: "RequestMedicalRecordModal",
  props: {
    id: {type: String, required: true},
    password: {type: String, default: null},
  },
  methods: {
    closeModal() {
      this.$bvModal.hide("request-medical-record-modal");
    },
  },
};
</script>

<style scoped lang="scss">
  .password-wrap {
    width: 50%;
    margin: 15px auto;
    padding: 10px;

    & span {
      font-size: 1.5rem;
      color: #008000;
    }
  }
</style>
