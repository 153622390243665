












































import ErrorsMixin from "../../mixins/ErrorsMixin";
import {Component, Mixins, Prop} from "vue-property-decorator";
import modify from "../../rest/modify";
import processResponseException from "../../utils/errors/processResponseException";
import {InternalReferral, InternalReferralFormData, InternalReferralStatus} from "../../types/InternalReferral";
import ErrorMessage from "../Form/ErrorMessage.vue";
import t from "../../i18n";
import LoadingMask from "../Loading/LoadingMask.vue";
import InternalReferralForm from "./InternalReferralForm.vue";
import read from "../../rest/read";

@Component({
  name: "CancelInternalReferralModal",
  components: {InternalReferralForm, LoadingMask, ErrorMessage},
})
export default class CancelInternalReferralModal extends Mixins(ErrorsMixin) {
  @Prop({type: String, required: true}) readonly internalReferralId!: string;
  t = t;
  InternalReferralStatus = InternalReferralStatus;
  private loading: boolean = false;
  private saving: boolean = false;
  private data: InternalReferralFormData = {};
  private patientDisplayName: string|null = null;
  private files: File[] = [];

  get modalId(): string {
    return `cancel-internal-referral-${this.internalReferralId}`;
  }

  private async fetchData(): Promise<void> {
    this.loading = true;
    this.errors = [];
    try {
      const {
        patientId,
        displayName,
        referringEstablishment,
        receivingEstablishment,
        description,
        rejectReason,
        author,
        files
      } = await read<InternalReferral>(`/api/internal-referrals/${this.internalReferralId}`);
      this.files = files;
      this.data = {
        patient: patientId,
        referringEstablishment: referringEstablishment,
        receivingEstablishment: receivingEstablishment,
        description,
        rejectReason,
        author,
        files: files.map(file => file.fileId)
      }
      this.patientDisplayName = displayName;
    } catch (e) {
      this.errors = processResponseException(e);
    }
    this.loading = false;
  }

  private hideModal(): void {
    this.$bvModal.hide(this.modalId);
  }

  private async cancelReferral(): Promise<void> {
    this.saving = true;
    this.errors = []
    try {
      await modify(`/api/internal-referrals/${this.internalReferralId}/cancel`, {
        files: this.data.files
      });
      this.$emit("updated");
      this.hideModal();
    } catch(e) {
      this.errors = processResponseException(e);
    }
    this.saving = false;
  }

  private resetData(): void {
    this.errors = [];
    this.saving = false;
  }
}
