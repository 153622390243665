<template>
  <b-modal
    :id="modalId"
    :title="title"
    no-close-on-backdrop
    title-tag="h3"
    @hide="resetData"
    @show="getAppointmentData"
  >
    <loading-mask :loading="loading">
      <error-message
        :errors="errors"
        class="m-t-20"
      />
      <template v-if="changeDateAllowed">
        <b-form-group label="Data wizyty">
          <date-picker
            v-model="startDate"
          />
        </b-form-group>
        <b-form-group label="Godzina rozpoczęcia">
          <hour-select
            v-model="startTime"
          />
        </b-form-group>
        <b-form-group label="Zarezerwowany czas trwania wizyty">
          <appointment-duration-select
            v-model="duration"
            :default-duration="defaultDuration"
          />
        </b-form-group>
        <error-message
          :errors="errors"
          field="startDate"
        />
        <error-message
          :errors="errors"
          field="endDate"
        />
      </template>
      <template v-if="changeTimeSpentAllowed">
        <b-form-group label="Faktyczny czas trwania wizyty">
          <div
            v-for="(document, index) in patientFinishedDocuments"
            :key="document.appointmentDocumentId"
          >
            <appointment-document-time-spent
              v-bind="document"
              :state="state(`documents[${index}].timeSpent`)"
              @updateTime="updateTime($event, document.appointmentDocumentId)"
            />
            <error-message
              :errors="errors"
              :field="`documents[${index}].timeSpent`"
            />
          </div>
        </b-form-group>
      </template>
    </loading-mask>
    <template #modal-footer>
      <button
        type="button"
        class="btn btn-secondary"
        @click="closeModal"
      >
        Anuluj
      </button>
      <button
        :disabled="loading"
        type="button"
        class="btn btn-primary"
        @click="save"
      >
        <i
          :class="loading ? 'fa-spin fa-spinner' : 'fa-check'"
          class="fa"
        />
        Zapisz
      </button>
    </template>
  </b-modal>
</template>

<script>
import LoadingMask from "../../Loading/LoadingMask";
import ErrorMessage from "../../Form/ErrorMessage";
import processResponseException from "../../../utils/errors/processResponseException";
import read from "../../../rest/read";
import stringifyDate from "../../../utils/date/stringifyDate";
import parseDate from "../../../utils/date/parseDate";
import DatePicker from "../../Form/DatePicker/DatePicker";
import HourSelect from "../../Form/Select/HourSelect";
import AppointmentDurationSelect from "../../Calendar/AppointmentDurationSelect";
import modify from "../../../rest/modify";
import AppointmentDocumentTimeSpent from "../AppointmentDocumentTimeSpent";
import {errorsMixin} from "../../../mixins/errorsMixin.js";

export default {
  name: "ChangeAppointmentDate",
  components: {
    AppointmentDocumentTimeSpent,
    AppointmentDurationSelect, HourSelect, DatePicker, ErrorMessage, LoadingMask},
  mixins: [errorsMixin],
  props: {
    appointmentId: {required: true, type: String},
    title: {required: true, type: String},
    status: {required: true, type: String},
    patientId: {required: true, type: String},
  },
  data() {
    return {
      loading: false,
      errors: [],
      defaultDuration: 0,
      duration: null,
      dateString: null,
      startDate: null,
      startTime: null,
      documents: [],
      documentsData: [],
    };
  },
  computed: {
    modalId() {
      return `changeAppointmentDate-${this.appointmentId}`;
    },
    changeDateAllowed() {
      return this.status ===  "finished" || this.status === "canceled";
    },
    changeTimeSpentAllowed() {
      return this.status === "finished";
    },
    patientFinishedDocuments() {
      return this.documents.filter(document =>
        document.patient.patientId === this.patientId
        && document.status === "finished"
      )
    },
  },
  watch: {
    dateString() {
      const startDate = this.dateString ? parseDate(this.dateString) : null;
      this.startDate = startDate;
      this.startTime = startDate
        ? {
          HH: this.stringifyNumberWithLeadingZero(startDate.getHours()),
          mm: this.stringifyNumberWithLeadingZero(startDate.getMinutes()),
        }
        : null;
    },
    defaultDuration() {
      this.duration = this.defaultDuration;
    },
    patientFinishedDocuments() {
      this.documentsData = this.patientFinishedDocuments
        .map(document => ({
          documentId: document.appointmentDocumentId,
          timeSpent: document.timeSpent,
        }))
    },
  },
  methods: {
    async getAppointmentData() {
      this.loading = true;
      try {
        await this.getAppointmentDates();
        await this.getAppointmentTimeSpent();
      } catch(e) {
        this.errors = processResponseException(e);
      }
      this.loading = false;
    },
    async getAppointmentDates() {
      if (this.changeDateAllowed) {
        const data = await read(`/api/appointments/${this.appointmentId}`);
        this.dateString = data.date;
        this.defaultDuration = data.duration;
        this.duration = data.duration;
      }
    },
    async getAppointmentTimeSpent() {
      if (this.changeTimeSpentAllowed) {
        const {documents} = await read(`/api/appointment/${this.appointmentId}/appointment`);
        this.documents = documents;
      }
    },
    resetData() {
      this.dateString = null;
      this.defaultDuration = 0;
      this.documents = [];
      this.errors = [];
    },
    closeModal() {
      this.$bvModal.hide(this.modalId);
      this.resetData();
    },
    async save() {
      this.loading = true;
      try {
        const data = {appointmentId: this.appointmentId};

        if (this.changeDateAllowed) {
          const startDate = new Date(this.startDate);
          startDate.setHours(this.startTime.HH);
          startDate.setMinutes(this.startTime.mm);


          data.startDate = stringifyDate(startDate);
          data.duration = this.duration;
          data.documents = [];
        }
        if (this.changeTimeSpentAllowed) {
          data.documents = this.documentsData;
        }
        await modify(`/api/appointment/${this.appointmentId}`, data);
        this.closeModal();
        this.$emit("appointmentUpdated");
      } catch (e) {
        this.errors = processResponseException(e);
      }
      this.loading = false;
    },
    stringifyNumberWithLeadingZero(num) {
      return (`00${  num}`).slice(-2);
    },
    updateTime(timeSpent, documentId) {
      const document = this.documentsData.find(data => data.documentId === documentId);
      document.timeSpent = timeSpent;
    },
  },
}
</script>
