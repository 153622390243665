<template>
  <card
    :loading="isLoading"
    title="Zgody pacjenta"
  >
    <template #control>
      <is-granted
        :privileges="['UPDATE_PATIENT_PERMISSIONS']"
        :subject="patient.patientId"
        component="div"
      >
        <b-btn
          v-b-modal.update-permissions
          :disabled="isLoading"
          variant="primary"
          class="btn-sm"
        >
          <i class="fa fa-pencil-alt" />
        </b-btn>
        <patient-card-update-permissions
          :permissions="permissions"
          :patient="patient"
          :disabled="disabledPermissions"
          :patient-type="permissions.patientType"
          @fetchPermissions="getPatientPermissions"
        />
      </is-granted>
    </template>
    <template #default>
      <div
        v-for="(patientPermission, index) in patientPermissionsList"
        :key="index"
      >
        <p
          v-if="null != permissions[patientPermission.propertyName]"
        >
          {{ patientPermission.displayLabel }}
          {{ permissions[patientPermission.propertyName] ? 'Tak' : 'Nie' }}
        </p>
      </div>
    </template>
  </card>
</template>

<script>
import Card from "../../../Card";
import read from "@/rest/read";
import PatientCardUpdatePermissions from "@/components/Patient/PatientCard/Permissions/PatientCardUpdatePermissions";
import IsGranted from "../../../IsGranted";
import patientPermissionsList from "../../../../components/Patient/patientPermissions.ts";
import {isGranted} from "@/security/isGranted";

export default {
  name: "PatientCardPermissions",
  components: {
    IsGranted,
    PatientCardUpdatePermissions,
    Card,
  },
  props: {
    patient: {type: Object, required: true},
  },
  data() {
    return {
      permissions: {},
      isLoading: false,
      patientPermissionsList,
      disabledPermissions: {}
    };
  },
  watch: {
    "patient.patientId": {
      immediate: true,
      handler(){
        this.getPatientPermissions();
        this.disablePermissions();
      }
    },
  },
  methods: {
    async getPatientPermissions() {
      this.isLoading = true;
      this.permissions = await read(`/api/patients/${this.patient.patientId}/permissions`);
      this.isLoading = false;
    },
    async disablePermissions(){
      this.patientPermissionsList.forEach(
        (permission) => this.disabledPermissions[permission.propertyName] = false
      );

      this.disabledPermissions["permissionForProcessingPersonalData"] = !await isGranted(
        ["CHANGE_PERMISSION_FOR_PROCESSING_PERSONAL_DATA_STATUS"],
        this.patient.patientId,
      );
    }
  }
}
</script>

<style scoped>

</style>
