
















import {Component, Prop, Watch} from "vue-property-decorator";
import ErrorMessage from "../../../components/Form/ErrorMessage.vue";
import LoadingMask from "../../../components/Loading/LoadingMask.vue";
import {mixins} from "vue-class-component";
import ErrorsMixin from "../../../mixins/ErrorsMixin.ts";
import processResponseException from "../../../utils/errors/processResponseException";
import read from "../../../rest/read";
import type {ChildIpzItem} from "../../../types/ChildIpzs";
import ChildIpzWidget from "../../../components/Ipz/ChildIpz/ChildIpzWidget.vue";
import ChildIpzList from "../../../components/Ipz/ChildIpz/ChildIpzList.vue";
import ChildIpzDetails from "../../../components/Ipz/ChildIpz/ChildIpzDetails.vue";

interface Overview {
  readonly: boolean;
}
interface IpzDetails {
  ipzId: string;
  overview: Overview;
}

@Component({
  name: "ChildIpzPanel",
  components: {ChildIpzDetails, ChildIpzList, ChildIpzWidget, ErrorMessage, LoadingMask},
})

export default class ChildIpzPanel extends mixins(ErrorsMixin) {
  @Prop({type: String, required: true}) readonly ipzId!: string;

  loading: boolean = false;
  overview: Overview | {} = {};
  items: Array<ChildIpzItem> = [];

  get editedItem(): ChildIpzItem|null {
    return this.items.find(item => item.status !== "closed") || null;
  };

  get closedItems(): Array<ChildIpzItem> {
    return this.items.filter(item => item.status === "closed");
  }

  @Watch("ipzId")
  async onIpzIdChange(): Promise<void> {
    await this.loadData();
  }

  async mounted(): Promise<void> {
    await this.loadData();
  }

  async loadData(): Promise<void> {
    this.errors = [];
    this.loading = true;
    try {
      await Promise.all([
        this.loadItems(),
        this.loadOverview(),
      ]);
    } catch (e) {
      this.errors = processResponseException(e);
    }
    this.loading = false;
  }

  async loadItems(): Promise<void> {
    const {items} = await read<{items: Array<ChildIpzItem>}>(`/api/ipzs/${this.ipzId}/childIpzItems`);
    this.items = items;
  }

  async loadOverview(): Promise<void> {
    const {overview} = await read<IpzDetails>(`/api/ipzs/${this.ipzId}/details`);
    this.overview = overview;
  }
}
