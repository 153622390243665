

































import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";
import FormRow from "../Form/FormRow.vue";
import type {Patient} from "../../types/Prescription";

@Component({
  name: "PrescriptionPatientDetails",
  components: {FormRow}
})
export default class PrescriptionPatientDetails extends Vue {
  @Prop({type: Object, required: true}) readonly patient!: Patient;
}
