<template>
  <div class="card-box">
    <loading-mask :loading="loading">
      <div class="pb-2 mb-2 border-bottom border-light">
        <h4>Status unifikacji pacjenta</h4>
      </div>
      <error-message
        :errors="errors"
        class="my-3"
      />
      <b-form-radio-group
        v-model="selected"
        :options="options"
        :button-variant="buttonVariant"
        buttons
        class="flex-wrap"
        @change="updateStatus($event, selected)"
      />
    </loading-mask>
  </div>
</template>

<script>
import t from "../../../i18n";
import LoadingMask from "../../Loading/LoadingMask";
import ErrorMessage from "../../Form/ErrorMessage";
import processResponseException from "../../../utils/errors/processResponseException";
import update from "../../../rest/update";

export default {
  name: "PatientDataUnificationStatusRadio",
  components: {ErrorMessage, LoadingMask},
  props: {
    unificationStatus: {type: String, required: true},
    patientId: {type: String, required: true},
  },
  data() {
    const statuses = ["pending", "waiting_for_patient", "ex_patient", "completed"];
    return {
      loading: false,
      errors: [],
      selected: this.unificationStatus,
      options: statuses.map(status => ({
        value: status,
        text: t(`@dataUnificationStatus.${status}`)
      })),
    };
  },
  computed: {
    buttonVariant() {
      return (this.selected === "ex_patient" || this.selected === "completed")
        ? "outline-success" : "outline-secondary";
    }
  },
  methods: {
    async updateStatus(status, selected) {
      this.errors = [];
      this.loading = true;
      try {
        await update(`/api/patients/${this.patientId}/data-unification-status`, {status})
      } catch(e) {
        this.errors = processResponseException(e);
        this.selected = selected;
      }
      this.loading = false;
    },
  },
}
</script>
