













































import {Component, Mixins, Prop} from "vue-property-decorator";
import PaginationDescription from "../Pagination/PaginationDescription.vue";
import type {Pagination} from "../../types/Pagination";
import read from "../../rest/read";
import IsGranted from "../IsGranted.vue";
import EditSocialActivityModal from "./EditSocialActivityModal.vue";
import LoadingMask from "../Loading/LoadingMask.vue";
import ShowSocialActivityDescriptionModal from "./ShowSocialActivityDescriptionModal.vue";
import ErrorMessage from "../Form/ErrorMessage.vue";
import ErrorsMixin from "../../mixins/ErrorsMixin.ts";
import processResponseException from "../../utils/errors/processResponseException";

interface SocialActivity {
  socialActivityId: string;
  treatmentTypeName: string;
  patientId: string;
  dateFrom: string;
  dateTo: string;
  workerName: string;
}

interface FindResponse {
  items: Array<SocialActivity> | null;
  pagination: Pagination;
}

interface Field {
  key: string,
  label: string
}

@Component({
  name: "SocialActivityList",
  components: {
    ErrorMessage,
    ShowSocialActivityDescriptionModal,
    LoadingMask,
    EditSocialActivityModal,
    IsGranted,
    PaginationDescription,
  }
})
export default class SocialActivityList extends Mixins(ErrorsMixin) {
  @Prop({type: String, required: true}) readonly patientId!: string;
  @Prop({type: Number, default: null}) readonly page!: Number;

  private socialActivityId: string|null = null;

  private pagination: Pagination = {
    perPage: 10,
    totalRows: 0,
    currentPage: 1
  };
  private items: Array<SocialActivity> | null = null;
  private currentPage: Number = this.page || 1;
  private loading: boolean = false;
  private fields: Array<Field> = [
    {key: "dateFrom", label: "Od"},
    {key: "dateTo", label: "Do"},
    {key: "treatmentTypeName", label: "Usługa"},
    {key: "workerName", label: "Twórca"},
    {key: "options", label: "Opcje"},
  ];

  async loadData(): Promise<void> {
    this.loading = true;
    this.errors = [];
    try {
      const {
        items,
        pagination
      } = await read<FindResponse>("/api/social-activity", {
        patientId: this.patientId,
        page: this.pagination.currentPage,
        perPage: this.pagination.perPage
      });
      this.items = items;
      this.pagination = pagination;
    } catch (e) {
      this.errors = processResponseException(e);
    }
    this.loading = false;
  }

  async edit(socialActivityId: string|null): Promise<void> {
    this.socialActivityId = socialActivityId;
  }

  async changePage(page: number): Promise<void> {
    this.pagination.currentPage = page;
    await this.loadData();
  }

  async mounted(): Promise<void> {
    await this.loadData();
  }
};
