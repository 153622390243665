<template>
  <div
    v-if="overview"
    class="row"
  >
    <div class="col-12">
      <div class="card-box">
        <h4 class="header-title">
          Auxilio
        </h4>
        <div class="row m-b-20">
          <div class="col-4">
            <router-link 
              :to="{
                name: 'ipz_appointment_requirements_monthly',
                params: {ipzId: overview.ipzId},
                query: {year: previousMonth.y, month: previousMonth.m }
              }"
            >
              {{ previousMonth.m }}/{{ previousMonth.y }}
            </router-link>
            &laquo; {{ month }}/{{ year }} &raquo;
            <router-link 
              :to="{name: 'ipz_appointment_requirements_monthly',
                    params: {ipzId: overview.ipzId},
                    query: {year: nextMonth.y, month: nextMonth.m}}"
            >
              {{ nextMonth.m }}/{{ nextMonth.y }}
            </router-link>
          </div>
          <div class="col-4 text-center">
            <span v-if="chosenTreatment">
              {{ treatment(chosenTreatment.treatmentType).name }}
              <span v-if="chosenTreatment.workerName">
                z {{ chosenTreatment.workerName }}
              </span>
              <span v-else>
                z dowolnym pracownikiem
              </span>
            </span>
            <span v-else>
              nie wybrano uslugi
            </span>
          </div>
          <div class="col-4 text-right">
            {{ overview.displayName }}
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <requirement-meetings-dashboard
              v-if="treatmentTypesData.length > 0"
              :appointment-requirements="appointmentRequirements"
              :treatment-types="treatmentTypesData"
              @chosenTreatment="(v) => chosenTreatment = v"
            />
          </div>
          <!-- notatki ipz narazie ukryte
          <div class="col-4">
            <ipz-monthly-notes
              :ipz-id="overview.ipzId"
              :month="month"
              :year="year"
            />
          </div>
          -->
        </div>
        <div class="row">
          <div class="col-12">
            <Calendar
              :filters="filters"
              :view="view"
              :preferred-patient="{patientId: overview.patientId, name: overview.fullName, pesel: overview.pesel}"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RequirementMeetingsDashboard
  from "../../components/Ipz/IpzAppointmentRequirements/IpzAppointmentRequirementsItem.vue";
import Calendar from "../../components/Calendar/Calendar.vue";
import read from "../../rest/read";
import {mapActions} from "vuex";

export default {
  name: "MonthlyIpzAppointmentRequirements",
  components: {
    Calendar,
    RequirementMeetingsDashboard,
  },
  props: {
    ipzId: {type: String, required: true},
    month: {type: Number, required: true},
    year: {type: Number, required: true},
  },
  data() {
    return {
      filters: {
        treatmentType: null,
        workersList: [],
        startDate: new Date(),
        endDate: new Date(),
      },
      overview: null,
      appointmentRequirements: null,
      view: "month",
      chosenTreatment: null,
      treatmentTypesData: [],
    };
  },
  computed: {
    previousMonth() {
      const p = new Date(`${this.year  }-${  this.month  }-01`);
      p.setMonth(p.getMonth() - 1);
      p.setDate(1);

      return {
        m: p.getMonth() + 1,//0-based month
        y: p.getFullYear(),
      }
    },
    nextMonth() {
      const n = new Date(`${this.year  }-${  this.month  }-01`);
      n.setMonth(n.getMonth() + 1);
      n.setDate(1);

      return {
        m: n.getMonth() + 1,//0-based month
        y: n.getFullYear(),
      }
    },
    workersList() {
      if (this.chosenTreatment && this.chosenTreatment.workerId) {
        return [this.chosenTreatment.workerId];
      }
      return [];
    },
    parsedStartDate() {
      const date = new Date(`${this.year}-${this.month}-01`);
      date.setHours(0,0,0);

      return date;
    },
    parsedEndDate() {
      const date = new Date(`${this.year}-${this.month}-01`);
      date.setMonth(date.getMonth() + 1);
      date.setHours(0,0,0);
      return date;
    },
  },
  watch: {
    chosenTreatment() {
      this.filters = {
        ...this.filters,
        treatmentType: this.chosenTreatment ? this.chosenTreatment.treatmentType : null,
        workersList: this.workersList,
        startDate: this.parsedStartDate,
        endDate: this.parsedEndDate,
      };
    },
    async "$route"() {
      this.loadAppointmentRequirements();
      this.filters = {
        treatmentType: this.chosenTreatment ? this.chosenTreatment.treatmentType : null,
        workersList: this.workersList,
        startDate: this.parsedStartDate,
        endDate: this.parsedEndDate,
      };
    },
  },
  async mounted() {
    this.loadAppointmentRequirements();
  },
  methods: {
    ...mapActions("stickyPatient", ["load"]),
    async loadOptions(phrase="") {
      const {items} = await read("/api/treatment-types", {active: 1, phrase});
      return items;
    },
    async loadAppointmentRequirements() {
      const {
        overview, appointmentRequirements
      } = await read(`/api/ipzs/${this.ipzId}/appointment-requirements`, {month: this.month, year: this.year});
      this.filters.startDate = this.parsedStartDate;
      this.filters.endDate = this.parsedEndDate;
      this.overview = overview;
      this.appointmentRequirements = appointmentRequirements;
      this.treatmentTypesData = await this.loadOptions();
      await this.load(this.overview.patientId);
    },
    treatment(value) {
      return this.treatmentTypesData.find(type => type.value === value);
    },
  },
};
</script>
