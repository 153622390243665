import {MutationTree} from "vuex";
import {LastEditedAppointmentState} from "./types";

export const mutations: MutationTree<LastEditedAppointmentState> = {
  setAppointmentId(state, id) {
    state.appointmentId = id;
  },
  clearAppointmentId(state) {
    state.appointmentId = null;
  },
  setAppointmentTreatmentType(state, treatmentTypeName) {
    state.appointmentTreatmentType = treatmentTypeName;
  },
  clearAppointmentTreatmentType(state) {
    state.appointmentTreatmentType = null;
  },
  setAppointmentDates(state, datesString) {
    state.appointmentDates = datesString;
  },
  clearAppointmentDates(state) {
    state.appointmentDates = null;
  },
};
