<template>
  <b-modal
    :id="`cancel-ipz-${ipzId}`"
    size="lg"
    no-close-on-backdrop
    title="Odwołanie IPZ"
    title-tag="h3"
  >
    <template #default>
      <FormRow
        :errors="errors"
        label="Powód odwołania"
        field="reason"
        class="text-left"
      >
        <textarea-counter
          id="reason"
          v-model="reason"
          :state="state('reason')"
          :max-characters-count="1000"
          rows="4"
        />
      </FormRow>
    </template>
    <template #modal-footer>
      <button
        type="button"
        class="btn btn-secondary"
        @click="closeModal"
      >
        Anuluj
      </button>
      <button
        :disabled="loading"
        class="btn btn-primary"
        @click="submit"
      >
        <i
          :class="loading ? 'fa-spin fa-spinner' : 'fa-check'"
          class="fa"
        />
        Odwołaj IPZ
      </button>
    </template>
  </b-modal>
</template>

<script>
import TextareaCounter from "../Form/Textarea/TextareaCounter";
import FormRow from "../Form/FormRow";
import modify from "../../rest/modify";
import processResponseException from "../../utils/errors/processResponseException";
import {errorsMixin} from "@/mixins/errorsMixin";
import removeExceededWhitespace from "../../utils/removeExceededWhitespace";

export default {
  name: "CancelIpz",
  components: {
    TextareaCounter,
    FormRow
  },
  mixins: [errorsMixin],
  props: {
    ipzId: {type: String, required: true}
  },
  data() {
    return {
      loading: false,
      modalVisible: false,
      reason: "",
    };
  },
  methods: {
    closeModal() {
      this.$bvModal.hide(`cancel-ipz-${this.ipzId}`);
    },
    async submit() {
      this.loading = true;
      try {
        await modify(`/api/ipzs/${this.ipzId}/cancel`, {reason: this.reason});
        this.$emit("ipzCanceled");
        this.closeModal();
      } catch (exception) {
        this.errors = processResponseException(exception);
      }
      this.loading = false;
    },
  },
}
</script>
