<template>
  <div>
    <div class="card-box m-b-5">
      <div class="text-right">
        <router-link
          v-if="showCreateButton"
          :to="{name: 'get_course_create_view'}"
          class="btn btn-success waves-effect"
        >
          <i class="fa fa-plus" /> Dodaj kurs
        </router-link>
      </div>
      <form
        class="form-row"
        @submit.prevent="submit"
      >
        <b-form-group
          class="col-md-6 col-lg-4"
          label="Instytucja"
        >
          <institution-select
            v-model="selectedInstitutions"
            clearable
            multiple
            name="institutions"
          />
        </b-form-group>
        <b-form-group
          class="col-md-6 col-lg-4"
          label="Rodzaj kursu"
        >
          <course-types-select
            v-model="selectedCourseTypes"
            clearable
            multiple
            name="courseTypes"
          />
        </b-form-group>
        <b-form-group
          class="col-sm-6 col-lg-2"
          label="Data od"
        >
          <date-picker
            v-model="start"
            clearable
          />
        </b-form-group>
        <b-form-group
          class="col-sm-6 col-lg-2"
          label="Data do"
        >
          <date-picker
            v-model="end"
            clearable
          />
        </b-form-group>
        <div class="col-12 text-right no-label">
          <a
            v-if="!loading"
            :href="exportUrl"
            class="btn btn-warning mr-2"
            target="_blank"
          >
            <i class="fa fa-atom" />
            Eksportuj
          </a>
          <button
            type="submit"
            class="btn btn-primary"
          >
            <i class="fa fa-search" /> Szukaj
          </button>
        </div>
      </form>
    </div>
    <card
      :loading="loading"
      title=""
    >
      <div class="row">
        <div class="card-box col-md-12 m-t-10 p-0 table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th>Data</th>
                <th style="min-width: 200px">
                  Instytucja
                </th>
                <th>Grupa</th>
                <th>Il. uczestników</th>
                <th>Il. godzin</th>
                <th
                  v-if="showNotes"
                  style="min-width: 300px"
                >
                  Notatki opisujące kurs
                </th>
                <th class="text-center">
                  Edycja
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="course in sortedCourses"
                :key="course.courseId"
              >
                <date-time
                  :value="parseDate(course.courseDate, DATE_FORMAT.DATE)"
                  format="DD.MM YYYY"
                  component="td"
                />
                <td>
                  <p
                    v-for="institution in course.institutionList"
                    :key="institution.institutionId"
                  >
                    {{ institution.name }}
                  </p>
                  <span
                    class="badge badge-secondary"
                  >
                    <i18n
                      :msgid="`@prevention.${course.courseType}`"
                    />
                    <template v-if="course.courseSubtype">
                      -
                      <i18n
                        :msgid="`@prevention.${course.courseSubtype}`"
                        component="span"
                      />
                    </template>
                  </span>
                </td>
                <td>{{ course.groupName }}</td>
                <td>{{ course.numberOfParticipants }} os.</td>
                <td>{{ course.courseLength }} godz.</td>
                <td v-if="showNotes">
                  <u><b>Notatka ogólna</b></u>
                  <read-more
                    :text="course.note ? course.note : 'brak notatki'"
                    :max-chars="noteMaxChars"
                    more-str="rozwiń"
                    less-str="zwiń"
                    btn-class="btn-sm"
                    class="m-b-10"
                  />
                  <u><b>Notatka obecności uczestników</b></u>
                  <read-more
                    :text="course.participantsAttendanceNote ? course.participantsAttendanceNote : 'brak notatki'"
                    :max-chars="noteMaxChars"
                    more-str="rozwiń"
                    less-str="zwiń"
                    btn-class="btn-sm"
                    class="m-b-10"
                  />
                  <u><b>Notatka obecności trenerów</b></u>
                  <read-more
                    :text="course.coachesAttendanceNote ? course.coachesAttendanceNote : 'brak notatki'"
                    :max-chars="noteMaxChars"
                    more-str="rozwiń"
                    less-str="zwiń"
                    btn-class="btn-sm"
                  />
                </td>
                <td class="text-center">
                  <is-granted
                    :privileges="['UPDATE_COURSE']"
                    :subject="course.courseId"
                  >
                    <router-link
                      :to="{name: 'course_update_view', params: {courseId: course.courseId}}"
                      class="btn btn-sm btn-outline-primary waves-effect waves-float"
                    >
                      <i class="fa fa-pencil-alt" />
                    </router-link>
                  </is-granted>
                </td>
              </tr>
            </tbody>
          </table>
          <pagination-description
            :items-count="courseList.length"
            :page="currentPage"
            :per-page="pagination.perPage"
            :total-count="pagination.totalRows"
          >
            <b-pagination
              :value="currentPage"
              :per-page="pagination.perPage"
              :total-rows="pagination.totalRows"
              align="right"
              class="mb-0"
              @input="changePage"
            />
          </pagination-description>
        </div>
      </div>
    </card>
  </div>
</template>

<script>
import read from "../../rest/read";
import I18n from "../../components/i18n";
import ReadMore from "../../components/ReadMore";
import IsGranted from "../../components/IsGranted";
import parseDate from "../../utils/date/parseDate";
import DATE_FORMAT from "../../utils/date/DATE_FORMAT";
import DateTime from "../../components/DateTime";
import Card from "../../components/Card";
import PaginationDescription from "../../components/Pagination/PaginationDescription";
import InstitutionSelect from "../../components/Prevention/InstitutionSelect";
import isEqual from "lodash/isEqual";
import {changePageQuery} from "../../utils/pageUrl/handlePageQuery";
import stringifyDate from "../../utils/date/stringifyDate";
import DatePicker from "../../components/Form/DatePicker/DatePicker";
import CourseTypesSelect from "../../components/Prevention/CourseTypesSelect";
import {createUrl} from "../../utils/pageUrl/createUrl";

export default {
  components: {
    CourseTypesSelect,
    InstitutionSelect,
    PaginationDescription,
    Card,
    DateTime,
    DatePicker,
    IsGranted,
    ReadMore,
    I18n,
  },
  props: {
    noteMaxChars: {type: Number, default: 180},
    showCreateButton: {type: Boolean, default: true},
    showNotes: {type: Boolean, default: true},
    institutions: {type: Array, default: ()=>[]},
    courseTypes: {type: Array, default: ()=>[]},
    startDate: {type: String, default: null},
    endDate: {type: String, default: null},
    page: {type: Number, default: null},
  },
  data() {
    return {
      loading: false,
      courseList: [],
      pagination: {perPage: 20, totalRows: 0},
      currentPage: this.page || 1,
      start: this.startDate ? parseDate(this.startDate) : null,
      end: this.endDate ? parseDate(this.endDate) : null,
      selectedInstitutions: this.institutions,
      selectedCourseTypes: this.courseTypes,
      parseDate,
      DATE_FORMAT,
    }
  },
  computed: {
    sortedCourses() {
      return this.courseList.slice(0).sort(function(a, b) {
        return new Date(a.courseDate) >= new Date(b.courseDate) ? -1 : 1;
      });
    },
    filters() {
      return {
        page: this.currentPage || 1,
        perPage: this.pagination.perPage,
        startDate: this.startDateString,
        endDate: this.endDateString,
        institutions: this.getIds(this.selectedInstitutions),
        courseTypes: this.getIds(this.selectedCourseTypes),
      };
    },
    query() {
      return {
        page: this.currentPage || undefined,
        perPage: this.pagination.perPage,
        startDate: this.startDateString,
        endDate: this.endDateString,
        institutions: this.getIds(this.selectedInstitutions).join(",") || undefined,
        courseTypes: this.getIds(this.selectedCourseTypes).join(",") || undefined,
      };
    },
    exportUrl() {
      return createUrl("/api/courses/export", this.filters);
    },
    startDateString() {
      return this.start ? stringifyDate(this.start) : undefined;
    },
    endDateString() {
      if (!this.end) {
        return;
      }
      const date = new Date(this.end.getTime());
      date.setHours(23, 59, 59);
      return stringifyDate(date);
    },
  },
  watch: {
    startDate(val) {
      this.start = val ? parseDate(val) : null;
    },
    endDate(val) {
      this.end = val ? parseDate(val) : null;
    },
    institutions(val) {
      this.selectedInstitutions = val || [];
    },
    courseTypes(val) {
      this.selectedCourseTypes = val || [];
    },
    "$route": {
      immediate: true,
      handler() {
        this.getCourseList();
      }
    },
  },
  methods: {
    async getCourseList() {
      this.loading = true;
      this.$emit("loading", true);
      const {items, pagination} = await read("/api/courses", this.filters);
      this.courseList = items;
      this.pagination = pagination;
      this.$emit("loading", false);
      this.loading = false;
    },
    submit() {
      this.currentPage = 1;
      if (isEqual(this.$route.query, this.query)) {
        this.getCourseList();
        return;
      }
      this.updatePageUrl();
    },
    updatePageUrl() {
      changePageQuery(this.query);
    },
    getIds(list) {
      if (list.length && "object" === typeof list[0]) {
        return list.map(obj => obj.value);
      }
      return list;
    },
    async changePage(page) {
      this.currentPage = page;
      this.updatePageUrl();
    },
  },
}
</script>
