<template>
  <b-alert
    :show="show"
    dismissible
    variant="success"
    @dismissed="clearAppointmentDetails"
  >
    <router-link
      :to="appointmentHref"
    >
      Wróć do wizyty: {{ appointmentTreatmentType }} {{ appointmentDates }}
    </router-link>
  </b-alert>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "LastEditedAppointment",
  computed: {
    ...mapState("lastEditedAppointment", [
      "appointmentId",
      "appointmentTreatmentType",
      "appointmentDates"
    ]),
    show() {
      return !!this.appointmentId && (this.$route.path !== this.appointmentHref);
    },
    appointmentHref() {
      return `/appointment/${this.appointmentId}/appointment`
    }
  },
  methods: {
    ...mapActions("lastEditedAppointment", [
      "clearAppointmentDetails",
    ]),
  },
}
</script>
