<template>
  <div>
    <b-form-group
      class="mb-0"
      label="Ta wizyta może zostać zrealizowana wcześniej, jeżeli mimo to chcesz ją umówić prosimy określić powód"
    >
      <b-form-radio-group
        id="earlierPossibilityReasonValue"
        :checked="reason"
        name="earlierPossibilityReasonValue"
        stacked
        @input="updateReason"
      >
        <b-form-radio value="direct_indication_by_specialist">
          Termin wskazany przez specjalistę
          <br>
          <span class="font-italic">
            - klient zgłosił, że specjalista polecił mu zapisanie się na konkretny termin
          </span>
        </b-form-radio>
        <b-form-radio value="private_matters_conflict">
          Termin koliduje z prywatnymi sprawami
          <br>
          <span class="font-italic">
            - klient zgłosił, że w tym dniu nie może stawić się na wizytę ze względu na prywatne plany
          </span>
        </b-form-radio>
        <b-form-radio value="patient_decision">
          Termin wizyty wynika ze zgłoszonej potrzeby klienta
          <br>
          <span class="font-italic">
            - klient zgłosił potrzebę wizyty w tym konkretnym dniu wynikającą ze nieustabilizowanego lub zmiany stanu
            emocjonalnego
          </span>
        </b-form-radio>
        <b-form-radio value="scheduled_series_of_meetings">
          Termin wizyty wynika z ustalonego cyklu spotkań z klientem
          <br>
          <span class="font-italic">
            - klient uczestniczy w spotkaniach odbywających się w trybie regularnym (np. cotygodniowym)
          </span>
        </b-form-radio>
        <b-form-radio value="other_reason">
          Inny powód
          <br>
          <span class="font-italic">
            - każdy powód powyżej niesklasyfikowany
          </span>
        </b-form-radio>
      </b-form-radio-group>
    </b-form-group>
    <b-form-textarea
      v-if="reason === 'other_reason'"
      :value="description"
      placeholder="Podaj powód"
      @input="updateDescription"
    />
  </div>
</template>

<script>
export default {
  props: {
    value: {type: Object, default: null}
  },
  computed: {
    reason() {
      return this.value.value
    },
    description() {
      return this.value.description
    },
  },
  methods: {
    updateReason(reason) {
      this.$emit("input",{
        value: reason,
        description: (reason !== "other_reason") ? null : this.description
      });
    },

    updateDescription(description){
      this.$emit("input",{
        value: this.reason,
        description: description
      });
    }
  },
}
</script>
