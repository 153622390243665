<template>
  <b-modal
    id="startSurveyModal"
    no-close-on-backdrop
    title="Nowe badanie"
    title-tag="h3"
    size="lg"
    @hide="clearForm"
  >
    <div class="card-box">
      <error-message :errors="errors" />
      <research-survey-select
        v-model="survey"
        :patient-id="patientId"
        :clearable="false"
      />
      <b-form-group
        label="Data rozpoczęcia"
      >
        <date-time-picker
          v-model="startedAt"
          :disabled="isLoading"
          :state="state('startedAt')"
          :time-span="1"
        />
      </b-form-group>
      <error-message
        :root="true"
        class="col-12"
        :errors="errors"
        field="startedAt"
      />
    </div>

    <b-form-radio-group
      id="requesterTypeValue"
      v-model="requesterType"
      name="requesterTypeValue"
      stacked
    >
      <b-form-radio
        value="patient"
      >
        wypełniane przez pacjenta
      </b-form-radio>
      <b-form-radio
        value="eligiblePerson"
      >
        wypełniane przez inną osobę niż pacjent
      </b-form-radio>
      <b-form-radio value="worker">
        wypełniane przez Specjalistę
      </b-form-radio>
    </b-form-radio-group>
    <template
      v-if="requesterType === 'eligiblePerson'"
    >
      <eligible-persons
        v-model="eligiblePersons"
        :disabled="isLoading"
        :with-address="false"
        :errors="errors"
        :optional-pesel="true"
        :multiple="false"
        :patient-id="patientId"
        form-expanded
      />
    </template>
    <template #modal-footer>
      <button
        class="btn btn-secondary"
        @click="hideModal"
      >
        Anuluj
      </button>
      <button
        :disabled="isLoading || survey === null || requesterType === null"
        class="btn btn-primary"
        @click="startSurvey"
      >
        <i
          :class="isLoading ? 'fa-spin fa-spinner' : 'fa-check'"
          class="fa"
        />
        Zapisz
      </button>
    </template>
  </b-modal>
</template>

<script>
import create from "../../../../rest/create";
import {errorsMixin} from "../../../../mixins/errorsMixin.js";
import processResponseException from "../../../../utils/errors/processResponseException";
import ErrorMessage from "../../../Form/ErrorMessage";
import ResearchSurveySelect from "../../../Form/Select/ResearchSurveySelect";
import subErrors from "../../../../utils/errors/subErrors";
import EligiblePersons from "../../../EligiblePersons/EligiblePersons";
import {generateUuid} from "../../../../utils/uuid/generateUuid";
import DateTimePicker from "../../../Form/DatePicker/DateTimePicker";
import stringifyDate from "../../../../utils/date/stringifyDate";

export default {
  components: {
    DateTimePicker,
    EligiblePersons,
    ErrorMessage,
    ResearchSurveySelect
  },
  mixins: [errorsMixin],
  props: {
    patientId: {type: String, required: true},
  },
  data() {
    return {
      survey: null,
      isLoading: false,
      requiredType: false,
      eligiblePersons: [],
      startedAt: null,
      requesterType: "patient"
    };
  },
  methods: {
    getSubErrors(field) {
      return subErrors(this.errors, field);
    },
    hideModal() {
      this.$bvModal.hide("startSurveyModal");
      this.clearForm();
    },
    clearForm() {
      this.errors = [];
      this.survey = null;
      this.eligiblePersons = [];
      this.startedAt = null;
      this.requesterType = "patient"
    },
    async startSurvey() {
      try {
        this.isLoading = true;
        this.errors = [];

        const startedAt = this.startedAt ? this.startedAt : new Date();
        const surveyId = generateUuid();
        const valueOrObject = this.eligiblePersons.length > 0 ?
          this.eligiblePersons[0] :
          {};
        const person = this.requesterType === "eligiblePerson" ? valueOrObject : null;
        await create(`/api/patients/${this.patientId}/surveys`, {
          surveyId,
          templateId: this.survey.id,
          startedAt: stringifyDate(startedAt),
          eligiblePerson: person,
          requesterType: this.requesterType
        });

        this.$emit("surveyStarted");
        this.hideModal();
      } catch (exception) {
        console.error(exception);
        this.errors = processResponseException(exception);
      }
      this.isLoading = false;
    },
  },
}
</script>
