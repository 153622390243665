<template>
  <div :class="`status ${status} clearfix`">
    {{ target.name }}&nbsp;
    <button
      type="button"
      title="Odśwież"
      class="btn btn-outline-dark btn-sm float-right"
      @click="checkStatus"
    >
      <i class="fa fa-sync" />
    </button>
  </div>
</template>

<script>
import read from "../../rest/read";

const WAITING = "waiting";
const FAIL = "dead";
const PROCESSING = "in-progress";
const OK = "open";

export default {
  name: "Status",
  props: {
    target: {type: Object, required: true},
  },
  data() {
    return {
      status: WAITING,
    };
  },
  mounted() {
    this.checkStatus();
  },
  methods: {
    async checkStatus() {
      try {
        this.status = PROCESSING;
        await read(this.target.url);
        this.status = OK;
      } catch (e) {
        this.status = FAIL;
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  .status {
    &.open:before {
      background-color: #94E185;
      border-color: #78D965;
      box-shadow: 0px 0px 4px 1px #94E185;
    }

    &.in-progress:before {
      background-color: #FFC182;
      border-color: #FFB161;
      box-shadow: 0px 0px 4px 1px #FFC182;
    }

    &.dead:before {
      background-color: #C9404D;
      border-color: #C42C3B;
      box-shadow: 0px 0px 4px 1px #C9404D;
    }

    &.wait:before {
      background-color: #CECECE;
      border-color: #BFBFBF;
      box-shadow: 0px 0px 4px 1px #CECECE;
    }

    &:before {
      content: ' ';
      display: inline-block;
      width: 7px;
      height: 7px;
      margin-right: 10px;
      border: 1px solid #000;
      border-radius: 7px;
    }
  }
</style>
