<template>
  <div>
    <b-modal
      id="create-request-medical-record"
      class="text-left"
      no-close-on-backdrop
      size="lg"
      title="Wniosek o wydanie dokumentacji medycznej"
      title-tag="h3"
      @hide="resetModalData"
      @show="onShow"
    >
      <p>Podmiot lub wybrane komórki:</p>
      <div class="form-row">
        <b-form-group
          class="col-sm-6"
          label="Podmiot"
        >
          <establishment-select
            v-model="establishment"
            :disabled="branches.length > 0"
            :multiple="false"
            :state="state('establishmentId')"
          />
          <error-message
            :errors="errors"
            field="establishmentId"
          />
        </b-form-group>
        <b-form-group
          class="col-sm-6"
          label="Komórka"
        >
          <branch-select
            v-model="branches"
            :disabled="establishment !== null"
            :multiple="true"
            :state="state('branches')"
          />
          <error-message
            :errors="errors"
            field="branches"
          />
        </b-form-group>
        <error-message
          :errors="errors"
          :bordered="true"
          field="units"
          class="col-sm-12"
        />
      </div>
      <template v-if="requestType !== 'continuousStay'">
        <hr>
        <p>Zakres dat:</p>
        <div class="form-row">
          <b-form-group
            class="col-6"
            label="Data od"
          >
            <date-picker v-model="startDate" />
          </b-form-group>
          <b-form-group
            class="col-6"
            label="Data do"
          >
            <date-picker v-model="endDate" />
            <error-message
              :root="true"
              :errors="errors"
              field="endDate"
            />
          </b-form-group>
        </div>
        <hr>
        <div>
          <p>Opiekun pacjenta widniejący na dokumentacji:</p>
          <available-eligible-persons-select
            :value="featuredEligiblePersonInDocumentation"
            :state="state('featuredEligiblePersonInDocumentation')"
            :available-options="eligiblePersonsList"
            :disabled="loading"
            class="my-3"
            @input="setFeaturedEligiblePersonIdDocumentation"
          />
          <error-message
            :errors="errors"
            field="featuredEligiblePersonInDocumentation"
          />
        </div>
        <hr>
        <p>Zakres danych:</p>
        <b-checkbox
          v-model="type"
          :state="state('type')"
          unchecked-value="ambulatory"
          :value="'all'"
        >
          włącz dokumentację z pobytów ciągłych
        </b-checkbox>
        <error-message
          :errors="errors"
          field="type"
        />
      </template>
      <hr>
      <p>
        Pacjent: <span class="font-weight-bold">{{ patient.displayName }}</span>
      </p>
      <error-message :errors="errors" />
      <b-form-radio-group
        id="requesterTypeValue"
        v-model="requesterType"
        :disabled="loading"
        name="requesterTypeValue"
        stacked
        @input="changeRequesterType"
      >
        <b-form-radio
          value="patient"
        >
          wnioskodawcą jest pacjent
        </b-form-radio>
        <b-form-radio
          value="eligiblePerson"
        >
          wnioskodawcą jest inna osoba niż pacjent
        </b-form-radio>
        <b-form-radio
          value="organization"
        >
          wnioskodawcą jest instytucja
        </b-form-radio>
      </b-form-radio-group>
      <template
        v-if="requesterType === 'eligiblePerson'"
      >
        <eligible-person-type-select
          v-model="eligiblePersonType"
          :state="state('eligiblePerson')"
          :errors="errors"
          :clearable="false"
          :disabled="loading"
          class="my-3"
          @input="addEligiblePerson"
        />

        <error-message
          :root="true"
          :errors="errors"
          field="eligiblePerson"
        />

        <available-eligible-persons-select
          v-if="selectedTypeEligiblePersonsList && selectedTypeEligiblePersonsList.length > 1"
          :value="eligiblePerson.id"
          :state="state('eligiblePersonType')"
          :available-options="selectedTypeEligiblePersonsList"
          :disabled="loading"
          class="my-3"
          @input="setEligiblePersonData"
        />

        <transition name="fade">
          <div v-if="eligiblePerson && eligiblePerson.type">
            <i18n
              :msgid="`@person-eligible.${eligiblePerson.type}`"
              class="font-weight-bold"
              component="h4"
            />
            <div class="row">
              <b-form-group
                label="Imię"
                class="col-md-6"
              >
                <b-form-input
                  v-model.trim="eligiblePerson.name"
                  :disabled="loading"
                  :state="state('eligiblePerson.name')"
                />
                <error-message
                  :errors="getSubErrors('eligiblePerson')"
                  field="name"
                />
              </b-form-group>
              <b-form-group
                label="Nazwisko"
                class="col-md-6"
              >
                <b-form-input
                  v-model.trim="eligiblePerson.surname"
                  :disabled="loading"
                  :state="state('eligiblePerson.surname')"
                />
                <error-message
                  :errors="getSubErrors('eligiblePerson')"
                  field="surname"
                />
              </b-form-group>
              <b-form-group
                label="PESEL"
                class="col-md-6"
              >
                <b-form-input
                  v-model.trim="eligiblePerson.pesel"
                  :disabled="loading"
                  :state="state('eligiblePerson.pesel')"
                />
                <error-message
                  :errors="getSubErrors('eligiblePerson')"
                  field="pesel"
                />
              </b-form-group>
              <b-form-group
                label="Telefon kontaktowy"
                class="col-md-6"
              >
                <b-form-input
                  v-model.trim="eligiblePerson.phoneNumber"
                  :disabled="loading"
                  :state="state('eligiblePerson.phoneNumber')"
                />
                <error-message
                  :errors="getSubErrors('eligiblePerson')"
                  field="phoneNumber"
                />
              </b-form-group>
              <address-form
                v-model="eligiblePerson.address"
                :disabled="loading"
                :visible-country="false"
                :errors="getSubErrors('eligiblePerson.address')"
                horizontal
                class="col-12"
              />
            </div>
          </div>
        </transition>
      </template>
      <b-form-group
        v-if="requesterType === 'organization'"
        class="my-3"
      >
        <b-form-input
          v-model="organizationName"
          :state="state('organizationName')"
          :disabled="loading"
          placeholder="Nazwa instytucji"
        />
        <error-message
          :errors="errors"
          field="organizationName"
        />
      </b-form-group>
      <loading-mask
        v-show="showAttachments"
        :loading="loadingAttachments"
      >
        <hr>
        <patient-request-medical-attachments-list
          v-if="files.length"
          :files="files"
          :selected-files="attachmentsIds"
          :pagination="pagination"
          class="col-12"
          @updateUnselectedAttachmentIds="updateUnselectedAttachmentIds"
          @changePage="changeAttachmentsListPage"
        />
        <p
          v-else-if="!establishment && !branches.length"
          class="text-center text-danger"
        >
          Wybierz podmiot lub komórki organizacyjne, aby pobrać listę załączników
        </p>
        <p
          v-else
          class="text-center font-weight-bold"
        >
          Nie znaleziono załączników
        </p>
      </loading-mask>
      <template #modal-footer>
        <button
          class="btn btn-secondary"
          @click="hideModal"
        >
          Anuluj
        </button>
        <button
          class="btn btn-primary"
          @click="toggleViewAttachments"
        >
          <i class="fa" />
          {{ showAttachments ? 'Ukryj' : 'Pokaż' }} załączniki
        </button>
        <button
          :disabled="loading"
          class="btn btn-primary"
          @click="requestMedicalRecord"
        >
          <i
            :class="loading ? 'fa-spin fa-spinner' : 'fa-check'"
            class="fa"
          />
          Dodaj wniosek
        </button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import EligiblePersonTypeSelect from "../../../EligiblePersons/EligiblePersonTypeSelect";
import AvailableEligiblePersonsSelect from "../../../EligiblePersons/AvailableEligiblePersonsSelect";
import ErrorMessage from "../../../Form/ErrorMessage";
import {errorsMixin} from "../../../../mixins/errorsMixin.js";
import subErrors from "../../../../utils/errors/subErrors";
import {generateUuid} from "../../../../utils/uuid/generateUuid";
import I18n from "../../../i18n";
import create from "../../../../rest/create";
import processResponseException from "../../../../utils/errors/processResponseException";
import AddressForm from "../../../Address/AddressForm";
import read from "../../../../rest/read";
import DatePicker from "../../../Form/DatePicker/DatePicker";
import stringifyDate from "../../../../utils/date/stringifyDate";
import DATE_FORMAT from "../../../../utils/date/DATE_FORMAT";
import PatientRequestMedicalAttachmentsList from "../Attachment/PatientRequestMedicalAttachmentsList";
import t from "@/i18n";
import BranchSelect from "@/components/Branch/BranchSelect";
import EstablishmentSelect from "@/components/Branch/EstablishmentSelect";
import LoadingMask from "@/components/Loading/LoadingMask";
import {mapState} from "vuex";

const paginationInitialState = {
  currentPage: 1,
  perPage: 10,
  totalRows: 0,
};

export default {
  name: "CreateRequestMedicalRecord",
  components: {
    LoadingMask,
    EstablishmentSelect,
    BranchSelect,
    AddressForm,
    I18n,
    ErrorMessage,
    EligiblePersonTypeSelect,
    AvailableEligiblePersonsSelect,
    DatePicker,
    PatientRequestMedicalAttachmentsList
  },
  mixins: [errorsMixin],
  props: {
    patient: {type: Object, required: true},
    requestType: {type: String, default: "ambulatory"},
    continuousStayId: {type: String, default: null},
  },
  data() {
    return {
      establishment: null,
      branches: [],
      modalVisible: false,
      requesterType: "patient",
      requestMedicalRecordsId: "",
      eligiblePersonType: null,
      eligiblePerson: null,
      eligiblePersonsList: [],
      startDate: null,
      endDate: null,
      organizationName: null,
      loading: false,
      loadingAttachments: false,
      showAttachments: false,
      files: [],
      attachmentsIds: [],
      unselectedAttachments: [],
      type: this.requestType,
      featuredEligiblePersonInDocumentation: null,
      pagination: paginationInitialState
    };
  },
  computed: {
    ...mapState({
      clinicParameters: state => state.clinicParameters.parameters,
    }),
    selectedTypeEligiblePersonsList() {
      return this.eligiblePersonType
        ? this.eligiblePersonsList.filter(eligiblePerson => eligiblePerson.type === this.eligiblePersonType.value)
        : [];
    },
  },
  watch: {
    async startDate() {
      await this.getAttachments();
    },
    async endDate() {
      await this.getAttachments();
    },
    async continuousStayId() {
      await this.getAttachments();
    },
    async type() {
      await this.getAttachments();
    },
    async branches() {
      await this.getAttachments();
    },
    async establishment() {
      await this.getAttachments();
    }
  },
  methods: {
    setFeaturedEligiblePersonIdDocumentation(value){
      const featuredPerson = this.eligiblePersonsList.find(item => item.id === value);
      const {id, ...person} = featuredPerson;
      this.featuredEligiblePersonInDocumentation = person;
    },

    changeRequesterType(requesterType) {
      if (requesterType === "eligiblePerson") {
        this.eligiblePerson = null;
      }
      this.resetRequesterData();
    },
    createEligiblePersonDataStructure(type = "") {
      return {
        id: generateUuid(),
        type,
        address: {
          country: "PL",
        },
      };
    },
    async onShow() {
      await Promise.all([
        this.getAvailableEligiblePersonsList(),
        this.getAttachments(),
      ]);
    },
    getSubErrors(field) {
      return subErrors(this.errors, field);
    },
    addEligiblePerson() {
      if (this.selectedTypeEligiblePersonsList.length === 1) {
        this.eligiblePerson = this.selectedTypeEligiblePersonsList[0];
      } else {
        this.eligiblePerson = this.createEligiblePersonDataStructure(this.eligiblePersonType.value);
      }
      this.errors = [];
    },
    setEligiblePersonData(eligiblePersonId) {
      const person = this.selectedTypeEligiblePersonsList.find(person => person.id === eligiblePersonId);
      this.eligiblePerson = person || this.createEligiblePersonDataStructure(this.eligiblePersonType.value);
    },
    async requestMedicalRecord() {
      const data = {
        patientId: this.patient.patientId,
        requestMedicalRecordsId: generateUuid(),
        organizationName: this.organizationName ? this.organizationName : null,
        eligiblePerson: this.eligiblePerson ? this.eligiblePerson : null,
        requesterType: this.requesterType,
        startDate: this.startDate ? this.startDateFormatted(this.startDate) : null,
        endDate: this.endDate ? this.endDateFormatted(this.endDate) : null,
        unselectedAttachments: this.unselectedAttachments,
        type: this.type,
        continuousStayId: this.continuousStayId,
        branches: this.branches,
        establishmentId: this.establishment ? this.establishment.value :null,
        featuredEligiblePersonInDocumentation: this.featuredEligiblePersonInDocumentation,
        isChildrenEstablishment: this.clinicParameters.ageGroupTarget === "children"
      };
      this.loading = true;
      try {
        await create("/api/request-medical-record", data);
        this.addRequestMedicalRecord(data.requestMedicalRecordsId);
        this.hideModal();
      } catch (exception) {
        this.errors = processResponseException(exception);
      }
      this.loading = false;
    },
    addRequestMedicalRecord(requestMedicalRecordsId) {
      this.$emit("addRequestMedicalRecord", requestMedicalRecordsId);
    },
    hideModal() {
      this.$bvModal.hide("create-request-medical-record");
    },
    resetModalData() {
      this.errors = [];
      this.requesterType = "patient";
      this.startDate = null;
      this.endDate = null;
      this.showAttachments = false;
      this.files = [];
      this.attachmentsIds = [];
      this.unselectedAttachments = [];
      this.establishment = null;
      this.branches = [];
      this.type = this.requestType;
      this.pagination = paginationInitialState;
      this.resetRequesterData();
    },
    resetRequesterData() {
      this.eligiblePerson = null;
      this.eligiblePersonType = null;
      this.organizationName = null;
      this.clearError("eligiblePerson");
      this.clearError("eligiblePersonType");
      this.clearError("organizationName");
    },
    async getAvailableEligiblePersonsList() {
      this.loading = true;
      try {
        const {eligiblePersons} = await read(`/api/patients/${this.patient.patientId}/persons-eligible`);
        this.eligiblePersonsList = eligiblePersons
          ? eligiblePersons.map(person => ({
            ...person,
            id: generateUuid(),
          }))
          : [];
      } catch (e) {
        this.errors = processResponseException(e);
      }
      this.loading = false;
    },
    startDateFormatted(date) {
      date.setHours(0, 0, 0);

      return stringifyDate(date, DATE_FORMAT.DEFAULT);
    },
    endDateFormatted(date) {
      date.setHours(23, 59, 59);

      return stringifyDate(date, DATE_FORMAT.DEFAULT);
    },
    clearError(field) {
      if (this.errors.length) {
        const fieldRegExp = new RegExp(`^${field}\(\.|$\)`);
        this.errors = this.errors.filter(error => !fieldRegExp.test(error.field));
      }
    },
    toggleViewAttachments() {
      this.showAttachments = !this.showAttachments;
    },
    updateUnselectedAttachmentIds(i) {
      if (this.unselectedAttachments.findIndex(id => id === i) === -1) {
        this.unselectedAttachments.push(i);
      } else {
        this.unselectedAttachments.splice(this.unselectedAttachments.findIndex(id => id === i), 1);
      }
    },
    async getAttachments() {
      this.errors = [];
      if (!this.branches.length && !this.establishment) {
        this.pagination = paginationInitialState;
        this.attachmentsIds = [];
        this.files = [];
        return;
      }
      this.loadingAttachments = true;
      try {
        const {items, pagination} = await read("/api/attachments", {
          patientId: this.patient.patientId,
          startDate: this.startDate ? this.startDateFormatted(this.startDate) : null,
          endDate: this.endDate ? this.endDateFormatted(this.endDate) : null,
          page: this.pagination.currentPage,
          perPage: this.pagination.perPage,
          orderDesc: true,
          scope: this.type ? [this.type] : null,
          continuousStayId: this.continuousStayId,
          branches: this.branches,
          establishmentId: this.establishment?.value,
        });
        this.pagination = pagination;
        this.attachmentsIds = items.map(item => {
          if(this.unselectedAttachments.findIndex(id => id === item.attachmentId) === -1) {
            return item.attachmentId;
          }
        });
        this.files = items.map((item) => ({
          ...item,
          category: t(`@patient_attachment_category.${item.category}`),
        }));
      } catch (exception) {
        this.files = [];
        this.attachmentsIds = [];
        this.unselectedAttachments = [];
        this.errors = processResponseException(exception);
      }
      this.loadingAttachments = false;
    },
    async changeAttachmentsListPage(page) {
      this.pagination.currentPage = page;
      await this.getAttachments();
    }
  },
}
</script>
