<template>
  <form>
    <div class="form-row">
      <div class="col-md-6">
        <label 
          for="national-minority-belonging"
          class="col-form-label min-height-58"
        >Osoba należąca do mniejszości narodowej lub etnicznej, migrant, osoba obcego pochodzenia</label>
        <boolean-refusal-select
          id="national-minority-belonging"
          :clearable="false"
          :state="state('minorityBelonging')"
          :value="value.minorityBelonging"
          @focus.native="clearError('minorityBelonging')"
          @input="update($event, 'minorityBelonging')"
        />
        <error-message
          :errors="errors"
          field="minorityBelonging"
        />
      </div>
      <div class="col-md-6">
        <label
          for="homeless"
          class="col-form-label min-height-58"
        >Osoba bezdomna lub dotknięta wykluczeniem z dostępu do mieszkań</label>
        <boolean-refusal-select
          id="homeless"
          :clearable="false"
          :only-boolean="true"
          :state="state('homeless')"
          :value="value.homeless"
          @focus.native="clearError('homeless')"
          @input="update($event, 'homeless')"
        />
        <error-message
          :errors="errors"
          field="homeless"
        />
      </div>
      <div class="col-md-6">
        <label
          for="disabilities-person"
          class="col-form-label min-height-58"
        >Osoba z niepełnosprawnościami</label>
        <boolean-refusal-select
          id="disabilities-person"
          :clearable="false"
          :state="state('disabilitiesPerson')"
          :value="value.disabilitiesPerson"
          @focus.native="clearError('disabilitiesPerson')"
          @input="update($event, 'disabilitiesPerson')"
        />
        <error-message
          :errors="errors"
          field="disabilitiesPerson"
        />
      </div>
      <div class="col-md-6">
        <label
          for="bad-social-situation-person"
          class="col-form-label min-height-58"
        >Osoba w innej niekorzystnej sytuacji społecznej</label>
        <boolean-refusal-select
          id="bad-social-situation-person"
          :clearable="false"
          :state="state('badSituation')"
          :value="value.badSituation"
          @focus.native="clearError('badSituation')"
          @input="update($event, 'badSituation')"
        />
        <error-message
          :errors="errors"
          field="badSituation"
        />
      </div>
    </div>
  </form>
</template>

<script>
import ErrorMessage from "../Form/ErrorMessage";
import BooleanRefusalSelect from "../Form/Select/BooleanRefusalSelect";

export default {
  components: {ErrorMessage, BooleanRefusalSelect},
  props: {
    value: {type: Object, required: true},
    errors: {type: Array, default: () => []}
  },
  methods: {
    state(field) {
      return this.errors.find((error) => error.field === field) ? false : null;
    },
    clearError(field) {
      this.$emit("clearError", field);
    },
    update(event, field) {
      this.$emit("input", {...this.value, [field]: event})
    }
  }
}
</script>

<style scoped>
.min-height-58 {
  min-height: 58px;
}
</style>
