<template>
  <div>
    <div
      v-show="editing === false"
    >
      <read-more
        :text="entry.content"
        :max-chars="400"
        class="text-justify"
        more-str="Czytaj dalej"
        less-str="Mniej"
      />
      <span class="font-weight-bold">
        {{ entry.createdAt }} - {{ entry.creator.name }} {{ entry.creator.surname }}
      </span>
      <is-granted
        :privileges="['EDIT_THERAPEUTIC_PLAN_ENTRY']"
        :subject="entry.entryId"
      >
        <button
          v-if="editable"
          class="btn-light btn btn-sm m-1"
          @click="editing = true"
        >
          <i class="fa fa-edit" /> Edytuj
        </button>
      </is-granted>
    </div>
    <text-entry-edit
      v-if="editable "
      v-show="editing"
      :entry="entry"
      @entry-edited="editedEntry"
      @cancel-edit="editing = false"
    />
  </div>
</template>

<script>
import ReadMore from "../../ReadMore";
import TextEntryEdit from "./TextEntryEdit";
import IsGranted from "../../IsGranted";

export default {
  name: "EntryEdit",
  components: {
    IsGranted,
    TextEntryEdit,
    ReadMore,
  },
  props: {
    entry: {type: Object, required: true},
    readOnly: {type: Boolean, required: true},
  },
  data() {
    return {
      editable: true,
      editing: false,
    };
  },
  methods: {
    editedEntry(editedEntry) {
      this.editing = false;
      this.$emit("entry-edited", editedEntry);
    },
  },
}
</script>
