











































import {Mixins, Prop} from "vue-property-decorator";
import ErrorsMixin from "../../mixins/ErrorsMixin.ts";
import I18n from "../i18n.vue";
import ErrorMessage from "../Form/ErrorMessage.vue";
import Component from "vue-class-component";
import type {EstablishmentItem} from "../../types/Establishment";
import processResponseException from "../../utils/errors/processResponseException";
import remove from "../../rest/remove";

interface SubmitData {
  branchId: string|null;
}

@Component({
  name: "RemoveEstablishmentChildModal",
  components: {ErrorMessage, I18n}
})
export default class RemoveEstablishmentChildModal extends Mixins(ErrorsMixin) {
  @Prop({type: Object, default: null}) readonly item!: EstablishmentItem;

  loading: boolean = false;

  closeModal() {
    this.$bvModal.hide("remove-establishment-child-modal");
  }

  resetModalData(): void {
    this.errors = [];
  }

  async submit(): Promise<void> {
    this.errors = [];
    this.loading = true;
    const data: SubmitData = {
      branchId: this.item ? this.item.branchId : null,
    };
    try {
      await remove<SubmitData>("/api/branches", data);
      this.closeModal();
      this.$emit("removed");
    } catch(e) {
      this.errors = processResponseException(e);
    }
    this.loading = false;
  }

  onHide(): void {
    this.resetModalData();
    this.$emit("close");
  }
}
