<template>
  <div class="d-flex justify-content-between">
    <div>{{ title }}</div>
    <div
      :class="{'pl-2 border-left': lastEditedEntryDate}"
      class="ml-2"
    >
      <template v-if="lastEditedEntryDate">
        Ost. wpis {{ lastEditedEntryDate }}
      </template>
      <b-badge
        v-if="showCount"
        variant="primary"
        pill
      >
        {{ entries.length }}
      </b-badge>
    </div>
  </div>
</template>

<script>
import parseDate from "../../utils/date/parseDate";
import stringifyDate from "../../utils/date/stringifyDate";
import DATE_FORMAT from "../../utils/date/DATE_FORMAT";

export default {
  name: "DiagnosisTitle",
  props: {
    title: {type: String, default: ""},
    entries: {type: Array, default: ()=>[]},
    showCount: {type: Boolean, default: true},
  },
  computed: {
    lastEditedEntryDate() {
      const lastDate = this.entries.reduce((date, entry) => {
        if (!entry.createdAt) {
          return null;
        }
        const entryDate = parseDate(entry.createdAt);
        return date && date.getTime() > entryDate.getTime()
          ? date
          : entryDate;
      }, null);
      return lastDate ? stringifyDate(lastDate, DATE_FORMAT.DATE_TIME) : null;
    },
  },
}
</script>

<style scoped>

</style>
