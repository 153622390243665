






























import Vue from "vue";
import Component from "vue-class-component";
import CalendarColor from "./CalendarColor.vue";

@Component({
  name: "DayCalendarLegend",
  components: {CalendarColor}
})
export default class DayCalendarLegend extends Vue {

}
