<template>
  <div id="map" />
</template>

<script>
import read from "../../rest/read";

const defaultCoordinates = {latitude: "52.1737068", longitude: "21.0559251"};

export default {
  props:{
    localizations: {type: Array, default: () => []},
    highlighted: {type: String, default: null}
  },

  data() {
    return {
      map: null,
      clinicCoordinates: defaultCoordinates,
      markers: [],
    }
  },

  watch: {
    localizations: {
      immediate: true,
      handler() {
        if (this.localizations.length > 0 && this.map === null) {
          this.initGoogleMaps();
        }
      },
    },
    highlighted() {
      if(this.highlighted !== null) {
        this.highlightWorker();
      } else {
        this.displayAllMarkers();
      }
    }
  },

  methods: {
    async initGoogleMaps(){
      window.google = {};
      let script = document.createElement("script");
      script.src =
        "https://maps.googleapis.com/maps/api/js?key=AIzaSyCc8ooq3OayCNMiJkYDZdP4WU6bArnXBos";
      document.head.appendChild(script);
      script.addEventListener("load", () => {
        this.renderGoogleMaps()
      });
    },
    async renderGoogleMaps(){
      await this.loadClinicGeolocalization();

      const coordinates = {
        lat: parseFloat(this.clinicCoordinates.latitude),
        lng: parseFloat(this.clinicCoordinates.longitude)
      };

      this.map = new google.maps.Map(document.getElementById("map"), {
        center: coordinates,
        zoom: 9
      });
      this.createMarkers(this.localizations);
    },
    createMarkers(value){
      this.markers = value.map((localization) => {
        return new google.maps.Marker(
          {
            position: {
              lat: parseFloat(localization.coordinates.latitude),
              lng: parseFloat(localization.coordinates.longitude)
            },
            map: this.map,
            title: localization.workerFullName
          },
        );
      });
    },
    async loadClinicGeolocalization(){
      try{
        const {coordinates} = await read("/api/clinic-geolocalization");
        if(null !== coordinates){
          this.clinicCoordinates =  coordinates;
        }
      } catch (e){
        console.error(e);
      }

    },
    highlightWorker(){
      let worker = this.localizations.find(item => item.workerId === this.highlighted);
      let position = {
        lat: parseFloat(worker.coordinates.latitude),
        lng: parseFloat(worker.coordinates.longitude)
      };
      this.displayOnlyHighlightedMarker();
      this.map.setCenter(position);
    },
    clearMakers(){
      this.markers.forEach((marker) => {
        marker.setMap(null);
      });
      this.markers = [];
    },
    displayOnlyHighlightedMarker(){
      this.clearMakers();
      let highlightedLocations = this.localizations.filter(value => {
        if(value.workerId === this.highlighted){
          return value;
        }
      });
      this.createMarkers(highlightedLocations);
    },
    displayAllMarkers(){
      this.clearMakers();
      this.createMarkers(this.localizations);
    }
  }
}
</script>


<style scoped>
  #map {
    width: 100%;
    height: 500px;
    background-color: grey;
  }
</style>