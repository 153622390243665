<template>
  <b-modal
    id="logout-modal"
    no-close-on-backdrop
    title="Wyloguj"
    title-tag="h3"
  >
    Czy na pewno chcesz się wylogować?
    <template #modal-footer>
      <button
        class="btn btn-secondary"
        @click="closeLogoutModal"
      >
        Anuluj
      </button>
      <button
        class="btn btn-primary"
        @click="logout"
      >
        <i class="fa fa-sign-out-alt" /> Wyloguj
      </button>
    </template>
  </b-modal>
</template>

<script>
import read from "../../rest/read";
import {mapActions} from "vuex";

export default {
  methods: {
    async logout(){
      await read("/api/logout");
      this.clearSession();
    },
    closeLogoutModal() {
      this.$bvModal.hide("logout-modal");
    },
    ...mapActions({clearSession: "session/clear"})
  },
}
</script>
