<template>
  <anonymous-layout>
    <b-alert
      :show="showMessage"
      variant="info"
      dismissible
      @dismissed="goToWorkersList"
    >
      Wysłano link do resetu hasła
      <template v-if="worker">
        na adres: {{ worker.email }}
      </template>
    </b-alert>
    <div 
      class="m-b-20 mx-auto text-center" 
      style="width: 40vw; max-width: 100%;"
    >
      <logotype
        :width="290" 
        class="img-fluid"
      />
    </div>
    <form
      class="text-center text-white"
      @submit.prevent="resetPassword"
    >
      <h3 class="m-b-20">
        Czy na pewno chcesz zmienić hasło?
      </h3>
      <p class="m-b-20">
        Link do zmiany hasła będzie aktywny tylko przez jedną dobę od wysłania.
      </p>
      <div class="d-flex justify-content-center">
        <button
          :disabled="loading"
          type="submit" 
          class="btn btn-light"
        >
          Tak, zmieniam hasło
        </button>
        <router-link
          :to="{name: 'worker_list'}"
          class="btn btn-secondary m-l-15"
        >
          Powrót
        </router-link>
      </div>
    </form>
  </anonymous-layout>
</template>

<script>
import create from "../../rest/create";
import AnonymousLayout from "../../components/Layout/AnonymousLayout";
import Logotype from "../../components/Layout/Logotype";
import read from "../../rest/read";

export default {
  name: "RequestPasswordChange",
  components: {Logotype, AnonymousLayout},
  props: {
    workerId: {type: String, required: true},
  },
  data() {
    return {
      showMessage: false,
      worker: null,
      loading: false,
    };
  },
  methods: {
    async resetPassword() {
      this.loading = true;
      this.worker = await read(`/api/worker/${this.workerId}`);
      await create("/api/users/password-reset-requests", {
        workerId: this.workerId,
        type: "workerId"
      });
      this.showMessage = true;
    },
    goToWorkersList() {
      this.$router.push({name: "worker_list"});
    },
  }
}
</script>
