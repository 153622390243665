<template>
  <is-granted
    :privileges="['INTERVENTION_NOTIFICATION_LIST']"
    component="fragment"
  >
    <button
      v-b-modal.interventionNotification
      class="btn btn-primary"
    >
      <i class="fas fa-file-alt" />
    </button>
  </is-granted>
</template>

<script>
import IsGranted from "../IsGranted";
export default {
  components: {IsGranted}
}
</script>
