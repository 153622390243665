import {ClinicParametersState} from "./clinicParameters/types";
import {actions} from "./clinicParameters/actions";
import {getters} from "./clinicParameters/getters";
import {mutations} from "./clinicParameters/mutations";
import {Module} from "vuex";
import {getInitialState} from "./clinicParameters/initialState";
import {RootState} from "../types";

export const CLINIC_PARAMETERS = "auxilio.clinicParameters";
export const storage = localStorage;

const clinicParameters: Module<ClinicParametersState, RootState> = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state: getInitialState(),
};

export default clinicParameters;
