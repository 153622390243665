<template>
  <div class="table-responsive">
    <table class="table">
      <thead>
        <tr>
          <th scope="col">
            Imię i nazwisko
          </th>
          <th scope="col">
            PESEL
          </th>
          <th scope="col">
            Powiązanie
          </th>
          <th scope="col">
            Telefon / E-mail
          </th>
          <th scope="col">
            Adres
          </th>
          <th scope="col">
            Dodatkowy opis
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(person, index) in eligiblePersons"
          :key="index"
        >
          <td>{{ person.name }} {{ person.surname }}</td>
          <td>{{ person.pesel }}</td>
          <i18n
            :msgid="`@person-eligible.${person.type}`"
            component="td"
          />
          <td>{{ person.phoneNumber }} / {{ person.email }}</td>
          <td>{{ person.address.street }} {{ person.address.zipCode }} {{ person.address.city }}</td>
          <td>{{ person.description }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import read from "../../../../rest/read";
import I18n from "../../../i18n";

export default {
  components: {I18n,},
  props: {
    patientId: {type: String, required: true},
  },
  data() {
    return {
      eligiblePersons: [],
    }
  },
  watch: {
    patientId() {
      this.getRelationList();
    },
  },
  async mounted() {
    this.getRelationList();
  },
  methods: {
    async getRelationList(){
      const response = await read(`/api/patients/${this.patientId}/persons-eligible`);
      this.eligiblePersons = response.eligiblePersons;
    },
  },
}
</script>
