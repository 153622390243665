<template>
  <div>
    <div class="row">
      <div class="col-sm-12">
        <div
          class="page-title-box"
          style="min-height: 70px;"
        >
          <loading-mask
            v-if="loading"
            loading
          />
          <appointment-document-title-box
            v-else
            :access-to-patient-card="hasAccessToPatientCard"
            :appointment-document-id="formData.appointmentDocumentId"
            :appointment-id="appointmentId"
            :branch-id="formData.branchId"
            :close="formData.close"
            :date-info="appointmentDateInfo"
            :is-autosave="isAutosave"
            :is-dirty="formData.isDirty"
            :is-saving="formData.isSaving"
            :patient="formData.patient"
            :printable="formData.printable"
            :read-only="formData.readOnly"
            :return-url="returnUrl"
            :scheduled-date="documentsData[0].scheduledDate"
            :status="formData.status"
            :treatment-name="formData.treatmentName"
            @autosaveToggle="isAutosave = !isAutosave"
            @fetchData="fetchData"
            @save="save"
            @updateBranchId="updateBranchId"
          />
        </div>
      </div>
    </div>
    <template v-if="!loading">
      <error-message :errors="errors" />
      <error-message
        :errors="errors"
        :bordered="true"
        field="continuousStayRange"
      />
      <appointment-hospitalizations
        v-if="formData.patient"
        :patient-id="formData.patient.patientId"
      />
      <appointment-document-cancel-reason
        v-if="formData.cancelReason"
        :cancel-comment="formData.cancelComment"
        :cancel-reason="formData.cancelReason"
        :title="formData.title"
      />
      <appointment-plan :appointment-id="appointmentId" />
      <div class="row">
        <appointment-document-select
          v-if="documentsData.length > 1"
          class="col-lg-3"
          :documents-data="documentsData"
          :selected-document="selectedDocumentIdx"
          @select="changeDocument"
        />
        <div
          :class="{'col-lg-9': documentsData.length > 1 }"
          class="col-12"
        >
          <template v-if="displayDocument">
            <appointment-document-modules
              v-if="!formData.cancelReason"
              :modules="formData.modules"
              :selected-document-idx="selectedDocumentIdx"
              :patient="formData.patient"
              :read-only="formData.readOnly"
              :appointment-document-id="formData.appointmentDocumentId"
              :continuous-stay-id="formData.continuousStayId"
              :appointment-date="appointmentDate"
              :appointment-id="appointmentId"
              :canceled="formData.cancelReason !== null"
              :appointment-end-date="appointmentEndDate"
              @saveCreateRedirect="saveCreateRedirect"
              @updateModule="updateModule"
              @updateContinuousStayId="updateContinuousStayId"
            />
            <patient-attachments-card
              v-if="formData.patient && formData.appointmentDocumentId"
              :appointment-id="appointmentId"
              :appointment-document-id="formData.appointmentDocumentId"
              :patient-id="formData.patient.patientId"
              :continuous-stay-id="documentsData[selectedDocumentIdx].continuousStayId"
              :scope="documentsData[selectedDocumentIdx].continuousStay ? ['continuousStay'] : ['ambulatory']"
              hide-appointment-ref-options
            />
          </template>
          <appointment-document-hidden-info
            v-else
            :blocked="formData.blocked"
            :display-medical-content="formData.displayMedicalContent"
          />
        </div>
      </div>
      <div class="d-flex justify-content-between flex-wrap w-100">
        <router-link
          class="btn btn-default mr-auto"
          :to="returnUrl"
        >
          <i class="fa fa-chevron-left" />
          Wróć na {{ hasAccessToPatientCard && formData.patient ? "kartę pacjenta" : "listę wizyt" }}
        </router-link>
        <appointment-document-save-buttons
          class="text-right ml-auto"
          :close="formData.close"
          :is-saving="formData.isSaving"
          :read-only="formData.readOnly"
          :scheduled-date="documentsData[0].scheduledDate"
          :status="formData.status"
          @save="save"
        />
      </div>
      <finish-appointment-modal
        :value="{timeSpent: formData.scheduledDuration, branchId: formData.branchId}"
        :document-errors="errors"
        :finished-documents="selectedPatientFinishedDocuments"
        :loading="loading"
        @forceFinishAppointment="forceFinishAppointment"
        @hide="forceStopAutosave = false"
        @input="updateFinishAppointmentData"
        @show="forceStopAutosave = true"
      />
    </template>
  </div>
</template>

<script>
import ErrorMessage from "../../components/Form/ErrorMessage";
import read from "../../rest/read";
import processResponseException from "../../utils/errors/processResponseException";
import update from "../../rest/update";
import {BadRequestException, ForbiddenException} from "../../rest";
import scrollPageToTop from "../../utils/scrollPageToTop";
import AppointmentDocumentSelect from "../../components/Appointment/AppointmentDocumentSelect";
import {isGranted} from "../../security/isGranted";
import parseDate from "../../utils/date/parseDate";
import stringifyDate from "../../utils/date/stringifyDate";
import DATE_FORMAT from "../../utils/date/DATE_FORMAT";
import debounce from "lodash/debounce";
import isEqual from "lodash/isEqual";
import {mapActions, mapMutations, mapState} from "vuex";
import FinishAppointmentModal from "../../components/Appointment/AppointmentActions/FinishAppointmentModal";
import LoadingMask from "../../components/Loading/LoadingMask";
import PatientAttachmentsCard from "../../components/Patient/PatientCard/Attachment/PatientAttachmentsCard";
import {errorsMixin} from "../../mixins/errorsMixin.js";
import AppointmentPlan from "../../components/Appointment/AppointmentPlan";
import AppointmentDocumentHiddenInfo from "../../components/Appointment/AppointmentDocumentHiddenInfo";
import AppointmentDocumentCancelReason from "../../components/Appointment/AppointmentDocumentCancelReason";
import AppointmentDocumentSaveButtons from "../../components/Appointment/AppointmentDocumentSaveButtons";
import AppointmentHospitalizations from "../../components/Appointment/AppointmentHospitalizations";
import AppointmentDocumentTitleBox from "../../components/Appointment/AppointmentDocumentTitleBox";
import AppointmentDocumentModules from "../../components/Appointment/DocumentModules/AppointmentDocumentModules";

const deferred = debounce((f) => f(), 60000, {maxWait: 100000});

export default {
  name: "Document",
  components: {
    AppointmentDocumentModules,
    AppointmentDocumentTitleBox,
    AppointmentDocumentCancelReason,
    AppointmentPlan,
    AppointmentDocumentHiddenInfo,
    AppointmentDocumentSaveButtons,
    AppointmentHospitalizations,
    PatientAttachmentsCard,
    LoadingMask,
    AppointmentDocumentSelect,
    ErrorMessage,
    FinishAppointmentModal,
  },
  mixins: [errorsMixin],
  beforeRouteUpdate(to, from, next) {
    this.changeView(to, from, next);
  },
  beforeRouteLeave(to, from, next) {
    this.changeView(to, from, next);
  },
  props: {
    appointmentId: {required: true, type: String}
  },
  data() {
    return {
      formData: {},
      documentsData: [],
      documentData: null,
      selectedDocumentIdx: 0,
      isAutosave: true,
      loading: true,
      forceStopAutosave: false,
      hasAccessToPatientCard: false,
    };
  },
  computed: {
    displayDocument() {
      const {blocked, displayMedicalContent, medicalContent} = this.formData;
      return !blocked && (!medicalContent || (medicalContent && displayMedicalContent));
    },
    ...mapState({
      currentUser: state => state.currentUser.user,
    }),
    appointmentDate() {
      return parseDate(this.documentsData[this.selectedDocumentIdx].scheduledDate);
    },
    appointmentEndDate() {
      return parseDate(this.documentsData[this.selectedDocumentIdx].endDate);
    },
    appointmentDateString() {
      return stringifyDate(this.appointmentDate, DATE_FORMAT.DATE);
    },
    appointmentTimeString() {
      return stringifyDate(this.appointmentDate, DATE_FORMAT.TIME);
    },
    appointmentEndTimeString() {
      return stringifyDate(this.appointmentEndDate, DATE_FORMAT.TIME);
    },
    appointmentDateInfo() {
      return `(${this.appointmentDateString} godz. ${this.appointmentTimeString} - ${this.appointmentEndTimeString})`;
    },
    hash() {
      return this.$route.hash.substring(1);
    },
    selectedPatientFinishedDocuments() {
      const selectedPatientId = this.formData.patient?.patientId || null;
      return this.documentsData.reduce((list, doc) => {
        const docPatientId = doc.patient?.patientId || null;
        if (doc.status === "finished" && selectedPatientId === docPatientId) {
          list = [...list, doc];
        }
        return list;
      }, []);
    },
    returnUrl() {
      return this.hasAccessToPatientCard && this.formData.patient
        ? `/patient/${this.formData.patient.patientId}/details`
        : "/appointments/list";
    }
  },
  watch: {
    hash(val) {
      if (val.length) {
        this.loadFromHash(val);
      }
    },
    "$route"(to, from) {
      if (to.path !== from.path) {
        this.fetchData();
      }
    },
  },
  async mounted() {
    try {
      await Promise.all([
        this.checkAccessToPatientCard(),
      ]);
    } catch (exception) {
      this.errors = processResponseException(exception);
    }
    await this.fetchData();
  },
  methods: {
    ...mapMutations("toastNotification", ["hideToast"]),
    ...mapActions("lastEditedAppointment", [
      "setAppointmentDetails",
      "clearAppointmentDetails",
    ]),
    ...mapActions("toastNotification", ["showToast"]),
    async checkAccessToPatientCard() {
      this.hasAccessToPatientCard = await isGranted(["PATIENT_CARD"]);
    },
    async fetchData() {
      this.loading = true;
      const {documents} = await read(`/api/appointment/${this.appointmentId}/appointment`);
      this.documentsData = documents.map(doc => ({
        ...doc,
        readOnly: true,
        patientCardUrl: "/appointments/list",
        indicators: [],
        isSaving: false,
        close: false,
        isDirty: false,
      }));
      if (!this.$route.hash) {
        await this.$router.replace({
          hash: `#appointmentDocumentId=${this.documentsData[this.selectedDocumentIdx].appointmentDocumentId}`,
        });
      } else {
        this.loadFromHash(this.hash);
      }
      this.documentData = this.documentsData[this.selectedDocumentIdx];
      this.formData = {...this.documentData};
      await this.checkPermissions();
      this.clearAppointmentDetails();
      if (this.documentsData[0].appointmentStatus === "opened") {
        this.setAppointmentDetails({
          id: this.appointmentId,
          treatmentTypeName: this.lastEditedAppointmentTreatmentName(),
          datesString: this.appointmentDateInfo,
        });
      }
      this.loading = false;
    },
    loadFromHash(hash) {
      const splitted = hash.split("=");
      if (splitted.length === 2 && splitted[0] === "appointmentDocumentId" && splitted[1].length) {
        const documentIndex = this.documentsData.map((doc) => {return doc.appointmentDocumentId}).indexOf(splitted[1]);
        if (this.selectedDocumentIdx !== documentIndex) {
          this.changeDocument(documentIndex);
        }
      }
    },
    async checkPermissions() {
      this.formData.readOnly = !(await isGranted("CHANGE_APPOINTMENT_DOCUMENT", this.formData.appointmentDocumentId));

      this.documentsData.forEach(async (value, index) => {
        this.documentsData[index].readOnly =
          !(await isGranted("CHANGE_APPOINTMENT_DOCUMENT", value.appointmentDocumentId));
      });
    },
    async save(close) {
      this.formData.isSaving = true;
      this.formData.close = close;
      this.resetErrors();

      try {
        this.formData.files = [];
        this.errors = [];
        await update(`/api/appointment/${this.appointmentId}/appointment`, {
          appointmentDocumentId: this.formData.appointmentDocumentId,
          modules: this.formData.modules,
          type: this.formData.type,
          name: this.formData.name,
          patient: this.formData.patient,
          mainWorker: this.formData.mainWorker,
          readOnly: close,
          timeSpent: (this.formData.timeSpent !== undefined)
            ? this.formData.timeSpent
            : this.formData.scheduledDuration,
          branchId: this.formData.branchId,
        });

        this.formData.readOnly = close;
        if (close) {
          this.formData.status = "finished";
        }
        close && this.documentsData.every(doc =>
          doc.appointmentDocumentId === this.formData.appointmentDocumentId || doc.readOnly)
          && this.clearAppointmentDetails();
        this.formData.isDirty = false;
        close ? await this.fetchData() : null;
        this.documentsData[this.selectedDocumentIdx] = {
          ...this.formData,
          isSaving: false,
        };
        if (this.documentsData.every(doc => doc.status === "finished")) {
          this.formData.printable = true;
        }
        this.closeConfirmDocumentModal();
        this.showToast({
          message: "Zmiany zostały zapisane",
          variant: "success",
        });
      } catch (exception) {
        if (exception instanceof BadRequestException) {
          const errors = exception.errors;
          this.hasBadRequestErr(errors, "continuousStayRange");
          const hasBranchIdErr = this.hasBadRequestErr(errors, "branchId");
          const hasTimeSpentErr = this.hasBadRequestErr(errors, "timeSpent");

          if (!hasBranchIdErr && !hasTimeSpentErr) {
            this.closeConfirmDocumentModal();
          }

          this.formData.modules = this.formData.modules.map((module, idx) => ({
            ...module, errors: errors[idx]
          }));
          this.showToast({
            message: "Formularz zawiera błędy.\nUzupełnij dane przed zamknięciem wizyty. ",
            variant: "danger",
          });
        }
        else if(exception instanceof ForbiddenException){
          this.closeConfirmDocumentModal();
          this.showToast({
            message: "Wystapił błąd podczas zapisywania dokumentu wizyty.\nDokument odświeży się w ciągu 5 sekund.",
            variant: "danger",
          });
          setTimeout(async ()=>{
            this.hideToast();
            await this.fetchData();
          }, 5000);
        } else {
          this.errors = processResponseException(exception);
          this.showToast({
            message: "Wystąpił błąd.\nZmiany nie zostały zapisane",
            variant: "danger",
          });
        }
      }
      this.formData.isSaving = false;
      this.formData.close = false;
    },
    async changeDocument(docIdx) {
      this.selectedDocumentIdx = docIdx;
      this.formData = {...this.documentsData[docIdx]};
      if(!this.displayDocument){
        this.formData.printable = false;
      }
    },
    async changeView(to, from, next) {
      this.forceStopAutosave = true;
      if (this.formData.isDirty) {
        await this.save(false);
      }
      next();
    },
    reload() {
      window.location.reload(true);
    },
    resetErrors() {
      this.formData.modules = this.formData.modules.map((module) => ({...module, errors: null}));
      this.hideToast();
    },
    updateBranchId(branchId) {
      this.formData.branchId = branchId;
    },
    updateModule({module, index}) {
      this.formData.modules = this.formData.modules.map((m, idx) => {
        if (index === idx) {
          if (!isEqual(m, module)) {
            this.formData.isDirty = true;
            this.hideToast();
          }
          return module;
        }
        return m;
      });
      if (this.isAutosave && this.formData.isDirty && !this.formData.readOnly) {
        this.forceStopAutosave = false;
        deferred(this.handleAutosave);
      }
    },
    async updateContinuousStayId(continuousStayId) {
      this.formData.continuousStayId = continuousStayId;
      if (!this.formData.readOnly) {
        await this.save(false);
      }
    },
    async handleAutosave() {
      if (this.forceStopAutosave) {
        return;
      }
      if (!this.formData.readOnly) {
        await this.save(false);
      }
    },
    async saveCreateRedirect(redirectUrl) {
      await this.save(false);
      await this.$router.push(redirectUrl);
    },
    lastEditedAppointmentTreatmentName() {
      const doc = this.documentsData.find(doc => doc.mainWorker.workerId === this.currentUser.workerId);
      return doc ? doc.treatmentName : this.documentsData[0].treatmentName;
    },
    closeConfirmDocumentModal(){
      this.$bvModal.hide("force-finish-appointment");
    },
    async forceFinishAppointment() {
      scrollPageToTop();
      await this.save(true);
    },
    updateFinishAppointmentData({timeSpent, branchId}) {
      this.formData.timeSpent = timeSpent;
      this.formData.branchId = branchId;
    },
    hasBadRequestErr(errors, field) {
      const error = errors.find(
        error => error !== null && error.field === field
      );
      if (error !== undefined) {
        this.errors.push(error);
      }
      return !!error;
    }
  },
};
</script>
