<template>
  <div class="topbar">
    <div class="topbar-left d-flex align-items-center">
      <side-bar-toggle />
      <router-link
        :to="{name: 'home'}"
        data-test="top-bar_logo"
        class="logo"
      >
        <div class="logotype-wrapper">
          <logotype
            class="img-fluid"
            :width="null"
          />
        </div>
      </router-link>
    </div>
    <nav class="navbar-custom d-flex align-items-center">
      <is-granted
        :privileges="['PATIENT_LIST']"
        component="fragment"
      >
        <sticky-patient-select
          data-test="top-bar__sticky-patient"
        />
      </is-granted>
      <ul class="list-inline menu-left mb-0 d-none">
        <is-granted
          :privileges="['ROLE_ALLOWED_TO_SWITCH']"
          component="li"
          data-test="top-bar__switch-user"
          class="d-none d-xl-block"
        >
          <switch-user-form />
        </is-granted>
        <is-granted
          :privileges="['ROLE_PREVIOUS_ADMIN']"
          component="li"
          data-test="top-bar_exit-user"
          class="d-none d-xl-block"
        >
          <tooltip
            :to="{query: {'_switch_user': 'exit'}}"
            component="router-link"
            class="nav-link waves-effect"
            tooltip-content="Powrót do Twojego konta"
          >
            <i class="fa fa-user-times" />
          </tooltip>
        </is-granted>
      </ul>
      <ul
        class="list-inline mb-0 ml-auto d-flex"
        style="height: 4.375rem;"
      >
        <li
          data-test="top-bar_register-patient"
          class="list-inline-item notification-list "
        >
          <register-patient-tooltip-button class="h-100" />
        </li>
        <li
          data-test="top-bar_intervention-notifications"
          class="list-inline-item notification-list d-none d-xl-inline-block"
        >
          <intervention-notification-button class="h-100" />
        </li>
        <li
          data-test="top-bar_web-notifications"
          class="list-inline-item notification-list lg-down-m-0"
        >
          <web-notifications class="h-100" />
        </li>
        <li
          data-test="top-bar_web-notifications"
          class="list-inline-item notification-list lg-down-m-0"
        >
          <user-notes-button class="h-100" />
        </li>
        <li
          data-test="top-bar_session-manager"
          class="list-inline-item notification-list lg-down-m-0"
        >
          <session-manager />
        </li>
        <li
          data-test="top-bar_user-menu"
          class="list-inline-item notification-list "
        >
          <user-menu class="h-100" />
        </li>
      </ul>
    </nav>
    <intervention-notification-modal />
    <user-notes-modal />
    <ue-modal />
    <helpdesk-widget />
  </div>
</template>

<script>
import SideBarToggle from "./SideBar/SideBarToggle";
import Logotype from "./Layout/Logotype";
import StickyPatientSelect from "./Patient/StickyPatientSelect";
import SwitchUserForm from "./SwitchUserForm";
import IsGranted from "./IsGranted";
import Tooltip from "./Tooltip.vue";
import RegisterPatientTooltipButton from "./Patient/RegisterPatientButton/RegisterPatientTooltipButton";
import InterventionNotificationButton from "./InterventionNotification/InterventionNotificationButton";
import WebNotifications from "./WebNotifications/WebNotifications";
import SessionManager from "./SessionManager";
import UserMenu from "./UserMenu";
import InterventionNotificationModal from "./InterventionNotification/InterventionNotificationModal";
import UeModal from "./UEinformation/UeModal";
import HelpdeskWidget from "./Helpdesk/HelpdeskWidget";
import UserNotesButton from "./Notes/UserNotesButton";
import UserNotesModal from "./Notes/UserNotesModal";

export default {
  name: "TopBar",
  components: {
    HelpdeskWidget,
    SideBarToggle,
    Logotype,
    StickyPatientSelect,
    SwitchUserForm,
    IsGranted,
    Tooltip,
    RegisterPatientTooltipButton,
    InterventionNotificationButton,
    WebNotifications,
    SessionManager,
    UserMenu,
    InterventionNotificationModal,
    UeModal,
    UserNotesButton,
    UserNotesModal,
  },
}
</script>

<style scoped lang="scss">
  @import "../styles/variables";

  $top-bar-height: 4.375rem;

  .navbar-custom {
    min-height: $top-bar-height;
  }

  .logotype-wrapper {
    display: inline-block;
    height: $top-bar-height;
    width: auto;

    .enlarged & {
      display: none;
    }
  }

  @include media-breakpoint-down(lg) {
    .lg-down-m-0 {
      margin: 0;
    }
  }
</style>
