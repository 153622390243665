<template>
  <div>
    <room-occupancy-report-widget
      ref="roomOccupancyReportWidget"
      :filters="{start, branch, type, number, description, floor}"
    />
  </div>
</template>

<script>
import RoomOccupancyReportWidget from "../../components/RoomOccupancyReport/RoomOccupancyReportWidget";

export default {
  name: "RoomOccupancyReport",
  components: {RoomOccupancyReportWidget},
  props: {
    start: {type: String, default: null},
    branch: {type: String, default: null},
    type: {type: String, default: null},
    number: {type: String, default: null},
    description: {type: String, default: null},
    floor: {type: String, default: null},
  },
}
</script>
<style>
  @page {
    margin: 0;
    page-break-after: always;
  }
  .pdf-container {
    font-size: 9px;
  }
  .pdf-container .fc-toolbar h2 {
    font-size: 9px;
  }
  .pdf-container .fc-header-toolbar {
    margin-bottom: 0 !important;
  }
</style>
