




























import Vue from "vue";
import Icd10CodeSelect from "../Form/Select/Icd10CodeSelect.vue";
import type {ICD10Category, ICD10CodeOption, ICD10FormData} from "../../types/Zla";
import Icd10CategoriesCheckboxGroup from "./Icd10CategoriesCheckboxGroup.vue";
import {Component, Prop} from "vue-property-decorator";
import ErrorMessage from "../Form/ErrorMessage.vue";
import {Error} from "../../rest";

@Component({
  name: "Icd10ZlaForm",
  components: {ErrorMessage, Icd10CategoriesCheckboxGroup, Icd10CodeSelect}
})
export default class Icd10ZlaForm extends Vue {
  @Prop({type: Object, default: null}) readonly value!: ICD10FormData;
  @Prop({type: Array, default: ()=>[]}) readonly errorsProp!: Error[];

  private updateCode(codeOption: ICD10CodeOption | null): void {
    this.$emit("input", {
      ...this.value,
      icd10code: codeOption,
    })
  }

  private updateCategories(categories: ICD10Category[]) {
    this.$emit("input", {
      ...this.value,
      icd10categories: categories,
    })
  }

  private state(field: string): boolean|null {
    return this.errorsProp.find((error) => error.field === field) ? false : null;
  }
}
