<template>
  <div>
    <loading-mask :loading="loading">
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th>
                Zakres
              </th>
              <th>
                Cel
              </th>
              <th>
                Kolejne zadania w realizacji celu / plan działań
              </th>
              <th>
                Czas realizacji
              </th>
              <th>
                Osoba wspierająca
              </th>
              <th>
                Stopień realizacji
              </th>
              <th>
                Wykonanie
              </th>
              <th class="text-right">
                Opcje
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(task, index) in oldTasks">
              <therapeutic-task-row
                :key="task.therapeuticTaskId"
                v-model="oldTasks[index]"
                :read-only="readOnly"
                :removing="removing && removingTaskId === task.therapeuticTaskId"
                @task-remove="openConfirmRemove"
                @task-updated="updated"
              />
              <tr
                v-if="removingErrors[task.therapeuticTaskId]"
                :key="`${task.therapeuticTaskId}-errors`"
              >
                <td
                  colspan="8"
                  class="text-center border-0"
                >
                  <error-message :errors="removingErrors[task.therapeuticTaskId]" />
                </td>
              </tr>
            </template>
            <tr v-if="!oldTasks.length">
              <td
                colspan="4"
                class="text-center text-muted"
              >
                Żadne zadanie nie pasuje do podanych kryteriów...
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <therapeutic-task-add
        v-if="!readOnly"
        :therapeutic-plan-id="therapeuticPlanId"
        @new-task-added="added"
      />
      <b-modal
        id="confirmRemoveTherapeuticTask"
        no-close-on-backdrop
        title="Potwierdź usunięcie planu terapeutycznego"
        title-tag="h3"
      >
        <template #default>
          <span class="font-18">
            Czy chcesz usunąć plan terapeutyczny?
          </span>
        </template>
        <template #modal-footer>
          <button
            type="button"
            class="btn btn-secondary"
            @click="closeConfirmRemove"
          >
            Anuluj
          </button>
          <button
            :disabled="removing"
            class="btn btn-primary"
            @click="removeTask"
          >
            <i
              :class="removing ? 'fa-spin fa-spinner' : 'fa-check'"
              class="fa"
            />
            Usuń
          </button>
        </template>
      </b-modal>
    </loading-mask>
  </div>
</template>

<script>
import read from "../../../../rest/read";
import remove from "../../../../rest/remove";
import LoadingMask from "../../../Loading/LoadingMask";
import processResponseException from "../../../../utils/errors/processResponseException";
import ErrorMessage from "../../../Form/ErrorMessage";
import TherapeuticTaskAdd from "./TherapeuticTaskAdd";
import TherapeuticTaskRow from "./TherapeuticTaskRow";

export default {
  name: "TherapeuticTasks",
  components: {TherapeuticTaskRow, TherapeuticTaskAdd, ErrorMessage, LoadingMask},
  props: {
    therapeuticPlanId: {type: String, required: true},
    readOnly: {type: Boolean, required: true},
  },
  data() {
    return {
      oldTasks: [],
      loading: false,
      removing: false,
      removingTaskId: null,
      removingErrors: {},
    };
  },
  async mounted() {
    this.loading = true;

    await this.loadTasks();

    this.loading = false;
  },
  methods: {
    async loadTasks() {
      const {items} = await read(`/api/ipzs/therapeutic-plans/${this.therapeuticPlanId}/therapeutic-tasks`);
      this.oldTasks = items;
      if (items.length > 0) {
        this.$emit("new-entry-added", this.oldTasks);
      }
    },
    added(task) {
      this.oldTasks = [task].concat(this.oldTasks);
      this.$emit("entry-updated", this.oldTasks);
    },
    async removeTask() {
      this.removing = true;

      try {
        await remove(`/api/therapeutic-tasks/${this.removingTaskId}`);
        this.removed(this.removingTaskId);
      } catch (exception) {
        this.removingErrors[this.removingTaskId] = processResponseException(exception);
      }

      this.removing = false;
      this.closeConfirmRemove();
    },
    removed(taskId) {
      this.oldTasks = this.oldTasks.filter(
        (task) => taskId !== task.therapeuticTaskId
      );
      this.$emit("entry-updated", this.oldTasks);
    },
    updated(task) {
      const index = this.oldTasks.findIndex(
        (old) => old.therapeuticTaskId === task.therapeuticTaskId
      );
      if (index > -1) {
        this.oldTasks = [
          ...this.oldTasks.slice(0, index),
          task,
          ...this.oldTasks.slice(index + 1)
        ]
      }
    },
    openConfirmRemove(taskId) {
      this.removingErrors = {
        ...this.removingErrors,
        [taskId]: [],
      };
      this.removingTaskId = taskId;
      this.$bvModal.show("confirmRemoveTherapeuticTask");
    },
    closeConfirmRemove() {
      this.$bvModal.hide("confirmRemoveTherapeuticTask");
      this.removingTaskId = null;
    },
  },
}
</script>
