<template>
  <card
    :loading="loading"
    title="Lista kursów"
    description="Lista kursów przeprowadzonych w ramach profilaktyki."
  >
    <course-list
      ref="courseList"
      :show-create-button="false"
      :show-notes="false"
      @loading="setLoading"
    />
  </card>
</template>
<script>
import Card from "../Card";
import CourseList from "../../views/Prevention/CourseList";

export default {
  components: {CourseList, Card},
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    setLoading(loading) {
      this.loading = loading;
    }
  }
}
</script>
