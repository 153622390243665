











import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";
import {TestResultStatus} from "../../../types/TestResult";
import TestResultsCard from "../../TestResults/TestResultsCard.vue";

interface Module {
  type: string;
}

@Component({
  name: "TestResultsModule",
  components: {
    TestResultsCard,
  }
})
export default class TestResultsModule extends Vue {
  @Prop({type: Number, required: true}) readonly idx!: number;
  @Prop({type: Object, required: true}) readonly module!: Module;
  @Prop({type: Boolean, required: true}) readonly readOnly!: boolean;
  @Prop({type: String, required: true}) readonly patientId!: string;
  @Prop({type: String, required: true}) readonly appointmentDocumentId!: string;
  @Prop({type: String, default: null}) readonly continuousStayId!: string | null;
  @Prop({type: Date, required: true}) readonly appointmentDate!: Date;

  private get status(): TestResultStatus[] {
    return this.readOnly
      ? [TestResultStatus.FINISHED]
      : [TestResultStatus.DRAFT];
  }
}
