<template>
  <div>
    <transition-group name="fade">
      <b-alert
        v-for="(alert, index) in alerts"
        :key="alert.id"
        :show="alert.message.length > 0"
        :variant="alert.className"
        dismissible
        @dismissed="alerts.splice(index, 1)"
      >
        {{ alert.message }}
      </b-alert>
    </transition-group>
    <div class="card-box">
      <is-granted :privileges="['CREATE_SYSTEM_NOTIFICATION']">
        <add-system-notification
          @clearAlerts="clearAlerts"
          @notification-created="notificationCreated($event)"
        />
      </is-granted>
      <is-granted :privileges="['GET_SYSTEM_NOTIFICATION_LIST']">
        <system-notifications-list
          :notifications="notifications"
        />
      </is-granted>
    </div>
  </div>
</template>

<script>
import AddSystemNotification from "../../components/SystemNotifications/AddSystemNotification.vue";
import SystemNotificationsList from "../../components/SystemNotifications/SystemNotificationsList.vue";
import IsGranted from "../../components/IsGranted";
import read from "../../rest/read";

export default {
  components: {
    AddSystemNotification,
    SystemNotificationsList,
    IsGranted,
  },
  data () {
    return {
      alerts: [],
      notifications: [],
    };
  },
  async mounted() {
    const {items} = await read("/api/system-notifications");
    this.notifications = items;
  },
  methods: {
    notificationCreated(v) {
      this.notifications.unshift(v.newNotification);
      this.showAlert(v.alert);
    },
    showAlert(v) {
      this.alerts.push(v);
    },
    clearAlerts() {
      this.alerts = [];
    }
  },
};
</script>
