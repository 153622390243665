<template>
  <report-container report-id="mhc_surveys">
    <div class="card-box no-label row">
      <div class="col-6">
        Raport zawiera informacje dotyczące:
        <ul>
          <li>MHC-SF Wersja dla młodzieży od 16 r.ż. i osób dorosłych</li>
        </ul>
      </div>
      <div class="col-6 text-right">
        <back-button :to="{name: 'reportsMainView'}" />
        <a
          href="/api/reports/mhc-surveys"
          class="btn btn-primary m-1"
        >
          <i class="fas fa-file-excel" />
          Generuj
        </a>
      </div>
    </div>
  </report-container>
</template>

<script>
import BackButton from "../../components/BackButton";
import ReportContainer from "../../components/Report/ReportContainer";

export default {
  components: {ReportContainer, BackButton},
}
</script>

<style scoped>

</style>
