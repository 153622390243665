
























































































import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";
import type {
  AdditionalRightsType,
  NfzBranch, Package,
  Patient,
  PrescriptionDrug,
} from "../../types/Prescription";
import MedicationType from "./MedicationType.vue";
import MedicationQuantity from "./MedicationQuantity.vue";
import MedicationForm from "./MedicationForm.vue";
import PrescriptionDate from "./PrescriptionDate.vue";
import PrescriptionNfzBranch from "./PrescriptionNfzBranch.vue";
import AdditionalRights from "./AdditionalRights.vue";
import stringifyDate from "../../utils/date/stringifyDate";
import DATE_FORMAT from "../../utils/date/DATE_FORMAT";
import {Error} from "../../rest";
import {isEmpty} from "lodash";
import ErrorMessage from "../Form/ErrorMessage.vue";
import subErrors from "../../utils/errors/subErrors";
import PrescriptionInsurance from "./PrescriptionInsurance.vue";

const warningField = "prescription-item-warning";

@Component({
  name: "PrescriptionPackage",
  components: {
    PrescriptionInsurance,
    ErrorMessage,
    AdditionalRights,
    PrescriptionDate,
    MedicationForm,
    MedicationSize: MedicationQuantity,
    MedicationType,
    PrescriptionNfzBranch,
  },
})
export default class PrescriptionPackage extends Vue {
  @Prop({type: Object, required: true}) readonly patient!: Patient;
  @Prop({type: Boolean, default: false}) readonly disabled!: boolean;
  @Prop({type: Object, required: true}) readonly value!: Package;
  @Prop({type: Array, default: ()=>[]}) readonly errors!: Array<Error>;
  @Prop({type: Array, default: ()=>[]}) readonly warnings!: Array<Error>;

  private get nfzBranch(): NfzBranch|null {
    return this.value.nfzBranch;
  }
  private get additionalRights(): AdditionalRightsType|{} {
    return this.value.additionalRights;
  };
  private get drugs(): Array<PrescriptionDrug> {
    return this.value.drugs;
  }

  private get today(): string {
    return stringifyDate(new Date(), DATE_FORMAT.DATE);
  }

  private globalPackageErrorClass(index: number): string|void {
    const errors = this.errors.filter(error => error.field === `prescription-item-${index}`);
    return errors.length ? "full text-center" : undefined;
  }

  private getPackageErrors(index: number = 0): Array<Error> {
    return subErrors(this.errors, `package[${index}]`);
  }

  private getWarnings(index: number): Array<Error> {
    return this.warnings.filter(warning => warning.field && warning.field.startsWith(`${warningField}-${index + 1}`));
  }
  private update($event: any, field: string): void {
    this.$emit("input", {
      ...this.value,
      [field]: $event,
    });
  }

  private updateDrugs($event: PrescriptionDrug| {}, index: number): void {
    const isRemoved = isEmpty($event) && (this.value.drugs.length !== 1);
    const drugs = isRemoved
      ? this.value.drugs.filter((drug, idx) => index !== idx)
      : this.value.drugs.map((drug, idx) => (index === idx) ? $event : drug);
    this.$emit("input", {
      ...this.value,
      drugs,
    });
    if (isRemoved) {
      this.$emit("removed", index);
    }
  }

  private addDrug(): void {
    this.$emit("addDrug");
  }
}
