














































































import {Component, Mixins, Prop, Watch} from "vue-property-decorator";
import ErrorsMixin from "../../mixins/ErrorsMixin";
import Card from "../Card.vue";
import processResponseException from "../../utils/errors/processResponseException";
import read from "../../rest/read";
import type {Pagination} from "../../types/Pagination";
import type {TestResult} from "../../types/TestResult";
import {TestResultModalType, TestResultStatus} from "../../types/TestResult";
import ErrorMessage from "../Form/ErrorMessage.vue";
import PaginationDescription from "../Pagination/PaginationDescription.vue";
import TestResultsList from "./TestResultsList.vue";
import CreateTestResultModal from "./CreateTestResultModal.vue";
import TestResultPreviewEditModal from "./TestResultPreviewEditModal.vue";
import IsGranted from "../IsGranted.vue";

@Component({
  name: "TestResultsCard",
  components: {
    IsGranted,
    TestResultPreviewEditModal,
    CreateTestResultModal,
    TestResultsList,
    PaginationDescription,
    ErrorMessage,
    Card,
  }
})
export default class TestResultsCard extends Mixins(ErrorsMixin) {
  @Prop({type: String, required: true}) readonly patientId!: string;
  @Prop({type: Array, default: null}) readonly status!: TestResultStatus[] | null;
  @Prop({type: String, default: null}) readonly appointmentDocumentId!: string | null;
  @Prop({type: String, default: null}) readonly continuousStayId!: string | null;
  @Prop({type: String, default: "Wyniki testów psychologicznych"}) readonly title!: string;
  @Prop({type: Date, default: null}) readonly appointmentDate!: Date|null;
  @Prop({type: Boolean, default: false}) readonly readOnly!: boolean;

  private loading: boolean = false;
  items: TestResult[] = [];
  private pagination: Pagination = {
    currentPage: 1,
    perPage: 5,
    totalRows: 0,
  }
  private selectedItemId: string|null = null;
  private modalType: TestResultModalType = TestResultModalType.PREVIEW;
  TestResultModalType = TestResultModalType;

  get createTestResultId(): string {
    return "create-test-result";
  }

  get testResultPreviewEditId(): string {
    return "test-result-preview-edit";
  }

  @Watch("patientId", {immediate: true}) async onPatientIdChange(): Promise<void> {
    await this.getData();
  }

  @Watch("appointmentDocumentId",) async onaApointmentIdChange(): Promise<void> {
    await this.getData();
  }

  @Watch("continuousStayId") async onContinuousStayIdChange(): Promise<void> {
    await this.getData();
  }

  private async getData(): Promise<void> {
    this.loading = true;
    try {
      const {items, pagination} = await read<{
        items: TestResult[],
        pagination: Pagination
      }>("/api/test-results", {
        patientId: this.patientId,
        status: this.status,
        appointmentDocumentId: this.appointmentDocumentId,
        continuousStayId: this.continuousStayId,
        page: this.pagination.currentPage,
        perPage: this.pagination.perPage,
      });
      this.items = items;
      this.pagination = pagination;
    } catch(e) {
      this.errors = processResponseException(e)
    }
    this.loading = false;
  }

  private changePage(page: number): void {
    this.pagination.currentPage = page;
    this.getData();
  }

  private openTestResultPreview(testResultId: string, modalType: TestResultModalType): void {
    this.selectedItemId = testResultId;
    this.modalType = modalType;
    this.$bvModal.show(this.testResultPreviewEditId);
  }

  private onPreviewEditModalClose(): void {
    this.selectedItemId = null;
    this.modalType = TestResultModalType.PREVIEW;
  }
}
