<template>
  <div>
    <a
      :href="`/api/appointments/plan?patientId=${patientId}`"
      class="btn btn-secondary btn-sm m-b-10"
      target="_blank"
      title="Pobierz plan wizyt"
    >
      <i class="fa fa-print" />
      Drukuj
    </a>

    <is-granted
      :privileges="[
        'SCHEDULE_APPOINTMENT', 'SCHEDULE_APPOINTMENT_SPECIALIST', 'SCHEDULE_APPOINTMENT_RECOVERY_ASSISTANT'
      ]"
    >
      <router-link
        :to="{name: 'calendarView', query: {continuousStay: false}}"
        class="btn btn-success btn-sm m-b-10 ml-2"
      >
        <i class="fa fa-calendar-plus" />
        Umów wizytę
      </router-link>
      <router-link
        :to="{name: 'calendarView', query: {
          continuousStay: true,
          view: 'day',
          startDate,
          endDate,
        }}"
        class="btn btn-outline-success btn-sm m-b-10 ml-2"
      >
        <i class="fa fa-calendar-plus" />
        Przyjmij na OD
      </router-link>
    </is-granted>

    <router-link
      :to="`/appointments/list?patientId=${patientId}`"
      class="btn btn-primary btn-sm m-b-10 ml-2"
      title="Przejdź na stronę planu wizyt"
    >
      <i class="fa fa-arrow-right" />
      Pokaż plan wizyt
    </router-link>
  </div>
</template>

<script>
import IsGranted from "../../components/IsGranted";
import stringifyDate from "@/utils/date/stringifyDate";
import DATE_FORMAT from "@/utils/date/DATE_FORMAT";

export default {
  components: {
    IsGranted
  },
  props: {
    patientId: {type: String, required: true},
  },
  data() {
    const start = new Date();
    start.setHours("00","00");
    const end = new Date(start).setDate(start.getDate() + 1);
    return {
      startDate: stringifyDate(start, DATE_FORMAT.DATE_TIME),
      endDate: stringifyDate(end, DATE_FORMAT.DATE_TIME),
    }
  },
}
</script>
