<template>
  <card :title="title">
    <template #default>
      <div class="row">
        <attachments-categories
          ref="categoryTypes"
          :appointment-document-id="appointmentDocumentId"
          :appointment-id="appointmentId"
          class="col-auto categories"
          :continuous-stay-id="continuousStayId"
          :patient-id="patientId"
          :scope="scope"
          :value="category"
          @input="changeCategory"
        />
        <loading-mask
          v-if="category"
          class="col content"
          :loading="loading"
        >
          <div class="input-group">
            <b-input
              v-model="phrase"
              placeholder="Nazwa pliku"
              @input="onPhraseChange"
              @keydown.enter="loadItems"
            />
            <div class="input-group-append">
              <button
                class="btn btn-light"
                @click="loadItems"
              >
                <i class="fa fa-search" /> Szukaj
              </button>
              <button
                v-if="enableAdd"
                class="btn btn-primary btn-sm"
                @click="modal=true"
              >
                <i class="fa fa-plus" /> Dodaj załącznik
              </button>
            </div>
          </div>
          <patient-attachments-list
            :attachments="visibleItems"
            :patient-id="patientId"
            :hide-appointment-ref-options="hideAppointmentRefOptions"
            @categoryChange="updateAttachmentCategory"
            @filenameChange="updateAttachmentFilename"
            @appointmentChange="updateAttachmentAppointment"
            @attachmentDeleted="deleteAttachment"
          />
          <pagination-description
            :items-count="items.length"
            :page="pagination.currentPage"
            :per-page="pagination.perPage"
            :total-count="pagination.totalRows"
          >
            <b-pagination
              v-model="pagination.currentPage"
              :total-rows="pagination.totalRows"
              :per-page="pagination.perPage"
              align="right"
              class="mb-0"
              @input="loadItems"
            />
          </pagination-description>
        </loading-mask>
        <div
          v-else
          class="col content"
        >
          <no-attachments-panel
            icon-class="fa-folder-open ml-4"
            panel-text="Nie wybrano kategorii"
          />
        </div>
      </div>
      <add-patient-attachment-modal
        :visible="modal"
        :errors="errors"
        :disabled="loading"
        @close="modal=false;errors=[]"
        @save="createAttachments"
      />
    </template>
  </card>
</template>

<script>
import Card from "../../../Card";
import read from "../../../../rest/read";
import AddPatientAttachmentModal from "./AddPatientAttachmentModal";
import create from "../../../../rest/create";
import parseDate from "../../../../utils/date/parseDate";
import PatientAttachmentsList from "./PatientAttachmentsList";
import AttachmentsCategories from "./AttachmentsCategories";
import LoadingMask from "../../../Loading/LoadingMask";
import PaginationDescription from "../../../Pagination/PaginationDescription";
import {mapState} from "vuex";
import {dimPartners} from "@/utils/partners/partners";
import NoAttachmentsPanel from "./NoAttachmentsPanel";
import processResponseException from "../../../../utils/errors/processResponseException";

export default {
  name: "PatientAttachmentsCard",
  components: {
    NoAttachmentsPanel,
    PaginationDescription,
    LoadingMask,
    AttachmentsCategories,
    PatientAttachmentsList,
    AddPatientAttachmentModal,
    Card,
  },
  props: {
    patientId: {type: String, required: true},
    appointmentId: {type: String, default: null},
    appointmentDocumentId: {type: String, default: null},
    continuousStayId: {type: String, default: null},
    hideAppointmentRefOptions: {type: Boolean, default: false},
    title: {type: String, default: "Załączniki"},
    scope: {type: Array, default: null},
    enableAdd: {type: Boolean, default: true},
  },
  data() {
    return {
      loading: false,
      modal: false,
      phraseFilterTimeout: null,
      category: null,
      phrase: "",
      errors: [],
      items: [],
      pagination: {
        currentPage: 1,
        perPage: 10,
        totalRows: 0,
      },
    };
  },
  computed: {
    ...mapState({
      partner: state => state.clinicParameters.parameters.partner
    }),
    visibleItems() {
      if (null == this.phraseFilterTimeout) {
        return this.items;
      }
      return this.items.filter((item) => item.name.includes(this.phrase));
    },
  },
  watch: {
    patientId() {
      this.category = null;
      this.phrase = "";
      this.pagination.currentPage = 1;
      this.loadItems();
    },
    continuousStayId() {
      this.pagination.currentPage = 1;
      this.loadItems();
    },
  },
  methods: {
    changePage(page) {
      this.pagination.currentPage = page;
      this.loadItems();
    },
    changeCategory(category) {
      if (category === this.category) {
        this.category = null;
      } else {
        this.category = category;
      }
      this.pagination.currentPage = 1;
      this.loadItems();
    },
    onPhraseChange() {
      clearTimeout(this.phraseFilterTimeout);
      this.phraseFilterTimeout = setTimeout(() => this.loadItems(), 1000);
    },
    async loadItems() {
      this.loading = true;
      clearTimeout(this.phraseFilterTimeout);
      this.phraseFilterTimeout = null;
      const query = {
        patientId: this.patientId,
        category: this.category,
        phrase: this.phrase,
        page: this.pagination.currentPage,
        perPage: this.pagination.perPage,
        orderDesc: true,
        continuousStayId: this.continuousStayId,
        scope: this.scope,
      };

      if (!dimPartners.includes(this.partner)) {
        query.appointmentId = this.appointmentId;
        query.appointmentDocumentId = this.appointmentDocumentId;
      }
      
      const {items, pagination} = await read("/api/attachments", query);
      this.pagination = pagination;
      this.items = items.map((item) => ({
        ...item,
        uploadDate: parseDate(item.uploadDate),
      }));
      this.loading = false;
    },
    async createAttachments(attachments) {
      const files = attachments.files;
      if (!files.length) {
        this.modal = false;
        return;
      }
      this.loading = true;
      try {
        await Promise.all(files.map(async (file) => {
          await create("/api/attachments", {
            patientId: this.patientId,
            fileId: file.fileId,
            appointmentId: this.appointmentId,
            appointmentDocumentId: this.appointmentDocumentId,
            category: this.category,
            continuousStayId: this.continuousStayId,
            branchId: attachments.branchId
          });

        }));
        this.modal = false;
      } catch (exception) {
        this.errors = processResponseException(exception);
      }

      await this.refreshList();
    },
    async updateAttachmentCategory(attachmentId, category) {
      this.items = this.items.map((item) => item.attachmentId === attachmentId
        ? {...item, category}
        : item,
      );
      await this.$refs.categoryTypes.getAttachmentsCount();
      setTimeout(() => this.filterItemsByCategory());
    },
    filterItemsByCategory() {
      this.items = this.items.filter((item) => item.category === this.category);
    },
    updateAttachmentFilename(attachmentId, name) {
      this.items = this.items.map((item) => item.attachmentId === attachmentId
        ? {...item, name}
        : item,
      );
    },
    updateAttachmentAppointment(attachmentId, {appointmentId, appointmentDocumentId}) {
      this.items = this.items.map((item) => item.attachmentId === attachmentId
        ? {...item, appointmentId, appointmentDocumentId}
        : item,
      );
    },
    async deleteAttachment(attachmentId) {
      this.items  = this.items.filter(item => item.attachmentId !== attachmentId);
      await this.$refs.categoryTypes.getAttachmentsCount();
    },
    async refreshList() {
      this.loading = true;
      await Promise.all([
        this.$refs.categoryTypes.getAttachmentsCount(),
        this.loadItems(),
      ]);
      this.loading = false;
    }
  },
};
</script>

<style scoped lang="scss">
  .categories {
    max-width: 30%;
  }

  .content {
    min-width: 70%;
  }
</style>
