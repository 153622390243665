import router from "../../router";

export async function changePageQuery(query) {
  await router.push({
    path: router.currentRoute.path,
    query,
  });
}

export async function updatePageQuery(query) {
  await router.push({
    path: router.currentRoute.path,
    query: {
      ...router.currentRoute.query,
      ...query,
    },
  });
}

export async function resetPageQuery() {
  await router.push({
    path: router.currentRoute.path,
    query: {},
  });
}
