<template>
  <img
    :src="url"
    :width="width"
    :height="height"
    alt="Auxilio"
    title="Logotyp Auxilio"
  >
</template>

<script>
import LogotypeStandard from "../../images/logotype/standard.svg";
import LogotypeWhite from "../../images/logotype/white.svg";

export default {
  name: "Logotype",
  props: {
    variant: {type: String, default: "white"},
    width: {type: Number, default: 300},
  },
  computed: {
    height() {
      return this.width !== null
        ? (this.width/300)*84
        : null;
    },
    url() {
      switch (this.variant) {
        case "standard":
          return LogotypeStandard;
        case "white":
          return LogotypeWhite;
        default:
          throw new Error("Unknown logotype variant");
      }
    }
  }
};
</script>

<style scoped>

</style>
