


























import Vue from "vue"
import Component from "vue-class-component";
import {Prop} from "vue-property-decorator";
import read from "../../../../rest/read";
import Tooltip from "../../../Tooltip.vue";
import {mapState} from "vuex";
import eventBus from "../../../../eventBus";

enum SurveyStatus {
  CREATED = "created",
  INVITED = "invited",
  OPENED = "opened",
  CLOSED = "closed",
}

interface SurveyResponse {
  surveyId: string,
  status: SurveyStatus,
  name: string,
  tokenExpired: boolean;
  accessToken: null|string,
  tokenExpiryDate: null|Date,
  endDate: null|Date,
}

@Component({
  name: "SurveyResults",
  components: {
    Tooltip,
  },
  computed: {...mapState({currentUser: state => state.currentUser.user,})},
})
export default class SurveyResults extends Vue {
  @Prop({type: String, required: true}) readonly surveyId!: string;

  loading: boolean = false;
  private survey: null|SurveyResponse = null;

  async mounted(): Promise<void> {
    await this.fetchData();
  }

  async fetchData(): Promise<void> {
    this.loading = true;
    await this.getSurvey();
    this.loading = false;
  }

  private async getSurvey(): Promise<void> {
    this.survey = await read<SurveyResponse>(`/api/surveys/${this.surveyId}`);
  }

  openSurvey(survey: SurveyResponse) {
    const href=`${this.currentUser.settings.surveyBuilderUrl}/?token=${survey.accessToken}`;
    window.open(href, "_blank");
    eventBus.on(survey.surveyId, () => {
      this.fetchData();
    });
  }
}
