<template>
  <loading-mask :loading="loading">
    <template v-if="overview">
      <template v-if="!diagnosisId">
        <button
          v-if="!diagnosisId"
          :disabled="overview.readonly"
          type="submit"
          class="btn btn-primary btn-sm"
          @click="createNew"
        >
          Nowe badanie neurologiczne
        </button>
      </template>
      <template v-else>
        <diagnosis-structure
          v-if="diagnosisId"
          :read-only="overview.readonly"
          :items="fields"
          :structure-version="structureVersion"
          :diagnosis-id="diagnosisId"
          diagnosis-type="neurological_child"
          @load="loadData"
        />
      </template>
    </template>
  </loading-mask>
</template>
<script>
import read from "../../../rest/read";
import create from "../../../rest/create";
import DiagnosisStructure from "../../../components/Ipz/DiagnosisStructure/DiagnosisStructure";
import {generateUuid} from "../../../utils/uuid/generateUuid";
import LoadingMask from "../../../components/Loading/LoadingMask";

export default {
  components: {LoadingMask, DiagnosisStructure},
  props: {
    ipzId: {type: String, required: true},
  },
  data() {
    return {
      overview: null,
      diagnosisId: null,
      fields: null,
      structureVersion: null,
      loading: false,
    }
  },
  watch: {
    async ipzId() {
      await this.loadData();
    },
  },
  async mounted() {
    await this.loadData();
  },
  methods: {
    async createNew(){
      this.loading = true;
      await create(`/api/ipzs/${this.ipzId}/neurological/create`, {
        ipzId: this.ipzId,
        diagnosisId: generateUuid()
      });
      await this.loadData();
      this.loading = false;
    },
    async loadData() {
      this.loading = true;
      const {
        overview, fields, structureVersion, diagnosisId
      } = await read(`/api/ipzs/${this.ipzId}/details/neurological`);
      this.overview = overview;
      this.fields = fields;
      this.structureVersion = structureVersion;
      this.diagnosisId = diagnosisId;
      this.loading = false;
    }
  }
}
</script>
