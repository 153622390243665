


































import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";
import DateTime from "../DateTime.vue";
import type {Day, Item} from "../../types/RegistrationSearchTypes";
import DATE_FORMAT from "../../utils/date/DATE_FORMAT";
import stringifyDate from "../../utils/date/stringifyDate";

@Component({
  name: "RegistrationSearchList",
  components: {DateTime}
})

export default class RegistrationSearchList extends Vue {
  @Prop({type: String, required: true}) readonly label!: Day["label"]
  @Prop({type: Array, required: true}) readonly items!: Day["items"]

  private get itemsByStartDate(): Day["items"] {
    //endpoint returns available slots sorted by worker
    return this.items.sort((itemA, itemB) => {
      return itemA.start.getTime() - itemB.start.getTime();
    });
  }

  private get timeFormat(): string {
    return DATE_FORMAT.TIME;
  }

  private goToRegistrationView(item: Item): void {
    const start = new Date(item.start);
    const day = start.getDay() || 7;
    start.setDate(start.getDate() + 1 - day);

    this.$router.push({
      name: "registration",
      query: {
        selectedWorkerId: item.worker.workerId,
        start: stringifyDate(start),
      },
    });
  }
}
