<template>
  <transition name="fade">
    <div
      v-if="value.chosen"
      class="col-md-6 col-lg-4 border-bottom border-light d-flex flex-column"
    >
      <span class="font-weight-bold w-100">
        {{ value.name }}
      </span>

      <div class="d-flex">
        <div class="form-group mr-sm-3">
          <label :for="`day-${value.number}-from`">Od godziny:</label>
          <hour-select
            :id="`day-${value.number}-from`"
            class="hour-select"
            :data-test="`day-${value.number}-from`"
            :value="value.hourFrom"
            @input="update($event, 'hourFrom')"
          />
        </div>

        <div class="form-group">
          <label :for="`day-${value.number}-to`">Do godziny:</label>
          <hour-select
            :id="`day-${value.number}-to`"
            class="hour-select"
            :data-test="`day-${value.number}-to`"
            :value="value.hourTo"
            @input="update($event, 'hourTo')"
          />
          <div v-if="endSoonerThanStart(value)">
            następnego dnia
          </div>
        </div>
      </div>

      <transition name="fade">
        <div
          v-if="value.errors"
          class="alert alert-danger alert-dismissible mt-auto"
        >
          <button
            type="button"
            class="close"
            @click="clearErrors"
          >
            <span>&times;</span>
          </button>
          {{ value.errors }}
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
import HourSelect from "../../Form/Select/HourSelect";

export default {
  name: "DayHoursInput",
  components: {
    HourSelect,
  },
  props: {
    value: {type: Object, required: true},
  },
  methods: {
    endSoonerThanStart(day) {
      const dateStart = new Date();
      dateStart.setHours(Number(day.hourFrom.HH));
      dateStart.setMinutes(Number(day.hourFrom.mm));

      const dateEnd = new Date();
      dateEnd.setHours(Number(day.hourTo.HH));
      dateEnd.setMinutes(Number(day.hourTo.mm));

      return dateStart > dateEnd;
    },
    clearErrors() {
      this.$emit("clearErrors");
    },
    update(event, field) {
      this.$emit("input", {...this.value, [field]: event});
    }
  },
}
</script>

<style scoped lang="scss">
  .hour-select {
    width: 100px;
  }
</style>
