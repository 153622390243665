import {MutationTree} from "vuex";
import {GeolocalizationState} from "./types";

export const mutations: MutationTree<GeolocalizationState> = {
  setGeolocalizationPermission(state, permission: boolean){
    state.permission = permission;
  },

  setGeolocalizationInterval(state, interval: number) {
    state.interval = interval;
  },
};