<template>
  <div class="pdf-background pdf-container">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "PdfLayout"
}

</script>

<style>
  @page {
    size: A4;
  }
</style>
<style scoped>
</style>
