import {ActionTree} from "vuex";
import {LastEditedAppointmentState} from "./types";
import {RootState} from "../../types";

export const actions: ActionTree<LastEditedAppointmentState, RootState> = {
  setAppointmentDetails({commit}, details) {
    commit("setAppointmentId", details.id);
    commit("setAppointmentTreatmentType", details.treatmentTypeName);
    commit("setAppointmentDates", details.datesString);
  },
  clearAppointmentDetails({commit}) {
    commit("clearAppointmentId");
    commit("clearAppointmentTreatmentType");
    commit("clearAppointmentDates");
  },
};
