export default {
  bind: (el, binding, vnode) => {
    el.setAttribute("tabindex", "0");
    el.addEventListener("blur", vnode.context[binding.expression], true);
  },
  unbind: (el, binding, vnode) => {
    el.removeEventListener("blur", vnode.context[binding.expression])
  },
}

