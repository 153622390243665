


























































































import Vue from "vue";
import Component from "vue-class-component";
import read from "../../rest/read";
import DatePicker from "../Form/DatePicker/DatePicker.vue";
import {Watch} from "vue-property-decorator";
import processResponseException from "../../utils/errors/processResponseException";
import LoadingMask from "../Loading/LoadingMask.vue";
import DATE_FORMAT from "../../utils/date/DATE_FORMAT";
import stringifyDate from "../../utils/date/stringifyDate";
import CoordinatedTeamDisplayModeRadio from "./CoordinatedTeamDisplayModeRadio";

@Component({
  name: "CoordinatedTeamLoadList",
  components: {CoordinatedTeamDisplayModeRadio, DatePicker, LoadingMask}
})
export default class CoordinatedTeamLoadList extends Vue {
  loading: boolean = true;
  items: Array<CoordinatedTeamLoadItem> = [];
  startDate: Date|null = null;
  endDate: Date|null = null;
  allSpecialists = true;
  fields: Array<Object> = [
    {"key": "worker","label":"Osoba"},
    {"key": "coordinator","label":"Koordynator"},
    {"key": "openedAppointments","label":"W. Otwarte"},
    {"key": "finishedAppointments","label":"W. Zamknięte"},
    {"key": "canceledAppointments","label":"W. Anulowane"},
  ];

  async mounted() {
    await this.loadItems();
  }

  async loadItems() {
    this.loading = true;

    try {
      const filters = {"startDate": this.startDate, "endDate": this.endDate, "allSpecialists": this.allSpecialists};
      const {items} = await read("/api/coordinated-team-load", filters);
      this.items = items;
    } catch (e) {
      processResponseException(e);
    }
    this.loading = false;
  }

  @Watch("endDate")
  @Watch("startDate")
  @Watch("allSpecialists")
  onFilterChange() {
    this.loadItems();
  }

  formatDate(date: Date|null) {
    return null !== date ? stringifyDate(date, DATE_FORMAT.DEFAULT): undefined;
  }

  get getStartDate() {
    return this.startDate === null ? this.formatDate((new Date().setFullYear(2000))): this.formatDate(this.startDate);
  }
}

interface CoordinatedTeamLoadItem {
  worker: string,
  coordinator: string,
  openedAppointments: number,
  finishedAppointments: number,
  canceledAppointments: number
}
