<template>
  <b-modal
    :id="modalId"
    :title="title"
    hide-footer
    no-close-on-backdrop
    title-tag="h3"
    @hide="resetModalData"
  >
    <error-message :errors="errors" />
    <appointment-details
      :appointment-id="appointmentId"
      :date="date"
      :patients="patients"
      :main-worker="mainWorkerData"
      :workers="workersData"
      :main-worker-id="mainWorkerId"
    />
    <form @submit.prevent="cancelAppointment">
      <form-row
        :errors="errors"
        label="Powód"
        field="cancelReason"
      >
        <cancel-appointment-reason-select
          v-model="cancelReason"
          :state="state('cancelReason')"
        />
      </form-row>
      <form-row
        :errors="errors"
        label="Uwagi"
        field="cancelComment"
      >
        <resizable-textarea
          id="cancelComment"
          v-model.trim="cancelComment"
          :state="state('cancelComment')"
          rows="3"
        />
      </form-row>
      <form-row
        v-if="deferred"
        :errors="errors"
        label="Uwagi do wniosku"
        field="deferredNote"
      >
        <resizable-textarea
          id="deferredNote"
          v-model.trim="deferredNote"
          :state="state('deferredNote')"
          rows="3"
        />
      </form-row>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          @click="closeCancelAppointmentModal"
        >
          Zamknij
        </button>
        <button
          :disabled="loading"
          type="submit"
          class="btn btn-primary"
        >
          <i
            v-show="loading"
            class="fas fa-spin fa-spinner"
          />
          {{ submitBtnText }}
        </button>
      </div>
    </form>
  </b-modal>
</template>

<script>
import FormRow from "../../Form/FormRow";
import AppointmentDetails from "../AppointmentDetails";
import CancelAppointmentReasonSelect from "./CancelAppointmentReasonSelect";
import {errorsMixin} from "../../../mixins/errorsMixin.js";
import update from "../../../rest/update";
import ErrorMessage from "../../Form/ErrorMessage";
import processResponseException
  from "../../../utils/errors/processResponseException";
import ResizableTextarea from "../../Form/Textarea/ResizableTextarea";
import {deferredMixin} from "../../../mixins/deferredMixin";

export default {
  components: {
    ResizableTextarea,
    ErrorMessage,
    FormRow,
    AppointmentDetails,
    CancelAppointmentReasonSelect
  },
  mixins: [errorsMixin, deferredMixin],
  props: {
    appointmentId: {required: true, type: String},
    title: {type: String, required: true,},
    date: {type: Date, default: null},
    group: {type: Boolean, default: false},
    patients: {type:Array, default: () => []},
    patientId: {type: String, default: null},
    mainWorkerId: {type: String, default: null},
    mainWorker: {type: Object, default: null},
    workers: {type: Array, default: ()=>[]},
  },
  data() {
    return {
      cancelReason: "",
      cancelComment: "",
      loading: false,
      deferredNote: "",
    }
  },
  computed: {
    modalId() {
      return this.deferred
        ? "cancel-reason-deferred-modal"
        : "cancel-reason-modal";
    },
    submitBtnText() {
      return this.deferred
        ? "Wyślij wniosek"
        : "Odwołaj wizytę";
    },
    mainWorkerData() {
      if (this.mainWorker) {
        return this.mainWorker;
      }
      return this.workers.length > 1
        ? undefined
        : this.workers.find(({workerId}) => workerId === this.mainWorkerId);
    },
    workersData() {
      return this.workers.length > 1
        ? this.workers.find(({workerId}) => workerId === this.mainWorkerId)
        : undefined;
    }
  },
  methods: {
    async cancelAppointment() {
      this.loading = true;
      const rejectUrl = `/api/appointment/${this.appointmentId}/cancel`;
      try {
        await update(rejectUrl, {
          cancelReason: this.cancelReason,
          cancelComment: this.cancelComment,
          deferredNote: this.deferred ? this.deferredNote : undefined,
          deferred: this.deferred ? true: undefined
        });
        this.$emit("appointmentCanceled");
        this.closeCancelAppointmentModal();
      } catch (exception) {
        this.errors = processResponseException(exception);
      }
      this.loading = false;
    },
    closeCancelAppointmentModal() {
      this.$bvModal.hide(this.modalId);
    },
    resetModalData() {
      this.errors = [];
      this.cancelReason = "";
      this.cancelComment = "";
      this.deferredNote = "";
    },
    resetErrors: function() {
      this.formData.modules = this.formData.modules.map((module) => ({...module, errors: null}));
    },
  },
};
</script>
