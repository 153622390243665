<template>
  <div class="row d-flex justify-content-end">
    <b-btn
      class="btn btn-success m-b-20"
      @click="showModal"
    >
      <i class="fa fa-plus" />
      Dodaj zdarzenie
    </b-btn>

    <b-modal
      id="add-system-notification"
      no-close-on-backdrop
      title="Dodawanie zdarzenia systemowego"
      title-tag="h3"
      @hide="clearFields"
    >
      <error-message
        :errors="errors"
      />
      <system-notification-category
        v-model="category"
        :disabled="createLoading"
      />
      <error-message
        :errors="errors"
        field="category"
      />
      <b-form-group label="Temat">
        <b-input
          id="subject"
          v-model="subject"
          :disabled="createLoading"
          :state="state('subject')"
          required
        />
        <error-message
          :errors="errors"
          field="subject"
        />
      </b-form-group>
      <b-form-group
        label="Treść"
      >
        <textarea-counter
          id="content"
          v-model="content"
          :disabled="createLoading"
          :max-characters-count="2048"
          :rows="5"
          :state="state('content')"
          required
        />
        <error-message
          :errors="errors"
          field="content"
        />
      </b-form-group>
      <template #modal-footer>
        <b-button
          type="button"
          class="btn btn-secondary"
          @click="hideModal"
        >
          Anuluj
        </b-button>
        <b-button
          :disabled="createLoading"
          class="btn btn-primary" 
          @click="addSystemNotification"
        >
          <i
            :class="createLoading ? 'fa-spin fa-spinner' : 'fa-check'"
            class="fa"
          />
          Zapisz
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import create from "../../rest/create";
import read from "../../rest/read";
import {generateUuid} from "../../utils/uuid/generateUuid";
import SystemNotificationCategory from "./SystemNotificationCategory.vue";
import ErrorMessage from "../Form/ErrorMessage";
import TextareaCounter from "../Form/Textarea/TextareaCounter";
import {errorsMixin} from "../../mixins/errorsMixin.js";
import processResponseException from "../../utils/errors/processResponseException";

export default {
  components: {
    TextareaCounter,
    ErrorMessage,
    SystemNotificationCategory,
  },
  mixins: [errorsMixin],
  data() {
    return {
      category: "success",
      content: null,
      createLoading: false,
      showFlash: false,
      subject: null,
    };
  },
  methods: {
    async addSystemNotification() {
      this.createLoading = true;
      const notificationId = generateUuid();
      const newNotification = {
        notificationId,
        category: this.category,
        subject: this.subject,
        content: this.content,
      };
      try {
        await create(`/api/system-notifications/${notificationId}`, newNotification);
        const data = await read(`/api/system-notifications/${notificationId}`);

        const event = {
          alert: {
            className: "success",
            message: "Wysłano powiadomienie systemowe! (do wszystkich użytkowników)",
          },
          newNotification: data,
        };
        // show info
        this.$emit("notification-created", event);

        // close modal
        this.hideModal();

        this.errors = [];
      } catch (e) {
        this.errors = processResponseException(e);
      }
      this.createLoading = false;
    },
    clearFields() {
      this.category = "success";
      this.content  = null;
      this.subject  = null;
      this.errors = [];
    },
    hideModal () {
      this.$bvModal.hide("add-system-notification");
      this.clearFields();
    },
    showModal () {
      this.$emit("clearAlerts");
      this.$bvModal.show("add-system-notification");
    },
  },
};
</script>
