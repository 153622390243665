<template>
  <tooltip
    :class="btnClass"
    tooltip-content="Czas do wylogowania"
    component="button"
    class="btn session-manager"
    @click="extendSessionTime"
  >
    <i
      :class="{'fa-spin': extending || null == secondsLeft}"
      class="fa fa-redo"
    />
    {{ displayTimeLeft }}
    <b-modal
      :visible="isExtendSessionModalVisible"
      no-close-on-backdrop
      size="md"
      title="Przedłużenie sesji"
      title-tag="h3"
      class="text-secondary text-left"
    >
      <div>
        Czy chcesz przedłużyć sesję?
      </div>
      <template #modal-footer>
        <b-btn
          class="btn btn-primary"
          @click="extendSessionTimeAndCloseModal"
        >
          Tak
        </b-btn>
      </template>
    </b-modal>
  </tooltip>
</template>

<script>
import read from "../rest/read";
import create from "../rest/create";
import Tooltip from "./Tooltip";
import {UnauthorizedException, ServiceUnavailableException} from "../rest";
import {mapState, mapActions} from "vuex";

const storageKey = "auxilio.userId";

export default {
  name: "SessionManager",
  components: {Tooltip},
  data() {
    return {
      secondsLeft: 3600,
      interval: null,
      extending: false,
      syncTimeout: null,
      isModalClosed: false,
    };
  },
  computed: {
    ...mapState("currentUser",["user"]),
    displayTimeLeft() {
      if (null == this.secondsLeft) {
        return "00:00";
      }
      const minutes = Math.floor(this.secondsLeft / 60);
      const seconds = this.secondsLeft % 60;
      const zeroPadded = (n) => n < 10 ? `0${n}` : n;

      return `${zeroPadded(minutes)}:${zeroPadded(seconds)}`;
    },
    btnClass() {
      if (null == this.secondsLeft || this.secondsLeft >= 30) {
        return "btn-primary";
      }
      if (this.secondsLeft >= 10) {
        return "btn-warning";
      }

      return "btn-danger";
    },
    nextPingDelay() {
      const minDelay = 1000;
      const maxDelay = 60*1000;
      const computedDelay = Math.floor(this.secondsLeft / 2) * 1000;

      return Math.min(maxDelay, Math.max(computedDelay, minDelay));
    },
    isExtendSessionModalVisible() {
      return this.secondsLeft < 5*60 && !this.isModalClosed;
    },
  },
  mounted() {
    this.syncTimeout = setTimeout(() => this.ping(), this.nextPingDelay);
    this.interval = setInterval(() => this.countDown(), 1000);

    const oldUserId = sessionStorage.getItem(storageKey);
    if (null != oldUserId && oldUserId !== this.user.userId) {
      this.clearStickyPatient();
    }
    sessionStorage.setItem(storageKey, this.user.userId);
  },
  beforeDestroy() {
    clearTimeout(this.syncTimeout);
    clearInterval(this.interval);
  },
  methods: {
    async ping() {
      try {
        const {expireIn} = await read("/api/session-data");
        this.secondsLeft = expireIn;
      } catch(exception) {
        if (exception instanceof UnauthorizedException) {
          this.logout();
        }
        if (exception instanceof ServiceUnavailableException) {
          this.clearSession();
        }
        console.error(exception);
        return;
      }
      this.syncTimeout = setTimeout(() => this.ping(), this.nextPingDelay);
    },
    async countDown() {
      if (this.secondsLeft > 0) {
        this.secondsLeft--;
      } else {
        this.logout();
      }
    },
    logout() {
      clearTimeout(this.syncTimeout);
      clearInterval(this.interval);

      this.clearSession();
    },
    async extendSessionTime() {
      this.extending = true;
      clearTimeout(this.syncTimeout);
      await create("/api/session-data", {credentials: "include"});
      await this.ping();
      this.extending = false;
      this.isModalClosed = false;
    },
    async extendSessionTimeAndCloseModal() {
      this.isModalClosed = true;
      this.extendSessionTime();
    },
    ...mapActions({
      clearStickyPatient: "stickyPatient/clear",
      clearSession: "session/clear"
    }),
  },
};
</script>
<style scoped lang="scss">
  @import "../styles/variables";

  .session-manager {
    height: 100%;

    @include media-breakpoint-down(lg) {
      display: none;

      &.btn-warning,
      &.btn-danger {
        display: block;
        position: fixed;
        bottom: 5px;
        right: 5px;
        height: auto !important;
        z-index: $zindex-tooltip;
      }
    }
  }
</style>
