<template>
  <object-select
    :loading="loading"
    :disabled="disabled"
    :options="options || []"
    :placeholder="placeholder"
    :state="state"
    :value="selectedOption"
    @input="update"
  >
    <template #noOptions>
      <slot name="noOptions">
        <span />
      </slot>
    </template>
  </object-select>
</template>

<script>
import ObjectSelect from "@/components/Form/Select/ObjectSelect";
import read from "@/rest/read";
import stringifyDate from "@/utils/date/stringifyDate";
import DATE_FORMAT from "@/utils/date/DATE_FORMAT";
import t from "@/i18n";
import parseDate from "@/utils/date/parseDate";

export default {
  name: "ContinuousStayIdSelect",
  components: {ObjectSelect},
  props: {
    disabled: {type: Boolean, default: false},
    patientId: {type: String, default: null},
    start: {type: Date, default: null},
    end: {type: Date, default: null},
    placeholder: {type: String, default: "Wybierz pobyt ciągły"},
    state: {type: Boolean, default: null},
    value: {type: String, default: null},
  },
  data() {
    return {
      loading: false,
      options: null, // change type into array after first load
    }
  },
  computed: {
    selectedOption() {
      if (!this.value) {
        return null;
      }
      return this.options?.find(option => option.value === this.value) || null;
    },
  },
  watch: {
    patientId: {
      immediate: true,
      async handler() {
        await this.loadOptions();
      },
    },
    async start() {
      await this.loadOptions();
    },
    async end() {
      await this.loadOptions();
    },
    selectedOption: {
      immediate: true,
      handler(newVal) {
        // force update this.value only if options are already loaded
        if (!newVal && this.value && this.options) {
          this.update(null);
        }
      }
    },
  },
  methods: {
    async loadOptions() {
      if (!this.patientId) {
        this.options = [];
        return;
      }
      this.loading = true;
      const {items} = await read(`/api/patients/${this.patientId}/continuous-stays`, {
        startDate: this.start ? stringifyDate(this.start, DATE_FORMAT.DATE_TIME_SEC) : undefined,
        endDate: this.end ? stringifyDate(this.end, DATE_FORMAT.DATE_TIME_SEC) : undefined,
        status: ["in_progress"],
        page: 1,
        perPage: 100,
      });
      this.options = items.map(item => {
        const category = t(`@continuousStay.${item.continuousStayCategory}`);
        const startDate = this.standardizeDate(item.startDate);
        const time = item.endDate
          ? `${startDate} - ${this.standardizeDate(item.endDate)}`
          : startDate;
        return {
          ...item,
          value: item.continuousStayId,
          name: `${category} ${time}`,
        };
      });
      this.loading = false;
    },
    update(option) {
      this.$emit("input", option?.value || null);
    },
    standardizeDate(dateStr) {
      const dateObj = parseDate(dateStr, DATE_FORMAT.DATE_TIME_SEC);
      return stringifyDate(dateObj, DATE_FORMAT.DATE_TIME)
    }
  },
}
</script>
