<template>
  <object-select
    :options="options"
    :value="selected"
    :multiple="true"
    @input="update"
  />
</template>
<script>
import ObjectSelect from "../../Form/Select/ObjectSelect";
import t from "../../../i18n";

export default {
  components: {ObjectSelect},
  props: {
    value: {type: Array, default: null},
  },
  data() {
    const availableStatuses = ["pending", "completed", "waiting_for_patient", "ex_patient"];
    return {
      options: availableStatuses.map(status => ({
        value: status,
        name: t(`@dataUnificationStatus.${status}`)
      })),
    }
  },
  computed: {
    selected() {
      if (!this.value) {
        return null;
      }
      return this.options.filter((option) => {return this.value && this.value.indexOf(option.value) !== -1});
    }
  },
  methods: {
    update(selectedOptions) {
      const selectedValues = selectedOptions.map(option => option.value);
      this.$emit("input", selectedValues);
    }
  }
}
</script>
