






























































































































import {Component, Prop, Watch} from "vue-property-decorator";
import ErrorsMixin from "../../../../mixins/ErrorsMixin";
import {mixins} from "vue-class-component";
import read from "../../../../rest/read";
import processResponseException from "../../../../utils/errors/processResponseException";
import Card from "../../../Card.vue";
import ErrorMessage from "../../../Form/ErrorMessage.vue";
import PatientPsychiatricHospitalizationsCreateModal from "./PatientPsychiatricHospitalizationsCreateModal.vue";
import DATE_FORMAT from "../../../../utils/date/DATE_FORMAT";
import stringifyDate from "../../../../utils/date/stringifyDate";
import PatientPsychiatricHospitalizationsUpdateModal from "./PatientPsychiatricHospitalizationsUpdateModal.vue";
import PatientPsychiatricHospitalizationsArchiveConfirmationModal
  from "./PatientPsychiatricHospitalizationsArchiveConfirmationModal.vue";
import PaginationDescription from "../../../Pagination/PaginationDescription.vue";
import type {Pagination} from "../../../../types/Pagination";
import IsGranted from "../../../IsGranted.vue";
import type {BvTableFieldArray} from "bootstrap-vue/src/components/table";
import type {HospitalizationMember} from "../../../../types/HospitalizationTypes";
import {HospitalizationType} from "../../../../types/HospitalizationTypes";
import I18n from "../../../i18n.vue";

interface HospitalizationsResponse {
  items: Array<HospitalizationMember>;
  pagination: Pagination;
}

@Component({components: {
  I18n,
  IsGranted,
  PaginationDescription,
  PatientPsychiatricHospitalizationsArchiveConfirmationModal,
  PatientPsychiatricHospitalizationsUpdateModal,
  PatientPsychiatricHospitalizationsCreateModal,
  Card,
  ErrorMessage,
}})
export default class PatientPsychiatricHospitalizations extends mixins(ErrorsMixin)
{
  @Prop({type: String, required: true}) readonly patientId!: string;

  @Watch("patientId")
  async onPatientIdChanged(newId: String, oldId: String): Promise<void> {
    if (newId !== oldId) {
      await this.fetchData();
    }
  }
  @Watch("pagination.currentPage")
  onPageChange(newPage: number) {
    this.pagination.currentPage = newPage;
    this.fetchData();
  }

  async mounted(): Promise<void> {
    await this.fetchData();
  }

  loading: boolean = false;
  createModalVisible: boolean = false;
  hospitalizationMembers: Array<HospitalizationMember> = [];
  editedHospitalizationId: string|null = null;
  removedHospitalization: HospitalizationMember|null = null;
  pagination: Pagination = {
    currentPage: 1,
    totalRows: 0,
    perPage: 3,
  };

  get fields(): BvTableFieldArray {
    return [
      {key: "hospital", label: "Szpital", thStyle: "width: 15%"},
      {key: "type", label: "Typ", thStyle: "min-width: 7rem"},
      {key: "since-until", label: "Zakres dat lub czas trwania", thStyle: "min-width: 8rem"},
      {key: "author", label: "Utworzono", thStyle: "min-width: 9rem"},
      {key: "comment", label: "Komentarz", thStyle: "width: 35%"},
      {key: "documents", label: "Dokumenty", thStyle: "width: 18%"},
      {key: "options", label: "", class: "text-right", thStyle: "min-width: 7rem"},
    ];
  }

  get filters() {
    return {
      page: this.pagination.currentPage,
      perPage: this.pagination.perPage,
    };
  }

  private getVariant(status: HospitalizationType): string {
    switch (status) {
      case HospitalizationType.PSYCHIATRIC:
        return "primary";
      case HospitalizationType.DAILY:
        return "success";
      case HospitalizationType.FULL_DAY:
        return "warning";
      case HospitalizationType.UNKNOWN:
        return "secondary";
      default:
        return "light";
    }
  }

  startEditing(hospitalization: HospitalizationMember): void {
    this.editedHospitalizationId = hospitalization.hospitalizationId;
  }
  remove(hospitalization: HospitalizationMember): void {
    this.removedHospitalization = hospitalization;
  }
  openModal(): void {
    this.createModalVisible = true;
    this.errors = [];
  }
  closeModal(): void {
    this.createModalVisible = false;
    this.editedHospitalizationId = null;
    this.removedHospitalization = null;
  }
  async fetchData(): Promise<void> {
    this.loading = true;
    try {
      await this.getHospitalizations();
    } catch(e) {
      this.errors = processResponseException(e);
    }
    this.loading = false;
  }
  async reloadData(): Promise<void> {
    this.closeModal();
    await this.fetchData();
  }
  async getHospitalizations(): Promise<void> {
    const {items, pagination} = await read<HospitalizationsResponse>(
      `/api/patients/${this.patientId}/hospitalizations`,
      this.filters,
    );
    this.pagination = pagination;
    this.hospitalizationMembers = items;
  }
  stringifyDate(date: string|null): string|null {
    return null !== date ? stringifyDate(new Date(date), DATE_FORMAT.DATE) : null;
  }
  updateFilename(hospitalizationId: string, fileId: string, name: string): void {
    const member = this.hospitalizationMembers.find(
      (member: HospitalizationMember) => member.hospitalizationId === hospitalizationId
    );
    if (!member) {
      return;
    }
    member.files = member.files.map((item) => item.fileId === fileId
      ? {...item, name}
      : item,
    );
  }
}
