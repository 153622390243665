









































































































































import {Component, Emit, Prop} from "vue-property-decorator";
import type {EstablishmentItem} from "../../types/Establishment";
import {EstablishmentType} from "../../types/Establishment";
import AddressForm from "../Address/AddressForm.vue";
import ErrorMessage from "../Form/ErrorMessage.vue";
import {Error} from "../../rest";
import Vue from "vue";
import subErrors from "../../utils/errors/subErrors";
import ResizableTextarea from "../Form/Textarea/ResizableTextarea.vue";
import EstablishmentParametersForm from "./EstablishmentParametersForm.vue";

@Component({
  name: "EstablishmentChildForm",
  components: {EstablishmentParametersForm, ResizableTextarea, ErrorMessage, AddressForm}
})

export default class EstablishmentChildForm extends Vue {
  @Prop({type: Object, default: null}) readonly parent!: EstablishmentItem;
  @Prop({type: String, default: null}) readonly type!: EstablishmentType;
  @Prop({type: Object, default: null}) readonly item!: EstablishmentItem;
  @Prop({type: Object, default: ()=>({})}) readonly value!: EstablishmentItem|{};
  @Prop({type: Array, default: ()=>[]}) readonly propErrors!: Array<Error>;

  private get hasCode(): boolean {
    if (this.type) {
      switch(this.type) {
        case EstablishmentType.ESTABLISHMENT:
        case EstablishmentType.DIVISION:
        case EstablishmentType.UNIT:
          return true
        default:
          return false;
      }
    }
    if (this.item) {
      switch(this.item.type) {
        case EstablishmentType.ESTABLISHMENT:
        case EstablishmentType.DIVISION:
        case EstablishmentType.UNIT:
          return true
        default:
          return false;
      }
    }
    return this.parent && this.parent.type !== EstablishmentType.ESTABLISHMENT;
  }

  private get isUnit(): boolean {
    if (this.item) {
      return this.item.type === EstablishmentType.UNIT;
    }
    return this.parent && this.parent.type === EstablishmentType.DIVISION;
  }

  private get isEstablishment(): boolean {
    if (this.item) {
      return this.item.type === EstablishmentType.ESTABLISHMENT;
    }
    return this.type === EstablishmentType.ESTABLISHMENT;
  }

  private get isNonMedical(): boolean {
    if (this.item) {
      return this.item.type === EstablishmentType.NON_MEDICAL;
    }
    return this.type === EstablishmentType.NON_MEDICAL;
  }

  private get hasAddress():boolean {
    return this.isUnit || this.isEstablishment || this.isNonMedical;
  }
  private get hasClinicParameters():boolean {
    return this.isEstablishment || this.isNonMedical;
  }

  getSubErrors(field: string): Array<Error> {
    return subErrors(this.propErrors, field);
  }

  state(field: string): boolean|null {
    return this.propErrors.find((error) => error.field === field) ? false : null;
  }

  @Emit()
  input(value: EstablishmentItem|null): EstablishmentItem|null {
    return value;
  }
}
