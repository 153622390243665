import {actions} from "./stickyPatient/actions";
import {getters} from "./stickyPatient/getters";
import {mutations} from "./stickyPatient/mutations";
import {state} from "./stickyPatient/state";
import {Module} from "vuex";
import {StickyPatientState} from "./stickyPatient/types";
import {RootState} from "../types";

export const storageKey = "auxilio.stickyPatientId";

const stickyPatient: Module<StickyPatientState, RootState> = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
}

export default stickyPatient;
