<template>
  <div>
    <div class="page-title-box">
      <h4 class="page-title">
        {{ $route.meta.title }}
        <template v-if="workerFullName">
          - {{ workerFullName }}
        </template>
      </h4>
      <div class="clearfix" />
    </div>
    <last-edited-appointment />
    <div class="card-box">
      <worker-absence-form
        :worker-id="workerId"
        @absenceSubmitted="getWorkerAbsenceList()"
      />
    </div>
    <div class="card-box">
      <error-message :errors="errors" />
      <worker-absence-list
        :worker-id="workerId"
        :absence-list="absenceList"
        @absenceDeleted="getWorkerAbsenceList()"
      />
    </div>
  </div>
</template>

<script>
import WorkerAbsenceForm from "../../components/Worker/Absence/WorkerAbsenceForm.vue";
import WorkerAbsenceList from "../../components/Worker/Absence/WorkerAbsenceList.vue";
import read from "../../rest/read";
import processResponseException from "../../utils/errors/processResponseException";
import ErrorMessage from "../../components/Form/ErrorMessage";
import LastEditedAppointment from "../../components/Appointment/LastEditedAppointment";

export default {
  name: "WorkerAbsence",
  components: {
    LastEditedAppointment,
    ErrorMessage,
    "worker-absence-form": WorkerAbsenceForm,
    "worker-absence-list": WorkerAbsenceList,
  },
  props: {
    workerId: {type: String, required: true},
  },
  data() {
    return {
      absenceList: [],
      errors: [],
      workerFullName: null,
    }
  },
  async mounted() {
    this.getWorkerAbsenceList();
    const {workerFullName} = await read(`/api/workers/${this.workerId}/define-absences`);
    this.workerFullName = workerFullName;
  },
  methods: {
    async getWorkerAbsenceList() {
      this.errors = [];
      try {
        const {items} = await read(`/api/workers/${this.workerId}/absences`);
        this.absenceList = items;
      } catch(exception) {
        this.errors = processResponseException(exception);
      }
    },
  },
}
</script>
