

























import Vue from "vue";
import Component from "vue-class-component";
import {Prop} from "vue-property-decorator";
import {Error} from "../../rest";
import ErrorMessage from "./ErrorMessage.vue";

@Component({
  components: {ErrorMessage}
})
export default class FormRow extends Vue {
  @Prop({type: String, required: true}) readonly label!: string;
  @Prop({type: String}) readonly field!: string;
  @Prop({type: Array, default: () => []}) readonly errors!: Array<Error>;
  @Prop({type: Boolean, default: false}) readonly root!: boolean;
}
