






























import {Component, Mixins, Prop} from "vue-property-decorator";
import TextareaCounter from "../Form/Textarea/TextareaCounter.vue";
import {CreateData, NoteItem} from "../../types/Notes";
import {generateUuid} from "../../utils/uuid/generateUuid";
import stringifyDate from "../../utils/date/stringifyDate";
import Card from "../Card.vue";
import create from "../../rest/create";
import processResponseException from "../../utils/errors/processResponseException";
import ErrorsMixin from "../../mixins/ErrorsMixin";
import ErrorMessage from "../Form/ErrorMessage.vue";

@Component({
  name: "NoteForm",
  components: {
    TextareaCounter,
    Card,
    ErrorMessage,
  },
})
export default class OfflineNoteForm extends Mixins(ErrorsMixin) {
  @Prop({type: Boolean, default: false}) readonly offline!: boolean;

  private note: string = "";
  private loading: boolean = false;

  private async submit(): Promise<void> {
    const note: NoteItem = {
      noteId: generateUuid(),
      content: this.note,
      date: stringifyDate(new Date()),
    }
    if (this.offline) {
      this.$emit("addNote", note);
    } else {
      await this.addNote(note);
    }
  }

  private async addNote(note: NoteItem): Promise<void> {
    this.loading = true;
    this.errors = [];
    try {
      await create<CreateData>("/api/notes", {
        notes: [note]
      });
      this.$emit("addNote", note);
      this.clearNote();
    } catch (e) {
      this.errors = processResponseException(e);
    }
    this.loading = false;
  }

  public clearNote(): void {
    this.note = "";
  }
}
