<template>
  <b-modal
    id="close-procedure"
    :title="title"
    no-close-on-backdrop
    title-tag="h3"
    @hide="onHide"
  >
    <error-message :errors="errors" />
    <p>{{ modalContent }}</p>
    <template v-if="type === 'cancel'">
      <form-row
        :errors="errors"
        field="cancelReason"
        label="Powód"
      >
        <resizable-textarea
          v-model.trim="cancelReason"
        />
      </form-row>
    </template>
    <template #modal-footer>
      <button
        class="btn btn-secondary"
        @click="close"
      >
        Anuluj
      </button>
      <button
        :disabled="!procedureId || loading"
        class="btn btn-primary"
        @click="submit"
      >
        <i
          :class="loading ? 'fa-spin fa-spinner' : 'fa-check'"
          class="fas"
        />
        Zapisz
      </button>
    </template>
  </b-modal>
</template>

<script>
import {mapState} from "vuex";
import ErrorMessage from "../../../Form/ErrorMessage";
import {errorsMixin} from "../../../../mixins/errorsMixin.js";
import processResponseException from "../../../../utils/errors/processResponseException";
import ResizableTextarea from "../../../Form/Textarea/ResizableTextarea";
import FormRow from "../../../Form/FormRow";
import modify from "../../../../rest/modify";

export default {
  name: "ProcedureCloseModal",
  components: {FormRow, ResizableTextarea, ErrorMessage},
  mixins: [errorsMixin],
  props: {
    procedureId: {type: String, default: null},
    type: {type: String, default: null},
  },
  data() {
    return {
      loading: false,
      cancelReason: null,
    };
  },
  computed: {
    ...mapState({
      currentUser: state => state.currentUser.user,
    }),
    title() {
      switch(this.type) {
        case "finish":
          return "Zamknij procedurę";
        case "cancel":
          return "Anuluj procedurę";
        default:
          return null;
      }
    },
    modalContent() {
      switch (this.type) {
        case "finish":
          return "Czy na pewno chcesz zamknąć procedurę?";
        case "cancel":
          return "Podaj powód anulacji procedury";
        default:
          return null;
      }
    },
  },
  methods: {
    close() {
      this.$bvModal.hide("close-procedure");
    },
    onHide() {
      this.$emit("close");
      this.errors = [];
      this.cancelReason = null;
    },
    async submit() {
      this.errors = [];
      this.loading = true;
      try {
        switch (this.type) {
          case "finish":
            await this.finishProcedure();
            break;
          case "cancel":
            await this.cancelProcedure();
            break;
          default:
            return;
        }
        this.close();
        this.$emit("update");
      } catch(e) {
        this.errors = processResponseException(e);
      }
      this.loading = false;
    },
    async finishProcedure() {
      await modify("/api/procedures/finish", {
        procedureId: this.procedureId,
      })
    },
    async cancelProcedure() {
      await modify ("/api/procedures/cancel", {
        procedureId: this.procedureId,
        cancelReason: this.cancelReason,
      });
    },
  },
}
</script>
