<template>
  <div class="card-box">
    <b-alert
      v-if="!readOnly"
      :show="!hasP1Access"
      variant="warning"
    >
      Uzupełnij certyfikat i numer PWZ w profilu użytkownika aby wystawić e-receptę.
    </b-alert>
    <div class="form-row mb-2 border-bottom border-light">
      <div class="form-group col font-weight-bold">
        Recepty
      </div>
      <div>
        <button
          v-if="!readOnly"
          class="btn btn-sm btn-success"
          @click="getPrescriptions"
        >
          <i class="fa fa-sync-alt" />
          Odśwież listę recept
        </button>
        <is-granted
          v-if="!readOnly && patient"
          :privileges="attributes.electronicPrescription"
          :subject="appointmentDocumentId"
          condition="AND"
        >
          <b-btn
            v-b-modal.create-prescription
            class="ml-2"
            size="sm"
            variant="success"
          >
            <i class="fa fa-plus" /> Wystaw e-receptę
          </b-btn>
        </is-granted>
      </div>
    </div>
    <error-message :errors="errors" />
    <loading-mask
      v-if="prescriptionsList.length"
      :loading="loading"
    >
      <table class="table">
        <thead>
          <tr>
            <th>Typ</th>
            <th>Lek</th>
            <th class="text-center">
              Kod dostępu
            </th>
            <th class="text-center">
              Anulowanie
            </th>
            <th class="text-center">
              Drukuj
            </th>
            <th class="text-center">
              Status
            </th>
          </tr>
        </thead>
        <tbody>
          <template v-for="prescription in prescriptionsList">
            <tr
              v-for="(drug, index) in prescription.drugs"
              :key="drug.uuid"
            >
              <td>
                {{ drug.availabilityCategory }}
              </td>
              <td>
                {{ drug.name }} /
                {{ drug.wrapper?'Op. '+drug.wrapper+' '+drug.quantity:null }}
                {{ drug.wrapper&&drug.size?' / ':null }}
                {{ drug.size?'Rozmiar: '+drug.size:null }}
                {{ drug.size&&drug.dose?' / ':null }}
                {{ drug.form?'Forma: '+drug.form:null }}
                {{ drug.form&&drug.dose?' / ':null }}
                {{ drug.dose?'Dawka: '+drug.dose:null }}
              </td>
              <td
                v-if="0===index"
                :class="{'bg-muted': prescription.accessCode && prescription.drugs.length > 1}"
                class="text-center border-bottom"
                :rowspan="prescription.drugs.length"
              >
                <template v-if="prescription.accessCode">
                  {{ prescription.accessCode }}
                </template>
              </td>
              <td class="text-center">
                <b-btn
                  v-if="prescription.prescriptionKey"
                  :disabled="prescription.status !== 'created'"
                  size="sm"
                  style="width: 3rem"
                  variant="outline-primary"
                  @click="openCancelPrescriptionModal(drug.prescriptionId)"
                >
                  <i class="fa fa-trash" />
                </b-btn>
              </td>
              <td
                v-if="0===index"
                :class="{'bg-muted': prescription.drugs.length > 1}"
                class="text-center border-bottom"
                :rowspan="prescription.drugs.length"
              >
                <b-btn
                  v-if="prescription.fileId"
                  :href="`/api/files/${prescription.fileId}`"
                  size="sm"
                  style="width: 3rem"
                  variant="primary"
                  target="_blank"
                >
                  <i class="fa fa-print" />
                </b-btn>
                <b-btn
                  v-else
                  size="sm"
                  style="width: 3rem"
                  variant="outline-primary"
                  @click="getAccessCodeAndPrint(prescription.drugs[0].prescriptionId)"
                >
                  <i class="fa fa-unlock" />
                </b-btn>
              </td>
              <td
                class="text-center"
              >
                <b-badge
                  class="status-badge"
                  :variant="getBadgeVariant(prescription.status)"
                >
                  <i18n
                    component="fragment"
                    :msgid="`@prescription-status-${prescription.status}`"
                  />
                </b-badge>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </loading-mask>
    <create-prescription-modal
      v-if="patient"
      :appointment-document-id="appointmentDocumentId"
      :nfz-branch="nfzBranch"
      :patient="patient"
      :continuous-stay-id="continuousStayId"
      @created="getPrescriptions"
      @prepared="getPrescriptions"
    />
    <b-modal
      id="cancel-prescription-modal"
      no-close-on-backdrop
      title="Anulowanie recepty"
      size="lg"
      title-tag="h3"
      @hide="clearCancelPrescriptionErrors"
    >
      <div class="card-box">
        <div>
          <form-row
            :errors="cancelPrescriptionErrors"
            field="cancelReason"
            label="Powód anulacji"
          >
            <b-form-textarea
              v-model="cancelReason"
              :rows="3"
              :state="stateCancelPrescription('cancelReason')"
              placeholder="Powód anulacji"
            />
          </form-row>
          <form-row
            :errors="cancelPrescriptionErrors"
            field="password"
            label="Hasło do certyfikatu"
          >
            <b-input
              v-model="password"
              :state="stateCancelPrescription('password')"
              type="password"
              autocomplete="new-password"
            />
          </form-row>
        </div>
        <error-message
          :errors="cancelPrescriptionErrors"
        />
      </div>
      <template #modal-footer>
        <button
          class="btn btn-secondary"
          @click="hideCancelPrescriptionModal"
        >
          Anuluj
        </button>
        <button
          :disabled="loading"
          class="btn btn-primary"
          @click="cancelPrescription"
        >
          <i
            :class="loading ? 'fa-spin fa-spinner' : 'fa-check'"
            class="fa"
          />
          Zapisz
        </button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import IsGranted from "../../IsGranted";
import read from "../../../rest/read";
import create from "../../../rest/create";
import {NotFoundException} from "@/rest";
import ErrorMessage from "../../Form/ErrorMessage";
import processResponseException from "../../../utils/errors/processResponseException";
import LoadingMask from "../../Loading/LoadingMask";
import FormRow from "../../Form/FormRow";
import I18n from "../../i18n";
import {isGranted} from "@/security/isGranted";
import CreatePrescriptionModal from "../../Prescription/CreatePrescriptionModal";
import {PrescriptionStatuses} from "@/types/Prescription";

export default {
  components: {
    CreatePrescriptionModal,
    I18n,
    LoadingMask,
    IsGranted,
    ErrorMessage,
    FormRow
  },
  props: {
    idx: {required: true, type: Number},
    module: {required: true, type: Object},
    readOnly: {required: true, type: Boolean},
    patientId: {required: true, type: String},
    appointmentDocumentId: {required: true, type: String},
    continuousStayId: {required: false, type: String, default: null}
  },
  data() {
    return {
      prescriptionsList: [],
      isOpen: false,
      modalType: null,
      errors: [],
      loading: false,
      selectedPrescriptionId: null,
      password: null,
      cancelReason: null,
      cancelPrescriptionErrors: [],
      hasP1Access: true,
      patient: null,
      nfzBranch: null,
    }
  },
  computed: {
    attributes() {
      return {
        prescription: ["CREATE_PRESCRIPTION"],
        electronicPrescription: ["CREATE_ELECTRONIC_PRESCRIPTION", "ACCESS_P1_CLIENT"],
      };
    }
  },
  watch: {
    patientId: {
      immediate: true,
      async handler() {
        await this.getPatientData();
      }
    },
  },
  async mounted() {
    await this.getPrescriptions();
    this.hasP1Access = await this.checkP1Access();
  },
  methods: {
    async getAccessCodeAndPrint(prescriptionId){
      const params = {prescriptionId};
      await create("/api/document-electronic-prescription", params);
      await this.getPrescriptions();
    },
    async getPrescriptions() {
      this.loading = true;
      try {
        const data = await read("/api/prescription", {appointmentDocumentId: this.appointmentDocumentId});
        this.prescriptionsList = data.prescriptions;
      } catch (e) {
        this.errors = processResponseException(e)
      }
      this.loading = false;
    },
    openCancelPrescriptionModal(prescriptionId) {
      this.selectedPrescriptionId = prescriptionId;
      this.$bvModal.show("cancel-prescription-modal");
    },
    hideCancelPrescriptionModal() {
      this.$bvModal.hide("cancel-prescription-modal");
      this.clearCancelPrescriptionErrors();
      this.selectedPrescriptionId = null;
    },
    clearCancelPrescriptionErrors() {
      this.cancelPrescriptionErrors = [];
    },
    async cancelPrescription() {
      try {
        await create(`/api/cancel-electronic-prescription/${this.selectedPrescriptionId}`, {
          password: this.password,
          cancelReason: this.cancelReason
        });
        this.clearCancelPrescriptionErrors();
        this.hideCancelPrescriptionModal();
        this.selectedPrescriptionId = null;
        await this.getPrescriptions();
        this.cancelReason = null;
        this.password = null;
      } catch (exception) {
        this.cancelPrescriptionErrors = processResponseException(exception);
      }
    },
    stateCancelPrescription(field) {
      return this.cancelPrescriptionErrors.find((error) => error.field === field) ? false : null;
    },
    getBadgeVariant(status) {
      switch (status) {
        case PrescriptionStatuses.VERIFIED:
          return "secondary";
        case PrescriptionStatuses.CREATED:
          return "primary";
        case PrescriptionStatuses.COMPLETED:
          return "success";
        case PrescriptionStatuses.QUEUED:
          return "warning";
        default:
          return "light";
      }
    },
    async checkP1Access() {
      return await isGranted("ACCESS_P1_CLIENT");
    },
    async getPatientData() {
      try {
        this.patient = await read(`/api/patients/${this.patientId}`);
        this.nfzBranch = await read(`/api/patients/${this.patientId}/nfz_branch`);
      } catch(e) {
        if (!(e instanceof NotFoundException)) {
          this.errors = processResponseException(e);
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
  .status-badge {
    width: 8rem;
  }
</style>
