<template>
  <div class="row m-b-5">
    <div class="col-auto">
      <span><i18n :msgid="qualifier.type" /></span>
    </div>
    <div class="col text-right">
      <b-form-checkbox-group
        :checked="value"
        buttons
        button-variant="outline-primary"
        @change="emitChange"
      >
        <b-form-checkbox
          v-for="option in options"
          :key="option.value"
          v-b-tooltip.hover
          :value="option.value"
          :title="option.name"
        >
          {{ option.value }}
        </b-form-checkbox>
      </b-form-checkbox-group>
      <div
        v-if="previousEvaluationCategoryData && previousEvaluationCategoryData.qualifiersAnswers[qualifier.type][0]"
        class="m-t-5"
      >
        Ocena: {{ previousEvaluationCategoryData.qualifiersAnswers[qualifier.type][0] }} -
        <span class="text-success">
          {{ previousEvaluationCategoryData.evaluationDate }}
          ({{ previousEvaluationCategoryData.workerName }})
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import I18n from "../../i18n";

export default {
  name: "EvaluationQualifierSingleValue",
  components: {
    I18n,
  },
  props: {
    qualifier: {type: Object, required: true},
    value: {type: Array, default: ()=>[]},
    previousEvaluationCategoryData: {type: Object, default: null},
  },
  computed: {
    options(){
      return this.qualifier.items;
    }
  },
  methods: {
    emitChange(answers) {
      let qualifierAnswer;
      switch (answers.length) {
        case 0:
          qualifierAnswer = [];
          break;
        case 1:
          qualifierAnswer = [answers[0]];
          break;
        default:
          qualifierAnswer = [answers[1]];
          break;
      }
      this.$emit("input", qualifierAnswer);
    },
  },
}
</script>
