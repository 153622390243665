<template>
  <card
    :loading="loading"
    title=""
  >
    <pagination-description
      :items-count="items.length"
      :page="currentPage"
      :per-page="pagination.perPage"
      :total-count="pagination.totalRows"
    />
    <b-table
      :fields="fields"
      :items="itemsWithDetails"
      empty-text="Brak pacjentów"
      hover
      :responsive="true"
      show-empty
    >
      <template
        #cell(show_details)="{detailsShowing, toggleDetails}"
      >
        <!-- we use @click.stop here to prevent emitting of a 'row-clicked' event  -->
        <b-button
          size="sm"
          class="mr-2"
          @click.stop="toggleDetails"
        >
          {{ detailsShowing ? 'Ukryj' : 'Pokaż' }} Wizyty
        </b-button>
      </template>
      <template
        #row-details="{item: {visitList}}"
      >
        <div class="m-l-15">
          <b-table
            :fields="visitFields"
            :items="visitList"
            empty-text="Brak wizyt"
            hover
            :responsive="true"
            show-empty
          >
            <template #cell(status)="{item: {status}}">
              <b-badge
                class="status-badge"
                :variant="getBadgeVariant(status)"
              >
                {{ status }}
              </b-badge>
            </template>
          </b-table>
        </div>
      </template>
    </b-table>
    <pagination-description
      :items-count="items.length"
      :page="currentPage"
      :per-page="pagination.perPage"
      :total-count="pagination.totalRows"
    >
      <b-pagination
        :value="currentPage"
        :per-page="pagination.perPage"
        :total-rows="pagination.totalRows"
        align="right"
        class="mb-0"
        @input="changePage"
      />
    </pagination-description>
  </card>
</template>
<script>
import read from "../../rest/read";
import Card from "../../components/Card";
import isEqual from "lodash/isEqual";
import {mapState} from "vuex";
import PaginationDescription from "../../components/Pagination/PaginationDescription";
import {changePageQuery} from "../../utils/pageUrl/handlePageQuery";

export default {
  name: "SupportNetworkPatientList",
  components: {
    PaginationDescription,
    Card,
  },
  props: {
    page: {type: Number, default: null},
    sort: {type: String, default: null}
  },
  data() {
    return {
      currentPage: this.page || 1,
      pagination: {},
      loading: false,
      items: [],
      fields:
        [
          {key: "displayName", label: "Pacjent"},
          {key: "pesel", label: "Pesel"},
          {key: "registeredDate", label: "Data rejestracji"},
          {key: "show_details", label: "Opcje"}
        ],
      visitFields:[
        {key: "date", label: "Data wizyty"},
        {key: "specialists", label: "Specjaliści"},
        {key: "status", label: "Status"}
      ]
    };
  },
  computed: {
    ...mapState({
      clinicParameters: state => state.clinicParameters.parameters,
    }),
    itemsWithDetails() {
      return this.items.map(item => ({
        ...item,
        _showDetails: false,
      }))
    },
  },
  watch: {
    page(val) {
      this.currentPage = val || 1;
    },
    async "$route"() {
      await this.fetchData();
    },
  },
  async mounted() {
    await this.fetchData();
  },
  methods: {
    async fetchData() {
      this.loading = true;
      const {items, pagination} = await read("/api/support-network-patient-list", {page: this.currentPage});
      this.items = items;
      this.pagination = pagination;
      this.loading = false;
    },
    async updatePageUrl() {
      await changePageQuery(this.query);
    },
    async changePage(page) {
      this.currentPage = page;
      await this.updatePageUrl();
    },
    async submit() {
      this.currentPage = 1;
      if (isEqual(this.$route.query, this.query)) {
        await this.fetchData();
        return;
      }
      await this.updatePageUrl();
    },
    getBadgeVariant(status) {
      switch(status) {
        case "utworzona":
          return "secondary";
        case "zarejestrowana":
          return "primary";
        case "otwarta":
          return "success";
        case "zakończona":
          return "dark";
        case "odwołana":
          return "light";
        default:
          throw new Error(`Unknown appointment status '${name}'`);
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .order-btn {
    cursor: pointer;
    white-space: nowrap;
  }

  .icon {
    transition: transform 1s;
    transform: rotate(0deg);

    &.upside-down {
      transform: rotate(180deg);
    }
  }
  .status-badge{
    width: 7rem;
  }
</style>
