

































import {Component, Mixins, Prop} from "vue-property-decorator";
import ErrorsMixin from "../../mixins/ErrorsMixin";
import modify from "../../rest/modify";
import processResponseException from "../../utils/errors/processResponseException";

@Component({
  name: "RemoveInternalReferralModal",
})

export default class RemoveInternalReferralModal extends Mixins(ErrorsMixin) {
  @Prop({type: String, required: true}) readonly internalReferralId!: string;

  private saving: boolean = false;

  private get modalId(): string {
    return `reject-internal-referral-${this.internalReferralId}`;
  }

  private hideModal(): void {
    this.$bvModal.hide(this.modalId);
  }

  private async removeReferral(): Promise<void> {
    this.saving = true;
    this.errors = [];
    try {
      await modify(`/api/internal-referrals/${this.internalReferralId}/remove`);
      this.$emit("updated");
      this.hideModal();
    } catch(e) {
      this.errors = processResponseException(e);
    }
    this.saving = false;
  }
}
