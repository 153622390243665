<template>
  <div>
    <calendar-options
      v-if="(view === 'day' || view === 'list')"
      v-model="selectedDuration"
    />
    <div class="card-box p-0 mb-5 mt-1">
      <loading-mask :loading="loading">
        <day-calendar
          v-if="view === 'day'"
          :filters="filtersData"
          :workers="sortedWorkers"
          :items="slots"
          :treatment-duration="treatmentDuration"
          :slot-duration="slotDuration"
          :preferred-patient="preferredPatient"
          @updateCalendar="load"
        />
        <timetable
          v-if="view === 'list'"
          :filters="filtersData"
          :workers="sortedWorkers"
          :items="slots"
          :treatment-duration="treatmentDuration"
          :preferred-patient="preferredPatient"
          @updateCalendar="load"
        />
        <month-calendar
          v-if="view === 'month'"
          :filters="filtersData"
          :items="slots"
        />
      </loading-mask>
    </div>
  </div>
</template>
<script>
import DayCalendar from "./DayCalendar";
import Timetable from "./Timetable";
import {mapState} from "vuex";
import MonthCalendar from "./MonthCalendar";
import CalendarOptions from "./CalendarOptions";
import read from "../../rest/read";
import parseDate from "../../utils/date/parseDate";
import LoadingMask from "../Loading/LoadingMask";
import DATE_FORMAT from "../../utils/date/DATE_FORMAT";
import stringifyDate from "../../utils/date/stringifyDate";
import {isGranted} from "../../security/isGranted";
import isEqual from "lodash/isEqual";

export default {
  components: {
    CalendarOptions,
    LoadingMask,
    DayCalendar,
    Timetable,
    MonthCalendar,
  },
  props: {
    view: {type: String, required: true},
    filters: {type: Object, required: true},
    preferredPatient: {type: Object, default: null},
  },
  data() {
    return {
      defaultSlotDuration: 15,
      milisecondsInMinute: 60 * 1000,
      loading: false,
      workers: [],
      items: [],
      treatmentTypeData: null,
      selectedDuration: 30,
      slots: [],
      appointment: null,
    };
  },
  computed: {
    ...mapState("currentUser",["user"]),
    slotDuration() {
      return this.defaultSlotDuration * this.milisecondsInMinute;
    },
    treatmentDuration() {
      return this.selectedDuration * this.milisecondsInMinute;
    },
    filtersData() {
      const endDate = this.filters.endDate
        ? new Date(this.filters.endDate)
        : new Date(this.filters.startDate);

      return {
        treatmentType: this.treatmentTypeData,
        workersList: this.filters.workers,
        startDate: this.filters.startDate,
        endDate,
        appointmentId: this.filters.appointmentId,
        copyId: this.filters.copyId,
        continuousStay: this.filters.continuousStay,
      };
    },
    treatmentType() {
      return this.treatmentTypeData ? this.treatmentTypeData.value : null;
    },
    sortedWorkers() {
      return [...this.calendarWorkers].sort((workerA, workerB) => {
        if (workerA.workerId === workerB.workerId) {
          return workerA.branchName.localeCompare(workerB.branchName);
        }
        if (workerA.workerId === this.user.workerId) {
          return -1;
        }
        if (workerB.workerId === this.user.workerId) {
          return 1;
        }

        return workerA.name.localeCompare(workerB.name);
      });
    },
    calendarWorkers() {
      return this.itemWorkerData.reduce((list, item) => {
        const worker = this.workers.find(worker => worker.workerId === item.workerId);
        return worker
          ? list.concat([
            {...item, ...worker,}
          ])
          : list;
      }, []);
    },
    itemWorkerData() {
      return this.items.reduce((list, item) => {
        return list.find(element => element.workerId === item.workerId && element.branchId === item.branchId)
          ? list
          : list.concat([
            {
              workerId: item.workerId,
              branchId: item.branchId,
              branchName: item.branchName,
            },
          ]);
      }, []);
    },
  },
  watch: {
    async filters(newFilters, oldFilters) {
      if (isEqual(newFilters, oldFilters)) {
        return;
      }
      await this.load();
    },
  },
  async mounted() {
    await this.load();
  },
  methods: {
    async load() {
      this.workers = [];
      this.items = [];
      this.slots = [];
      this.loading = true;
      await this.loadAppointmentDetails();
      const treatmentTypeLoading = this.loadTreatmentTypeData();
      await this.loadCalendarData();
      await this.propagateSlots();
      await treatmentTypeLoading;
      this.loading = false;
    },
    async loadCalendarData() {
      const {items, workers} = await read("/api/workers/availabilities", {
        ...this.filters,
        view: this.view,
        workerId: this.filters.workers,
        workersList: null,
        startDate: stringifyDate(this.filters.startDate, DATE_FORMAT.DATE_TIME),
        endDate: this.filters.endDate ? stringifyDate(this.filters.endDate, DATE_FORMAT.DATE_TIME) : null,
        continuousStay: undefined,
      });
      this.workers = workers;
      this.items = items;
    },
    async loadTreatmentTypeData() {
      if (null == this.filters.treatmentType) {
        this.treatmentTypeData = null;

        return;
      }
      const {items} = await read("/api/treatment-types");
      this.treatmentTypeData = items.find((item) => item.value === this.filters.treatmentType);
    },
    async checkIfIsClickable(item) {
      if (this.filters.appointmentId) {
        if (item.appointmentId && item.appointmentId !== this.filters.appointmentId) {
          return false;
        }
        return isGranted(
          "RESCHEDULE_APPOINTMENT",
          this.filters.appointmentId
        );
      }

      return await this.canScheduleAppointment(item);
    },
    async canRescheduleAppointment(appointmentId) {
      return isGranted("RESCHEDULE_APPOINTMENT", appointmentId);
    },

    async canScheduleAppointment(item) {
      // czy moge tutaj umówić pacjenta
      if (!item.available) {
        return false;
      }
      const attribute =
        this.user.workerId === item.workerId
          ? ["SCHEDULE_APPOINTMENT_SPECIALIST", "SCHEDULE_APPOINTMENT_RECOVERY_ASSISTANT"]
          : "SCHEDULE_APPOINTMENT";
      return isGranted(attribute);
    },
    async checkIfIsEditable(item) {
      if (null == item.appointmentId) {
        return false;
      }

      if (this.filters.appointmentId) {
        return (
          item.appointmentId === this.filters.appointmentId &&
          await this.canRescheduleAppointment(item.appointmentId)
        );
      }

      if (item.group) {
        return this.canScheduleAppointment(item);
      }

      return false;
    },
    async propagateSlots() {
      const slots = this.items.map(async (item) => {
        const clickable = await this.checkIfIsClickable(item);
        const editable = await this.checkIfIsEditable(item);

        return {
          ...item,
          clickable,
          editable,
          start: parseDate(item.start),
          end: parseDate(item.end),
        };
      });

      this.slots = await Promise.all(slots);

    },
    async loadAppointmentDetails() {
      if (this.filters.appointmentId && (this.view === "day" || this.view === "list")) {
        this.appointment = await read(`/api/appointments/${this.filters.appointmentId}`);
      }
    }
  },
};
</script>
