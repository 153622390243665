<template>
  <div>
    <div
      :class="getMarginLeftClass(level)"
    >
      <b-form-checkbox-group
        v-if="!treatmentType.isDisabled"
        :checked="selectedValues"
        :state="state(`treatmentTypes.${treatmentType.value}`)"
      >
        <div :class="getCheckboxClass(level)">
          <b-form-checkbox
            :value="treatmentType.value"
            :state="state(`treatmentTypes.${treatmentType.value}`)"
            @change="update(treatmentType.value)"
          >
            {{ treatmentType.name }}
          </b-form-checkbox>
          <error-message
            :errors="errors"
            :field="`treatmentTypes.${treatmentType.value}`"
          />
        </div>
      </b-form-checkbox-group>
      <div
        v-else
        :class="getFlexAlignmentClass(level)"
      >
        <h2
          v-if="level === 0"
          class="my-4"
        >
          {{ treatmentType.name }}
        </h2>
        <span
          v-else
          class="mr-2 ml-1 p-2 font-weight-bold treatment-parent"
        >
          {{ treatmentType.name }}
        </span>
        <button
          v-if="treatmentType.children"
          class="btn btn-sm btn-outline-secondary ml-auto"
          @click="isAllSelected(treatmentType) ? unselectAll(treatmentType): selectAll(treatmentType)"
        >
          {{ isAllSelected(treatmentType) ? "odznacz" : "zaznacz" }}
        </button>
      </div>
    </div>
    <div
      v-if="treatmentType.children"
      class="children-wrap"
    >
      <treatment-type-expanded-select
        v-for="child in treatmentType.children"
        :key="child.value"
        :treatment-type="child"
        :level="level+1"
        :selected-values="selectedValues"
        :errors="errors"
        @update="receiveUpdate"
      />
    </div>
  </div>
</template>

<script>

import ErrorMessage from "../Form/ErrorMessage";

export default {
  name: "TreatmentTypeExpandedSelect",
  components: {ErrorMessage},
  props: {
    treatmentType: {type: Object, default: () => {}},
    selectedValues: {type: Array, default: () => []},
    errors: {type: Array, default: () => []},
    level: {type: Number, default: 0}
  },
  methods: {
    receiveUpdate(value) {
      this.$emit("update", value);
    },
    update(value) {
      this.$emit("update", {value})
    },
    getMarginLeftClass(level) {
      if (level >= 3) {
        return "ml-4";
      } else {
        return `ml-${level * 2}`;
      }
    },
    getCheckboxClass(level) {
      if(level === 1) {
        return "p-1 ml-2 font-weight-bold";
      } else if(level === 2) {
        return "d-block border-left p-2 ml-1";
      } else if(level >= 3){
        return "d-block border-left p-2 pl-4 ml-1 child-border";
      }
    },
    getFlexAlignmentClass(level) {
      return level === 0 ? "d-flex align-items-center justify-content-between flex-wrap" : "";
    },
    isAllSelected(treatmentType) {
      return treatmentType.children.every(item => {
        if (!this.selectedValues.includes(item.value) && !item.isDisabled) {
          return false;
        }
        if (item.children !== null) {
          return this.isAllSelected(item);
        }
        return true;
      });
    },
    selectAll(treatmentType) {
      treatmentType.children.forEach(item => {
        if (!this.selectedValues.includes(item.value) && !item.isDisabled) {
          this.$emit("update", {value: item.value});
        }
        if (item.children != null) {
          this.selectAll(item);
        }
      });      
    },
    unselectAll(treatmentType) {
      treatmentType.children.forEach(item => {
        if(!item.isDisabled) {
          this.$emit("update", {value: item.value});
        }
        if (item.children != null) {
          this.unselectAll(item);
        }
      });
    },
    state(field) {
      return this.errors.find((error) => error.field === field) ? false : null;
    },
  },
}
</script>

<style scoped lang="scss">
  .child-border {
    position: relative;

    &::before {
      content: "";
      display: block;
      position: absolute;
      left: 15px;
      top: 0;
      bottom: 0;
      border-left: 1px solid #dee2e6;
    }
  }
  .children-wrap .ml-4 .treatment-parent {
    border-left: 1px solid #dee2e6;
  }
</style>
