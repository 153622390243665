<template>
  <div>
    <h3>Kreator ICF</h3>
    <div class="form-group">
      <async-select
        v-model="chosenCoreSets"
        :options-provider="getCoreSetsFromBackend"
        multiple
        placeholder="Wybierz zestawy kluczowe ICF"
      />
    </div>
    <div
      v-if="errorWhileGettingCoreSets"
      class="alert alert-danger"
    >
      Błąd komunikacji z serwerem
    </div>
    <button
      class="btn btn-primary"
      type="submit"
      @click="submit"
    >
      Zatwierdź
    </button>
  </div>
</template>

<script>
import read from "../../../rest/read";
import t from "../../../i18n";
import AsyncSelect from "../../Form/Select/AsyncSelect";

export default {
  components: {
    AsyncSelect,
  },
  data() {
    return {
      chosenCoreSets: [],
      errorWhileGettingCoreSets: false,
    };
  },
  mounted() {
    this.getCoreSetsFromBackend();
  },
  methods: {
    async getCoreSetsFromBackend() {
      try {
        const {items} = await read("/api/icf/core-sets");
        return items.map(coreSet => ({
          "value": coreSet.name,
          "name": t(coreSet.name)
        }));
      } catch (exception) {
        this.errorWhileGettingCoreSets = true;
      }
    },
    submit() {
      if (this.chosenCoreSets.length > 0) {
        this.$emit("showCorrespondingCategoriesForm", this.chosenCoreSets);
      }
    },
  },
};
</script>
