<template>
  <loading-mask :loading="loading">
    <evaluation-form
      v-if="overview"
      :ipz-id="ipzId"
      :evaluation-id="evaluationId"
      :components="components"
      :overview="overview"
      :saved-answers="answers"
      :saved-evaluator-type="evaluatorType"
      :evaluation-status="evaluationStatus"
      :evaluation-created-at="evaluationCreatedAt"
      :evaluation-edited-at="evaluationEditedAt"
      :previous-evaluation="previousEvaluation"
      :evaluation-core-set="evaluationCoreSet"
    />
  </loading-mask>
</template>
<script>
import read from "../../../rest/read";
import EvaluationForm from "../../../components/Ipz/Evaluation/EvaluationForm";
import LoadingMask from "../../../components/Loading/LoadingMask";

export default {
  components: {LoadingMask, EvaluationForm},
  props: {
    ipzId: {type: String, required: true},
    evaluationId: {type: String, required: true},
  },
  data() {
    return {
      overview: null,
      components: null,
      answers: null,
      evaluatorType: null,
      evaluationStatus: null,
      evaluationCreatedAt: null,
      evaluationEditedAt: null,
      loading: false,
      previousEvaluation: null,
      evaluationCoreSet: [],
    }
  },
  watch: {
    async ipzId() {
      await this.loadData();
    },
    async evaluationId() {
      await this.loadData();
    },
  },
  async mounted() {
    await this.loadData();
  },
  methods: {
    async loadData() {
      this.loading = true;
      const {
        overview,components, answers, evaluatorType, evaluationStatus,
        evaluationCreatedAt, previousEvaluation, evaluationEditedAt, evaluationCoreSet} =
        await read(`/api/ipzs/${this.ipzId}/evaluations/${this.evaluationId}`);
      this.previousEvaluation = previousEvaluation;
      this.overview = overview;
      this.components = components;
      this.answers = answers;
      this.evaluatorType = evaluatorType;
      this.evaluationStatus = evaluationStatus;
      this.evaluationCreatedAt = evaluationCreatedAt;
      this.evaluationEditedAt = new Date(evaluationEditedAt);
      this.evaluationCoreSet = evaluationCoreSet;
      this.loading = false;
    },
  }
}
</script>
