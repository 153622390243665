import {state} from "./security/state";
import {getters} from "./security/getters";
import {actions} from "./security/actions";
import {mutations} from "./security/mutations";
import {Module} from "vuex";
import {SecurityState} from "./security/types";
import {RootState} from "../types";

const security: Module<SecurityState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default security;
