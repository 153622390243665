<template>
  <async-select
    :options-provider="loadOptions"
    :value="hasValue ? value : null"
    :state="state"
    placeholder="Wybierz role"
    @input="update"
  />
</template>

<script>
import read from "../../../rest/read";
import AsyncSelect from "./AsyncSelect";

export default {
  components: {
    AsyncSelect,
  },
  props: {
    value: {type: [Object, String], default: null},
    state: {type: Boolean, default: null},
    withComputedRoles: {type: Boolean, default: false},
  },
  computed: {
    hasValue() {
      return !("object" !== typeof this.value);
    }
  },
  watch: {
    async value(newValue) {
      if (typeof (newValue) === "string") {
        const options = await this.loadOptions();
        const value = options.find(option => option.value === this.value);
        this.$emit("input", value);
      }
    }
  },
  methods: {
    async loadOptions() {
      const {roles} = await read("/api/roles-list", {withComputed: this.withComputedRoles});
      return roles.map(item => ({
        ...item,
        value: item.value,
      }));
    },
    update(option) {
      this.$emit("input", option);
    },
  },
}
</script>
