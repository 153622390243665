export interface ScheduleAppointmentState {
  modalOpened: boolean;
  modalType: ModalType | null;
  appointmentData: AppointmentData | null;
  scheduleStep: number;
  summaryModalOpened: boolean;
  appointmentDetails: AppointmentDetails | null;
}

export interface AppointmentData {
  appointmentStart: Date;
  appointmentEnd: Date;
  treatmentType: string;
  worker: Worker;
  appointmentId: string|null;
  oldAppointmentId: string|null;
  copyId: string|null;
  preferredPatient: Patient|null;
  branchId: string|null;
  branchName: string|null;
  plan: string|null;
  reschedule?: boolean;
  scheduleStep?: number;
  continuousStay?: boolean;
  patientContinuousStays?: PatientContinuousStay[];
}

export interface AppointmentDetails {
  appointmentId: string;
  appointmentStart: Date;
  treatmentType: string;
  patients: Array<Patient>;
  patientPresence: boolean;
  worker: SummaryWorker;
  branchName: string,
  room: Room|null;
  address: Address;
  mobile: boolean;
  continuousStay?: boolean;
  patientContinuousStays?: PatientContinuousStay[];
}

export interface SummaryWorker {
  name: string;
}

export interface Worker extends SummaryWorker {
  branchId: string;
  branchName: string;
  selected: boolean;
  treatmentTypeDefaultId: string;
  workerId: string;
}

export interface Patient {
  displayName: string;
  patientId: string;
}

export interface Room {
  roomId: string;
  number: string;
  branchId: string;
  isOccupied: boolean;
  floor: string;
}

export interface Address {
  country: string;
  zipCode: string;
  city: string;
  street: string;
}

export enum ModalType {
  DEFAULT = "default",
  CONTINUOUS_STAY = "continuousStay",
}

export interface PatientContinuousStay {
  patientId: string;
  continuousStayId: string;
}
