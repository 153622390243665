























































































import {Component} from "vue-property-decorator";
import ErrorMessage from "../../components/Form/ErrorMessage.vue";
import DatePicker from "../../components/Form/DatePicker/DatePicker.vue";
import {mixins} from "vue-class-component";
import ErrorsMixin from "../../mixins/ErrorsMixin.ts";
import stringifyDate from "../../utils/date/stringifyDate";
import {createUrl} from "../../utils/pageUrl/createUrl";
import DATE_FORMAT from "../../utils/date/DATE_FORMAT";
import {isGranted} from "../../security/isGranted";
import BackButton from "../../components/BackButton.vue";
import processResponseException from "../../utils/errors/processResponseException";
import PatientSelect from "../../components/Patient/PatientSelect.vue";
import ChildIpzStatusSelect from "../../components/Ipz/ChildIpzStatusSelect";
import BooleanRefusalSelect from "../../components/Form/Select/BooleanRefusalSelect.vue";
import ReportContainer from "../../components/Report/ReportContainer.vue";

@Component({
  name: "ChildIpzMonitoringReport",
  components: {
    ReportContainer,
    BooleanRefusalSelect, BackButton, ErrorMessage, DatePicker, PatientSelect, ChildIpzStatusSelect
  },
})
export default class ChildIpzMonitoringReport extends mixins(ErrorsMixin) {

  selectedPatient: null|object = null;
  status: null|string = null;
  printed: null|boolean = null;
  startDate: null|string = null;
  endDate: null|string = null;
  isLoading: boolean = false;
  canUseReport: boolean = false;

  async mounted() {
    await this.loadUser();
  }

  async loadUser() {
    this.isLoading = true;
    try {
      await this.canUseThisReport();
    } catch (exception) {
      this.errors = processResponseException(exception);
    }
    this.isLoading = false;
  }

  get parsedStartDate() {
    const date = new Date(this.startDate);
    date.setHours(0, 0, 0, 0);
    return this.startDate ? date : null;
  };

  get parsedEndDate() {
    const date = new Date(this.endDate);
    date.setHours(23, 59, 59);
    return this.endDate ? date : null;
  };

  get reportUrl() {
    let baseUrl = "/api/report/child-ipz-monitoring";
    return createUrl(baseUrl, this.filters);
  };

  get filters() {
    return {
      startDate: this.parsedStartDate ? stringifyDate(this.parsedStartDate, DATE_FORMAT.DATE) : null,
      endDate: this.parsedEndDate ? stringifyDate(this.parsedEndDate, DATE_FORMAT.DATE) : null,
      status: this.status,
      patient: this.selectedPatient ? this.selectedPatient.patientId: null,
      printed: this.printed
    }
  }
  async canUseThisReport() {
    this.canUseReport = await isGranted(["GET_CANCEL_REASON_REPORT"]);
  }

  validateReportDates() {
    this.errors = [];
    if (this.parsedStartDate && this.parsedEndDate
        && this.parsedStartDate.getTime() >= this.parsedEndDate.getTime()) {
      this.errors = [
        {
          message: "Data początkowa powinna być wcześniejsza niż data końcowa",
          field: "startDate"
        },
        {
          message: "Data końcowa powinna być późniejsza niż data początkowa",
          field: "endDate"
        },
      ];
    }
    return;
  };

}

