import {Module} from "vuex";
import {AppointmentListItemState} from "./appointmentListItem/types";
import {actions} from "./appointmentListItem/actions";
import {getters} from "./appointmentListItem/getters";
import {mutations} from "./appointmentListItem/mutations";
import {state} from "./appointmentListItem/state";
import {RootState} from "../types";

const appointmentListItem: Module<AppointmentListItemState, RootState> = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};

export default appointmentListItem;
