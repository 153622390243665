<template>
  <div class="card-box">
    <b-tabs lazy>
      <b-tab
        title="Ustawienia"
        active
      >
        <div class="row">
          <div class="col-sm-6 col-lg-4 col-xl-3 mb-4">
            <authentication-method class="h-100 card-box" />
          </div>
          <div class="col-sm-6 col-lg-4 col-xl-3 mb-4">
            <push-notifications-settings class="h-100 card-box" />
          </div>
          <is-granted
            component="fragment"
            :privileges="['GET_WORKER_EMAIL_NOTIFICATION_SETTINGS']"
          >
            <div class="col-sm-6 col-lg-4 col-xl-3 mb-4">
              <unclosed-appointments-notifications class="h-100 card-box" />
            </div>
          </is-granted>
          <div class="col-sm-6 col-lg-4 col-xl-3 mb-4">
            <default-treatment-type-settings
              class="h-100 card-box"
              :worker-id="workerId"
            />
          </div>
          <is-granted
            :privileges="['EDIT_INSURANCE_ACCESS_PROVIDER']"
            :subject="workerId"
            component="fragment"
          >
            <div class="col-sm-6 col-lg-4 col-xl-3 mb-4">
              <ewus-worker-settings
                class="h-100 card-box"
                :worker-id="workerId"
              />
            </div>
          </is-granted>
          <div class="col-sm-6 col-lg-4 col-xl-3 mb-4">
            <nfz-contract-settings class="h-100 card-box" />
          </div>
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import PushNotificationsSettings from "../../components/PushNotifications/PushNotificationsSettings";
import DefaultTreatmentTypeSettings from "../../components/TreatmentType/DefaultTreatmentTypeSettings";
import EwusWorkerSettings from "../../components/Ewus/EwusWorkerSettings";
import IsGranted from "../../components/IsGranted";
import {mapState} from "vuex";
import NfzContractSettings from "../../components/Worker/NfzContractSettings";
import UnclosedAppointmentsNotifications from "../../components/UnclosedAppointmentsNotifications";
import AuthenticationMethod from "@/components/Authentication/AuthenticationMethod";

export default {
  name: "Settings",
  components: {
    AuthenticationMethod,
    UnclosedAppointmentsNotifications,
    NfzContractSettings,
    IsGranted,
    EwusWorkerSettings,
    DefaultTreatmentTypeSettings,
    PushNotificationsSettings,
  },
  computed: {
    ...mapState({
      workerId: state => state.currentUser.user.workerId,
    })
  }
};
</script>
