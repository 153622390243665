<template>
  <b-modal
    id="open-appointment-modal"
    no-close-on-backdrop
    title="Otwórz wizytę"
    size="lg"
    title-tag="h3"
    @hide="clearErrors"
    @show="showModal"
  >
    <template #default>
      <appointment-details
        :appointment-id="appointmentId"
        :date="date"
        :patients="patients"
      />
      <error-message :errors="errors" />
      <div
        v-if="mainWorker"
        class="form-group row"
      >
        <i class="col-2 text-center fa fa-2x fa-user-md" />
        <div class="col-10">
          <treatment-worker-select
            v-model="additionalWorkers"
            :allow-add="false"
            :allow-remove="false"
            disable-choice-if-defined
            :errors="errors"
            :scope="continuousStay ? ['continuous-stay'] : ['ambulatory']"
          />
        </div>
      </div>
      <div
        class="form-group row"
      >
        <i class="col-2 text-center fa fa-2x fa-hospital-alt" />
        <div class="col-10">
          <branch-select
            v-model="newBranchId"
            check-if-any-branch-exists
            :state="state('branchId')"
          />
          <error-message
            :errors="errors"
            field="branchId"
          />
        </div>
      </div>
      <is-granted
        :privileges="['FINISH_EMPTY_APPOINTMENT']"
        :subject="appointmentId"
      >
        <div class="text-center">
          <p>
            Zaplanowana wizyta nie posiada zdefiniowanego pacjenta,
            dlatego możesz ją zamknąć bez konieczności wypełniania dokumentu.
          </p>
          <button
            class="btn btn-sm btn-warning text-center"
            @click="finishEmptyAppointment"
          >
            Zamknij wizytę bez otwierania dokumentu
          </button>
        </div>
      </is-granted>
    </template>
    <template #modal-footer>
      <button
        type="button"
        class="btn btn-secondary"
        @click="closeModal"
      >
        Zamknij
      </button>
      <button
        :disabled="loading"
        type="submit"
        class="btn btn-primary"
        @click="open"
      >
        <i
          v-if="loading"
          class="fas fa-spin fa-spinner"
        />
        Otwórz wizytę
      </button>
    </template>
  </b-modal>
</template>

<script>
import AppointmentDetails from "../AppointmentDetails";
import update from "../../../rest/update";
import {BadRequestException, ForbiddenException} from "../../../rest";
import {errorsMixin} from "../../../mixins/errorsMixin.js";
import processResponseException from "../../../utils/errors/processResponseException";
import ErrorMessage from "../../Form/ErrorMessage";
import TreatmentWorkerSelect from "../../Worker/TreatmentWorkerSelect";
import IsGranted from "../../IsGranted";
import BranchSelect from "@/components/Branch/BranchSelect";

export default {
  components: {
    BranchSelect,
    IsGranted,
    ErrorMessage,
    AppointmentDetails,
    TreatmentWorkerSelect
  },
  mixins: [errorsMixin],
  props: {
    id: {type: String, default: null},
    appointmentId: {type: String, required: true},
    status: {type: String, required: true},
    date: {type: Date, required: true},
    group: {type: Boolean, required: true},
    patients: {type: Array, required: true},
    patientId: {type: String, default: null},
    mainWorker: {type: Object, required: true},
    workers: {type: Array, default: () => []},
    continuousStay: {type: Boolean, default: false},
    branchId: {type: String, required: true},
    branchName: {type: String, required: true},
  },
  data() {
    return {
      loading: false,
      additionalWorkers: [],
      newBranchId: this.branchId,
    };
  },
  computed: {
    documentPath() {
      return {name: "viewDocumentAppointment", params: {appointmentId: this.appointmentId}};
    },
  },
  methods: {
    showModal() {
      this.additionalWorkers = this.workers.map((item) => {return {
        treatmentTypeId: item.treatmentType || item.treatmentTypeId,
        name: item.name,
        workerId: item.workerId,
        isTreatmentTypeUndefined: item.isTreatmentTypeUndefined
      }});
    },
    closeModal() {
      this.$bvModal.hide("open-appointment-modal");
    },
    async open() {
      this.clearErrors();
      this.loading = true;
      const updates = {
        additionalWorkers: this.additionalWorkers,
        branchId: this.newBranchId,
      };
      try {
        await update(`/api/appointment/${this.appointmentId}/open`, updates);
        await this.$router.push(this.documentPath);
      } catch (e) {
        if (e instanceof BadRequestException) {
          this.errors = processResponseException(e);
        }
        if (e instanceof ForbiddenException) {
          //invalid status transition, already opened
          await this.$router.push(this.documentPath);
        }
      }
      this.loading = false;
    },
    clearErrors() {
      this.errors = [];
    },
    finishEmptyAppointment() {
      this.closeModal();
      this.$emit("finishEmptyAppointment");
    },
  },
}
</script>
