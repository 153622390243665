<template>
  <card
    :loading="loading"
    title=""
  >
    <error-message :errors="errors" />
    <form-row
      :errors="errors"
      field="branchId"
      label="Komórka organizacyjna"
    >
      <branch-select
        v-model="formData.branchId"
        :check-if-any-branch-exists="true"
        :disabled="true"
        :state="state('branchId')"
      />
    </form-row>
    <form-row
      :errors="errors"
      field="roomType"
      label="Typ pokoju"
    >
      <room-type-select
        id="room-type"
        v-model="formData.roomType"
        :state="state('roomType')"
      />
    </form-row>
    <form-row
      :errors="errors"
      field="roomNumber"
      label="Numer"
    >
      <b-form-input
        id="room_update_number"
        v-model.trim="formData.roomNumber"
        :state="state('roomNumber')"
        placeholder="Podaj numer pokoju"
      />
    </form-row>
    <form-row
      :errors="errors"
      field="floor"
      label="Piętro"
    >
      <b-form-input
        id="room_update_floor"
        v-model.trim="formData.floor"
        :state="state('floor')"
        placeholder="Podaj numer piętra"
      />
    </form-row>
    <form-row
      :errors="errors"
      field="description"
      label="Opis"
    >
      <b-form-textarea
        id="room_update_description"
        v-model="formData.description"
        :rows="3"
        :state="state('description')"
        @input="removeExcessiveWhitespace"
      />
    </form-row>
    <b-checkbox
      id="room_active"
      v-model="formData.active"
      :state="state('active')"
    >
      <span class="font-weight-bold">
        Pokój aktywny
      </span>
    </b-checkbox>
    <error-message
      :errors="errors"
      field="active"
    />
    <div class="text-right">
      <router-link
        :to="{name: 'room_list'}"
        class="btn btn-secondary waves-effect waves-float"
      >
        Anuluj
      </router-link>
      <button
        :disabled="loading"
        type="submit"
        class="btn btn-primary ml-2 waves-effect waves-float"
        @click="submit"
      >
        <i
          :class="loading ? 'fa-spin fa-spinner' : 'fa-check'"
          class="fa fa-check"
        />
        Zapisz
      </button>
    </div>
  </card>
</template>

<script>
import FormRow from "../../components/Form/FormRow";
import update from "../../rest/update";
import ErrorMessage from "../../components/Form/ErrorMessage";
import removeExceededWhitespace from "../../utils/removeExceededWhitespace";
import {errorsMixin} from "../../mixins/errorsMixin.js";
import processResponseException from "../../utils/errors/processResponseException";
import BranchSelect from "../../components/Branch/BranchSelect";
import read from "../../rest/read";
import RoomTypeSelect from "../../components/Rooms/RoomTypeSelect";
import Card from "../../components/Card";

export default {
  name: "RoomUpdate",
  components: {Card, RoomTypeSelect, ErrorMessage, FormRow, BranchSelect},
  mixins: [errorsMixin],
  props: {
    roomId: {type: String, required: true}
  },
  data() {
    return {
      loading: false,
      formData: {
        roomNumber: null,
        floor: null,
        description: null,
        branchId: null,
        active: false,
        roomType: null,
      },
    };
  },
  async mounted(){
    this.loadData();
  },
  methods: {
    async loadData(){
      this.errors = [];
      this.loading = true;
      try {
        const {roomNumber, floor, description, branchId, active, roomType} = await read(`/api/room/${this.roomId}`);
        this.formData = {
          roomNumber: roomNumber,
          floor: floor,
          description: description,
          branchId: branchId,
          active: active,
          roomType: roomType,
        };
      } catch(exception) {
        this.errors = processResponseException(exception);
      }
      this.loading = false;
    },
    removeExcessiveWhitespace(v) {
      this.formData.description = removeExceededWhitespace(v);
    },
    async submit() {
      this.errors = [];
      this.loading = true;
      try {
        await update(`/api/rooms/${this.roomId}`, this.formData);
        await this.$router.push({name: "room_list"});
      } catch(exception) {
        this.errors = processResponseException(exception);
      }
      this.loading = false;
    },
  },
}
</script>
