<template>
  <div>
    <div
      v-if="showCollapseComponent"
      class="m-b-5"
    >
      <button
        v-b-toggle="'media-query-collapse'"
        class="btn btn-light btn-block text-left waves-effect waves-float"
      >
        <i
          :class="{opened: visible}"
          class="fa fa-caret-down collapse-icon"
        />
        {{ collapseMessage }}
      </button>
      <b-collapse
        id="media-query-collapse"
        :visible="collapseOpen"
        class="mt-2"
        @show="onShow"
        @hide="onHide"
      >
        <slot
          name="collapseContent"
        />
      </b-collapse>
    </div>
    <slot
      v-else
      name="collapseContent"
    />
  </div>
</template>

<script>
export default {
  props: {
    messageOpen: {type: String, default: "Rozwiń"},
    messageClose: {type: String, default: "Zwiń"},
    collapseMediaQuery: {type: String, default: "(max-width: 767.98px)"},
    collapseOpen: {type: Boolean, default: false},
  },
  data() {
    return {
      showCollapseComponent: false,
      collapseMessage: this.collapseOpen ? this.messageClose : this.messageOpen,
      visible: this.collapseMessage,
      viewportWidth: 0,
    };
  },
  created() {
    this.showCollapseComponent = window.matchMedia(this.collapseMediaQuery).matches;
    this.viewportWidth = window.innerWidth;
    window.addEventListener("resize", this.onResize, false);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize, false);
  },
  methods: {
    onShow() {
      this.collapseMessage = this.messageClose;
      this.visible = true;
    },
    onHide() {
      this.collapseMessage = this.messageOpen;
      this.visible = false;
    },
    onResize() {
      if (this.viewportWidth !== window.innerWidth) {
        this.showCollapseComponent = window.matchMedia(this.collapseMediaQuery).matches;
        this.viewportWidth = window.innerWidth;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .collapse-icon {
    transition: transform .3s;
    transform: translate(0, 0);

    &.opened {
      transform: rotate(-180deg);
    }
  }
</style>
