import {parse, stringify} from "query-string";
import parseDate from "./date/parseDate";
import stringifyDate from "./date/stringifyDate";

export function parseSearchParams(s = document.location.search) {
  const parsed = parse(s.replace(/(\w+)\[\d+\]/g, "$1[]"), {arrayFormat: "bracket"});

  return Object.keys(parsed).reduce((object, key) => {
    const value = parseValue(parsed[key]);

    return {...object, [key]: value};
  }, {});
}

export function stringifySearchParams(params) {
  const object = Object.keys(params).reduce((object, key) => {
    const value = params[key];
    if (null == value) {
      return object;
    }
    return {...object, [key]: stringifyValue(value)};
  }, {});
  return stringify(object, {arrayFormat: "index"});
}

const parseValue = (value) => {
  if (Array.isArray(value)) {
    return value.map((v) => parseValue(v));
  }

  switch (value) {
    case "true":
      return true;
    case "false":
      return false;
    case "null":
      return null;
  }

  if (/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/.test(value)) {
    return parseDate(value);
  }

  if (/^-?\d{1,6}$/.test(value)) {
    return parseInt(value);
  }

  return value;
};

const stringifyValue = (value) => {
  if (value instanceof Date) {
    return stringifyDate(value);
  }

  return value;
};
