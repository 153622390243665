<template>
  <b-modal
    id="check-in-appointment-modal"
    no-close-on-backdrop
    title="Potwierdź wizytę"
    title-tag="h3"
    @hide="clearErrors"
  >
    <template #default>
      <p
        v-if="patient != null"
      >
        Czy potwierdzasz przybycie pacjenta {{ patient.displayName }} na wizytę?
      </p>
      <appointment-details
        :appointment-id="appointmentId"
        :date="date"
        :patients="patients"
        :main-worker="mainWorker"
      />
      <is-granted
        :privileges="['FINISH_EMPTY_APPOINTMENT']"
        :subject="appointmentId"
      >
        <div class="text-center">
          <p>
            Zaplanowana wizyta nie posiada zdefiniowanego pacjenta,
            dlatego możesz ją zamknąć bez konieczności wypełniania dokumentu.
          </p>
          <button
            class="btn btn-sm btn-warning text-center"
            @click="finishEmptyAppointment"
          >
            Zamknij wizytę bez otwierania dokumentu
          </button>
        </div>
      </is-granted>
      <error-message :errors="errors" />
    </template>
    <template #modal-footer>
      <button
        type="button"
        class="btn btn-secondary"
        @click="closeCheckInModal"
      >
        Zamknij
      </button>
      <button
        :disabled="loading"
        type="submit"
        class="btn btn-primary"
        @click="checkIn"
      >
        <i
          v-show="loading"
          class="fas fa-spin fa-spinner"
        />
        Potwierdź wizytę
      </button>
    </template>
  </b-modal>
</template>

<script>
import AppointmentDetails from "../AppointmentDetails";
import update from "../../../rest/update";
import ErrorMessage from "../../Form/ErrorMessage";
import {errorsMixin} from "../../../mixins/errorsMixin.js";
import processResponseException from "../../../utils/errors/processResponseException";
import IsGranted from "../../IsGranted";

export default {
  components: {
    IsGranted,
    ErrorMessage,
    AppointmentDetails,
  },
  mixins: [errorsMixin],
  props: {
    id: {type: String, default: null},
    appointmentId: {type: String, required: true},
    date: {type: Date, required: true},
    group: {type: Boolean, required: true},
    patients: {type: Array, required: true},
    patientId: {type: String, default: null},
    mainWorker: {type: Object, required: true},
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    patient() {
      return this.patientId
        ? this.patients.find(patient => patient.patientId === this.patientId)
        : this.patients[0];
    },
  },
  methods: {
    async checkIn() {
      this.clearErrors();
      try {
        this.loading = true;
        await update(`/api/appointment/${this.appointmentId}/checkIn`);
        this.closeCheckInModal();
        this.$emit("checkIn");
      } catch (e) {
        this.errors = processResponseException(e);
      }
      this.loading = false;
    },
    finishEmptyAppointment() {
      this.closeCheckInModal();
      this.$emit("finishEmptyAppointment");
    },
    closeCheckInModal() {
      this.$bvModal.hide("check-in-appointment-modal");
    },
    clearErrors() {
      this.errors = [];
    },
  },
}
</script>
