
















































































































import {mixins} from "vue-class-component";
import {Component, Prop, Watch} from "vue-property-decorator";
import ErrorMessage from "../../components/Form/ErrorMessage.vue";
import ErrorsMixin from "../../mixins/ErrorsMixin";
import BackButton from "../../components/BackButton.vue";
import DatePicker from "../../components/Form/DatePicker/DatePicker.vue";
import I18n from "../../components/i18n.vue";
import Card from "../../components/Card.vue";
import read from "../../rest/read";
import {generateUuid} from "../../utils/uuid/generateUuid";
import create from "../../rest/create";
import stringifyDate from "../../utils/date/stringifyDate";
import DATE_FORMAT from "../../utils/date/DATE_FORMAT";
import eventBus from "../../eventBus";
import processResponseException from "../../utils/errors/processResponseException";
import ReportContainer from "../../components/Report/ReportContainer.vue";
import PaginationDescription from "../../components/Pagination/PaginationDescription.vue";
import {changePageQuery} from "../../utils/pageUrl/handlePageQuery";
import TypeOfServicesPerPatientConditions from "./TypeOfServicesPerPatient/TypeOfServicesPerPatientConditions.vue";

interface TypeOfServicesPerPatient {
  typeOfServicesPerPatientRecordsId: string;
  createdAt: string;
  status: Statuses;
  conditions: Array<string> | null
}

interface FindResponse {
  items: Array<TypeOfServicesPerPatient> | null;
  pagination: Pagination;
}

interface Pagination {
  perPage: number;
  totalRows: number;
}

interface Field {
  key: string;
  label: string;
  class?: string;
}

interface Query {
  page: number | undefined;
}

interface Filters {
  page: number | undefined;
}

enum Statuses {
  PENDING = "pending",
  COMPLETED = "completed",
  REJECTED = "rejected",
}

@Component({components: {
  TypeOfServicesPerPatientConditions,
  PaginationDescription,
  ReportContainer,
  Card,
  ErrorMessage,
  BackButton,
  DatePicker,
  I18n,
}})

export default class TypeOfServicesPerPatientView extends mixins(ErrorsMixin){
  @Prop({type: Number, default: null}) readonly page !: number;

  items: Array<TypeOfServicesPerPatient> | null = null;
  startDate: Date|null = null;
  endDate: Date|null = null
  isLoading: boolean = false;
  isSaving: boolean = false;
  currentPage: number = this.page || 1;
  pagination: Object = {};

  @Watch("page") onPageChange(page: number): void {
    this.currentPage = page;
  }

  get fields(): Array<Field> {
    return [
      {key: "createdAt", label: "Data zlecenia"},
      {key: "status", label: "Status", class: "text-center"},
      {key: "conditions", label: "Warunki"},
      {key: "download", label: "Pobierz", class: "text-center"},
    ];
  }

  mounted(): void {
    this.loadData();
  }

  changePage(page: number) {
    this.currentPage = page;
    this.loadData();
    changePageQuery(this.query());
  }

  filters():Filters {
    return {
      page: this.currentPage || undefined,
    }
  }

  query():Query {
    return {
      page: this.currentPage || undefined
    }
  }

  validateReportDates(): void {
    if (this.startDate && this.endDate
      && this.startDate.getTime() >= this.endDate.getTime()) {
      this.errors = [
        {
          message: "Data początkowa powinna być wcześniejsza niż data końcowa",
          field: "startDate"
        },
        {
          message: "Data końcowa powinna być późniejsza niż data początkowa",
          field: "endDate"
        },
      ];
      return;
    } else {
      const errors = []
      if (!this.startDate) {
        errors.push({
          message: "Pole nie powinno być puste",
          field: "startDate"
        })
      }
      if (!this.endDate) {
        errors.push({
          message: "Pole nie powinno być puste",
          field: "endDate"
        })
      }
      this.errors = errors;
      return;
    }
    this.errors = [];
  }

  clearForm(): void {
    this.startDate = null;
    this.endDate = null;
    this.errors = [];
  }

  async loadData(): Promise<void>{
    this.isLoading = true;
    const {items, pagination} = await read<FindResponse>("/api/report/type-of-services-per-patient", this.filters());
    this.items = items;
    this.pagination = pagination;
    this.isLoading = false;
  }

  async createReport(): Promise<void> {
    try {
      this.isSaving = true;
      if(this.startDate && !this.endDate){
        this.endDate = new Date();
      }
      const typeOfServicesPerPatientRecordsId = generateUuid() as string;
      await create("/api/report/type-of-services-per-patient", {
        typeOfServicesPerPatientRecordsId,
        startDate: !!this.startDate ? stringifyDate(this.startDate, DATE_FORMAT.DATE) : null,
        endDate: !!this.endDate ? stringifyDate(this.endDate, DATE_FORMAT.DATE) : null,
        conditions: {
          startDate: !!this.startDate ? stringifyDate(this.startDate, DATE_FORMAT.DATE) : null,
          endDate: !!this.endDate ? stringifyDate(this.endDate, DATE_FORMAT.DATE) : null,
        }
      });
      this.clearForm();
      await this.loadData();
      eventBus.on(typeOfServicesPerPatientRecordsId, ()=>{ this.loadData() });
    } catch (exception) {
      this.errors = processResponseException(exception);
    }
    this.isSaving = false;
  }

  downloadStatusIconClass(status: Statuses): string | void {
    switch(status)  {
      case "pending":
        return "fa-spin fa-spinner";
      case "rejected":
        return "fa-ban text-danger";
      default:
        return;
    }
  }

  getBadgeVariant(status: Statuses): string {
    switch(status) {
      case Statuses.PENDING:
        return "success";
      case Statuses.COMPLETED:
        return "primary";
      case Statuses.REJECTED:
        return "danger";
      default:
        throw Error(`Not supported status: ${status}`);
    }
  }
}
