<template>
  <ObjectSelect
    :options="options"
    :value="value"
    :disabled="disabled"
    :clearable="clearable"
    :placeholder="placeholder"
    :loading="loading"
    :name="name"
    :multiple="multiple"
    :state="state"
    @search-change="loadOptions"
    @open="open"
    @close="cancelLoading"
    @input="update"
    @select="select"
  />
</template>
<script>
import ObjectSelect from "./ObjectSelect";

export default {
  components: {ObjectSelect},
  props: {
    optionsProvider: {type: Function, required: true},
    clearOnOpen: {type: Boolean, default: false},
    name: ObjectSelect.props.name, // eslint-disable-line
    value: ObjectSelect.props.value, // eslint-disable-line
    disabled: ObjectSelect.props.disabled, // eslint-disable-line
    clearable: ObjectSelect.props.clearable, // eslint-disable-line
    placeholder: ObjectSelect.props.placeholder, // eslint-disable-line
    multiple: ObjectSelect.props.multiple, // eslint-disable-line
    cacheKey: {type: String, default: null},
    state: ObjectSelect.props.state, // eslint-disable-line
  },
  data() {
    return {
      loading: false,
      phrase: "",
      typingTimeout: null,
      options: []
    };
  },
  watch: {
    cacheKey() {
      this.options = [];
    }
  },
  methods: {
    cancelLoading() {
      this.loading = false;
      clearTimeout(this.typingTimeout);
    },
    loadOptions(phrase) {
      this.loading = true;
      this.phrase = phrase;
      clearTimeout(this.typingTimeout);
      this.typingTimeout = setTimeout(this.doLoad, 1000);
    },
    async doLoad(){
      const loadedOptions = await this.optionsProvider(this.phrase);
      const newOptions = loadedOptions.filter((option) => !this.options.find((o) => o.value === option.value));
      this.options = this.options.concat(newOptions);
      this.loading = false;
    },
    open() {
      if (this.clearOnOpen) {
        this.options = [];
      }
      this.doLoad();
      this.$emit("open");
    },
    update(option) {
      this.$emit("input", option);
    },
    select(option) {
      this.$emit("select", option);
    }
  },
}
</script>
