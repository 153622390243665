<template>
  <div
    class="widget-simple-chart text-right card-box"
  >
    <div class="circliful-chart circliful m-t-5">
      <i
        v-if="icon"
        :class="`fa fa-4x fa-${icon}`"
      />
    </div>
    <h3 class="text-primary m-t-10">
      <i
        v-if="loading"
        class="fa fa-spinner fa-spin"
      />
      <router-link
        v-else-if="link"
        :to="link"
      >
        {{ value }}
      </router-link>
      <template v-else>
        {{ value }}
      </template>
    </h3>
    <p
      v-b-tooltip.hover.bottom
      class="text-muted m-b-10"
      :title="tooltipText"
    >
      {{ title }}
    </p>
  </div>
</template>
<script>
import read from "../rest/read";

const defaultFetchValue = async (resource, filters) => {
  const {pagination} = await read(resource, {...filters, perPage: 1});
  return pagination.totalRows;
};

export default {
  name: "CounterWidget",
  props: {
    title: {type: String, required: true},
    icon: {type: String, required: true},
    resource: {type: String, required: true},
    fetchValue: {type: Function, default: defaultFetchValue},
    goToUrl: {type: String, default: null},
    tooltipText: {type: String, default: null},
    filters: {type: Object, default: () => ({})},
  },
  data() {
    return {
      value: 0,
      loading: false,
    }
  },
  computed: {
    link() {
      if(!this.goToUrl) {
        return null;
      }
      return {
        path: this.goToUrl,
        query: {
          ...this.filters,
          workerIds: this.filters.workerIds ? this.filters.workerIds.join(",") : undefined,
          statuses: this.filters.statuses ? this.filters.statuses.join(",") : undefined,
        }
      }
    }
  },
  watch: {
    filters() {
      this.loadValue();
    },
  },
  mounted() {
    this.loadValue();
  },
  methods: {
    async loadValue() {
      this.loading = true;
      this.value = await this.fetchValue(this.resource, this.filters);
      this.loading = false;
    }
  }
}
</script>
