

















import ErrorMessage from "../../Form/ErrorMessage.vue";
import create from "../../../rest/create";
import processResponseException from "../../../utils/errors/processResponseException";
import {generateUuid} from "../../../utils/uuid/generateUuid";
import SimplifiedIpzEntryForm from "./SimplifiedIpzEntryForm.vue";
import ErrorsMixin from "../../../mixins/ErrorsMixin.ts";
import {Component, Prop} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import type {EntryPart} from "../../../types/SimplifiedIpzTypes";
import {EntryHour} from "../../../types/SimplifiedIpzTypes";

interface FormDataItem {
  category: EntryPart["category"];
  content: EntryPart["content"]|null;
  entryDate: EntryHour|null
}

@Component({
  name: "SimplifiedIpzTimelineEntryAdd",
  components: {SimplifiedIpzEntryForm, ErrorMessage},
})

export default class SimplifiedIpzTimelineEntryAdd extends mixins(ErrorsMixin) {
  @Prop({type: String, required: true}) readonly simplifiedIpzId!: string;

  formData: Array<FormDataItem> = [{
    category: "manual",
    content: null,
    entryDate: null
  }];
  loading: boolean = false;


  async addEntry(): Promise<void> {
    this.loading = true;
    this.errors = [];
    try {
      await create(`/api/simplified-ipzs/${this.simplifiedIpzId}/entries`, {
        entryId: generateUuid(),
        parts: this.formData,
        entryDate: this.formData[0].entryDate
      });
      this.$emit("entryAdded");
      this.resetData();
    } catch (e) {
      this.errors = processResponseException(e);
    }
    this.loading = false;
  }

  resetData(): void {
    this.formData = [{
      category: "manual",
      content: null,
      entryDate: null
    }];
  }
}
