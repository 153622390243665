<template>
  <div class="row">
    <div
      v-for="(requirement, index) in appointmentRequirements"
      :key="index"
      class="col-6"
    >
      <requirement-meeting-dashboard-item
        :selected="selectedRequirementType === requirement.treatmentType"
        :requirement="requirement"
        :treatment-type="treatmentType(requirement.treatmentType)"
        @click="select"
      />
    </div>
  </div>
</template>

<script>
import RequirementMeetingDashboardItem from "./RequirementMeetingDashboardItem.vue";

export default {
  name: "IpzAppointmentRequirementsList",
  components: {
    RequirementMeetingDashboardItem,
  },
  props: {
    appointmentRequirements: {required: true, type: Array},
    treatmentTypes: {required: true, type: Array},
  },
  data() {
    return {
      selectedRequirementType: null,
    };
  },
  methods: {
    select(selectedRequirement) {
      this.selectedRequirementType = selectedRequirement.treatmentType;
      this.$emit("chosenTreatment", selectedRequirement);
    },
    treatmentType(value) {
      return this.treatmentTypes.find(type => type.value === value);
    },
  },
};
</script>
