<template>
  <is-granted
    :privileges="['COURSE_LIST']"
    component="div"
  >
    <course-list-card />
  </is-granted>
</template>
<script>
import CourseListCard from "./CourseListCard";
import IsGranted from "../IsGranted";

export default {
  components: {
    IsGranted,
    CourseListCard,
  }
};
</script>
