















































import Vue from "vue";
import VueMultiSelect from "vue-multiselect";
import {Component, Prop, Watch} from "vue-property-decorator";
import type {ContinuousStay} from "../../../../types/ContinuousStay";
import {ContinuousStayStatus} from "../../../../types/ContinuousStay";
import read from "../../../../rest/read";
import stringifyDate from "../../../../utils/date/stringifyDate";
import DATE_FORMAT from "../../../../utils/date/DATE_FORMAT";
import t from "../../../../i18n";
import parseDate from "../../../../utils/date/parseDate";

@Component({
  name: "ContinuousStaySelectWithStatus",
  components: {VueMultiSelect},
  directives: {
    tabindex: {
      inserted(el) {
        el.setAttribute("tabindex", 0);
      },
    },
  },
})
export default class ContinuousStaySelectWithStatus extends Vue {
  @Prop({type: Boolean, default: false}) readonly disabled!: boolean;
  @Prop({type: String, default: null}) readonly patientId!: string|null;
  @Prop({type: String, default: "Wybierz pobyt ciągły"}) readonly placeholder!: string;
  @Prop({type: Boolean, default: null}) readonly state!: boolean|null;
  @Prop({type: String, default: null}) readonly value!: string|null;

  private loading: boolean = false;
  private options: ContinuousStay[] = [];

  private get selectedOption(): ContinuousStay|null {
    if (!this.value) {
      return null;
    }
    return this.options.find(option => option.continuousStayId === this.value) || null;
  }

  private get isInvalid() {
    return false === this.state;
  }

  @Watch("patientId", {immediate: true}) async onPatientIdChange() {
    await this.loadOptions();
  }

  @Watch("selectedOption") onSelectedOptionChange(newVal: ContinuousStay|null) {
    if (!newVal && this.value) {
      this.update(null);
    }
  }
  private async loadOptions(): Promise<void> {
    if (!this.patientId) {
      this.options = [];
      return;
    }
    this.loading = true;
    const {items} = await read(`/api/patients/${this.patientId}/continuous-stays`, {
      page: 1,
      perPage: 100,
    });
    this.options = items.map((item: ContinuousStay) => {
      const category = t(`@continuousStay.${item.continuousStayCategory}`);
      const startDate = this.standardizeDate(item.startDate);
      const time = item.endDate
        ? `${startDate} - ${this.standardizeDate(item.endDate)}`
        : startDate;
      return {
        ...item,
        name: `${category} ${time}`,
      };
    });
    this.loading = false;
  }

  private update(option: ContinuousStay|null): void {
    this.$emit("input", option?.continuousStayId || null);
  }

  standardizeDate(dateStr: string): string {
    const dateObj = parseDate(dateStr, DATE_FORMAT.DATE_TIME_SEC);
    return stringifyDate(dateObj, DATE_FORMAT.DATE)
  }

  statusClass(status:ContinuousStayStatus): string {
    switch (status) {
      case ContinuousStayStatus.IN_PROGRESS:
        return "text-success";
      case ContinuousStayStatus.CLOSED:
        return "text-primary";
      case ContinuousStayStatus.CANCELED:
        return "text-danger";
      default:
        return "text-secondary";
    }
  }

}
