

































































import FormRow from "../Form/FormRow.vue";
import ErrorsMixin from "../../mixins/ErrorsMixin";
import {mixins} from "vue-class-component";
import Component from "vue-class-component";
import {Prop, Ref} from "vue-property-decorator";
import TextareaCounter from "../Form/Textarea/TextareaCounter.vue";
import processResponseException from "../../utils/errors/processResponseException";
import modify from "../../rest/modify";
import LoadingMask from "../Loading/LoadingMask.vue";
import {isGranted} from "../../security/isGranted";
import {BFormTextarea} from "bootstrap-vue";

@Component({
  name: "RegistrationNotes",
  components: {LoadingMask, FormRow, TextareaCounter},
})
export default class RegistrationNotes extends mixins(ErrorsMixin) {
  @Prop({type: String, default: null}) value !: string | null;
  @Prop({type: String, required: true}) appointmentId !: string;
  @Prop({type: String, default: null}) appointmentStatus !: string;

  @Ref("textarea") readonly textarea!: BFormTextarea;

  registrationNotes: string = "";
  editing: boolean = false;
  loading: boolean = false;
  isReception: boolean = false;

  get isEditable(): boolean {
    return this.isReception && this.appointmentStatus === "created";
  }

  async mounted() {
    await this.loadUser();
  }

  async loadUser() {
    this.loading = true;
    this.isReception = await isGranted(["ROLE_RECEPTION", "ROLE_ADMIN"]);
    this.loading = false;
  }

  startEditing(): void {
    this.registrationNotes = this.value as string;
    this.editing = true;
    setTimeout(() => {
      (this.textarea.$children[0].$el as HTMLTextAreaElement).focus();
    });
  }

  stopEditing(): void {
    this.editing = false;
  }

  cancel(): void {
    this.errors = [];
    this.stopEditing();
  }

  async edit(): Promise<void> {
    this.errors = [];
    if (this.value === this.registrationNotes || !this.isReception) {
      this.stopEditing();
      return;
    }
    this.loading = true;
    try {
      await modify(`/api/appointments/${this.appointmentId}/registration-notes`, {
        registrationNotes: this.registrationNotes,
      });
      this.$emit("input", this.registrationNotes);
      this.stopEditing();
    } catch(e) {
      this.errors = processResponseException(e);
    }

    this.loading = false;
  }
}
