































import WeeklyScheduleWorker from "./WeeklyScheduleWorker.vue";
import moveDateByDays from "../../utils/moveDateByDays";
import WeeklyScheduleDay from "./WeeklyScheduleDay.vue";
import AppointmentBoxModals from "../Appointment/AppointmentBox/AppointmentBoxModals.vue";
import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";
import type {WeeklyScheduleItem, WorkerObject} from "../../types/WeeklySchedule";

@Component({
  name: "WeeklyScheduleTable",
  components: {AppointmentBoxModals, WeeklyScheduleDay, WeeklyScheduleWorker},
})

export default class WeeklyScheduleTable extends Vue {
  @Prop({type: Array, default: () => []}) readonly items!: Array<WeeklyScheduleItem>;
  @Prop({type: Array, default: () => []}) readonly canceledItems!: Array<WeeklyScheduleItem>;
  @Prop({type: Array, default: () => []}) readonly workers!: Array<WorkerObject>;
  @Prop({type: Date, required: true}) readonly startDate!: Date;
  @Prop({type: Number, required: true}) readonly visibleDays!: number;

  moveDateByDays = moveDateByDays;

  get sortedWorkers(): Array<WorkerObject> {
    return [...this.workers].sort((workerA, workerB) => {
      return workerA.name.localeCompare(workerB.name);
    });
  }

  workerItems(workerId: string): Array<WeeklyScheduleItem> {
    return this.items.filter(item => item.workerId === workerId);
  }

  workerCanceledItems(workerId: string): Array<WeeklyScheduleItem> {
    return this.canceledItems.filter(item => item.workerId === workerId);
  }

  updateSchedule(): void {
    this.$emit("updateSchedule");
  }
}
