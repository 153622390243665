












import {Component, Vue} from "vue-property-decorator";
import Tooltip from "../Tooltip.vue";

@Component({
  name: "UserNotesButton",
  components: {Tooltip}
})
export default class UserNotesButton extends Vue {

}
