

























import Vue from "vue";
import ObjectSelect from "../Form/Select/ObjectSelect.vue";
import Component from "vue-class-component";
import {Prop, Ref} from "vue-property-decorator";
import type {DosageFormOption} from "../../types/Prescription";
import {Error} from "../../rest";
import blur from "../../directives/blur";

@Component({
  name: "MedicationRate",
  directives: {
    blur,
  },
  components: {ObjectSelect}
})
export default class MedicationRate extends Vue {
  @Prop({type: String, default: null}) readonly value!: string;
  @Prop({type: String, default: "Wybierz..."}) readonly placeholder!: string;
  @Prop({type: Boolean, default: false}) readonly disabled!: boolean;
  @Prop({type: Array, default: ()=>[]}) readonly errors!: Array<Error>

  @Ref("multiselect") readonly multiselect!: ObjectSelect;

  private edited: boolean = false;

  private get isInvalid(): boolean {
    return this.value !== "hours" && !!this.errors.find((error) => error.field === "rateQuantity");
  }

  private get editable(): boolean {
    return !this.disabled && !this.edited;
  }

  private get options(): Array<DosageFormOption> {
    return [
      {value: "1", name: "Raz dziennie"},
      {value: "2", name: "2 x dziennie"},
      {value: "3", name: "3 x dziennie"},
      {value: "4", name: "4 x dziennie"},
      {value: "hours", name: "Co x godzin"},
    ];
  }

  private get selectedOption(): DosageFormOption|null {
    if (!this.value) {
      return null
    } else {
      return this.options.find((option) => option.value === this.value) || null;
    }
  }

  private update(option: DosageFormOption|null): void {
    this.$emit("input", option ? option.value : null);
  }

  private startEdit(): void {
    if (!this.disabled) {
      this.edited = true;
      this.$nextTick(() => this.multiselect.activate());
    }
  }

  private stopEdit(deactivate: boolean = true): void {
    if (deactivate) {
      this.multiselect.deactivate();
    }
    this.edited = false;
  }
}
