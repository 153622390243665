<template>
  <object-select
    :value="selectedOption"
    :options="options"
    :disabled="disabled"
    :loading="loading"
    :multiple="multiple"
    placeholder="Wyszukaj pacjenta"
    @search-change="searchChange"
    @input="update"
    @open="open"
  />
</template>

<script>
import read from "../../rest/read";
import ObjectSelect from "../Form/Select/ObjectSelect";

const patient2option = (patient) => ({
  patient,
  value: patient.patientId,
  name: patient.displayName,
});

export default {
  components: {ObjectSelect},
  props: {
    value: {type: [Array, Object, String], default: null},
    multiple: {type: Boolean, default: false},
    state: {type: Boolean, default: null},
    disabled: {type: Boolean, default: false},
    caseManager: {type: String, default: null}
  },
  data() {
    return {
      typingTimeout: null,
      options: [],
      selectedOption: null,
      loading: false,
    };
  },

  watch: {
    caseManager(){
      if(this.caseManager){
        this.loadParticipants();
        this.selectedOption = [];
      } else {
        this.options = [];
      }
    },
    value: {
      immediate: true,
      async handler() {
        await this.loadSelectedOption();
      }
    }
  },

  methods: {
    async loadParticipants(phrase){
      this.loading = true;
      const caseManagers = this.caseManager ? [this.caseManager] : undefined;
      const {items} = await read("/api/patients", {phrase, caseManagers});
      this.options = items.map(option => patient2option(option));
      this.loading = false;
    },
    searchChange(phrase) {
      clearTimeout(this.typingTimeout);
      if (phrase.length) {
        this.typingTimeout = setTimeout(async () => {
          this.loadParticipants(phrase)
        }, 500);
      }
    },
    async loadSelectedOption() {
      if (null === this.value) {
        this.selectedOption = null;
        return;
      }

      if (Array.isArray(this.value) && "string" === typeof this.value[0]) {
        //Array<string>
        const responses = await Promise.all(this.value.map(patientId => read("/api/patients", {patientId})));
        this.selectedOption = responses.reduce((patients, {items}) => {
          const optionItems = items.map(item => patient2option(item));
          return [...patients, ...optionItems];
        }, []);
        this.options = this.selectedOption;
        this.update(this.selectedOption);

      } else if (Array.isArray(this.value)) {
        //Array<object>
        this.selectedOption = this.value.map(patient => patient2option(patient));

      } else {

        if ("string" === typeof this.value) {
          // string
          const {items} = await read("/api/patients", {patientId: this.value});
          this.selectedOption = patient2option(items[0]);
          this.update(this.selectedOption);
        } else {
          // object
          this.selectedOption = patient2option(this.value);
        }
        this.options = [this.selectedOption];
      }
    },
    update(option) {
      if (!option) {
        this.$emit("input", null);
      }
      if (Array.isArray(this.value)) {
        this.$emit("input", option.map(optionItem => optionItem.patient));
      } else {
        this.$emit("input", option.patient);
      }
    },
    open() {
      if(!this.caseManager){
        this.options = [];
      }
    },
  }
}
</script>
