










































import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";
import {Error} from "../../rest";
import ErrorMessage from "../Form/ErrorMessage.vue";
import read from "../../rest/read";
import type {InternalReferral, InternalReferralFormData} from "../../types/InternalReferral";
import InternalReferralForm from "./InternalReferralForm.vue";
import processResponseException from "../../utils/errors/processResponseException";
import LoadingMask from "../Loading/LoadingMask.vue";
import InternalReferralComments from "./InternalReferralComments.vue";
import modify from "../../rest/modify";

@Component({
  name: "PreviewInternalReferralModal",
  components: {InternalReferralComments, LoadingMask, InternalReferralForm, ErrorMessage}
})
export default class PreviewInternalReferralModal extends Vue {
  @Prop({type: String, required: true}) readonly internalReferralId!: string;

  private loading: boolean = false;
  private saving: boolean = false;
  private addedFiles: boolean = false;
  public errors: Error[] = [];
  private data: InternalReferralFormData = {};
  private patientDisplayName: string|null = null;
  private files: File[] = [];

  private get modalId(): string {
    return `preview-internal-referral-${this.internalReferralId}`;
  }

  private hideModal(): void {
    this.$bvModal.hide(this.modalId);
  }

  private updateFiles(): void {
    this.addedFiles = true;
  }

  private async fetchData(): Promise<void> {
    this.loading = true;
    this.errors = [];
    try {
      const {
        patientId,
        displayName,
        referringEstablishment,
        receivingEstablishment,
        description,
        rejectReason,
        author,
        files
      } = await read<InternalReferral>(`/api/internal-referrals/${this.internalReferralId}`);
      this.files = files;
      this.data = {
        patient: patientId,
        referringEstablishment: referringEstablishment,
        receivingEstablishment: receivingEstablishment,
        description,
        rejectReason,
        author: author,
        files: files.map(file => file.fileId)
      }
      this.patientDisplayName = displayName;
    } catch (e) {
      this.errors = processResponseException(e);
    }
    this.loading = false;
  }

  private async sendFiles(): Promise<void> {
    this.saving = true;
    this.errors = []
    try {
      await modify(`/api/internal-referrals/${this.internalReferralId}/files`, {
        files: this.data.files
      });
      this.$emit("updated");
    } catch(e) {
      this.errors = processResponseException(e);
    }
    await this.fetchData();
    this.saving = false;
  }
}
