<template>
  <form>
    <b-form-group
      label="Zakres dat"
      label-for="date-from"
    >
      <date-picker
        id="date-from"
        v-model="dateRange"
        :range="true"
        :lang="lang"
        class="w-auto"
        input-class="form-control"
        placeholder="Wybierz zakres dat"
      />
    </b-form-group>

    <div class="form-group form-check">
      <input
        id="all-day"
        v-model="allDay"
        type="checkbox"
        class="form-check-input"
      >
      <label
        class="form-check-label"
        for="all-day"
      >
        Całe dni
      </label>
    </div>
    <transition name="fade">
      <div
        v-if="!allDay"
        class="d-flex flex-wrap"
      >
        <b-form-group
          label="Od godziny"
          label-for="hour-from"
          class="mr-2"
        >
          <hour-select
            id="hour-from"
            v-model="hourFrom"
            class="hour-select"
          />
        </b-form-group>
        <b-form-group
          label="Do godziny"
          label-for="hour-to"
        >
          <hour-select
            id="hour-to"
            v-model="hourTo"
            class="hour-select"
          />
        </b-form-group>
      </div>
    </transition>
    <transition name="fade">
      <div
        v-if="errorSubmitting"
        class="alert alert-danger alert-dismissible"
      >
        <button
          type="button"
          class="close"
          @click="errorSubmitting = false"
        >
          <span>&times;</span>
        </button>
        {{ errorMessage }}
      </div>
    </transition>
    <transition name="fade">
      <div
        v-if="successSubmitting"
        class="alert alert-success alert-dismissible"
      >
        <button
          type="button"
          class="close"
          @click="successSubmitting = false"
        >
          <span>&times;</span>
        </button>
        Zapisano zmiany
      </div>
    </transition>
    <div class="text-right">
      <button
        class="btn btn-secondary"
        @click.prevent="goBackToList"
      >
        Powrót
      </button>
      <button
        :disabled="loading"
        class="btn btn-primary ml-2"
        @click.prevent="submit"
      >
        <i
          :class="loading? 'fa-spin fa-spinner' : 'fa-check'"
          class="fa"
        />
        Zapisz
      </button>
    </div>
  </form>
</template>

<script>
import DatePicker from "vue2-datepicker";
import lang from "../../../i18n/datePicker.json";
import stringifyDate from "../../../utils/date/stringifyDate";
import create from "../../../rest/create";
import processResponseException from "../../../utils/errors/processResponseException";
import HourSelect from "../../Form/Select/HourSelect";

export default {
  name: "WorkerAbsenceForm",
  components: {
    HourSelect,
    DatePicker,
  },
  props: {
    workerId: {type: String, required: true},
  },
  data() {
    return {
      dateRange: [
        new Date(), new Date()
      ],
      hourFrom: {
        HH: "12",
        mm: "00",
      },
      hourTo: {
        HH: "12",
        mm: "30",
      },
      allDay: false,
      errorSubmitting: false,
      errorMessage: "",
      successSubmitting: false,
      lang,
      loading: false,
    };
  },
  methods: {
    async submit() {
      this.loading = true;
      try {
        await create(`/api/workers/${this.workerId}/absences`, {
          dateFrom: stringifyDate(this.dateRange[0], "YYYY-MM-DD"),
          dateTo: stringifyDate(this.dateRange[1], "YYYY-MM-DD"),
          hourFrom: `${this.hourFrom.HH}:${this.hourFrom.mm}`,
          hourTo: `${this.hourTo.HH}:${this.hourTo.mm}`,
          allDay: this.allDay
        });
        this.successSubmitting = true;
        this.errorSubmitting = false;
        this.loading = false;
        this.$emit("absenceSubmitted");
      } catch(exception) {
        this.errorSubmitting = true;
        this.successSubmitting = false;
        this.loading = false;
        const errors = processResponseException(exception);
        this.errorMessage = errors.map(error => error.message).join();
      }
    },
    goBackToList() {
      this.$router.push({name: "listWorkingHoursOfWorker"});
    }
  },
}
</script>

<style scoped lang="scss">
  .hour-select {
    width: 160px;
  }
</style>
