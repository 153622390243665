<template>
  <div>
    <transition name="fade">
      <div
        v-if="errorDeleting"
        class="alert alert-danger alert-dismissible"
      >
        <button
          type="button"
          class="close"
          @click="errorDeleting = false"
        >
          <span>&times;</span>
        </button>
        {{ errorMessage }}
      </div>
    </transition>

    <transition name="fade">
      <div
        v-if="successDeleting"
        class="alert alert-success alert-dismissible"
      >
        <button
          type="button"
          class="close"
          @click="successDeleting = false"
        >
          <span>&times;</span>
        </button>
        Zapisano zmiany
      </div>
    </transition>

    <ul class="list-group">
      <li
        v-for="(absence, index) in absenceList"
        :key="index"
        class="list-group-item d-flex align-items-center justify-content-between"
      >
        <div>
          Daty: {{ absence.dateFrom }} - {{ absence.dateTo }},
          <span v-if="!absence.allDays"><br>godziny: {{ absence.hourFrom }} - {{ absence.hourTo }} </span>
          <span v-if="absence.allDays"><br>całe dni </span>
        </div>
        <tooltip
          class="btn btn-sm btn-outline-secondary"
          component="button"
          tooltip-content="Usuń"
          type="button"
          @click="deleteAbsence(absence)"
        >
          <i class="fa fa-trash" />
        </tooltip>
      </li>
    </ul>
  </div>
</template>

<script>
import Tooltip from "../../Tooltip";
import processResponseException from "../../../utils/errors/processResponseException";
import remove from "@/rest/remove";

export default {
  name: "WorkerAbsenceList",
  components: {Tooltip},
  props: {
    workerId: {type: String, required: true},
    absenceList: {type: Array, required: true},
  },
  data() {
    return {
      successDeleting: false,
      errorDeleting: false,
      errorMessage: ""
    }
  },
  methods: {
    async deleteAbsence(absence) {
      this.errorDeleting = false;
      this.successDeleting = false;
      try {
        await remove(`/api/workers/${this.workerId}/absences`, absence);
        this.successDeleting = true;
        this.$emit("absenceDeleted");
      } catch(exception) {
        this.errorDeleting = true;
        const errors = processResponseException(exception.response.data);
        this.errorMessage = errors.map((error) => error.message).join();
      }
    },
  },
}
</script>
