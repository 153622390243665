<template>
  <b-modal
    id="change-appointment-parameters-modal"
    :title="title"
    no-close-on-backdrop
    title-tag="h4"
    size="s80p"
    @shown="getAppointmentParameters"
    @hide="clearErrors"
  >
    <loading-mask :loading="loading">
      <ErrorMessage
        :errors="errors"
        class="m-t-20"
      />
      <div class="row">
        <b-form-group
          :label="label('formOfCare')"
          class="col-md-6"
        >
          <single-value-checkbox-group
            :id="`${ref}_form_of_care`"
            v-model="formOfCare"
            :options="optionsFormOfCare"
            stacked
            class="text-wrap"
          />
        </b-form-group>
        <div class="col-md-6">
          <b-form-group
            :label="label('location')"
          >
            <single-value-checkbox-group
              :id="`${ref}_location`"
              v-model="location"
              :options="optionsLocation"
              stacked
              class="text-wrap"
            />
          </b-form-group>
          <b-form-group
            :label="label('contact')"
          >
            <single-value-checkbox-group
              :id="`${ref}_contact`"
              v-model="contact"
              :options="optionsContact"
              stacked
              class="text-wrap"
            />
          </b-form-group>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <b-form-group
            :label="label('mode')"
          >
            <single-value-checkbox-group
              :id="`${ref}_mode`"
              v-model="mode"
              :options="optionsMode"
              stacked
              class="text-wrap"
            />
          </b-form-group>
          <b-form-group label="ODA">
            <b-form-checkbox-group
              :id="`${ref}_oda`"
              v-model="oda"
              :options="optionsOda"
              class="text-wrap"
            />
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group
            :label="label('ipzStage')"
          >
            <single-value-checkbox-group
              :id="`${ref}_ipz_stage`"
              v-model="ipzStage"
              :options="optionsIpzStage"
              stacked
              class="text-wrap"
            />
          </b-form-group>
          <b-form-group label="Parametry dodatkowe">
            <b-form-checkbox
              :id="`${ref}_active_care_CZP`"
              v-model="activeCareCZP"
              class="text-wrap"
            >
              {{ optionActiveCareCZP }}
            </b-form-checkbox>
          </b-form-group>
        </div>
      </div>
    </loading-mask>
    <template #modal-footer>
      <button
        type="button"
        class="btn btn-secondary"
        @click="closeModal"
      >
        Zamknij
      </button>
      <button
        :disabled="loading"
        type="button"
        class="btn btn-primary"
        @click="save"
      >
        <span
          :class="loading ? 'fa-spin fa-spinner' : 'fa-check'"
          class="fas"
        />
        Zapisz
      </button>
    </template>
  </b-modal>
</template>
<script>
import t from "../../../i18n";
import LoadingMask from "../../Loading/LoadingMask";
import read from "../../../rest/read";
import update from "../../../rest/update";
import ErrorMessage from "../../Form/ErrorMessage";
import processResponseException
  from "../../../utils/errors/processResponseException";
import SingleValueCheckboxGroup from "../../Form/Checkbox/SingleValueCheckboxGroup";

export default {
  name: "ChangeAppointmentParameters",
  components: {SingleValueCheckboxGroup, LoadingMask, ErrorMessage},
  props: {
    appointmentId: {required: true, type: String},
    title: {required: true, type: String},
  },
  data() {
    return {
      formOfCare: null,
      location: null,
      ipzStage: null,
      contact: null,
      mode: null,
      oda: null,
      activeCareCZP: null,

      loading: false,
      errors: [],
    };
  },
  computed: {
    ref() {
      return "changeAppointmentParameters";
    },
    optionsFormOfCare() {
      return ["uzamb", "uzsrd", "uzodd", "uznmk", "uzhst", "uzdor", "usamb", "ussrd", "ussds", "usksp", "uscow",
        "usmch"]
        .map((value) => ({
          value,
          text: t(`@appointment-params-formOfCare.${value}`),
        }))
    },
    optionsIpzStage() {
      return ["ipz_before", "ipz_create", "ipz_supervision", "ipz_close"].map((value) => ({
        value,
        text: t(`@appointment-params-ipzStage.${value}`),
      }));
    },
    optionsLocation() {
      return ["inhouse", "health_facility", "social_welfare_facility", "education_facility", "other_facility"]
        .map((value) => ({
          value,
          text: t(`@appointment-params-location.${value}`),
        }))
    },
    optionsContact() {
      return ["personally", "remotely", "written"].map((value) => ({
        value,
        text: t(`@appointment-params-contact.${value}`),
      }))
    },
    optionsMode() {
      return ["crisis", "urgent", "ordinary"].map((value) => ({
        value,
        text: t(`@appointment-params-mode.${value}`),
      }))
    },
    optionsOda() {
      return ["oda"].map((value) => ({
        value,
        text: t(`@appointment-params-oda.${value}`),
      }))
    },
    optionActiveCareCZP() {
      return t("@appointment-params-additional-parameters.active_care_CZP");
    }
  },
  methods: {
    async getAppointmentParameters() {
      this.loading = true;
      const data = await read(`/api/appointments/${this.appointmentId}/parameters`);
      this.formOfCare = data.formOfCare;
      this.location = data.location;
      this.ipzStage = data.ipzStage;
      this.contact = data.contact;
      this.mode = data.mode;
      this.oda = data.oda === true ? "oda" : data.oda;
      this.activeCareCZP = data.activeCareCZP;

      this.loading = false;
    },
    closeModal() {
      this.$bvModal.hide("change-appointment-parameters-modal");
    },
    clearErrors() {
      this.errors = [];
    },
    async save() {
      this.loading = true;
      try {
        await update(`/api/appointments/${this.appointmentId}/parameters`, {
          appointmentParameters: {
            formOfCare: this.formOfCare,
            location: this.location,
            ipzStage: this.ipzStage,
            contact: this.contact,
            mode: this.mode,
            oda: this.oda === "oda" ? true : this.oda,
            activeCareCZP: this.activeCareCZP
          }
        });
        this.closeModal();
        this.$emit("appointmentUpdated");
      } catch (e) {
        this.errors = processResponseException(e);
      }
      this.loading = false;
    },
    label(parameter) {
      return t(`@appointment-params.${parameter}`);
    },
  },
}
</script>
<style>
.modal-dialog.modal-s80p {
  max-width: 80vw;
}
</style>
