<template>
  <b-form-group
    id="meetingTypeSelect"
    :description="description"
    label-for="meetingTypeSelectInput"
  >
    <template #label>
      <span class="col-form-label">
        Typ wizyty
      </span>
    </template>
    <single-value-checkbox-group
      id="meetingTypeSelectInput"
      :value="value"
      :options="options"
      buttons
      @input="input"
    />
  </b-form-group>
</template>

<script>
import SingleValueCheckboxGroup from "./SingleValueCheckboxGroup";

export default {
  name: "MeetingTypeCheckbox",
  components: {SingleValueCheckboxGroup},
  props: {
    value: {type: Boolean, default: null},
  },
  computed: {
    options() {
      return [
        {
          html: "<i class=\"fa fa-hospital\" title=\"Lokalna\"></i>",
          value: false,
          label: "Lokalna",
        },
        {
          html: "<i class=\"fa fa-car\" title=\"Zdalna\"></i>",
          value: true,
          label: "Wyjazd",
        },
      ];
    },
    description() {
      const option = this.options.find((option) => option.value === this.value);
      return option ? option.label : null;
    },
  },
  methods: {
    input(option) {
      this.$emit("input", option);
    },
  },
}
</script>
