import {Icd10CodeOption} from "./IcdCodes";

export interface Address {
  country: string;
  zipCode: string;
  city: string;
  street: string;
}

export interface Patient {
  patientId: string;
  displayName: string;
  fileNumber: string;
  pesel: string;
  birthPlace: string|null;
  name: string;
  surname: string;
}

export interface Referral {
  createdAt: string;
  doctor: Doctor;
  type: ReferralType | "consultation";
  unitName: string;
  unitDepartment: string|null;
  referralId: string;
  appointmentDocumentId: string;
  isElectronic: boolean;
  status: ReferralStatus;
}

interface Doctor {
  name: string;
  surname: string;
  pwz: string;
}

export enum ReferralType {
  HOSPITAL = "hospital",
  CLINIC = "clinic",
  DIAGNOSTICS = "diagnostics",
}

export enum ReferralStatus {
  CREATED = "created",
  COMPLETED = "completed",
  REJECTED = "rejected",
  CANCELED = "canceled",
}

export interface Recognition {
  code?: null|Icd10CodeOption;
  comment?: string;
}

export interface RecognitionSubmit {
  code?: string;
  comment: string;
}
