import {Module} from "vuex";
import {RegistrationFiltersState} from "./registrationFilters/types";
import {actions} from "./registrationFilters/actions";
import {getters} from "./registrationFilters/getters";
import {mutations} from "./registrationFilters/mutations";
import {state} from "./registrationFilters/state";
import {RootState} from "../types";

const registrationFilters: Module<RegistrationFiltersState, RootState> =  {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
}

export default registrationFilters;
