<template>
  <b-modal
    id="edit-appointment-patients-modal"
    :title="title"
    no-close-on-backdrop
    title-tag="h4"
    size="lg"
    @shown="getAppointmentPatients"
    @hide="onCloseModal"
  >
    <loading-mask :loading="loading">
      <error-message
        :errors="errors"
        class="m-t-20"
      />
      <div class="mb-2">
        <patient-select
          v-model="selectedPatient"
          :clear-on-click="true"
          @open="clearActionMessage"
        />
        <transition name="fade">
          <div
            v-if="actionMessage"
            :class="actionMessage.class"
            class="mt-2"
          >
            {{ actionMessage.text }}
          </div>
        </transition>
      </div>
      <div class="card">
        <b-table
          :fields="fields"
          :items="patientList"
          :show-empty="true"
          hover
          empty-text="Brak pacjentów"
          :responsive="true"
        >
          <template #cell(deleteRow)="row">
            <b-button
              v-if="patientList.length > 1"
              size="sm"
              @click="remove(row.item)"
            >
              <i class="fa fa-trash" />
            </b-button>
          </template>
        </b-table>
      </div>
    </loading-mask>
    <template #modal-footer>
      <button
        type="button"
        class="btn btn-secondary"
        @click="closeModal"
      >
        Zamknij
      </button>
    </template>
  </b-modal>
</template>

<script>
import patientSelect from "../../Patient/PatientSelect";
import LoadingMask from "../../Loading/LoadingMask";
import read from "../../../rest/read";
import create from "../../../rest/create";
import ErrorMessage from "../../Form/ErrorMessage";
import processResponseException from "../../../utils/errors/processResponseException";
import remove from "../../../rest/remove";


export default {
  components: {LoadingMask, patientSelect, ErrorMessage},
  props: {
    id: {type: String, default: null},
    appointmentId: {required: true, type: String},
    title: {required: true, type: String}
  },
  data() {
    return {
      selectedPatient: null,
      patientList: [],
      loading: false,
      errors: [],
      actionMessage: null,
    };
  },
  computed: {
    fields() {
      return [
        {key: "displayName", label: "Pacjent"},
        {key: "deleteRow", label: "Usuń"}
      ];
    }
  },
  watch: {
    selectedPatient(patient) {
      if (null == patient) {
        return;
      }
      setTimeout(() => this.onClickAdd(), 0);
    }
  },
  methods: {
    async getAppointmentPatients() {
      this.loading = true;
      try {
        const data = await read("/api/patients", {appointmentId: this.appointmentId, perPage: 100});
        this.patientList = data.items;
      } catch (e) {
        this.errors = processResponseException(e);
      }
      this.loading = false;
    },
    closeModal() {
      this.$bvModal.hide("edit-appointment-patients-modal");
    },
    onCloseModal() {
      this.$emit("updateAppointmentPatients");
      this.errors = [];
      this.clearActionMessage();
    },
    async remove(patient) {
      this.errors = [];
      const list = [...this.patientList];
      this.patientList = list.filter((item) => item.patientId !== patient.patientId);
      try {
        const data = {patients: [patient.patientId]};
        await remove(`/api/appointment/${this.appointmentId}/patients`, data);
        this.actionMessage = {
          class: "text-success",
          text: `Pacjent ${patient.displayName} został wypisany z wizyty`,
        };
      } catch (e) {
        this.patientList = list;
        this.errors = processResponseException(e.response.data);
      }
    },
    async onClickAdd() {
      this.errors = [];
      const selectedPatient = this.selectedPatient;
      this.selectedPatient = null;

      if (!selectedPatient) {
        return;
      }

      const duplicated = null != this.patientList
        .find((patient) => selectedPatient.patientId === patient.patientId);

      if (duplicated) {
        this.actionMessage = {
          class: "text-danger",
          text: `Pacjent ${selectedPatient.displayName} znajduje się już na liście`,
        };
        return;
      }

      const list = [...this.patientList];
      try {
        const patientIds = [selectedPatient.patientId];
        await create(`/api/appointment/${this.appointmentId}/patients`, {patientIds});
        this.patientList = list.concat([selectedPatient]);
        this.actionMessage = {
          class: "text-success",
          text: `Pacjent ${selectedPatient.displayName} został dodany do wizyty`,
        };
      } catch (exception) {
        this.errors = processResponseException(exception);
      }
    },
    clearActionMessage() {
      this.actionMessage = null;
    },
  },
};
</script>
