<template>
  <div :class="{'collapse-visible':collapseVisible}">
    <slot
      v-for="item in staticItems"
      :item="item"
    />
    <template v-if="displayCollapse">
      <b-collapse
        :id="collapseId"
        :visible="collapseVisible"
      >
        <slot
          v-for="item in collapsableItems"
          :item="item"
          :open="collapseVisible"
        />
      </b-collapse>
      <a
        href="#"
        class="small"
        @click.prevent.stop="toggleCollapse"
      >
        <template v-if="collapseVisible">
          <slot name="show-less">
            <i class="fa fa-caret-up" />
            Zwiń
          </slot>
        </template>
        <template v-else>
          <slot name="show-more">
            <i class="fa fa-caret-down" />
            Rozwiń
          </slot>
        </template>
      </a>
    </template>
  </div>
</template>

<script>
import generateUniqueElementId from "../../utils/generateUniqueElementId";

export default {
  name: "CollapseList",
  props: {
    items: {type: Array, required: true},
    size: {type: Number, default: 1},
    collapsed: {type: Boolean, default: true},
  },
  data() {
    return {
      collapseId: generateUniqueElementId(),
      collapseVisible: !this.collapsed,
    };
  },
  computed: {
    staticItems() {
      return this.items.slice(0, this.size);
    },
    collapsableItems() {
      return this.items.slice(this.size);
    },
    displayCollapse() {
      return this.items.length > this.size;
    }
  },
  methods: {
    toggleCollapse() {
      this.collapseVisible = !this.collapseVisible
    },
  },
}
</script>
