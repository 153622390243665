import {GetterTree} from "vuex";
import {CurrentUserState} from "./types"
import {RootState} from "../../types";

export const getters: GetterTree<CurrentUserState, RootState> = {
  referringEstablishment(state) {
    return {
      branchId: state.user.establishmentId,
      name: state.user.establishmentName,
    };
  }
};
