





























import {Component, Prop, Ref} from "vue-property-decorator";
import CreateSocialActivityModal from "../../components/SocialActivity/CreateSocialActivityModal.vue";
import IsGranted from "../../components/IsGranted.vue";
import Vue from "vue";
import SocialActivityList from "../../components/SocialActivity/SocialActivityList.vue";
import Card from "../../components/Card.vue";

@Component({
  name: "SocialActivityPage",
  components: {Card, SocialActivityList, IsGranted, CreateSocialActivityModal}
})
export default class SocialActivityPage extends Vue {
  @Prop({type: String, required: true}) readonly patientId!: string;

  @Ref() readonly socialActivityList!: SocialActivityList;

  async loadData(): Promise<void> {
    await this.socialActivityList.loadData();
  }
}
