<template>
  <form @submit.prevent="submit">
    <div class="row">
      <div class="col-lg-6 col-xl-3">
        <b-form-group label="Pacjent">
          <patient-select
            v-model="formData.patient"
          />
        </b-form-group>
      </div>
      <div class="col-lg-6 col-xl-3">
        <b-form-group label="Status">
          <patient-data-unification-status-select
            v-model="formData.statuses"
          />
        </b-form-group>
      </div>
      <div class="col-lg-6 col-xl-3">
        <b-form-group label="Case Manager">
          <worker-select
            v-model="formData.caseManagers"
            multiple
            placeholder="Wybierz Case Manager"
          />
        </b-form-group>
      </div>
      <div class="col-lg-6 col-xl-3">
        <b-form-group label="Specjalista">
          <worker-select
            v-model="formData.workers"
            multiple
          />
        </b-form-group>
      </div>
      <div class="col text-right no-label">
        <button
          class="btn btn-primary ml-2"
          type="submit"
        >
          <i class="fa fa-search" />
          Szukaj
        </button>
      </div>
    </div>
  </form>
</template>
<script>

import PatientDataUnificationStatusSelect from "./PatientDataUnificationStatusSelect.vue"
import WorkerSelect from "../../Worker/WorkerSelect";
import PatientSelect from "../PatientSelect";
import {changePageQuery} from "../../../utils/pageUrl/handlePageQuery";
import isEqual from "lodash/isEqual";

export default {
  name: "PatientDataUnificationFilters",
  components: {PatientSelect, WorkerSelect, PatientDataUnificationStatusSelect},
  props: {
    patient: {type: String, default: null},
    statuses: {type: Array, default: ()=>[]},
    caseManagers: {type: Array, default: ()=>[]},
    workers: {type: Array, default: ()=>[]},
  },
  data() {
    return {
      formData: {
        patient: this.patient,
        statuses: this.statuses,
        caseManagers: this.caseManagers,
        workers: this.workers,
      },
    }
  },
  computed: {
    formDataWorkerIds() {
      if ("object" === typeof this.formData.workers[0]) {
        return this.formData.workers.map(option => option.value);
      }
      return this.formData.workers;
    },
    formDataCaseManagerIds() {
      if ("object" === typeof this.formData.caseManagers[0]) {
        return this.formData.caseManagers.map(option => option.value);
      }
      return this.formData.caseManagers;
    },
    query() {
      return {
        patient: (this.formData.patient && this.formData.patient.patientId) || this.formData.patient || undefined,
        statuses: this.formData.statuses.join(",") || undefined,
        workers: this.formDataWorkerIds.join(",") || undefined,
        caseManagers: this.formDataCaseManagerIds.join(",") || undefined,
      };
    },
  },
  watch: {
    patient(patient){
      this.formData = {...this.formData, patient};
    },
    statuses(statuses){
      this.formData = {...this.formData, statuses};
    },
    caseManagers(caseManagers){
      this.formData = {...this.formData, caseManagers};
    },
    workers(workers){
      this.formData = {...this.formData, workers};
    },
  },
  methods: {
    submit() {
      if (isEqual(this.$route.query, this.query)) {
        this.$emit("reload");
        return;
      }
      changePageQuery(this.query);
    },
  },
}
</script>
