<template>
  <div>
    <b-btn
      v-b-modal.updateIndicators
      :disabled="isLoading"
      variant="primary"
      class="btn-sm"
    >
      <i class="fa fa-pencil-alt" />
    </b-btn>
    <b-modal
      id="updateIndicators"
      title="Edycja znaczników"
      title-tag="h3"
      size="lg"
    >
      <div class="card-box text-left">
        <b-form-checkbox-group
          :checked="patientIndicatorsCheckedList"
          stacked
        >
          <div
            v-for="(indicator, key) in sortedIndicators"
            :key="key"
            class="m-b-30"
          >
            <b-form-checkbox
              :value="indicator"
              :disabled="isLoading"
              @change="changeIndicatorStatus(indicator)"
            >
              {{ indicator.title }}
            </b-form-checkbox>
            {{ indicator.description }}
            <error-message
              :errors="errors"
              :field="indicator.patientIndicatorTypeId.type"
            />
          </div>
        </b-form-checkbox-group>
        <error-message :errors="errors" />
      </div>
      <template #modal-footer>
        <button
          :disabled="isLoading"
          class="btn btn-secondary"
          @click="hideModal"
        >
          <i
            v-if="isLoading"
            class="fa fa-spin fa-spinner"
          />
          Powrót
        </button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import remove from "../../../../rest/remove";
import {generateUuid} from "../../../../utils/uuid/generateUuid";
import create from "../../../../rest/create";
import ErrorMessage from "../../../Form/ErrorMessage";
import processResponseException from "../../../../utils/errors/processResponseException";

export default {
  components: {ErrorMessage},
  props: {
    patientId: {type: String, required: true},
    indicatorTypesList: {type: Array, required: true},
    patientIndicatorsList: {type: Array, required: true},
    isParentLoading: {type: Boolean, required: true},
  },
  data() {
    return {
      isLoading: this.isParentLoading,
      errors: [],
    };
  },
  computed: {
    patientIndicatorsCheckedList() {
      return this.patientIndicatorsList.map((item) => {
        return {
          description: item.description,
          patientIndicatorTypeId: {type: item.type},
          title: item.title,
        }
      });
    },
    sortedIndicators() {
      return [...this.indicatorTypesList]
        .sort((indicatorA, indicatorB) => indicatorA.title.localeCompare(indicatorB.title));
    }
  },
  watch: {
    isParentLoading() {
      this.isLoading = this.isParentLoading;
    }
  },
  methods: {
    async changeIndicatorStatus(indicatorType) {
      this.isLoading = true;
      const patientIndicator = this.patientIndicatorsList.find(function(item) {
        return indicatorType.patientIndicatorTypeId.type === item.type;
      });

      if(patientIndicator !== undefined) {
        try {
          await remove(`/api/patient/${this.patientId}/indicators/${patientIndicator.patientIndicatorId}`);
        } catch (exception) {
          this.errors = processResponseException(exception);
        }
      } else {
        const patientIndicatorId = generateUuid();
        try {
          await create(`/api/patient/${this.patientId}/indicators`, {
            patientIndicatorId: patientIndicatorId,
            patientId: this.patientId,
            patientIndicatorType: indicatorType.patientIndicatorTypeId.type,
          });
        } catch (exception) {
          this.errors = processResponseException(exception);
        }
      }

      this.$emit("indicatorsUpdated");
      this.isLoading = false;
    },
    hideModal() {
      this.$bvModal.hide("updateIndicators");
    },
  },
}
</script>
