

























































import {Component, Prop} from "vue-property-decorator";
import Vue from "vue";
import type {ChildIpzItem} from "../../../types/ChildIpzs";
import Card from "../../Card.vue";
import I18n from "../../i18n.vue";
import DATE_FORMAT from "../../../utils/date/DATE_FORMAT";
import parseDate from "../../../utils/date/parseDate";
import DateTime from "../../DateTime.vue";
import ChildIpzDetailsModal from "./ChildIpzDetailsModal.vue";
import ChildIpzStatusBadge from "./ChildIpzStatusBadge.vue";

@Component({
  name: "ChildIpzList",
  components: {
    ChildIpzStatusBadge,
    ChildIpzDetailsModal,
    DateTime,
    I18n,
    Card,
  },
})

export default class ChildIpzList extends Vue {
  @Prop({type: String, required: true}) readonly ipzId!: string;
  @Prop({type: Array, required: true}) readonly items!: Array<ChildIpzItem>;

  parseDate = parseDate;
  DATE_FORMAT = DATE_FORMAT;
  selectedItem: ChildIpzItem|null = null;

  get fields() {
    return [
      {key: "createdAt", label: "Data utworzenia"},
      {key: "authorName", label: "Autor"},
      {key: "openedAt", label: "Data otwarcia"},
      {key: "openedBy", label: "Otworzył"},
      {key: "closedAt", label: "Data zamknięcia"},
      {key: "closedBy", label: "Zamknął"},
      {key: "status", label: "Status"},
      {key: "options", label: "", thStyle: "width: 150px"},
    ]
  }

  showDetails(item: ChildIpzItem): void {
    this.selectedItem = item;
    this.$bvModal.show("child-ipz-details-modal");
  }
}
