<template>
  <div>
    <transition name="fade">
      <div
        v-if="errorDeleting"
        class="alert alert-danger alert-dismissible"
      >
        <button
          type="button"
          class="close"
          @click="errorDeleting = false"
        >
          <span>&times;</span>
        </button>
        {{ errorMessage }}
      </div>
    </transition>
    <div class="table-responsive">
      <transition-group
        class="table"
        name="fade"
        tag="table"
      >
        <template v-for="(range, index) in allRanges">
          <recurring-range
            v-if="range.type === 'recurringRange'"
            :key="index"
            :recurring-range="range"
            :edit-mode="editMode"
            @deleteRange="deleteRange"
          />
          <range
            v-if="range.type === 'range'"
            :key="index"
            :range="range"
            :edit-mode="editMode"
            @deleteRange="deleteRange"
          />
        </template>
      </transition-group>
    </div>
  </div>
</template>

<script>
import Range from "./Range.vue";
import RecurringRange from "./RecurringRange.vue";
import processResponseException from "../../../utils/errors/processResponseException";
import remove from "@/rest/remove";

export default {
  name: "WorkerWorkingHoursList",
  components: {
    Range,
    RecurringRange,
  },
  props: {
    workerId: {type: String, required: true},
    recurringRangesList: {type: Array, required: true},
    rangesList: {type: Array, required: true},
    editMode: {type: Boolean, required: false, default: true}
  },
  data() {
    return {
      errorDeleting: false,
      errorMessage: "",
    };
  },
  computed: {
    rangesWithType() {
      return this.rangesList.map(range => ({...range, type: "range"}));
    },
    recurringRangesWithType() {
      return this.recurringRangesList.map(range => ({...range, type: "recurringRange"}));
    },
    allRanges() {
      return this.recurringRangesWithType.concat(this.rangesWithType).sort((a,b) => {
        const dateA = new Date(a.rangeStart);
        const dateB = new Date(b.rangeStart);

        if (dateA < dateB) {
          return 1;
        }
        if (dateA > dateB) {
          return -1;
        }

        return 0;
      });
    }
  },
  methods: {
    async deleteRange(range) {
      try {
        await remove(`/api/workers/${this.workerId}/working-hours`, range)
        this.errorDeleting = false;
        this.$emit("rangeDeleted");

      } catch (exception) {
        this.errorDeleting = true;
        const errors = processResponseException(exception);
        this.errorMessage = errors.map(error => error.message).join();
      }
    },
  },
}
</script>
