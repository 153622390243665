
































































































































































import Vue from "vue";
import MedicationType from "./MedicationType.vue";
import MedicationQuantity from "./MedicationQuantity.vue";
import MedicationRepayment from "./MedicationRepayment.vue";
import MedicationDosage from "./MedicationDosage.vue";
import type {ActiveSubstance, Dosage, Drug, PrescriptionDrug,} from "../../types/Prescription";
import {AvailabilityCategory,} from "../../types/Prescription";
import Component from "vue-class-component";
import {Prop} from "vue-property-decorator";
import ActiveSubstanceDose from "./ActiveSubstanceDose.vue";
import MedicationDescription from "./MedicationDescription.vue";
import {Error} from "../../rest";
import ErrorMessage from "../Form/ErrorMessage.vue";
import subErrors from "../../utils/errors/subErrors";
import MedicationAdditionalInfo from "./MedicationAdditionalInfo.vue";
import ActiveSubstanceDescription from "./ActiveSubstanceDescription.vue";

const initialValue = {
  quantity: 1,
  repayment: "100%",
  cannotReplace: false,
  ignoreWarnings: true,
  yearlyPrescription: false
}

@Component({
  name: "MedicationForm",
  components: {
    ActiveSubstanceDescription,
    MedicationAdditionalInfo,
    ErrorMessage,
    MedicationDescription,
    ActiveSubstanceDose,
    MedicationDosage,
    MedicationRepayment,
    MedicationQuantity,
    MedicationType,
  }
})
export default class MedicationForm extends Vue {
  @Prop({type: Object, default: ()=>({})}) readonly value!: PrescriptionDrug;
  @Prop({type: String, default: null}) readonly patient!: string;
  @Prop({type: Boolean, default: false}) readonly disabled!: boolean;
  @Prop({type: Array, default: ()=>[]}) readonly errors!: Array<Error>;
  @Prop({type: Array, default: ()=>[]}) readonly warnings!: Array<Error>;

  private AvailabilityCategory = AvailabilityCategory;

  private get drug(): Drug|null {
    return this.value.externalId
      ? {
        externalId: this.value.externalId,
        ean: this.value.ean,
        name: this.value.name,
        internationalName: this.value.internationalName,
        wrapper: this.value.wrapper,
        form: this.value.form,
        dose: this.value.dose,
        size: this.value.size,
        activeSubstance: this.value.activeSubstance,
        availabilityCategory: this.value.availabilityCategory,
        confirmedDoseRequired: this.value.confirmedDoseRequired,
        cannotReplace: this.value.cannotReplace,
        ignoreWarnings: this.value.ignoreWarnings,
        refunds: this.value.refunds,
        yearlyPrescription: this.value.yearlyPrescription
      }
      : null;
  }
  private get quantity(): number|undefined {
    return this.value.quantity;
  }
  private get dosage(): Dosage|null {
    return this.value.externalId
      ? {
        doseQuantity: this.value.doseQuantity,
        rateQuantity: this.value.rateQuantity,
        rateQuantityUnit: this.value.rateQuantityUnit,
      }
      : null;
  }
  private get repayment(): string|undefined {
    return this.value.repayment;
  }

  private get description(): string|undefined {
    return this.value.description || "";
  }

  private get additionalInformation(): string|undefined {
    return this.value.additionalInformation || "";
  }

  private get drugErrors() {
    return subErrors(this.errors, "drug");
  }

  updateDrug(drug: Drug|null) {
    const newVal = drug
      ? {
        ...drug,
        ...initialValue
      }
      : {};
    this.$emit("input", newVal);
  }

  private update($event: number|string|boolean, field: string): void {
    this.$emit("input", {
      ...this.value,
      [field]: $event,
    })
  }

  private updateDosage(dosage: Dosage|null) {
    this.$emit("input", {
      ...this.value,
      ...dosage,
    })
  }

  private updateSubstance($event: ActiveSubstance, index: number): void {
    this.$emit("input", {
      ...this.value,
      activeSubstance: this.value.activeSubstance
        && this.value.activeSubstance.map((substance, idx) => (idx === index) ? $event : substance)
    });
  }

  private updateSubstanceDescription($event: string, index: number): void {
    this.$emit("input", {
      ...this.value,
      activeSubstance: this.value.activeSubstance
        && this.value.activeSubstance.map((substance, idx) => (idx === index) ?
          {...substance, confirmedDoseDescription: $event} : substance)
    })
  }

  private getActiveSubstanceErrors(index: number): Array<Error> {
    return subErrors(this.drugErrors, `activeSubstance[${index}]`);
  }
}
