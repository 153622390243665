export interface ContinuousStay {
  continuousStayId: string;
  patientId: string;
  workerFullName: string;
  continuousStayCategory: string;
  startDate: string; //YYYY-MM-DD HH:mm:ss
  endDate: string; //YYYY-MM-DD HH:mm:ss
  continuousStayStatus: ContinuousStayStatus;
  patientFullName: string;
  cancelReason: string | null;
  cancelReasonCategory?: string | null;
}

export enum ContinuousStayStatus {
  IN_PROGRESS = "in_progress",
  CLOSED = "closed",
  CANCELED = "canceled",
}
