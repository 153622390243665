import {Module} from "vuex";
import {getters} from "./currentUser/getters";
import {actions} from "./currentUser/actions";
import {mutations} from "./currentUser/mutations";
import {CurrentUserState} from "./currentUser/types";
import {initialState} from "./currentUser/initialState"
import {RootState} from "../types";

const namespaced = true;

const currentUser: Module<CurrentUserState, RootState> = {
  namespaced,
  state: initialState,
  getters,
  actions,
  mutations
};

export default currentUser;
