<template>
  <form 
    v-if="active" 
    class="app-search"
  >
    <input 
      v-model="name" 
      type="email" 
      class="form-control" 
      name="_switch_user"
    >
    <tooltip
      :to="url"
      component="router-link"
      tooltip-content="Przełącz konto"
    >
      <i class="fa fa-user-shield" />
    </tooltip>
  </form>
</template>
<script>
import Tooltip from "./Tooltip";

export default {
  components: {Tooltip},
  data() {
    return {
      name: "",
    };
  },
  computed: {
    url() {
      return `?_switch_user=${encodeURIComponent(this.name)}`;
    },
    active() {
      return "production" !== process.env.NODE_ENV;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../styles/variables";


.app-search {
  position: relative;
  padding-top: 18px;

  a {
    position: absolute;
    top: 18px;
    left: 160px;
    display: block;
    height: 34px;
    line-height: 34px;
    width: 34px;
    text-align: center;
    color: rgba($white, 0.5);

    &:hover {
      color: $white;
    }
  }

  .form-control,
  .form-control:focus {
    border: 1px solid rgba($white, 0.1);
    font-size: 13px;
    height: 34px;
    color: $white;
    padding-left: 20px;
    padding-right: 40px;
    background: rgba($white, 0.1);
    box-shadow: none;
    border-radius: 30px;
    width: 200px;
  }
}

.app-search input {
  &::-webkit-input-placeholder {
    color: rgba($white, 70%);
  }
  &:-moz-placeholder {
    color: rgba($white, 70%);
  }
  &::-moz-placeholder {
    color: rgba($white, 70%);
  }
  &:-ms-input-placeholder {
    color: rgba($white, 70%);
  }
}
</style>
