<template>
  <form
    class="card-box"
    @submit.prevent="submit"
  >
    <div class="row">
      <div class="col-lg-3">
        <b-form-group label="Data początkowa">
          <date-picker
            v-model="formData.startDate"
          />
        </b-form-group>
      </div>
      <div class="col-lg-3">
        <b-form-group label="Data końcowa">
          <date-picker
            v-model="formData.endDate"
            clearable
          />
        </b-form-group>
      </div>
      <div class="col-lg-3">
        <b-form-group
          label="Klasa"
        >
          <b-form-input
            v-model.trim="formData.classNamePhrase"
            placeholder="Wpisz nazwę klasy"
          />
        </b-form-group>
      </div>
      <div class="col-lg-3">
        <b-form-group
          label="Użytkownik"
        >
          <b-form-input
            v-model.trim="formData.user"
            placeholder="Wpisz nazwę użytkownika"
          />
        </b-form-group>
      </div>
      <div class="col-lg-3">
        <b-form-group
          label="Dowolny tekst"
        >
          <b-form-input
            v-model.trim="formData.text"
            placeholder="Wpisz fragment tekstu"
          />
        </b-form-group>
      </div>
      <div class="col text-right no-label">
        <button
          class="btn btn-primary ml-2"
          type="submit"
        >
          <i class="fa fa-search" />
          Szukaj
        </button>
      </div>
    </div>
  </form>
</template>

<script>

import DatePicker from "../Form/DatePicker/DatePicker.vue";
import stringifyDate from "../../utils/date/stringifyDate";
import {changePageQuery} from "../../utils/pageUrl/handlePageQuery";
import isEqual from "lodash/isEqual";

export default {
  components: {
    DatePicker
  },
  props: {
    startDate: {type: Date, default: null},
    endDate: {type: Date, default: null},
    text: {type: String, default: null},
    user: {type: String, default: null},
    classNamePhrase: {type: String, default: null}
  },
  data() {
    return {
      formData: {
        startDate: this.startDate,
        endDate: this.endDate,
        text: this.text,
        user: this.user,
        classNamePhrase: this.classNamePhrase,
      }
    }
  },
  computed: {
    query() {
      return {
        startDate: this.formData.startDate ? stringifyDate(this.formData.startDate) : undefined,
        endDate: this.formData.endDate ? stringifyDate(this.formData.endDate) : undefined,
        text: this.formData.text || undefined,
        user: this.formData.user || undefined,
        classNamePhrase: this.formData.classNamePhrase || undefined,
      };
    },
  },
  watch: {
    startDate(val) {
      this.formData.startDate = val;
    },
    endDate(val) {
      this.formData.endDate = val;
    },
    text(val) {
      this.formData.text = val;
    },
    user(val) {
      this.formData.user = val;
    },
    classNamePhrase(val) {
      this.formData.classNamePhrase = val;
    },
  },
  methods: {
    submit() {
      if (isEqual(this.$route.query, this.query)) {
        this.$emit("reload");
        return;
      }
      changePageQuery(this.query);
    },
  },
}
</script>
