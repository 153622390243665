<template>
  <div class="card-box m-b-5">
    <form
      class="form-row"
      @submit.prevent="submit"
    >
      <b-form-group
        label-for="requestStatus"
        class="col-lg-3"
        label="Status"
      >
        <request-status-select
          id="requestStatus"
          v-model="formData.status"
          multiple
        />
      </b-form-group>
      <b-form-group
        label-for="creator"
        class="col-lg-3"
        label="Twórca"
      >
        <worker-select
          id="creator"
          v-model="formData.creator"
          multiple
        />
      </b-form-group>
      <b-form-group
        label-for="requestType"
        class="col-lg-3"
        label="Typ wniosku"
      >
        <request-type-select
          id="requestType"
          v-model="formData.requestType"
        />
      </b-form-group>
      <div class="col-lg-2 no-label">
        <button
          type="submit"
          class="btn btn-primary"
        >
          <i class="fa fa-search" /> Szukaj
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import RequestStatusSelect from "./RequestStatusSelect";
import WorkerSelect from "../Worker/WorkerSelect";
import RequestTypeSelect from "./RequestTypeSelect";
export default {
  name: "RequestListFilters",
  components: {RequestTypeSelect, WorkerSelect, RequestStatusSelect},
  props: {
    status: {type: Array, default: () => []},
    creator: {type: Array, default: () => []},
    requestType: {type: Array, default: () => []}
  },
  data() {
    return {
      formData: {
        status: this.status,
        creator: this.creator,
        requestType: this.requestType.length > 0 ? this.requestType : ["CancelAppointmentCommand"]
      }
    }
  },
  computed: {
    formDataWorkerIds() {
      if ("object" === typeof this.formData.creator[0]) {
        return this.formData.creator.map(option => option.value);
      }
      return this.formData.creator;
    },
  },
  methods: {
    submit(){
      this.$emit("input", {
        ...this.formData,
        creator: this.formDataWorkerIds,
      });
    }
  }
}
</script>

<style scoped>

</style>
