<template>
  <object-select
    :value="country"
    :options="options"
    :state="state"
    :disabled="disabled"
    placeholder="Wybierz Kraj"
    @input="update"
  />
</template>

<script>
import ObjectSelect from "../Form/Select/ObjectSelect";
import countries from "../../countries.json";

export default {
  components: {
    ObjectSelect,
  },
  props: {
    value: {type: String, default: null},
    state: ObjectSelect.props.state, // eslint-disable-line
    disabled: {type: Boolean, default: false},
  },
  data() {
    return {
      country: null,
      options: countries.map(item => ({
        ...item,
        value: item.code,
      })),
    };
  },
  watch: {
    value(value) {
      this.country = this.options
        .find((country) => country.code === value);
    },
  },
  async mounted() {
    if (null == this.value) {
      this.$emit("input", "PL");
    }
    this.country = this.options
      .find((country) => country.code === this.value);
  },
  methods: {
    update(country) {
      this.country = country;
      this.$emit("input", country ? country.code : null);
    },
  },
};
</script>
