import {Module} from "vuex";
import {AppointmentListFiltersState} from "./appointmentListFilters/types";
import {actions} from "./appointmentListFilters/actions";
import {getters} from "./appointmentListFilters/getters";
import {mutations} from "./appointmentListFilters/mutations";
import {state} from "./appointmentListFilters/state";
import {RootState} from "../types";

const appointmentListFilters: Module<AppointmentListFiltersState, RootState> =  {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
}

export default appointmentListFilters;
