





















































import ErrorMessage from "../Form/ErrorMessage.vue";
import ErrorsMixin from "../../mixins/ErrorsMixin";
import {Component, Mixins, Prop} from "vue-property-decorator";
import processResponseException from "../../utils/errors/processResponseException";
import ResizableTextarea from "../Form/Textarea/ResizableTextarea.vue";
import modify from "../../rest/modify";
import {RequestCommand} from "../../types/Request";
import AppointmentInfoRequestPreview from "../Appointment/AppointmentInfoRequestPreview.vue";

@Component({
  name: "CancelRequestModal",
  components: {ResizableTextarea, ErrorMessage, AppointmentInfoRequestPreview,}
})
export default class CancelRequestModal extends Mixins(ErrorsMixin) {
  @Prop({type: String, required: true}) readonly changeRequestId!: string;
  @Prop({type: String, required: true}) readonly command!: RequestCommand;
  @Prop({type: String, required: true}) readonly subjectId!: string;

  private loading: boolean = false;
  private note: string = "";

  private get modalId(): string {
    return `cancel-request-${this.changeRequestId}`;
  }

  private get title(): string {
    switch(this.command) {
      case RequestCommand.CANCEL_APPOINTMENT_COMMAND:
        return "Odrzucenie wniosku o anulowanie wizyty";
      default:
        throw Error(`Not supported command: '${this.command}'`);
    }
  }

  private get commandComponent(): string {
    switch(this.command) {
      case RequestCommand.CANCEL_APPOINTMENT_COMMAND:
        return "appointment-info-request-preview";
      default:
        throw Error(`Not supported command: '${this.command}'`);
    }
  }

  private async submit(): Promise<void> {
    this.loading = true;
    this.errors = [];
    try {
      await modify(`/api/change-requests/${this.changeRequestId}/cancel`, {
        note: this.note,
      });
      this.$emit("canceled");
      this.hideModal();
    } catch (e) {
      this.errors = processResponseException(e);
    }
    this.loading = false;
  }

  private hideModal(): void {
    this.$bvModal.hide(this.modalId);
  }

  private onHide(): void {
    this.errors = [];
    this.loading = false;
    this.note = "";
  }

  private async onShow(): Promise<void> {

  }
}
