export interface EstablishmentItem {
  branchId: string;
  parentId?: string;
  name: string;
  code?: string;
  nip: string;
  regon: string;
  phoneNumber: string;
  codeEight: string;
  children?: Array<EstablishmentItem>;
  address?: EstablishmentAddress;
  type: EstablishmentType;
  shortName?: string;
  krs?: string;
  parameters?: EstablishmentParameters;
  geolocalization?: EstablishmentGeolocalization;
}

export interface EstablishmentAddress {
  street: string;
  zipCode: string;
  city: string;
  country: string;
}

export enum EstablishmentType {
  ESTABLISHMENT = "establishment",
  NON_MEDICAL = "non_medical_establishment",
  INSTITUTE = "institute",
  DIVISION = "division",
  UNIT = "unit",
}

export interface EstablishmentParameters {
  patientAnonymous: boolean;
  ageGroupTarget: AgeGroupTargetType|null;
  partnershipSignature: string|null;
  filterResearchSurveysByAgeAndSex: boolean;
  ignoreBranchRestrictions: boolean;
}

export enum AgeGroupTargetType {
  CHILDREN = "children",
  ADULTS = "adults",
}

export interface EstablishmentGeolocalization {
  latitude: string|null;
  longitude: string|null;
}

export interface EstablishmentSmtpConfig {
  host: string|null;
  port: string|null;
  encryption: string|null;
  username: string|null;
  password: string|null;
  senderName: string|null;
  senderEmail: string|null;
}
