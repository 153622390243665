<template>
  <a
    v-if="user.settings && user.settings.sentryDns"
    :class="{'btn-fixed btn': fixed}"
    @click="popForm"
  >
    <i class="fa fa-exclamation-circle" />
    <span>Zgłoszenia</span>
  </a>
</template>

<script>
import * as Sentry from "@sentry/browser";
import {generateUuid} from "../utils/uuid/generateUuid";
import {mapState} from "vuex";

export default {
  name: "FeedbackButton",
  props: {
    fixed: {type: Boolean, default: false},
  },
  computed: {
    ...mapState("currentUser", ["user"]),
  },
  methods: {
    popForm() {
      const eventId = generateUuid().replace(/-/g, "");
      Sentry.captureEvent({
        message: "User Feedback.",
        event_id: eventId
      });
      Sentry.showReportDialog({eventId});
    },
  },
}
</script>

<style scoped lang="scss">
  @import "../styles/variables";

  .btn-fixed {
    z-index: $zindex-fixed;
    position: fixed;
    top: 30%;
    right: -43px;
    transform: rotate(-90deg);
    color: $white !important;
    background-color: $danger;

    &:hover {
      background-color: darken($danger, 5%);
      box-shadow: -1px 0px 6px 0 $lightdark7;
    }

    span {
      padding-left: 5px;
    }
  }
</style>
