<template>
  <object-select
    :value="selectedCourseType"
    :options="options"
    :state="state"
    placeholder="Wybierz rodzaj kursu"
    @input="update"
  />
</template>

<script>
import ObjectSelect from "../Form/Select/ObjectSelect";
import t from "../../i18n";
export default {
  components: {
    ObjectSelect
  },
  props: {
    courseType: {type: String, default: null},
    state: ObjectSelect.props.state, //eslint-disable-line
  },
  computed: {
    selectedCourseType () {
      return this.options.find(element => {
        return element.value === this.courseType;
      });
    },
    options() {
      return [
        {name: t("@prevention.for_specialists"), value: "for_specialists"},
        {name: t("@prevention.class_branches"), value: "class_branches"},
        {name: t("@prevention.for_parents"), value: "for_parents"},
        {name: t("@prevention.ozdkp_relatives"), value: "ozdkp_relatives"},
        {name: t("@prevention.social_campaign"), value: "social_campaign"}
      ];
    },
  },
  methods: {
    update(courseType) {
      this.$emit("input", courseType ? courseType.value : null)
    },
  },
}
</script>
