<template>
  <div>
    <report-treatment-type
      v-if="savedItem"
      :appointment-id="savedItem.appointmentId"
      title="Raportowanie typu usługi"
      @reportedTreatment="updateAppointmentList"
    />
    <change-appointment-parameters
      v-if="savedItem"
      :appointment-id="savedItem.appointmentId"
      title="Parametry wizyty"
      @appointmentUpdated="updateAppointmentList"
    />
    <change-additional-workers
      v-if="savedItem"
      :appointment-id="savedItem.appointmentId"
      :main-worker-id="savedItem.mainWorkerId"
      :patient-id="savedItem.patientId"
      title="Edycja specjalistów dodatkowych"
      @appointmentUpdated="updateAppointmentList"
    />
    <change-appointment-date
      v-if="savedItem"
      :appointment-id="savedItem.appointmentId"
      :patient-id="savedItem.patientId"
      :status="savedItem.status"
      title="Edycja daty wizyty"
      @appointmentUpdated="updateAppointmentList"
    />
    <cancel-reason-modal
      v-if="savedItem"
      v-bind="savedItem"
      title="Odwołanie wizyty"
      @appointmentCanceled="updateAppointmentList"
    />
    <cancel-reason-modal
      v-if="savedItem"
      v-bind="savedItem"
      :deferred="true"
      title="Wniosek o odwołanie wizyty"
      @appointmentCanceled="updateAppointmentList"
    />
    <change-appointment-location-type
      v-if="savedItem"
      :appointment-id="savedItem.appointmentId"
      :patient-id="savedItem.patientId"
      title="Zmiana typu wizyty"
      @appointmentUpdated="updateAppointmentList"
    />
    <finish-appointment-modal
      v-if="savedItem"
      v-model="finishAppointmentData"
      :document-errors="errors"
      :loading="loading"
      :branch-id="savedItem.branchId"
      @forceFinishAppointment="forceFinishAppointment"
    />
  </div>
</template>

<script>
import ReportTreatmentType from "../AppointmentActions/ReportTreatmentTypeModal";
import ChangeAppointmentParameters from "../AppointmentActions/ChangeAppointmentParameters";
import ChangeAdditionalWorkers from "./ChangeAdditionalWorkers";
import ChangeAppointmentDate from "./ChangeAppointmentDate";
import CancelReasonModal from "../AppointmentActions/CancelReasonModal";
import {mapState} from "vuex";
import ChangeAppointmentLocationType from "./ChangeAppointmentLocationType";
import FinishAppointmentModal from "../AppointmentActions/FinishAppointmentModal";
import create from "../../../rest/create";
import {errorsMixin} from "../../../mixins/errorsMixin.js";
import processResponseException from "../../../utils/errors/processResponseException";

export default {
  name: "AppointmentUnificationModals",
  components: {
    ChangeAppointmentLocationType,
    CancelReasonModal,
    ChangeAppointmentDate,
    ChangeAdditionalWorkers,
    ChangeAppointmentParameters,
    ReportTreatmentType,
    FinishAppointmentModal
  },
  mixins: [errorsMixin],
  data() {
    return {
      loading: false,
      finishAppointmentData: {
        timeSpent: 0,
        branchId: this.savedItem?.branchId,
      },
    };
  },
  computed: {
    ...mapState("appointmentListItem", ["savedItem"])
  },
  watch: {
    savedItem(val) {
      if (!val) {
        return;
      }
      this.finishAppointmentData.branchId = val.branchId;
    },
  },
  methods: {
    updateAppointmentList() {
      this.$emit("setAppointmentData");
      this.$emit("updateAppointmentList");
    },
    async forceFinishAppointment() {
      this.loading = true;
      this.errors = [];
      try {
        const data = {
          appointmentId: this.savedItem.appointmentId,
          ...this.finishAppointmentData,
        };
        await create("/api/force-finish-appointment", data);
        this.updateAppointmentList();
        this.$bvModal.hide("force-finish-appointment");
      } catch(e) {
        this.errors = processResponseException(e);
      }
      this.loading = false;
    },
  },
}
</script>
