let index = Number.MAX_SAFE_INTEGER;

/**
 * Do you need id for Your HTML element?
 *
 * @returns {string}
 */
export default function generateUniqueElementId() {
  const base = 36;
  const id = index.toString(base);
  index--;

  return `auxilio-element-${id}`;
}
