import {HospitalizationsData, HospitalizationsDataFields} from "../types/HospitalizationTypes";

export default function (): HospitalizationsData {
  return {
    [HospitalizationsDataFields.WAS_HOSPITALIZED]: null,
    [HospitalizationsDataFields.WHEN_FIRST_HOSPITALIZED]: {},
    [HospitalizationsDataFields.WHEN_LAST_HOSPITALIZED]: {},
    [HospitalizationsDataFields.HOW_MANY_HOSPITALIZATIONS_LAST_PERIOD]: {},
    [HospitalizationsDataFields.HOW_LONG_HOSPITALIZED_LAST_PERIOD]: {},
  };
}
