





























































import read from "../../rest/read";
import ErrorsMixin from "../../mixins/ErrorsMixin";
import {Component, Mixins} from "vue-property-decorator";
import {NoteItem} from "../../types/Notes";
import {Pagination} from "../../types/Pagination";
import remove from "../../rest/remove";
import ErrorMessage from "../Form/ErrorMessage.vue";
import PaginationDescription from "../Pagination/PaginationDescription.vue";
import type {BvTableFieldArray} from "bootstrap-vue/src/components/table";
import DateTime from "../DateTime.vue";
import DATE_FORMAT from "../../utils/date/DATE_FORMAT";
import parseDate from "../../utils/date/parseDate";
import processResponseException from "../../utils/errors/processResponseException";
import NoteModal from "./NoteModal.vue";
import Card from "../Card.vue";

interface ReadData {
  items: NoteItem[];
  pagination: Pagination;
}

@Component({
  name: "UserNotesTable",
  components: {NoteModal,
    DateTime,
    PaginationDescription,
    ErrorMessage,
    Card,
  },
})
export default class UserNotesTable extends Mixins(ErrorsMixin) {
  DATE_FORMAT = DATE_FORMAT;

  private loading: boolean = false;
  private notes: NoteItem[] = [];
  private pagination: Pagination = {
    currentPage: 1,
    perPage: 5,
    totalRows: 0,
  };

  get fields(): BvTableFieldArray {
    return [
      {key: "date", label: "Data dodania"},
      {key: "content", label: "Treść"},
      {key: "options", label: "", tdClass: "text-right"},
    ]
  }
  async mounted() {
    await this.fetchData();
  }

  private dateValue(date: string): Date|null {
    return parseDate(date, DATE_FORMAT.DEFAULT);
  }

  public async fetchData(): Promise<void> {
    this.loading = true;
    this.errors = [];
    try {
      const {items, pagination} = await read<ReadData>("/api/notes", {
        page: this.pagination.currentPage,
        perPage: this.pagination.perPage,
      });
      this.notes = items;
      this.pagination = pagination;
    } catch (e) {
      this.errors = processResponseException(e);
    }
    this.loading = false;
  }

  private async removeNote(noteId: NoteItem["noteId"]): Promise<void> {
    this.loading = true;
    this.errors = [];
    try {
      await remove(`/api/notes/${noteId}`, {});
      this.pagination.currentPage = 1;
      await this.fetchData();
    } catch (e) {
      this.errors = processResponseException(e);
    }
    this.loading = false;
  }
}
