<template>
  <b-modal
    id="procedure-description"
    no-close-on-backdrop
    title="Opis procedury"
    title-tag="h3"
    @hide="$emit('close')"
  >
    {{ description }}
    <template #modal-footer>
      <button
        class="btn btn-secondary"
        @click="close"
      >
        Zamknij
      </button>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: "ProcedureDescriptionModal",
  props: {
    description: {type: String, default: null},
  },
  methods: {
    close() {
      this.$bvModal.hide("procedure-description");
    },
  },
}
</script>
