<template>
  <b-dd
    v-if="user"
    right
    variant="primary"
  >
    <template #button-content>
      <i class="fas fa-user" />
    </template>
    <b-dd-header>
      <h5 class="text-overflow">
        Witaj {{ user.name }} {{ user.surname }}<br>
        <small>{{ user.email }}</small>
      </h5>
    </b-dd-header>
    <b-dd-item>
      <router-link
        :to="{name: 'settings'}"
        class="text-body"
      >
        <i class="fa fa-cog" />
        Profil użytkownika
      </router-link>
    </b-dd-item>
    <b-dd-item @click="logout">
      <i class="fa fa-sign-out-alt" />
      Wyloguj
    </b-dd-item>
  </b-dd>
</template>

<script>
import read from "../rest/read";
import {mapState} from "vuex";
import {mapActions} from "vuex";

export default {
  name: "UserMenu",
  computed: {
    ...mapState("currentUser", ["user"]),
  },
  methods: {
    async logout(){
      await read("/api/logout");
      this.clear();
    },
    ...mapActions("session", ["clear"]),
  }
}
</script>
