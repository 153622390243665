<template>
  <span>{{ text }}</span>
</template>
<script>
import * as filesize from "filesize";

export default {
  name: "FileSize",
  props: {
    size: {type: Number, required: true},
  },
  computed: {
    text() {
      return filesize(this.size);
    }
  }
}
</script>
