<template>
  <div>
    <div class="card-box d-flex">
      <div 
        v-for="dataSetName in ['roles', 'treatmentTypes']"
        :key="dataSetName"
        :class="chosenDataSetName === dataSetName ? 'btn-success' : 'btn-outline-success'"
        class="flex-grow-1 m-2 btn"
        @click="setChosenDataSet(dataSetName)"
      >
        <i18n
          :msgid="dataSetName"
        />
      </div>
    </div>
    <div 
      v-show="!chosenDataSetName || chosenDataSetName === 'versionTag'" 
      class="card-box"
    >
      <b>Version: </b> {{ versionTag }} <br>
    </div>
    <role-list
      v-show="!chosenDataSetName || chosenDataSetName === 'roles'"
      :role-list="roles"
    />
    <treatment-type-list
      v-show="!chosenDataSetName || chosenDataSetName === 'treatmentTypes'"
      :treatment-types="treatmentTypes"
    />
  </div>
</template>

<script>
import TreatmentTypeList from "../../components/SetupPage/TreatmentTypeList";
import RoleList from "../../components/SetupPage/RoleList";
import I18n from "../../components/i18n";
import read from "../../rest/read";

export default {
  components: {
    I18n,
    RoleList,
    TreatmentTypeList
  },
  data() {
    return {
      roles: [],
      treatmentTypes: [],
      chosenDataSetName: null,
    };
  },
  computed: {
    versionTag() {
      return process.env.APP_VERSION;
    }
  },
  async mounted() {
    const {roles, treatmentTypes} = await read("/api/setup");
    this.roles = roles;
    this.treatmentTypes = treatmentTypes;
  },
  methods: {
    setChosenDataSet(dataSetName) {
      this.chosenDataSetName = this.chosenDataSetName === dataSetName ? null : dataSetName;
    },
  },
}
</script>
