












import DatePicker from "./DatePicker.vue";
import moveDateByDays from "../../../utils/moveDateByDays";
import stringifyDate from "../../../utils/date/stringifyDate";
import DATE_FORMAT from "../../../utils/date/DATE_FORMAT";
import {Component, Prop} from "vue-property-decorator";
import Vue from "vue";

@Component({
  name: "WeekDatePicker",
  components: {DatePicker},
})

export default class WeekDatePicker extends Vue {
  @Prop({type: Date, required: true}) readonly value!: Date;

  get weekStartDate(): Date {
    return this.getMonday(this.value);
  }

  get weekEndDate(): Date {
    return moveDateByDays(this.weekStartDate, 6);
  }

  get datePickerFormat(): string {
    const startString: string = stringifyDate(this.weekStartDate, DATE_FORMAT.DATE_INVERSED);
    const endString: string = stringifyDate(this.weekEndDate, DATE_FORMAT.DATE_INVERSED);

    return `[${startString} - ${endString}]`;
  }


  changeDatePickerClasses() {
    this.$nextTick(()=> {
      document.querySelectorAll(".week-picker tr.mx-date-row.active")
        .forEach(tr => tr.classList.remove("active"));
      const dateTableCell: HTMLTableCellElement | null =
        document.querySelector(".week-picker .mx-date-row td.cell.active");
      dateTableCell?.parentElement?.classList.add("active");
    });
  }

  getMonday(date: Date): Date {
    const newDate: Date = new Date(date);
    const day: number = newDate.getDay() || 7;

    newDate.setDate(newDate.getDate() + 1 - day);
    newDate.setHours(0, 0, 0, 0);

    return newDate;
  }
}
