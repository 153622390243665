












import ObjectSelect from "../Form/Select/ObjectSelect.vue";
import {Component, Prop} from "vue-property-decorator";
import Vue from "vue";
import t from "../../i18n";
import type {Option} from "../../types/MultiSelectTypes";

@Component({
  name: "RoomTypeSelect",
  components: {ObjectSelect},
})

export default class RoomTypeSelect extends Vue {
  @Prop({type: Boolean, default: false}) readonly clearable!: boolean;
  @Prop({type: Boolean, default: false}) readonly disabled!: boolean;
  @Prop({type: String, default: "Wybierz typ pokoju"}) readonly placeholder!: string;
  @Prop({type: Boolean, default: null}) readonly state!: boolean;
  @Prop({type: String, default: null}) readonly value!: string;

  get options(): Array<Option> {
    return [
      "treatment",
      "doctor",
      "other",
    ].map(value => ({
      value,
      name: t(`@room-type.${value}`),
    }))
  }

  get selectedValue(): Option|null {
    if (!this.value) {
      return null;
    }
    return this.options.find(option => option.value === this.value) || null;
  }

  update(option: Option): void {
    this.$emit("input", option ? option.value : null);
  }
}
