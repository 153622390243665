var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('card',{attrs:{"title":"Lista zamkniętych IPZ"}},[_c('b-table',{attrs:{"items":_vm.items,"fields":_vm.fields,"empty-text":"Brak zamkniętych IPZ","responsive":true,"show-empty":""},scopedSlots:_vm._u([{key:"cell(createdAt)",fn:function(ref){
var item = ref.item;
return [_c('date-time',{attrs:{"value":_vm.parseDate(item.createdAt, _vm.DATE_FORMAT.DATE_TIME_SEC),"format":_vm.DATE_FORMAT.DATE_TIME}})]}},{key:"cell(authorName)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.authorName)+" ")]}},{key:"cell(openedAt)",fn:function(ref){
var item = ref.item;
return [_c('date-time',{attrs:{"value":_vm.parseDate(item.openedAt, _vm.DATE_FORMAT.DATE_TIME_SEC),"format":_vm.DATE_FORMAT.DATE_TIME}})]}},{key:"cell(openedBy)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.openedBy)+" ")]}},{key:"cell(closedAt)",fn:function(ref){
var item = ref.item;
return [_c('date-time',{attrs:{"value":_vm.parseDate(item.closedAt, _vm.DATE_FORMAT.DATE_TIME_SEC),"format":_vm.DATE_FORMAT.DATE_TIME}})]}},{key:"cell(closedBy)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.closedBy)+" ")]}},{key:"cell(status)",fn:function(ref){
var item = ref.item;
return [_c('child-ipz-status-badge',{attrs:{"status":item.status}})]}},{key:"cell(options)",fn:function(ref){
var item = ref.item;
return [_c('b-btn',{attrs:{"size":"sm","variant":"outline-secondary"},on:{"click":function($event){return _vm.showDetails(item)}}},[_c('span',{staticClass:"fas fa-eye"}),_vm._v(" Szczegóły ")])]}}])}),_c('child-ipz-details-modal',{attrs:{"item":_vm.selectedItem,"ipz-id":_vm.ipzId}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }