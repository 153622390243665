<template>
  <div v-if="animate || visible || networkFailure">
    <offline-notes-widget v-if="!!userId" />
    <div
      v-else
      :class="{visible: visible || networkFailure}"
      class="splash-screen"
    >
      <iframe src="/offline.html" />
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import OfflineNotesWidget from "./Notes/OfflineNotesWidget";

export default {
  components: {OfflineNotesWidget},
  computed: {
    ...mapState({
      visible: state => state.splashScreen.visible,
      networkFailure: state => state.splashScreen.networkFailure,
      animate: state => state.splashScreen.animate,
      userId: state => state.currentUser.user.userId,
    }),
  },
  mounted() {
    window.addEventListener("offline", this.showOffline);
    window.addEventListener("online", this.hideOffline);
  },
  destroy() {
    window.removeEventListener("offline", this.showOffline);
    window.addEventListener("online", this.hideOffline);
  },
  methods: {
    ...mapActions("splashScreen", [
      "showOffline",
      "hideOffline",
    ]),
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/variables";

.splash-screen {
  position: fixed;
  top:0;
  left:0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0);
  z-index: $zindex-splash-screen*10;
  transition: background-color .5s;

  &.visible {
    background-color: rgba(255, 255, 255, 0.9);

    &>.overlay {
      opacity: 1;
    }
  }
  &>iframe {
    width: 100%;
    height: 100%;
    border: 0;
  }
}
</style>
