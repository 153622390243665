<template>
  <b-modal
    id="updateWorkerInsurancePasswordModal"
    title="Aktualizacja hasła w systemie eWUŚ"
    no-close-on-backdrop
    size="lg"
    title-tag="h3"
    @show="checkPasswordExpiried"
    @hide="resetModalData"
  >
    <template #default>
      <loading-mask :loading="loading">
        <error-message :errors="errors" />
        <transition name="fade">
          <div
            v-if="actionMessage"
            :class="actionMessage.class"
            class="mt-2"
          >
            {{ actionMessage.text }}
          </div>
        </transition>
        <form-row
          :errors="errors"
          field="currentPassword"
          label="Bieżące hasło"
        >
          <b-form-input
            id="currentPassword"
            v-model="currentPassword"
            placeholder="Podaj hasło"
            type="password"
          />
        </form-row>
        <form-row
          :errors="errors"
          field="newPassword"
          label="Nowe hasło"
        >
          <b-form-input
            id="newPassword"
            v-model="newPassword"
            placeholder="Podaj hasło"
            type="password"
          />
        </form-row>
        <form-row
          :errors="errors"
          field="passwordConfirmation"
          label="Powtórz hasło"
        >
          <b-form-input
            id="passwordConfirmation"
            v-model="passwordConfirmation"
            placeholder="Podaj hasło"
            type="password"
          />
        </form-row>
      </loading-mask>
    </template>
    <template #modal-footer>
      <button
        type="button"
        class="btn btn-secondary"
        @click="closeModal"
      >
        Anuluj
      </button>
      <button
        class="btn btn-primary"
        @click="submit"
      >
        Zmień hasło
      </button>
    </template>
  </b-modal>
</template>

<script>
import FormRow from "../../components/Form/FormRow";
import create from "../../rest/create";
import processResponseException from "../../utils/errors/processResponseException";
import ErrorMessage from "../../components/Form/ErrorMessage";
import {mapActions} from "vuex";
import LoadingMask from "../Loading/LoadingMask";

export default {
  components: {
    FormRow,
    ErrorMessage,
    LoadingMask
  },
  props: {
    workerId: {type: String, default: null}
  },
  data() {
    return {
      loading: false,
      currentPassword: "",
      newPassword: "",
      passwordConfirmation: "",
      errors: [],
      actionMessage: null
    }
  },
  methods: {
    ...mapActions({
      showToast: "toastNotification/showToast",
    }),
    async checkPasswordExpiried() {
      try {
        this.loading = true;
        await create(`/api/worker/${this.workerId}/check-insurance-password`);
        this.actionMessage = {
          class: "alert alert-success",
          text: "Hasło jest aktualne",
        };
      } catch (e) {
        this.errors = processResponseException(e);
        this.actionMessage = false;
      }
      this.loading = false;
    },
    async submit() {
      const data = {
        currentPassword: this.currentPassword,
        newPassword: this.newPassword,
        passwordConfirmation: this.passwordConfirmation
      };
      try {
        this.loading = true;
        this.actionMessage = null;
        await create(`/api/worker/${this.workerId}/update-insurance-password`, data);
        this.showToast({
          message: "Hasło zostało zaktualizowane",
          variant: "success"
        });
        this.closeModal();
      } catch (e) {
        this.errors = processResponseException(e);
      }
      this.loading = false;
    },
    resetModalData() {
      this.currentPassword = "";
      this.newPassword = "";
      this.passwordConfirmation = "";
      this.actionMessage = false;
      this.clearErrors();
    },
    clearErrors() {
      this.errors = []
    },
    closeModal() {
      this.resetModalData();
      this.$bvModal.hide("updateWorkerInsurancePasswordModal");
    },
  },
}
</script>
