





























































































































































































import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";
import {InternalReferralDirection, InternalReferralItem, InternalReferralStatus} from "../../types/InternalReferral";
import parseDate from "../../utils/date/parseDate";
import DATE_FORMAT from "../../utils/date/DATE_FORMAT";
import type {BvTableFieldArray} from "bootstrap-vue/src/components/table";
import t from "../../i18n";
import RejectInternalReferralModal from "./RejectInternalReferralModal.vue";
import AcceptInternalReferralModal from "./AcceptInternalReferralModal.vue";
import FinishInternalReferralModal from "./FinishInternalReferralModal.vue";
import PreviewInternalReferralModal from "./PreviewInternalReferralModal.vue";
import UpdateInternalReferralModal from "./UpdateInternalReferralModal.vue";
import IsGranted from "../IsGranted.vue";
import CancelInternalReferralModal from "./CancelInternalReferralModal.vue";
import DateTime from "../DateTime.vue";
import RemoveInternalReferralModal from "./RemoveInternalReferralModal.vue";

@Component({
  name: "InternalReferralsList",
  components: {
    RemoveInternalReferralModal,
    RejectInternalReferralModal,
    AcceptInternalReferralModal,
    CancelInternalReferralModal,
    UpdateInternalReferralModal,
    FinishInternalReferralModal,
    IsGranted,
    PreviewInternalReferralModal,
    DateTime,
  }
})
export default class InternalReferralsList extends Vue {
  @Prop({type: Array, default: ()=>[]}) readonly items!: InternalReferralItem[];

  t = t;
  parseDate = parseDate;
  DATE_FORMAT = DATE_FORMAT;

  get fields(): BvTableFieldArray {
    return [
      {key: "direction", label: "Kierunek"},
      {key: "receivingEstablishmentName", label: "Podmiot odbierający"},
      {key: "patient", label: "Pacjent"},
      {key: "author", label: "Autor"},
      {key: "status", label: "Status"},
      {key: "sendDate", label: "Data wysyłki"},
      {key: "acceptRejectDate", label: "Data akceptacji / anulacji"},
      {key: "lastCommentDate", label: "Data ostatniego komentarza"},
      {key: "options", label: ""},
    ];
  }

  noEdit(status: InternalReferralStatus, direction: InternalReferralDirection): boolean {
    return status === InternalReferralStatus.ACCEPTED
      || status === InternalReferralStatus.CANCELED
      || status === InternalReferralStatus.REJECTED
      || status === InternalReferralStatus.FINISHED
      || (status === InternalReferralStatus.DRAFT && direction === InternalReferralDirection.RECEIVED);
  }

  private refresh(): void {
    this.$emit("refresh");
  }

  directionClass(direction: InternalReferralDirection){
    switch (direction) {
      case InternalReferralDirection.SENT:
        return "fa-arrow-alt-circle-right";
      case InternalReferralDirection.RECEIVED:
        return "fa-arrow-alt-circle-left";
      default:
        return "";
    }
  }

  statusClass(status: InternalReferralStatus): string {
    switch (status) {
      case InternalReferralStatus.DRAFT:
        return "text-warning";
      case InternalReferralStatus.ACCEPTANCE:
        return "text-success";
      case InternalReferralStatus.ACCEPTED:
        return "text-primary";
      case InternalReferralStatus.REJECTED:
        return "text-danger";
      default:
        return "text-secondary";
    }
  }
}
