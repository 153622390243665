<template>
  <form>
    <div class="form-row">
      <div class="col-md-6">
        <b-form-group
          label="Czy osoba jest bierna zawodowo"
          label-for="isProfessionallyInactivePerson"
        >
          <boolean-refusal-select
            id="isProfessionallyInactivePerson"
            :disabled="loading"
            :only-boolean="true"
            :state="state('isProfessionallyInactivePerson')"
            :value="value.isProfessionallyInactivePerson"
            @input="update($event, 'isProfessionallyInactivePerson')"
          />
          <error-message
            :errors="errors"
            field="isProfessionallyInactivePerson"
          />
        </b-form-group>
      </div>
      <div class="col-md-6">
        <b-form-group
          label="Czy osoba jest pracująca"
          label-for="doesWork"
        >
          <boolean-refusal-select
            id="doesWork"
            :disabled="loading"
            :only-boolean="true"
            :state="state('doesWork')"
            :value="value.doesWork"
            @input="updateDoesWork($event, 'doesWork')"
          />
          <error-message
            :errors="errors"
            field="doesWork"
          />
        </b-form-group>
      </div>
      <template v-if="value.doesWork">
        <div class="col-md-6">
          <b-form-group
            label="Osoba pracująca w"
            label-for="workplace"
          >
            <workplace-select
              id="workplace"
              :state="state('workplace')"
              :value="value.workplace"
              @input="update($event, 'workplace')"
            />
            <error-message
              :errors="errors"
              field="workplace"
            />
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group
            label="Wykonywany zawód"
            label-for="profession"
          >
            <profession-select
              id="profession"
              :state="state('profession')"
              :value="value.profession"
              @input="update($event, 'profession')"
            />
            <error-message
              :errors="errors"
              field="profession"
            />
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group
            label="Osoba zatrudniona w"
            label-for="worksIn"
          >
            <b-form-textarea
              id="worksIn"
              :rows="3"
              :state="state('worksIn')"
              placeholder="Zatrudniona w"
              :value="value.worksIn"
              @input="update($event, 'worksIn')"
            />
            <error-message
              :errors="errors"
              field="worksIn"
            />
          </b-form-group>
        </div>
      </template>
    </div>
  </form>
</template>
<script>
import WorkplaceSelect from "../Form/Select/WorkplaceSelect";
import ProfessionSelect from "../Form/Select/ProfessionSelect";
import ErrorMessage from "../Form/ErrorMessage";
import BooleanRefusalSelect from "../Form/Select/BooleanRefusalSelect";

export default {
  components: {
    BooleanRefusalSelect,
    ErrorMessage,
    ProfessionSelect,
    WorkplaceSelect,
  },
  props: {
    value: {type: Object, required: true},
    errors: {type: Array, default: () => []},
    loading: {type: Boolean, default: false},
  },
  data() {
    const options = [{value: true, name: "tak"}, {value: false, name: "nie"}];
    return {
      options
    };
  },
  methods: {
    state(field) {
      return this.errors.find((error) => error.field === field) ? false : null;
    },
    clearError(field) {
      this.$emit("clearError", field);
    },
    update(event, field) {
      this.$emit("input", {...this.value, [field]: event})
    },
    updateDoesWork(event) {
      const value = {
        ...this.value,
        doesWork: event,
        workplace: null,
        profession: null,
        worksIn: null
      }
      this.$emit("input", value);
    },
  }
}
</script>
