








































































































import ErrorsMixin from "../../mixins/ErrorsMixin.ts";
import {Component, Mixins} from "vue-property-decorator";
import IsGranted from "../IsGranted.vue";
import Tooltip from "../Tooltip.vue";
import processResponseException from "../../utils/errors/processResponseException";
import FormRow from "../Form/FormRow.vue";
import create from "../../rest/create";
import {mapState} from "vuex";
import ResizableTextarea from "../Form/Textarea/ResizableTextarea.vue";
import FileUpload from "../File/FileUpload.vue";
import ErrorMessage from "../Form/ErrorMessage.vue";

interface File {
  fileId: string;
  size: number;
  name: string;
  type?: string;
}

interface FormData {
  title: string;
  body: string;
  files: Array<File>;
  email: string;
}

@Component({
  name: "HelpdeskWidget",
  components: {IsGranted, ErrorMessage, FileUpload, ResizableTextarea, FormRow, Tooltip},
  computed: {...mapState({
    currentUser: state => state.currentUser.user,
  })}
})
export default class HelpdeskWidget extends Mixins(ErrorsMixin) {
  private loading: boolean = false;
  private title: string = "";
  private fileNumber: string = "";
  private body: string = "";
  private files: Array<File> = [];

  private get email(): string {
    return this.currentUser ? this.currentUser.email : "";
  };

  private close(): void {
    this.$bvModal.hide("helpdesk-modal");
  }

  private async submit(): Promise<void> {
    this.clearErrors();
    this.loading = true;
    try {
      const data = {
        title: this.title,
        fileNumber: this.fileNumber,
        body: this.body,
        files: this.files.map(file => file.fileId),
        email: this.email,
        url: document.location.href,
      };
      await create<FormData>("/api/helpdesk/message", data);
      this.close();
    } catch(e) {
      this.errors = processResponseException(e);
    }
    this.loading = false;
  }

  private clearErrors(): void {
    this.errors = [];
  }

  private addFile(file: File): void {
    this.files.push(file);
  }

  private removeFile(file):void {
    this.files = this.files.filter(f => f.fileId !== file.fileId);
  }

  private resetModalData(): void {
    this.clearErrors();
    this.loading = false;
    this.title = "";
    this.fileNumber = "";
    this.body = "";
    this.files = [];
  }
}
