<template>
  <loading-mask :loading="loading">
    <error-message :errors="errors" />
    <appointment-plan :appointment-id="item.appointmentId" />
    <appointment-document-cancel-reason
      v-if="item.cancelReason"
      :cancel-comment="item.cancelComment"
      :cancel-reason="item.cancelReason"
      :title="item.title"
    />
    <appointment-document-hidden-info
      v-else-if="item.blocked || (item.medicalContent && !item.displayMedicalContent)"
      :display-medical-content="item.displayMedicalContent"
      :blocked="item.blocked"
    />
    <appointment-document-modules
      v-else
      :modules="item.modules"
      :selected-document-idx="idx"
      :patient="item.patient"
      :read-only="true"
      :appointment-document-id="item.appointmentDocumentId"
      :continuous-stay-id="item.continuousStayId"
      :appointment-date="appointmentDate(item)"
      :appointment-id="item.appointmentId"
      :canceled="item.cancelReason !== null"
      :appointment-end-date="appointmentEndDate(item)"
    />
  </loading-mask>
</template>

<script>
import ErrorMessage from "../../components/Form/ErrorMessage";
import LoadingMask from "../../components/Loading/LoadingMask";
import {documentMixin} from "../../mixins/Appointment/documentMixin";
import {errorsMixin} from "../../mixins/errorsMixin.js";
import AppointmentDocumentCancelReason from "../../components/Appointment/AppointmentDocumentCancelReason";
import AppointmentPlan from "../../components/Appointment/AppointmentPlan";
import AppointmentDocumentModules from "../../components/Appointment/DocumentModules/AppointmentDocumentModules";
import parseDate from "../../utils/date/parseDate";
import AppointmentDocumentHiddenInfo from "../../components/Appointment/AppointmentDocumentHiddenInfo";

export default {
  name: "DocumentPreview",
  components: {
    AppointmentDocumentHiddenInfo,
    AppointmentDocumentModules,
    AppointmentPlan,
    AppointmentDocumentCancelReason,
    LoadingMask,
    ErrorMessage,
  },
  mixins: [errorsMixin, documentMixin],
  props: {
    item: {type: Object, required: true},
    idx: {type: Number, required: true},
  },
  data() {
    return {
      loading: false,
      appointmentPlan: null
    };
  },
  methods: {
    appointmentDate(item) {
      return parseDate(item.scheduledDate);
    },
    appointmentEndDate(item) {
      return parseDate(item.endDate);
    },
  },
};
</script>
