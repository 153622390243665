

















































import ErrorsMixin from "../../mixins/ErrorsMixin";
import {Component, Mixins, Watch} from "vue-property-decorator";
import processResponseException from "../../utils/errors/processResponseException";
import InternalReferralForm from "./InternalReferralForm.vue";
import type {Patient, Branch, InternalReferralSubmitData} from "../../types/InternalReferral";
import {generateUuid} from "../../utils/uuid/generateUuid";
import create from "../../rest/create";
import modify from "../../rest/modify";
import ErrorMessage from "../Form/ErrorMessage.vue";
import IsGranted from "../IsGranted.vue";
import {mapGetters, mapState} from "vuex";

interface FormData {
  patient?: Patient;
  referringEstablishment?: Branch;
  receivingEstablishment?: Branch;
  description?: string;
  author?: string;
  files: string[];
}

@Component({
  name: "CreateInternalReferralModal",
  components: {IsGranted, ErrorMessage, InternalReferralForm},
  computed: {
    ...mapState({
      currentUser: state => state.currentUser.user,
    }),
    ...mapGetters({
      referringEstablishment: "currentUser/referringEstablishment",
    }),
  },
})
export default class CreateInternalReferralModal extends Mixins(ErrorsMixin) {
  private loading: boolean = false;
  private data: FormData = {files: []};
  private files: File[] = [];

  private get author(): string {
    return `${this.currentUser?.name || ""} ${this.currentUser?.surname || ""}`;
  }

  @Watch("author", {immediate:true}) onAuthorChange(val: string): void {
    this.data.author = val;
  }

  @Watch("referringEstablishment", {immediate:true}) onReferringEstablishmentChange(val: Branch): void {
    this.data.referringEstablishment = {
      ...val,
      value: val.branchId,
    };
  }

  private hideModal(): void {
    this.$bvModal.hide("create-internal-referral")
  }

  private resetData(): void {
    this.errors = [];
    this.loading = false;
    this.data = {
      referringEstablishment: this.referringEstablishment,
      author: this.author,
      files: []
    };
  }

  private async save(): Promise<void> {
    this.loading = true;
    this.errors = [];
    const internalReferralId = generateUuid();
    try {
      await this.createReferral(internalReferralId);
      this.hideModal();
      this.$emit("created");
    } catch (e) {
      this.errors = processResponseException(e);
    }
    this.loading = false;
  }

  private async send(): Promise<void> {
    this.loading = true;
    this.errors = [];
    const internalReferralId = generateUuid();
    try {
      await this.createReferral(internalReferralId);
      await modify(`/api/internal-referrals/${internalReferralId}/send`, {});
      this.hideModal();
      this.$emit("created");
    } catch (e) {
      this.errors = processResponseException(e);
    }
    this.loading = false;
  }

  private async createReferral(internalReferralId: string): Promise<void> {
    const data = {
      internalReferralId,
      patientId: this.data?.patient?.patientId,
      referringEstablishment: this.referringEstablishment?.branchId,
      receivingEstablishment: this.data?.receivingEstablishment?.branchId,
      description: this.data?.description || undefined,
      files: this.data.files
    };
    await create<InternalReferralSubmitData>("/api/internal-referrals", data);
  }
}
