<template>
  <card
    :loading="loading"
    title="Przyjmowane leki"
  >
    <template #control>
      <is-granted
        :privileges="['CHANGE_PATIENT_MEDICINES_TAKEN']"
      >
        <b-btn
          v-b-modal.medicinesTakenModal
          :disabled="loading"
          variant="primary"
          class="btn-sm"
        >
          <i class="fa fa-pencil-alt" />
        </b-btn>
      </is-granted>
    </template>
    <p v-if="medicinesTaken">
      {{ medicinesTaken }}
    </p>
    <p v-else>
      Brak
    </p>
    <patient-card-medicines-taken-modal
      :patient-id="patientId"
      :history="medicinesTakenHistory"
      :old-value="medicinesTaken"
      @submit-start="loading = true"
      @submit-end="loadData"
    />
  </card>
</template>

<script>
import read from "../../../../rest/read";
import Card from "../../../Card";
import PatientCardMedicinesTakenModal from "./PatientCardMedicinesTakenModal";
import IsGranted from "../../../IsGranted";

export default {
  name: "PatientCardMedicinesTaken",
  components: {
    IsGranted,
    PatientCardMedicinesTakenModal,
    Card,
  },
  props: {
    patientId: {type: String, required: true},
  },
  data() {
    return {
      medicinesTaken: "",
      medicinesTakenHistory: [],
      loading: false,
    };
  },
  watch: {
    patientId() {
      this.loadData();
    },
  },
  async mounted() {
    await this.loadData();
  },
  methods: {
    async loadData() {
      this.loading = true;
      this.errors = [];
      const response = await read(`/api/patients/${this.patientId}/medicines-taken`);
      this.medicinesTakenHistory = response.medicinesTakenHistory.reverse();
      this.medicinesTaken = response.medicinesTaken;
      this.loading = false;
    },
  },
}
</script>

<style scoped>
  .card-box {
    overflow: hidden;
  }
</style>
