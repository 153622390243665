<template>
  <card
    :loading="loading"
    title=""
  >
    <template #control>
      <is-granted
        :privileges="[
          'SCHEDULE_APPOINTMENT', 'SCHEDULE_APPOINTMENT_SPECIALIST', 'SCHEDULE_APPOINTMENT_RECOVERY_ASSISTANT'
        ]"
        component="fragment"
      >
        <router-link
          class="btn btn-success btn-sm m-1"
          :to="{name: 'calendarView', query: {continuousStay: true}}"
        >
          <i class="fas fa-calendar-plus" />
          Umów wizytę
        </router-link>
      </is-granted>
      <b-btn
        class="m-1"
        size="sm"
        variant="primary"
        @click="loadData"
      >
        <span class="fas fa-sync" />
        Odśwież listę usług
      </b-btn>
    </template>
    <template #default>
      <error-message :errors="errors" />
      <template v-if="!itemsByDay.length">
        <div class="alert text-center">
          Brak usług w ramach pobytu ciągłego
        </div>
      </template>
      <pagination-description
        :items-count="items.length"
        :page="pagination.currentPage"
        :per-page="pagination.perPage"
        :total-count="pagination.totalRows"
      />
      <appointment-list
        :items-by-day="itemsByDay"
        @refresh="loadData"
      />
      <pagination-description
        :items-count="items.length"
        :page="pagination.currentPage"
        :per-page="pagination.perPage"
        :total-count="pagination.totalRows"
      >
        <b-pagination
          :total-rows="pagination.totalRows"
          :per-page="pagination.perPage"
          :value="pagination.currentPage"
          align="right"
          class="mb-0"
          @input="changePage"
        />
      </pagination-description>
      <appointment-box-modals
        @appointmentUpdated="loadData"
      />
    </template>
  </card>
</template>

<script>
import PaginationDescription from "@/components/Pagination/PaginationDescription";
import AppointmentList from "@/components/Appointment/AppointmentList";
import AppointmentBoxModals from "@/components/Appointment/AppointmentBox/AppointmentBoxModals";
import stringifyDate from "@/utils/date/stringifyDate";
import DATE_FORMAT from "@/utils/date/DATE_FORMAT";
import {updatePageQuery} from "@/utils/pageUrl/handlePageQuery";
import read from "@/rest/read";
import {mapMutations} from "vuex";
import parseDate from "@/utils/date/parseDate";
import processResponseException from "@/utils/errors/processResponseException";
import Card from "@/components/Card";
import ErrorMessage from "@/components/Form/ErrorMessage";
import {errorsMixin} from "../../mixins/errorsMixin.js";
import IsGranted from "@/components/IsGranted";

export default {
  name: "ContinuousStayAppointments",
  components: {
    IsGranted,
    ErrorMessage,
    Card,
    AppointmentBoxModals,
    AppointmentList,
    PaginationDescription,
  },
  mixins:[errorsMixin],
  beforeRouteUpdate (to, from, next) {
    this.items = [];
    next();
  },
  beforeRouteLeave(to, from, next) {
    this.saveAppointmentListItem(null);
    next();
  },
  props: {
    continuousStayId: {type: String, default: null},
    patientId: {type: String, default: null},
    page: {type: Number, default: null},
  },
  data() {
    return {
      currentPage: this.page || 1,
      items: [],
      loading: false,
      pagination: {perPage: 20},
    };
  },
  computed: {
    parsedItems() {
      return this.items.map((item) => {
        const date = parseDate(item.date);
        const label = stringifyDate(date, DATE_FORMAT.TIME);
        return {...item, date, label};
      });
    },
    itemsByDay() {
      return this.parsedItems.reduce((days, item) => {
        const itemLabel = stringifyDate(item.date, DATE_FORMAT.DATE);
        const day = days.find(({label}) => label === itemLabel);

        if (null == day) {
          return days.concat([
            {label: itemLabel, items: [item]},
          ]);
        }

        return days.map((d) => d !== day ? d : {
          label: d.label,
          items: d.items.concat([item])
        });
      }, [])
    },
    filters() {
      return {
        page: this.currentPage,
        perPage: this.pagination.perPage,
        patientId: this.patientId,
        continuousStayId: this.continuousStayId,
      };
    }
  },
  watch: {
    async page(val) {
      this.currentPage = val || 1;
      await this.loadData();
    },
    continuousStayId: {
      immediate: true,
      async handler() {
        if (this.currentPage > 1) {
          await this.changePage(1);
        }
        await this.loadData();
      },
    },
  },
  methods: {
    ...mapMutations("appointmentListItem", ["saveAppointmentListItem",]),
    async changePage(page) {
      this.currentPage = page;
      await updatePageQuery({csaPage: this.currentPage});
    },
    async loadData() {
      this.loading = true;
      this.errors = [];
      try {
        const {pagination, items} = await read("/api/appointments", this.filters);
        this.pagination = pagination;
        this.items = items;
        this.$emit("loaded", this.pagination.totalRows);
      } catch (e) {
        this.errors = processResponseException(e);
      }
      this.loading = false;
    },
  },
}
</script>

<style scoped>

</style>
