import {IS_AUTHENTICATED, TOKEN, storage} from "../session";
import {SessionState} from "./types";

export const getInitialState = (): SessionState => {
  const isAuthenticatedStr = storage.getItem(IS_AUTHENTICATED);
  const token = storage.getItem(TOKEN);
  return {
    isAuthenticated: isAuthenticatedStr
      ? JSON.parse(isAuthenticatedStr)
      : false,
    token: token ? token : null,
  }
};
