<template>
  <b-modal
    id="reopenContinuousStayModal"
    no-close-on-backdrop
    title="Otwórz pobyt ponownie"
    title-tag="h3"
  >
    <error-message :errors="errors" />
    Czy na pewno chcesz ponownie otworzyć pobyt?
    <template #modal-footer>
      <button
        type="button"
        class="btn btn-secondary"
        @click="hideModal"
      >
        Anuluj
      </button>
      <button
        :disabled="loading"
        type="submit"
        class="btn btn-primary"
        @click="reopenContinuousStay"
      >
        <i
          :class="loading ? 'fa-spin fa-spinner' : 'fa-check'"
          class="fa"
        />
        Otwórz
      </button>
    </template>
  </b-modal>
</template>

<script>
import {errorsMixin} from "../../../../mixins/errorsMixin.js";
import processResponseException from "../../../../utils/errors/processResponseException";
import update from "../../../../rest/update";
import ErrorMessage from "../../../Form/ErrorMessage";

export default {
  name: "ReopenContinuousStay",
  components: {ErrorMessage},
  mixins: [errorsMixin],
  props: {
    patientId: {type: String, default: null},
    continuousStayId: {type: String, required: true},
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    hideModal() {
      this.$bvModal.hide("reopenContinuousStayModal")
    },
    async reopenContinuousStay() {
      this.loading = true;
      try {
        await update(`/api/continuous-stays/${this.continuousStayId}/reopen`);
        this.$emit("reopened");
        this.clearErrors();
        this.hideModal();
      } catch (e) {
        this.errors = processResponseException(e);
      }
      this.loading = false;
    },
    clearErrors() {
      this.errors = [];
    }
  }
}
</script>
