<template>
  <object-select
    :value="selectedValue"
    :options="options"
    :placeholder="placeholder"
    :state="state"
    :disabled="disabled"
    @input="update"
  />
</template>

<script>
import ObjectSelect from "./ObjectSelect";
import t from "../../../i18n";

export default {
  components: {
    ObjectSelect,
  },
  props: {
    value: {type: String, default: null},
    state: ObjectSelect.props.state, // eslint-disable-line
    placeholder: {type: String, default: "Wybierz stopień ubezwłasnowolnienia"},
    disabled: {type: Boolean, default: false},
  },
  data() {
    return {
      optionValues: [
        {value: "none"},
        {value: "partly"},
        {value: "completely"},
      ],
    };
  },
  computed: {
    options() {
      return this.optionValues.map(option => ({
        ...option,
        name: t(`@incapacitation-degree.${option.value}`),
      }));
    },
    selectedValue() {
      if (!this.value) {
        return null
      } else {
        return this.options.find((option) => option.value === this.value);
      }
    },
  },
  methods: {
    update(option) {
      this.$emit("input", option ? option.value : null);
    },
  },
};
</script>
