






























































































import ErrorsMixin from "../../mixins/ErrorsMixin.ts";
import {Component, Mixins, Prop} from "vue-property-decorator";
import type {InsuredPersonAddress} from "../../types/Zla";
import InsuredPersonAddressBox from "./InsuredPersonAddressBox.vue";
import isEqual from "lodash/isEqual";
import {Error} from "../../rest";
import ErrorMessage from "../Form/ErrorMessage.vue";

@Component({
  name: "InsuredPersonAddressForm",
  components: {ErrorMessage, InsuredPersonAddressBox}
})
export default class InsuredPersonAddressForm extends Mixins(ErrorsMixin) {
  @Prop({type: Object, default: null}) readonly value!: InsuredPersonAddress;
  @Prop({type: Array, default: ()=>[]}) readonly addresses!: InsuredPersonAddress[];
  @Prop({type: Array, default: ()=>[]}) readonly errorsProp!: Error[];

  isEqual = isEqual;
  private address: InsuredPersonAddress = {
    zipCode: "",
    cityName: "",
    streetName: "",
    streetNo: "",
  };
  private customAddress: boolean = false;

  private update(address: InsuredPersonAddress|null): void {
    this.$emit("input", address);
  }

  private select(address: InsuredPersonAddress): void {
    this.customAddress = false;
    this.update(address);
  }

  private checkCustomAddress($event: boolean, address: InsuredPersonAddress): void {
    this.update( $event ? address : null);
  }

  private state(field: string): boolean|null {
    return this.errorsProp.find((error) => error.field === field) ? false : null;
  }
}

