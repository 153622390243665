<template>
  <div class="card mb-3">
    <div class="card-header">
      Usługa: {{ treatmentType.name }}
    </div>
    <div class="card-body">
      <p>
        Preferowany pracownik:
        <span v-if="requirement.preferredWorker">
          {{ requirement.preferredWorker.name }} {{ requirement.preferredWorker.surname }}
        </span>
        <span v-else>dowolny</span>
      </p>
      <p>{{ requirement.scheduledAppointmentsCountPerMonth }}/{{ requirement.frequencyCountPerMonth }}</p>
    </div>
    <div class="card-footer text-center">
      <button
        :class="`btn ${selected ? 'btn-primary' : 'btn-outline-primary'}`"
        @click="emitClickEvent"
      >
        Zaplanuj wizytę
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "IpzAppointmentRequirementsItem",
  props: {
    requirement: {required: true, type: Object},
    treatmentType: {required: true, type: Object},
    selected: {required: true, type: Boolean},
  },
  methods: {
    emitClickEvent() {
      const requirement = this.requirement;
      this.$emit("click", {
        meetingRequirementId: requirement.meetingRequirementId,
        treatmentType: requirement.treatmentType,
        workerId: requirement.preferredWorker ? requirement.preferredWorker.workerId : null,
        workerName: requirement.preferredWorker
          ? `${requirement.preferredWorker.name  } ${  requirement.preferredWorker.surname}`
          : null,
      });
    }
  }
};
</script>
