import {store} from "../main";
import create from "./create";
import modify from "./modify";
import read from "./read";
import remove from "./remove";
import update from "./update";

const headers = new Headers();
headers.append("Content-Type", "application/json");
headers.append("Accept", "application/json");
headers.append("X-Requested-With", "XMLHttpRequest");

export const defaults: Partial<RequestInit> = {
  headers,
  redirect: "manual",
  credentials: "same-origin"
};

export class BadRequestException extends Error {
  public readonly errors: Array<Error>;

  constructor({errors}: {errors: Array<Error>}) {
    super("Bad request");
    this.errors = errors;
  }
}

export class NotFoundException extends Error {
  constructor() {
    super("Not found");
  }
}

export class ForbiddenException extends Error {
  constructor(){
    super("Action Forbidden");
  }
}

export class UnauthorizedException extends Error {
  constructor() {
    store.dispatch("session/clear");
    super("Unauthorized");
  }
}

export class ServiceUnavailableException extends Error {
  constructor() {
    super("Service Unavailable");
  }
}

export class ConflictException extends Error {
  constructor() {
    super("Conflict");
  }
}

export class UnknownServerException extends Error {
  public readonly status: number;
  public readonly url: string;

  constructor({status, url}: {status: number; url: string}) {
    super("Unknown");
    this.status = status;
    this.url = url;
  }
}

export class NetworkFailureException extends Error {
  constructor() {
    super("Network Failure");
  }
}

export interface Error {
  code?: string;
  field?: string;
  message: string;
}

export const rest = {create, read, update, delete: remove, modify};
export default rest;
