<template>
  <span>
    <the-mask
      :mask="['####-##-##']"
      :class="{'is-invalid': invalidFormat || hasErrors}"
      :value="birthdate"
      masked
      class="form-control"
      placeholder="YYYY-MM-DD"
      @input="update"
    />
    <span 
      v-if="invalidFormat" 
      class="text-danger"
    >
      Niepoprawny format daty.
    </span>
  </span>
</template>

<script>
import {TheMask} from "vue-the-mask"
import stringifyDate from "../../../utils/date/stringifyDate";
import DATE_FORMAT from "../../../utils/date/DATE_FORMAT";
import parseDate from "../../../utils/date/parseDate";

export default {
  components: {
    TheMask
  },

  props: {
    value: {type: Date, default: null},
    errors: {type: Array, default: () => []},
  },

  data(){
    return {
      birthdate: "",
      invalidFormat: false,
      hasErrors: false,
    }
  },
  watch: {
    value() {
      if (null !== this.value) {
        this.birthdate = stringifyDate(this.value, format);
      }

    },
    errors() {
      this.hasErrors = false;
      if (this.errors.find((error) => error.field === "birthdate")){
        this.hasErrors = true;
      }
    },
    birthdate() {
      if (this.birthdate.length < 10){
        this.$emit("input", null);
      }
    }
  },

  methods: {
    update(value) {
      this.birthdate = value;
      this.invalidFormat = false;
      if (this.birthdate.length === 10) {
        const date = parseDate(value, format);
        const stringDate = date ? stringifyDate(date, format) : null;
        if (date && stringDate === value) {
          this.$emit("input", date);
        } else {
          this.invalidFormat = true;
        }
      }
    }
  },
}

const format= DATE_FORMAT.DATE;
</script>
