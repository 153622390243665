








import Tooltip from "../Tooltip.vue";
import Component from "vue-class-component";
import Vue from "vue";

@Component({
  name: "MedicationDosageInfo",
  components: {Tooltip}
})
export default class MedicationDosageInfo extends Vue {
  private info: string = `Wypełnij sekcję "dawkowanie" lub "opis słowny".
    Po wypełnieniu jednej sekcji, druga automatycznie stanie się nieaktywna.
    Usuń wartości z jednej sekcji aby umożliwić uzupełnienie drugiej.`
}
