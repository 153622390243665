







import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";
import type {InsuredPersonAddress} from "../../types/Zla";

@Component({
  name: "InsuredPersonAddressBox"
})
export default class InsuredPersonAddressBox extends Vue {
  @Prop({type: Object, required: true}) readonly address!: InsuredPersonAddress;
}
