<template>
  <b-card
    no-body
    class="p-b-10"
  >
    <b-card-header
      header-tag="header"
      class="p-0"
    >
      <button
        class="btn btn-link btn-block text-left text-wrap"
        @click="toggleDetails"
      >
        <diagnosis-title
          :title="title"
          :entries="entries"
          :show-count="showEntriesCount"
        />
      </button>
    </b-card-header>
    <b-collapse
      :id="`details-${index}`"
      :visible="areDetailsVisible"
    >
      <b-card-body>
        <slot />
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>
import DiagnosisTitle from "./DiagnosisTitle";

export default {
  name: "DiagnosisCollapse",
  components: {DiagnosisTitle},
  props: {
    index: {type: Number, required: true},
    value: {type: Array, default: ()=>[]},
    title: {type: String, default: ""},
    entries: {type: Array, default: ()=>[]},
    diagnosisType: {type: String, default: null},
  },
  computed: {
    areDetailsVisible() {
      return this.value.findIndex(visibleIndex => visibleIndex === this.index) > -1;
    },
    showEntriesCount() {
      return !this.entries.some(entry => entry.disableCounter);
    },
  },
  methods: {
    toggleDetails() {
      const visibleDetails = this.areDetailsVisible
        ? this.value.filter(visibleIndex => visibleIndex !== this.index)
        : [...this.value, this.index];
      this.$emit("input", visibleDetails);
    },
  },
}
</script>
