<template>
  <div class="card-box">
    <h3>{{ title }} - Anulowano</h3>
    <p>
      <span class="font-weight-bold">Powód:</span> {{ cancelReasonMessage }}
    </p>
    <p>
      <span class="font-weight-bold">Uwagi:</span>
      <br>
      <nl2br :text="cancelComment" />
    </p>
  </div>
</template>

<script>
import t from "../../i18n";
import Nl2br from "../Nl2br";

export default {
  name: "AppointmentDocumentCancelReason",
  components: {Nl2br},
  props: {
    cancelComment: {type: String, default: null},
    cancelReason: {type: String, default: null},
    title: {type: String, required: true},
  },
  computed: {
    cancelReasonMessage() {
      return this.cancelReason ? t(`@cancel-reason.${this.cancelReason}`) : "";
    },
  },
}
</script>
