import {IS_AUTHENTICATED, TOKEN, storage} from "../session";
import {ActionTree} from "vuex";
import {SessionState} from "./types";
import {RootState} from "../../types";

export const actions: ActionTree<SessionState, RootState> = {
  authenticate({commit, dispatch}, token = null){
    storage.setItem(IS_AUTHENTICATED, "true");
    storage.setItem(TOKEN, token);
    commit("setIsAuthenticated", {isAuthenticated: true, token: token});
    dispatch("currentUser/loadCurrentUser", null, {root: true});
  },
  clear({commit, dispatch}){
    dispatch("geolocalization/clearLocalization", null, {root: true});
    storage.setItem(IS_AUTHENTICATED, "false");
    storage.setItem(TOKEN, "");
    commit("setIsAuthenticated", {isAuthenticated: false, token: null});
    window.location.reload();
  }
};
