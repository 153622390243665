var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 text-right m-b-10"},[_c('button',{staticClass:"btn btn-sm btn-secondary m-1",on:{"click":_vm.showAll}},[_vm._v(" Rozwiń wszystko ")]),(_vm.atLeastOneEntry)?_c('a',{staticClass:"btn btn-sm btn-primary m-1",attrs:{"href":("/api/ipzs/therapeutic-plans/" + _vm.therapeuticPlanId + "/print"),"target":"_blank"}},[_c('i',{staticClass:"fa fa-print"}),_vm._v(" Drukuj Plan Terapeutyczny ")]):_vm._e()]),_c('div',{staticClass:"col-12"},[_c('diagnosis-collapse',{attrs:{"entries":_vm.getFieldsEntries(['THERAPEUTIC_RESOURCES', 'THERAPEUTIC_NEEDS']),"index":0,"title":_vm.titles[0]},model:{value:(_vm.visibleDetails),callback:function ($$v) {_vm.visibleDetails=$$v},expression:"visibleDetails"}},_vm._l(({
          THERAPEUTIC_RESOURCES: 'Zasoby',
          THERAPEUTIC_NEEDS: 'Potrzeby',
        }),function(item,name){return _c('diagnosis-field',{key:name,attrs:{"read-only":_vm.overview.readonly,"injected-entries":_vm.getField(name).entries,"type":name,"type-trans":item.toUpperCase(),"create-entry-url":_vm.createEntryUrl},on:{"new-entry-added":_vm.entryUpdated}})}),1),_c('diagnosis-collapse',{attrs:{"entries":_vm.getFieldsEntries(['THERAPEUTIC_MAIN_TARGETS', 'THERAPEUTIC_TARGETS']),"index":1,"title":_vm.titles[1]},model:{value:(_vm.visibleDetails),callback:function ($$v) {_vm.visibleDetails=$$v},expression:"visibleDetails"}},[_vm._l(({
          THERAPEUTIC_MAIN_TARGETS: 'Cele główne',
        }),function(item,name){return _c('diagnosis-field',{key:name,attrs:{"read-only":_vm.overview.readonly,"injected-entries":_vm.getField(name).entries,"type":name,"type-trans":item.toUpperCase(),"create-entry-url":_vm.createEntryUrl},on:{"new-entry-added":_vm.entryUpdated}})}),_vm._l(({
          THERAPEUTIC_TARGETS: 'Cele pracy terapeutycznej',
        }),function(item,name){return _c('editable-diagnosis-field',{key:name,attrs:{"read-only":_vm.overview.readonly,"injected-entries":_vm.getField(name).entries,"type":name,"type-trans":item.toUpperCase(),"create-entry-url":_vm.createEntryUrl},on:{"new-entry-added":_vm.entryUpdated}})})],2),_c('diagnosis-collapse',{attrs:{"entries":_vm.therapeuticTaskEntries,"index":2,"title":_vm.titles[2]},model:{value:(_vm.visibleDetails),callback:function ($$v) {_vm.visibleDetails=$$v},expression:"visibleDetails"}},[_c('TherapeuticTasks',{attrs:{"read-only":_vm.overview.readonly,"therapeutic-plan-id":_vm.therapeuticPlanId},on:{"entry-updated":_vm.therapeuticTaskEntryUpdated}})],1),_c('diagnosis-collapse',{attrs:{"entries":_vm.meetingRequirementsEntries,"index":3,"title":_vm.titles[3]},model:{value:(_vm.visibleDetails),callback:function ($$v) {_vm.visibleDetails=$$v},expression:"visibleDetails"}},[_c('MeetingRequirements',{attrs:{"read-only":_vm.overview.readonly,"ipz-id":_vm.overview.ipzId},on:{"entry-updated":_vm.meetingRequirementsEntryUpdated}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }