



















































































import ResizableTextarea from "../../Form/Textarea/ResizableTextarea.vue";
import processResponseException from "../../../utils/errors/processResponseException";
import update from "../../../rest/update";
import ErrorMessage from "../../Form/ErrorMessage.vue";
import TargetStatusSelect from "./TargetStatusSelect.vue";
import Card from "../../Card.vue";
import {Component, Prop, Watch} from "vue-property-decorator";
import ErrorsMixin from "../../../mixins/ErrorsMixin.ts";
import {mixins} from "vue-class-component";
import type {TargetFormData, Task} from "../../../types/TargetsAndTasks";

@Component({
  name: "TaskForm",
  components: {Card, TargetStatusSelect, ErrorMessage, ResizableTextarea},
})

export default class TaskForm extends mixins(ErrorsMixin) {
  @Prop({type: String, required: true}) readonly ipzId!: string;
  @Prop({type: String, required: true}) readonly targetId!: string;
  @Prop({type: Object, required: true}) readonly task!: Task;
  @Prop({type: Boolean, default: false}) readonly updating!: boolean;
  @Prop({type: Boolean, required: true}) readonly readonly!: boolean;

  name: TargetFormData["name"] = this.task.name;
  description: TargetFormData["description"] = this.task.description;
  status: TargetFormData["status"] = this.task.status;

  get disableActions(): boolean {
    return this.task.name === this.name
      && this.task.description === this.description
      && this.task.status === this.status;
  }

  @Watch("task", {immediate: true}) onTaskChange(): void {
    this.resetData();
  }

  async submit(): Promise<void> {
    this.resetErrors();
    this.$emit("setUpdating", true);
    try {
      const data = {
        name: this.name ? this.name.trim() : this.name,
        description: this.description ? this.description.trim() : this.description,
        status: this.status,
      };
      await update(`/api/tasks/${this.task.taskId}`, data);
      this.$emit("updated");
    } catch (e) {
      this.errors = processResponseException(e);
    }
    this.$emit("setUpdating", false);
  }

  resetErrors(): void {
    this.errors = [];
  }

  resetData():void {
    this.resetErrors();
    this.name = this.task.name;
    this.description = this.task.description;
    this.status = this.task.status;
  }
}
