<template>
  <div>
    <div class="card-box">
      <b-form>
        <b-input-group>
          <b-form-input
            v-model="searchString"
            placeholder="Wpisz imię i/lub nazwisko..."
          />
          <b-input-group-append>
            <button
              class="btn btn-primary"
              @click.prevent="getWorkingHoursForWorkersByFullName()"
            >
              Szukaj
            </button>
          </b-input-group-append>
        </b-input-group>
      </b-form>
    </div>
    <div class="card-box">
      <div class="text-center">
        <i
          v-if="isLoading"
          class="fa fa-spinner fa-spin"
        />
      </div>
      <error-message :errors="errors" />
      <transition name="fade">
        <b-alert
          :show="workers.length === 0 && message.length > 0"
          variant="warning"
          dismissible
          @dismissed="message=''"
        >
          {{ message }}
        </b-alert>
      </transition>
      <table 
        v-if="workers.length > 0" 
        class="table"
      >
        <thead>
          <tr>
            <th>Imię i nazwisko</th>
            <th>Przedziały</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(worker, index) in workers"
            :key="index"
          >
            <td>
              {{ worker.fullName }}
            </td>
            <td>
              <worker-working-hours-list
                :worker-id="worker.workerId"
                :recurring-ranges-list="worker.recurringRanges"
                :ranges-list="worker.ranges"
                :edit-mode="false"
              />
            </td>
            <td>
              <is-granted :privileges="['WORKER_WORKING_HOURS_VIEW']">
                <router-link
                  :to="{name: 'workersWorkingHoursPage', params: {workerId: worker.workerId}}"
                  class="btn btn-sm btn-primary"
                >
                  <i class="fa fa-pencil-alt" /> Edytuj
                </router-link>
              </is-granted>
              <is-granted :privileges="['FIND_WORKER_ABSENCES']">
                <router-link
                  :to="{name: 'workersAbsence', params: {workerId: worker.workerId}}"
                  class="btn btn-sm btn-secondary edit-absences"
                >
                  <i class="fa fa-pencil-alt" /> Nieobecności
                </router-link>
              </is-granted>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import WorkerWorkingHoursList from "../../components/Worker/WorkingHours/WorkerWorkingHoursList";
import IsGranted from "../../components/IsGranted";
import ErrorMessage from "../../components/Form/ErrorMessage";
import read from "../../rest/read";
import processResponseException from "../../utils/errors/processResponseException";

export default {
  name: "WorkingHoursList",
  components: {
    ErrorMessage,
    WorkerWorkingHoursList,
    IsGranted,
  },
  data() {
    return {
      workers: [],
      errors: [],
      searchString: "",
      message: "",
      isLoading: false,
    }
  },
  methods: {
    async getWorkingHoursForWorkersByFullName() {
      this.workers = [];
      this.isLoading = true;
      this.errors = [];
      try {
        const {workers} = await read("/api/workers-working-hours", {fullName: this.searchString});
        this.workers = workers;
        if (this.workers.length === 0) {
          this.message = "nie znaleziono pracowników spełniających kryteria"
        }
      } catch(exception) {
        this.errors = processResponseException(exception);
      }
      this.isLoading = false
    },
  },
}
</script>

<style scoped lang="css">
  .edit-absences {
    margin: 5px;
  }
</style>
