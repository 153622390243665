<template>
  <div>
    <div class="card-box m-b-5">
      <is-granted
        :privileges="['CREATE_WORKER']"
        class="text-right"
        component="div"
      >
        <router-link
          :to="{name: 'worker_create_view'}"
          class="btn btn-success"
        >
          <i class="fa fa-plus" /> Dodaj pracownika
        </router-link>
      </is-granted>
      <form
        class="form-row"
        @submit.prevent="submit"
      >
        <b-form-group
          label-for="fullName"
          class="col-lg-6"
          label="Specjalista"
        >
          <b-form-input
            id="fullName"
            v-model="currentName"
            placeholder="Imię lub nazwisko"
            autofocus
          />
        </b-form-group>
        <b-form-group
          label-for="active"
          class="col-sm-6 col-lg-2"
          label="Aktywny"
        >
          <boolean-refusal-select
            id="active"
            v-model="currentActive"
            :only-boolean="true"
            name="activeStatus"
          />
        </b-form-group>
        <b-form-group
          label-for="blocked"
          class="col-sm-6 col-lg-2"
          label="Zablokowany"
        >
          <boolean-refusal-select
            id="blocked"
            v-model="currentBlocked"
            :only-boolean="true"
            name="blockedStatus"
          />
        </b-form-group>
        <div class="col-lg-2 no-label">
          <button
            type="submit"
            class="btn btn-primary"
          >
            <i class="fa fa-search" /> Szukaj
          </button>
        </div>
      </form>
    </div>
    <card
      ref="content"
      :loading="loading"
      title=""
    >
      <pagination-description
        :items-count="items.length"
        :page="currentPage"
        :per-page="pagination.perPage"
        :total-count="pagination.totalRows"
      />
      <div class="table-responsive mh-170">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">
                Imię i nazwisko
              </th>
              <th scope="col">
                Telefon
              </th>
              <th
                scope="col"
                class="d-none d-md-table-cell"
              >
                E-mail
              </th>
              <th scope="col">
                Komórka organizacyjna
              </th>
              <th
                scope="col"
                class="text-center"
              >
                Aktywny
              </th>
              <th
                scope="col"
                class="text-center"
              >
                Zablokowany
              </th>
              <th scope="col" />
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="worker in items"
              :key="worker.workerId"
            >
              <td>
                {{ worker.academicDegreeTitle }} {{ worker.name }} {{ worker.surname }}
                <span
                  v-if="!worker.active"
                >
                  (konto nieaktywne)
                </span>
              </td>
              <td>{{ worker.phoneNumber }}</td>
              <td class="text-left d-none d-md-table-cell">
                {{ worker.personalEmail }}
              </td>
              <td>{{ worker.branchName }}</td>
              <td 
                :style="`color:${worker.active ? 'green' : 'red' }`"
                class="text-center"
              >
                <i
                  :class="worker.active ? 'fa-check': 'fa-times'"
                  class="fa"
                />
              </td>
              <td
                :class="worker.blocked ? 'text-danger' : 'text-light'"
                class="text-center"
              >
                <i
                  :class="worker.blocked ? 'fa-ban' : 'fa-minus'"
                  class="fa"
                />
              </td>
              <td class="text-right">
                <is-granted
                  :privileges="[
                    'UPDATE_WORKER',
                    'WORKER_WORKING_HOURS_VIEW',
                    'REQUEST_PASSWORD_CHANGE',
                    'UNBLOCK_USER',
                    'UPDATE_WORKER_INSURANCE_PROVIDER_PASSWORD',
                    'EDIT_INSURANCE_ACCESS_PROVIDER',
                  ]"
                  :subject="worker.workerId"
                  component="fragment"
                >
                  <b-dropdown
                    size="sm"
                    style="width: 6rem"
                    text="Opcje"
                    variant="outline-primary"
                  >
                    <is-granted
                      :privileges="['UPDATE_WORKER']"
                      :subject="worker.workerId"
                      component="fragment"
                    >
                      <b-dropdown-item :to="{name: 'workerUpdateView', params: {workerId: worker.workerId}}">
                        <i class="mr-1 fas fa-pencil-alt" /> Dane pracownika
                      </b-dropdown-item>
                    </is-granted>
                    <is-granted
                      :privileges="['WORKER_WORKING_HOURS_VIEW']"
                      :subject="worker.workerId"
                      component="fragment"
                    >
                      <b-dropdown-item :to="{name: 'workersWorkingHoursPage', params: {workerId: worker.workerId}}">
                        <i class="mr-1 fas fa-calendar-alt" /> Harmonogram
                      </b-dropdown-item>
                    </is-granted>
                    <is-granted
                      v-if="worker.userId"
                      :privileges="['REQUEST_PASSWORD_CHANGE']"
                      component="fragment"
                    >
                      <b-dropdown-item :to="{name: 'request_password_change', params: {workerId: worker.workerId}}">
                        <i class="mr-1 fas fa-eraser" /> Resetuj hasło
                      </b-dropdown-item>
                    </is-granted>
                    <is-granted
                      v-if="worker.blocked"
                      :privileges="['UNBLOCK_USER']"
                      component="fragment"
                    >
                      <b-dropdown-item-btn
                        v-b-modal.unblockConfirmation
                        @click="openUnblockConfirmation(worker)"
                      >
                        <i class="mr-1 fas fa-lock-open" /> Odblokuj konto pracownika
                      </b-dropdown-item-btn>
                    </is-granted>
                    <is-granted
                      :privileges="['UPDATE_WORKER_INSURANCE_PROVIDER_PASSWORD']"
                      :subject="worker.workerId"
                      component="fragment"
                    >
                      <b-dropdown-item-btn
                        v-b-modal.updateWorkerInsurancePasswordModal
                        @click="openResetInsurancePassword(worker)"
                      >
                        <i class="mr-1 fas fa-key" /> Resetuj hasło w systemie eWUŚ
                      </b-dropdown-item-btn>
                    </is-granted>
                    <is-granted
                      :privileges="['EDIT_INSURANCE_ACCESS_PROVIDER']"
                      :subject="worker.workerId"
                      component="fragment"
                    >
                      <b-dropdown-item :to="{name: 'workerEwusAccess', params: {workerId: worker.workerId}}">
                        <i class="mr-1 fas fa-cogs" /> Dostęp do systemu eWUŚ
                      </b-dropdown-item>
                    </is-granted>
                  </b-dropdown>
                </is-granted>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <p
        v-if="0 === items.length"
        class="text-center"
      >
        Brak wyników do wyświetlenia
      </p>
      <pagination-description
        :items-count="items.length"
        :page="currentPage"
        :per-page="pagination.perPage"
        :total-count="pagination.totalRows"
      >
        <b-pagination
          v-model="currentPage"
          :per-page="pagination.perPage"
          :total-rows="pagination.totalRows"
          align="right"
          class="mb-0"
          @input="changePage"
        />
      </pagination-description>
    </card>
    <unblock-user-confirmation
      :worker="workerToUnblock"
      @workerUnblocked="fetchData"
    />
    <UpdateWorkerInsurancePasswordModal
      :worker-id="workerId"
    />
  </div>
</template>
<script>
import UnblockUserConfirmation from "../../components/Worker/UnblockUserConfirmation";
import UpdateWorkerInsurancePasswordModal from "../../components/Worker/UpdateWorkerInsurancePasswordModal";
import IsGranted from "../../components/IsGranted";
import read from "../../rest/read";
import Card from "../../components/Card";
import PaginationDescription from "../../components/Pagination/PaginationDescription";
import {changePageQuery} from "@/utils/pageUrl/handlePageQuery";
import BooleanRefusalSelect from "../../components/Form/Select/BooleanRefusalSelect";
import isEqual from "lodash/isEqual";

export default {
  components: {
    BooleanRefusalSelect,
    PaginationDescription,
    Card,
    IsGranted,
    UnblockUserConfirmation,
    UpdateWorkerInsurancePasswordModal
  },
  props: {
    page: {type: Number, default: null},
    fullName: {type: String, default: ""},
    active: {type: Boolean, default: null},
    blocked: {type: Boolean, default: null},
  },
  data() {
    return {
      items: [],
      currentPage: this.page || 1,
      currentName: this.fullName,
      currentActive: this.active,
      currentBlocked: this.blocked,
      pagination: {},
      workerToUnblock: {},
      workerId: null,
      loading: false,
    }
  },
  computed: {
    filters() {
      return {
        page: this.currentPage,
        perPage: this.pagination.perPage,
        fullName: this.currentName,
        active: this.currentActive,
        blocked: this.currentBlocked,
      };
    },
    query() {
      return {
        page: this.currentPage,
        fullName: this.currentName || undefined,
        active: this.currentActive !== null ? this.currentActive : undefined,
        blocked: this.currentBlocked !== null ? this.currentBlocked : undefined,
      };
    },
  },
  watch: {
    page(val) {
      this.currentPage = val || 1;
    },
    async "$route"() {
      this.fetchData();
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    openUnblockConfirmation(worker) {
      this.workerToUnblock = worker;
    },
    openResetInsurancePassword(worker) {
      this.workerId = worker.workerId;
    },
    async fetchData() {
      this.loading = true;
      this.items = [];
      const {items, pagination} = await read("/api/workers", this.filters);
      this.items = items;
      this.pagination = pagination;
      this.loading = false;
    },
    updatePageUrl() {
      if (isEqual(this.$route.query, this.query)) {
        this.fetchData();
        return;
      }
      changePageQuery(this.query);
    },
    async changePage(page) {
      this.currentPage = page;
      this.updatePageUrl();
    },
    submit() {
      this.currentPage = 1;
      this.updatePageUrl();
    },
  }
}
</script>

<style scoped lang="scss">
  // fix tooltip bug
  .mh-170 {
    min-height: 170px;
  }
</style>
