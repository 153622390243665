<template>
  <card
    :title="title"
    :loading="loading"
    :description="description"
  >
    <patient-data-unification-filters
      :patient="patient"
      :statuses="statuses"
      :workers="workers"
      :case-managers="caseManagers"
      @reload="loadItems"
    />
    <pagination-description
      :items-count="items.length"
      :page="currentPage"
      :per-page="pagination.perPage"
      :total-count="pagination.totalRows"
    />
    <patient-data-unification-list
      :items="items"
      :columns="columns"
    />
    <pagination-description
      :items-count="items.length"
      :page="currentPage"
      :per-page="pagination.perPage"
      :total-count="pagination.totalRows"
    >
      <b-pagination
        :value="currentPage"
        :total-rows="pagination.totalRows"
        :per-page="pagination.perPage"
        align="right"
        class="mb-0"
        @input="changePage"
      />
    </pagination-description>
    <template #control>
      <slot name="control" />
    </template>
  </card>
</template>

<script>
import PatientDataUnificationList from "./PatientDataUnificationList";
import PatientDataUnificationFilters from "./PatientDataUnificationFilters";
import Card from "../../Card";
import read from "../../../rest/read";
import PaginationDescription from "../../Pagination/PaginationDescription";
import {updatePageQuery} from "../../../utils/pageUrl/handlePageQuery";

export default {
  name: "PatientDataUnificationCard",
  components: {PaginationDescription, Card, PatientDataUnificationList, PatientDataUnificationFilters},
  props: {
    title: {type: String, required: true},
    description: {type: String, default: null},
    columns: {type: Array, required: true},
    patient: {type: String, default: null},
    statuses: {type: Array, default: ()=>[]},
    caseManagers: {type: Array, default: ()=>[]},
    workers: {type: Array, default: ()=>[]},
    page: {type: Number, default: null},
  },
  data() {
    return {
      loading: false,
      currentPage: this.page || 1,
      items: [],
      pagination: {perPage: 10},
    }
  },
  computed: {
    filters() {
      return {
        patient: this.patient,
        statuses: this.statuses,
        caseManagersIds: this.caseManagers,
        workerIds: this.workers,
        perPage: this.pagination.perPage,
        page: this.currentPage,
      }
    },
  },
  watch: {
    page(val) {
      this.currentPage = val || 1;
    },
    "$route"() {
      this.loadItems();
    },
  },
  mounted() {
    this.loadItems();
  },
  methods: {
    changePage(page) {
      this.currentPage = page;
      updatePageQuery({page: this.currentPage});
    },
    async loadItems() {
      this.loading = true;
      const {items, pagination} = await read("/api/data-unification", this.filters);
      this.items = items;
      this.pagination = pagination;
      this.loading = false;
    },
  },
}
</script>
