import {MutationTree} from "vuex";
import {ScheduleAppointmentState} from "./types";

export const mutations: MutationTree<ScheduleAppointmentState> = {
  openModal(state, modalType) {
    state.modalType = modalType;
    state.modalOpened = true;
  },
  closeModal(state) {
    state.modalOpened = false;
    state.modalType = null;
  },
  setAppointmentData(state, data) {
    state.appointmentData = data;
  },
  resetAppointmentData(state) {
    state.appointmentData = null;
  },
  openSummaryModal(state) {
    state.summaryModalOpened = true;
  },
  closeSummaryModal(state) {
    state.summaryModalOpened = false;
  },
  updateAppointmentDetails(state, data) {
    state.appointmentDetails = data;
  },
  resetAppointmentDetails(state) {
    state.appointmentDetails = null;
  },
  step(state) {
    state.scheduleStep += 1;
  },
  stepBack(state) {
    state.scheduleStep -= 1;
  },
  stepReset(state) {
    state.scheduleStep = 0;
  },
};
