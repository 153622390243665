<template>
  <img
    src="../../images/UE_EFS.png"
    class="ue-image"
    @click="openUeModal"
  >
</template>

<script>
export default {
  name: "UeLogotype",
  methods: {
    openUeModal() {
      this.$bvModal.show("ue-modal");
    },
  },
}
</script>

<style scoped lang="scss">
  $image-height: 2rem;
  .ue-image {
    box-sizing: content-box;
    height: $image-height;
    cursor: pointer;
    background-color: #fff;
    padding: calc(1 / 25 * #{$image-height});
  }
</style>
