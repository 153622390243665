





























































































import {Component, Mixins, Prop, Watch} from "vue-property-decorator";
import {AcceptedReferral} from "../Patient/PatientCard/PatientCardAcceptedReferrals.vue";
import {BvTableFieldArray} from "bootstrap-vue/src/components/table";
import update from "../../rest/update";
import processResponseException from "../../utils/errors/processResponseException";
import ErrorsMixin from "../../mixins/ErrorsMixin";
import ErrorMessage from "../Form/ErrorMessage.vue";
import create from "../../rest/create";

@Component({
  name: AcceptedReferralsTable.name,
  components: {ErrorMessage,},
})
export default class AcceptedReferralsTable extends Mixins(ErrorsMixin) {
  @Prop({type: Array, default: ()=>[]}) readonly items!: AcceptedReferral[];
  @Prop({type: String, default: null}) readonly appointmentDocumentId!: string;

  private selectedId: string | null = null;
  private loading: boolean = false;
  private action: string | null = null;

  private async unassign(id: string, appointmentDocumentId: string): Promise<void> {
    this.action = "unassign";
    this.loading = true;
    this.selectedId = id;
    this.errors = [];
    try {
      await create("/api/unassign-referral", {
        acceptedReferralId: id,
        appointmentDocumentId,
      });
      this.$emit("refresh");
    }catch(e) {
      this.errors = processResponseException(e);
    }
    this.loading = false;
    this.selectedId = null;
    this.action = null;
  }

  private async complete(id: string): Promise<void> {
    this.action = "complete";
    this.loading = true;
    this.selectedId = id;
    this.errors = [];
    try {
      await update("/api/complete-referral", {
        acceptedReferralId: id
      });
      this.$emit("refresh");
    }catch(e) {
      this.errors = processResponseException(e);
    }
    this.loading = false;
    this.selectedId = null;
    this.action = null;
  }

  private get fields(): BvTableFieldArray {
    const fields = [
      {key: "creator", label: "Przyjął"},
      {key: "extension", label: "Numer skierowania"},
      {key: "createdAt", label: "Data przyjęcia e-skierowania"},
      {key: "actions", label: "Realizacja"},
    ];
    if(!this.appointmentDocumentId) {
      return [...fields,
        {key: "appointmentId", label: "Id wizyty"},
        {key: "doctor", label: "Specjalista"},
        {key: "scheduledDate", label: "Data wizyty"},
        fields.pop() as {key: string, label: string}, // remove actions and set at last,
      ];
    }
    return fields;
  }

  @Watch("items") private async onItemsChange(): Promise<void> {
    this.errors = [];
  }

}

