




























































































































































































































































import type {EstablishmentItem} from "../../types/Establishment";
import {EstablishmentType} from "../../types/Establishment";
import {Component, Emit, Prop} from "vue-property-decorator";
import Vue from "vue";
import Tooltip from "../Tooltip.vue";
import I18n from "../i18n.vue";
import AddEstablishmentChildForm from "./AddEstablishmentChildForm.vue";
import EditEstablishmentChildForm from "./EditEstablishmentChildForm.vue";
import t from "../../i18n";
import EstablishmentGeolocalizationModal from "./EstablishmentGeolocalizationModal.vue";
import EstablishmentCertificatesModal from "./EstablishmentCertificatesModal.vue";
import IsGranted from "../IsGranted.vue";
import EstablishmentP1DataModal from "./EstablishmentP1DataModal.vue";
import EstablishmentSmtpModal from "./EstablishmentSmtpModal.vue";

@Component({
  name: "EstablishmentTreeView",
  components: {
    EstablishmentSmtpModal,
    EstablishmentP1DataModal,
    IsGranted,
    EstablishmentCertificatesModal,
    EstablishmentGeolocalizationModal,
    EditEstablishmentChildForm,
    AddEstablishmentChildForm,
    I18n,
    Tooltip,
  },
})

export default class EstablishmentTreeView extends Vue {
  @Prop({type: String, required: true}) readonly name!: EstablishmentItem["name"];
  @Prop({type: String, required: true}) readonly branchId!: EstablishmentItem["branchId"];
  @Prop({type: String, required: true}) readonly type!: EstablishmentItem["type"];
  @Prop({type: String, required: false}) readonly code!: EstablishmentItem["code"];
  @Prop({type: String, required: false}) readonly parentId!: EstablishmentItem["parentId"];
  @Prop({type: Array, required: false}) readonly children!: EstablishmentItem["children"];
  @Prop({type: Object, required: false}) readonly address!: EstablishmentItem["address"];
  @Prop({type: String, required: false}) readonly nip!: EstablishmentItem["nip"];
  @Prop({type: String, required: false}) readonly regon!: EstablishmentItem["regon"];
  @Prop({type: String, required: false}) readonly phoneNumber!: EstablishmentItem["phoneNumber"];
  @Prop({type: String, required: false}) readonly codeEight!: EstablishmentItem["codeEight"];
  @Prop({type: String, required: false}) readonly shortName!: EstablishmentItem["shortName"];
  @Prop({type: String, required: false}) readonly krs!: EstablishmentItem["krs"];
  @Prop({type: Object, required: false}) readonly parameters!: EstablishmentItem["parameters"];
  @Prop({type: Object, required: false}) readonly geolocalization!: EstablishmentItem["geolocalization"];
  @Prop({type: Boolean, default: false}) readonly editing!: boolean;

  t = t;
  updatedItem: Partial<EstablishmentItem> | null = null;
  addFormVisible: boolean = false;
  editFormVisible: boolean = false;
  lastChildHeight: string = "0px";
  elementHeight: number = 0;

  private get hasChildren(): boolean {
    return !!this.children?.length;
  }

  private get noChildren(): boolean {
    switch(this.type) {
      case EstablishmentType.NON_MEDICAL:
      case EstablishmentType.UNIT:
        return true;
      default:
        return false;
    }
  }

  get hasParent(): boolean {
    return !!this.parentId;
  }

  get translateNew(): string {
    switch (this.childType) {
      case EstablishmentType.INSTITUTE:
        return "Nowy";
      case EstablishmentType.DIVISION:
      case EstablishmentType.UNIT:
        return "Nowa";
      default:
        return "";
    }
  }

  get childType(): EstablishmentItem["type"]|null {
    switch (this.type) {
      case EstablishmentType.ESTABLISHMENT:
        return EstablishmentType.INSTITUTE;
      case EstablishmentType.INSTITUTE:
        return EstablishmentType.DIVISION;
      case EstablishmentType.DIVISION:
        return EstablishmentType.UNIT;
      default:
        return null;
    }
  }

  get establishmentIconClass(): string {
    switch (this.type) {
      case EstablishmentType.ESTABLISHMENT:
        return "fa-hospital-alt";
      case EstablishmentType.NON_MEDICAL:
        return "fa-hotel";
      case EstablishmentType.INSTITUTE:
        return "fa-hospital";
      case EstablishmentType.DIVISION:
        return "fa-clinic-medical";
      default:
        return "fa-hospital-user";
    }
  }

  get treeViewLineHeight() {
    return this.elementHeight && this.lastChildHeight
      ? `calc(${this.elementHeight}px - 8px - ${this.lastChildHeight})`
      : "100%";
  }

  mounted() {
    this.$nextTick(() => {
      this.handleTreeLineHeight();
    })
  }

  updated() {
    setTimeout(() => {
      this.handleTreeLineHeight();
    }, 800)
  }

  addChild(item: Partial<EstablishmentItem> | null): void {
    this.updatedItem = item;
    this.addFormVisible = true;
    this.editingToggle();
  }

  editChild(item: EstablishmentItem | null): void {
    this.updatedItem = item;
    this.editFormVisible = true;
    this.editingToggle();
  }

  update(): void {
    this.$emit("update");
  }

  addFormClose(): void {
    this.updatedItem = null;
    this.addFormVisible = false;
    this.editingToggle();
  }

  editFormClose(): void {
    this.updatedItem = null;
    this.editFormVisible = false;
    this.editingToggle();
  }

  editingToggle() {
    this.$emit("editingToggle");
  }

  setLastChildHeight() {
    if (this.hasChildren) {
      const selector = `#id${this.branchId} .${this.childType}:last-of-type`;
      const child = document.querySelector(selector);
      if (child) {
        const childStyles = window.getComputedStyle(child);
        this.lastChildHeight = childStyles.getPropertyValue("height");
      }
    }
  }

  setElementHeight() {
    this.elementHeight = this.$el.clientHeight;
  }

  handleTreeLineHeight() {
    this.setElementHeight();
    this.setLastChildHeight();
  }

  componentUpdate() {
    this.$emit("componentUpdate");
    this.handleTreeLineHeight();
  }

  @Emit()
  removeChild(updatedItem: EstablishmentItem | null): EstablishmentItem | null {
    return updatedItem;
  }
}
