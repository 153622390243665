<template>
  <card
    :loading="loading"
    title="Pobyt ciągły"
  >
    <template #control>
      <is-granted
        :privileges="['CREATE_CONTINUOUS_STAY']"
      >
        <b-btn
          v-b-modal.createContinuousStayModal
          size="sm"
          variant="primary"
        >
          <i class="fa fa-plus" /> Dodaj pobyt ciągły
        </b-btn>
      </is-granted>
    </template>
    <b-table
      :items="stays"
      :fields="fields"
      empty-text="Brak dodanych pobytów"
      :responsive="true"
      show-empty
    >
      <template #cell(continuousStayCategory)="data">
        <i18n
          component="fragment"
          :msgid="`@continuousStay.${data.item.continuousStayCategory}`"
        />
      </template>
      <template #cell(startDate)="data">
        <date-time
          :value="parseDate(data.item.startDate, DATE_FORMAT.DATE_TIME_SEC)"
          :format="DATE_FORMAT.DATE"
        />
      </template>
      <template #cell(endDate)="data">
        <date-time
          v-if="data.item.endDate"
          :value="parseDate(data.item.endDate, DATE_FORMAT.DATE_TIME_SEC)"
          :format="DATE_FORMAT.DATE"
        />
      </template>
      <template #cell(continuousStayStatus)="{item: {continuousStayStatus: status}}">
        <b-badge
          class="status-badge"
          :variant="getBadgeVariant(status)"
        >
          <i18n :msgid="`@continuousStay.${status}`" />
        </b-badge>
      </template>
      <template #cell(workerFullName)="data">
        {{ data.item.workerFullName }}
      </template>
      <template #cell(options)="data">
        <is-granted
          :privileges="['CONTINUOUS_STAY_EDIT_VIEW']"
          :subject="data.item.continuousStayId"
          component="fragment"
        >
          <router-link
            class="btn btn-sm btn-secondary m-1"
            :to="editViewPath(data.item.continuousStayId)"
          >
            <span class="fa fa-eye mr-1" /> Szczegóły
          </router-link>
        </is-granted>
        <is-granted
          :privileges="[
            'CLOSE_CONTINUOUS_STAY',
            'REOPEN_CONTINUOUS_STAY',
            'CANCEL_CONTINUOUS_STAY',
            'PRINT_CONTINUOUS_STAY',
          ]"
          :subject="data.item.continuousStayId"
          component="fragment"
        >
          <b-dropdown
            boundary="$el"
            class="text-left m-1"
            right
            size="sm"
            style="width: 6rem"
            text="Opcje"
            variant="outline-secondary"
          >
            <is-granted
              :privileges="['CLOSE_CONTINUOUS_STAY']"
              :subject="data.item.continuousStayId"
              component="fragment"
            >
              <b-dropdown-item-btn
                v-b-modal.closeContinuousStayModal
                @click="selectContinuousStay(data.item)"
              >
                <span class="fa fa-lock mr-1" /> Zakończ pobyt
              </b-dropdown-item-btn>
            </is-granted>
            <is-granted
              :privileges="['REOPEN_CONTINUOUS_STAY']"
              :subject="data.item.continuousStayId"
              component="fragment"
            >
              <b-dropdown-item-btn
                v-b-modal.reopenContinuousStayModal
                @click="selectContinuousStay(data.item)"
              >
                <i class="fa fa-unlock mr-1" /> Otwórz pobyt ponownie
              </b-dropdown-item-btn>
            </is-granted>
            <is-granted
              :privileges="['CANCEL_CONTINUOUS_STAY']"
              :subject="data.item.continuousStayId"
              component="fragment"
            >
              <b-dropdown-item-btn
                v-b-modal.cancelContinuousStayModal
                @click="selectContinuousStay(data.item)"
              >
                <i class="fa fa-ban mr-1" /> Odwołaj pobyt
              </b-dropdown-item-btn>
            </is-granted>
            <is-granted
              :privileges="['PRINT_CONTINUOUS_STAY']"
              :subject="data.item.continuousStayId"
              component="fragment"
            >
              <b-dropdown-item
                :href="`/api/continuous-stays/${data.item.continuousStayId}/print`"
                target="_blank"
              >
                <i class="fa fa-print mr-1" /> Drukuj podsumowanie
              </b-dropdown-item>
            </is-granted>
          </b-dropdown>
        </is-granted>
      </template>
    </b-table>
    <pagination-description
      :items-count="stays.length"
      :page="page"
      :per-page="pagination.perPage"
      :total-count="pagination.totalRows"
    >
      <b-pagination
        :value="page"
        :total-rows="pagination.totalRows"
        :per-page="pagination.perPage"
        align="right"
        class="mb-0"
        @input="changePage"
      />
    </pagination-description>
    <patient-card-create-continuous-stay
      :patient-id="patientId"
      @created="loadContinuousStays"
    />
    <patient-card-cancel-continuous-stay
      :patient-id="patientId"
      :continuous-stay-id="selectedContinuousStayId"
      @canceled="loadContinuousStays"
    />
    <patient-card-close-continuous-stay
      :patient-id="patientId"
      :continuous-stay-id="selectedContinuousStayId"
      :start="selectedStartDate"
      :end="selectedEndDate"
      @closed="loadContinuousStays"
    />
    <reopen-continuous-stay
      :patient-id="patientId"
      :continuous-stay-id="selectedContinuousStayId"
      @reopened="loadContinuousStays"
    />
  </card>
</template>



<script>
import read from "../../../../rest/read";
import PatientCardCreateContinuousStay from "./PatientCardCreateContinuousStay";
import PatientCardCancelContinuousStay from "./PatientCardCancelContinuousStay";
import I18n from "../../../i18n";
import PatientCardCloseContinuousStay from "./PatientCardCloseContinuousStay";
import parseDate from "../../../../utils/date/parseDate";
import DATE_FORMAT from "../../../../utils/date/DATE_FORMAT";
import DateTime from "../../../DateTime";
import Card from "../../../Card";
import IsGranted from "../../../IsGranted";
import ReopenContinuousStay from "./ReopenContinuousStay";
import PaginationDescription from "../../../Pagination/PaginationDescription";

export default {
  components: {
    PaginationDescription,
    ReopenContinuousStay,
    IsGranted,
    Card,
    DateTime,
    PatientCardCloseContinuousStay,
    I18n,
    PatientCardCreateContinuousStay,
    PatientCardCancelContinuousStay,
  },
  props: {
    patientId: {type: String, required: true},
  },
  data() {
    return {
      parseDate: parseDate,
      DATE_FORMAT: DATE_FORMAT,
      loading: true,
      selectedContinuousStayId: "",
      selectedStartDate: null,
      selectedEndDate: null,
      page: 1,
      pagination: {perPage: 5, totalRows: 0},
      stays: [],
      fields: [
        {key: "continuousStayCategory", label: "Kategoria"},
        {key: "continuousStayStatus", label: "Status"},
        {key: "startDate", label: "Data rozpoczęcia"},
        {key: "endDate", label: "Data zakończenia"},
        {key: "workerFullName", label: "Otworzył"},
        {key: "options", label: "", class: "text-right"},
      ],
    };
  },
  watch: {
    patientId() {
      this.page = 1;
      this.loadContinuousStays();
    },
  },
  mounted() {
    this.loadContinuousStays();
  },
  methods: {
    async loadContinuousStays() {
      this.loading = true;
      const {items, pagination} = await read(`/api/patients/${this.patientId}/continuous-stays`, {
        page: this.page,
        perPage: this.pagination.perPage,
      });
      this.pagination = pagination;
      this.stays = items;
      this.loading = false;
      this.selectedStartDate = null;
      this.selectedEndDate = null;
    },
    selectContinuousStay(item) {
      this.selectedEndDate = item.endDate;
      this.selectedStartDate = item.startDate;
      this.selectedContinuousStayId = item.continuousStayId;
    },
    editViewPath(continuousStayId) {
      return {name: "continuous_stay_edit", params: {continuousStayId}};
    },
    changePage(page) {
      this.page = page;
      this.loadContinuousStays();
    },
    getBadgeVariant(status) {
      switch(status) {
        case "in_progress":
          return "success";
        case "closed":
          return "primary";
        case "canceled":
          return "danger";
        default:
          throw Error(`Not supported status: ${status}`);
      }
    },
  },
}
</script>

<style scoped lang="scss">
.status-badge{
  width: 5rem;
}
</style>
