export default {
  functional: true,
  name: "Fragment",
  render(h, ctx) {
    if (ctx.children.length) {
      return ctx.children[0];
    }
    return ctx.children;
  },
};
