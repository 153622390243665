



























import Vue from "vue";
import {Prop, Ref} from "vue-property-decorator";
import ObjectSelect from "../Form/Select/ObjectSelect.vue";
import Component from "vue-class-component";
import {Error} from "../../rest";
import {BFormInput} from "bootstrap-vue";

@Component({
  name: "MedicationDoseQuantity",
  components: {ObjectSelect},
})
export default class MedicationDoseQuantity extends Vue {
  @Prop({type: Number, default: null}) readonly value!: number;
  @Prop({type: Boolean, default: false}) readonly disabled!: boolean;
  @Prop({type: Array, default: ()=>[]}) readonly errors!: Array<Error>;

  @Ref("input") readonly input!: BFormInput;

  private edited: boolean = false;

  private get isInvalid(): boolean {
    return !!this.errors.find((error) => error.field === "doseQuantity");
  }

  private get editable(): boolean {
    return !this.disabled && !this.edited;
  }

  private update(value: string|null): void {
    const newVal = value != null ? parseFloat(value) : value;
    this.$emit("input", (newVal != null && !isNaN(newVal)) ? newVal : null);
  }

  private startEdit(): void {
    if (!this.disabled) {
      this.edited = true;
      this.$nextTick(() => this.input.focus());
    }
  }

  private stopEdit(): void {
    this.edited = false;
  }
}
