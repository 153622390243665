














import {Component, Mixins, Prop, Watch} from "vue-property-decorator";
import type {
  Filters,
  Worker,
  WorkerAvailabilitiesParams,
  WorkersAvailabilitiesData,
  Item,
  Day,
} from "../../types/RegistrationSearchTypes";
import ErrorMessage from "../Form/ErrorMessage.vue";
import ErrorsMixin from "../../mixins/ErrorsMixin";
import Card from "../Card.vue";
import stringifyDate from "../../utils/date/stringifyDate";
import DATE_FORMAT from "../../utils/date/DATE_FORMAT";
import read from "../../rest/read";
import processResponseException from "../../utils/errors/processResponseException";
import DateTime from "../DateTime.vue";
import days from "../../utils/date/days";
import RegistrationSearchList from "./RegistrationSearchList.vue";

@Component({
  name: "RegistrationSearchWidget",
  components: {RegistrationSearchList, DateTime, Card, ErrorMessage},
})
export default class RegistrationSearchWidget extends Mixins(ErrorsMixin) {
  @Prop({type: Object, required: true}) readonly filters!: Filters;

  private loading: boolean = false;
  private items: Array<Item> = [];
  private workers: Array<Worker> = [];

  private get itemsByDay(): Array<Day> {
    return this.items.reduce<Day[]>((daysList, item) => {
      const itemLabel = `${stringifyDate(item.start, DATE_FORMAT.DATE)} ${days[item.start.getDay()]}`;
      const day = daysList.find(({label}) => label === itemLabel);

      if (null == day) {
        return daysList.concat([
          {label: itemLabel, items: [item]},
        ]);
      }

      return daysList.map((d) => d !== day ? d : {
        label: d.label,
        items: d.items.concat([item])
      });
    }, [])
  }

  @Watch("filters", {deep: true, immediate: true}) private async onFiltersChange(): Promise<void> {
    await this.fetchData();
  }

  private async fetchData(): Promise<void> {
    this.errors = [];
    this.loading = true;

    try {
      const end = this.filters.end ? new Date(this.filters.end) : null;
      if (end) {
        end.setDate(end.getDate() + 1);
      }
      const params: WorkerAvailabilitiesParams = {
        startDate: this.filters.start ? stringifyDate(this.filters.start, DATE_FORMAT.DATE_TIME) : undefined,
        endDate: end ? stringifyDate(end, DATE_FORMAT.DATE_TIME) : undefined,
        workerId: this.filters.workers,
      };

      const {items, workers} = await read<WorkersAvailabilitiesData>("/api/workers/availabilities", params)

      this.workers = workers;
      this.items = items.reduce<Item[]>((list, item) => {
        if (item.appointmentId) {
          return list;
        }
        return [...list, {
          branchName: item.branchName,
          start: new Date(item.start),
          end: new Date(item.end),
          worker: this.workers.find(({workerId}) => workerId === item.workerId)
        }];
      }, []);
    } catch(e) {
      this.workers = [];
      this.items = [];
      this.errors = processResponseException(e);
    }
    this.loading = false;
  }
}
