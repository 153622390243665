<template>
  <div class="card-box">
    <table class="table">
      <thead>
        <tr>
          <th scope="col">
            Nazwa
          </th>
          <th scope="col">
            Długość trwania lokalnie/zdalnie
          </th>
          <th scope="col">
            Aktywna
          </th>
          <th scope="col">
            Szablon dokumentu
          </th>
          <th scope="col">
            Klasyfikacja
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="treatmentType in treatmentTypes"
          :key="treatmentType.value"
        >
          <td>{{ treatmentType.name }}</td>
          <td>
            {{ treatmentType.durationLocally ? treatmentType.durationLocally : 'brak' }}
            /
            {{ treatmentType.durationRemotely ? treatmentType.durationRemotely : 'brak' }}
          </td>
          <td>{{ treatmentType.active ? 'tak' : 'nie' }}</td>
          <td>{{ treatmentType.appointmentDocumentTemplate }}</td>
          <td>
            <i18n
              :msgid="treatmentType.classification ?
                `@treatmentTypeClassification.${treatmentType.classification}` : 'brak'"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import I18n from "../i18n";
export default {
  components: {I18n},
  props: {
    treatmentTypes: {type: Array, required: true},
  }
}
</script>
