<template>
  <is-granted
    :privileges="['REPORT_APPOINTMENT_MONTHLY_STATS']"
    component="div"
  >
    <report-container report-id="monthly-stats">
      <appointment-monthly-stats-filters
        :date-from="filters.dateFrom"
        :date-to="filters.dateTo"
        :statuses="filters.statuses"
        class="card-box m-b-5"
        @submit="executeFilters"
        @generatePDF="generatePDF"
        @generateXLSX="generateXLSX"
      />
      <loading-mask :loading="loading">
        <h3 class="text-center m-t-10">
          {{ stringDateFrom }} - {{ stringDateTo }}
        </h3>
        <appointment-monthly-stats-list
          :items="currentItems"
          :summary="currentSummary"
          class="card-box"
        />
      </loading-mask>
    </report-container>
  </is-granted>
</template>

<script>
import AppointmentMonthlyStatsFilters from "../../components/Report/AppointmentMonthlyStatsFilters";
import AppointmentMonthlyStatsList from "../../components/Report/AppointmentMonthlyStatsList";
import IsGranted from "../../components/IsGranted";
import {createUrl} from "../../utils/pageUrl/createUrl";
import stringifyDate from "../../utils/date/stringifyDate";
import DATE_FORMAT from "../../utils/date/DATE_FORMAT";
import read from "../../rest/read";
import BadRequestException from "../../rest/index"
import LoadingMask from "../../components/Loading/LoadingMask";
import {changePageQuery} from "../../utils/pageUrl/handlePageQuery";
import isEqual from "lodash/isEqual";
import ReportContainer from "../../components/Report/ReportContainer";

export default {
  name: "AppointmentMonthlyStats",
  components: {
    ReportContainer,
    LoadingMask, AppointmentMonthlyStatsFilters, IsGranted, AppointmentMonthlyStatsList},
  props: {
    dateFrom: {type: String, default: null},
    dateTo: {type: String, default: null},
    statuses: {type: Array, default: () => []},
  },
  data() {
    const cd = new Date();
    return {
      currentItems: [],
      currentSummary: this.summary,
      filters: {
        dateFrom: this.dateFrom ? new Date(this.dateFrom) : new Date(cd.getFullYear(), cd.getMonth(), 1),
        dateTo: this.dateTo ? new Date(this.dateTo) : new Date(),
        statuses: this.statuses
      },
      summary: null,
      loading: false,
    };
  },
  computed: {
    parsedFilters(){
      return {
        dateFrom: this.stringDateFrom,
        dateTo: this.stringDateTo,
        statuses: this.filters.statuses,
      }
    },
    stringDateFrom() {
      if (!this.filters.dateFrom) {
        return null;
      }
      return stringifyDate(this.filters.dateFrom, DATE_FORMAT.DATE);
    },
    stringDateTo() {
      if (!this.filters.dateTo) {
        return null;
      }
      return stringifyDate(this.filters.dateTo, DATE_FORMAT.DATE);
    },
  },
  watch: {
    "$route"() {
      this.getAppointmentMonthlyStats();
    },
  },
  async mounted() {
    await this.getAppointmentMonthlyStats();
  },
  methods: {
    async getAppointmentMonthlyStats() {
      try {
        this.loading = true;
        const {items, summary} = await read("/api/reports/appointment-monthly-stats", this.parsedFilters);
        this.currentItems = items;
        this.currentSummary = summary;
      } catch (exception) {
        if (exception instanceof BadRequestException) {
          this.currentItems = [];
        } else {
          throw exception;
        }
      }
      this.loading = false;
    },
    executeFilters(data) {
      this.updateFilters(data);
      this.updatePageUrl();
    },
    updatePageUrl() {
      const query = {
        ...this.parsedFilters,
        statuses: this.parsedFilters.statuses.join(",") || undefined,
      };
      if (isEqual(this.$route.query, query)) {
        this.getAppointmentMonthlyStats();
      }
      changePageQuery(query);
    },
    updateFilters(data) {
      this.filters.dateFrom = data.dateFrom;
      this.filters.dateTo = data.dateTo;
      this.filters.statuses = data.statuses;
    },
    generatePDF(data){
      this.executeFilters(data);
      window.open(createUrl("/api/reports/appointment-monthly-stats-print", this.parsedFilters), "_blank");
    },
    generateXLSX(data){
      this.executeFilters(data);
      window.open(createUrl("/api/reports/appointment-monthly-stats-print-xlsx", this.parsedFilters), "_blank");
    }
  },
}
</script>
