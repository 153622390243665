<template>
  <div>
    <b-btn
      v-b-modal.participantStatusModal
      variant="primary"
      class="btn-sm"
    >
      Status pacjenta w chwili przystąpienia do projektu
    </b-btn>
    <b-modal
      id="participantStatusModal"
      hide-footer
      size="lg"
      no-close-on-backdrop
      title="Edycja statusu uczestnika"
      title-tag="h3"
    >
      <participant-status-form
        :patient-id="patientId"
        @cancel="cancel"
      />
    </b-modal>
  </div>
</template>

<script>
import ParticipantStatusForm from "../ParticipantStatusForm";

export default {
  components: {
    ParticipantStatusForm
  },
  props: {
    patientId: {type: String, required: true},
  },
  methods: {
    cancel() {
      this.$bvModal.hide("participantStatusModal");
    },
  },

};
</script>
