<template>
  <b-modal
    :visible="visible"
    :title="title"
    hide-footer
    no-close-on-backdrop
    title-tag="h3"
    size="lg"
    @hide="closeModal"
  >
    <template v-if="report">
      <!-- report description -->
      <div>
        <span class="font-bold">
          Opis raportu
        </span>

        <div class="m-3 m-t-10">
          {{ report.description }}
        </div>
      </div>
      <!-- e/o report description -->

      <!-- report legend -->
      <div v-if="report.legendItems">
        <span class="font-bold">
          Opis danych
        </span>

        <div class="m-3 m-t-10">
          <ul class="list">
            <li
              v-for="legentItem in report.legendItems"
              :key="legentItem.name"
            >
              <span class="font-bold">{{ legentItem.name }}</span>
              -
              <span>{{ legentItem.description }}</span>
            </li>
          </ul>
        </div>
      </div>
      <!-- e/o report legend -->

      <!-- reports-schedule-specialist extra info -->
      <div v-if="report.id === 'reports-schedule-specialist'">
        <span class="font-bold">Uwagi</span>

        <div class="m-3 m-t-10">
          Oznaczenia w samym raporcie:
          <ul class="list">
            <li>
              <span class="font-bold">Kolor zielony</span> -
              <span>specjalista pracuje w wybranym czasie</span>
            </li>
            <li>
              <span class="font-bold">Kolor czerwony</span> -
              <span>specjalista nie jest obecny w jednostce</span>
            </li>
            <li>
              <span class="font-bold">Brak koloru</span> -
              <span>brak wprowadzonego harmonogramu na wybrany okres</span>
            </li>
            <li>
              <span class="font-bold">Imię i nazwisko w kwadratowym nawiasie [ ]</span> -
              <span>dane pacjenta, z którym specjalista miał wizytę w konkretnym przedziale czasowym</span>
            </li>
          </ul>
        </div>
      </div>
      <!-- e/o reports-schedule-specialist extra info -->
    </template>
  </b-modal>
</template>

<script>
export default {
  name: "ReportTooltipModal",
  props: {
    report: {type: Object, default: null},
  },
  computed: {
    visible() {
      return this.report !== null;
    },
    title() {
      return this.report ? `Raport - ${this.report.name}` : "";
    },
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
  },
}
</script>

<style scoped>
  .list {
    list-style-type: circle;
    padding-left: 20px;
  }
</style>
