import axios, {AxiosResponse} from "axios";
import {ActionTree} from "vuex";
import {SecurityState} from "./types";
import debounce from "lodash/debounce";
import {RootState} from "../../types";


const queueLimit = 100;
const loadDebounce = debounce(f => f(), 500, {maxWait: 1000});

export const actions: ActionTree<SecurityState, RootState> = {
  subscribe({commit, dispatch}, subscriptions) {
    commit("subscribe", subscriptions);
    loadDebounce(() => dispatch("load"));
  },
  unsubscribe({commit}, subscriptions) {
    commit("unsubscribe", subscriptions);
  },
  async load({state, commit, dispatch}) {
    const sync = state.fetching;
    let done: unknown | (() => void);
    state.fetching = new Promise((r) => done = r);
    await sync;

    if (!state.queue.length) {
      (done as (() => void))();
      return;
    }
    const items = state.queue.slice(0, queueLimit);
    if (state.queue.length > queueLimit) {
      state.fetching.then(() => dispatch("load"));
    }
    let data: AxiosResponse["data"];
    try {
      const response = await axios.post("/api/accesses", {items});
      data = response.data;
    } catch (e) {
      if (e.message === "Network Error" || e.code === "ECONNABORTED") {
        dispatch("splashScreen/showNetworkFailure", null, {root: true});
      }
    }

    if (!data || data.items.length !== items.length) {
      (done as (() => void))();
      throw new Error("Security voters response mismatch");
    }

    const result = items.map((item, index) => {
      const access = !!data.items[index];
      return {...item, access};
    });
    commit("fetched", result);
    (done as (() => void))();
  },
  refresh({state, commit, dispatch}) {
    commit("queue", state.subscriptions);
    dispatch("load");
  },
  isGranted({state, dispatch, commit}, {attribute, subject}) {
    return new Promise((callback) => {
      commit("registerCallback", {attribute, subject, callback});
      dispatch("load");
    });
  }
};
