<template>
  <object-select
    :options="options"
    :value="selectedOption"
    :clearable="clearable"
    :state="state"
    :disabled="disabled"
    @input="update"
  />
</template>

<script>
import ObjectSelect from "../../../../Form/Select/ObjectSelect";
import t from "../../../../../i18n";
import {attachmentCategories} from "../../../../../utils/attachmentCategories";

export default {
  name: "AttachmentCategorySelect",
  components: {ObjectSelect},
  props: {
    value: {type: String, default: "other"},
    clearable: {type: Boolean, default: true},
    state: {type: Boolean, default: null},
    disabled: {type: Boolean, default: false},
  },
  computed: {
    options() {
      return attachmentCategories.map((value) => ({
        value,
        name: t(`@patient_attachment_category.${value}`),
      }));
    },
    selectedOption() {
      return this.options.find((option) => option.value === this.value) || null;
    }
  },
  methods: {
    update(option) {
      this.$emit("input", option.value);
    }
  },
}
</script>
