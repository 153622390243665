<template>
  <div class="d-flex">
    <b-btn
      v-if="value > 0"
      class="prev"
      title="Poprzedni"
      variant="light"
      @click="prevItem()"
    >
      <span class="fas fa-chevron-circle-left fa-2x" />
    </b-btn>
    <div class="content">
      <slot name="content" />
    </div>
    <b-btn
      v-if="value !== (items.length - 1)"
      class="next"
      title="Następny"
      variant="light"
      @click="nextItem()"
    >
      <span class="fas fa-chevron-circle-right fa-2x" />
    </b-btn>
  </div>
</template>
<script>
export default {
  name: "ItemCarousel",
  props: {
    items: {type: Array, default: ()=>[]},
    value: {type: Number, default: 0},
  },
  methods: {
    nextItem() {
      const next = this.value + 1;
      if (next <= this.items.length) {
        this.$emit("input", next);
      }
    },
    prevItem() {
      const prev = this.value - 1;
      if (prev >= 0) {
        this.$emit("input", prev);
      }
    }
  },
};
</script>

<style lang="scss" scoped>
  @import "../styles/variables";

  .prev,
  .next {
    flex: 0 0 auto;
  }

  .content {
    flex: 1 1 100%;
  }
</style>
