<template>
  <label
    :class="value.chosen ? 'btn-secondary' : 'btn-outline-dark'"
    class="m-r-10 btn"
  >
    <input
      :id="value.number"
      v-model="chosen"
      :data-test="`day-${value.number}`"
      hidden
      type="checkbox"
      @change="update"
    >
    {{ value.name }}
  </label>
</template>

<script>
export default {
  name: "DayCheckbox",
  props: {
    value: {type: Object, required: true},
  },
  data() {
    return {
      chosen: this.value.chosen,
    };
  },
  watch: {
    "value.chosen"(value) {
      this.chosen = value;
    },
  },
  methods: {
    update(event) {
      this.$emit("input", {...this.value, chosen: event.target.checked});
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../../../styles/variables";
  .btn-outline-dark:hover {
    color: $white;
    background-color: #6c757d;
    border-color: #6c757d;
  }
</style>
