<template>
  <div>
    <div class="row">
      <div class="col-md-4" />
      <div class="offset-md-4 col-md-4 text-right">
        <is-granted
          :privileges="['CREATE_COOPERATING_UNIT']"
        >
          <router-link
            :to="{name: 'createCooperatingUnitView'}"
            class="btn btn-success"
          >
            <i class="fa fa-plus" /> Dodaj jednostkę
          </router-link>
        </is-granted>
      </div>
    </div>
    <div class="card-box m-t-10 p-0">
      <div class="table-responsive">
        <table class="table table-hover">
          <thead>
            <tr>
              <th scope="col">
                Nazwa
              </th>
              <th scope="col">
                Adres
              </th>
              <th 
                scope="col" 
                class="text-center"
              >
                Aktywny
              </th>
              <th scope="col">
                Akcje
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="unit in items"
              :key="unit.cooperatingUnitId"
            >
              <td> {{ unit.name }} </td>
              <td> {{ unit.city }}, {{ unit.street }}</td>
              <td class="text-center">
                <i
                  :class="unit.active ? 'fa-check' : 'fa-times'"
                  class="fa"
                />
              </td>
              <td>
                <is-granted
                  :privileges="['UPDATE_COOPERATING_UNIT']"
                  :subject="unit.cooperatingUnitId"
                >
                  <router-link
                    :to="{name: 'updateCooperatingUnitView', params: {cooperatingUnitId: unit.cooperatingUnitId}}"
                    class="btn btn-sm btn-primary" 
                    title="Edycja"
                  >
                    <i class="fa fa-pencil-alt" />
                  </router-link>
                </is-granted>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import IsGranted from "../../components/IsGranted";
import read from "../../rest/read";

export default {
  name: "CooperatingUnitListVue",
  components: {
    IsGranted,
  },
  data() {
    return {
      items: [],
    }
  },
  async mounted () {
    const {items} = await read("/api/cooperating-units");
    this.items = items;
  },
}
</script>
