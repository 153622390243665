<template>
  <div>
    <h3>Kreator ICF</h3>
    <h4>Wybrane zestawy kluczowe:</h4>
    <ul>
      <li
        v-for="core_set in chosenCoreSets"
        :key="core_set.value"
      >
        {{ core_set.name }}
      </li>
    </ul>

    <button
      v-b-toggle="'baseCategories'"
      class="btn btn-secondary cursor-pointer"
      type="button"
    >
      <span class="when-closed">Pokaż obowiązkowe kategorie</span>
      <span class="when-opened">Schowaj obowiązkowe kategorie</span>
    </button>

    <hr>

    <b-collapse id="baseCategories">
      <div class="card card-body">
        <table class="table table-sm">
          <tbody>
            <tr
              v-for="base_category in baseCategories"
              :key="base_category.icfCode"
            >
              <td class="font-weight-bold">
                {{ base_category.icfCode }}
              </td>
              <td>{{ base_category.title }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-collapse>

    <div
      v-if="displayCorrespondingCategories"
      class="form-group"
    >
      <label for="correspondingCategories">Wybierz powiązane kategorie</label>
      <object-select
        id="correspondingCategories"
        v-model="chosenCorrespondingCategories"
        :options="correspondingCategoriesOptions"
        multiple
        placeholder="..."
      />
    </div>

    <div class="form-group">
      <label for="additionalCategories">Wybierz dodatkowe kategorie</label>
      <object-select
        id="additionalCategories"
        v-model="chosenAdditionalCategories"
        :options="additionalCategoriesOptions"
        multiple
        placeholder="..."
      />
    </div>

    <div
      v-if="errorWhileGettingCategories"
      class="alert alert-danger"
    >
      Błąd komunikacji z serwerem
    </div>

    <button
      v-if="!formAlreadySent"
      class="btn btn-primary"
      @click="onSubmit"
    >
      Zapisz
    </button>
    <div
      v-else
      class="alert alert-success"
    >
      Zapisano wybór
    </div>
  </div>
</template>

<script>
import read from "../../../rest/read";
import create from "../../../rest/create";
import ObjectSelect from "../../Form/Select/ObjectSelect";

export default {
  components: {
    ObjectSelect,
  },
  props: {
    ipzId: {type: String, required: true},
    chosenCoreSets: {type: Array, required: true},
  },
  data() {
    return {
      allCategories: [],
      baseCategories: [],
      correspondingCategories: [],
      errorWhileGettingCategories: false,

      chosenCorrespondingCategories: [],
      chosenAdditionalCategories: [],
      formAlreadySent: false,
    };
  },
  computed: {
    allChosenCategories() {
      const merged = this.chosenCorrespondingCategories
        .concat(this.chosenAdditionalCategories)
        .concat(this.baseCategories);
      const codesOnly = merged.map((catObject) => catObject.icfCode);

      return codesOnly.filter((catId, currIndex) => codesOnly.indexOf(catId) === currIndex);
    },
    correspondingCategoriesOptions() {
      return this.correspondingCategories.map((category) => ({
        ...category,
        value: category.icfCode,
        name:`${category.icfCode} ${category.title}`,
      }));

    },
    displayCorrespondingCategories() {
      return this.correspondingCategories.length > 0;
    },
    additionalCategoriesOptions() {
      return this.allCategories.map((category) => ({
        ...category,
        value: category.icfCode,
        name: `${category.icfCode} ${category.title}`,
      }));
    },
  },
  mounted() {
    this.getCategories(
      (items) => {
        this.allCategories = items;
      },
    );
    this.getCategories(
      (items) => {
        this.baseCategories = items;
      },
      this.chosenCoreSets.map((setObject) => setObject.value),
      "base",
    );
    this.getCategories(
      (items) => {
        this.correspondingCategories = items;
      },
      this.chosenCoreSets.map((setObject) => setObject.value),
      "corresponding",
    );
  },
  methods: {
    async onSubmit() {
      await create(`/api/ipzs/${this.ipzId}/icf/categories`, {
        categoryIds: this.allChosenCategories,
        coreSetNames: this.chosenCoreSets.map((setObject) => setObject.value),
      });
      this.formAlreadySent = true;
      this.$emit("categoriesAssigned");
    },
    async getCategories(onSuccess, chosenCoreSets = [], relationType = "") {
      try {
        const {items} = await read("/api/icf/categories", {
          coreSetNames: chosenCoreSets,
          coreSetRelationType: relationType,
        });
        onSuccess(items);
      } catch (exception) {
        this.errorWhileGettingCategories = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .collapsed > .when-opened,
  :not(.collapsed) > .when-closed {
    display: none;
  }
</style>
