




















import {Component, Prop, Watch} from "vue-property-decorator";
import read from "../../rest/read";
import i18n from "../i18n.vue"
import processResponseException from "../../utils/errors/processResponseException";
import ErrorsMixin from "../../mixins/ErrorsMixin.ts";
import {mixins} from "vue-class-component";

interface Gaps {
  gaps: string,
}

@Component({components: {i18n}})
export default class DataGapsInformation extends mixins(ErrorsMixin) {
  @Prop({type: String, required: true}) readonly patientId !: string;

  loading: boolean = false;
  data: Array<Gaps> = [];

  @Watch("patientId", {immediate: true})
  async onPatientIdChange(): Promise<void>
  {
    await this.loadData();
  }

  async loadData(): Promise<void> {
    this.loading = true;
    try {
      const {gaps} = await read(`/api/patient/${this.patientId}/data-gaps-information`);
      this.data = gaps;
    } catch (e) {
      this.errors = processResponseException(e);
    }
    this.loading = false;
  }

}
