<template>
  <div class="row">
    <div class="col-12">
      <patient-data-unification-status-radio
        v-if="dataUnificationStatus"
        :unification-status="dataUnificationStatus"
        :patient-id="patientId"
      />
    </div>
    <div class="col-sm-6">
      <div class="card-box">
        <div class="pb-2 mb-2 border-bottom border-light">
          <h4>Dane osobowe</h4>
        </div>
        <personal-data-form :patient-id="patientId" />
      </div>
    </div>
    <div class="col-sm-6">
      <div class="card-box">
        <div class="pb-2 mb-2 border-bottom border-light">
          <h4>Dane dodatkowe</h4>
        </div>
        <additional-data-form :patient-id="patientId" />
      </div>
    </div>
    <div class="col-sm-6">
      <div class="card-box">
        <div class="pb-2 mb-2 border-bottom border-light">
          <h4>Adres zamieszkania</h4>
        </div>
        <living-address-form
          :patient-id="patientId"
          @updateLivingAddress="updateLivingAddress"
        />
      </div>
    </div>
    <div class="col-sm-6">
      <div class="card-box">
        <div class="pb-2 mb-2 border-bottom border-light">
          <h4>Adres korespondencyjny</h4>
        </div>
        <correspondence-address-form
          :living-address="livingAddress"
          :patient-id="patientId"
        />
      </div>
    </div>
    <div class="col-sm-6">
      <div class="card-box">
        <div class="pb-2 mb-2 border-bottom border-light">
          <h4>Status uczestnika w chwili przystąpienia do projektu</h4>
        </div>
        <participant-status-form
          :patient-id="patientId"
        />
      </div>
    </div>
    <div class="col-sm-6">
      <div class="card-box">
        <div class="pb-2 mb-2 border-bottom border-light">
          <h4>Status na rynku pracy</h4>
        </div>
        <labor-market-status-form :patient-id="patientId" />
      </div>
    </div>
    <div class="col-12">
      <appointments-report-card
        :columns="['dateTime','specialistTypeStatus', 'parameters', 'unificationOptions']"
        :filters="{patientId, orderDesc: true, continuousStay: false}"
        :patient-id="patientId"
        :pagination-per-page="10000000"
        title="Lista wizyt"
      />
    </div>
    <is-granted
      :privileges="['PATIENT_IPZ_LIST']"
      :subject="patientId"
      component="div"
      class="col-12"
    >
      <patient-card-ipz
        :is-unification-enabled="true"
        :patient-id="patientId"
        class="card-box"
      />
    </is-granted>
    <div class="col-12 text-right">
      <router-link
        :to="{name: 'patients_data_unification_view'}"
        class="btn btn-secondary"
      >
        Powrót
      </router-link>
    </div>
  </div>
</template>

<script>
import IsGranted from "../../components/IsGranted";
import PatientCardIpz from "../../components/Patient/PatientCard/PatientCardIpz";
import {mapMutations, mapActions, mapState} from "vuex";
import PatientDataUnificationStatusRadio
  from "../../components/Patient/DataUnification/PatientDataUnificationStatusRadio";
import AppointmentsReportCard from "../../components/Appointment/AppointmentsReportCard";
import read from "../../rest/read";
import ParticipantStatusForm from "../../components/Patient/ParticipantStatusForm";
import PersonalDataForm from "../../components/Patient/PersonalDataForm";
import AdditionalDataForm from "../../components/Patient/AdditionalDataForm";
import LivingAddressForm from "../../components/Patient/LivingAddressForm";
import CorrespondenceAddressForm from "../../components/Patient/CorrespondenceAddressForm";
import LaborMarketStatusForm from "../../components/Patient/LaborMarketStatusForm";

export default {
  components: {
    LaborMarketStatusForm,
    CorrespondenceAddressForm,
    LivingAddressForm,
    AdditionalDataForm,
    PersonalDataForm,
    ParticipantStatusForm,
    AppointmentsReportCard,
    PatientDataUnificationStatusRadio,
    PatientCardIpz,
    IsGranted,
  },
  beforeRouteLeave(to, from, next) {
    this.saveAppointmentListItem(null);
    next();
  },
  props: {
    patientId: {type: String, required: true}
  },
  data() {
    return {
      patient: {},
      livingAddress: {},
      appointments: [],
      dataUnificationStatus: null
    };
  },
  computed: {
    ...mapState({
      patientAnonymous: state => state.clinicParameters.parameters.patientAnonymous,
    }),
  },
  async mounted() {
    const {
      dataUnificationStatus,
      displayName,
      fileNumber,
      pesel,
    } = await read(`/api/patients/${this.patientId}/report-card`);
    this.patient = {
      patientId: this.patientId,
      displayName,
    };
    this.dataUnificationStatus = dataUnificationStatus;

    const displayNameWithPesel = pesel ? `${displayName} (${pesel})` : displayName;
    const stickyDisplayName = (!this.patientAnonymous && fileNumber)
      ? `${displayNameWithPesel} | ${fileNumber}`
      : displayNameWithPesel;
    this.setStickyPatient({
      ...this.patient,
      displayName: stickyDisplayName,
    });
  },
  methods: {
    ...mapMutations("appointmentListItem", ["saveAppointmentListItem",]),
    ...mapActions({
      setStickyPatient: "stickyPatient/set",
    }),
    updateLivingAddress(livingAddress) {
      this.livingAddress = livingAddress;
    },
  },
}
</script>
