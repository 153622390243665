












import ObjectSelect from "../../Form/Select/ObjectSelect.vue";
import t from "../../../i18n";
import {Component, Prop} from "vue-property-decorator";
import Vue from "vue";

interface OptionValue {
  value: string;
}

interface Option extends OptionValue {
  name: string;
}

@Component({
  name: "TargetStatusSelect",
  components: {ObjectSelect},
})

export default class TargetStatusSelect extends Vue {
  @Prop({type: String, default: null}) readonly value!: string;
  @Prop({type: Boolean, default: null}) readonly state!: string;
  @Prop({type: String, default: "Wybierz status"}) readonly placeholder!: string;
  @Prop({type: Boolean, default: false}) readonly disabled!: boolean;
  @Prop({type: Boolean, default: true}) readonly clearable!: boolean;

  optionValues: Array<OptionValue> = [
    {value: "planned"},
    {value: "in_progress"},
    {value: "finished"},
    {value: "canceled"},
  ];


  get options(): Array<Option> {
    return this.optionValues.map(option => ({
      ...option,
      name: t(`@ipz-target-status.${option.value}`),
    }));
  }

  get selectedValue(): Option|null {
    if (!this.value) {
      return null
    } else {
      return this.options.find((option) => option.value === this.value) || null;
    }
  }

  update(option: Option): void {
    this.$emit("input", option ? option.value : null);
  }
}
