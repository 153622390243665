<template>
  <div>
    <b-table
      :items="itemsWithDetails"
      :fields="fields"
      :responsive="true"
    >
      <template #cell(status)="row">
        <tooltip
          :tooltip-content="statusName(row.item.status)"
          :class="statusIconClass(row.item.status)"
          class="fas"
        />
      </template>
      <template #cell(procedureName)="row">
        <div
          class="cursor-pointer"
          @click.stop="showProcedureDescription(row.item.procedureDescription)"
        >
          {{ row.item.procedureName }}
          (<date-time
            :value="parseDate(row.item.createdAt, DATE_FORMAT.DATE_TIME_SEC)"
            component="fragment"
          />)
        </div>
      </template>
      <template #cell(specialist)="row">
        <template v-if="row.item.specialist && row.item.finishedAt">
          {{ row.item.specialist }}
          (<date-time
            v-if="row.item.finishedAt"
            :value="parseDate(row.item.finishedAt, DATE_FORMAT.DATE_TIME_SEC)"
            component="fragment"
          />)
        </template>
      </template>
      <template #cell(options)="row">
        <div
          class="d-flex flex-column ml-auto"
          style="width: 6rem"
        >
          <b-btn
            block
            class="m-1"
            size="sm"
            variant="secondary"
            @click.stop="row.toggleDetails"
          >
            <span
              :class="row.detailsShowing ? 'fa-eye-slash' : 'fa-eye'"
              class="fas mr-1"
            /> {{ row.detailsShowing ? "Ukryj" : "Pokaż" }}
          </b-btn>
          <is-granted
            :privileges="['FINISH_PROCEDURE', 'CANCEL_PROCEDURE']"
            :subject="row.item.procedureId"
            component="fragment"
          >
            <b-dropdown
              block
              boundary="$el"
              class="m-1 w-100 text-left"
              right
              size="sm"
              text="Opcje"
              variant="outline-secondary"
            >
              <is-granted
                :privileges="['FINISH_PROCEDURE']"
                :subject="row.item.procedureId"
                component="fragment"
              >
                <b-dropdown-item-btn @click.stop="finishProcedure(row.item.procedureId)">
                  <span class="fas fa-lock mr-1" /> Zamknij
                </b-dropdown-item-btn>
              </is-granted>
              <is-granted
                :privileges="['CANCEL_PROCEDURE']"
                :subject="row.item.procedureId"
                component="fragment"
              >
                <b-dropdown-item-btn @click.stop="cancelProcedure(row.item.procedureId)">
                  <span class="fas fa-ban mr-1" /> Anuluj
                </b-dropdown-item-btn>
              </is-granted>
            </b-dropdown>
          </is-granted>
        </div>
      </template>
      <template #row-details="row">
        {{ row.detailsShowing }}
        <procedure-details
          :procedure-id="row.item.procedureId"
          :reason="row.item.cancelReason"
          class="procedure-details"
        />
      </template>
    </b-table>
    <procedure-close-modal
      :procedure-id="selectedProcedure"
      :type="closeType"
      @update="updateProcedures"
      @close="closeProcedureModal"
    />
    <procedure-description-modal
      :description="selectedDescription"
      @close="closeProcedureDescription"
    />
  </div>
</template>

<script>
import DateTime from "../../../DateTime";
import parseDate from "../../../../utils/date/parseDate";
import Tooltip from "../../../Tooltip";
import ProcedureDetails from "./ProcedureDetails";
import ProcedureCloseModal from "./ProcedureCloseModal";
import IsGranted from "../../../IsGranted";
import ProcedureDescriptionModal from "./ProcedureDescriptionModal";
import DATE_FORMAT from "@/utils/date/DATE_FORMAT";

export default {
  name: "ProceduresList",
  components: {ProcedureDescriptionModal, IsGranted, ProcedureCloseModal, ProcedureDetails, Tooltip, DateTime},
  props: {
    items: {type:Array, default: ()=>[]},
  },
  data() {
    return {
      selectedProcedure: null,
      selectedDescription: null,
      closeType: null,
      parseDate,
      DATE_FORMAT,
    };
  },
  computed: {
    fields() {
      return [
        {key: "status", label: "Status", class: "text-center", thStyle: "width: 100px;"},
        {key: "procedureName", label: "Procedura", thStyle: "width: calc(50% - 140px);"},
        {key: "specialist", label: "Zamknął", thStyle: "width: calc(50% - 140px);"},
        {key: "options", label: "", thStyle: "width: 170px;"},
      ];
    },
    itemsWithDetails() {
      return this.items.map(item => ({
        ...item,
        _showDetails: false,
      }))
    },
  },
  methods: {
    statusName(status) {
      switch (status) {
        case "opened":
          return "Aktywna";
        case "finished":
          return "Zakończona";
        case "canceled":
          return "Anulowana";
        default:
          return;
      }
    },
    statusIconClass(status) {
      switch (status) {
        case "opened":
          return "fa-circle text-success";
        case "finished":
          return "fa-lock text-danger";
        case "canceled":
          return "fa-ban text-danger";
        default:
          return;
      }
    },
    finishProcedure(procedureId) {
      this.selectedProcedure = procedureId;
      this.closeType = "finish";
      this.$bvModal.show("close-procedure");
    },
    cancelProcedure(procedureId) {
      this.selectedProcedure = procedureId;
      this.closeType = "cancel";
      this.$bvModal.show("close-procedure");
    },
    closeProcedureModal() {
      this.selectedProcedure = null;
      this.closeType = null;
    },
    updateProcedures() {
      this.$emit("loadItems");
    },
    showProcedureDescription(description) {
      this.selectedDescription = description;
      this.$bvModal.show("procedure-description");
    },
    closeProcedureDescription() {
      this.selectedDescription = null;
    },
  },
}
</script>

<style scoped lang="scss">
  .procedure-details {
    margin-left: 70px;
    margin-right: 140px;
  }
</style>
