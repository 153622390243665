<template>
  <is-granted
    :privileges="['APPOINTMENT_LIST']"
    component="fragment"
  >
    <loading-mask :loading="loading">
      <counter-widget
        :filters="filters"
        :title="title"
        :icon="icon"
        resource="/api/appointments"
        go-to-url="/appointments/list"
      />
    </loading-mask>
  </is-granted>
</template>
<script>
import CounterWidget from "../CounterWidget";
import {mapState} from "vuex";
import stringifyDate from "../../utils/date/stringifyDate";
import DATE_FORMAT from "../../utils/date/DATE_FORMAT";
import LoadingMask from "../Loading/LoadingMask";
import IsGranted from "../IsGranted";
import {isGranted} from "../../security/isGranted";
import {specialistRoles} from "../../utils/roles/specialistRoles";

export default {
  components: {
    IsGranted,
    LoadingMask,
    CounterWidget,
  },
  props: {
    remote: {type: Boolean, default: false},
  },
  data() {
    return {
      preselectedWorker: null,
      loading: false
    };
  },
  computed: {
    ...mapState("currentUser", ["user"]),
    title() {
      return this.remote ? "Dzisiejsze wyjazdy" : "Dzisiejsze wizyty";
    },
    icon() {
      return this.remote ? "car" : "user";
    },
    filters() {
      return {
        startDate: this.startDate,
        endDate: this.endDate,
        remote: this.remote,
        workerIds: this.preselectedWorker,
        statuses: ["created", "checkedin", "opened"],
      };
    },
    startDate() {
      const date = new Date();
      date.setHours(0, 0, 0);
      return stringifyDate(date, DATE_FORMAT.DEFAULT);
    },
    endDate() {
      const date = new Date();
      date.setHours(23, 59, 59);
      return stringifyDate(date, DATE_FORMAT.DEFAULT);
    },
  },
  async mounted() {
    this.loading = true;
    this.preselectedWorker = await isGranted(specialistRoles) ? [this.user.workerId] : null;
    this.loading = false;
  },
}
</script>
