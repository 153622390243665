




































































































































































import {Component, Mixins, Prop} from "vue-property-decorator";
import ErrorsMixin from "../../../../mixins/ErrorsMixin";
import DatePicker from "../../../Form/DatePicker/DatePicker.vue";
import LoadingMask from "../../../Loading/LoadingMask.vue";
import ErrorMessage from "../../../Form/ErrorMessage.vue";
import processResponseException from "../../../../utils/errors/processResponseException";
import create from "../../../../rest/create";
import {generateUuid} from "../../../../utils/uuid/generateUuid";
import stringifyDate from "../../../../utils/date/stringifyDate";
import DATE_FORMAT from "../../../../utils/date/DATE_FORMAT";
import TextareaCounter from "../../../Form/Textarea/TextareaCounter.vue";
import HospitalWidget from "./HospitalWidget.vue";
import FileUpload from "../../../File/FileUpload.vue";
import type {HospitalEntry, Hospitalization, UploadedFile} from "../../../../types/HospitalizationTypes";
import {HospitalizationType} from "../../../../types/HospitalizationTypes";
import HospitalizationTypeSelect from "./HospitalizationTypeSelect.vue";

@Component({components: {
  HospitalizationTypeSelect,
  FileUpload,
  HospitalWidget,
  TextareaCounter,
  ErrorMessage,
  LoadingMask,
  DatePicker,
}})
export default class PatientPsychiatricHospitalizationsCreateModal extends Mixins(ErrorsMixin) {

  @Prop({type: Boolean, required: true}) readonly modalVisible!: boolean;
  @Prop({type: String, required: true}) readonly patientId!: string;

  loading: boolean = false;
  hospital: HospitalEntry|null = null;
  comment: string = "";
  dateFrom: Date|null = null;
  dateTo: Date|null = null;
  datesUnknown: boolean = false;
  duration: number| null = null;
  uploadedFiles: Array<UploadedFile> = [];
  type: HospitalizationType = HospitalizationType.UNKNOWN;

  closeModal(): void {
    this.clearForm();
    this.$emit("closeModal");
  }
  async submit(): Promise<void> {
    this.loading = true;
    try {
      await create<Hospitalization>(`/api/patients/${this.patientId}/hospitalizations`, {
        hospitalizationId: generateUuid() as string,
        start: !!this.dateFrom ? stringifyDate(this.dateFrom, DATE_FORMAT.DATE) : null,
        end: !!this.dateTo ? stringifyDate(this.dateTo, DATE_FORMAT.DATE) : null,
        datesUnknown: this.datesUnknown,
        duration: this.duration,
        hospital: this.hospital,
        comment: this.comment,
        documents: [...this.uploadedFiles.map<string>(file => file.fileId)],
        type: this.type,
      });
      this.$emit("created");
      this.clearForm();
    } catch (exception) {
      this.errors = processResponseException(exception);
    }
    this.loading = false;
  }
  clearForm(): void {
    this.hospital = null;
    this.comment = "";
    this.dateFrom = null;
    this.dateTo = null;
    this.datesUnknown = false;
    this.duration = null;
    this.uploadedFiles = [];
    this.type = HospitalizationType.UNKNOWN;
    this.errors = [];
  }
  addFile(file: UploadedFile): void {
    this.uploadedFiles.push(file);
  }
  removeFile(file: UploadedFile): void {
    this.uploadedFiles = this.uploadedFiles.filter(f => f.fileId !== file.fileId);
  }
  setNullDates(event: boolean): void {
    if (event) {
      this.dateFrom = null;
      this.dateTo = null;
    } else {
      this.duration = null;
    }
  }
}
