<template>
  <div class="card-box">
    <form>
      <h3 class="pb-2 mb-2 border-bottom border-light">
        Kreator tygodniowy
      </h3>

      <div class="form-group">
        <day-checkbox
          v-for="(dayOfWeek, index) in daysOfWeek"
          :key="index"
          v-model="daysOfWeek[index]"
        />
      </div>

      <div class="form-group mt-3">
        <label
          for="range"
          class="font-weight-bold mr-2"
          style="font-size: 1rem"
        >
          Zakres obowiązywania tego grafiku:
        </label>
        <date-picker
          id="range"
          v-model="dateRange"
          :range="true"
          :type="'date'"
          :format="'YYYY-MM-DD'"
          :lang="lang"
          :editable="false"
          class="date-picker"
          data-test="range-date-picker"
          input-class="form-control"
          placeholder="Wybierz zakres dat"
        />
      </div>

      <div class="form-row mt-5">
        <day-hours-input
          v-for="(dayOfWeek, index) in daysOfWeek"
          :key="index"
          v-model="daysOfWeek[index]"
          @clearErrors="daysOfWeek[index].errors = ''"
        />
      </div>
      <b-form-group
        label="Komórka organizacyjna:"
      >
        <branch-select
          id="branch"
          v-model="selectedBranch"
          data-test="selected-branch"
        />
      </b-form-group>

      <transition name="fade">
        <div
          v-if="error.length > 0"
          class="alert alert-danger alert-dismissible form-errors"
        >
          <button
            type="button"
            class="close"
            @click="error = ''"
          >
            <span>&times;</span>
          </button>
          {{ error }}
        </div>
      </transition>

      <div class="d-flex justify-content-end flex-wrap">
        <button
          class="btn btn-secondary"
          data-test="go-back"
          @click.prevent="goBackToList"
        >
          Powrót
        </button>
        <button
          :disabled="submittingInProgress"
          class="btn btn-primary ml-2"
          data-test="submit"
          @click.prevent="submit()"
        >
          <i
            :class="submittingInProgress ? 'fa-spinner fa-spin' : 'fa-check'"
            class="fa"
          />
          Zapisz
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import lang from "../../../i18n/datePicker.json";
import stringifyDate from "../../../utils/date/stringifyDate";
import DayHoursInput from "./DayHoursInput.vue";
import DayCheckbox from "./DayCheckbox.vue";
import create from "../../../rest/create";
import processResponseException from "../../../utils/errors/processResponseException";
import BranchSelect from "../../Branch/BranchSelect";

export default {
  name: "WeeklyWorkingHoursForm",
  components: {
    BranchSelect,
    DatePicker,
    DayHoursInput,
    DayCheckbox,
  },
  props: {
    workerId: {type: String, required: true},
  },
  data() {
    return {
      selectedBranch: null,
      dateRange: [],
      daysOfWeek: [
        {
          name: "Poniedziałek",
          number: 1,
          chosen: false,
          hourFrom: {HH: "08", mm: "00"},
          hourTo: {HH: "16", mm: "00"},
          errors: ""
        },
        {
          name: "Wtorek",
          number: 2,
          chosen: false,
          hourFrom: {HH: "08", mm: "00"},
          hourTo: {HH: "16", mm: "00"},
          errors: ""
        },
        {
          name: "Środa",
          number: 3,
          chosen: false,
          hourFrom: {HH: "08", mm: "00"},
          hourTo: {HH: "16", mm: "00"},
          errors: ""
        },
        {
          name: "Czwartek",
          number: 4,
          chosen: false,
          hourFrom: {HH: "08", mm: "00"},
          hourTo: {HH: "16", mm: "00"},
          errors: ""
        },
        {
          name: "Piątek",
          number: 5,
          chosen: false,
          hourFrom: {HH: "08", mm: "00"},
          hourTo: {HH: "16", mm: "00"},
          errors: ""
        },
        {
          name: "Sobota",
          number: 6,
          chosen: false,
          hourFrom: {HH: "08", mm: "00"},
          hourTo: {HH: "16", mm: "00"},
          errors: ""
        },
        {
          name: "Niedziela",
          number: 0,
          chosen: false,
          hourFrom: {HH: "08", mm: "00"},
          hourTo: {HH: "16", mm: "00"},
          errors: ""
        },
      ],
      lang,
      dayCounter: 0,
      submittingInProgress: false,
      error: "",
    };
  },
  computed: {
    chosenDays() {
      return this.daysOfWeek.filter((day) => day.chosen);
    },
  },
  methods: {
    convertDayToRange(dayOfWeek) {
      const date = this.getDate(dayOfWeek);
      const dateStr = stringifyDate(date, "YYYY-MM-DD");
      const rangeStart = new Date(`${dateStr  } ${  dayOfWeek.hourFrom.HH  }:${  dayOfWeek.hourFrom.mm}`);
      const rangeEnd = new Date(`${dateStr  } ${  dayOfWeek.hourTo.HH  }:${  dayOfWeek.hourTo.mm}`);
      const numberOfDays = this.numberOfSuchDaysInChosenRange(dayOfWeek);

      if (0 === numberOfDays) {
        throw {
          name: "NoSuchDay",
          message: "W wybranym zakresie nie ma takiego dnia tygodnia"
        };
      }

      const dateEnd = new Date(this.dateRange[1].valueOf());

      if (rangeStart > rangeEnd) {
        rangeEnd.setDate(rangeEnd.getDate() + 1);
        dateEnd.setDate(dateEnd.getDate() + 1);
      }

      if (1 === numberOfDays) {
        return {
          start: stringifyDate(rangeStart, "YYYY-MM-DD HH:mm"),
          end: stringifyDate(rangeEnd, "YYYY-MM-DD HH:mm"),
          intervalDays: null,
        };
      }

      return {
        start: stringifyDate(rangeStart, "YYYY-MM-DD HH:mm"),
        end: stringifyDate(rangeEnd, "YYYY-MM-DD HH:mm"),
        dateEnd: stringifyDate(dateEnd, "YYYY-MM-DD"),
        intervalDays: 7,
      };
    },
    getDate(day) {
      const date = new Date(this.dateRange[0].getTime());
      while (date.getDay() !== day.number) {
        date.setDate(date.getDate() + 1);
      }
      return date;
    },
    numberOfSuchDaysInChosenRange(dayOfWeek) {
      const date = new Date(this.dateRange[0].getTime());
      let counter = 0;

      while (!(date > this.dateRange[1])) {
        if (date.getDay() === dayOfWeek.number) {
          counter++;
          if (counter > 1) {
            return counter; // optimize - no need to count more
          }
        }
        date.setDate(date.getDate() + 1);
      }

      return counter;
    },
    submit() {
      if (!this.chosenDays.length > 0) {
        this.error = "Wybierz dni";
        return;
      }
      if (!(this.dateRange[0] instanceof Date || this.dateRange[1] instanceof Date)) {
        this.error = "Wybierz zakres dat";
        return;
      }
      if (!this.selectedBranch) {
        this.error = "Wybierz jednostkę";
        return;
      }
      this.submittingInProgress = true;
      this.daysOfWeek.map((day) => day.errors = "");
      this.error = "";
      this.dayCounter = 0;
      this.sendDays();
    },
    sendDays() {
      const day = this.chosenDays[this.dayCounter];
      try {
        const range = this.convertDayToRange(day);
        create(`/api/workers/${this.workerId}/working-hours`, {
          rangeStart: range.start,
          rangeEnd: range.end,
          rangeIntervalDays: range.intervalDays,
          dateEnd: range.dateEnd,
          branchId: this.selectedBranch,
        }).then(() => {
          this.$emit("rangeSubmitted");
          this.sendNextDay();
        }).catch((exception) => {
          const errors = processResponseException(exception);
          day.errors = errors.map((error) => error.message).join();
          this.sendNextDay();
        });
      } catch (e) {
        if (e.name === "NoSuchDay") {
          day.errors = e.message;
          this.sendNextDay();
        } else {
          const errors = processResponseException(exception);
          day.errors = errors.map((error) => error.message).join();
        }
      }
    },
    sendNextDay() {
      if (++this.dayCounter in this.chosenDays) {
        this.sendDays();
      } else {
        this.markAllSentDaysAsNotChosen()
      }
    },
    markAllSentDaysAsNotChosen() {
      this.daysOfWeek.map((day) => {if (day.errors === "") day.chosen = false});
      setTimeout(() => {
        this.daysOfWeek.map((day) => {if (day.errors === "") day.class = "form-group"});
      }, 500);
      this.submittingInProgress = false;
    },
    goBackToList() {
      this.$router.push({name: "listWorkingHoursOfWorker"});
    }
  },
}
</script>

<style lang="scss" scoped>
 .alert.form-errors {
   margin-top: 15px;
 }

 .date-picker {
   width: 192px;
   max-width: 100%;
 }
</style>
