







































import Vue from "vue";
import Component from "vue-class-component";
import {Prop} from "vue-property-decorator";
import type {Dosage, DosageFormData, DosageFormOption} from "../../types/Prescription";
import {RateQuantityUnit} from "../../types/Prescription";
import MedicationDoseQuantity from "./MedicationDoseQuantity.vue";
import MedicationRate from "./MedicationRate.vue";
import MedicationRateHours from "./MedicationRateHours.vue";
import {Error} from "../../rest";
import ErrorMessage from "../Form/ErrorMessage.vue";
import MedicationDosageInfo from "./MedicationDosageInfo.vue";


const initialValue = {
  quantity: null,
  rate: null,
}

@Component({
  name: "MedicationDosage",
  components: {MedicationDosageInfo, ErrorMessage, MedicationRateHours, MedicationRate, MedicationDoseQuantity},
})
export default class MedicationDosage extends Vue {
  @Prop({type: Object, default: null}) readonly value!: Dosage;
  @Prop({type: Boolean, default: false}) readonly disabled!: boolean;
  @Prop({type: Array, default: ()=>[]}) readonly errors!: Array<Error>

  private get rate(): DosageFormData["rate"] {
    if (this.value.rateQuantityUnit === RateQuantityUnit.DAY) {
      return this.value.rateQuantity ? this.value.rateQuantity.toString() : null;
    }
    return (this.value.rateQuantityUnit === RateQuantityUnit.HOUR)
      ? "hours"
      : null;
  }

  private get hours(): DosageFormData["hours"] {
    return this.value.rateQuantityUnit === RateQuantityUnit.HOUR
      ? this.value.rateQuantity
      : undefined
  }

  private get formData(): DosageFormData {
    return this.value
      ? {
        quantity: (this.value.doseQuantity != null) ? this.value.doseQuantity : null,
        rate: this.rate,
        hours: this.hours,
      }
      : {...initialValue}
  }

  private update($event: DosageFormOption["value"], field: string): void {
    const data = {
      ...this.formData,
      [field]: $event,
    }
    const rate = data.rate ? Number(data.rate) : null;
    const newValue = {
      doseQuantity: data.quantity,
      rateQuantity: data.rate === "hours" ? data.hours : rate,
      rateQuantityUnit: data.rate === "hours" ? RateQuantityUnit.HOUR : RateQuantityUnit.DAY,
    }
    this.$emit("input", newValue);
  }
}
