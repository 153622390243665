




















































































































import ErrorsMixin from "../../mixins/ErrorsMixin";
import {Component, Mixins, Prop, Watch} from "vue-property-decorator";
import read from "../../rest/read";
import processResponseException from "../../utils/errors/processResponseException";
import {Pagination} from "../../types/Pagination";
import type {RequestItem, RequestFilters} from "../../types/Request";
import {RequestStatus, UrlRequestFilters} from "../../types/Request";
import ErrorMessage from "../../components/Form/ErrorMessage.vue";
import type {BvTableFieldArray} from "bootstrap-vue/src/components/table";
import I18n from "../../components/i18n.vue";
import Card from "../../components/Card.vue";
import PaginationDescription from "../../components/Pagination/PaginationDescription.vue";
import {updatePageQuery} from "../../utils/pageUrl/handlePageQuery";
import IsGranted from "../../components/IsGranted.vue";
import AcceptRequestModal from "../../components/Request/AcceptRequestModal.vue";
import CancelRequestModal from "../../components/Request/CancelRequestModal.vue";
import PreviewRequestModal from "../../components/Request/PreviewRequestModal.vue";
import RequestListFilters from "../../components/Request/RequestListFilters.vue";

interface ResponseData {
  items: RequestItem[];
  pagination: Pagination;
}

@Component({
  name: "RequestListPage",
  components: {
    RequestListFilters,
    CancelRequestModal,
    AcceptRequestModal,
    PreviewRequestModal,
    IsGranted,
    PaginationDescription,
    Card,
    I18n,
    ErrorMessage
  },
})
export default class RequestListPage extends Mixins(ErrorsMixin) {
  @Prop({type: Number, default: 1}) readonly page!: number;
  @Prop({type: Array, default: () => []}) readonly status!: Array<string>;
  @Prop({type: Array, default: () => []}) readonly creator!: Array<string>;
  @Prop({type: Array, default: () => []}) readonly requestType!: Array<string>;

  private loading: boolean = false;
  private items: RequestItem[] = [];
  private pagination: Pagination = {
    perPage: 20,
    currentPage: this.page || 1,
    totalRows: 0,
  }
  private filters: RequestFilters = {
    status: this.status,
    creator: this.creator,
    requestType: this.requestType
  }

  private getStatusVariant(status: RequestStatus): string {
    switch (status) {
      case RequestStatus.CREATED:
        return "primary";
      case RequestStatus.ACCEPTED:
        return "success";
      case RequestStatus.CANCELED:
        return "secondary";
      default:
        return "light";
    }
  }

  private get fields(): BvTableFieldArray {
    return [
      {key: "command", label: "Typ wniosku"},
      {key: "createdAt", label: "Data stworzenia"},
      {key: "creator", label: "Twórca"},
      {key: "creatorNote", label: "Uwagi twórcy"},
      {key: "acceptor", label: "Zaakceptował"},
      {key: "acceptorNote", label: "Uwagi akceptującego"},
      {key: "status", label: "Status"},
      {key: "options", label: ""},
    ]
  }

  @Watch("$route", {immediate: true}) async onRouteChange(): Promise<void> {
    await this.fetchData();
  }

  private async fetchData(): Promise<void> {
    this.loading = true;
    try {
      const {items, pagination} = await read<ResponseData>("/api/change-requests", {
        page: this.pagination.currentPage,
        perPage: this.pagination.perPage,
        ...this.filters
      });
      this.items = items;
      this.pagination = pagination;
    } catch (e) {
      this.errors = processResponseException(e);
    }
    this.loading = false;
  }

  private async changePage(page: number): Promise<void> {
    this.pagination.currentPage = page;
    let filters = this.urlFilters()
    await updatePageQuery({
      page: this.pagination.currentPage,
      ...filters
    });
  }

  private async changeFilters(value: RequestFilters): Promise<void> {
    this.filters = value;
    let filters = this.urlFilters()
    await updatePageQuery({
      page: this.pagination.currentPage,
      ...filters
    });
    await this.fetchData();
  }

  private urlFilters(): UrlRequestFilters
  {
    return {
      status: this.filters.status.join(",") || undefined,
      creator: this.filters.creator.join(",") || undefined,
      requestType: this.filters.requestType.join(",") || undefined
    }
  }
}
