

















































































































import {Component, Mixins, Prop} from "vue-property-decorator";
import ErrorMessage from "../Form/ErrorMessage.vue";
import ErrorsMixin from "../../mixins/ErrorsMixin.ts";
import DatePicker from "../Form/DatePicker/DatePicker.vue";
import TreatmentTypeSelect from "../TreatmentType/TreatmentTypeSelect.vue";
import TextareaCounter from "../Form/Textarea/TextareaCounter.vue";
import processResponseException from "../../utils/errors/processResponseException";
import DATE_FORMAT from "../../utils/date/DATE_FORMAT";
import stringifyDate from "../../utils/date/stringifyDate";
import update from "../../rest/update";
import IsGranted from "../IsGranted.vue";
import read from "../../rest/read";

interface SocialActivityData {
  socialActivityId: string;
  treatmentTypeId: string;
  dateFrom: string;
  dateTo: string;
  description: string;
}

@Component({
  name: "EditSocialActivityModal",
  components: {IsGranted, TextareaCounter, TreatmentTypeSelect, ErrorMessage, DatePicker}
})

export default class EditSocialActivityModal extends Mixins(ErrorsMixin) {
  @Prop({type: String, required: true}) readonly socialActivityId!: string;
  @Prop({type: String, required: true}) readonly patientId!: string;

  private treatmentTypeId: string | null = null;
  private description: string | null = null;
  private dateFrom: string | null = null;
  private dateTo: string | null = null;
  private loading: boolean = false;

  private get modalId(): string {
    return `edit-social-activity-${this.socialActivityId}`
  }

  dateString(date: Date): string | null {
    return date ? stringifyDate(date, DATE_FORMAT.DATE) : null;
  }

  async loadData(): Promise<void> {
    this.loading = true;

    const {
      treatmentTypeId,
      description,
      dateFrom,
      dateTo,
    } = await read<SocialActivityData>(`/api/social-activity/${this.socialActivityId}`);
    this.treatmentTypeId = treatmentTypeId;
    this.description = description;
    this.dateFrom = dateFrom;
    this.dateTo = dateTo;

    this.loading = false;
  }

  async submit(): Promise<void> {
    this.errors = [];
    this.loading = true;
    try {
      await update("/api/social-activity", {
        socialActivityId: this.socialActivityId,
        treatmentTypeId: this.treatmentTypeId,
        patientId: this.patientId,
        description: this.description,
        dateFrom: this.dateFrom ? this.dateString(new Date(this.dateFrom)) : null,
        dateTo: this.dateTo? this.dateString(new Date(this.dateTo)) : null
      });
      this.hideModal();
      this.$emit("updated");
    } catch(e) {
      this.errors = processResponseException(e);
    }
    this.loading = false;
  }

  hideModal(): void {
    this.$bvModal.hide(this.modalId);
  }

  resetData(): void {
    this.treatmentTypeId = null;
    this.description = null;
    this.dateFrom = null;
    this.dateTo = null;
    this.errors = [];
  }
}
