import pageLoad from "../utils/pageLoad";
import {isProduction} from "../isProduction";
import {store} from "../main";

export default async () => {
  if (!("serviceWorker" in navigator)) {
    throw new Error("SW is not supported");
  }

  const registration = await navigator.serviceWorker.register(
    "/serviceWorker.js"
  );

  await pageLoad;

  registration.addEventListener(
    "updatefound",
    () => update(registration),
  );

  return registration;
}

const update = ({installing}) => {
  installing.addEventListener("statechange", () => {
    if (isProduction && installing.state === "installed" && navigator.serviceWorker.controller) {
      store.commit("toastNotification/showToast", {
        message: "Wersja systemu została zaktualizowana. Odśwież stronę aby zacząć korzystać z nowej wersji.",
        variant: "info"
      });
    }
  });
};
