<template>
  <card
    :loading="loading"
    title="Procedury"
  >
    <template #control>
      <is-granted
        :privileges="['CREATE_PROCEDURE']"
        :subject="patientId"
        component="fragment"
      >
        <button
          v-b-modal.create-procedure
          :disabled="loading"
          class="btn btn-sm btn-primary"
        >
          <i
            :class="loading ? 'fa-spin fa-spinner' : 'fa-plus'"
            class="fas"
          />
          Uruchom procedurę
        </button>
      </is-granted>
    </template>
    <error-message :errors="errors" />
    <procedures-list
      v-if="items.length"
      :items="items"
      @loadItems="fetchData"
    />
    <procedure-create-modal
      :patient-id="patientId"
      @update="fetchData"
    />
  </card>
</template>

<script>
import Card from "../../../Card";
import read from "../../../../rest/read";
import processResponseException from "../../../../utils/errors/processResponseException";
import {errorsMixin} from "../../../../mixins/errorsMixin.js";
import ErrorMessage from "../../../Form/ErrorMessage";
import ProcedureCreateModal from "./ProcedureCreateModal";
import ProceduresList from "./ProceduresList";
import IsGranted from "../../../IsGranted";

export default {
  name: "PatientCardProcedures",
  components: {
    IsGranted,
    ProceduresList,
    ProcedureCreateModal,
    ErrorMessage,
    Card,
  },
  mixins: [errorsMixin],
  props: {
    patientId: {type: String, required: true},
  },
  data() {
    return {
      loading: false,
      items: [],
    };
  },
  watch: {
    async patientId() {
      await this.fetchData();
    },
  },
  async mounted() {
    await this.fetchData();
  },
  methods: {
    async fetchData() {
      this.loading = true;
      this.errors = [];
      try {
        const {items} = await read("/api/procedures", {
          patientId: this.patientId,
        });
        this.items = items;
      } catch(e) {
        this.errors = processResponseException(e);
      }
      this.loading = false;
    },
  },
}
</script>
