<template>
  <AsyncSelect
    :disabled="disabled"
    :clearable="clearable"
    :placeholder="placeholder"
    :options-provider="loadOptions"
    :value="selectedOption"
    :name="name"
    :multiple="multiple"
    :state="state"
    :cache-key="treatmentType"
    @input="update"
    @open="open"
  />
</template>
<script>
import AsyncSelect from "../Form/Select/AsyncSelect";
import read from "../../rest/read";

const worker2option = (worker) => ({
  worker,
  value: worker.workerId,
  name: `${worker.surname} ${worker.name}`,
});

export default {
  components: {AsyncSelect},
  props: {
    name: AsyncSelect.props.name, // eslint-disable-line
    value: {type: [Object, String, Array], default: null},
    disabled: AsyncSelect.props.disabled, // eslint-disable-line
    clearable: AsyncSelect.props.clearable, // eslint-disable-line
    placeholder: {type: String, default: "Wybierz specjalistę"},
    multiple: AsyncSelect.props.multiple, // eslint-disable-line
    state: AsyncSelect.props.state, // eslint-disable-line
    treatmentType: {type: String, default: null},
    excludeWorkerIds: {type: Array, default: ()=>[]},
    withCooperatingUnit: {type: Boolean, default: false},
    isCoordinator: {type: Boolean, default: false},
    withCurrentUser: {type: Boolean, default: false},
    coordinatedByCurrentUser: {type: Boolean, default: false},
    roles: {type: Array,  default: ()=>[]}
  },
  computed: {
    selectedOption() {
      if (!this.value) {
        return this.value;
      } else if (Array.isArray(this.value)) {
        if ("string" === typeof this.value[0]) {
          this.loadSelectedValue();
        } else {
          return this.value.map(worker => {
            if (!worker.value) {
              worker.value = worker.workerId;
            }
            return worker;
          });
        }
      } else if ("object" === typeof this.value) {
        return worker2option(this.value);
      } else if ("string" === typeof this.value) {
        this.loadSelectedValue();
      }
      return null;
    },
  },
  methods: {
    async loadOptions(fullName="") {
      const params = {
        fullName,
        perPage: 200,
        cooperatingUnit: this.withCooperatingUnit,
        treatmentType: this.treatmentType || undefined,
        teamCoordinator: this.isCoordinator || undefined,
        coordinatedByCurrentUser: this.coordinatedByCurrentUser || undefined,
        withCurrentUser: this.withCurrentUser || undefined,
        roles: this.roles || undefined
      };
      let {items} = await read("/api/workers", params);
      if (this.excludeWorkerIds.length) {
        items = this.excludeWorkerOption(items);
      }
      return items.map(worker2option);
    },
    update(option) {
      if (option) {
        if (Array.isArray(this.value)) {
          this.$emit("input", option);
        } else {
          this.$emit("input", option.worker);
        }
      } else {
        this.$emit("input");
      }
    },
    open() {
      this.$emit("open");
    },
    async loadSelectedValue() {
      const workerIds = Array.isArray(this.value) ? this.value : [this.value];
      const {items} = await read("/api/workers", {workerIds});
      const value = Array.isArray(this.value) ? items.map(worker2option) : items[0];
      this.$emit("input", value);
    },
    excludeWorkerOption(workers) {
      return workers.filter(worker => !this.excludeWorkerIds.includes(worker.workerId));
    },
  },
}
</script>
