


















import Vue from "vue";
import Component from "vue-class-component";
import {Prop} from "vue-property-decorator";
import ObjectSelect from "../Form/Select/ObjectSelect.vue";

interface Option {
  name: number;
  value: number;
}

@Component({
  components: {ObjectSelect}
})
export default class PerPageSelect extends Vue {
  @Prop({type: Number, required: true}) value!: number;
  @Prop({type: Array, default: ()=>[5, 10, 20]}) options!: Array<number>;
  @Prop({type: Boolean, default: null}) state!: boolean | null;
  @Prop({type: String, default: ""}) placeholder!: string;
  @Prop({type: Boolean, default: false}) disabled!: boolean;

  get optionObjects(): Array<Option> {
    return this.options.map(option => ({
      value: option,
      name: option,
    }));
  }

  get perPage(): Option | null {
    return this.optionObjects.find(optionObject => optionObject.value === this.value) || null;
  }

  update(option: Option): void {
    this.$emit("input", option.value);
  }
}
