<template>
  <b-modal
    id="finishEvaluationModal"
    class="text-left"
    no-close-on-backdrop
    title="Zakończ ewaluację"
    title-tag="h3"
  >
    Czy na pewno chcesz zakończyć ewaluację?
    <template #modal-footer>
      <button
        class="btn btn-secondary"
        @click="hideModal"
      >
        Powrót
      </button>
      <button
        class="btn btn-primary"
        @click="finishEvaluation"
      >
        <i
          class="fa 'fa-check'"
        />
        Zakończ ewaluację
      </button>
    </template>
  </b-modal>
</template>

<script>
import {errorsMixin} from "../../../mixins/errorsMixin.js";

export default {
  mixins: [errorsMixin],
  methods: {
    async finishEvaluation() {
      this.hideModal();
      this.$emit("finished");
    },
    hideModal() {
      this.$bvModal.hide("finishEvaluationModal");
    }
  }
}
</script>
