













import Vue from "vue";
import ObjectSelect from "@/components/Form/Select/ObjectSelect.vue";
import {Component, Prop} from "vue-property-decorator";
import type {Test} from "../../types/TestResult";
import read from "../../rest/read";

@Component({
  name: "TestSelect",
  components: {ObjectSelect},
})
export default class TestSelect extends Vue {
  @Prop({type: Boolean, default: false}) readonly clearable!: boolean;
  @Prop({type: Boolean, default: false}) readonly disabled!: boolean;
  @Prop({type: String, default: "Wybierz typ testu"}) readonly placeholder!: string;
  @Prop({type: Boolean, default: null}) readonly state!: boolean;
  @Prop({type: Object, default: null}) readonly value!: Test;

  private loading: boolean = false;
  options: Test[] = [];

  async mounted() {
    await this.loadOptions();
  }

  private async loadOptions(): Promise<void> {
    this.loading = true;
    const {items} = await read<{items: Test[]}>("/api/tests");
    this.options = items.map(item => ({...item, value: item.testId}));
    this.loading = false;
  }

  private update(option: Test|null): void {
    this.$emit("input", option);
  }
}
