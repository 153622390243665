'<template>
  <card
    :loading="loading"
    title="Skierowania"
  >
    <template #control>
      <b-btn
        size="sm"
        variant="primary"
        @click="loadItems"
      >
        <i class="fa fa-sync-alt" />
        Odśwież listę skierowań
      </b-btn>
    </template>
    <referrals-table
      :items="referrals"
      :show-doctor="true"
      @refresh="loadItems"
    />
    <pagination-description
      :items-count="referrals.length"
      :page="page"
      :per-page="pagination.perPage"
      :total-count="pagination.totalRows"
    >
      <b-pagination
        :value="page"
        :total-rows="pagination.totalRows"
        :per-page="pagination.perPage"
        align="right"
        class="mb-0"
        @input="changePage"
      />
    </pagination-description>
  </card>
</template>
<script>
import read from "../../../rest/read";
import Card from "../../Card";
import PaginationDescription from "../../Pagination/PaginationDescription";
import ReferralsTable from "../../Referrals/ReferralsTable";

export default {
  components: {ReferralsTable, PaginationDescription, Card,},
  props: {
    patientId: {required: true, type: String},
  },
  data() {
    return {
      referrals: [],
      loading: true,
      page: 1,
      pagination: {perPage: 5, totalRows: 0},
    };
  },
  watch: {
    patientId() {
      this.page = 1;
      this.loadItems();
    },
  },
  async mounted() {
    await this.loadItems();
  },
  methods: {
    async loadItems() {
      this.loading = true;
      const {items, pagination} = await read(`/api/patients/${this.patientId}/referrals`, {
        perPage: this.pagination.perPage,
        page: this.page,
      });
      this.pagination = pagination;
      this.referrals = items;
      this.loading = false;
    },
    changePage(page) {
      this.page = page;
      this.loadItems();
    },
  },
};
</script>
