































































































































































import {mixins} from "vue-class-component";
import {Component, Prop, Watch} from "vue-property-decorator";
import ErrorMessage from "../../../components/Form/ErrorMessage.vue";
import BooleanRefusalSelect from "../../../components/Form/Select/BooleanRefusalSelect.vue";
import PatientSelect from "../../../components/Patient/PatientSelect.vue";
import DatePicker from "../../../components/Form/DatePicker/DatePicker.vue";
import ErrorsMixin from "../../../mixins/ErrorsMixin";
import create from "../../../rest/create";
import read from "../../../rest/read";
import processResponseException from "../../../utils/errors/processResponseException";
import {generateUuid} from "../../../utils/uuid/generateUuid";
import stringifyDate from "../../../utils/date/stringifyDate";
import DATE_FORMAT from "../../../utils/date/DATE_FORMAT";
import Card from "../../../components/Card.vue";
import eventBus from "../../../eventBus";
import I18n from "../../../components/i18n.vue";
import BackButton from "../../../components/BackButton.vue";
import IKUPConditions from "./IKUPConditions.vue";
import ReportContainer from "../../../components/Report/ReportContainer.vue";
import PaginationDescription from "../../../components/Pagination/PaginationDescription.vue";
import {changePageQuery} from "../../../utils/pageUrl/handlePageQuery";
import {mapState} from "vuex";

interface IKUPAdults {
  id: string;
  createdAt: string;
  status: Statuses;
}

interface FindResponse {
  items: Array<IKUPAdults> | null;
  pagination: Pagination;
}

interface Pagination {
  perPage: number;
  totalRows: number;
}

interface Patient{
  patientId: string;
  displayName: string;
}

interface Field {
  key: string;
  label: string;
  class?: string;
}

interface Query {
  page: number | undefined;
}

interface Filters {
  page: number | undefined;
}

enum Statuses {
  PENDING = "pending",
  COMPLETED = "completed",
  REJECTED = "rejected",
}

@Component({
  components: {
    ReportContainer,
    PaginationDescription,
    IKUPConditions,
    BackButton,
    I18n,
    ErrorMessage,
    BooleanRefusalSelect,
    PatientSelect,
    DatePicker,
    Card,
  },
  computed: {
    ...mapState({
      patientAnonymous: state => state.clinicParameters.parameters.patientAnonymous,
    }),
  },
})
export default class IKUPAdultsSummary extends mixins(ErrorsMixin){
  @Prop({type: Number, default: null}) readonly page !: number;

  mounted(): void {
    this.loadData();
  }

  canceledAppointments: boolean|null = false;
  patientPresence: boolean|null = null;
  selectedPatients: Array<Patient> = [];
  startDate: Date|null = null;
  endDate: Date|null = null;
  items: Array<IKUPAdults> | null = null;
  isLoading: boolean = false;
  isSaving: boolean = false;
  currentPage: number = this.page || 1;
  pagination: Object = {};

  @Watch("page") onPageChange(page: number): void {
    this.currentPage = page;
  }

  get fields(): Array<Field> {
    return [
      {key: "createdAt", label: "Data zlecenia"},
      {key: "status", label: "Status", class: "text-center"},
      {key: "conditions", label: "Warunki"},
      {key: "download", label: "Pobierz", class: "text-center"},
    ];
  }

  clearForm(): void {
    this.patientPresence = null;
    this.canceledAppointments = false;
    this.selectedPatients = [];
    this.startDate = null;
    this.endDate = null;
    this.errors = [];
  }

  validateReportDates(): void {
    if (this.startDate && this.endDate
      && this.startDate.getTime() >= this.endDate.getTime()) {
      this.errors = [
        {
          message: "Data początkowa powinna być wcześniejsza niż data końcowa",
          field: "startDate"
        },
        {
          message: "Data końcowa powinna być późniejsza niż data początkowa",
          field: "endDate"
        },
      ];
      return;
    } else {
      const errors = []
      if (!this.startDate) {
        errors.push({
          message: "Pole nie powinno być puste",
          field: "startDate"
        })
      }
      if (!this.endDate) {
        errors.push({
          message: "Pole nie powinno być puste",
          field: "endDate"
        })
      }
      this.errors = errors;
      return;
    }
    this.errors = [];
  }

  changePage(page: number) {
    this.currentPage = page;
    this.loadData();
    changePageQuery(this.query());
  }

  filters():Filters {
    return {
      page: this.currentPage || undefined,
    }
  }

  query():Query {
    return {
      page: this.currentPage || undefined
    }
  }

  async loadData(): Promise<void>{
    this.isLoading = true;
    const {items, pagination} = await read<FindResponse>("/api/report/ikup-adult-multiple", this.filters());
    this.items = items;
    this.pagination = pagination;
    this.isLoading = false;
  }

  async createReport(): Promise<void> {
    try {
      this.isSaving = true;
      if(this.startDate && !this.endDate){
        this.endDate = new Date();
      }
      const requestIKUPReportRecordsId = generateUuid() as string;
      await create("/api/report/ikup-adult-multiple", {
        requestIKUPReportRecordsId: requestIKUPReportRecordsId,
        patientPresence: this.patientPresence,
        canceledAppointments: this.canceledAppointments,
        patients: this.selectedPatients.map((selectedPatient)=>{
          return selectedPatient.patientId;
        }),
        startDate: !!this.startDate ? stringifyDate(this.startDate, DATE_FORMAT.DATE) : null,
        endDate: !!this.endDate ? stringifyDate(this.endDate, DATE_FORMAT.DATE) : null,
        conditions: {
          patientPresence: this.patientPresence,
          patients: this.selectedPatients.map((selectedPatient)=>{
            return selectedPatient.displayName;
          }),
          startDate: !!this.startDate ? stringifyDate(this.startDate, DATE_FORMAT.DATE) : null,
          endDate: !!this.endDate ? stringifyDate(this.endDate, DATE_FORMAT.DATE) : null,
          canceledAppointments: this.canceledAppointments,
        }
      });
      this.clearForm();
      await this.loadData();
      eventBus.on(requestIKUPReportRecordsId, ()=>{ this.loadData() });
    } catch (exception) {
      this.errors = processResponseException(exception);
    }
    this.isSaving = false;
  }

  downloadStatusIconClass(status: Statuses): string | void {
    switch(status)  {
      case Statuses.PENDING:
        return "fa-spin fa-spinner";
      case Statuses.REJECTED:
        return "fa-ban text-danger";
      default:
        return;
    }
  }

  getBadgeVariant(status: Statuses): string {
    switch(status) {
      case Statuses.PENDING:
        return "success";
      case Statuses.COMPLETED:
        return "primary";
      case Statuses.REJECTED:
        return "danger";
      default:
        throw Error(`Not supported status: ${status}`);
    }
  }
}
