<template>
  <div>
    <transition name="fade">
      <div
        v-if="!attachments.length"
        class="text-center m-3"
      >
        <no-attachments-panel
          icon-class="fa-file"
          panel-text="Brak załączników powiązanych z tym pacjentem"
        />
      </div>
    </transition>
    <b-table
      v-if="attachments.length"
      :fields="fields"
      hover
      :items="attachments"
      :responsive="true"
    >
      <template #cell(name)="{item: {attachmentId, name}}">
        <tooltip :tooltip-content="`Pobierz plik ${name}`">
          <a
            class="text-body attachment-name-link"
            :href="`/api/files/${attachmentId}`"
            download
          >
            {{ name }}
          </a>
        </tooltip>
      </template>
      <template #cell(size)="{item: {size}}">
        <file-size :size="size" />
      </template>
      <template #cell(uploadDate)="{item: {uploadDate}}">
        <date-time :value="uploadDate" />
      </template>
      <template #cell(options)="{item}">
        <is-granted
          :privileges="['UPDATE_PATIENT_ATTACHMENT']"
          component="fragment"
        >
          <tooltip
            class="btn btn-sm btn-primary btn-block m-1"
            component="button"
            tooltip-content="Edytuj nazwę i kategorię wizyty"
            @click="openEditAttachmentModal(item)"
          >
            <span class="fas fa-pencil-alt" />
            Edytuj
          </tooltip>
        </is-granted>
        <template v-if="!hideAppointmentRefOptions && !item.continuousStayId">
          <tooltip
            v-if="item.appointmentId"
            class="btn btn-sm btn-outline-primary btn-block m-1"
            component="router-link"
            :to="{
              name: 'viewDocumentAppointment',
              params: {appointmentId : item.appointmentId},
              hash: `#appointmentDocumentId=${item.appointmentDocumentId}`,
            }"
            tooltip-content="Przejdź do karty wizyty"
          >
            Karta wizyty
          </tooltip>
          <tooltip
            class="btn btn-sm btn-outline-primary btn-block m-1"
            component="button"
            :tooltip-content="item.appointmentId ? 'Zmień powiązany dokument' : 'Dodaj informację o wizycie'"
            type="button"
            @click="openAddAttachmentAppointment(item.attachmentId, item.appointmentDocumentId)"
          >
            {{ item.appointmentId ? "Zmień" : "Przypisz" }} wizytę
          </tooltip>
        </template>
        <is-granted
          :privileges="['DELETE_ATTACHMENT']"
          component="fragment"
        >
          <tooltip
            class="btn btn-sm btn-outline-secondary btn-block m-1"
            component="button"
            tooltip-content="Usuń załącznik"
            type="button"
            @click="openDeleteAttachmentModal(item.attachmentId)"
          >
            Usuń
          </tooltip>
        </is-granted>
      </template>
    </b-table>
    <add-attachment-appointment
      :attachment-id="selectedAttachment ? selectedAttachment.attachmentId : null"
      :appointment-document-id="selectedAttachment ? selectedAttachment.appointmentDocumentId : null"
      :patient-id="patientId"
      @close="selectedAttachment = null"
      @input="updateAppointment($event)"
    />
    <delete-attachment-modal
      :attachment-id="deleteAttachmentId"
      @attachmentDeleted="attachmentDeleted($event)"
    />
    <edit-attachment-modal
      :id="editAttachmentModalId"
      :attachment="selectedAttachment"
      @close="selectedAttachment = null"
      @updated="updateAttachment($event)"
    />
  </div>
</template>

<script>
import FileSize from "../../../File/FileSize";
import DateTime from "../../../DateTime";
import Tooltip from "../../../Tooltip";
import AddAttachmentAppointment from "./AddAttachmentAppointment";
import DeleteAttachmentModal from "./DeleteAttachmentModal";
import IsGranted from "../../../IsGranted";
import NoAttachmentsPanel from "./NoAttachmentsPanel";
import EditAttachmentModal from "./EditAttachmentModal";

export default {
  name: "PatientAttachmentsList",
  components: {
    EditAttachmentModal,
    NoAttachmentsPanel,
    IsGranted,
    DeleteAttachmentModal,
    AddAttachmentAppointment,
    Tooltip,
    DateTime,
    FileSize,
  },
  props: {
    attachments: {type: Array, required: true},
    patientId: {type: String, required: true},
    hideAppointmentRefOptions: {type: Boolean, required: true, default: false},
  },
  data() {
    return {
      selectedAttachment: null,
      deleteAttachmentId: null,
    };
  },
  computed: {
    fields() {
      return [
        {key: "name", label: "Nazwa pliku", class: "attachment-name",},
        {key: "size", label: "Rozmiar pliku", class: "attachment-size",},
        {key: "uploadDate", label: "Data dodania", class: "attachment-date",},
        {key: "options", label: "Opcje", class: "attachment-options",},
      ];
    },
    editAttachmentModalId() {
      return "edit-attachment";
    },
  },
  methods: {
    updateAttachment({attachmentId, category, name}) {
      this.$emit("categoryChange", attachmentId, category);
      this.$emit("filenameChange", attachmentId, name);
    },
    updateAppointment({attachmentId, appointment}) {
      this.$emit("appointmentChange", attachmentId, appointment);
    },
    openAddAttachmentAppointment(attachmentId, appointmentDocumentId) {
      this.selectedAttachment = {attachmentId, appointmentDocumentId};
      this.$bvModal.show("add-attachment-appointment");
    },
    openDeleteAttachmentModal(attachmentId) {
      this.deleteAttachmentId = attachmentId;
      this.$bvModal.show("delete-attachment-modal");
    },
    attachmentDeleted(attachmentId) {
      this.$emit("attachmentDeleted", attachmentId);
    },
    openEditAttachmentModal(attachment) {
      this.selectedAttachment = attachment;
      this.$bvModal.show(this.editAttachmentModalId)
    },
  }
}
</script>

<style scoped lang="scss">
  @import "../../../../styles/variables";

  ::v-deep .attachment {
    &-name {
      min-width: 130px;
      word-break: break-all;

      &-link:hover {
        font-weight: 700;
      }
    }

    &-size {
      text-align: right;
      min-width: 65px;
    }

    &-date {
      text-align: center;
      min-width: 65px;
    }

    &-options {
      width: 115px;
      text-align: center;
    }
  }
</style>
