<template>
  <span>
    <i :class="iconClass" />
    <br v-if="vertical && text">
    <template v-if="text">
      {{ loading ? "Trwa wczytywanie...": "Gotowe." }}
    </template>
  </span>
</template>
<script>
export default {
  name: "LoadingIcon",
  props: {
    loading: {type: Boolean, default: true},
    text: {type: Boolean, default: false},
    size: {type: String, default: "md"},
    vertical: {type: Boolean, default: false},
    readyIcon: {type: String, default : "fa-check"}
  },
  computed: {
    iconSize() {
      switch (this.size) {
        case "sm":
          return 1;
        case "md":
          return 2;
        case "lg":
          return 3;
        default:
          throw new Error(`Unsupported size: '${this.size}'`);
      }
    },
    iconClass() {
      return [
        "fa",
        `fa-${this.iconSize}x`,
        ...this.loading ? [
          "fa-spinner",
          "fa-spin"
        ] : [
          this.readyIcon
        ],
      ];
    }
  }
}
</script>
