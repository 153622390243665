<template>
  <tooltip
    :component="component"
    :tooltip-content="name"
  >
    {{ shortName }}
  </tooltip>
</template>

<script>
import read from "../../rest/read";
import Tooltip from "../Tooltip";

const data = {
  treatmentsList: [],
  loaded: false,
};

export default {
  name: "TreatmentTypeNameTooltip",
  components: {Tooltip},
  props: {
    value: {type: String, required: true},
    component: {type: String, default: "div"},
  },
  data() {
    return data;
  },
  computed: {
    name(){
      const treatmentType = this.treatmentsList.find(option => option.value === this.value);
      if (null == treatmentType) {
        return this.value;
      }
      return treatmentType.name;
    },
    shortName() {
      const names = this.name.split(" ⇒ ");
      return names[names.length - 1];
    },
  },
  async mounted() {
    if (!this.loaded) {
      this.loaded = true;
      const {items} = await read("/api/treatment-types", {wantTreeStructure: 0});
      this.treatmentsList = items;
    }
  },
}
</script>
