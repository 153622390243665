





































import {Component, Prop} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import ErrorsMixin from "../../../../mixins/ErrorsMixin.ts";
import processResponseException from "../../../../utils/errors/processResponseException";
import remove from "../../../../rest/remove";

interface Hospital {
  name: string,
  address: string,
}

interface Hospitalization {
  hospital: Hospital,
  hospitalizationId: string,
}
@Component({name: "PatientPsychiatricHospitalizationsArchiveConfirmationModal"})
export default class PatientPsychiatricHospitalizationsArchiveConfirmationModal extends mixins(ErrorsMixin) {
  @Prop({type: Object, default: null}) readonly hospitalization!: Hospitalization;

  loading: boolean = false;

  get modalVisible(): boolean {
    return !!this.hospitalization;
  }

  closeModal(): void {
    this.$emit("closeModal");
  }
  async submit(): Promise<void> {
    this.loading = true;
    try {
      await remove(`/api/hospitalizations/${this.hospitalization.hospitalizationId}`, {});
      this.$emit("removed");
    } catch (exception) {
      this.errors = processResponseException(exception);
    }
    this.loading = false;
  }
}
