




































import {Component, Prop, Vue} from "vue-property-decorator";
import TextareaCounter from "../Form/Textarea/TextareaCounter.vue";
import {NoteItem} from "../../types/Notes";
import DateTime from "../DateTime.vue";
import DATE_FORMAT from "../../utils/date/DATE_FORMAT";
import parseDate from "../../utils/date/parseDate";

@Component({
  name: "OfflineNotes",
  components: {
    DateTime,
    TextareaCounter,
  },
})
export default class OfflineNotes extends Vue {
  @Prop({type: Array, default: ()=>[]}) readonly notes!: NoteItem[];
  DATE_FORMAT = DATE_FORMAT;

  private dateValue(date): Date {
    return parseDate(date, DATE_FORMAT.DEFAULT);
  }
}
