<template>
  <b-modal
    id="update-permissions"
    title="Edycja zgód pacjenta"
    title-tag="h3"
    size="lg"
    @hide="resetModalData"
  >
    <template #default>
      <loading-icon v-if="hasUpdatePermission == null" />
      <b-form-group
        v-for="(patientPermission, index) in patientPermissionsList"
        v-else
        :key="index"
      >
        <b-form-checkbox
          v-model="permissionsFormData[patientPermission.propertyName]"
          :disabled="disabled[patientPermission.propertyName]"
          size="lg"
          switch
        >
          {{ patientPermission.updateLabel }}
        </b-form-checkbox>
        <div v-if="disabled[patientPermission.propertyName]">
          {{ patientPermission.updateDisabledDescription }}
        </div>
        <div v-else>
          {{ patientPermission.updateDescription }}
        </div>
        <error-message
          :errors="errors"
          :field="`permissions.${patientPermission.propertyName}`"
        />
      </b-form-group>
      <error-message
        :errors="errors"
      />
    </template>
    <template #modal-footer>
      <button
        type="button"
        class="btn btn-secondary"
        @click="hideModal"
      >
        Anuluj
      </button>
      <button
        :disabled="loading"
        class="btn btn-primary"
        @click="updatePermission"
      >
        <i
          :class="loading ? 'fa-spin fa-spinner' : 'fa-check'"
          class="fa"
        />
        Zapisz
      </button>
    </template>
  </b-modal>
</template>

<script>
import {mapActions} from "vuex";
import LoadingIcon from "@/components/Loading/LoadingIcon";
import {isGranted} from "@/security/isGranted";
import permissionsList from "../../../../components/Patient/patientPermissions.ts";
import ErrorMessage from "@/components/Form/ErrorMessage";
import processResponseException from "@/utils/errors/processResponseException";
import update from "@/rest/update";
import {errorsMixin} from "../../../../mixins/errorsMixin.js";

export default {
  name: "PatientCardUpdatePermissions",
  components: {
    LoadingIcon,
    ErrorMessage
  },
  mixins: [errorsMixin],
  props: {
    patient: {type: Object, required: true},
    permissions: {type: Object, required: true},
    disabled: {type:Object, required: true},
    patientType: {type: String, required: true}
  },
  data() {
    return {
      permissionsList,
      patientPermissionsList: permissionsList.filter(
        permission => permission.types.includes(this.patientType)
      ),
      hasUpdatePermission: null,
      permissionsFormData: {...this.permissions},
      loading: false
    }
  },
  watch: {
    patient: {
      immediate: true,
      async handler(newVal) {
        this.hasUpdatePermission = null;
        this.hasUpdatePermission = await isGranted(
          ["UPDATE_PATIENT_PERMISSIONS"],
          newVal.patientId,
        )
      }
    },
    permissions() {
      this.permissionsFormData = {...this.permissions};
      if(!this.permissions.permissionForProcessingPersonalData){
        this.redirectToPatientList();
      }
    }
  },
  methods: {
    ...mapActions({
      clearStickyPatient: "stickyPatient/clear",
    }),
    hideModal() {
      this.$bvModal.hide("update-permissions");
    },
    redirectToPatientList() {
      this.$router.push({name: "listPatients"});
      this.clearStickyPatient();
    },
    async updatePermission() {
      this.loading = true;
      try {
        await update(`/api/patients/${this.patient.patientId}/permissions`, {
          permissions: this.permissionsFormData
        });
        this.$emit("fetchPermissions");
        this.hideModal()
      } catch (exception) {
        this.errors = processResponseException(exception);
      }
      this.loading = false;
    },
    resetModalData(){
      this.permissionsFormData = {...this.permissions}
      this.errors = [];
    }
  },
}
</script>
