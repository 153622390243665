<template>
  <div
    v-if="appointmentPlan"
    class="card-box"
  >
    <b-form-group
      label="Plan wizyty"
    >
      {{ appointmentPlan }}
    </b-form-group>
  </div>
</template>

<script>
import read from "../../rest/read";

export default {
  name: "AppointmentPlan",
  props: {
    appointmentId: {type: String, required: true},
  },
  data() {
    return {
      appointmentPlan: null,
    };
  },
  watch: {
    appointmentId: {
      immediate: true,
      async handler() {
        await this.getAppointmentPlan();
      },
    },
  },
  methods: {
    async getAppointmentPlan() {
      try {
        const {plan} = await read(`/api/appointments/${this.appointmentId}`);
        this.appointmentPlan = plan;
      } catch(e) {
        console.error(e);
      }
    },
  },
}
</script>
