<template>
  <div>
    <registration-filters
      :filters="{start, selectedWorkerId, dayView}"
      @reload="fetchData"
    />

    <transition
      name="fade"
      mode="out-in"
    >
      <registration-widget
        v-if="selectedWorkerId"
        key="registrationCalendar"
        ref="registrationWidget"
        :filters="{start, selectedWorkerId, dayView}"
      />

      <div
        v-else
        key="registrationInfo"
        class="card-box"
      >
        <span class="d-block text-center">
          Należy wybrać specjalistę i wcisnąć przycisk szukaj, aby zobaczyć jego kalendarz dostępności
        </span>
      </div>
    </transition>
  </div>
</template>

<script>
import {mapState} from "vuex";
import RegistrationFilters from "../../components/Registration/RegistrationFilters";
import RegistrationWidget from "../../components/Registration/RegistrationWidget";
import isEmpty from "lodash/isEmpty";

export default {
  name: "Registration",
  components: {RegistrationWidget, RegistrationFilters},
  props: {
    start: {type: String, default: null},
    selectedWorkerId: {type: String, default: null},
    dayView: {type: Boolean, default: true}
  },
  computed: {
    ...mapState("registrationFilters", ["savedFilters",]),
  },
  watch: {
    "$route": {
      immediate: true,
      async handler() {
        if (isEmpty(this.$route.query) && this.savedFilters) {
          await this.$router.replace({
            path: this.$route.path,
            query: this.savedFilters
          });
          return;
        }

        await this.fetchData();
      }
    },
  },
  methods: {
    async fetchData() {
      setTimeout(() => {
        this.$refs.registrationWidget && this.$refs.registrationWidget.fetchData()
      });
    }
  },
}
</script>

<style scoped lang="scss">
  .fade-enter-active, .fade-leave-active {
    transition: opacity .4s;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
</style>
