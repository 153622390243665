<template>
  <div>
    <b-btn
      v-if="!readOnly"
      class="m-1"
      :disabled="isSaving && close"
      @click="$emit('save', false)"
    >
      <i
        :class="isSaving && !close ? 'fa-spin fa-spinner' : 'fa-check'"
        class="fas"
      />
      Zapisz
    </b-btn>
    <b-btn
      v-if="!readOnly && status === 'opened'"
      v-b-modal.force-finish-appointment
      class="m-1"
      :disabled="(isSaving && !close) || futureAppointment"
      variant="primary"
    >
      <i
        :class="isSaving && close ? 'fa-spin fa-spinner' : 'fa-check'"
        class="fas"
      />
      {{ futureAppointment
        ? "Nie możesz zamknąć wizyty przed jej rozpoczęciem"
        : "Zapisz i zamknij" }}
    </b-btn>
    <!--FinishAppointmentModal is part of Document.vue - do not duplicate it-->
  </div>
</template>

<script>
export default {
  name: "AppointmentDocumentSaveButtons",
  props: {
    close: {type: Boolean, required: true},
    isSaving: {type: Boolean, default: false},
    readOnly: {type: Boolean, default: false},
    scheduledDate: {type: String, required: true},
    status: {type: String, required: true},
  },
  computed: {
    futureAppointment() {
      return new Date(this.scheduledDate) > new Date();
    },
  },
}
</script>
