import {Component, Mixins, Prop, Watch} from "vue-property-decorator";
import ErrorsMixin from "./ErrorsMixin";
import read from "../rest/read";
import processResponseException from "../utils/errors/processResponseException";
import update from "../rest/update";
import {InsuranceHistory} from "../types/Insurance";

interface Insurance {
  insured: null|boolean,
  lastUpdateDate: null|string,
  provider: null|string,
  additionalInformation: null|string,
  insuranceHistory: null|InsuranceHistory
}

@Component({
  name: "PatientInsurance",
})

export default class PatientInsuranceMixin extends Mixins(ErrorsMixin) {
  @Prop({type: String, required: true}) readonly patientId!: string;

  isLoading: boolean = false;
  insurance: Insurance = {
    insured: null,
    lastUpdateDate: null,
    provider: null,
    additionalInformation: null,
    insuranceHistory: null
  };

  get insured(): string
  {
    if (null === this.insurance.insured) {
      return "Brak danych";
    }

    return this.insurance.insured ? "Pacjent ubezpieczony" : "Pacjent nieubezpieczony";
  }

  @Watch("patientId") async onPatientIdChange(): Promise<void>
  {
    await this.loadData();
  }

  async mounted(): Promise<void>
  {
    await this.loadData();
  }

  async loadData(): Promise<void>
  {
    this.isLoading = true;
    try {
      this.insurance = await read<Insurance>(`/api/patient/get-insurance-by-patient-id/${this.patientId}`);
    } catch(e) {
      this.errors = processResponseException(e);
    }
    this.isLoading = false;
  }

  async checkStatus(): Promise<void>
  {
    this.errors = [];
    this.isLoading = true;
    try {
      await update(`/api/update-ewus-status/${this.patientId}`, {});
    } catch(e) {
      this.errors = processResponseException(e);
    }
    this.isLoading = false;
    await this.loadData();
  }
}
