<template>
  <patient-data-unification-card
    :columns="['patient', 'dataUnificationStatus', 'invalidTreatmentTypes', 'incompleteData', 'unifyPatient']"
    :patient="patient"
    :statuses="statuses"
    :workers="workers"
    :case-managers="caseManagers"
    title="Lista pacjentów"
  />
</template>

<script>
import PatientDataUnificationCard from "../../components/Patient/DataUnification/PatientDataUnificationCard";

export default {
  name: "DataUnification",
  components: {PatientDataUnificationCard},
  props: {
    patient: {type: String, default: null},
    statuses: {type: Array, default: ()=>[]},
    caseManagers: {type: Array, default: ()=>[]},
    workers: {type: Array, default: ()=>[]},
    page: {type: Number, default: null},
  },
}
</script>
