<template>
  <div
    :class="{'is-active': menuOpened}"
    class="hamburger hamburger--arrow"
    @click="toggleMenu"
  >
    <span class="hamburger-box">
      <span class="hamburger-inner" />
    </span>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  data() {
    return {
      mediaQuery: "(max-width: 767.98px)",
    };
  },
  computed: {
    ...mapState("sidebar", ["menuOpened"]),
  },
  created() {
    if (window.matchMedia(this.mediaQuery).matches) {
      this.closeMenu();
    }
  },
  methods: {
    ...mapActions("sidebar", ["toggleMenu", "closeMenu"]),
  },
};
</script>
<style lang="scss">
$hamburger-types: (arrow);
$hamburger-active-layer-color: #fff;
$hamburger-layer-color: #fff;
$hamburger-padding-x: 0;
$hamburger-padding-y: 0;
@import "../../../node_modules/hamburgers/_sass/hamburgers/hamburgers";
.hamburger {
  transform: scale(.75);
  height: 47px;
  margin: 0 9px 0 18px;
}
.logo {
  vertical-align: super;
}

.hamburger-inner {
  top: 90%;
}
</style>
