import {isProduction} from "../isProduction";
import eventBus, {EVENT} from "../eventBus";
import {
  BadRequestException,
  defaults,
  ForbiddenException,
  NotFoundException,
  ServiceUnavailableException,
  ConflictException,
  UnauthorizedException,
  UnknownServerException,
  NetworkFailureException,
} from "./index";
import {store} from "../main";

export default async function update<Model extends object>(resource: string, model: Model): Promise<void> {
  const body = JSON.stringify(model);
  const method = "PUT";
  let response;

  try {
    response = await fetch(resource, {...defaults, method, body,});
  } catch(e) {
    await store.dispatch("splashScreen/showNetworkFailure");
    throw new NetworkFailureException();  }

  if (response.ok) {
    eventBus.emit(EVENT.SAVE);
    if (!isProduction) {
      console.assert(response.status === 200, "Invalid response code", response);
    }
    return;
  }

  switch(response.status) {
    case 503:
      throw new ServiceUnavailableException();
    case 409:
      throw new ConflictException();
    case 400:
      throw new BadRequestException(await response.json());
    case 404:
      throw new NotFoundException();
    case 403:
      throw new ForbiddenException();
    case 401:
      throw new UnauthorizedException();
    default:
      throw new UnknownServerException(response);
  }
}
