import i18next from "i18next";

export function initTranslations(context: string): void {
  i18next.init({
    nsSeparator: false,
    keySeparator: false,
    lng: "pl",
    saveMissing: true,
    resources: {
      pl: {
        translation: {
          ...require("./translations/messages.pl.po"),
        }
      }
    }
  });
}

const t = (key: string): string => i18next.t(key);

export default t;
