<template>
  <div>
    <loading-mask
      :loading="loading"
    >
      <bar-chart
        v-if="chartData"
        :data="chartData"
        :colors="['#3369C6', '#FF9330', '#009827']"
        :title="[title]"
        height="550px"
      />
      <div v-else>
        Brak danych
      </div>
    </loading-mask>
  </div>
</template>

<script>
import LoadingMask from "../../Loading/LoadingMask";
import read from "../../../rest/read";
import Vue from "vue"
import VueChartkick from "vue-chartkick";
import Chart from "chart.js";

Vue.use(VueChartkick, {adapter: Chart});

export default {
  name: "IcfChangeComparisonChart",
  components: {LoadingMask},
  props: {
    ipzId: {type: String, required: true},
  },
  data() {
    return {
      loading: false,
      chartData: null,
      title: ""
    };
  },
  async mounted() {
    await this.loadChartData();
  },
  methods: {
    async loadChartData() {
      this.loading = true;

      const {items, title} = await read(`/api/ipzs/${this.ipzId}/icf-change-comparison`);
      this.chartData = items.map((item) => [item.title, item.value]).filter((item) => parseInt(item[1]) !== 0);
      this.title = title;

      this.loading = false;
    },
  },
}
</script>

<style scoped>

</style>
