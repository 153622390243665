<template>
  <b-modal
    id="check-in-withdrawal-modal"
    :title="title"
    no-close-on-backdrop
    title-tag="h3"
    @hide="resetModalData"
  >
    <template #default>
      <appointment-details
        :appointment-id="appointmentId"
        :date="date"
        :patients="patients"
        :main-worker="mainWorker"
      />
      <form-row
        :errors="errors"
        label="Podaj powód cofnięcia"
        field="checkInWithdrawalReason"
      >
        <resizable-textarea
          v-model="checkInWithdrawalReason"
          :state="state('checkInWithdrawalReason')"
          rows="3"
        />
      </form-row>
      <error-message :errors="errors" />
    </template>
    <template #modal-footer>
      <button
        type="button"
        class="btn btn-secondary"
        @click="closeModal"
      >
        Zamknij
      </button>
      <button
        type="submit"
        class="btn btn-primary"
        @click="withdraw"
      >
        Cofnij potwierdzenie
      </button>
    </template>
  </b-modal>
</template>

<script>
import AppointmentDetails from "../AppointmentDetails";
import ErrorMessage from "../../Form/ErrorMessage";
import update from "../../../rest/update";
import FormRow from "../../Form/FormRow";
import {errorsMixin} from "../../../mixins/errorsMixin.js";
import processResponseException
  from "../../../utils/errors/processResponseException";
import ResizableTextarea from "../../Form/Textarea/ResizableTextarea";

export default {
  components: {
    ResizableTextarea,
    FormRow,
    ErrorMessage,
    AppointmentDetails,
  },
  mixins: [errorsMixin],
  props: {
    id: {type: String, default: null},
    appointmentId: {required: true, type: String},
    title: {required: true, type: String},
    date: {type: Date, default: null},
    group: {type: Boolean, default: false},
    patients: {type: Array, default: () => []},
    patientId: {type: String, default: null},
    mainWorker: {type: Object, default: null},
  },
  data() {
    return {
      checkInWithdrawalReason: "",
    }
  },
  methods: {
    async withdraw() {
      this.errors = [];
      this.checkInWithdrawalReason = this.checkInWithdrawalReason.trim();
      try {
        await update(`/api/appointment/${this.appointmentId}/withdraw`, {
          checkInWithdrawalReason: this.checkInWithdrawalReason
        });
        this.$emit("appointmentCheckInWithdrawn");
        this.closeModal();
      } catch (exception) {
        this.errors = processResponseException(exception)
      }
    },
    resetModalData(){
      this.errors = [];
      this.checkInWithdrawalReason = "";
    },
    closeModal() {
      this.$bvModal.hide("check-in-withdrawal-modal");
    },
  },
}
</script>
