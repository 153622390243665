<template>
  <div>
    <address-form
      v-model="formData"
      :disabled="loading"
      :errors="subErrors('address')"
      :horizontal="true"
      :visible-country="false"
      :in-modal="true"
    />
    <error-message :errors="errors" />
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-secondary"
        @click="cancel"
      >
        Anuluj
      </button>
      <button
        :disabled="loading"
        type="button"
        class="btn btn-primary"
        @click="onSubmit"
      >
        <i
          :class="loading ? 'fa-spin fa-spinner' : 'fa-check'"
          class="fa"
        />
        Zapisz
      </button>
    </div>
  </div>
</template>

<script>
import update from "../../rest/update";
import read from "../../rest/read";
import AddressForm from "../Address/AddressForm";
import getSubErrors from "../../utils/errors/subErrors";
import processResponseException from "../../utils/errors/processResponseException";
import ErrorMessage from "../Form/ErrorMessage";

export default {
  components: {
    ErrorMessage,
    AddressForm,
  },
  props: {
    patientId: {type: String, required: true},
    livingAddress: {type: Object, default: null},
  },
  data() {
    return {
      address: this.livingAddress ? this.livingAddress : {},
      formData: {},
      errors: [],
      loading: false,
    };
  },
  watch: {
    livingAddress() {
      this.address = {...this.livingAddress};
      this.formData = {...this.address};
    },
    async patientId() {
      if (!this.livingAddress) {
        await this.getLivingAddress();
        this.formData = {...this.address};
      }
    },
  },
  async mounted() {
    if (!this.livingAddress) {
      await this.getLivingAddress();
    }
    this.formData = {...this.address};
  },
  methods: {
    async getLivingAddress() {
      this.loading = true;
      try {
        this.address = await read(`/api/patients/${this.patientId}/living_address`);
        this.$emit("updateLivingAddress", this.address);
      } catch(exception) {
        this.errors = processResponseException(exception);
      }
      this.loading = false;
    },
    subErrors(field) {
      return getSubErrors(this.errors, field);
    },
    async onSubmit() {
      this.loading = true;
      try{
        await update(`/api/patients/${this.patientId}/living_address`, {address: this.formData});
        this.address = await read(`/api/patients/${this.patientId}/living_address`);
        this.$emit("updateLivingAddress", this.address);
        this.cancel();
      } catch (exception) {
        this.errors = processResponseException(exception);
      }
      this.loading = false;
    },
    cancel() {
      this.resetData();
      this.$emit("cancel");
    },
    resetData() {
      this.formData = {...this.address};
      this.errors = [];
    },
  },
};
</script>
