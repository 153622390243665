<template>
  <div v-if="null != title">
    <Card
      :title="title"
      description="Możesz dodać aplikację Auxilio do ekranu głównego na tym urządzeniu."
    >
      <button
        :disabled="!browserHandler"
        class="btn btn-block btn-success"
        @click="showPrompt()"
      >
        <i class="fa fa-plus" />
        Dodaj do ekranu głównego
      </button>
    </Card>
  </div>
</template>
<script>
import Card from "../Card";
import {isProduction} from "./../../isProduction";

export default {
  components: {Card},
  data() {
    return {
      devPreview: !isProduction && Math.random() < 0.5,
      browserHandler: null,
    };
  },
  computed: {
    title() {
      const title = "Dodaj do ekranu głównego";
      if (this.browserHandler) {
        return title;
      }
      if (this.devPreview) {
        return `${title} (preview)`
      }

      return null;
    },
  },
  mounted() {
    if (isProduction) {
      window.addEventListener("beforeinstallprompt", this.beforeInstall);
      window.addEventListener("appinstalled", this.afterInstall);
    }
  },
  destroy() {
    if (isProduction) {
      window.removeEventListener("beforeinstallprompt", this.beforeInstall);
      window.removeEventListener("appinstalled", this.afterInstall);
    }
  },
  methods: {
    beforeInstall(event) {
      event.preventDefault();
      this.browserHandler = event;
    },
    afterInstall() {
      this.browserHandler = null;
    },
    async showPrompt() {
      this.browserHandler.prompt();
      await this.browserHandler.userChoice;
      this.browserHandler = null;
    }
  },
};
</script>
