<template>
  <div>
    <form-row
      label="Kod ICD-10"
    >
      <icd10-code-select
        v-model="recognition.icd10Code"
        :disabled="disabled"
        :state="state('recognitions')"
        clear-after-select
        @onSelectCode="addRecognition"
      />
      <error-message
        :errors="errors"
        field="recognitions"
      />
    </form-row>
    <div
      v-if="recognitions.length"
      class="table-responsive"
    >
      <table class="table">
        <thead>
          <tr>
            <th class="w-25">
              Kod rozpoznania
            </th>
            <th class="w-50">
              Opis
            </th>
            <th class="w-25" />
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(recognitionItem, index) in recognitions"
            :key="index"
          >
            <td style="width: 35%">
              <error-message
                :errors="errors"
                :field="`recognitions[${index}][icd10Code]`"
              />
              {{ recognitionItem.icd10Code.name }}
            </td>
            <td style="width: 60%">
              <error-message
                :errors="errors"
                :field="`recognitions[${index}][comments]`"
              />
              <textarea-counter
                v-model="recognitionItem.comments"
                :disabled="disabled"
                :max-characters-count="maxCharactersCount"
                :state="state(`recognitions[${index}][comments]`)"
                class="icd10-desc"
                @input="updateComment($event, index)"
              />
            </td>
            <td style="width: 5%">
              <button
                type="button"
                class="btn btn-outline-secondary btn-sm mt-3"
                @click="removeItem(index)"
              >
                <i class="fa fa-trash" />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import Icd10CodeSelect from "../Form/Select/Icd10CodeSelect";
import ErrorMessage from "../Form/ErrorMessage";
import FormRow from "../Form/FormRow";
import TextareaCounter from "../Form/Textarea/TextareaCounter";

export default {
  components: {TextareaCounter, FormRow, ErrorMessage, Icd10CodeSelect},
  model: {
    prop: "recognitions",
  },
  props: {
    disabled: {type: Boolean, default: false},
    recognitions: {type: Array, default: () => []},
    errors: {type: Array, default: () => []},
  },
  data() {
    return {
      maxCharactersCount: 512,
      recognition: {},
    };
  },
  methods: {
    addRecognition(option) {
      if (!this.recognitions.find(recognition => recognition.code === option.value)) {
        const recognitions = [...this.recognitions, {icd10Code: option}];
        this.$emit("input", recognitions);
      }
    },
    removeItem(index) {
      this.$emit("input", this.recognitions.filter((r, i) => i !== index));
    },
    state(field) {
      return this.errors.find((error) => error.field === field) ? false : null;
    },
    updateComment(event, index) {
      this.removeCommentWhiteSpace(event, index);
    },
    removeCommentWhiteSpace(text, index) {
      const recognitions = this.recognitions;
      recognitions[index].comments = text;
      this.$emit("input", recognitions);
    },
  },
}
</script>

<style scoped lang="scss">
  .table th, .table td {
    word-wrap: break-word;
  }
</style>
