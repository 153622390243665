<template>
  <loading-mask :loading="isLoading">
    <div class="text-right m-b-10">
      <button
        class="btn btn-sm btn-secondary m-1"
        @click="showAll"
      >
        Rozwiń wszystko
      </button>
      <a
        v-if="atLeastOneEntry"
        :href="`/api/diagnosis/${diagnosisId}/print`"
        class="btn btn-sm btn-primary m-1"
        target="_blank"
      >
        <i class="fa fa-print" />
        Drukuj diagnozę
      </a>
    </div>
    <diagnosis-collapse
      v-for="(item, index) in sortedItems"
      :key="index"
      v-model="visibleDetailsIndexes"
      :diagnosis-type="diagnosisType"
      :entries="detailsEntries(item.details)"
      :index="index"
      :title="item.title"
    >
      <component
        :is="resolveFieldType(field.entryType.type)"
        v-for="field in item.details"
        :key="field.entryType.key"
        :field="field"
        :diagnosis-id="diagnosisId"
        :read-only="readOnly"
        :text-entry-max-length="textEntryMaxLength"
        @new-entry-added="entryAdded"
      />
    </diagnosis-collapse>
  </loading-mask>
</template>
<script>
import MultiselectField from "./MultiselectField";
import RadioField from "./RadioField";
import CheckboxField from "./CheckboxField";
import NumberField from "./NumberField";
import StringField from "./StringField/StringField";
import TextField from "./TextField/TextField";
import read from "../../../rest/read";
import LoadingMask from "../../Loading/LoadingMask";
import DiagnosisCollapse from "../DiagnosisCollapse";

export default {
  name: "DiagnosisStructure",
  components: {DiagnosisCollapse, LoadingMask},
  props: {
    readOnly: {type: Boolean, required: true},
    diagnosisType: {type: String, required: true},
    diagnosisId: {type: String, required: true},
    items: {type: Array, required: true},
    structureVersion: {type: String, required: true},
    textEntryMaxLength: {type: Number, default: 2500},
  },
  data() {
    return {
      isLoading: true,
      visibleDetailsIndexes: [],
      structure: [],
      data: [],
    };
  },
  computed: {
    atLeastOneEntry() {
      return this.items.length > 0
    },
    sortedItems() {
      const disableCounterTypes = [
        "string",
        "number",
        "radio",
        "checkbox",
        "multiselect",
      ];
      return this.structure.map(detailsObject => ({
        title: detailsObject.title,
        details: detailsObject.fields.map(entryType => {
          const item = this.items.find(item => item.entryType === entryType.key);
          const entries = item ? item.entries : [];
          return {
            entries: entries.map(entry => ({
              ...entry,
              disableCounter: disableCounterTypes.includes(entryType.type),
            })),
            entryType,
          }
        }),
      }));
    },
  },
  watch: {
    structureVersion() {
      this.loadStructure();
    },
  },
  async mounted() {
    await this.loadStructure();
  },
  methods: {
    entryAdded() {
      this.$emit("load");
    },
    async loadStructure() {
      this.isLoading = true;
      const {data} = await read(`/api/get-diagnosis-structure/${this.diagnosisType}?version=${this.structureVersion}`);
      this.structure = data.groups;
      this.isLoading = false;
    },
    resolveFieldType(type) {
      switch (type) {
        case "text":
          return TextField;
        case "string":
          return StringField;
        case "number":
          return NumberField;
        case "radio":
          return RadioField;
        case "checkbox":
          return CheckboxField;
        case "multiselect":
          return MultiselectField;
        default:
          throw Error(`Not supported field type: ${type}`);
      }
    },
    showAll() {
      this.visibleDetailsIndexes = this.sortedItems.map((item, index) => index);
    },
    detailsEntries(details) {
      return details.reduce((list, detail) => {
        return [...list, ...detail.entries];
      }, [])
    },
  },
}
</script>
