
















































import {Component, Vue} from "vue-property-decorator";
import DatePicker from "../../../components/Form/DatePicker/DatePicker.vue";
import ReportContainer from "../../../components/Report/ReportContainer.vue";
import WorkerSelect from "../../../components/Worker/WorkerSelect.vue";
import DATE_FORMAT from "../../../utils/date/DATE_FORMAT";
import stringifyDate from "../../../utils/date/stringifyDate";
import {createUrl} from "../../../utils/pageUrl/createUrl";

@Component({
  name: "WorkOnIpzReport",
  components: {DatePicker, ReportContainer, WorkerSelect},
})
export default class WorkOnIpzReport extends Vue {
  public workers: Array<{value: string}>;
  public startDate: Date;
  public endDate: Date;

  constructor() {
    super();
    this.workers = [];
    this.endDate = new Date();

    this.startDate = new Date();
    this.startDate.setDate(1);
    this.startDate.setMonth(this.startDate.getMonth() - 1);
  }

  public get downloadUrl(): string {
    return createUrl("/api/report/work-on-ipz", {
      workerIds: this.workers.map((w) => w.value),
      startDate: stringifyDate(this.startDate, DATE_FORMAT.DATE),
      endDate: stringifyDate(this.endDate, DATE_FORMAT.DATE)
    });
  }

  public get valid(): boolean {
    return (
      this.workers.length > 0 &&
      this.workers.length <= 30 &&
      this.startDate < new Date() &&
      this.endDate <= new Date() &&
      this.startDate <= this.endDate
    );
  }
}
