import {MutationTree} from "vuex";
import {InterventionNotificationsState} from "./types";

export const mutations: MutationTree<InterventionNotificationsState> = {
  setData(state, {items, pagination}) {
    state.items = items;
    state.pagination = pagination;
  },
  setLoading(state, loading) {
    state.loading = loading;
  }
};
