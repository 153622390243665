import {MutationTree} from "vuex";
import {StickyPatientState} from "./types";
import {storageKey} from "../stickyPatient";

export const mutations: MutationTree<StickyPatientState> = {
  set(state, {patientId, displayName}) {
    state.loading = true;
    if (!patientId || !displayName) {
      throw new Error("Invalid patient data");
    }
    state.loading = false;
    state.patient = {patientId, displayName};
    sessionStorage.setItem(storageKey, patientId);
  },
  clear(state) {
    state.loading = false;
    state.patient = null;
    sessionStorage.removeItem(storageKey);
  }
};
