








































import Vue from "vue";
import FileSelect from "../File/FileSelect.vue";
import {Component, Prop, Watch} from "vue-property-decorator";
import FileSize from "../File/FileSize.vue";

@Component({
  name: "ReportTemplateFileSelect",
  components: {FileSize, FileSelect}
})
export default class ReportTemplateFileSelect extends Vue {
  @Prop({type: String, default: "*/*"}) readonly accept!: string;
  @Prop({type: Boolean, default: false}) readonly disabled!: boolean;
  @Prop({type: File, default: null}) readonly selectedFile!: File;

  @Watch("selectedFile") onFileChange(file: File|null): void {
    this.file = file;
  }

  file: File|null = null;

  private addFile(selectedFiles: File[]): void {
    this.file = selectedFiles[0]
    this.$emit("input", this.file);
  }

  removeFile(): void {
    this.file = null;
    this.$emit("remove");
  }
}
