<template>
  <div>
    <media-query-collapse
      message-close="Zwiń filtry"
      message-open="Rozwiń filtry"
    >
      <template #collapseContent>
        <appointment-filters
          :start-date="parsedStartDate"
          :end-date="parsedEndDate"
          :treatment-type="treatmentType"
          :statuses="statuses"
          :patient-id="patientId"
          :worker-ids="workerIds"
          :remote="remote"
          :plan="plan"
          :print-url="printUrl"
          :export-url="exportUrl"
          :loading="loading"
          :patient-options="patientOptions"
          :multiple-presence="multiplePresence"
          class="card-box m-b-5"
          @reloadList="getAppointmentsList"
        />
      </template>
    </media-query-collapse>
    <card
      ref="content"
      :loading="loading"
      class="appointment-card"
      title=""
    >
      <template v-if="!itemsByDay.length">
        <div class="alert text-center">
          Brak umówionych wizyt w wybranym terminie
        </div>
      </template>
      <pagination-description
        :items-count="items.length"
        :page="pagination.currentPage"
        :per-page="pagination.perPage"
        :total-count="pagination.totalRows"
      />
      <appointment-list
        :items-by-day="itemsByDay"
        @refresh="getAppointmentsList"
      />
      <pagination-description
        :items-count="items.length"
        :page="pagination.currentPage"
        :per-page="pagination.perPage"
        :total-count="pagination.totalRows"
      >
        <b-pagination
          :total-rows="pagination.totalRows"
          :per-page="pagination.perPage"
          :value="pagination.currentPage"
          align="right"
          class="mb-0"
          @input="changePage"
        />
      </pagination-description>
      <appointment-box-modals
        @appointmentUpdated="getAppointmentsList"
      />
    </card>
  </div>
</template>
<script>
import DATE_FORMAT from "../../utils/date/DATE_FORMAT";
import parseDate from "../../utils/date/parseDate";
import stringifyDate from "../../utils/date/stringifyDate";
import read from "../../rest/read";
import {createUrl} from "../../utils/pageUrl/createUrl";
import Card from "../../components/Card";
import AppointmentFilters from "../../components/Appointment/AppointmentFilters.vue";
import MediaQueryCollapse from "../../components/MediaQueryCollapse";
import AppointmentBoxModals from "../../components/Appointment/AppointmentBox/AppointmentBoxModals";
import {mapMutations} from "vuex";
import PaginationDescription from "../../components/Pagination/PaginationDescription";
import {updatePageQuery} from "../../utils/pageUrl/handlePageQuery";
import AppointmentList from "@/components/Appointment/AppointmentList";

export default {
  name: "AppointmentListPage",
  components: {
    AppointmentList,
    PaginationDescription,
    MediaQueryCollapse,
    Card,
    AppointmentFilters,
    AppointmentBoxModals,
  },
  beforeRouteUpdate (to, from, next) {
    this.items = [];
    next();
  },
  beforeRouteLeave(to, from, next) {
    this.saveAppointmentListItem(null);
    next();
  },
  props: {
    startDate: {type: String, default: null},
    endDate: {type: String, default: null},
    treatmentType: {type: String, default: null},
    statuses: {type: Array, default: null},
    patientId: {type: String, default: null},
    workerIds: {type: Array, default: null},
    remote: {type: Boolean, default: null},
    plan: {type: String, default: null},
    page: {type: Number, default: null},
    patientOptions: {type: Array, default: null},
    multiplePresence: {type: Array, default: null},
  },
  data() {
    return {
      currentPage: this.page || 1,
      items: [],
      loading: false,
      pagination: {perPage: 50},
    };
  },
  computed: {
    parsedStartDate() {
      const date = this.startDate ? parseDate(this.startDate) : new Date();
      date.setHours(0,0,0,0);
      return date;
    },
    parsedEndDate() {
      if (!this.endDate) {
        return null;
      }
      const date = parseDate(this.endDate);
      date.setHours(23, 59, 59);
      return date;
    },
    parsedItems() {
      return this.items.map((item) => {
        const date = parseDate(item.date);
        const label = stringifyDate(date, DATE_FORMAT.TIME);
        return {...item, date, label};
      });
    },
    itemsByDay() {
      return this.parsedItems.reduce((days, item) => {
        const itemLabel = stringifyDate(item.date, DATE_FORMAT.DATE);
        const day = days.find(({label}) => label === itemLabel);

        if (null == day) {
          return days.concat([
            {label: itemLabel, items: [item]},
          ]);
        }

        return days.map((d) => d !== day ? d : {
          label: d.label,
          items: d.items.concat([item])
        });
      }, [])
    },
    filters() {
      return {
        startDate: this.parsedStartDate ? stringifyDate(this.parsedStartDate) : null,
        endDate: this.parsedEndDate ? stringifyDate(this.parsedEndDate) : null,
        treatmentType: this.treatmentType,
        statuses: this.statuses,
        page: this.currentPage,
        perPage: this.pagination.perPage,
        patientId: this.patientId,
        workerIds: this.workerIds,
        remote: this.remote,
        plan: this.plan,
        patientOptions: this.patientOptions,
        multiplePresence: this.multiplePresence
      };
    },
    printUrl() {
      return createUrl("/api/appointments/plan", this.filters);
    },
    exportUrl() {
      return createUrl("/api/appointments/export", this.filters);
    },
  },
  watch: {
    page(val) {
      this.currentPage = val || 1;
    },
  },
  methods: {
    ...mapMutations("appointmentListItem", ["saveAppointmentListItem",]),
    async changePage(page) {
      this.currentPage = page;
      await updatePageQuery({page: this.currentPage});
    },
    async getAppointmentsList() {
      this.loading = true;
      const response = await read("/api/appointments", this.filters);
      this.pagination = response.pagination;
      this.items = response.items;
      setTimeout(() => this.scrollToContent());
      this.loading = false;
    },
    scrollToContent() {
      if(this.$refs.content) {
        const element = this.$refs.content.$vnode.elm;
        element.scrollIntoView(true);
        document.documentElement.scrollTop -= 100;
      }
    },
  },
}

</script>

<style scoped lang="scss">
  @import "../../styles/variables";

  @include media-breakpoint-down(lg) {
    .appointment-card {
      padding: 0;
      border: none;
      background-color: transparent;
    }
  }
</style>
