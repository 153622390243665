<template>
  <div class="card-box">
    <b-form-group
      label="Nazwa"
    >
      <b-form-input
        id="name"
        v-model.trim="name"
        :state="state('name')"
      />
      <error-message
        :errors="errors"
        field="name"
      />
    </b-form-group>
    <address-form
      v-model="address"
      :errors="getSubErrors('address')"
      horizontal
    />
    <error-message :errors="errors" />
    <div class="text-right">
      <button
        class="btn btn-secondary waves-effect"
        @click="redirect"
      >
        Anuluj
      </button>
      <button
        :disabled="loading"
        class="btn btn-primary waves-effect ml-2"
        @click="createInstitution"
      >
        <i
          :class="loading ? 'fa-spin fa-spinner' : 'fa-check'"
          class="fa"
        />
        Zapisz
      </button>
    </div>
  </div>
</template>

<script>
import AddressForm from "../../components/Address/AddressForm";
import create from "../../rest/create";
import {generateUuid} from "../../utils/uuid/generateUuid";
import {errorsMixin} from "../../mixins/errorsMixin.js";
import processResponseException from "../../utils/errors/processResponseException";
import ErrorMessage from "../../components/Form/ErrorMessage";
import subErrors from "../../utils/errors/subErrors";

export default {
  components: {
    ErrorMessage,
    AddressForm,
  },
  mixins: [errorsMixin],
  data() {
    return {
      name: null,
      address: {},
      loading: false,
    };
  },
  methods: {
    async createInstitution() {
      try {
        this.loading = true;
        this.errors = [];
        const institutionId = generateUuid();

        await create("/api/institutions", {
          institutionId: institutionId,
          name: this.name,
          address: this.address,
        });
        this.redirect();
      } catch (exception) {
        this.errors = processResponseException(exception);
      }
      this.loading = false;
    },
    redirect() {
      this.$router.push({name: "institution_list"});
    },
    getSubErrors(field) {
      return subErrors(this.errors, field);
    },
  },
}
</script>
