
















import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";

@Component({
  name: "ModuleErrorMessage",
})
export default class ModuleErrorMessage extends Vue {
  @Prop({type: Array, default: null}) readonly messages!: string[] | null;

}
