




































import Vue from "vue";
import AdditionalRightsSelect from "./AdditionalRightsSelect.vue";
import {Prop, Ref} from "vue-property-decorator";
import Component from "vue-class-component";
import type {AdditionalRightsType} from "../../types/Prescription";
import {Error} from "../../rest";
import blur from "../../directives/blur";

@Component({
  name: "AdditionalRights",
  directives: {
    blur,
  },
  components: {AdditionalRightsSelect}
})
export default class AdditionalRights extends Vue {
  @Prop({type: Object, default: ()=>({})}) readonly value!: AdditionalRightsType;
  @Prop({type: Boolean, default: false}) readonly disabled!: boolean;
  @Prop({type: Array, default: ()=>[]}) readonly errors!: Array<Error>

  @Ref("multiselect") readonly multiselect!: AdditionalRightsSelect;

  private edited: boolean = false;

  private get isInvalid(): boolean {
    return !!this.errors.find((error) => error.field === "additionalRights.code");
  }

  private update(code: string|null): void {
    this.$emit("input", {...this.value, code});
  }

  private startEdit(): void {
    if (!this.disabled) {
      this.edited = true;
      this.$nextTick(() => this.multiselect.activate());
    }
  }

  private stopEdit(deactivate: boolean = true): void {
    if (deactivate) {
      this.multiselect.deactivate();
    }
    this.edited = false;
  }

  private clearValue() :void {
    this.update(null);
    this.edited = false;
  }
}
