<template>
  <object-select
    :value="selected"
    :name="name"
    :options="options"
    :placeholder="placeholder"
    :disabled="disabled"
    :loading="loading"
    :multiple="multiple"
    :clear-after-select="clearAfterSelect"
    :state="state"
    @search-change="loadOptions"
    @close="cancelLoading"
    @input="update"
    @select="select"
  />
</template>

<script>
import ObjectSelect from "./ObjectSelect";
import read from "../../../rest/read";
import {code2option} from "../../../utils/icd10Code/code2option";

export default {
  components: {ObjectSelect},
  props: {
    value: ObjectSelect.props.value, // eslint-disable-line
    name: ObjectSelect.props.name, // eslint-disable-line
    placeholder: {type: String, default: "Zacznij wpisywać kod ICD-10"},
    disabled: ObjectSelect.props.disabled, // eslint-disable-line
    state: ObjectSelect.props.state, // eslint-disable-line
    clearAfterSelect: {type: Boolean, default: false},
    showGroupsOnly: {type: Boolean, default: false},
    multiple: {type: Boolean, default: false},
  },
  data() {
    return {
      options: [],
      loading: false,
      typingTimeout: null,
    };
  },
  computed: {
    selected() {
      if (!this.value) {
        return this.value;
      } else if (Array.isArray(this.value)) {
        if ("string" === typeof this.value[0]) {
          this.loadSelectedValues();
        } else {
          return this.value;
        }
      } else if ("string" === typeof this.value) {
        this.loadSelectedValue();
      }
      return this.clearAfterSelect ? null : this.value;
    },
  },
  methods: {
    async loadOptions(phrase="") {
      clearTimeout(this.typingTimeout);
      if ("" === phrase || 2 > phrase.length) {
        return;
      }
      this.typingTimeout = setTimeout(async () => {
        this.loading = true;
        const query = {phrase};
        if(this.showGroupsOnly){
          query.showGroupsOnly = true;
        }
        const response = await read("/api/icd10code", query);
        this.options = response.items.map(code2option);
        this.loading = false;
      }, 1000);
    },
    cancelLoading() {
      this.loading = false;
      clearTimeout(this.typingTimeout);
    },
    update(option) {
      this.$emit("input", option);
    },
    select(option) {
      this.$emit("select", option);
      this.$emit("onSelectCode", option);
    },
    async loadSelectedValue() {
      await this.loadOptions(this.value);
      const value = this.options.find(option => option.value === this.value);
      this.update(value);
    },
    async loadSelectedValues() {
      const responses = await Promise.all(this.value.map(phrase => read("/api/icd10code", {phrase})));
      const values = responses
        .reduce((icd10Codes, {items}) => [...icd10Codes, ...items], [])
        .map(code2option);
      this.options = values;
      this.update(values);
    },
  },
}
</script>
