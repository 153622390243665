var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('error-message',{attrs:{"errors":_vm.errors}}),_c('b-table',{attrs:{"empty-text":"Brak skierowań do wyświetlenia","fields":_vm.fields,"items":_vm.items,"hover":"","responsive":""},scopedSlots:_vm._u([{key:"cell(extension)",fn:function(ref){
var extension = ref.item.extension;
return [_vm._v(" "+_vm._s(extension)+" ")]}},{key:"cell(createdAt)",fn:function(ref){
var createdAt = ref.item.createdAt;
return [_vm._v(" "+_vm._s(createdAt)+" ")]}},{key:"cell(creator)",fn:function(ref){
var creator = ref.item.creator;
return [_vm._v(" "+_vm._s(creator)+" ")]}},(!_vm.appointmentDocumentId)?{key:"cell(appointmentId)",fn:function(ref){
var document = ref.item.document;
return [_vm._v(" "+_vm._s(document ? document.appointmentId : null)+" ")]}}:null,(!_vm.appointmentDocumentId)?{key:"cell(doctor)",fn:function(ref){
var document = ref.item.document;
return [_vm._v(" "+_vm._s(document ? document.doctor : null)+" ")]}}:null,(!_vm.appointmentDocumentId)?{key:"cell(scheduledDate)",fn:function(ref){
var document = ref.item.document;
return [_vm._v(" "+_vm._s(document ? document.scheduledDate : null)+" ")]}}:null,{key:"cell(actions)",fn:function(ref){
var ref_item = ref.item;
var id = ref_item.id;
var status = ref_item.status;
var appointmentDocumentId = ref_item.appointmentDocumentId;
return [(_vm.selectedId === id && _vm.loading && _vm.action ==='complete')?_c('b-btn',{staticStyle:{"width":"3rem"},attrs:{"size":"sm","variant":"primary"}},[_c('i',{staticClass:"fas fa-spin fa-spinner"})]):('completed' === status)?_c('b-btn',{staticStyle:{"width":"3rem","cursor":"default"},attrs:{"title":"Skierowanie zrealizowane.","size":"sm","variant":"primary"}},[_c('i',{staticClass:"fa fa-check-square"})]):_c('b-btn',{staticStyle:{"width":"3rem"},attrs:{"title":"Zakończ realizację skierowania w systemie P1.","size":"sm","variant":"outline-primary"},on:{"click":function($event){return _vm.complete(id)}}},[_c('i',{staticClass:"fa fa-edit"})]),(id === appointmentDocumentId && _vm.loading && _vm.action ==='unassign')?_c('b-btn',{staticClass:"m-l-5",staticStyle:{"width":"3rem"},attrs:{"size":"sm","variant":"primary"}},[_c('i',{staticClass:"fas fa-spin fa-spinner"})]):(appointmentDocumentId)?_c('b-btn',{staticClass:"m-l-5",staticStyle:{"width":"3rem"},attrs:{"title":"Odepnij od dokumentu wizyty","size":"sm","variant":"outline-primary"},on:{"click":function($event){return _vm.unassign(id, appointmentDocumentId)}}},[_c('i',{staticClass:"fa fa-code-branch"})]):_vm._e()]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }