<template>
  <div>
    <card
      :loading="loading"
      title="Lekarz prowadzący"
    >
      <template #control>
        <is-granted
          :privileges="['CHANGE_PATIENT_LEADING_SPECIALIST']"
        >
          <b-btn
            v-b-modal.leadingSpecialistModal
            :disabled="loading"
            variant="primary"
            class="btn-sm"
          >
            <i class="fa fa-pencil-alt" />
          </b-btn>
        </is-granted>
      </template>
      <p v-if="leadingSpecialist">
        {{ leadingSpecialist.name }} {{ leadingSpecialist.surname }} <br>
        {{ leadingSpecialist.email }} / {{ leadingSpecialist.phone }}
      </p>
      <p v-else>
        Brak
      </p>
    </card>

    <b-modal
      id="leadingSpecialistModal"
      no-close-on-backdrop
      title="Edycja specjalisty prowadzącego"
      title-tag="h3"
    >
      <template #default>
        <worker-select
          v-model="newLeadingSpecialist"
          :state="state('leadingSpecialist')"
          clearable
        />
        <error-message
          :errors="errors"
          field="leadingSpecialist"
        />
        <div
          v-show="leadingSpecialistHistory.length"
          v-b-toggle="'leadingSpecialistHistory'"
          class="text-center m-2 cursor-pointer"
        >
          ---
          <span class="when-closed">Pokaż historię</span>
          <span class="when-opened">Ukryj historię</span>
          ---
        </div>
        <b-collapse id="leadingSpecialistHistory">
          <table class="table table-sm">
            <thead>
              <tr>
                <th>Data zmiany</th>
                <th>Osoba zmieniająca</th>
                <th>Nowy specjalista prowadzący</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(entry, index) in leadingSpecialistHistory"
                :key="index"
                class="p-3"
              >
                <td>{{ entry.dateOfChange }}</td>
                <td>{{ entry.changerFullName }}</td>
                <td>{{ entry.leadingSpecialistFullName ? entry.leadingSpecialistFullName : 'brak' }}</td>
              </tr>
            </tbody>
          </table>
        </b-collapse>
      </template>
      <template #modal-footer>
        <button
          type="button"
          class="btn btn-secondary"
          @click="cancel()"
        >
          Anuluj
        </button>
        <button
          :disabled="loading"
          class="btn btn-primary"
          @click="submit"
        >
          <i
            :class="loading ? 'fa-spin fa-spinner' : 'fa-check'"
            class="fa"
          />
          Zapisz
        </button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import update from "../../../rest/update";
import read from "../../../rest/read";
import processResponseException from "../../../utils/errors/processResponseException";
import WorkerSelect from "../../Worker/WorkerSelect";
import Card from "../../Card";
import ErrorMessage from "../../Form/ErrorMessage";
import {errorsMixin} from "../../../mixins/errorsMixin.js";
import IsGranted from "../../IsGranted";

export default {
  name: "PatientCardLeadingSpecialist",
  components: {
    IsGranted,
    Card,
    WorkerSelect,
    ErrorMessage,
  },
  mixins: [errorsMixin],
  props: {
    patientId: {type: String, required: true}
  },
  data() {
    return {
      leadingSpecialist: null,
      newLeadingSpecialist: null,
      leadingSpecialistHistory: [],
      loading: false,
    };
  },
  watch: {
    patientId() {
      this.loadData();
    },
  },
  async mounted() {
    await this.loadData();
  },
  methods: {
    async submit() {
      this.loading = true;
      try {
        const resource = `/api/patients/${this.patientId}/leading-specialist`;
        await update(resource, {workerId: this.newLeadingSpecialist ? this.newLeadingSpecialist.workerId : null});
        this.loadData();
        await this.cancel();
      } catch (exception) {
        this.errors = processResponseException(exception);
      }
      this.loading = false;
    },
    cancel() {
      this.$bvModal.hide("leadingSpecialistModal");
      this.resetData();
    },
    resetData() {
      this.newLeadingSpecialist = this.leadingSpecialist;
    },
    async loadData() {
      this.loading = true;
      const response = await read(`/api/patients/${this.patientId}/leading-specialist`);
      this.leadingSpecialistHistory = response.leadingSpecialistHistory.reverse();
      this.leadingSpecialist = response.workerId ? response : null;
      this.resetData();
      this.loading = false;
    },
  },
}
</script>

<style scoped>
  .card-box {
    overflow: hidden;
  }
  .collapsed > .when-opened,
  :not(.collapsed) > .when-closed {
    display: none;
  }
</style>
