<template>
  <div class="row m-b-30">
    <div class="col-2 text-right">
      <span>{{ typeTrans }}</span>
    </div>
    <div class="col-10">
      <div
        v-if="!!entries.length"
        class="card m-b-10"
      >
        <div class="card-body">
          <div
            v-for="entry in entries"
            :key="entry.entryId"
            class="card-text m-b-15"
          >
            <entry-edit
              :entry="entry"
              :read-only="readOnly"
              @entry-edited="entryEdited"
            />
          </div>
        </div>
      </div>
      <diagnosis-entry-add
        v-if="!readOnly"
        :create-entry-url="createEntryUrl"
        :type="type"
        :type-trans="typeTrans"
        @new-diagnosis-entry-added="entryAdded"
      />
    </div>
  </div>
</template>

<script>
import DiagnosisEntryAdd from "../DiagnosisField/DiagnosisEntryAdd";
import EntryEdit from "../DiagnosisField/EntryEdit";

export default {
  name: "EditableDiagnosisField",
  components: {DiagnosisEntryAdd, EntryEdit},
  props: {
    createEntryUrl: {type: String, required: true},
    injectedEntries: {type: Array, required: true},
    type: {type: String, required: true},
    typeTrans: {type: String, required: true},
    readOnly: {type: Boolean, required: true},
  },
  data() {
    return {
      entries: this.injectedEntries || [],
    };
  },
  methods: {
    entryAdded(entry) {
      this.entries = this.entries.concat([entry]);
      this.$emit("new-entry-added");
    },
    entryEdited(entry) {
      const index = this.entries.findIndex(
        (oldEntry) => oldEntry.entryId === entry.entryId
      );
      if (index > -1) {
        this.entries = [
          ...this.entries.slice(0, index),
          entry,
          ...this.entries.slice(index + 1)
        ];
        this.$emit("entry-edited");
      }
    },
  },
}
</script>
