import {MutationTree} from "vuex";
import {ClinicParametersState} from "./types";
import {CLINIC_PARAMETERS, storage} from "../clinicParameters";

export const mutations: MutationTree<ClinicParametersState> = {
  setParameters(state, parameters) {
    state.parameters = parameters;
  },
  clear(state) {
    storage.removeItem(CLINIC_PARAMETERS);
    state.parameters = null;
  }
};
