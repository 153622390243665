import {CurrentUserState} from "./types";

export const initialState: CurrentUserState = {
  loading: false,
  user: {
    userId: null,
    name: null,
    surname: null,
    email: null,
    workerId: null,
    settings: {
      multiFactorAuth: false,
      updateNotify: false,
      surveyBuilderUrl: null,
      partnerId: null
    },
  }
};
