<template>
  <!--eslint-disable-next-line-->
  <component :is="component">{{ text }}</component>
</template>

<script>
import read from "../../rest/read";

const data = {
  treatmentsList: [],
  loaded: false,
};

export default {
  name: "TreatmentTypeName",
  props: {
    value: {type: String, required: true},
    component: {type: String, default: "span"},
  },
  data() {
    return data;
  },
  computed: {
    text(){
      const treatmentType = this.treatmentsList.find(option => option.value === this.value);
      if (null == treatmentType) {
        return this.value;
      }
      return treatmentType.name;
    }
  },
  async mounted() {
    if (!this.loaded) {
      this.loaded = true;
      const {items} = await read("/api/treatment-types", {wantTreeStructure: 0});
      this.treatmentsList = items;
    }
  },
}
</script>
