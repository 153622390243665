



























































import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";
import type {InsuredPersonFormData} from "../../types/Zla";
import InsurerTypeSelect from "./InsurerTypeSelect.vue";
import {Error} from "../../rest";
import ErrorMessage from "../Form/ErrorMessage.vue";

@Component({
  name: "InsuredPersonForm",
  components: {ErrorMessage, InsurerTypeSelect}
})
export default class InsuredPersonForm extends Vue {
  @Prop({type: Object, required: true}) readonly value!: InsuredPersonFormData;
  @Prop({type: Array, default: ()=>[]}) readonly errorsProp!: Error[];

  private update(
    $event: InsuredPersonFormData[keyof InsuredPersonFormData],
    field: keyof InsuredPersonFormData
  ): void {
    this.$emit("input", {
      ...this.value,
      [field]: $event,
    })
  }

  private state(field: string): boolean|null {
    return this.errorsProp.find((error) => error.field === field) ? false : null;
  }
}
