<template>
  <div class="row">
    <div class="col-12">
      <div class="card-box">
        <h4>Dane osobowe pacjenta</h4>
        <hr>
        <div class="row">
          <div class="col-md-6 col-lg-4">
            <b-form-group
              id="name"
              label="Imię"
              label-for="nameInput"
            >
              <b-form-input
                id="nameInput"
                v-model.trim="personalDataForm.name"
                :state="state('name')"
                placeholder="Wprowadź imię"
                @input="updateModel"
              />
              <error-message
                :errors="errors"
                field="name"
              />
            </b-form-group>
          </div>
          <div class="col-md-6 col-lg-4">
            <b-form-group
              id="surname"
              label="Nazwisko"
              label-for="surnameInput"
            >
              <b-form-input
                id="surnameInput"
                v-model.trim="personalDataForm.surname"
                :state="state('surname')"
                placeholder="Wprowadź nazwisko"
                @input="updateModel"
              />
              <error-message
                :errors="errors"
                field="surname"
              />
            </b-form-group>
          </div>
          <div class="col-md-6 col-lg-4">
            <b-form-group
              id="birthdate"
              label="Data urodzenia"
              label-for="birthdateInput"
            >
              <birthdate-input
                v-model="personalDataForm.birthdate"
                :errors="errors"
                @input="updateModel"
              />
              <error-message
                :errors="errors"
                field="birthdate"
              />
            </b-form-group>
          </div>
          <div class="col-md-6 col-lg-4">
            <b-form-group
              id="pesel"
              label="Numer PESEL"
              label-for="peselInput"
            >
              <verify-pesel
                id="peselInput"
                v-model.trim="personalDataForm.pesel"
                :state="state('pesel')"
                @input="updateModel"
              />
              <error-message
                :errors="errors"
                field="pesel"
              />
            </b-form-group>
          </div>
          <div class="col-md-6 col-lg-4">
            <b-form-group
              id="phone"
              label="Telefon kontaktowy"
              label-for="phoneInput"
            >
              <b-form-input
                id="phoneInput"
                v-model.trim="personalDataForm.phoneNumber"
                :state="state('phoneNumber')"
                placeholder="Wprowadź numer telefonu (opcjonalnie)"
                @input="updateModel"
              />
              <error-message
                :errors="errors"
                field="phoneNumber"
              />
            </b-form-group>
          </div>
          <div class="col-md-6 col-lg-4">
            <b-form-group
              id="registrationDate"
              label="Data rejestracji"
              label-for="registrationDateInput"
            >
              <date-picker
                id="registrationDateInput"
                v-model="personalDataForm.registeredDate"
                :format="'YYYY-MM-DD'"
                :state="state('registeredDate')"
                @input="updateModel"
              />
              <error-message
                :errors="errors"
                field="registeredDate"
              />
            </b-form-group>
          </div>
          <div class="col-md-6 col-lg-4">
            <b-form-group
              id="fileNumber"
              label="Numer teczki"
              label-for="fileNumberInput"
            >
              <b-form-input
                id="fileNumberInput"
                v-model.trim="personalDataForm.fileNumber"
                :state="state('fileNumber')"
                placeholder="Wprowadź numer teczki"
                @input="updateModel"
              />
              <error-message
                :errors="errors"
                field="fileNumber"
              />
            </b-form-group>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BirthdateInput from "../Form/Input/BirthdateInput.vue"
import DatePicker from "../Form/DatePicker/DatePicker.vue"
import ErrorMessage from "../Form/ErrorMessage";
import VerifyPesel from "./VerifyPesel";
import decodeBirthday from "../../utils/peselBirthDay";

export default {
  components: {
    BirthdateInput,
    DatePicker,
    ErrorMessage,
    VerifyPesel,
  },
  props: {
    errors: {type: Array, default: () => []},
    value: {type: Object, required: true},
  },
  data() {
    const registeredDate = new Date();
    const personalDataForm = {...this.value, registeredDate};
    return {personalDataForm};
  },
  watch:{
    "personalDataForm.pesel": function (pesel){
      const birthDate = decodeBirthday(pesel);
      if (birthDate.day && birthDate.month && birthDate.year) {
        this.personalDataForm.birthdate = new Date(birthDate.year, birthDate.month - 1, birthDate.day);
      }
    },
  },
  mounted() {
    this.updateModel();
  },
  methods: {
    state(field) {
      return this.errors.find((error) => error.field === field) ? false : null;
    },
    updateModel() {
      this.$emit("input", this.personalDataForm);
    },
  },
}
</script>

<style scoped>

</style>
