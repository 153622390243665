import {MutationTree} from "vuex";
import {SplashScreenState} from "./types";

export const mutations: MutationTree<SplashScreenState> = {
  showNetworkFailure(state) {
    state.networkFailure = true;
  },
  setOffline(state, offlineStatus) {
    state.visible = offlineStatus;
  },
  setAnimate(state, animateStatus) {
    state.animate = animateStatus;
  },
  setSplashScreenTimeout(state, timeout) {
    state.timeout = timeout;
  }
};
