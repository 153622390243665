
















































































































import ErrorsMixin from "../../mixins/ErrorsMixin.ts";
import {Component, Mixins, Prop} from "vue-property-decorator";
import type {Payer} from "../../types/Zla";
import {PayerIdStringType} from "../../types/Zla";
import PayerBox from "./PayerBox.vue";
import isEqual from "lodash/isEqual";
import ErrorMessage from "../Form/ErrorMessage.vue";
import PayerIdTypeSelect from "./PayerIdTypeSelect.vue";
import processResponseException from "../../utils/errors/processResponseException";
import read from "../../rest/read";

const payerInitialState = {
  nip: "",
  companyName: "",
  pesel: "",
  name: "",
  surname: "",
}

@Component({
  name: "PayerForm",
  components: {PayerIdTypeSelect, ErrorMessage, PayerBox}
})
export default class PayerForm extends Mixins(ErrorsMixin) {
  @Prop({type: String, required: true}) readonly sessionId!: string;
  @Prop({type: Object, default: null}) readonly value!: Payer;
  @Prop({type: Array, default: () => []}) readonly payers!: Payer[];

  isEqual = isEqual;
  private payer: Payer = {...payerInitialState};
  private loading: boolean = false;
  private idType: PayerIdStringType = PayerIdStringType.NIP;
  private payerId: string = "";
  private customPayer: boolean = false;

  private update(payer: Payer | null): void {
    this.$emit("input", payer);
  }

  private select(payer: Payer): void {
    this.customPayer = false;
    this.update(payer);
  }

  private checkCustomPayer($event: boolean, payer: Payer): void {
    this.update($event ? payer : null);
  }

  private async getPayerData(): Promise<void> {
    this.errors = [];
    this.loading = true;
    if (this.idType === PayerIdStringType.NIP) {
      this.payer.nip = this.payerId;
    }
    if (this.idType === PayerIdStringType.PESEL) {
      this.payer.pesel = this.payerId;
    }
    const data = {
      sessionId: this.sessionId,
      numberType: this.idType,
      number: this.payerId,
    };

    try {
      const payer = await read<Payer>("/api/zla/get-payer", data);
      this.payer = {
        ...this.payer,
        ...payer,
      };
      this.update(this.payer);
    } catch (e) {
      this.errors = processResponseException(e);
    }
    this.loading = false;
  }

  private clearCustomPayerData() {
    this.payer = {...payerInitialState};
    this.update(null);
  }
}
