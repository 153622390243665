<template>
  <span>{{ sourceOfInformationType }}</span>
</template>

<script>
import t from "../../i18n";

export default {

  props: {
    sourceOfInformation: {type: String, required: true},
  },

  computed: {
    sourceOfInformationType() {
      return null === this.sourceOfInformation ? null : t(`@source-of-information.${this.sourceOfInformation}`);
    }
  },
}
</script>
