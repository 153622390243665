







import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";
import type {Payer} from "../../types/Zla";

@Component({
  name: "PayerBox"
})
export default class PayerBox extends Vue {
  @Prop({type: Object, required: true}) readonly payer!: Payer;
}
