import read from "../../../rest/read";
import {ActionTree} from "vuex";
import {InterventionNotificationsState} from "./types";
import {RootState} from "../../types";

export const actions : ActionTree<InterventionNotificationsState, RootState>= {
  async getItems({commit}, filters = {}) {
    commit("setLoading", true);
    const {items, pagination} = await read("/api/intervention-notifications", filters);
    commit("setData", {items, pagination});
    commit("setLoading", false);
  },
};
