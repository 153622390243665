

































import {Component, Mixins, Prop} from "vue-property-decorator";
import ErrorsMixin from "../../mixins/ErrorsMixin";
import read from "../../rest/read";
import LoadingMask from "../Loading/LoadingMask.vue";
import ErrorMessage from "../Form/ErrorMessage.vue";
import parseDate from "../../utils/date/parseDate";
import DATE_FORMAT from "../../utils/date/DATE_FORMAT";
import DateTime from "../DateTime.vue";
import processResponseException from "../../utils/errors/processResponseException";
import {NoteItem} from "../../types/Notes";

@Component({
  name: "NoteModal",
  components: {DateTime, ErrorMessage, LoadingMask}
})
export default class NoteModal extends Mixins(ErrorsMixin) {
  @Prop({type: String, required: true}) readonly noteId!: string;

  DATE_FORMAT = DATE_FORMAT;
  private loading: boolean = false;
  private note: NoteItem|null = null;

  private get modalId(): string {
    return `user-note-${this.noteId}`;
  }

  private dateValue(date: string): Date|null {
    return parseDate(date, DATE_FORMAT.DEFAULT);
  }

  private async fetchData(): Promise<void> {
    this.loading = true;
    this.errors = [];
    try {
      this.note = await read<NoteItem>(`/api/notes/${this.noteId}`);
    } catch (e) {
      this.errors = processResponseException(e);
    }
    this.loading = false;
  }

  private hideModal() {
    this.$bvModal.hide(this.modalId);
  }
}
