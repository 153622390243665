<template>
  <is-granted
    :privileges="['SHOW_DIRECTOR_DASHBOARD']"
    component="fragment"
  >
    <counter-widget
      :filters="filters"
      :title="title"
      :icon="icon"
      :fetch-value="fetchValue"
      :tooltip-text="tooltipText"
      resource="/api/director-dashboard/occupancy"
    />
  </is-granted>
</template>

<script>
import CounterWidget from "../CounterWidget";
import read from "../../rest/read";
import IsGranted from "../IsGranted";

export default {
  name: "OccupancyByDay",
  components: {
    IsGranted,
    CounterWidget,
  },
  props: {
    type: {type: String, default: "day"},
    title: {type: String, required: true},
    icon: {type: String, required: true},
    workerId: {type: String, default: null},
    tooltipText: {type: String, default: null}
  },
  data() {
    return {
      loading: false
    };
  },
  computed: {
    filters() {
      return {
        workerId: this.workerId,
        type: this.type,
      };
    },
  },
  methods: {
    async fetchValue() {
      if(this.workerId === null) return 0;
      const url = `/api/director-dashboard/occupancy/${ this.filters.workerId}/${this.filters.type}`;
      const reader = await read(url);
      return Math.round(reader.count * 100) / 100;
    }
  }
}
</script>
