<template>
  <div>
    <div class="row m-0">
      <div class="col-md-1 border-right p-3">
        {{ categoryId }}
      </div>
      <div class="col-md-2 border-right p-3">
        {{ categoryTitle }}
      </div>
      <div class="col-md-2 border-right p-3">
        <template v-if="sourcesOfInformation.length === 0">
          -Brak źródeł informacji-
        </template>
        <template v-else>
          <i18n
            v-for="(source, sourceIndex) in sourcesOfInformation"
            :key="sourceIndex"
            :msgid="source"
          />
        </template>
      </div>
      <div class="col-md-7">
        <evaluation-result-qualifier
          v-for="(qualifier, qualifierIndex) in qualifiers"
          :key="qualifierIndex"
          :qualifiers-answers="qualifiersAnswers[qualifier.type]"
          :class="{'border-bottom': qualifierIndex !== (qualifiers.length - 1)}"
          v-bind="qualifier"
          :symbol="symbol"
        />
      </div>
    </div>
    <div
      v-if="!description"
      class="row m-0 border-top"
    >
      <div class="col-md-1 border-right p-3">
        Opis problemu:
      </div>
      <div class="col-md-11 p-3">
        {{ description }}
      </div>
    </div>
  </div>
</template>

<script>
import EvaluationResultQualifier from "./EvaluationResultQualifier";
import I18n from "../../i18n";

export default {
  name: "EvaluationResultAnswer",
  components: {
    I18n,
    EvaluationResultQualifier,
  },
  props: {
    categoryId: {type: String, required: true},
    qualifiersAnswers: {type: Object, required: true},
    sourcesOfInformation: {type: Array, required: true},
    description: {type: String, default: null},
    categoryTitle: {type: String, required: true},
    qualifiers: {type: Array, required: true},
    symbol: {type: String, required: true},
  }
}
</script>

<style scoped>

</style>
