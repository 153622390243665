<template>
  <div>
    <button
      class="btn btn-sm btn-link"
      :disabled="show"
      @click.prevent.stop="onClick"
    >
      <i
        :class="visible ? 'fa-caret-up' : 'fa-caret-down'"
        class="fa"
      />
      {{ buttonText }}
    </button>
    <b-collapse
      id="collapse-plan"
      v-model="visible"
    >
      <slot />
    </b-collapse>
  </div>
</template>

<script>
export default {
  name: "CollapseWrapper",
  props: {
    show: {type: Boolean, default: false},
  },
  data() {
    return {
      visible: this.show,
    };
  },
  computed: {
    buttonText() {
      return this.visible ? "Zwiń" : "Uzupełnij"
    },
  },
  watch: {
    show: {
      immediate: true,
      handler(val) {
        if (val) {
          this.visible = val;
        }
      },
    },
  },
  methods: {
    onClick() {
      this.visible = !this.visible;
    }
  }
}
</script>
