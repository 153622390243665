


































import AppointmentButton from "../Appointment/AppointmentButton.vue";
import DateTime from "../DateTime.vue";
import ScheduleBreakButton from "./ScheduleBreakButton.vue";
import type {Branch, WeeklyScheduleItem, WorkingHours} from "../../types/WeeklySchedule";
import {Component, Prop} from "vue-property-decorator";
import Vue from "vue";

@Component({
  name: "WeeklyScheduleBranch",
  components: {ScheduleBreakButton, DateTime, AppointmentButton},
})

export default class WeeklyScheduleBranch extends Vue {
  @Prop({type: Object, default: null}) readonly branch!: Branch|null;
  @Prop({type: Array, default: ()=>[]}) readonly items!: Array<WeeklyScheduleItem>;
  @Prop({type: Array, default: ()=>[]}) readonly canceledItems!: Array<WeeklyScheduleItem>;
  @Prop({type: Object, required: true}) readonly workingHours!: WorkingHours;
  @Prop({type: Boolean, default: false}) readonly isToday!: boolean;

  get itemsWithCanceledAppointments(): Array<WeeklyScheduleItem> {
    return [...this.items, ...this.canceledItems]
      .sort((itemA, itemB) => itemA.start.getTime() < itemB.start.getTime() ? -1 : 1);
  }
}
