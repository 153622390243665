<template>
  <b-modal
    :id="id"
    title="Edytuj załącznik"
    hide-footer
    no-close-on-backdrop
    title-tag="h3"
    @hide="resetModalData"
  >
    <error-message :errors="errors" />
    <form @submit.prevent="submit">
      <form-row
        :errors="errors"
        label="Nazwa"
        field="name"
      >
        <b-input
          v-model="name"
          :disabled="loading"
          :state="state('name')"
        />
      </form-row>
      <form-row
        :errors="errors"
        label="Kategoria"
        field="category"
      >
        <attachment-category-select
          v-model="category"
          :clearable="false"
          :disabled="loading"
          :state="state('category')"
        />
      </form-row>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          @click="closeModal"
        >
          Zamknij
        </button>
        <button
          :disabled="loading"
          type="submit"
          class="btn btn-primary"
        >
          <i
            :class="loading ? 'fa-spin fa-spinner' : 'fa-check'"
            class="fas"
          />
          Zapisz
        </button>
      </div>
    </form>
  </b-modal>
</template>

<script>
import ErrorMessage from "../../../Form/ErrorMessage";
import FormRow from "../../../Form/FormRow";
import {errorsMixin} from "../../../../mixins/errorsMixin.js";
import processResponseException from "../../../../utils/errors/processResponseException";
import update from "../../../../rest/update";
import AttachmentCategorySelect from "./Category/AttachmentCategorySelect";

export default {
  name: "EditAttachmentModal",
  components: {AttachmentCategorySelect, FormRow, ErrorMessage},
  mixins: [errorsMixin],
  props: {
    id: {type: String, required: true},
    attachment: {type: Object, default: null},
  },
  data() {
    return {
      name: this.attachment?.name || "",
      category: this.attachment?.category || null,
      loading: false
    }
  },
  watch: {
    attachment() {
      this.name = this.attachment?.name || "";
      this.category = this.attachment?.category || null;
    },
  },
  methods: {
    async submit() {
      this.loading = true;
      try {
        await update(`/api/attachments/${this.attachment.attachmentId}`, {
          name: this.name,
          category: this.category
        });
        this.$emit("updated", {
          ...this.attachment,
          name: this.name,
          category: this.category,
        });
        this.closeModal();
      } catch (exception) {
        this.errors = processResponseException(exception);
      }
      this.loading = false;
    },
    closeModal() {
      this.$bvModal.hide(this.id);
    },
    resetModalData() {
      this.errors = [];
      this.name = "";
      this.category = null;
    }
  },
}
</script>
