<template>
  <form
    class="card-box"
    @submit.prevent="submit"
  >
    <div class="input-group">
      <worker-select
        v-model="worker"
        class="worker-select"
        :clearable="false"
      />
      <div class="input-group-append">
        <button
          class="btn btn-primary"
          type="submit"
        >
          Szukaj
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import WorkerSelect from "../Worker/WorkerSelect";
import {changePageQuery} from "@/utils/pageUrl/handlePageQuery";
import isEqual from "lodash/isEqual";

export default {
  name: "DirectorReportsFilter",
  components: {
    WorkerSelect,
  },
  props: {
    workerId: {type: String, default: null},
  },
  data() {
    return {
      worker: this.workerId || null
    }
  },
  computed: {
    filters() {
      return {
        workerId: this.worker ? this.worker.workerId : undefined,
      };
    },
  },
  watch: {
    workerId(val) {
      this.worker = val
    },
  },
  methods: {
    submit() {
      if (isEqual(this.$route.query, this.filters)) {
        return;
      }
      changePageQuery(this.filters);
    },
  },
}
</script>

<style scoped lang="scss">
.worker-select {
  max-width: 320px;
}
</style>
