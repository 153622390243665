<template>
  <b-modal
    id="reopen-canceled-appointment-modal"
    no-close-on-backdrop
    title="Otwórz ponownie wizytę"
    title-tag="h3"
    @hide="clearErrors"
  >
    <template #default>
      <p>
        Czy chcesz ponownie otworzyć wizytę ?
      </p>
    </template>
    <template #modal-footer>
      <button
        type="button"
        class="btn btn-secondary"
        @click="closeModal"
      >
        Zamknij
      </button>
      <button
        :disabled="loading"
        type="submit"
        class="btn btn-primary"
        @click="reopen"
      >
        <i
          v-show="loading"
          class="fas fa-spin fa-spinner"
        />
        Otwórz ponownie wizytę
      </button>
    </template>
  </b-modal>
</template>

<script>
import update from "../../../rest/update";
import processResponseException from "../../../utils/errors/processResponseException";


export default {
  props: {
    id: {type: String, default: null},
    appointmentId: {type: String, required: true}
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async reopen() {
      this.clearErrors();
      try {
        this.loading = true;
        await update(`/api/appointment/${this.appointmentId}/reopen-canceled`);
        this.closeModal();
        this.$emit("reopenAppointment");
      } catch (e) {
        this.errors = processResponseException(e);
      }
      this.loading = false;
    },
    closeModal() {
      this.$bvModal.hide("reopen-canceled-appointment-modal");
    },
    clearErrors() {
      this.errors = [];
    },
  },
}
</script>
