<template>
  <div>
    <media-query-collapse
      message-close="Zwiń filtry"
      message-open="Rozwiń filtry"
    >
      <template #collapseContent>
        <ipz-filters
          :selected-status="selectedStatus"
          :selected-patient="selectedPatient"
          :selected-workers="selectedWorkers"
          :selected-mobile-team-members="selectedMobileTeamMembers"
          :export-url="exportUrl"
          class="card-box m-b-5"
          @reload="getIpzList"
          @submit="submit"
        />
      </template>
    </media-query-collapse>
    <loading-mask
      :loading="loading"
      class="card-box p-b-10 m-t-10"
    >
      <error-message :errors="errors" />
      <div class="table-responsive">
        <table class="table fit-dropdown">
          <thead>
            <tr>
              <th
                class="order-btn"
                scope="col"
                @click="changeOrder('displayName')"
              >
                Pacjent
                <i
                  :class="orderedBy('displayName')"
                  class="fas icon"
                />
              </th>
              <th
                class="order-btn"
                scope="col"
                @click="changeOrder('caseManager')"
              >
                Case Manager
                <i
                  :class="orderedBy('caseManager')"
                  class="fas icon"
                />
              </th>
              <th
                v-if="showMobileTeamColumn"
                scope="col"
              >
                Zespół Mobilny
              </th>
              <th
                class="order-btn"
                scope="col"
                @click="changeOrder('status')"
              >
                Status
                <i
                  :class="orderedBy('status')"
                  class="fas icon"
                />
              </th>
              <th
                class="order-btn"
                scope="col"
                @click="changeOrder('createdAt')"
              >
                Data otwarcia
                <i
                  :class="orderedBy('createdAt')"
                  class="fas icon"
                />
              </th>
              <th scope="col" />
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="ipz in items"
              :key="ipz.ipzId"
            >
              <td>{{ ipz.displayName }}</td>
              <td>{{ ipz.caseManagerNameAndSurname }}</td>
              <td v-if="showMobileTeamColumn">
                <p
                  v-for="mobileTeamMember in ipz.mobileTeam"
                  :key="mobileTeamMember.workerId"
                >
                  {{ mobileTeamMember.displayName }}
                </p>
              </td>
              <td>
                <b-badge
                  class="status-badge"
                  :variant="getBadgeVariant(ipz.status)"
                >
                  <i18n
                    component="fragment"
                    :msgid="convStatus(ipz.status)"
                  />
                </b-badge>
                <div v-if="!!ipz.reason">
                  ({{ ipz.reason }})
                </div>
              </td>
              <td>{{ ipz.createdAt }}</td>
              <td class="text-right">
                <b-dropdown
                  size="sm"
                  style="min-width: 6rem"
                  text="Opcje"
                  variant="outline-primary"
                >
                  <is-granted
                    :privileges="['IPZ_DETAILS']"
                    component="fragment"
                  >
                    <b-dropdown-item :to="{name: 'ipz_details', params: {ipzId: ipz.ipzId}}">
                      Szczegóły
                    </b-dropdown-item>
                  </is-granted>
                  <is-granted
                    :privileges="['PATIENT_CARD']"
                    component="fragment"
                  >
                    <b-dropdown-item :to="{name: 'patientCard', params: {patientId: ipz.patientId}}">
                      Karta pacjenta
                    </b-dropdown-item>
                  </is-granted>
                  <is-granted
                    v-if="ipz.status === 'opened'"
                    :privileges="['GET_IPZ_APPOINTMENT_REQUIREMENTS_BY_MONTH']"
                    component="fragment"
                  >
                    <b-dropdown-item
                      :to="{ name: 'ipz_appointment_requirements_monthly',
                             params: {ipzId: ipz.ipzId}, query: {year, month}}"
                    >
                      Umawianie wizyt IPZ
                    </b-dropdown-item>
                  </is-granted>
                  <is-granted
                    v-if="ipz.status === 'opened'"
                    :privileges="['IPZ_EDIT_START_DATE']"
                    component="fragment"
                  >
                    <b-dropdown-item @click="openEditIpzStartDateModal(ipz)">
                      Edycja daty otwarcia IPZ
                    </b-dropdown-item>
                  </is-granted>
                  <is-granted
                    v-if="ipz.status === 'canceled'"
                    :privileges="['REOPEN_CANCELED_IPZ']"
                    component="fragment"
                  >
                    <b-dropdown-item @click="reopenCanceledIpz(ipz)">
                      Otwórz ponownie IPZ
                    </b-dropdown-item>
                  </is-granted>
                </b-dropdown>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <pagination-description
        :items-count="items.length"
        :page="pagination.currentPage"
        :per-page="pagination.perPage"
        :total-count="pagination.totalRows"
      >
        <b-pagination
          :total-rows="pagination.totalRows"
          :per-page="pagination.perPage"
          :value="pagination.currentPage"
          align="right"
          class="mb-0"
          @input="changePage"
        />
      </pagination-description>
    </loading-mask>
    <ipz-edit-modal
      :ipz="selectedIpz"
      @startDateUpdated="getIpzList"
      @close="deselectIpz"
    />
  </div>
</template>

<script>
import IsGranted from "../../components/IsGranted";
import stringifyDate from "../../utils/date/stringifyDate";
import I18n from "../../components/i18n";
import IpzFilters from "../../components/Ipz/IpzList/IpzFilters.vue";
import MediaQueryCollapse from "../../components/MediaQueryCollapse";
import read from "../../rest/read";
import IpzEditModal from "../../components/Ipz/IpzList/IpzEditModal";
import LoadingMask from "../../components/Loading/LoadingMask";
import PaginationDescription from "../../components/Pagination/PaginationDescription";
import {updatePageQuery} from "@/utils/pageUrl/handlePageQuery";
import isEqual from "lodash/isEqual";
import {createUrl} from "@/utils/pageUrl/createUrl";
import {mapState} from "vuex";
import update from "../../rest/update";
import processResponseException from "../../utils/errors/processResponseException";
import ErrorMessage from "../../components/Form/ErrorMessage";

export default {
  components: {
    PaginationDescription,
    LoadingMask,
    IpzEditModal,
    I18n,
    IsGranted,
    IpzFilters,
    MediaQueryCollapse,
    ErrorMessage
  },
  props: {
    page: {type: Number, default: null},
    status: {type: String, default: null},
    patientId: {type: String, default: null},
    workerIds: {type: Array, default: () => []},
    mobileTeamMembersIds: {type: Array, default: () => []},
    sort: {type: String, default: null}
  },
  data() {
    return {
      currentPage: this.page || 1,
      selectedStatus: this.status,
      selectedPatient: this.patient,
      selectedWorkers: this.workerIds,
      selectedMobileTeamMembers: this.mobileTeamMembersIds,
      selectedIpz: null,
      pagination: {},
      loading: false,
      items: [],
      order: this.sortToObject(),
      errors: [],
    }
  },
  computed: {
    orderString() {
      return this.order.column?`${this.order.column}:${this.order.direction}` : undefined;
    },
    year() {
      return stringifyDate(new Date(), "YYYY");
    },
    month() {
      return stringifyDate(new Date(), "M");
    },
    query() {
      return {
        page: this.currentPage,
        status: this.selectedStatus || undefined,
        patientId: this.selectedPatient || undefined,
        workerIds: this.stringWorkers.join(",") || undefined,
        mobileTeamMembersIds: this.stringMobileTeamMembers.join(",") || undefined,
        sort: this.orderString,
      };
    },
    filters() {
      return {
        status: this.selectedStatus,
        page: this.currentPage,
        perPage: this.pagination.perPage,
        patientId: this.selectedPatient,
        workerIds: this.stringWorkers,
        mobileTeamMembersIds: this.stringMobileTeamMembers,
        sort: this.orderString
      };
    },
    stringWorkers() {
      if ("object" === typeof this.selectedWorkers[0]) {
        return this.selectedWorkers.map(option => option.value);
      }
      return this.selectedWorkers;
    },
    stringMobileTeamMembers() {
      if ("object" === typeof this.selectedMobileTeamMembers[0]) {
        return this.selectedMobileTeamMembers.map(option => option.value);
      }
      return this.selectedMobileTeamMembers;
    },
    exportUrl() {
      return createUrl("/api/ipz/export", this.filters);
    },
    ...mapState({
      clinicParameters: state => state.clinicParameters.parameters,
    }),
    showMobileTeamColumn() {
      return this.clinicParameters && this.clinicParameters.ageGroupTarget !== "children";
    }
  },
  watch: {
    page(val) {
      this.currentPage = val;
    },
    status(val) {
      this.selectedStatus = val;
    },
    patientId(val) {
      this.selectedPatient = val;
    },
    workerIds(val) {
      this.selectedWorkers = val;
    },
    mobileTeamMembersIds(val) {
      this.selectedMobileTeamMembers = val;
    },
    async "$route"() {
      await this.getIpzList();
    },
  },
  async mounted() {
    await this.getIpzList();
  },
  methods: {
    convStatus(status) {
      return `@ipz-status.${status}`;
    },
    color(value) {
      return value >= 50 ? "color: green" : "color: red";
    },
    changePage(page) {
      this.currentPage = page;
      this.updatePageUrl();
    },
    submit(data) {
      this.updateFilters(data);
      this.currentPage = 1;
      this.updatePageUrl();
    },
    async getIpzList() {
      this.loading = true;
      const response = await read("/api/ipzs", this.filters);
      this.pagination = response.pagination;
      this.items = response.items;
      this.loading = false;
    },
    updateFilters(data) {
      this.selectedStatus = data.selectedStatus;
      this.selectedPatient = data.selectedPatient;
      this.selectedWorkers = data.selectedWorkers;
      this.selectedMobileTeamMembers = data.selectedMobileTeamMembers;
    },
    async reopenCanceledIpz(ipz) {
      try{
        this.errors = [];
        await update(`/api/ipzs/${ipz.ipzId}/reopen-canceled`, {});
      } catch (e) {
        this.errors = processResponseException(e);
      }
      await this.getIpzList();
    },
    updatePageUrl() {
      if (isEqual(this.$route.query, this.query)) {
        this.getIpzList();
        return;
      }
      updatePageQuery(this.query);
    },
    openEditIpzStartDateModal(ipz) {
      this.selectedIpz = ipz;
    },
    deselectIpz() {
      this.selectedIpz = null;
    },
    changeOrder(columnName) {
      this.order = {
        column: columnName,
        direction: this.order.direction === "asc" ? "desc" : "asc"
      };
      this.updatePageUrl();
    },
    orderedBy(columnName) {
      return {
        "fa-sort": this.order.column !== columnName,
        "fa-caret-up": this.order.column === columnName,
        "upside-down": this.order.column === columnName && this.order.direction === "asc"
      }
    },
    sortToObject() {
      const sort = {
        column: "displayName",
        direction: "asc"
      };
      if (this.sort) {
        const sortArray = this.sort.split(":");
        sort.column = sortArray[0];
        sort.direction = sortArray[1];
      }
      return sort;
    },
    getBadgeVariant(status) {
      switch (status) {
        case "opened":
          return "success";
        case "closed":
          return "primary";
        case "canceled":
          return "danger";
        default:
          throw Error(`Not supported status: ${status}`);
      }
    },
  },
};
</script>

<style scoped lang="scss">
  $dropdown-height: 136px;
  $table-padding: 20px;
  .fit-dropdown {
    min-height: $dropdown-height + ($table-padding * 2);
  }
  .order-btn {
    cursor: pointer;
    white-space: nowrap;
  }

  .icon {
    transition: transform 1s;
    transform: rotate(0deg);

    &.upside-down {
      transform: rotate(180deg);
    }
  }

  .status-badge{
    width: 5rem;
  }
</style>
