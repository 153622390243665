<template>
  <card
    :title="title"
    :loading="loading"
    :description="description"
  >
    <pagination-description
      :items-count="items.length"
      :page="page"
      :per-page="pagination.perPage"
      :total-count="pagination.totalRows"
    />
    <appointment-table
      :items="items"
      :columns="columns"
      :patient-id="patientId"
      class="table-border-dark"
      display-unreported-treatments
      @appointmentUpdated="loadItems"
    />
    <pagination-description
      :items-count="items.length"
      :page="page"
      :per-page="pagination.perPage"
      :total-count="pagination.totalRows"
    >
      <b-pagination
        v-model="page"
        :per-page="pagination.perPage"
        :total-rows="pagination.totalRows"
        align="right"
        class="mb-0"
      />
    </pagination-description>
  </card>
</template>

<script>
import Card from "../Card";
import AppointmentTable from "./AppointmentTable";
import read from "../../rest/read";
import parseDate from "../../utils/date/parseDate";
import PaginationDescription from "../Pagination/PaginationDescription";

export default {
  name: "AppointmentsReportCard",
  components: {PaginationDescription, AppointmentTable, Card},
  props: {
    title: {type: String, required: true},
    description: {type: String, default: null},
    columns: {type: Array, required: true},
    patientId: {type: String, default: null},
    filters: {type: Object, default: () => ({})},
  },
  data() {
    return {
      loading: false,
      page: 1,
      pagination: {},
      items: [],
    }
  },
  watch: {
    page() {
      this.loadItems();
    },
    patientId() {
      this.loadItems();
    },
  },
  mounted() {
    this.loadItems();
  },
  methods: {
    async loadItems() {
      this.loading = true;
      this.items = [];
      const {items, pagination} = await read("/api/appointments", {
        ...this.filters,
        page: this.page,
        perPage: this.pagination.perPage,
      });
      this.items = items.map((item) => this.processItem(item));
      this.pagination = pagination;
      this.loading = false;
    },
    processItem(item) {
      return {
        ...item,
        date: parseDate(item.date),
      };
    },
  },
}
</script>

