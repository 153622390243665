









































import {mapState, mapMutations} from "vuex";
import Card from "../../components/Card.vue";
import WeeklyScheduleTable from "../../components/WeeklySchedule/WeeklyScheduleTable.vue";
import DateTime from "../../components/DateTime.vue";
import read from "../../rest/read";
import stringifyDate from "../../utils/date/stringifyDate";
import parseDate from "../../utils/date/parseDate";
import DATE_FORMAT from "../../utils/date/DATE_FORMAT";
import ErrorMessage from "../../components/Form/ErrorMessage.vue";
import processResponseException from "../../utils/errors/processResponseException";
import moveDateByDays from "../../utils/moveDateByDays";
import {updatePageQuery} from "../../utils/pageUrl/handlePageQuery";
import WeeklyScheduleFilters from "../../components/WeeklySchedule/WeeklyScheduleFilters.vue";
import ErrorsMixin from "../../mixins/ErrorsMixin.ts";
import {Component, Prop, Watch, Mixins} from "vue-property-decorator";
import {Route} from "vue-router";
import type {
  CanceledAppointmentsParams,
  WeeklyScheduleItem,
  WorkerObject,
  WorkerAvailabilitiesParams,
  WorkerOption,
  WorkersAvailabilitiesData, CanceledAppointmentsData, CanceledAppointment
} from "../../types/WeeklySchedule";
import {isGranted} from "../../security/isGranted";
import {specialistRoles} from "../../utils/roles/specialistRoles";

Component.registerHooks(["beforeRouteLeave"]);

@Component({
  name: "WeeklySchedule",
  components: {
    WeeklyScheduleFilters,
    ErrorMessage,
    Card,
    WeeklyScheduleTable,
    DateTime
  },
  computed: {
    ...mapState({
      user: state => state.currentUser.user,
      partner: state => state.clinicParameters.parameters.partner,
    }),
  },
  methods: {
    ...mapMutations("appointmentListItem", ["saveAppointmentListItem",]),
  },
})

export default class WeeklySchedule extends Mixins(ErrorsMixin) {
  @Prop({type: String, default: null}) readonly start!: string|null;
  @Prop({type: Array, default: () => []}) readonly worker!: Array<string|WorkerOption>;
  @Prop({type: Boolean, default: null}) readonly canceled!: boolean|null;

  startDate: Date = this.getStartDate(this.start);
  loading: boolean = false;
  items: Array<WeeklyScheduleItem> = [];
  workers: Array<WorkerObject> = [];
  canceledAppointments: Array<any> = [];
  DATE_FORMAT = DATE_FORMAT;
  moveDateByDays = moveDateByDays;

  get visibleDays(): number {
    if (this.partner === "wieliczka") {
      return 7;
    }
    return 5;
  }

  get endDate(): Date {
    return moveDateByDays(this.startDate, this.visibleDays);
  }

  get startDateString(): string {

    return stringifyDate(this.startDate, DATE_FORMAT.DATE_TIME);
  }

  get endDateString() {
    return stringifyDate(this.endDate, DATE_FORMAT.DATE_TIME);
  }

  get workerIds() {
    return this.worker.map(worker => (typeof worker === "string") ? worker : worker.value);
  }

  @Watch("start") onStartChange(val: string): void {
    this.startDate = this.getStartDate(val);
  }

  @Watch("$route")
  async onRouteChange(): Promise<void> {
    await this.fetchData();
  }

  async mounted(): Promise<void> {
    if (!this.worker.length) {
      const worker: string|undefined = await isGranted(specialistRoles) ? this.user.workerId : undefined;
      await updatePageQuery({
        worker,
      });
    } else {
      await this.fetchData();
    }
  }

  beforeRouteLeave(to: Route, from: Route, next: any) {
    this.saveAppointmentListItem(null);
    next();
  }

  getStartDate(dateString: string|null): Date {
    const date: Date = dateString ? parseDate(dateString, DATE_FORMAT.DATE_TIME) : new Date();
    const day = date.getDay() || 7;
    date.setDate(date.getDate() + 1 - day);
    date.setHours(0, 0, 0, 0);
    return date;
  }

  async fetchData(): Promise<void> {
    this.errors = [];
    this.loading = true;
    try {
      await Promise.all([
        this.loadWorkersAvailabilities(),
        this.loadCanceledAppointments(),
      ]);
    } catch (exception) {
      this.errors = processResponseException(exception);
    }
    this.loading = false;
  }

  async loadWorkersAvailabilities(): Promise<void> {
    const params: WorkerAvailabilitiesParams = {
      startDate: this.startDateString,
      endDate: this.endDateString,
      workerId: this.workerIds,
      useScheduledDates: true, // doesn't show free time slots from appointments finished earlier than assumed,
      // only start and end dates as originally scheduled
    };
    const {items, workers} = await read<WorkersAvailabilitiesData>("/api/workers/availabilities", params);

    this.workers = workers;
    this.items = items.map(item => {
      return {
        ...item,
        start: parseDate(item.start),
        end: parseDate(item.end),
      };
    });
  }

  async loadCanceledAppointments(): Promise<void> {
    if (!this.canceled) {
      this.canceledAppointments = [];
    } else {
      const end: Date = this.moveDateByDays(this.startDate, this.visibleDays - 1);
      end.setHours(23, 59, 59);
      const params: CanceledAppointmentsParams = {
        startDate: stringifyDate(this.startDate),
        endDate: stringifyDate(end),
        workerIds: this.workerIds,
        statuses: ["canceled"],
        page: 1,
        perPage: 1000,
      };
      const {items} = await read<CanceledAppointmentsData>("/api/appointments", params);

      this.canceledAppointments = items.reduce((list: Array<WeeklyScheduleItem>, item: CanceledAppointment) => {
        const workerItemList: Array<WeeklyScheduleItem> = item.workers.map((element: WorkerObject) => ({
          appointmentId: item.appointmentId,
          branchId: item.branchId,
          branchName: item.branchName,
          start: parseDate(item.date),
          end: parseDate(item.endDate),
          group: item.group,
          isLocal: !item.mobile,
          mainWorkerId: item.mainWorkerId,
          patientPresence: item.patientPresence,
          patientsList: item.patients,
          status: item.status,
          treatmentType: item.treatment_type,
          workerId: element.workerId,
        }));

        return [...list, ...workerItemList];
      }, []);
    }
  }
}
