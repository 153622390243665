<template>
  <anonymous-layout>
    <card
      title="Statusy"
    >
      <Status
        v-for="target in targets"
        :key="target.name"
        :target="target"
        class="m-b-5"
      />
    </card>
  </anonymous-layout>
</template>

<script>
import Card from "../../components/Card";
import Status from "../../components/Health/Status";
import AnonymousLayout from "../../components/Layout/AnonymousLayout";

export default {
  name: "Health",
  components: {AnonymousLayout, Status, Card},
  computed: {
    targets() {
      return [
        {
          name: "postgres",
          url: "/api/health/db"
        },
        {
          name: "redis",
          url: "/api/health/redis",
        },
        {
          name: "titanium",
          url: "/api/health/titanium"
        },
      ];
    }
  }
}
</script>

