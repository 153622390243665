<template>
  <b-card>
    <!-- eslint-disable -->
    <div v-html="content" />
  </b-card>
</template>
<script>
import read from "../../rest/read";

export default {
  name: "Changelog",
  data() {
    return {
      content: "",
    };
  },
  async mounted() {
    const {content} = await read("/api/changelog");
    this.content = content;
  },
}
</script>
