
























import {Component, Mixins, Ref} from "vue-property-decorator";
import ErrorsMixin from "../../mixins/ErrorsMixin";
import NoteForm from "./NoteForm.vue";
import ErrorMessage from "../Form/ErrorMessage.vue";
import UserNotesTable from "./UserNotesTable.vue";

@Component({
  name: "UserNotesModal",
  components: {UserNotesTable, ErrorMessage, NoteForm},
})

export default class UserNotesModal extends Mixins(ErrorsMixin) {
  @Ref("noteForm") readonly noteForm!: NoteForm;
  @Ref("notesTable") readonly notesTable!: UserNotesTable;

  private hideModal(): void {
    this.$bvModal.hide("user-notes");
  }

  private async fetchData() {
    await this.notesTable.fetchData();
  }
}
