<template>
  <loading-mask
    :loading="loading"
    class="card-box"
  >
    <div class="row mb-3">
      <div class="col mb-2">
        <form @submit.prevent="submit">
          <div
            class="input-group w-100"
            style="max-width: 380px;"
          >
            <input
              v-model="currentSearch"
              class="form-control"
              name="query"
              autofocus
              placeholder="Wyszukaj po opisie lub podaj nr pokoju"
            >
            <div class="input-group-append">
              <button
                type="submit"
                class="btn btn-primary"
              >
                <i class="fa fa-search" />
              </button>
            </div>
          </div>
        </form>
      </div>
      <div class="col-auto text-right">
        <is-granted
          :privileges="['CREATE_ROOM']"
          component="fragment"
        >
          <router-link
            :to="{name: 'room_create_view'}"
            class="btn btn-success"
          >
            <i class="fa fa-plus" /> Dodaj pokój
          </router-link>
        </is-granted>
      </div>
    </div>
    <table class="table">
      <thead>
        <tr>
          <th scope="col">
            Komórka organizacyjna
          </th>
          <th scope="col">
            Numer
          </th>
          <th scope="col">
            Piętro
          </th>
          <th scope="col">
            Typ
          </th>
          <th scope="col">
            Opis
          </th>
          <th scope="col">
            Aktywny
          </th>
          <th scope="col">
            Edycja
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="room in items"
          :key="room.id"
        >
          <td>{{ room.branch }}</td>
          <td>{{ room.number }}</td>
          <td>{{ room.floor }}</td>
          <i18n
            :msgid="`@room-type.${room.type}`"
            component="td"
          />
          <td style="word-break: break-all">
            <nl2br
              v-if="room.description"
              :text="room.description"
            />
          </td>
          <td>
            <i
              :class="room.active ? 'fa-check text-success' : 'fa-times text-danger'"
              class="fas"
            />
          </td>
          <td>
            <is-granted
              :privileges="['UPDATE_ROOM']"
              :subject="room.id"
            >
              <router-link
                :to="{name: 'roomUpdateView', params: {roomId: room.id}}"
                class="btn btn-sm btn-primary"
              >
                <i class="fa fa-pencil-alt" />
              </router-link>
            </is-granted>
          </td>
        </tr>
      </tbody>
    </table>
  </loading-mask>
</template>

<script>
import IsGranted from "../../components/IsGranted";
import Nl2br from "../../components/Nl2br";
import read from "../../rest/read";
import {changePageQuery} from "../../utils/pageUrl/handlePageQuery";
import isEqual from "lodash/isEqual";
import LoadingMask from "../../components/Loading/LoadingMask";
import I18n from "../../components/i18n";

export default {
  name: "RoomList",
  components: {I18n, LoadingMask, Nl2br, IsGranted},
  props: {
    search: {type: String, default: ""},
  },
  data() {
    return {
      currentSearch: this.search,
      loading: false,
      items: [],
    };
  },
  computed: {
    query() {
      return {search: this.currentSearch || undefined};
    },
  },
  watch: {
    async "$route"() {
      this.fetchItems();
    },
  },
  mounted() {
    this.fetchItems();
  },
  methods: {
    async fetchItems() {
      this.loading = true;
      const {items} = await read("/api/rooms", this.query);
      this.items = items;
      this.loading = false;
    },
    submit() {
      if (isEqual(this.$route.query, this.query)) {
        this.fetchItems();
        return;
      }
      changePageQuery(this.query);
    },
  }
}
</script>
