import {actions} from "./lastEditedAppointment/actions";
import {getters} from "./lastEditedAppointment/getters";
import {mutations} from "./lastEditedAppointment/mutations";
import {state} from "./lastEditedAppointment/state";
import {Module} from "vuex";
import {LastEditedAppointmentState} from "./lastEditedAppointment/types";
import {RootState} from "../types";

const lastEditedAppointment: Module<LastEditedAppointmentState, RootState> = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};

export default lastEditedAppointment;
