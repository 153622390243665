import {SplashScreenState} from "./types";

const visible = !navigator.onLine;

export const state: SplashScreenState = {
  visible,
  networkFailure: false,
  animate: false,
  timeout: 0,
};
