import {createUrl} from "../utils/pageUrl/createUrl";
import {
  BadRequestException,
  defaults,
  ForbiddenException,
  NotFoundException,
  UnauthorizedException,
  ServiceUnavailableException,
  ConflictException,
  UnknownServerException,
  NetworkFailureException,
} from "./index";
import {store} from "../main";
import {storage, TOKEN} from "../store/modules/session";

export default async function read<Model>(
  resource: string,
  query: object = {},
  showNetworkErrorScreen: boolean = true,
): Promise<Model> {

  const method = "GET";
  const url = createUrl(resource, query);
  let response;

  try {
    const token = storage.getItem(TOKEN);
    if(token && "null" !== token && defaults.headers instanceof Headers){
      defaults.headers.set("x-authorization", `Bearer ${token}`);
    }
    response = await fetch(url, {...defaults, method});
  } catch (e) {
    if (showNetworkErrorScreen) {
      await store.dispatch("splashScreen/showNetworkFailure");
    }
    throw new NetworkFailureException();
  }

  switch(response.status) {
    case 503:
      throw new ServiceUnavailableException();
    case 409:
      throw new ConflictException();
    case 400:
      throw new BadRequestException(await response.json());
    case 404:
      throw new NotFoundException();
    case 403:
      throw new ForbiddenException();
    case 401:
      throw new UnauthorizedException();
    case 200:
      return response.json();
    default:
      throw new UnknownServerException(response);
  }
}
