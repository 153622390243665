











































import {Component,  Prop, Watch} from "vue-property-decorator";
import PatientHospitalizations from "../PatientHospitalizations.vue";
import subErrors from "../../../utils/errors/subErrors";
import read from "../../../rest/read";
import type {HospitalizationValues,} from "../../../types/HospitalizationTypes";
import {HospitalizationsData, HospitalizationsDataFields, WasHospitalized}
  from "../../../types/HospitalizationTypes";
import processResponseException from "../../../utils/errors/processResponseException";
import initHospitalizationsData from "../../../utils/initHospitalizationsData";
import update from "../../../rest/update";
import {mixins} from "vue-class-component";
import ErrorsMixin from "../../../mixins/ErrorsMixin";
import LoadingMask from "../../Loading/LoadingMask.vue";

@Component({components: {
  LoadingMask,
  PatientHospitalizations,
}})
export default class PatientCardHospitalizationsData extends mixins(ErrorsMixin) {
  @Prop({type: String, required: true}) readonly patientId!: string;

  loading: boolean = false;
  formData: HospitalizationsData = initHospitalizationsData();

  get resource(): string
  {
    return `/api/patients/${this.patientId}/hospitalizations-data`;
  }

  @Watch("patientId") async onPatientIdChange(): Promise<void>
  {
    await this.loadData();
  }

  async loadData(): Promise<void>
  {
    this.loading = true;
    try {
      this.formData = await read<HospitalizationsData>(this.resource);
    } catch(e) {
      this.errors = processResponseException(e);
    }
    this.loading = false;
  }

  prepareHospitalizationDataValue(dataField: string): HospitalizationValues {
    return (this.formData[dataField]
      && !this.formData[dataField].doesRemember)
      ? {...this.formData[dataField], value: null}
      : this.formData[dataField];
  }

  async submit(): Promise<void> {
    this.loading = true;
    try {
      const patientHospitalized: boolean =
        this.formData[HospitalizationsDataFields.WAS_HOSPITALIZED] === WasHospitalized.YES;
      const hospitalizationsData = {
        ...this.formData,
        [HospitalizationsDataFields.WHEN_FIRST_HOSPITALIZED]: patientHospitalized
          ? this.prepareHospitalizationDataValue(HospitalizationsDataFields.WHEN_FIRST_HOSPITALIZED) : null,
        [HospitalizationsDataFields.WHEN_LAST_HOSPITALIZED]: patientHospitalized
          ? this.prepareHospitalizationDataValue(HospitalizationsDataFields.WHEN_LAST_HOSPITALIZED) : null,
        [HospitalizationsDataFields.HOW_MANY_HOSPITALIZATIONS_LAST_PERIOD]: patientHospitalized
          ? this.prepareHospitalizationDataValue(
            HospitalizationsDataFields.HOW_MANY_HOSPITALIZATIONS_LAST_PERIOD
          ) : null,
        [HospitalizationsDataFields.HOW_LONG_HOSPITALIZED_LAST_PERIOD]: patientHospitalized
          ? this.prepareHospitalizationDataValue(HospitalizationsDataFields.HOW_LONG_HOSPITALIZED_LAST_PERIOD) : null,
      };
      await update(this.resource, {hospitalizationsData});
      this.cancel();
    } catch (exception) {
      this.errors = processResponseException(exception);
    }
    this.loading = false;
  }

  resetData() {
    this.errors = [];
    this.formData = initHospitalizationsData();
  }

  cancel(): void {
    this.$bvModal.hide("hospitalizations-data");
    this.resetData();
    this.$emit("close");
  }

  getSubErrors(field: string) {
    return subErrors(this.errors, field);
  }
}
