<template>
  <div>
    <h3 class="text-danger">
      Pobyt został odwołany
    </h3>
    Powód odwołania pobytu: {{ cancelReason }}
  </div>
</template>
<script>
export default {
  props: {
    cancelReason: {type: String, required: true}
  }
}
</script>
