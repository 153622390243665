









































































































import {Component, Mixins, Prop, Watch} from "vue-property-decorator";
import ErrorMessage from "../../components/Form/ErrorMessage.vue";
import BooleanRefusalSelect from "../../components/Form/Select/BooleanRefusalSelect.vue";
import PatientSelect from "../../components/Patient/PatientSelect.vue";
import DatePicker from "../../components/Form/DatePicker/DatePicker.vue";
import ErrorsMixin from "../../mixins/ErrorsMixin";
import create from "../../rest/create";
import read from "../../rest/read";
import processResponseException from "../../utils/errors/processResponseException";
import {generateUuid} from "../../utils/uuid/generateUuid";
import stringifyDate from "../../utils/date/stringifyDate";
import DATE_FORMAT from "../../utils/date/DATE_FORMAT";
import Card from "../../components/Card.vue";
import eventBus from "../../eventBus";
import I18n from "../../components/i18n.vue";
import BackButton from "../../components/BackButton.vue";
import ReportContainer from "../../components/Report/ReportContainer.vue";
import PaginationDescription from "../../components/Pagination/PaginationDescription.vue";
import {changePageQuery} from "../../utils/pageUrl/handlePageQuery";
import BranchSelect from "../../components/Branch/BranchSelect";
import type {BvTableFieldArray} from "bootstrap-vue/src/components/table";

interface MzReportRequest {
  mzRequestReportId: string;
}

interface FindResponse {
  items: Array<MzReportRequest>;
  pagination: Pagination;
}

interface Field {

}

interface Pagination {
  perPage: number;
  totalRows: number;
}

interface Query {
  page?: number;
}

interface Filters {
  page?: number;
  year: string| null;
  branch: string | null;
}

enum Statuses {
  PENDING = "pending",
  COMPLETED = "completed",
  REJECTED = "rejected",
}

@Component({
  components: {
    ReportContainer,
    PaginationDescription,
    BackButton,
    I18n,
    ErrorMessage,
    BooleanRefusalSelect,
    PatientSelect,
    DatePicker,
    Card,
    BranchSelect
  },
})
export default class MzReportView extends Mixins(ErrorsMixin){
  @Prop({type: Number, default: null}) readonly page !: number;

  async mounted(): Promise<void> {
    await this.loadData();
  }

  items: Array<MzReportRequest> | null = null;
  isLoading: boolean = false;
  isSaving: boolean = false;
  currentPage: number = this.page || 1;
  pagination: Object = {};
  year: Date|null = null;
  branch: String|null = null;

  @Watch("page") onPageChange(page: number): void {
    this.currentPage = page;
  }

  get fields(): BvTableFieldArray {
    return [
      {key: "createdAt", label: "Data zlecenia"},
      {key: "branchName", label: "Komórka"},
      {key: "status", label: "Status", class: "text-center"},
      {key: "year", label: "Rok raportu"},
      {key: "download", label: "Pobierz", class: "text-center"},
    ];
  }

  get filters():Filters {
    return {
      page: this.currentPage || undefined,
      year: this.year ? stringifyDate(this.year, DATE_FORMAT.YEAR): null,
      branch: this.branch || undefined,
    }
  }

  get query():Query {
    return {
      page: this.currentPage || undefined
    }
  }

  clearForm(): void {
    this.year = null;
    this.branch = null;
  }

  async changePage(page: number): Promise<void> {
    this.currentPage = page;
    await Promise.all([
      this.loadData(),
      changePageQuery(this.query),
    ]);
  }

  async loadData(): Promise<void>{
    this.isLoading = true;
    const {items, pagination} = await read<FindResponse>("/api/report/request-mz-report", this.filters);
    this.items = items;
    this.pagination = pagination;
    this.isLoading = false;
  }

  async createReport(): Promise<void> {
    this.isSaving = true;
    try {
      const requestIKUPReportRecordsId = generateUuid() as string;
      await create("/api/report/request-mz-report", {
        requestMzReportId: generateUuid(),
        year: this.year ? stringifyDate(this.year, DATE_FORMAT.YEAR) : null,
        branch: this.branch,
      });
      this.clearForm();
      await this.loadData();
      eventBus.on(requestIKUPReportRecordsId, ()=>{ this.loadData() });
    } catch (exception) {
      this.errors = processResponseException(exception);
    }
    this.isSaving = false;
  }

  disabledDate(date: Date): boolean{
    return date > new Date();
  }

  downloadStatusIconClass(status: Statuses): string | void {
    switch(status)  {
      case Statuses.PENDING:
        return "fa-spin fa-spinner";
      case Statuses.REJECTED:
        return "fa-ban text-danger";
      default:
        return;
    }
  }

  getBadgeVariant(status: Statuses): string {
    switch(status) {
      case Statuses.PENDING:
        return "success";
      case Statuses.COMPLETED:
        return "primary";
      case Statuses.REJECTED:
        return "danger";
      default:
        throw Error(`Not supported status: ${status}`);
    }
  }
}
