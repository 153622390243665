import {ActionTree} from "vuex";
import {ModalType, ScheduleAppointmentState} from "./types";
import {RootState} from "../../types";

export const actions: ActionTree<ScheduleAppointmentState, RootState> = {
  openModal(context, modalType = ModalType.DEFAULT) {
    context.commit("openModal", modalType);
  },
  closeModal(context) {
    context.commit("closeModal");
  },
  setAppointmentData(context, data) {
    context.commit("setAppointmentData", data);
  },
  resetAppointmentData(context) {
    context.commit("resetAppointmentData");
  },
  openSummaryModal(context) {
    context.commit("openSummaryModal");
  },
  closeSummaryModal(context) {
    context.commit("closeSummaryModal");
  },
  updateAppointmentDetails(context, data) {
    context.commit("updateAppointmentDetails", data);
  },
  resetAppointmentDetails(context) {
    context.commit("resetAppointmentDetails");
  },
  step(context) {
    context.commit("step");
  },
  stepBack(context) {
    context.commit("stepBack");
  },
  stepReset(context) {
    context.commit("stepReset");
  },
};
