






























































































import {Component, Mixins, Prop} from "vue-property-decorator";
import ErrorMessage from "../Form/ErrorMessage.vue";
import ErrorsMixin from "../../mixins/ErrorsMixin.ts";
import DatePicker from "../Form/DatePicker/DatePicker.vue";
import TreatmentTypeSelect from "../TreatmentType/TreatmentTypeSelect.vue";
import TextareaCounter from "../Form/Textarea/TextareaCounter.vue";
import create from "../../rest/create";
import processResponseException from "../../utils/errors/processResponseException";
import {generateUuid} from "../../utils/uuid/generateUuid";
import DATE_FORMAT from "../../utils/date/DATE_FORMAT";
import stringifyDate from "../../utils/date/stringifyDate";

@Component({
  name: "CreateSocialActivityModal",
  components: {TextareaCounter, TreatmentTypeSelect, ErrorMessage, DatePicker}
})
export default class CreateSocialActivityModal extends Mixins(ErrorsMixin) {
  @Prop({type: String, required: true}) readonly patientId!: string;

  private treatmentTypeId: string | null = null;
  private description: string | null = null;
  private dateFrom: string | null = null;
  private dateTo: string | null = null;
  private loading: boolean = false;

  private onHide(): void {
    this.resetData();
    this.$emit("created");
  }

  dateString(date: Date): string | null {
    return date ? stringifyDate(date, DATE_FORMAT.DATE) : null;
  }

  resetData(): void {
    this.treatmentTypeId = null;
    this.description = null;
    this.dateFrom = null;
    this.dateTo = null;
    this.errors = [];
  }

  async submit(): Promise<void> {
    this.errors = [];
    this.loading = true;
    try {
      await create("/api/social-activity", {
        socialActivityId: generateUuid(),
        patientId: this.patientId,
        treatmentTypeId: this.treatmentTypeId,
        description: this.description,
        dateFrom: this.dateFrom ? this.dateString(new Date(this.dateFrom)) : null,
        dateTo: this.dateTo? this.dateString(new Date(this.dateTo)) : null
      });
      this.hideModal();
      this.$emit("created");
    } catch(e) {
      this.errors = processResponseException(e);
    }
    this.loading = false;
  }

  hideModal(): void {
    this.$bvModal.hide("create-social-activity");
  }
}
