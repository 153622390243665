<template>
  <tr>
    <td>
      {{ rangeStringFirstLine }} <span 
        v-if="range.isOverriding" 
        class="overridingRule"
      >reguła nadpisująca</span> <br>
      {{ rangeStringSecondLine }} <br>
      <span> komórka organizacyjna: {{ range.branchName }} </span>
    </td>
    <td v-if="editMode">
      <tooltip
        class="btn btn-sm btn-outline-secondary"
        component="button"
        type="button"
        tooltip-content="Usuń"
        @click="$emit('deleteRange', range)"
      >
        <i
          class="fa fa-trash"
        />
      </tooltip>
    </td>
  </tr>
</template>

<script>
import parseDate from "../../../utils/date/parseDate";
import Tooltip from "../../Tooltip";

export default {
  name: "Range",
  components: {Tooltip},
  props: {
    range: {type: Object, required: true},
    editMode: {type: Boolean, required: false, default: true}
  },
  data() {
    return {
      parseDate: parseDate,
      localeOptions: {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit"
      },
    }
  },
  computed: {
    dateStart() {
      return parseDate(this.range.rangeStart, "YYYY-MM-DD HH:mm");
    },
    dateEnd() {
      return parseDate(this.range.rangeEnd, "YYYY-MM-DD HH:mm");
    },
    rangeStringFirstLine() {
      if (true === this.isRangeInsideOneDay()) {
        return this.dateStart.toLocaleDateString(
          "pl-PL",
          {weekday: "long", year: "numeric", month: "long", day: "numeric"}
        )
      }
      return `${this.dateStart.toLocaleDateString("pl-PL", this.localeOptions)  } - `
    },
    rangeStringSecondLine() {
      if (true === this.isRangeInsideOneDay()) {
        return `${this.getHour(this.dateStart)  } - ${  this.getHour(this.dateEnd)}`
      }
      return this.dateEnd.toLocaleDateString("pl-PL", this.localeOptions)
    }
  },
  methods: {
    getHour(dateTime) {
      const hour = dateTime.getHours();
      const minute = dateTime.getMinutes().toLocaleString("en", {minimumIntegerDigits:2});
      return `${hour}:${minute}`
    },
    isRangeInsideOneDay() {
      const dateStartDay = new Date(this.dateStart.getTime()).setHours(0, 0, 0, 0);
      const dateEndDay = new Date(this.dateEnd.getTime()).setHours(0, 0, 0, 0);

      return dateStartDay.valueOf() === dateEndDay.valueOf();
    }
  }
}
</script>
<style scoped>
  .overridingRule {
    background-color: #00b19d;
    color: #FFF;
  }
</style>
