








































import Vue from "vue";
import {Component, Prop, Watch} from "vue-property-decorator";
import HospitalSelect from "./HospitalSelect.vue";
import TextareaCounter from "../../../Form/Textarea/TextareaCounter.vue";

interface HospitalEntry {
  name: string,
  address: string,
}

interface HospitalOption {
  name: string,
  value: HospitalEntry,
}

@Component({name: "HospitalWidget", components: {TextareaCounter, HospitalSelect}})
export default class HospitalWidget extends Vue {
  @Prop({type: Object, default: null}) readonly value!: HospitalEntry|null;
  @Prop({type: Boolean, default: null}) readonly state!: boolean|null;

  selectedValue: HospitalOption|null = null;
  hospitalName: string = "";
  hospitalAddress: string = "";

  onChangedSelectedValue(option: HospitalOption|null): void {
    this.selectedValue = option;
    this.hospitalName = option ? option.value.name : "";
    this.hospitalAddress = option ? option.value.address : "";
    this.onChangedInputValue();
  }

  onChangedInputValue(): void {
    const address: string = this.hospitalAddress;
    const name: string = this.hospitalName;
    const hospitalEntry: HospitalEntry|null = (address || name)
      ? {address, name}
      : null;
    this.$emit("input", hospitalEntry);
  }

  @Watch("value")
  onChangedValue(newVal: HospitalEntry|null): void {
    this.selectedValue = newVal ? {name: newVal.name, value: newVal} : null
    this.hospitalName = newVal ? newVal.name : "";
    this.hospitalAddress = newVal ? newVal.address : "";
  }
}
