export default function decodeBirthday(pesel) {

  let century = Math.floor(20+ parseInt(pesel.charAt(2)) / 2);
  century = 24 === century ? 19 : century;
  const year = (century - 1) * 100 + parseInt(pesel.substr(0,2));
  const month = parseInt(pesel.substr(2,2)) % 20;
  const day = parseInt(pesel.substr(4, 2));

  return {
    year: year,
    month: month,
    day: day
  }
}
