<template>
  <div>
    <audit-log-filters
      :end-date="parsedEndDate"
      :start-date="parsedStartDate"
      :text="text"
      :user="user"
      :class-name-phrase="classNamePhrase"
      @reload="getAuditLogList"
    />
    <card
      :loading="loading"
      title=""
    >
      <template v-if="!loading">
        <audit-log-table
          :items="items"
        />
        <pagination-description
          :items-count="items.length"
          :page="currentPage"
          :per-page="pagination.perPage"
          :total-count="pagination.totalRows"
        >
          <b-pagination
            :total-rows="pagination.totalRows"
            :per-page="pagination.perPage"
            :value="currentPage"
            align="right"
            class="mb-0"
            @input="changePage"
          />
        </pagination-description>
      </template>
    </card>
  </div>
</template>
<script>

import read from "../../rest/read";
import AuditLogFilters from "../../components/Audit/AuditLogFilters";
import AuditLogTable from "../../components/Audit/AuditLogTable";
import Card from "../../components/Card";
import parseDate from "../../utils/date/parseDate";
import PaginationDescription from "../../components/Pagination/PaginationDescription";
import {updatePageQuery} from "../../utils/pageUrl/handlePageQuery";

export default {
  components: {
    PaginationDescription,
    Card,
    AuditLogTable,
    AuditLogFilters,
  },
  props: {
    startDate: {type: String, default: null},
    endDate: {type: String, default: null},
    text: {type: String, default: null},
    user: {type: String, default: null},
    classNamePhrase: {type: String, default: null},
    page: {type: Number, default: null},
  },
  data() {
    return {
      currentPage: this.page || 1,
      loading: false,
      items: [],
      pagination: {
        perPage: 20,
      },
    };
  },
  computed: {
    parsedStartDate() {
      return this.startDate ? parseDate(this.startDate) : null;
    },
    parsedEndDate() {
      return this.endDate ? parseDate(this.endDate) : null;
    },
    filters() {
      return {
        startDate: this.startDate,
        endDate: this.endDate,
        text: this.text,
        user: this.user,
        classNamePhrase: this.classNamePhrase,
        perPage: this.pagination.perPage,
        page: this.currentPage,
      };
    },
  },
  watch: {
    page(val) {
      this.currentPage = val || 1;
    },
    "$route"() {
      this.getAuditLogList();
    },
  },
  async mounted() {
    this.getAuditLogList();
  },
  methods: {
    changePage(page) {
      this.currentPage = page;
      updatePageQuery({page: this.currentPage});
    },
    async getAuditLogList() {
      this.loading = true;
      const response = await read("/api/audit", this.filters);
      this.pagination = response.pagination;
      this.items = response.items;
      this.loading = false;
    },
  },
}
</script>

<style scoped>
  .table {
    font-size: 12px;
  }
</style>
