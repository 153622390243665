import { render, staticRenderFns } from "./TargetsAndTasksBadge.vue?vue&type=template&id=68001710&scoped=true&"
import script from "./TargetsAndTasksBadge.vue?vue&type=script&lang=ts&"
export * from "./TargetsAndTasksBadge.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68001710",
  null
  
)

export default component.exports