<template>
  <treeselect
    :value="value"
    :options="options"
    :flat="true"
    :multiple="false"
    :default-expand-level="0"
    :name="name"
    :state="state"
    :load-options="loadOptions"
    :placeholder="placeholder"
    :clearable="clearable"
    :no-options-text="noOptionsText"
    :class="{invalid: isInvalid}"
    clear-all-text="Odznacz wszystko"
    clear-value-text="Odznacz"
    disable-branch-nodes
    loading-text="Pobieranie opcji..."
    no-children-text="Brak rozwijanych opcji"
    no-results-text="Brak wyników wyszukiwania"
    retry-text="Pobrać ponownie?"
    retry-title="Kliknij by pobrać ponownie"
    search-prompt-text="Zacznij pisać..."
    @input="update"
    @select="select"
  />
</template>

<script>
import read from "../../../rest/read";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  components: {Treeselect},
  props: {
    name: {default: "disabilityLevel", type: String},
    value: {type: [Object, String, Array], default: null},
    disabled: {default: false, type: Boolean},
    clearable: {default: true, type: Boolean},
    placeholder: {type: String, default: "Wybierz poziom niepełnosprawności"},
    multiple: {default: false, type: Boolean},
    state: {default: null, type: Boolean},
    clearOnOpen: {type: Boolean, default: false},
    noOptionsText: {type: String, default: "Brak opcji do wyświetlenia"},
  },
  data() {
    return {
      options: null
    };
  },
  computed: {
    isInvalid() {
      return false === this.state;
    },
  },
  methods: {
    async loadOptions() {
      const {items} = await read("/api/disability-levels", {});

      this.options = items;
    },
    update(option) {
      if(option === undefined) {
        this.select(null);
      }
      this.$emit("input", option || null);
    },
    select(option) {
      this.$emit("select", option || null);
    }
  },
}
</script>
