















































import Vue from "vue";
import type {PersonOfCareFormData} from "../../types/Zla";
import {Component, Prop} from "vue-property-decorator";
import PersonOfCareSelect from "./PersonOfCareSelect.vue";
import DatePicker from "../Form/DatePicker/DatePicker.vue";
import {Error} from "../../rest";
import ErrorMessage from "../Form/ErrorMessage.vue";

const initialState: PersonOfCareFormData = {
  type: null,
  birthDate: null,
}

@Component({
  name: "PersonOfCareForm",
  components: {
    ErrorMessage,
    PersonOfCareSelect,
    DatePicker,
  },
})
export default class PersonOfCareForm extends Vue {
  @Prop({type: Object, default: null}) readonly value!: PersonOfCareFormData;
  @Prop({type: Array, default: ()=>[]}) readonly errorsProp!: Error[];

  private updateCheckbox(): void {
    const newValue = this.value
      ? null
      : {...initialState};
    this.$emit("input", newValue);
  }

  private updateData($event: string, field: string):void {
    const baseValue = this.value || initialState;
    this.$emit("input", {
      ...baseValue,
      [field]: $event
    });
  }

  private state(field: string): boolean|null {
    return this.errorsProp.find((error) => error.field === field) ? false : null;
  }
}
