<template>
  <div class="row m-b-30">
    <div class="col-2 text-right">
      {{ field.entryType.name }}
    </div>
    <div class="col-10">
      <div
        v-if="!editing"
        class="row m-b-10"
      >
        <div
          v-if="!!entries.length"
          class="col"
        >
          <div class="card-box disabled-data">
            <read-more
              :text="entry.content.text ? entry.content.text : entry.content"
              :max-chars="400"
              more-str="Czytaj dalej"
              less-str="Mniej"
            />
            <span
              class="font-weight-bold"
              :title="entry.createdAt"
            >
              {{ stringifyDate(entry.createdAt) }} - {{ entry.creator.name }} {{ entry.creator.surname }}
            </span>
          </div>
        </div>
        <div class="col-auto">
          <button
            v-if="!readOnly"
            class="btn-success btn"
            @click="editing = !editing"
          >
            <template v-if="entry">
              Aktualizuj
            </template>
            <template v-else>
              Dodaj wpis
            </template>
          </button>
        </div>
      </div>
      <string-entry-add
        v-else-if="!readOnly"
        :diagnosis-id="diagnosisId"
        :type="field.entryType.key"
        :type-name="field.entryType.name"
        :previous-entry="entry"
        @new-entry-added="entryAdded"
        @cancel-adding-entry="canceledEntry"
      />
    </div>
  </div>
</template>

<script>
import ReadMore from "../../../ReadMore";
import StringEntryAdd from "./StringEntryAdd";
import {default as getEntry} from "../EntryField";
import stringifyDate from "../../../../utils/date/stringifyDate";
import DATE_FORMAT from "../../../../utils/date/DATE_FORMAT";

export default {
  name: "StringField",
  components: {
    StringEntryAdd,
    ReadMore,
  },
  props: {
    diagnosisId: {type: String, required: true},
    field: {type: Object, required: true},
    readOnly: {type: Boolean, required: true},
  },
  data() {
    return {
      editing: false,
      entries: this.field.entries || [],
    };
  },
  computed: {
    entry() {
      return getEntry(this.entries);
    },
  },
  methods: {
    entryAdded(entry) {
      this.entries = this.entries.concat([entry]);
      this.editing = false;
      this.$emit("new-entry-added");
    },
    canceledEntry() {
      this.editing = false;
    },
    stringifyDate(date) {
      return stringifyDate(new Date(date), DATE_FORMAT.DATE);
    }
  },
}
</script>
