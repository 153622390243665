<template>
  <div class="card-box">
    <div class="text-center">
      <i class="fas fa-lock fa-7x d-block mb-4" />
      <span v-if="blocked">
        Pacjent cofnął zgodę na przetwarzanie danych osobowych
        <br>
      </span>
      <span v-if="!displayMedicalContent">
        Nie posiadasz uprawnień do przeglądania treści medycznych
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppointmentDocumentHiddenInfo",
  props: {
    blocked: {type: Boolean, required: true},
    displayMedicalContent: {type: Boolean, required: true},
  },
}
</script>
