

































































import {Component, Mixins, Prop, Watch} from "vue-property-decorator";
import ErrorsMixin from "../../../mixins/ErrorsMixin";
import {AcceptedReferral} from "../../Patient/PatientCard/PatientCardAcceptedReferrals.vue";
import LoadingMask from "../../Loading/LoadingMask.vue";
import AcceptReferralModal from "../../AcceptedReferrals/AcceptReferralModal.vue";
import AcceptedReferralsTable from "../../AcceptedReferrals/AcceptedReferralsTable.vue";
import read from "../../../rest/read";
import {ResponseCollection} from "../../../types/ResponseCollection";
import {isGranted} from "../../../security/isGranted";
import processResponseException from "../../../utils/errors/processResponseException";
import AssignReferralModal from "../../AcceptedReferrals/AssignReferralModal.vue";

interface Module {
  type: string;
  referrals: AcceptedReferral[];
}

@Component({
  name: AcceptedReferralsModule.name,
  components: {
    LoadingMask,
    AcceptReferralModal,
    AcceptedReferralsTable,
    AssignReferralModal,
  }
})
export default class AcceptedReferralsModule extends Mixins(ErrorsMixin) {
  @Prop({required: true, type: Number}) readonly idx!: number;
  @Prop({required: true, type: Object}) readonly module!: Module;
  @Prop({required: true, type: Boolean}) readonly readOnly!: boolean;
  @Prop({required: true, type: String}) readonly patientId!: string;
  @Prop({required: true, type: String}) readonly appointmentId!: string;
  @Prop({required: true, type: String}) readonly appointmentDocumentId!: string;

  private loading: boolean = false;
  private referrals: AcceptedReferral[] = [];
  private hasP1Access: boolean = true;

  async mounted(): Promise<void> {
    this.hasP1Access = await isGranted("ACCESS_P1_CLIENT");
  }

  @Watch("patientId", {immediate: true}) async onPatientIdChange(): Promise<void> {
    await this.loadData()
  }

  private async loadData(): Promise<void> {
    this.loading = true;
    try {
      const {items} = await read<ResponseCollection<AcceptedReferral>>("/api/find-accepted-referral", {
        patientId: this.patientId,
        appointmentDocumentId: this.appointmentDocumentId,
      });
      this.referrals = items;
    }catch(e) {
      this.errors = processResponseException(e);
    }
    this.loading = false;
  }

}
