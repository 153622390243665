
























































import type {EstablishmentItem} from "../../types/Establishment";
import {Component, Mixins} from "vue-property-decorator";
import EstablishmentTreeView from "../../components/Establishment/EstablishmentTreeView.vue";
import ErrorsMixin from "../../mixins/ErrorsMixin";
import AddEstablishmentChildForm from "../../components/Establishment/AddEstablishmentChildForm.vue";
import processResponseException from "../../utils/errors/processResponseException";
import read from "../../rest/read";
import CreateEstablishmentModal from "../../components/Establishment/CreateEstablishmentModal.vue";
import Card from "../../components/Card.vue";
import ErrorMessage from "../../components/Form/ErrorMessage.vue";
import RemoveEstablishmentChildModal from "../../components/Establishment/RemoveEstablishmentChildModal.vue";
import EditEstablishmentChildModal from "../../components/Establishment/EditEstablishmentChildForm.vue";
import IsGranted from "../../components/IsGranted.vue";
import {EstablishmentType} from "../../types/Establishment";

@Component({
  name: "EstablishmentPage",
  components: {
    IsGranted,
    EditEstablishmentChildModal,
    RemoveEstablishmentChildModal,
    ErrorMessage, Card, CreateEstablishmentModal, AddEstablishmentChildForm, EstablishmentTreeView},
})
export default class EstablishmentPage extends Mixins(ErrorsMixin) {
  private loading: boolean = false;
  private editing: boolean = false;
  public items: Array<EstablishmentItem> =  [];
  private updatedItem: EstablishmentItem|null = null;
  private createType: EstablishmentType|null = null;
  EstablishmentType = EstablishmentType;

  async mounted():Promise<void> {
    await this.fetchData();
  }

  removeChild(updatedItem: EstablishmentItem|null): void {
    this.updatedItem = updatedItem;
    this.$bvModal.show("remove-establishment-child-modal");
  }

  async fetchData(): Promise<void> {
    this.loading = true;
    try {
      const {items} = await read<{items: Array<EstablishmentItem>}>("/api/branches", {noRestrict: true});
      this.items = items;
    } catch(e) {
      this.errors = processResponseException(e);
    }
    this.loading = false;
  }

  async update(): Promise<void> {
    this.updatedItem = null;
    await this.fetchData();
  }

  private openCreateModal(type: EstablishmentType): void {
    this.createType = type;
    this.$bvModal.show("create-establishment");
  }
}
