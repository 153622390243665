<template>
  <div>
    <div class="card-box m-b-5">
      <div class="text-right">
        <b-btn
          v-b-modal.interventionNotification
          class="btn btn-success waves-effect"
        >
          <i class="fa fa-plus" /> Dodaj nowe zdarzenie
        </b-btn>
      </div>
      <form
        class="form-row"
        @submit.prevent="submit"
      >
        <b-form-group
          class="col-lg-6"
          label="Fragment tekstu zgłoszenia"
        >
          <b-input
            v-model="currentPhrase"
            class="form-control"
            name="phrase"
            autofocus
            placeholder="Wpisz fragment tekstu zgłoszenia..."
          />
        </b-form-group>
        <b-form-group
          class="col-sm-6 col-lg-2"
          label="Początek"
        >
          <date-picker
            v-model="start"
          />
        </b-form-group>
        <b-form-group
          class="col-sm-6 col-lg-2"
          label="Koniec"
        >
          <date-picker
            v-model="end"
          />
        </b-form-group>
        <div class="col-lg-2 no-label">
          <button
            type="submit"
            class="btn btn-primary"
          >
            <i class="fa fa-search" /> Szukaj
          </button>
        </div>
      </form>
    </div>
    <card
      v-if="items"
      ref="content"
      :loading="loading"
      title=""
    >
      <pagination-description
        v-if="pagination"
        :items-count="items.length"
        :page="currentPage"
        :per-page="pagination.perPage"
        :total-count="pagination.totalRows"
      />
      <div class="table-responsive">
        <table class="table table-hover">
          <thead>
            <tr>
              <th scope="col">
                Data utworzenia
              </th>
              <th scope="col">
                Numer referencyjny
              </th>
              <th scope="col">
                Kategoria
              </th>
              <th scope="col">
                Płeć
              </th>
              <th scope="col">
                Przedział wiekowy
              </th>
              <th scope="col">
                Czas trwania
              </th>
              <th scope="col">
                Autor
              </th>
              <th scope="col">
                Opcje
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="notification in items"
              :key="notification.interventionNotificationId"
            >
              <td>{{ notification.createdAt }}</td>
              <td>{{ notification.referenceNumber }}</td>
              <td><Translation :msgid="notification.category" /></td>
              <td>
                <Translation
                  v-if="notification.sex"
                  :msgid="`@sex.${notification.sex}`"
                />
              </td>
              <td>
                <Translation
                  v-if="notification.age"
                  :msgid="`@age.${notification.age}`"
                />
              </td>
              <td>
                <span v-if="notification.startDate">
                  od {{ stringifyDate(notification.startDate) }}
                </span>
                <span v-if="notification.duration">
                  przez {{ notification.duration }} minut
                </span>
              </td>
              <td>{{ notification.author }}</td>
              <td>
                <router-link
                  :to="{name: 'intervention_notification_single',
                        params: {interventionNotificationId: notification.interventionNotificationId}}"
                  class="btn btn-success btn-sm mr-1"
                >
                  Pokaż szczegóły
                </router-link>
                <a
                  :href="`/api/intervention-notifications/${notification.interventionNotificationId}/print`"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="btn btn-primary btn-sm mr-1"
                ><i class="fa fa-print" /> Wydrukuj</a>
              </td>
            </tr>
          </tbody>
        </table>
        <p
          v-if="0 === items.length"
          class="text-center"
        >
          Brak wyników do wyświetlenia
        </p>
      </div>
      <pagination-description
        v-if="pagination"
        :items-count="items.length"
        :page="currentPage"
        :per-page="pagination.perPage"
        :total-count="pagination.totalRows"
      >
        <b-pagination
          v-model="currentPage"
          :per-page="pagination.perPage"
          :total-rows="pagination.totalRows"
          align="right"
          class="mb-0"
          @input="changePage"
        />
      </pagination-description>
    </card>
  </div>
</template>

<script>
import Translation from "../../components/i18n";
import stringifyDate from "../../utils/date/stringifyDate";
import DATE_FORMAT from "../../utils/date/DATE_FORMAT";
import Card from "../../components/Card";
import DatePicker from "../../components/Form/DatePicker/DatePicker";
import parseDate from "../../utils/date/parseDate";
import {mapState, mapActions} from "vuex";
import PaginationDescription from "../../components/Pagination/PaginationDescription";
import {changePageQuery} from "../../utils/pageUrl/handlePageQuery";
import isEqual from "lodash/isEqual";

export default {
  components: {
    PaginationDescription,
    DatePicker,
    Card,
    Translation
  },
  props: {
    phrase: {type: String, default: ""},
    page: {type: Number, default: null},
    startDate: {type: String, default: null},
    endDate: {type: String, default: null},
  },
  data() {
    return {
      currentPhrase: this.phrase,
      currentPage: this.page || 1,
      start: this.startDate ? parseDate(this.startDate) : null,
      end: this.endDate ? parseDate(this.endDate) : null,
    }
  },
  computed: {
    ...mapState("interventionNotifications", ["items", "pagination", "loading"]),
    filters() {
      return {
        page: this.currentPage,
        phrase: this.currentPhrase || undefined,
        startDate: this.startDateString,
        endDate: this.endDateString,
      };
    },
    startDateString() {
      return this.start ? stringifyDate(this.start) : undefined;
    },
    endDateString() {
      if (!this.end) {
        return;
      }
      const date = new Date(this.end.getTime());
      date.setHours(23, 59, 59);
      return stringifyDate(date);
    },
  },
  watch: {
    phrase(val) {
      this.currentPhrase = val;
    },
    startDate(val) {
      this.start = val ? parseDate(val) : null;
    },
    endDate(val) {
      this.end = val ? parseDate(val) : null;
    },
    page(val) {
      this.currentPage = val || 1;
    },
    async "$route"() {
      this.fetchData();
    },
  },
  async mounted() {
    await this.fetchData();
  },
  methods: {
    ...mapActions("interventionNotifications", ["getItems"]),
    async fetchData(){
      this.getItems(this.filters);
    },
    stringifyDate(date) {
      return stringifyDate(new Date(date), DATE_FORMAT.DATE_TIME);
    },
    updatePageUrl() {
      if (isEqual(this.$route.query, this.filters)) {
        this.fetchData();
        return;
      }
      changePageQuery(this.filters);
    },
    async changePage(page) {
      this.currentPage = page;
      this.updatePageUrl();
    },
    submit() {
      this.currentPage = 1;
      this.updatePageUrl();
    },
  },
}
</script>
