<template>
  <div>
    <loading-icon
      v-if="loading"
      class="form-control"
      size="sm"
      text
    />
    <AsyncSelect
      v-else
      :disabled="disabled"
      :clearable="clearable"
      :placeholder="placeholder"
      :options-provider="loadOptions"
      :value="selectedOption"
      :name="name"
      :multiple="multiple"
      :state="state"
      @input="update"
      @open="open"
    />
  </div>
</template>
<script>
import AsyncSelect from "../Form/Select/AsyncSelect";
import read from "../../rest/read";
import LoadingIcon from "@/components/Loading/LoadingIcon";

const branch2option = (branch) => ({
  ...branch,
  value: branch.branchId,
});

export default {
  components: {LoadingIcon, AsyncSelect},
  props: {
    name: AsyncSelect.props.name, // eslint-disable-line
    value: {type: [Object, String, Array], default: null},
    disabled: AsyncSelect.props.disabled, // eslint-disable-line
    clearable: AsyncSelect.props.clearable, // eslint-disable-line
    placeholder: {type: String, default: "Wybierz podmiot"},
    multiple: AsyncSelect.props.multiple, // eslint-disable-line
    state: AsyncSelect.props.state, // eslint-disable-line
    excludeBranchIds: {type: Array, default: null},
    noRestrict: {type: Boolean, default: false},
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    selectedOption() {
      if (!this.value) {
        return this.value;
      } else if (Array.isArray(this.value)) {
        if ("string" === typeof this.value[0]) {
          this.loadSelectedValue();
        } else {
          return this.value.map(branch => {
            if (!branch.value) {
              branch.value = branch.branchId;
            }
            return branch;
          });
        }
      } else if ("object" === typeof this.value) {
        return branch2option(this.value);
      } else if ("string" === typeof this.value) {
        this.loadSelectedValue();
      }
      return null;
    },
  },
  methods: {
    async loadOptions() {
      const params = {
        flat: true,
        branchTypes: ["establishment", "non_medical_establishment"],
        noRestrict: this.noRestrict
      };
      let {items} = await read("/api/branches", params);

      if (this.excludeBranchIds) {
        items = this.excludeBranchOption(items);
      }

      return items.map(branch2option);
    },
    update(option) {
      this.$emit("input", option);
    },
    open() {
      this.$emit("open");
    },
    async loadSelectedValue() {
      this.loading = true;
      const branchIds = Array.isArray(this.value) ? this.value : [this.value];
      const params = {
        branchIds,
        flat: true,
        branchTypes: ["establishment", "non_medical_establishment"],
        noRestrict: this.noRestrict,
      };
      const {items} = await read("/api/branches", params);
      const value = Array.isArray(this.value) ? items.map(branch2option) : items[0];
      this.$emit("input", value);
      this.loading = false;
    },
    excludeBranchOption(branches) {
      return branches.filter(branch => !this.excludeBranchIds.includes(branch.branchId));
    },
  },
}
</script>
