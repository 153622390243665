


















































import ErrorsMixin from "../../mixins/ErrorsMixin.ts";
import {Component, Mixins, Prop, Watch} from "vue-property-decorator";
import processResponseException from "../../utils/errors/processResponseException";
import FormRow from "../Form/FormRow.vue";
import AddressForm from "../Address/AddressForm.vue";
import type {EstablishmentAddress, EstablishmentItem} from "../../types/Establishment";
import {EstablishmentType} from "../../types/Establishment";
import ErrorMessage from "../Form/ErrorMessage.vue";
import {generateUuid} from "../../utils/uuid/generateUuid";
import I18n from "../i18n.vue";
import EstablishmentChildForm from "./EstablishmentChildForm.vue";
import isEmpty from "lodash/isEmpty";
import create from "../../rest/create";

const formDataInitialState: FormData = {
  name: null,
  code: null,
  address: {},
  nip: null,
  regon: null,
  phoneNumber: null,
  codeEight: null,
};

interface FormData {
  name: EstablishmentItem["name"]|null;
  code: EstablishmentItem["code"]|null;
  address: EstablishmentAddress|{};
  nip: EstablishmentItem["nip"]|null;
  regon: EstablishmentItem["regon"]|null;
  phoneNumber: EstablishmentItem["phoneNumber"]|null;
  codeEight?: EstablishmentItem["codeEight"]|null;
}

interface SubmitData {
  branchId: string;
  parentId: string|null;
  name: string|null;
  code?: string|null;
  type: EstablishmentType|null;
  address?: EstablishmentAddress|{}|null;
  nip?: string|null;
  regon?: string|null;
  phoneNumber?: string|null;
  codeEight?: string|null;
}

@Component({
  name: "AddEstablishmentChildForm",
  components: {EstablishmentChildForm, I18n, ErrorMessage, AddressForm, FormRow}
})

export default class AddEstablishmentChildForm extends Mixins(ErrorsMixin) {
  @Prop({type: Object, default: null}) readonly parent!: EstablishmentItem;

  loading: boolean = false;
  formData: FormData = formDataInitialState;

  get type(): EstablishmentItem["type"]|null {
    if (!this.parent) {
      return EstablishmentType.ESTABLISHMENT;
    }
    switch (this.parent.type) {
      case EstablishmentType.ESTABLISHMENT:
        return EstablishmentType.INSTITUTE;
      case EstablishmentType.INSTITUTE:
        return EstablishmentType.DIVISION;
      case EstablishmentType.DIVISION:
        return EstablishmentType.UNIT;
      default:
        return null;
    }
  }

  @Watch("errors") onErrorsChange() {
    this.$nextTick(() => {
      this.$emit("componentUpdate");
    });
  }

  resetModalData(): void {
    this.errors = [];
    this.formData = formDataInitialState;
  }

  close() {
    this.resetModalData();
    this.$emit("close");
  }

  async submit(): Promise<void> {
    this.errors = [];
    this.loading = true;
    const data: SubmitData = {
      branchId: generateUuid(),
      parentId: this.parent ? this.parent.branchId : null,
      name: this.formData ? this.formData.name : null,
      code: this.formData ? this.formData.code : null,
      type: this.type,
      address: this.formData && !isEmpty(this.formData.address) ? this.formData.address : null,
      nip: this.formData ? this.formData.nip : null,
      regon: this.formData ? this.formData.regon : null,
      phoneNumber: this.formData ? this.formData.phoneNumber : null,
      codeEight: this.formData ? this.formData.codeEight : null,
    };
    try {
      await create("/api/branches", data);
      this.close();
      this.$emit("added");
    } catch(e) {
      this.errors = processResponseException(e);
    }
    this.loading = false;
  }
}
