























import Vue from "vue";
import Component from "vue-class-component";
import {Prop, Ref} from "vue-property-decorator";
import ResizableTextarea from "../Form/Textarea/ResizableTextarea.vue";
import {Error} from "../../rest";
import {BFormTextarea} from "bootstrap-vue";

@Component({
  name: "ActiveSubstanceDescription",
  components: {ResizableTextarea}
})
export default class ActiveSubstanceDescription extends Vue {
  @Prop({type: String, default: null}) readonly value!: string;
  @Prop({type: Boolean, default: false}) readonly disabled!: boolean;
  @Prop({type: Array, default: ()=>[]}) readonly errors!: Array<Error>

  @Ref("textarea") readonly textarea!: BFormTextarea;

  private edited: boolean = false;

  private get isInvalid(): boolean {
    return !!this.errors.find((error) => error.field === "description");
  }

  private update(description: string): void {
    this.$emit("input", description.trim());
  }

  private startEdit(): void {
    if (!this.disabled) {
      this.edited = true;
      this.$nextTick(() => this.textarea.focus());
    }
  }

  private stopEdit(): void {
    this.edited = false;
  }
}
