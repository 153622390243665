<template>
  <div class="text-center">
    <svg
      class="animation"
      version="1.1"
      width="380px"
      height="500px"
      viewBox="0 0 837 1045"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g 
        id="Page-1" 
        stroke="none" 
        stroke-width="1" 
        fill="none" 
        fill-rule="evenodd"
      >
        <path
          d="M353,9 L626.664028,170 L626.664028,487 L353,642 L79.3359724,487 L79.3359724,170 L353,9 Z"
          stroke="#3bafda"
          stroke-width="6"
        />
        <path
          d="M78.5,529 L147,569.186414 L147,648.311216 L78.5,687 L10,648.311216 L10,569.186414 L78.5,529 Z"
          stroke="#00705E"
          stroke-width="6"
          style="animation-delay: .2s"
        />
        <path
          d="M773,186 L827,217.538705 L827,279.636651 L773,310 L719,279.636651 L719,217.538705 L773,186 Z"
          stroke="#f76397"
          stroke-width="6"
          style="animation-delay: .4s"
        />
        <path
          d="M639,529 L773,607.846761 L773,763.091627 L639,839 L505,763.091627 L505,607.846761 L639,529 Z"
          stroke="#00b19d"
          stroke-width="6"
          style="animation-delay: .6s"
        />
        <path
          d="M281,801 L383,861.025276 L383,979.21169 L281,1037 L179,979.21169 L179,861.025276 L281,801 Z"
          stroke="#ffaa00"
          stroke-width="6"
          style="animation-delay: .8s"
        />
      </g>
    </svg>
    <main>
      <h1>404</h1>
      <h4>Nie znaleziono</h4>
      <p>Strona której szukasz nie istnieje lub została usunięta.</p>
      <div>
        <button
          class="m-1"
          @click="$router.go(-1)"
        >
          &#x23CE; Powrót
        </button>
        <router-link
          :to="{name: 'home'}"
          class="m-1"
        >
          Strona główna
        </router-link>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  name: "PageNotFound",
}
</script>

<style lang="scss" scoped>
  svg, main {
    display: inline-block;
    vertical-align: middle;
    text-align: left;
  }

  main a, main button {
    margin: 20px 0 0 0;
    border-radius: 4px;
    padding: 6px 12px;
    display: inline-block;
    background: #3bafda;
    border: 1px solid #3bafda;
    border-bottom: 2px solid #2494be;
    color: white;
    font: inherit;
    font-size: 1rem;
    cursor: pointer;
    text-decoration: none;
    vertical-align: baseline;
    line-height: 1.5rem;
  }

  main a:hover, main button:hover {
    border-color: #28a5d4;
    background: #28a5d4;
  }

  svg.animation path {
    animation: float 1s infinite ease-in-out alternate;
    float: left;
  }

  h1 {
    color: #252932;
    margin: 0;
    font-size: 98px;
    font-weight: 700;
    line-height: 98px;
    text-shadow: rgba(61, 61, 61, 0.3) 1px 1px, rgba(61, 61, 61, 0.2) 2px 2px, rgba(61, 61, 61, 0.3) 3px 3px;
  }

  h4 {
    font-size: 18px;
    margin: 0;
  }

  @keyframes float {
    100% {
      transform: translateY(20px);
    }
  }
</style>
