



























































import read from "../../rest/read";
import Card from "../Card.vue";
import PaginationDescription from "../Pagination/PaginationDescription.vue";
import DateTime from "../DateTime.vue";
import parseDate from "../../utils/date/parseDate";
import processResponseException from "../../utils/errors/processResponseException";
import ErrorMessage from "../Form/ErrorMessage.vue";
import {Component} from "vue-property-decorator";
import ErrorsMixin from "../../mixins/ErrorsMixin.ts";
import {mixins} from "vue-class-component";
import type {FetchedData, FetchedNotification, WebNotification} from "../../types/WebNotifications";
import type {Pagination} from "../../types/Pagination";

@Component({
  name: "WebNotificationsWidget",
  components: {ErrorMessage, DateTime, PaginationDescription, Card},
})

export default class WebNotificationsWidget extends mixins(ErrorsMixin) {

  notifications: Array<WebNotification> = [];
  loading: boolean = false;
  pagination: Pagination = {
    currentPage: 1,
    perPage: 5,
    totalRows: 0,
  };
  page: Pagination["currentPage"] = 1;

  async mounted(): Promise<void> {
    await this.fetchData();
  }

  async fetchData() {
    this.loading = true;
    this.errors = [];
    try {
      const {items, pagination} = await read<FetchedData>("/api/web-notifications", {
        perPage: this.pagination.perPage,
        page: this.page,
      });
      this.notifications = items.map((item: FetchedNotification) => ({
        ...item,
        date: parseDate(item.date),
      }));
      this.pagination = pagination;
    } catch (e) {
      this.errors = processResponseException(e);
    }
    this.loading = false;
  }

  changePage(page: Pagination["currentPage"]) {
    this.page = page;
    this.fetchData();
  }
}
