<template>
  <div>
    <b-form-checkbox
      :checked="value.hasContract"
      class="mb-3"
      @input="updateValue({hasContract: $event})"
    >
      Pracownik posiada podpisaną umowę z NFZ
    </b-form-checkbox>
    <error-message
      :errors="errors"
      field="reimbursementContract"
      root
    />
    <transition>
      <div v-if="value.hasContract">
        <b-form-group label="Oddział NFZ">
          <nfz-branch-select
            :value="value.branchId ? {branchId: value.branchId} : null"
            :state="state('reimbursementContract.branchId')"
            :disabled="disabled"
            @input="updateValue({branchId: $event ? $event.branchId : null})"
          />
          <error-message
            :errors="getSubErrors('reimbursementContract')"
            field="branchId"
          />
        </b-form-group>
        <b-form-group label="Typ kontraktu">
          <contract-type-select
            :value="value.typeSuffix"
            :state="state('reimbursementContract.typeSuffix')"
            :disabled="disabled"
            @input="updateValue({typeSuffix: $event})"
          />
          <error-message
            :errors="getSubErrors('reimbursementContract')"
            field="typeSuffix"
          />
        </b-form-group>
        <b-form-group label="Numer kontraktu">
          <b-form-input
            :value="value.contractNumber"
            :state="state('reimbursementContract.contractNumber')"
            :disabled="disabled"
            @input="updateValue({contractNumber: $event ? $event.trim() : null})"
          />
          <error-message
            :errors="getSubErrors('reimbursementContract')"
            field="contractNumber"
          />
        </b-form-group>
        <b-form-group>
          <template #label>
            Certyfikat p12 <i
              :class="value.pkcs12FileId ? 'fa-check text-success' : 'fa-times text-danger'"
              class="fa"
            />
            <button
              v-if="value.pkcs12FileId"
              class="btn btn-primary btn-sm float-right"
              @click="removeFile"
            >
              <i class="fa fa-trash" /> Usuń certyfikat
            </button>
          </template>
          <file-upload
            :uploaded-files="uploadedFiles"
            @removed="removeFile"
            @uploaded="addFile"
          />
        </b-form-group>
      </div>
    </transition>
    <b-modal
      id="certificateOverwriteModal"
      ref="certificateOverwriteModal"
      no-close-on-backdrop
      title="Potwierdzenie napdisania certyfikatu"
      title-tag="h3"
    >
      <div>
        Posiadasz już wgrany certyfikat, czy chcesz go nadpisać?
      </div>
      <template #modal-footer>
        <button
          class="btn btn-secondary"
          @click="hideModal"
        >
          Anuluj
        </button>
        <button
          class="btn btn-primary"
          @click="confirmCertificateOverwrite"
        >
          Nadpisz certyfikat
        </button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import NfzBranchSelect from "../Form/Select/NfzBranchSelect";
import ContractTypeSelect from "../Form/Select/ContractTypeSelect";
import ErrorMessage from "../Form/ErrorMessage";
import subErrors from "../../utils/errors/subErrors";
import FileUpload from "../File/FileUpload";

export default {
  name: "ReimbursementContractForm",
  components: {ContractTypeSelect, NfzBranchSelect, ErrorMessage, FileUpload},
  props: {
    value: {type: Object, required: true},
    disabled: {type: Boolean, default: false},
    errors: {type: Array, default: () => []},
  },
  data() {
    return {
      file: null,
      uploadedFiles: [],
    };
  },
  methods: {
    addFile(file) {
      if(this.value.pkcs12FileId){
        this.$bvModal.show("certificateOverwriteModal");
        this.file = file;
        return;
      }
      this.updateValue({pkcs12FileId: file.fileId});
      this.uploadedFiles = [];
    },
    removeFile() {
      this.updateValue({pkcs12FileId: null});
    },
    getSubErrors(field) {
      return subErrors(this.errors, field);
    },
    state(field) {
      return this.errors.find((error) => error.field === field) ? false : null;
    },
    updateValue(diff) {
      const newVal = {...this.value, ...diff};
      this.$emit("input", newVal);
    },
    hideModal() {
      this.$bvModal.hide("certificateOverwriteModal");
      this.file = null;
      this.uploadedFiles = [];
    },
    confirmCertificateOverwrite(){
      this.updateValue({pkcs12FileId: this.file.fileId});
      this.hideModal();
    },
  }
}
</script>
