
































import {Component, Prop} from "vue-property-decorator";
import Vue from "vue";

@Component({
  name: "PatientPresenceRadio",
})
export default class PatientPresenceRadio extends Vue {
  @Prop({type: Boolean, default: null}) readonly patientPresence!: boolean;

  change(option: boolean): void {
    this.$emit("input", option);
  }
}
