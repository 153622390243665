













































import ErrorsMixin from "../../../../mixins/ErrorsMixin.ts";
import {Component, Emit, Prop} from "vue-property-decorator";
import ErrorMessage from "../../../Form/ErrorMessage.vue";
import processResponseException from "../../../../utils/errors/processResponseException";
import create from "../../../../rest/create";
import debounce from "lodash/debounce";
import LoadingMask from "../../../Loading/LoadingMask.vue";
import {generateUuid} from "../../../../utils/uuid/generateUuid";
import {mixins} from "vue-class-component";

enum RatingSurveyCategory {
  TREATMENTS = "treatments",
  RELATIONS = "relations",
}

const deferred = debounce(f => f(), 2000, {maxWait: 3000});

@Component({
  name: "CreateRatingSurveyModal",
  components: {ErrorMessage, LoadingMask},
})
export default class CreateRatingSurveyModal extends mixins(ErrorsMixin) {
  @Prop({type: String, required: true}) readonly patientId!: string;
  @Prop({type: String, required: true}) readonly category!: RatingSurveyCategory;

  loading: boolean = false;
  disabled: boolean = false;
  showConfirmation: boolean = false;

  async createSurvey() {
    this.loading = true;
    this.disabled = true;
    try {
      this.errors = [];
      const surveyId = generateUuid();

      await create(`/api/patients/${this.patientId}/rating-surveys/${surveyId}`, {
        type: this.category
      });

      this.surveyCreated(surveyId);
      this.showConfirmation = true;
      deferred(() => {
        this.closeModal();
      });
    } catch (exception) {
      this.errors = processResponseException(exception);
    }
    this.loading = false;
  }

  get modalName() {
    return "create-rating-survey-modal";
  }

  @Emit()
  surveyCreated(surveyId: string): string {
    return surveyId;
  }

  onHide() {
    this.errors = [];
    this.disabled = false;
    this.showConfirmation = false;
  }

  closeModal() {
    this.$bvModal.hide(this.modalName);
  }
}
