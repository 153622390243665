<template>
  <report-container report-id="reports-schedule-specialist">
    <schedule-specialist-filters
      class="card-box"
      :month="month"
      @submit="submit"
    />
  </report-container>
</template>

<script>
import ScheduleSpecialistFilters from "../../components/Report/ScheduleSpecialistFilters";
import {createUrl} from "../../utils/pageUrl/createUrl";
import DATE_FORMAT from "../../utils/date/DATE_FORMAT";
import stringifyDate from "../../utils/date/stringifyDate";
import ReportContainer from "../../components/Report/ReportContainer";

export default {
  name: "Schedulespecialist",
  components: {ScheduleSpecialistFilters, ReportContainer},
  data() {
    return {
      month: new Date(),
    }
  },
  methods: {
    submit(data) {
      const startDate = new Date(data.month);
      startDate.setDate(1);
      const endDate = new Date(data.month);
      endDate.setMonth(data.month.getMonth()+1);
      endDate.setDate(0);
      window.location = createUrl("/api/reports/schedule-specialist", {
        workerId: data.worker?data.worker.workerId:null,
        startDate: stringifyDate(startDate, DATE_FORMAT.DATE),
        endDate: stringifyDate(endDate, DATE_FORMAT.DATE),
      });
    },
  },
}
</script>
