<template>
  <b-table
    :items="items"
    :fields="fields"
    empty-text="Brak pacjentów spełniających kryteria"
    :responsive="true"
    show-empty
  >
    <template #cell(patient)="data">
      <router-link :to="{name: 'patientCard', params: {patientId: data.item.patientId}}">
        {{ data.item.displayName }}
      </router-link>
    </template>
    <template #cell(dataUnificationStatus)="{item: {unificationStatus: status}}">
      <b-badge
        class="status-badge"
        :variant="getBadgeVariant(status)"
      >
        <i18n
          component="fragment"
          :msgid="`@dataUnificationStatus.${status}`"
        />
      </b-badge>
    </template>
    <template #cell(invalidTreatmentTypes)="data">
      <i
        :class="data.item.invalidTreatmentTypes ? 'fa-times text-danger' : 'fa-check text-success'"
        class="fa"
      />
    </template>
    <template #cell(incompleteData)="data">
      <i
        :class="data.item.incompleteData ? 'fa-times text-danger' : 'fa-check text-success'"
        class="fa"
      />
    </template>
    <template #cell(unifyPatient)="data">
      <router-link
        :to="{name: 'patientReportCard', params: {patientId: data.item.patientId}}"
        class="btn btn-sm btn-secondary"
      >
        Unifikacja
      </router-link>
    </template>
  </b-table>
</template>

<script>
import I18n from "../../i18n";
export default {
  name: "PatientDataUnificationList",
  components: {I18n},
  props: {
    items: {type: Array, required: true},
    columns: {type: Array, required: true},
  },
  data() {
    return {
      fieldsDefinitions: [
        {key: "patient", label: "Pacjent"},
        {key: "dataUnificationStatus", label: "Status unifikacji", class: "text-center"},
        {key: "invalidTreatmentTypes", label: "Poprawne usługi", class: "text-center"},
        {key: "incompleteData", label: "Pełne dane ewaluacyjne", class: "text-center"},
        {key: "unifyPatient", label: "Unifikacja", class: "text-center"},
      ],
    };
  },
  computed: {
    fields() {
      return this.columns
        .map((column) => this.fieldsDefinitions.find((definition) => definition.key === column))
        .filter((definition) => null != definition);
    },
  },
  methods: {
    getBadgeVariant(status) {
      switch (status) {
        case "ex_patient":
        case "completed":
          return "success";
        case "pending":
          return "secondary";
        case "waiting_for_patient":
          return "primary";
        default:
          throw Error(`Not supported status: ${status}`);
      }
    },
  },
}
</script>

<style scoped lang="scss">
  .status-badge{
    width: 14rem;
  }
</style>
