<template>
  <object-select
    :value="selectedValue"
    :options="options"
    :state="state"
    placeholder="Wybierz powód anulowania"
    @input="update"
  />
</template>

<script>
import t from "../../../../i18n";
import ObjectSelect from "../../../Form/Select/ObjectSelect";

export default {
  components: {ObjectSelect},
  props: {
    cancelCategory: {type: String, default: null},
    state: ObjectSelect.props.state, //eslint-disable-line
  },
  data(){
    return {
      reasonsCategories: [
        {value: "change_specialist_decision"},
        {value: "non_compliance_with_conditions"},
        {value: "failure_to_appear"},
        {value: "client_resignation"},
        {value: "other"},
      ]
    }
  },
  computed : {
    options() {
      return this.reasonsCategories.map(option => ({
        ...option,
        name: t(`@cancel-reason-categories.${option.value}`),
      }));
    },
    selectedValue() {
      return this.cancelCategory ? this.options.find((option) => option.value === this.cancelCategory) : null;
    },
  },
  methods:{
    update(value){
      this.$emit("input", value ? value.value : null);
    }
  }
}
</script>

<style scoped>

</style>