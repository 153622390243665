<template>
  <object-select
    :value="selectedValue"
    :options="options"
    :disabled="disabled"
    :state="state"
    placeholder="Wybierz typ kontraktu"
    @input="update"
  />
</template>

<script>
import ObjectSelect from "./ObjectSelect";

export default {
  components: {
    ObjectSelect,
  },
  props: {
    value: {type: String, default: null},
    state: ObjectSelect.props.state, // eslint-disable-line
    disabled: {type: Boolean, default: false},
  },
  computed: {
    options() {
      return [
        {
          "value": "1",
          "name": "Usługodawca"
        },
        {
          "value": "2",
          "name": "Lekarz"
        },
      ];
    },
    selectedValue() {
      return this.options.find((option) => option.value === this.value) || null;
    },
  },
  methods: {
    update(option) {
      this.$emit("input", option ? option.value : null);
    },
  },
};
</script>
