<template>
  <div>
    <div
      v-if="reason"
      class="text-danger border border-danger rounded px-3 py-2 mb-3"
    >
      Powód anulowania: {{ reason }}
    </div>
    <is-granted
      :privileges="['PROCEDURE_JOBS_LIST']"
      :subject="procedureId"
      component="fragment"
    >
      <procedure-jobs-list
        :procedure-id="procedureId"
      />
    </is-granted>
  </div>
</template>

<script>
import IsGranted from "../../../IsGranted";
import ProcedureJobsList from "./ProcedureJobsList";

export default {
  name: "ProcedureDetails",
  components: {ProcedureJobsList, IsGranted},
  props: {
    procedureId: {type: String, required: true},
    reason: {type: String, default: null},
  },
}
</script>
