



























import WeeklyScheduleBranch from "./WeeklyScheduleBranch.vue";
import AppointmentButton from "../Appointment/AppointmentButton.vue";
import {Component, Prop} from "vue-property-decorator";
import Vue from "vue";
import type {Range, WeeklyScheduleItem} from "../../types/WeeklySchedule";

@Component({
  name: "WeeklyScheduleWorkerDay",
  components: {AppointmentButton, WeeklyScheduleBranch},
})

export default class WeeklyScheduleWorkerDay extends Vue {
  @Prop({type: Date, required: true}) readonly date!: Date;
  @Prop({type: Array, default: () => []}) readonly items!: Array<WeeklyScheduleItem>;
  @Prop({type: Array, default: () => []}) readonly canceledItems!: Array<WeeklyScheduleItem>;

  get isToday(): boolean {
    const today: Date = new Date();
    today.setHours(0, 0, 0, 0);
    return today.getTime() === this.date.getTime();
  }

  get sortedItems(): Array<WeeklyScheduleItem> {
    return [...this.items].sort((itemA, itemB) => itemA.start.getTime() < itemB.start.getTime() ? -1 : 1);
  }

  get ranges(): Array<any> {
    return this.sortedItems.reduce((
      ranges: Array<Range>,
      item: WeeklyScheduleItem,
      idx: number,
      src: Array<WeeklyScheduleItem>) => {

      const prevItem: WeeklyScheduleItem | null = idx ? src[idx - 1] : null;
      if (prevItem && prevItem.branchId === item.branchId && prevItem.end.getTime() >= item.start.getTime()) {
        return ranges
          .map(r => {
            return r.items
              .find(i => i.start.getTime() === prevItem.start.getTime() && i.end.getTime() === prevItem.end.getTime())
              ? {
                ...r,
                workingHours: {
                  ...r.workingHours,
                  end: item.end.getTime() > prevItem.end.getTime()
                    ? item.end
                    : prevItem.end,
                },
                items: r.items.concat([item]),
              }
              : r;
          });
      }

      return ranges.concat([{
        workingHours: {
          start: item.start,
          end: item.end,
        },
        data: {
          branchId: item.branchId,
          branchName: item.branchName,
        },
        items: [item],
      }])
    }, []);
  }

  get branches(): Array<Range> {
    return [...this.ranges]
      .sort((rangeA, rangeB) => {
        if (rangeA.workingHours.start.getTime() < rangeB.workingHours.start.getTime()) {
          return -1;
        }
        if (rangeA.workingHours.start.getTime() > rangeB.workingHours.start.getTime()) {
          return 1;
        }
        return 0;
      });
  }

  get otherCanceledAppointments(): Array<WeeklyScheduleItem> {
    return this.canceledItems
      .filter(item => !this.branches
        .find(branch => branch.data.branchId === item.branchId
          && item.start.getTime() >= branch.workingHours.start.getTime()
          && item.start.getTime() <= branch.workingHours.end.getTime()
        ));
  }


  canceledItemsByBranch(branch: Range) {
    return this.canceledItems.filter(item => item.branchId === branch.data.branchId
      && item.start.getTime() >= branch.workingHours.start.getTime()
      && item.start.getTime() <= branch.workingHours.end.getTime()
    );
  }
}
