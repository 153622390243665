<template>
  <!-- eslint-disable -->
  <span v-html="html" />
</template>
<script>
export default {
  props: {
    text: {type: String, required: true}
  },
  computed: {
    html() {
      return this.text.replace(/\n/g, "<br>");
    }
  }
}
</script>
