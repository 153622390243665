













import Vue from "vue";
import I18n from "../i18n.vue";
import Component from "vue-class-component";
import {Prop} from "vue-property-decorator";
import read from "../../rest/read";

interface Task {
  taskId: string,
  name: string,
}
interface Target {
  targetId: string,
  name: string,
  tasks: Array<Task>,
}

@Component({
  name: "TargetsAndTasksBadge",
  components: {
    I18n,
  },
})
export default class TargetsAndTasksBadge extends Vue {
  @Prop({type: String, required: true}) readonly appointmentDocumentId!: string;

  targets: Array<Target> = [];

  mounted(): void {
    this.loadData();
  }

  get printTargets(): string {
    return this.targets
      .map((target: Target): string => `${target.name}${
        target.tasks.length > 0 ? "-" : ""
      }${
        target.tasks.map((task: Task): string => task.name).join(", ")
      }`).join(", ");
  }

  async loadData(): Promise<void> {
    const {items} = await read(`/api/appointment/${this.appointmentDocumentId}/document/targets`);
    this.targets = items;
  }
}
