























































































































import LoadingIcon from "../Loading/LoadingIcon.vue";
import DateTime from "../DateTime.vue";
import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";
import type {WebNotification} from "../../types/WebNotifications";

@Component({
  name: "WebNotificationsModal",
  components: {DateTime, LoadingIcon},
})

export default class WebNotificationsModal extends Vue {
  @Prop({type: Number, required: true}) readonly unreadCounter!: number;
  @Prop({type: Boolean, required: true}) readonly hasMoreNotifications!: boolean;
  @Prop({type: Array, required: true}) readonly notifications!: Array<WebNotification>;
  @Prop({type: Boolean, required: true}) readonly loading!: boolean;
  @Prop({type: Boolean, required: true}) readonly reading!: boolean;

  showModal(): void {
    this.$emit("open");
    this.$bvModal.show("notifications-modal");
  }

  hideModal(): void {
    this.$bvModal.hide("notifications-modal");
    this.$emit("hide");
  }

  loadNotifications(): void {
    this.$emit("loadNotifications");
  }

  readAll(): void {
    this.$emit("readAll");
  }
}
