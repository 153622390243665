

























import ErrorMessage from "../Form/ErrorMessage.vue";
import ErrorsMixin from "../../mixins/ErrorsMixin";
import {Component, Mixins, Prop} from "vue-property-decorator";
import ResizableTextarea from "../Form/Textarea/ResizableTextarea.vue";
import {RequestCommand} from "../../types/Request";
import AppointmentInfoRequestPreview from "../Appointment/AppointmentInfoRequestPreview.vue";

@Component({
  name: "PreviewRequestModal",
  components: {
    ResizableTextarea,
    ErrorMessage,
    AppointmentInfoRequestPreview,
  },
})
export default class AcceptRequestModal extends Mixins(ErrorsMixin) {
  @Prop({type: String, required: true}) readonly changeRequestId!: string;
  @Prop({type: String, required: true}) readonly command!: RequestCommand;
  @Prop({type: String, required: true}) readonly subjectId!: string;

  private get modalId(): string {
    return `preview-request-${this.changeRequestId}`;
  }

  private get title(): string {
    switch(this.command) {
      case RequestCommand.CANCEL_APPOINTMENT_COMMAND:
        return "Podgląd wniosku o anulowanie wizyty";
      default:
        throw Error(`Not supported command: '${this.command}'`);
    }
  }

  private get commandComponent(): string {
    switch(this.command) {
      case RequestCommand.CANCEL_APPOINTMENT_COMMAND:
        return "appointment-info-request-preview";
      default:
        throw Error(`Not supported command: '${this.command}'`);
    }
  }

  private hideModal(): void {
    this.$bvModal.hide(this.modalId);
  }
}
