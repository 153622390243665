











































































import {Component, Mixins, Prop} from "vue-property-decorator";
import {generateUuid} from "../../../utils/uuid/generateUuid";
import create from "../../../rest/create";
import stringifyDate from "../../../utils/date/stringifyDate";
import I18n from "../../i18n.vue";
import t from "../../../i18n";
import processResponseException from "../../../utils/errors/processResponseException";
import {mapState} from "vuex";
import bFormTextarea from "bootstrap-vue";
import bFormInput from "bootstrap-vue";
import bFormRadioGroup from "bootstrap-vue";
import {BasicEntryItem, default as getEntry} from "./EntryField";
import ErrorMessage from "../../Form/ErrorMessage.vue";
import ErrorsMixin from "../../../mixins/ErrorsMixin";

function symDiff(arr1: Array<string>, arr2: Array<string>) {
  return arr1
    .filter(x => !arr2.includes(x))
    .concat(arr2.filter(x => !arr1.includes(x)));
}

@Component({
  components: {
    ErrorMessage,
    I18n,
  },
  computed: mapState({
    workerId: (state: any): string => state.currentUser.user.workerId,
    workerName: (state: any): string => state.currentUser.user.name,
    surname: (state: any): string => state.currentUser.user.surname,
  }),
})
export default class CheckboxField extends Mixins(ErrorsMixin) {
  @Prop({type: String, required: true}) readonly diagnosisId!: string;
  @Prop({type: Object, required: true}) readonly field!: CheckboxFieldData;
  @Prop({type: Boolean, required: true}) readonly readOnly!: boolean;

  entries: Array<EntryItem> = this.field.entries || [];
  saveLoading: boolean = false;
  checkedItems: Array<string> = [];
  additional: object = {};

  workerId?: string;
  name?: string;
  surname?: string;

  mounted() {
    this.saveLoading = true;
    if (this.entry) {
      this.field.entryType.options.forEach(
        ({value}) => {
          const val = this.entry?.content.value.find(v => value === v.value);
          return this.additional[value] = val && value === val.value
            ? val.additional : null;
        }
      );
    }
    this.checkedItems = this.entry ? this.entry.content.value.map(it => it.value) : [];
    this.saveLoading = false;
  }

  get entry(): EntryItem | null {
    return getEntry(this.entries) as EntryItem;
  }

  isSavingNotAllowed(): boolean {
    if (this.readOnly) {
      return true;
    }

    if (this.checkedItems.length < 1
      && (this.entry === null || this.entry.content.value.length < 1)) {
      return true;
    }

    return this.entry !== null
        && symDiff(this.checkedItems, this.entry.content.value.map(it => it.value)).length < 1
        && this.entry.content.value
          .filter(it => it.additional !== undefined)
          .map(it => it.additional === this.additional[it.value])
          .reduce((accumulator, currentValue) => accumulator && currentValue, true);
  }

  async saveEntry(): Promise<void> {
    if (this.isSavingNotAllowed()) {
      return;
    }
    this.saveLoading = true;
    try {
      const newEntry = {
        content: {
          type: "checkbox",
          value: this.checkedItems.map(item => {return {
            value: item,
            additional: this.additional[item],
          };}),
        },
        entryId: generateUuid(),
        type: this.field.entryType.key,
        createdAt: stringifyDate(new Date()),
        workerId: this.workerId,
      };
      await create(`/api/diagnosis/${this.diagnosisId}/entry`, newEntry);
      const entry = {
        ...newEntry,
        creator: {
          name: this.name,
          surname: this.surname,
        },
      } as EntryItem;
      this.entries = this.entries.concat([entry]);

      this.errors = [];
      this.$emit("new-entry-added");
    } catch (exception) {
      this.errors = processResponseException(exception);
    }
    this.saveLoading = false;
  }

  resolveAdditionalFieldType(additional: Additional): any {
    const type = additional.type;
    switch (type) {
      case "text":
        return "bFormTextarea";
      case "string":
        return "bFormInput";
      case "radio":
        return "bFormRadioGroup";
      default:
        throw Error(`Not supported field type: '${type}'`);
    }
  }

  resolveAdditionalFieldTypeOptions(additional: Additional): object {
    switch (additional.type) {
      case "text":
        return {};
      case "string":
        return {};
      case "radio":
        return {
          options: (additional.options || []).map((o: OptionItem) => ({
            value: o.value,
            text: t(o.label),
          })),
        };
    }
  }
}

interface CheckboxFieldData {
  entries: Array<EntryItem>;
  entryType: EntryType;
}
interface EntryType {
  name: string;
  key: string;
  options: Array<OptionItem>;
}

interface EntryItem extends BasicEntryItem {
  content: ContentItem;
}
interface ContentItem {
  type: string;
  value: Array<ValueItem>;
}
interface ValueItem {
  value: string;
  additional?: null | string;
}

type Additional  = AdditionalText | AdditionalRadio;

interface AdditionalText {
  label?: string;
  type: "text" | "string";
}

interface AdditionalRadio {
  label?: string;
  type: "radio";
  options: Array<{value: string; label: string}>;
}

interface OptionItem {
  value: string;
  label: string;
}
interface Error {
  message: string;
  field?: string;
}
