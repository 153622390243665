<template>
  <div class="row">
    <director-reports-filter
      class="col-12"
      :worker-id="workerId"
    />
    <div class="col-xs-12 col-sm-12 col-md-6 col-xl-3">
      <occupancy-widget
        type="day"
        title="Obłożenie dzienne"
        icon="calendar"
        class="h-100"
        :worker-id="workerId"
        tooltip-text="Średnie obłożenie dziennie specjalisty w
        ubiegłym miesiącu – liczba wszystkich zamkniętych wizyt podzielona przez 21."
      />
    </div>
    <div class="col-xs-12 col-sm-12 col-md-6 col-xl-3">
      <occupancy-widget
        type="month"
        title="Obłożenie miesięczne"
        icon="calendar-alt"
        class="h-100"
        :worker-id="workerId"
        tooltip-text="Liczba wszystkich zamkniętych wizyt jakie miał wybrany specjalista w ubiegłym miesiącu."
      />
    </div>
    <div class="col-xs-12 col-sm-12 col-md-6 col-xl-3">
      <count-active-patients-led-by-case-manager-widget
        class="h-100"
        title="Pacjentów prowadzonych jako Case Manager"
        icon="users"
        :worker-id="workerId"
        tooltip-text="Liczba pacjentów, których Case Manager’em jest wybrany specjalista."
      />
    </div>
    <div class="col-xs-12 col-sm-12 col-md-6 col-xl-3">
      <count-active-patients-with-appointments-in-future-widget
        class="h-100"
        title="Pacjentów z umówioną wizytą w przyszłości"
        icon="users"
        :worker-id="workerId"
        tooltip-text="Liczba pacjentów,
        którzy mają umówioną co najmniej jedną wizytę w przyszłości do wybranego specjalisty."
      />
    </div>
  </div>
</template>

<script>
import OccupancyWidget from "../../components/DirectorReportsDashboard/OccupancyWidget";
import CountActivePatientsLedByCaseManagerWidget
  from "../../components/DirectorReportsDashboard/CountActivePatientsLedByCaseManagerWidget";
import CountActivePatientsWithAppointmentsInFutureWidget
  from "../../components/DirectorReportsDashboard/CountActivePatientsWithAppointmentsInFutureWidget";
import DirectorReportsFilter from "../../components/DirectorReportsDashboard/DirectorReportsFilter";

export default {
  name: "DirectorReports",
  components: {
    DirectorReportsFilter,
    OccupancyWidget,
    CountActivePatientsLedByCaseManagerWidget,
    CountActivePatientsWithAppointmentsInFutureWidget,
  },
  props: {
    workerId: {type: String, default: null}
  },
};
</script>
