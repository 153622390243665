















































































import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";
import ErrorMessage from "../Form/ErrorMessage.vue";
import ResizableTextarea from "../Form/Textarea/ResizableTextarea.vue";
import type {EstablishmentParameters} from "../../types/Establishment";
import {Error} from "../../rest";
import AgeGroupTargetSelect from "../Form/Select/AgeGroupTargetSelect.vue";

@Component({
  name: "EstablishmentParametersForm",
  components: {AgeGroupTargetSelect, ErrorMessage, ResizableTextarea}
})
export default class EstablishmentParametersForm extends Vue {
  @Prop({type: Object, required: true}) readonly value!: EstablishmentParameters;
  @Prop({type: Array, default: ()=>[]}) readonly propErrors!: Error[];

  private state(field: string): boolean|null {
    return this.propErrors.find((error) => error.field === field) ? false : null;
  }

  private input(parameters: EstablishmentParameters): void {
    this.$emit("input", parameters);
  }
}
