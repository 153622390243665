<template>
  <div class="row">
    <div class="col-10">
      <span v-if="errors && errors.length">
        <span
          v-for="(error, index) in errors"
          :key="index"
          class="text-danger"
        >
          {{ error.message }}
        </span>
      </span>
      <div>
        <textarea-counter
          v-model="content"
          :disabled="createLoading"
          :max-characters-count="2500"
          :rows="2"
          :placeholder="placeholder"
          required
          @input="update"
        />
      </div>
    </div>
    <div class="col-2">
      <button
        :disabled="emptyEntry || createLoading"
        class="btn-success btn"
        @click="addEntry"
      >
        <i
          :class="createLoading ? 'fa-spin fa-spinner' : 'fa-plus'"
          class="fa"
        />
        Zapisz
      </button>
    </div>
  </div>
</template>

<script>
import {generateUuid} from "@/utils/uuid/generateUuid";
import create from "../../../rest/create";
import read from "../../../rest/read";
import TextareaCounter from "../../Form/Textarea/TextareaCounter";
import processResponseException from "../../../utils/errors/processResponseException";

export default {
  components: {TextareaCounter},
  props: {
    createEntryUrl: {type: String, required: true},
    typeTrans: {type: String, required: true},
    type: {type: String, required: true},
  },
  data() {
    return {
      content: null,
      createLoading: false,
      errors: {},
      emptyEntry: true,
    };
  },
  computed: {
    placeholder() {
      return `Treść nowego opisu do pola ${this.typeTrans}`;
    },
  },
  methods: {
    async addEntry() {
      this.createLoading = true;
      try {
        const newEntry = {
          content: {type: "text", text: this.content},
          entryId: generateUuid(),
          type: this.type,
        };
        const location = await create(this.createEntryUrl, newEntry);
        const entry = await read(location);
        this.$emit("new-diagnosis-entry-added", entry);

        this.content = null;
        this.errors = {};
      } catch (exception){
        this.errors = processResponseException(exception);
      }
      this.createLoading = false;
    },
    update(text) {
      if (!text) {
        this.emptyEntry = true;
      } else {
        this.content = text;
        this.emptyEntry = false;
      }
    },
  },
};
</script>
