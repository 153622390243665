<template>
  <card
    :loading="loadingItems"
    title="Indywidualne Plany Zdrowienia"
  >
    <template #control>
      <is-granted
        :privileges="['CREATE_IPZ']"
        :subject="patientId"
        component="fragment"
      >
        <button
          v-show="!hasActiveIpz"
          :disabled="createLoading"
          class="btn btn-primary btn-sm float-right m-b-10"
          @click="openConfirmCreateModal"
        >
          <i
            v-show="createLoading"
            class="fa fa-spin fa-spinner"
          />
          <i
            v-show="!createLoading"
            class="fa fa-plus"
          />
          Stwórz nowy IPZ
        </button>
      </is-granted>
    </template>
    <error-message :errors="errors" />
    <table class="table">
      <thead>
        <tr>
          <th scope="col">
            Data stworzenia - zakończenia
          </th>
          <th scope="col">
            Status
          </th>
          <th scope="col" />
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(ipz, index) in ipzList"
          :key="index"
        >
          <td>{{ ipz.createdAt }} - {{ ipz.closedAt }}</td>
          <td>
            <b-badge
              class="status-badge"
              :variant="getBadgeVariant(ipz.status)"
            >
              <i18n
                component="fragment"
                :msgid="`@ipz-status.${ipz.status}`"
              />
            </b-badge>
            <div v-if="!!ipz.reason">
              ({{ ipz.reason }})
            </div>
          </td>
          <td class="text-right">
            <is-granted
              v-if="isUnificationEnabled && ipz.status === 'closed'"
              :privileges="['REOPEN_IPZ']"
              component="fragment"
            >
              <b-btn
                class="m-1"
                size="sm"
                variant="primary"
                @click="reopenIpz(ipz)"
              >
                <i
                  v-show="reopening"
                  class="fa fa-spin fa-spinner"
                />
                Otwórz ponownie IPZ
              </b-btn>
            </is-granted>
            <is-granted
              v-if="ipz.status === 'canceled'"
              :privileges="['REOPEN_CANCELED_IPZ']"
              component="fragment"
            >
              <b-btn
                class="m-1"
                size="sm"
                variant="primary"
                @click="reopenCanceledIpz(ipz)"
              >
                <i
                  :class="reopening ? 'fa-spin fa-spinner' :'fa-lock-open'"
                  class="fas mr-1"
                />
                Otwórz ponownie IPZ
              </b-btn>
            </is-granted>
            <is-granted
              :privileges="['IPZ_DETAILS']"
              component="fragment"
            >
              <router-link
                :to="{name: 'ipz_details', params: {ipzId: ipz.ipzId}}"
                class="btn btn-secondary btn-sm m-1"
              >
                <span class="fas fa-eye mr-1" />
                Szczegóły
              </router-link>
            </is-granted>
            <is-granted
              :privileges="['UNIFY_IPZ']"
              component="fragment"
            >
              <b-btn
                class="m-1"
                :disabled="editing"
                size="sm"
                variant="outline-secondary"
                @click="openEditModal(ipz)"
              >
                <i
                  :class="editing ? 'fa-spin fa-spinner' :'fa-pencil-alt'"
                  class="fas mr-1"
                />
                Edycja daty
              </b-btn>
            </is-granted>
            <is-granted
              v-if="isUnificationEnabled && 'opened' === ipz.status"
              :privileges="['UNIFY_IPZ', 'CANCEL_IPZ']"
              condition="AND"
              component="fragment"
            >
              <b-btn
                v-b-modal="`cancel-ipz-${ipz.ipzId}`"
                variant="light"
                size="sm"
                class="m-1"
              >
                <i class="fas fa-ban mr-1" /> Odwołaj IPZ
              </b-btn>
            </is-granted>
            <cancel-ipz
              :ipz-id="ipz.ipzId"
              class="d-inline-block m-1"
              @ipzCanceled="loadIpzs"
            />
            <error-message :errors="errors" />
          </td>
        </tr>
      </tbody>
    </table>
    <p
      v-show="!loadingItems && !ipzList.length"
    >
      Nie znaleziono IPZ
    </p>
    <b-modal
      id="confirm-create-ipz"
      no-close-on-backdrop
      title="Potwierdź utworzenie IPZ"
      title-tag="h3"
    >
      <template #default>
        <span class="font-18">
          Czy chcesz utworzyć IPZ?
        </span>
      </template>
      <template #modal-footer>
        <button
          type="button"
          class="btn btn-secondary"
          @click="closeConfirmCreateModal"
        >
          Anuluj
        </button>
        <button
          :disabled="createLoading"
          class="btn btn-primary"
          @click="createIpz"
        >
          <i
            :class="createLoading ? 'fa-spin fa-spinner' : 'fa-check'"
            class="fa"
          />
          Utwórz
        </button>
      </template>
    </b-modal>
    <unification-ipz-edit-modal
      :ipz="editingIpz"
      @close="closeEditModal"
    />
  </card>
</template>

<script>
import {generateUuid} from "@/utils/uuid/generateUuid";
import IsGranted from "../../IsGranted";
import I18n from "../../i18n";
import read from "../../../rest/read";
import create from "../../../rest/create";
import update from "../../../rest/update";
import processResponseException from "../../../utils/errors/processResponseException";
import ErrorMessage from "../../Form/ErrorMessage";
import UnificationIpzEditModal from "../../Ipz/UnificationIpzEditModal";
import CancelIpz from "../../Ipz/CancelIpz";
import Card from "../../Card";

export default {
  components: {CancelIpz, UnificationIpzEditModal, ErrorMessage, IsGranted, I18n, Card},
  props: {
    isUnificationEnabled: {type: Boolean, default: false},
    patientId: {type: String, required: true},
  },
  data() {
    return {
      createLoading: false,
      editing: false,
      reopening: false,
      editingIpz: null,
      ipzList: [],
      loadingItems: false,
      errors: [],
    };
  },
  computed: {
    hasActiveIpz() {
      return this.ipzList.filter((i) => {return "opened"===i.status}).length > 0;
    },
  },
  watch: {
    patientId() {
      this.loadIpzs();
    },
  },
  async mounted() {
    await this.loadIpzs();
  },
  methods: {
    async loadIpzs() {
      this.loadingItems = true;
      try {
        const {items} = await read(`/api/patients/${this.patientId}/ipzs`);
        this.ipzList = items;
      } catch(exception) {
        this.errors = processResponseException(exception);
      }
      this.loadingItems = false;
    },
    async createIpz() {
      this.createLoading = true;
      const ipzId = generateUuid();
      const diagnosisCardId = generateUuid();
      const simplifiedIpzId = generateUuid();
      const newIpz = {
        simplifiedIpzId,
        diagnosisCardId,
        ipzId,
        patientId: this.patientId,
      };

      try {
        await create(`/api/ipzs/${ipzId}`, newIpz);
        const data = await read(`/api/ipzs/${ipzId}`);

        this.ipzList.unshift(data);
        this.errors = [];
      } catch (exception) {
        this.errors = processResponseException(exception);
        if (!this.ipzList.length) {
          this.loadIpzs();
        }
      }
      this.createLoading = false;
      this.closeConfirmCreateModal()
    },
    closeConfirmCreateModal() {
      this.$bvModal.hide("confirm-create-ipz");
    },
    openConfirmCreateModal() {
      this.$bvModal.show("confirm-create-ipz");
    },
    closeEditModal(newIpz) {
      this.editingIpz = null;
      if (null == newIpz) {
        return;
      }
      const index = this.ipzList.findIndex(
        (old) => old.ipzId === newIpz.ipzId
      );
      if (index < 0) {
        return;
      }
      this.ipzList.splice(index, 1, newIpz);
    },
    openEditModal(ipz) {
      this.editingIpz = ipz;
    },
    async reopenIpz(ipz) {
      try{
        this.reopening = true;
        this.errors = [];
        await update(`/api/ipzs/${ipz.ipzId}/reopen`, {});
      } catch (e) {
        this.errors = processResponseException(e);
      }
      this.reopening = false;
      await this.loadIpzs();
    },
    async reopenCanceledIpz(ipz) {
      try{
        this.reopening = true;
        this.errors = [];
        await update(`/api/ipzs/${ipz.ipzId}/reopen-canceled`, {});
      } catch (e) {
        this.errors = processResponseException(e);
      }
      this.reopening = false;
      await this.loadIpzs();
    },
    getBadgeVariant(status) {
      switch (status) {
        case "opened":
          return "success";
        case "closed":
          return "primary";
        case "canceled":
          return "danger";
        default:
          throw Error(`Not supported status: ${status}`);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.status-badge{
  width: 5rem;
}
</style>
