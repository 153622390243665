





















































import {Component, Prop, Watch} from "vue-property-decorator";
import read from "../../../rest/read";
import LoadingMask from "../../../components/Loading/LoadingMask.vue";
import SimplifiedIpzTimelineEntry from "../../../components/Ipz/SimplifiedIpz/SimplifiedIpzTimelineEntry.vue";
import SimplifiedIpzFilters from "../../../components/Ipz/SimplifiedIpz/SimplifiedIpzFilters.vue";
import SimplifiedIpzTimelineEntryAdd from "../../../components/Ipz/SimplifiedIpz/SimplifiedIpzTimelineEntryAdd.vue";
import ErrorMessage from "../../../components/Form/ErrorMessage.vue";
import ErrorsMixin from "../../../mixins/ErrorsMixin.ts";
import {mixins} from "vue-class-component";
import processResponseException from "../../../utils/errors/processResponseException";
import type {SimplifiedIpzEntriesData, SimplifiedIpzEntry, SimplifiedIpzTypes} from "../../../types/SimplifiedIpzTypes";
import SimplifiedIpzTimelineInfoEntry from "../../../components/Ipz/SimplifiedIpz/SimplifiedIpzTimelineInfoEntry.vue";

@Component({
  name: "SimplifiedIpzPage",
  components: {
    SimplifiedIpzTimelineInfoEntry,
    ErrorMessage,
    SimplifiedIpzTimelineEntryAdd,
    SimplifiedIpzTimelineEntry,
    SimplifiedIpzFilters,
    LoadingMask
  },
})
export default class SimplifiedIpzPage extends mixins(ErrorsMixin) {
  @Prop({type: String, required: true}) readonly ipzId!: string;

  entries: Array<SimplifiedIpzEntry> = [];
  loading: boolean = false;
  type: SimplifiedIpzTypes | null = null;
  simplifiedIpzId: string | null = null;
  overview: object = {};
  noteEditing: boolean = false;

  get filteredEntries():Array<SimplifiedIpzEntry> {
    return this.type
      ? this.entries.filter(entry => entry.type === this.type)
      : this.entries;
  }

  get atLeastOneEntry() {
    return this.entries.length > 0;
  };

  @Watch("ipzId") async onIpzIdChanged(): Promise<void>
  {
    await this.loadEntries();
  };

  @Watch("type") onTypeChange(): void
  {
    this.loading = true;
    setTimeout(() => {this.loading = false}, 70);
  }


  async mounted(): Promise<void> {
    await this.loadEntries();
  }

  private async loadEntries(): Promise<void> {
    this.loading = true;
    try {
      const {entries, simplifiedIpzId, overview} = await read<SimplifiedIpzEntriesData>(
        `/api/ipzs/${this.ipzId}/simplified-ipz`,
      );
      this.entries = entries.map(entry => {
        return{
          ...entry,
          entryDate: new Date(entry.entryDate)
        }
      });
      this.simplifiedIpzId = simplifiedIpzId;
      this.overview = overview;
    } catch (exception) {
      this.errors = processResponseException(exception);
    }
    this.loading = false;
  }

  startEditing(): void {
    this.noteEditing = true;
  }

  stopEditing(): void {
    this.noteEditing = false;
  }
}
