<template>
  <object-select
    :value="selectedOption"
    :options="options"
    :disabled="disabled"
    :placeholder="placeholder"
    :state="state"
    :multiple="true"
    @input="update"
  />
</template>

<script>
import ObjectSelect from "../Form/Select/ObjectSelect";
import read from "../../rest/read";

export default {
  components: {
    ObjectSelect,
  },

  props: {
    value: {type: Array, default: () => []},
    disabled: {type: Boolean, default: false},
    state: {type: Boolean, default: null},
    placeholder: {type: String, default: "Wybierz specialzację medyczne i lekarsko-dentystyczne"},
  },

  data() {
    return {
      options: [],
    }
  },
  computed: {
    selectedOption () {
      return this.options.filter((option) => (this.value.includes(option.value)));
    }
  },
  async mounted() {
    const {items} = await read("/api/medical-specializations");
    this.options = items;
  },
  methods: {
    update(option) {
      this.$emit("input", option.map((opt)=> (opt.value)));
    },
  },
}
</script>

<style scoped>

</style>