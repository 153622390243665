





























import {Component, Prop, Ref} from "vue-property-decorator";
import type {Drug} from "../../types/Prescription";
import Vue from "vue";
import {BFormInput} from "bootstrap-vue";
import {Error} from "../../rest";
import ErrorMessage from "../Form/ErrorMessage.vue";

@Component({
  name: "MedicationSize",
  components: {ErrorMessage}
})
export default class MedicationQuantity extends Vue {
  @Prop({type: Object, default: null}) readonly drug!: Drug;
  @Prop({type: Number, default: null}) readonly value!: number;
  @Prop({type: Boolean, default: false}) readonly disabled!: boolean;
  @Prop({type: Array, default: ()=>[]}) readonly errors!: Array<Error>;

  @Ref("input") readonly input!: BFormInput;

  private edited: boolean = false;

  private get isInvalid(): boolean {
    return !!this.errors.find((error) => error.field === "quantity");
  }

  private get drugSize(): string {
    return this.drug
      ? `x ${this.drug.size} ${this.drug.wrapper}`
      : "";
  }

  private update(newValue: string|null): void {
    this.$emit("input", newValue ? parseFloat(newValue) : null);
  }

  private startEdit(): void {
    if (!this.disabled) {
      this.edited = true;
      this.$nextTick(() => this.input.focus());
    }
  }

  private stopEdit(): void {
    this.edited = false;
  }
}
