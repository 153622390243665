








import Vue from "vue";
import Component from "vue-class-component";
import {Prop} from "vue-property-decorator";
import ObjectSelect from "../Form/Select/ObjectSelect.vue";
import AppointmentStatus from "./AppointmentStatus.vue";
import type {Option} from "../../types/MultiSelectTypes";

@Component({
  name: "AppointmentStatusSelect",
  components: {ObjectSelect}
})
export default class AppointmentStatusSelect extends Vue {
  @Prop({type: Array, default: null}) readonly value!: Array<string> | null;
  @Prop({type: Boolean, default: false}) readonly checkedInEnabled!: boolean;

  update(selectedOptions: Array<Option>) {
    const selectedValues = selectedOptions.map(option => option.value);
    this.$emit("input", selectedValues);
  }

  get selected(): Array<Option> | null {
    if (!this.value) {
      return null;
    }

    return this.options.filter((option) => {return this.value && this.value.indexOf(option.value) !== -1});
  }

  get options(): Array<Option> {
    let statuses = AppointmentStatus.getNames();
    if (!this.checkedInEnabled) {
      statuses = statuses.filter(status => status !== "checkedin");
    }
    return statuses.map((value: string) => ({
      name: AppointmentStatus.getLabel(value as any),
      value
    }));
  }
}
