<template>
  <loading-mask :loading="loading">
    <diagnoses-entries-dashboard
      v-if="overview"
      :overview="overview"
      :read-only="overview.readonly"
    />
  </loading-mask>
</template>
<script>
import read from "../../../rest/read";
import DiagnosesEntriesDashboard from "../../../components/Ipz/IpzDetails/DiagnosesEntriesDashboard";
import LoadingMask from "../../../components/Loading/LoadingMask";

export default {
  components: {LoadingMask, DiagnosesEntriesDashboard},
  props: {
    ipzId: {type: String, required: true},
  },
  data() {
    return {
      overview: null,
      loading: false,
    }
  },
  watch: {
    async ipzId() {
      await this.loadData();
    },
  },
  async mounted() {
    await this.loadData();
  },
  methods: {
    async loadData() {
      this.loading = true;
      const {overview} = await read(`/api/ipzs/${this.ipzId}/details`);
      this.overview = overview;
      this.loading = false;
    }
  }
}
</script>
