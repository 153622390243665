<template>
  <object-select
    :value="selectedValue"
    :options="options"
    :placeholder="placeholder"
    :state="state"
    @input="update"
  />
</template>

<script>
import ObjectSelect from "./ObjectSelect";
export default {
  components: {ObjectSelect},
  props: {
    value: {type: String, default: null},
    placeholder: {type: String, default: "Wybierz okres"},
      state: ObjectSelect.props.state, // eslint-disable-line
  },
  data() {
    return {
      options: [
        {value: "1", name: "1 dzień"},
        {value: "7", name: "tydzień"},
        {value: "14", name: "2 tygodnie"},
        {value: "21", name: "3 tygodnie"},
      ],
    };
  },
  computed: {
    selectedValue() {
      return this.options.find(option => option.value === this.value);
    },
  },
  methods: {
    update(option) {
      this.$emit("input", option ? option.value : null);
    },
  },
}
</script>
