<template>
  <div v-if="overview">
    <loading-mask :loading="loading">
      <ipz-custom-title-box
        :overview="overview"
        :title="$route.meta.title"
        @reloadData="reloadData"
      />
      <ipz-tabs
        :overview="overview"
        :tab="$route.name"
      />
    </loading-mask>
    <transition name="fade">
      <div class="card-box view-container">
        <router-view />
      </div>
    </transition>
  </div>
</template>

<script>
import IpzCustomTitleBox from "../Ipz/IpzCustomTitleBox";
import IpzTabs from "../Ipz/IpzTabs";
import LoadingMask from "../Loading/LoadingMask";
import read from "../../rest/read";

export default {
  name: "IpzBaseLayout",
  components: {LoadingMask, IpzTabs, IpzCustomTitleBox},
  props: {
    ipzId: {type: String, required: true},
  },
  data() {
    return {
      loading: false,
      overview: null,
      fields: null,
      branch24hId: null,
    };
  },
  watch: {
    ipzId() {
      this.loadData();
    },
  },
  async mounted () {
    this.loadData();
  },
  methods: {
    async loadData() {
      this.loading = true;
      const {overview} = await read(`/api/ipzs/${this.ipzId}/details`);
      this.overview = overview;
      this.loading = false;
    },
    reloadData() {
      this.$router.push({name: "ipz_details", params: {ipzId: this.ipzId}});
      this.loadData();
    }
  }
}
</script>

<style scoped>
  .view-container {
    min-height: 110px;
  }
</style>
