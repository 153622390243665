<template>
  <div>
    <template v-if="!value">
      <p>b.d.</p>
    </template>
    <template v-else>
      <template v-if="value.startDate">
        <p><b>Data początkowa: </b>{{ value.startDate }}</p>
      </template>

      <template v-if="value.endDate">
        <p><b>Data końcowa: </b>{{ value.endDate }}</p>
      </template>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    value: {type: Object, default: null},
  },
}
</script>

<style scoped>

</style>
