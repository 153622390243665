<template>
  <div class="row">
    <div :class="!vertical && files.length ? 'col-sm-6' : 'col-12'">
      <file-select
        :multiple="multiple"
        :accept="accept"
        :disabled="disabled"
        @input="handleChange"
      />
    </div>
    <div
      v-if="files.length"
      :class="vertical ? 'col-md-6 offset-md-3 m-t-10' : 'col-sm-6'"
    >
      <div
        v-for="(file, index) in files"
        :key="index"
        class="media"
      >
        <div class="media-body">
          <div>
            <i
              v-if="file.error"
              :title="file.error"
              class="fa fa-times text-danger"
            />
            <i
              v-else-if="file.send !== file.size"
              class="fa fa-spinner fa-spin"
              title="Trwa wgrywanie pliku..."
            />
            <i
              v-else
              class="fa fa-check-circle text-success"
              title="Plik zapisany"
            />
            {{ file.name }}
            <span 
              v-if="file.error" 
              class="text-danger"
            >
              {{ file.error }}
            </span>
            <span v-else-if="file.send !== file.size">
              ( {{ progressText(file) }})
            </span>
          </div>
          <b-progress 
            :value="file.send" 
            :max="file.size" 
            class="mb-3"
          />
        </div>
        <div class="ml-3">
          <div 
            class="btn btn-group btn-group-sm" 
            style="width: 60px"
          >
            <button
              v-if="file.request || file.error"
              type="button"
              title="Anuluj"
              class="btn btn-outline-danger"
              :disabled="disabled"
              @click="cancelUpload(file)"
            >
              <i class="fa fa-times" />
            </button>
            <button
              v-if="file.error"
              type="button"
              title="Ponów"
              class="btn btn-outline-primary"
              :disabled="disabled"
              @click="uploadFile(file)"
            >
              <i class="fa fa-sync" />
            </button>
            <button
              v-if="file.send === file.size"
              type="button"
              title="Usuń"
              class="btn btn-outline-danger"
              :disabled="disabled"
              @click="removeFile(file)"
            >
              <i class="fa fa-trash-alt" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FileSelect from "../File/FileSelect";
import {generateUuid} from "../../utils/uuid/generateUuid";
import * as filesize from "filesize";

export default {
  name: "FileUpload",
  components: {FileSelect},
  props: {
    accept: {type: String, default: "*/*"},
    multiple: {type: Boolean, default: false},
    disabled: {type: Boolean, default: false},
    vertical: {type: Boolean, default: true},
    uploadedFiles: {type: Array, default: ()=>[]}
  },
  data() {
    return {
      files: this.uploadedFiles,
    };
  },
  watch: {
    uploadedFiles(){
      this.files = this.uploadedFiles;
    },
  },
  methods: {
    handleChange(selectedFiles) {
      const newFiles = selectedFiles
        .filter((file) => !this.files.find((f) => (
          f.resource.size === file.size &&
          f.resource.name === file.name &&
          f.resource.lastModified === file.lastModified
        )))
        .map((file) => ({
          fileId: generateUuid(),
          name: file.name,
          size: file.size,
          resource: file,
          send: 0,
        }));

      this.files = this.files.concat(newFiles);
      newFiles.forEach((file) => this.uploadFile(file));
    },
    uploadFile(file) {
      file.error = null;
      file.send = 0;
      const formData = new FormData();
      formData.append("file", file.resource);
      formData.append("fileId", file.fileId);
      const request = new XMLHttpRequest();
      const url = "/api/files";
      request.open("POST", url);
      request.onreadystatechange = () => {
        if (XMLHttpRequest.DONE !== request.readyState) {
          return;
        }
        if (!request.responseURL.endsWith(url)) {
          // handle logout
          window.location.reload();
          return;
        }
        switch (request.status) {
          case 200:
          case 201:
            this.fileUploaded(file);
            break;
          case 413:
            this.handleUploadError(file, "Wybrany plik jest zbyt duży.");
            break;
          default:
            this.handleUploadError(file);
        }
      };
      request.onerror = () => {
        this.handleUploadError(file, "Wystąpił problem z połączeniem.");
      };
      request.upload.onprogress = (event) => {
        file.send = event.loaded;
      };
      request.send(formData);
      file.request = request;
    },
    handleUploadError(file, error = "Wystąpił błąd podczas wgrywania pliku.") {
      file.error = error;
      file.request = null;
      file.send = 0;
    },
    cancelUpload(file) {
      if (file.request) {
        file.request.abort();
      }
      this.files = this.files.filter((f) => f.fileId !== file.fileId);
    },
    async removeFile(file) {
      this.files = this.files.filter((f) => f.fileId !== file.fileId);
      this.$emit("removed", file);
    },
    fileUploaded(file) {
      file.send = file.size;
      file.error = null;
      file.request = null;
      this.$emit("uploaded", {
        fileId: file.fileId,
        size: file.size,
        name: file.name,
        type: file.type,
      });
    },
    progressText(file) {
      return `${Math.floor(file.send / file.size * 100)}% ${filesize(file.send)}/${filesize(file.size)}`;
    }
  }
}
</script>
