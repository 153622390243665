








































































































































import ReportContainer from "../../components/Report/ReportContainer.vue";
import {Component, Mixins, Prop, Watch} from "vue-property-decorator";
import ErrorsMixin from "../../mixins/ErrorsMixin";
import ErrorMessage from "../../components/Form/ErrorMessage.vue";
import postDataAndFiles from "../../rest/postDataAndFiles";
import processResponseException from "../../utils/errors/processResponseException";
import DateTime from "../../components/DateTime.vue";
import DatePicker from "../../components/Form/DatePicker/DatePicker.vue";
import BackButton from "../../components/BackButton.vue";
import stringifyDate from "../../utils/date/stringifyDate";
import {generateUuid} from "../../utils/uuid/generateUuid";
import read from "../../rest/read";
import {changePageQuery} from "../../utils/pageUrl/handlePageQuery";
import PaginationDescription from "../../components/Pagination/PaginationDescription.vue";
import Card from "../../components/Card.vue";
import eventBus from "../../eventBus";
import I18n from "../../components/i18n.vue";
import ReportTemplateFileSelect from "../../components/Report/ReportTemplateFileSelect.vue"

const MAX_FILE_SIZE_MB = 5;
const MAX_FILE_SIZE = {
  megaBytes: MAX_FILE_SIZE_MB,
  bytes: MAX_FILE_SIZE_MB * 1024 * 1024 *5,
};

interface SlReport {
  id: string;
  createdAt: string;
  status: Statuses;
}

interface FindResponse {
  items: Array<SlReport> | null;
  pagination: Pagination;
}

interface Pagination {
  perPage: number;
  totalRows: number;
}

interface Filters {
  page: number | undefined;
}

interface Query {
  page: number | undefined;
}

enum Statuses {
  PENDING = "pending",
  COMPLETED = "completed",
  REJECTED = "rejected",
}

@Component({
  name: "SLReport",
  components: {Card, ReportContainer, ErrorMessage, ReportTemplateFileSelect, DatePicker,
    BackButton, PaginationDescription, I18n}
})

export default class SLReportView extends Mixins(ErrorsMixin) {
  @Prop({type: Number, default: null}) readonly page !: number;

  mounted(): void {
    this.loadData();
  }

  isLoading: boolean = false;
  isSaving: boolean = false;
  hasTemplate: boolean = false;
  startDate: DateTime|null = null;
  endDate: DateTime|null = null;
  templateFile: File|null = null;
  sizeError: string = "";
  items: Array<SlReport> | null = null;
  currentPage: number = this.page || 1;
  pagination: Object = {};

  @Watch("page") onPageChange(page: number): void {
    this.currentPage = page;
  }

  addFile(file: File): void {
    this.templateFile = file;
    this.sizeError = "";
    if (file.size > (MAX_FILE_SIZE.bytes)) {
      this.sizeError = `Plik ${file.name} jest zbyt duży.
        Maksymalna wielkość pliku to ${MAX_FILE_SIZE.megaBytes}MB`;
    }
  }

  removeFile(): void {
    this.sizeError = "";
    this.templateFile = null;
  }

  get fields(): Array<Field> {
    return [
      {key: "createdAt", label: "Data zlecenia"},
      {key: "status", label: "Status", class: "text-center"},
      {key: "conditions", label: "Warunki"},
      {key: "download", label: "Pobierz", class: "text-center"},
    ];
  }

  async submit(): Promise<void> {
    this.errors = [];
    this.isSaving = true;

    const data = new FormData();
    const requestSLReportId = generateUuid() as string;

    data.append("requestSLReportId", requestSLReportId);
    data.append("startDate", stringifyDate(this.startDate));
    data.append("endDate", stringifyDate(this.endDate));
    data.append("templateFile", this.templateFile as Blob || undefined);
    try {
      await postDataAndFiles("/api/report/sl-report", data);
      this.resetData();
      await this.loadData();
      eventBus.on(requestSLReportId, ()=>{ this.loadData() });
    } catch(e) {
      this.errors = processResponseException(e);
    }

    this.isSaving = false;
  }

  async loadData(): Promise<void>{
    this.isLoading = true;
    const {items, pagination} = await read<FindResponse>("/api/report/sl-report", this.filters());
    this.items = items;
    this.pagination = pagination;
    this.isLoading = false;
  }

  filters():Filters {
    return {
      page: this.currentPage || undefined,
    }
  }

  changePage(page: number) {
    this.currentPage = page;
    this.loadData();
    changePageQuery(this.query());
  }

  query():Query {
    return {
      page: this.currentPage || undefined
    }
  }

  resetData(){
    this.startDate = null;
    this.endDate = null;
    this.templateFile = null;
    this.removeFile();
  }

  validateReportDates() {
    if (this.parsedStartDate && this.parsedEndDate
      && this.parsedStartDate.getTime() >= this.parsedEndDate.getTime()) {
      this.errors = [
        {
          message: "Data początkowa powinna być wcześniejsza niż data końcowa",
          field: "startDate"
        },
        {
          message: "Data końcowa powinna być późniejsza niż data początkowa",
          field: "endDate"
        },
      ];
      return;
    }
    this.errors = [];
  }

  get parsedStartDate() {
    const date = new Date(this.startDate);
    date.setHours(0, 0, 0, 0);
    return this.startDate ? date : null;
  }

  get parsedEndDate() {
    const date = new Date(this.endDate);
    date.setHours(23, 59, 59);
    return this.endDate ? date : null;
  }

  getBadgeVariant(status: Statuses): string {
    switch(status) {
      case Statuses.PENDING:
        return "success";
      case Statuses.COMPLETED:
        return "primary";
      case Statuses.REJECTED:
        return "danger";
      default:
        throw Error(`Not supported status: ${status}`);
    }
  }

  downloadStatusIconClass(status: Statuses): string | void {
    switch(status)  {
      case Statuses.PENDING:
        return "fa-spin fa-spinner";
      case Statuses.REJECTED:
        return "fa-ban text-danger";
      default:
        return;
    }
  }

}
