









































































import {Component, Prop} from "vue-property-decorator";
import Vue from "vue";
import ErrorMessage from "../Form/ErrorMessage.vue";
import {Error} from "../../rest";
import {getState} from "../../utils/errors/state";
import {PrescriptionStatuses} from "../../types/Prescription";

@Component({
  name: "PrescriptionDetails",
  components: {ErrorMessage}
})
export default class PrescriptionDetails extends Vue {
  @Prop({type: String, default: null}) readonly value!: string;
  @Prop({type: Boolean, default: false}) readonly disabled!: boolean;
  @Prop({type: Boolean, default: false}) readonly loading!: boolean;
  @Prop({type: Boolean, default: false}) readonly canVerify!: boolean;
  @Prop({type: Boolean, default: false}) readonly ignoreWarnings!: boolean;
  @Prop({type: Array, default: ()=>[]}) readonly errors!: Array<Error>;
  @Prop({type: Array, default: ()=>[]}) readonly warnings!: Array<Error>;
  @Prop({type: String, default: null}) readonly status!: PrescriptionStatuses;
  @Prop({type: String, default: null}) readonly code!: string;
  @Prop({type: String, default: null}) readonly fileId!: string;

  private get isCompleted(): boolean {
    return this.status === PrescriptionStatuses.COMPLETED;
  }

  private get hasWarnings(): boolean {
    return !!this.warnings.length;
  }

  private get statusInfo(): string {
    switch(this.status) {
      case PrescriptionStatuses.QUEUED:
        return "e-Recepta elektroniczna jest w trakcie przetwarzania przez system NFZ." +
          "\nGdy recepta zostanie wystawiona otrzymasz powiadomienie."
      case PrescriptionStatuses.VERIFIED:
        return "e-Recepta została zweryfikowana i przekazana do realizacji, otrzymasz powiadomienie po jej wystawieniu."
      case PrescriptionStatuses.CREATED:
        return "e-Recepta została utworzona. Trwa pobieraniu kodu i wydruku"
      case PrescriptionStatuses.COMPLETED:
        return "e-Recepta została przygotowana. Kliknij przycisk \"Drukuj\" aby pobrać wydruk."
      case PrescriptionStatuses.REJECTED:
        return "Wystawienie e-Recepty nie powiodło się."
      default:
        return "";
    }
  }

  private get statusInfoVariant(): string {
    switch(this.status) {
      case PrescriptionStatuses.QUEUED:
      case PrescriptionStatuses.CREATED:
      case PrescriptionStatuses.VERIFIED:
      case PrescriptionStatuses.COMPLETED:
        return "success";
      case PrescriptionStatuses.REJECTED:
        return "danger";
      default:
        return "";
    }
  }

  private get submitIconClass(): string {
    if (this.loading) {
      return "fa-spin fa-spinner";
    }
    return (this.canVerify && !this.disabled) ? "fa-check" : "fa-ban";
  }

  private submit(): void {
    this.$emit("submit");
  }

  private state(field: string): boolean|null {
    return getState(this.errors, field);
  }

  private updateIgnoreWarnings(value: boolean): void {
    this.$emit("updateIgnoreWarnings", value);
  }
}
