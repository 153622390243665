<template>
  <form>
    <form-row
      :errors="propErrors"
      field="ewusLogin"
      label="Login"
    >
      <b-form-input
        id="ewus-login"
        :state="state('ewusLogin')"
        placeholder="Podaj login"
        :value="value.login"
        @input="update($event, 'login')"
      />
    </form-row>
    <form-row
      :errors="propErrors"
      field="ewusPassword"
      label="Hasło"
    >
      <b-form-group
        :description="displayInfo ? `pozostaw puste jeżeli hasło się nie zmienia` : null"
      >
        <b-form-input
          id="ewus-passowrd"
          placeholder="Podaj hasło"
          :state="state('ewusPassword')"
          type="password"
          :value="value.password"
          @input="update($event, 'password')"
        />
      </b-form-group>
    </form-row>
    <form-row
      :errors="propErrors"
      field="ewusDomain"
      label="Oddział NFZ"
    >
      <nfz-branch-select
        id="ewus-domain"
        class="select"
        :state="state('ewusDomain')"
        :value="value.domain ? {branchId: value.domain} : null"
        @input="updateDomainValue"
      />
    </form-row>
    <form-row
      :errors="propErrors"
      field="ewusOperatorType"
      label="Typ operatora"
    >
      <insurance-provider-operator-type
        id="ewusOperatorType"
        class="select"
        :state="state('ewusOperatorType')"
        :value="value.operatorType"
        @input="update($event, 'operatorType')"
      />
    </form-row>
  </form>
</template>

<script>
import FormRow from "../Form/FormRow";
import NfzBranchSelect from "../Form/Select/NfzBranchSelect";
import InsuranceProviderOperatorType from "../Form/Select/InsuranceProviderOperatorType";

export default {
  name: "EwusAccessForm",
  components: {InsuranceProviderOperatorType, NfzBranchSelect, FormRow},
  props: {
    value: {type: Object, required: true},
    displayInfo: {type: Boolean, default: false},
    propErrors: {type: Array, default: ()=>[]},
  },
  methods: {
    update($event, field) {
      this.$emit("input", {
        ...this.value,
        [field]: $event,
      })
    },
    updateDomainValue($event) {
      const domain = $event ? $event.branchId : null;
      this.$emit("input", {
        ...this.value,
        domain,
      })
    },
    state(field) {
      return this.propErrors.find((error) => error.field === field) ? false : null;
    },
  }
}
</script>
