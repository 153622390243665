<template>
  <div>
    <div class="card p-b-10">
      <div
        id="headingOne"
        class="card-header"
      >
        <diagnosis-title
          :entries="entries"
          title="Oddział całodobowy"
        />
      </div>
      <div class="card-body">
        <diagnosis-field
          v-for="(item, name) in {
            BRANCH_24H_EPIC: 'Epikryza',// name: item
            BRANCH_24H_PSYCHOLOGICAL_EXAMINATION: 'Badania psychologiczne',
            BRANCH_24H_THERAPEUTIC_INTERACTIONS: 'Oddziaływania terapeutyczne',
            BRANCH_24H_OTHERS: 'Inne',
          }"
          :key="name"
          :read-only="overview.readonly"
          :injected-entries="getField(name).entries"
          :type="name"
          :type-trans="item.toUpperCase()"
          :create-entry-url="`/api/ipzs/24-hour-branch/${branch24hId}/entries`"
          @new-entry-added="entryAdded"
        />
      </div>
    </div>
    <div class="card p-b-10 mt-5">
      <div
        id="headingSecond"
        class="card-header"
      >
        <diagnosis-title
          :entries="entries"
          title="Oddział Dzienny"
        />
      </div>
      <div class="card-body">
        <diagnosis-field
          v-for="(item, name) in {
            BRANCH_DAILY_EPIC: 'Epikryza',// name: item
            BRANCH_DAILY_PSYCHOLOGICAL_EXAMINATION: 'Badania psychologiczne',
            BRANCH_DAILY_THERAPEUTIC_INTERACTIONS: 'Oddziaływania terapeutyczne',
            BRANCH_DAILY_OTHERS: 'Inne',
          }"
          :key="name"
          :read-only="overview.readonly"
          :injected-entries="getField(name).entries"
          :type="name"
          :type-trans="item.toUpperCase()"
          :create-entry-url="`/api/ipzs/24-hour-branch/${branch24hId}/entries`"
          @new-entry-added="entryAdded"
        />
      </div>
    </div>
  </div>
</template>

<script>
import DiagnosisField from "../DiagnosisField/DiagnosisField";
import DiagnosisTitle from "../DiagnosisTitle";

export default {
  name: "Branch24HDetails",
  components: {DiagnosisTitle, DiagnosisField},
  props: {
    branch24hId: {type: String, required: true},
    overview: {type: Object, required: true},
    fields: {type: [Object, Array], required: true},
  },
  computed: {
    atLeastOneEntry() {
      return Object.keys(this.fields).length > 0;
    },
    entries() {
      return Object.keys(this.fields).reduce((entriesList, key) => {
        return [...entriesList, ...this.fields[key].entries];
      }, []);
    },
  },
  methods: {
    getField(key) {
      return undefined !== this.fields[key] ? this.fields[key] : {entries: []};
    },
    entryAdded() {
      this.$emit("update");
    },
  },
}
</script>
