<template>
  <object-select
    :value="value"
    :placeholder="placeholder"
    :options="options"
    @input="update"
  />
</template>

<script>
import ObjectSelect from "../Form/Select/ObjectSelect";

export default {
  components: {
    ObjectSelect
  },
  props: {
    value: {type: Object, default: null},
    placeholder: {type: String, default: "Wybierz typ uwierzytelniania dwuetapowego"},
  },
  data() {
    return {
      options: [
        {value: "email", name: "jednorazowy kod wysyłany na Twój adres e-mail"},
        {value: "totp", name: "aplikacja generująca kody"}
      ],
    };
  },
  methods: {
    update(option) {
      this.$emit("input", option);
    },
  },
}
</script>
