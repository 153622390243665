
















import {Component, Prop} from "vue-property-decorator";
import Vue from "vue";

@Component({})
export default class PaginationDescription extends Vue {
  @Prop({type: Number, default: 0}) readonly itemsCount !: number;
  @Prop({type: Number, default: 1}) readonly page !: number;
  @Prop({type: Number, default: 0}) readonly perPage !: number;
  @Prop({type: Number, default: 0}) readonly totalCount !: number;
  @Prop({type: Boolean, default: false}) readonly persistedSlot !: boolean;

  get firstVisibleItem(): number {
    return this.perPage * (this.page - 1);
  }

  get showSlot(): boolean {
    return this.persistedSlot ? true : this.totalCount > this.perPage;
  }
}
