<template>
  <loading-mask :loading="loading">
    <patient-evaluations-list
      v-if="overview"
      :overview="overview"
    />
  </loading-mask>
</template>
<script>
import read from "../../../rest/read";
import PatientEvaluationsList from "../../../components/Ipz/Evaluation/PatientEvaluationsList";
import LoadingMask from "../../../components/Loading/LoadingMask";

export default {
  components: {LoadingMask, PatientEvaluationsList},
  props: {
    ipzId: {type: String, required: true},
  },
  data() {
    return {
      overview: null,
      loading: false,
    }
  },
  watch: {
    async ipzId() {
      await this.loadData();
    },
  },
  async mounted() {
    await this.loadData();
  },
  methods: {
    async loadData() {
      this.loading = true;
      const {overview} = await read(`/api/ipzs/${this.ipzId}/details/evaluations`);
      this.overview = overview;
      this.loading = false;
    }
  }
}
</script>
