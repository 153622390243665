<template>
  <div>
    <div
      v-if="patient"
      class="card-box"
    >
      <h4 class="col-12">
        Dane pacjenta
      </h4>
      <form-row
        label="Imię"
      >
        <b-form-input
          :value="patient.name"
          readonly
        />
      </form-row>
      <form-row
        label="Nazwisko"
      >
        <b-form-input
          :value="patient.surname"
          readonly
        />
      </form-row>
      <form-row
        label="PESEL"
      >
        <b-form-input
          :value="patient.pesel"
          readonly
        />
      </form-row>
    </div>
    <div class="card-box">
      <h4 class="col-12">
        Dane jednostki
      </h4>
      <form-row
        :errors="errors"
        label="Nazwa jednostki"
        field="unitName"
      >
        <b-form-input
          v-model.trim="unitName"
          :disabled="createLoading"
          :state="state('unitName')"
          class="form-control"
          @focus.native="clearError('unitName')"
        />
      </form-row>
      <form-row
        :errors="errors"
        label="Oddział"
        field="unitDepartment"
      >
        <b-form-input
          v-model.trim="unitDepartment"
          :disabled="createLoading"
          :state="state('unitDepartment')"
          @focus.native="clearError('unitDepartment')"
        />
      </form-row>
    </div>
    <div class="card-box">
      <h4 class="col-12">
        Rozpoznanie
      </h4>
      <form-row
        :errors="errors"
        label="Cel porady (uzasadnienie)"
        field="reason"
      >
        <textarea-counter
          v-model="reason"
          :disabled="createLoading"
          :max-characters-count="25000"
          :rows="2"
          :state="state('reason')"
          required
          @focus="clearError('reason')"
        />
      </form-row>
      <recognitions
        v-model="recognitions"
        :disabled="createLoading"
        :errors="errors"
        @input="clearError('recognitions')"
      />
    </div>
    <div class="col-12">
      <error-message :errors="errors" />
    </div>
    <div class="text-right">
      <router-link
        :to="appointmentDocumentPath"
        class="btn btn-secondary"
      >
        Powrót do wizyty
      </router-link>
      <button
        :disabled="createLoading"
        class="btn btn-primary ml-2"
        @click="issueReferral"
      >
        <i
          :class="createLoading ? 'fa-spinner fa-spin' : 'fa-check'"
          class="fa"
        />
        Zapisz
      </button>
    </div>
  </div>
</template>
<script>
import Recognitions from "../../components/Referrals/Recognitions.vue";
import TextareaCounter from "../../components/Form/Textarea/TextareaCounter";
import ErrorMessage from "../../components/Form/ErrorMessage";
import FormRow from "../../components/Form/FormRow";
import {generateUuid} from "../../utils/uuid/generateUuid";
import create from "../../rest/create";
import processResponseException from "../../utils/errors/processResponseException";
import {BadRequestException} from "../../rest";
import read from "../../rest/read";
import {mapActions, mapMutations} from "vuex";

export default {
  components: {
    Recognitions,
    TextareaCounter,
    FormRow,
    ErrorMessage
  },
  props: {
    appointmentDocumentId: {type: String, default: null},
  },
  data() {
    return {
      appointmentId: null,
      createLoading: false,
      errors: [],
      reason: "",
      unitName: "",
      unitDepartment: "",
      recognitions: [],
      patient: null
    };
  },
  computed: {
    appointmentDocumentPath() {
      return {
        name: "viewDocumentAppointment",
        params: {appointmentId: this.appointmentId},
        hash: `#appointmentDocumentId=${this.appointmentDocumentId}`,
      };
    },
  },
  watch: {
    errors() {
      if (!this.errors.length) {
        this.hideToast();
      }
    },
  },
  async mounted() {
    const {patient, appointmentId} = await read(`/api/referral/${this.appointmentDocumentId}/consultation/patient`);
    this.patient = patient;
    this.appointmentId = appointmentId;
  },
  methods: {
    ...mapMutations("toastNotification", ["hideToast"]),
    ...mapActions("toastNotification", ["showToast"]),
    async issueReferral() {
      this.createLoading = true;
      const referralId = generateUuid();
      const referral = {
        referralId,
        appointmentDocumentId: this.appointmentDocumentId,
        patientId: this.patient.patientId,
        reason: this.reason.trim(),
        unitName: this.unitName,
        unitDepartment: this.unitDepartment,
        recognitions: this.recognitions.map(recognition => ({
          comments: "",
          ...recognition,
          icd10Code: recognition.icd10Code.value,
        })),
      };

      try {
        this.hideToast();
        await create(`/api/referral-for-consultation/${this.appointmentDocumentId}`, referral);
        this.$emit("created", referralId);
        await this.$router.push(this.appointmentDocumentPath);
      } catch (e) {
        this.errors = processResponseException(e);
        if (e instanceof BadRequestException) {
          this.showToast({
            message: "Formularz zawiera błędy.\nUzupełnij dane, aby wystawić skierowanie. ",
            variant: "danger",
          });
        } else {
          this.showToast({
            message: "Wystąpił błąd.\nZmiany nie zostały zapisane",
            variant: "danger",
          });
        }
      }
      this.createLoading = false;
    },
    clearFields() {
      this.reason = "";
      this.unitName = "";
      this.unitDepartment = "";
      this.recognitions = [];
    },
    clearErrors() {
      this.errors = [];
    },
    state(field) {
      return this.errors.find((error) => error.field === field) ? false : null;
    },
    clearError(field) {
      this.errors = this.errors.filter(error => error.field !== field);
    },
  },
}
</script>
