<template>
  <card
    :loading="isLoading"
    title="Znaczniki pacjenta"
  >
    <template #control>
      <is-granted
        :privileges="['CREATE_PATIENT_INDICATOR']"
      >
        <patient-card-update-indicators
          :patient-id="patientId"
          :indicator-types-list="indicatorTypesList"
          :patient-indicators-list="patientIndicatorsList"
          :is-parent-loading="isLoading"
          @indicatorsUpdated="getPatientIndicators"
        />
      </is-granted>
    </template>
    <tooltip
      v-for="patientIndicator in sortedPatientIndicatorsList"
      :id="patientIndicator.type"
      :key="patientIndicator.type"
      :tooltip-content="patientIndicator.description"
      component="div"
      class="btn btn-danger col-lg-12 col-xl-12 font-14 m-b-10"
    >
      {{ patientIndicator.title }}
    </tooltip>
    <p v-if="isLoading">
      <i class="fa fa-spin fa-spinner" />
    </p>
  </card>
</template>

<script>

import read from "../../../../rest/read";
import PatientCardUpdateIndicators from "./PatientCardUpdateIndicators";
import Tooltip from "../../../Tooltip";
import Card from "../../../Card";
import IsGranted from "../../../IsGranted";

export default {
  components: {
    IsGranted,
    Card,
    Tooltip,
    PatientCardUpdateIndicators
  },
  props: {
    patientId: {type: String, required: true},
  },
  data() {
    return {
      indicatorTypesList: [],
      patientIndicatorsList: [],
      isLoading: false,
    };
  },
  computed: {
    sortedPatientIndicatorsList() {
      return this.patientIndicatorsList.slice(0).sort(function(a, b) {
        return a.title.toLowerCase() >= b.title.toLowerCase() ? 1 : -1;
      });
    }
  },
  watch: {
    patientId() {
      this.getPatientIndicators();
    },
  },
  mounted() {
    this.getIndicatorTypes();
    this.getPatientIndicators();
  },
  methods: {
    async getIndicatorTypes() {
      this.isLoading = true;
      const response = await read("/api/patient/indicatorTypes");
      this.indicatorTypesList = response.items;
      this.isLoading = false;
    },
    async getPatientIndicators() {
      this.isLoading = true;
      const response = await read(`/api/patient/${this.patientId}/indicators`);
      this.patientIndicatorsList = response.items;
      this.isLoading = false;
    },
  },
}
</script>
