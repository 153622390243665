




import Vue from "vue";
import Component from "vue-class-component";
import {Prop} from "vue-property-decorator";
import t from "../i18n";

@Component
export default class i18n extends Vue {
  @Prop({type: String, default: "span"}) readonly component!: string;
  @Prop({type: String, required: true}) readonly msgid!: string;

  get text(): string {
    return t(this.msgid) || this.msgid;
  }
}
