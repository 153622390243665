export interface Test {
  testId: string;
  value?: string;
  name: string;
  scales: TestScale[];
  labelType: string,
  shortcut: string | null
}

export interface TestScale {
  scaleId: string;
  name: string;
  description: string;
}

export interface TestResultSubmitData {
  testResultId: string;
  patientId: string;
  testId: string | null;
  rawResults: Record<TestScale["scaleId"], string>;
  results: Record<TestScale["scaleId"], string | null>;
  date: string | null; // YYYY-MM-DD HH:mm:ss
  summary: string;
  status: TestResultStatus;
  continuousStayId: string|null;
  appointmentDocumentId: string|null;
  labelType: string|null;
}

export interface TestResult {
  testResultId: string;
  name: string;
  testId: string;
  date: string; // YYYY-MM-DD HH:mm:ss
  createdBy: string; // worker name
  scales: TestScale[];
  rawResults: Record<TestScale["scaleId"], string>;
  results: Record<TestScale["scaleId"], string>;
  summary: string;
  status: TestResultStatus;
  continuousStayId: string|null;
  appointmentDocumentId: string|null;
  labelType: string;
  shortcut: string;
}

export enum TestResultStatus {
  OPENED = "opened",
  FINISHED = "finished",
  DRAFT = "draft",
}

export enum TestResultModalType {
  EDIT = "edit",
  PREVIEW = "preview",
}
