<template>
  <div>
    <div class="card-box m-b-5">
      <div class="text-right">
        <div>
          <i
            v-if="loading"
            class="fa fa-spin fa-spinner fa-lg"
          />
        </div>
        <router-link
          :to="{name: 'get_institution_create_view'}"
          class="btn btn-success waves-effect"
        >
          <i class="fa fa-plus" /> Dodaj instytucję
        </router-link>
      </div>
      <form
        class="form-row"
        @submit.prevent="submit"
      >
        <b-form-group
          class="col-md-6"
          label="Nazwa"
        >
          <b-input
            v-model="currentPhrase"
            class="form-control"
            name="phrase"
            autofocus
            placeholder="Wpisz fragment nazwy..."
          />
        </b-form-group>
        <div class="col-12 text-right no-label">
          <a
            v-if="!loading"
            :href="exportUrl"
            class="btn btn-warning mr-2"
            target="_blank"
          >
            <i class="fa fa-atom" />
            Eksportuj
          </a>
          <button
            type="submit"
            class="btn btn-primary"
          >
            <i class="fa fa-search" /> Szukaj
          </button>
        </div>
      </form>
    </div>
    <card
      :loading="loading"
      title=""
    >
      <div class="row">
        <div class="card-box col-md-12 m-t-10 p-0 table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th>Nazwa</th>
                <th>Adres</th>
                <th class="text-center">
                  Edycja
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="institution in sortedInstitutions"
                :key="institution.institutionId"
              >
                <td>{{ institution.name }}</td>
                <td>
                  {{ institution.address.city }},
                  {{ institution.address.street }}&nbsp;
                  {{ institution.address.zipCode }}
                </td>
                <td class="text-center">
                  <router-link
                    :to="{name: 'institution_update_view', params: {institutionId: institution.institutionId}}"
                    class="btn btn-sm btn-outline-primary"
                  >
                    <i class="fa fa-pencil-alt" />
                  </router-link>
                  <b-btn
                    v-b-modal.delete-institution-modal
                    size="sm"
                    variant="outline-danger"
                    class="m-l-5"
                    @click="selectInstitutionToDelete(institution.institutionId)"
                  >
                    <i class="fa fa-trash" />
                  </b-btn>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </card>
    <institution-delete-modal
      :institution-id="institutionToDelete"
      @institutionDeleted="getInstitutionList"
    />
  </div>
</template>

<script>
import read from "../../rest/read";
import InstitutionDeleteModal from "../../components/Prevention/InstitutionDeleteModal";
import Card from "../../components/Card";
import isEqual from "lodash/isEqual";
import {changePageQuery} from "../../utils/pageUrl/handlePageQuery";
import {createUrl} from "../../utils/pageUrl/createUrl";

export default {
  components: {InstitutionDeleteModal, Card},
  props: {
    phrase: {type: String, default: ""},
  },
  data() {
    return {
      currentPhrase: this.phrase,
      loading: false,
      institutionList: [],
      institutionToDelete: null,
    };
  },
  computed: {
    filters() {
      return {
        phrase: this.currentPhrase || undefined,
      };
    },
    sortedInstitutions() {
      return this.institutionList.slice(0).sort(function(a, b) {
        return a.name.toLowerCase() >= b.name.toLowerCase()? 1 : -1;
      });
    },
    exportUrl() {
      return createUrl("/api/institutions/export", this.filters);
    },
  },
  watch: {
    phrase(val) {
      this.currentPhrase = val;
    },
    "$route": {
      immediate: true,
      handler() {
        this.getInstitutionList();
      }
    },
  },
  mounted() {
    this.getInstitutionList();
  },
  methods: {
    async getInstitutionList() {
      this.loading = true;
      const {items} = await read("/api/institutions", this.filters);
      this.institutionList = items;
      this.loading = false;
    },
    selectInstitutionToDelete(institutionId) {
      this.institutionToDelete = institutionId;
    },
    updatePageUrl() {
      if (isEqual(this.$route.query, this.filters)) {
        this.getInstitutionList();
        return;
      }
      changePageQuery(this.filters);
    },
    submit() {
      this.updatePageUrl();
    },
  },
}
</script>
