<template>  
  <div class="card-box">
    <div class="form-row">
      <div class="form-group col font-weight-bold">
        Uwagi
      </div>
      <is-granted
        :privileges="['CREATE_APPOINTMENT_DOCUMENT_REMARK_COMMAND']"
        :subject="appointmentDocumentId"
        component="fragment"
      >
        <span>

          <button
            v-if="readOnly"
            class="btn btn-success btn-sm"
            @click="openRemarksModal"
          >
            <i class="fa fa-plus" />
            Dodaj uwagę
          </button>
        </span>
      </is-granted>
      <b-table
        v-if="remarkItems.length"
        :fields="fields"
        :items="remarkItems"
        hover
        :responsive="true"
      >
        <template #cell(worker)="data">
          <div class="worker-name">
            {{ data.value }}
          </div>
        </template>
        <template #cell(created)="data">
          <span class="text-nowrap">{{ data.value }}</span>
        </template>
        <template #cell(remark)="data">
          <span style="white-space: pre-line">{{ data.value }}</span>
        </template>
      </b-table>
      <b-modal
        :visible="modalOpened"
        no-close-on-backdrop
        size="lg"
        title="Dodaj uwagę"
        title-tag="h3"
        @hide="closeRemarksModal"
      >
        <textarea-counter
          v-model="remarkText"
          :state="state('remark')"
          :max-characters-count="10000"
          :rows="8"
          @focus="resetErrors"
          @input="input"
        />
        <error-message
          :errors="errors"
          field="remark"
        />
        <error-message :errors="errors" />
        <template #modal-footer>
          <button
            class="btn btn-secondary"
            @click="closeRemarksModal"
          >
            Anuluj
          </button>
          <button
            class="btn btn-primary"
            @click="sendRemark"
          >
            <i
              :class="sending ? 'fa-spin fa-spinner' : 'fa-check'"
              class="fa"
            />
            Dodaj uwagę
          </button>
        </template>
      </b-modal>
    </div>
  </div>
</template>
<script>

import create from "../../../rest/create";
import {mapState} from "vuex";
import ErrorMessage from "../../Form/ErrorMessage";
import stringifyDate from "../../../utils/date/stringifyDate";
import DATE_FORMAT from "../../../utils/date/DATE_FORMAT"
import TextareaCounter from "../../Form/Textarea/TextareaCounter";
import {errorsMixin} from "../../../mixins/errorsMixin.js";
import processResponseException from "../../../utils/errors/processResponseException";
import IsGranted from "../../IsGranted";

export default {
  components:{
    IsGranted,
    ErrorMessage,
    TextareaCounter
  },
  mixins: [errorsMixin],
  props: {
    idx: {required: true, type: Number},
    module: {required: true, type: Object},
    readOnly: {required: true, type: Boolean},
    appointmentId: {required: true, type: String},
    appointmentDocumentId: {required: true, type: String},
  },
  data() {
    return {
      modalOpened: false,
      remarkItems: this.module.items,
      remarkText: "",
      sending: false,
      fields: [
        {
          key: "worker",
          label: "Wystawił", formatter: (value) => {return `${value.name  } ${  value.surname}`},
          thStyle: "width: 200px"},
        {key: "created", label: "Data", thStyle: "width: 100px"},
        {key: "remark", label: "Uwaga"},
      ],
    };
  },
  computed: {
    ...mapState("currentUser", ["user"]),
  },
  methods: {
    openRemarksModal() {
      this.resetErrors();
      this.modalOpened = true;
      this.remarkText = "";
    },
    closeRemarksModal() {
      this.modalOpened = false;
    },
    async sendRemark() {
      this.sending = true;

      try {
        await create(`/api/appointment/${this.appointmentDocumentId}/document/remarks`, {
          remark: this.remarkText.trim()
        });
        const item = {
          remark: this.remarkText.trim(),
          worker: {
            name: this.user.name,
            surname: this.user.surname,
            workerId: this.user.workerId
          },
          created: stringifyDate(new Date(), DATE_FORMAT.DATE)
        };
        this.remarkItems.push(item);
        this.$emit("created", item);
        this.modalOpened = false;
        this.resetErrors();
      }
      catch (exception) {
        this.errors = processResponseException(exception);
      }
      this.sending = false;
    },
    input(field) {
      this.remarkText = field;
      const module = {
        ...this.module,
        items: this.remarkItems,
      };
      this.$emit("input", module);
    },
    resetErrors() {
      this.errors = [];
    },
  },
}
</script>
