




























import {Component, Prop} from "vue-property-decorator";
import Vue from "vue";
import type {ChildIpzItem} from "../../../types/ChildIpzs";

@Component({
  name: "ChildIpzSignaturesCheckbox"
})
export default class ChildIpzSignaturesCheckbox extends Vue {
  @Prop({type: Array, default: () => []}) readonly signatures!: ChildIpzItem["signatures"];
  @Prop({type: Boolean, default: false}) readonly displayAsContent!: boolean;
  @Prop({type: Boolean, default: null}) readonly state!: boolean;
  @Prop({type: Boolean, default: false}) readonly disabled!: boolean;

  showCheckbox(value: string): boolean {
    return this.signatures.includes(value);
  }

  update($event: Array<string>): void {
    this.$emit("input", $event);
  }
}
