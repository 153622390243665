












import Vue from "vue";
import Component from "vue-class-component";
import CoordinatedTeamLoadList from "./CoordinatedTeamLoadList.vue";
import IsGranted from "../IsGranted.vue";
import Card from "../Card.vue";

@Component({
  name: "CoordinatedTeamLoadListWidget",
  components: {CoordinatedTeamLoadList, IsGranted, Card}
})
export default class CoordinatedTeamLoadListWidget extends Vue {

}
