<template>
  <div>
    <feedback-button fixed />
    <div
      id="wrapper"
      class="wrapper-page d-flex flex-column align-items-center"
      style="height: 100vh"
    >
      <div style="flex: 1 1 auto; overflow: auto; width: 100%">
        <div
          class="mx-auto"
          style="width: 100%; max-width: 400px; margin-top: 4.375rem;"
        >
          <slot />
        </div>
      </div>
      <ue-footer style="flex-shrink: 0" />
      <ue-modal />
    </div>
  </div>
</template>

<script>
import FeedbackButton from "../FeedbackButton";
import UeFooter from "../UEinformation/UeFooter";
import UeModal from "../UEinformation/UeModal";

export default {
  name: "AnonymousLayout",
  components: {UeModal, UeFooter, FeedbackButton},
  computed: {
    colorClass() {
      return "bg-primary"
    },
  },
  mounted() {
    document.documentElement.classList.add(this.colorClass);
    document.body.classList.add(this.colorClass);
  },
  destroyed() {
    document.documentElement.classList.remove(this.colorClass);
    document.body.classList.remove(this.colorClass);
  },
}
</script>
