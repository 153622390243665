






























import {Component, Mixins, Prop, Ref} from "vue-property-decorator";
import type {Drug} from "../../types/Prescription";
import ErrorsMixin from "../../mixins/ErrorsMixin";
import MedicationSelect from "./MedicationSelect.vue";
import {Error} from "../../rest";
import blur from "../../directives/blur";

@Component({
  name: "MedicationType",
  directives: {
    blur,
  },
  components: {MedicationSelect},
})
export default class MedicationType extends Mixins(ErrorsMixin) {
  @Prop({type: String, default: null}) readonly patient!: string;
  @Prop({type: Object, default: null}) readonly value!: Drug;
  @Prop({type: Boolean, default: false}) readonly disabled!: boolean;
  @Prop({type: String, default: "Wybierz lek"}) readonly placeholder!: string;
  @Prop({type: Array, default: ()=>[]}) readonly prescriptionErrors!: Array<Error>;

  @Ref("multiselect") readonly multiselect!: MedicationSelect;

  private edited: boolean = false;

  private get isInvalid(): boolean {
    return !!this.prescriptionErrors.find((error) => error.field === "drug");
  }

  private get drugName(): string {
    return this.value
      ? `${this.value.name} (${this.value.form}) ${this.value.size} ${this.value.wrapper} / ${this.value.dose}`
      : this.placeholder;
  }

  private update(drug: Drug|null): void {
    this.$emit("input", drug);
  }

  private startEdit(): void {
    if (!this.disabled) {
      this.edited = true;
      this.$nextTick(() => this.multiselect.activate());
    }
  }

  private stopEdit(deactivate: boolean = true): void {
    if (deactivate) {
      this.multiselect.deactivate();
    }
    this.edited = false;
  }

  private removeDrug() :void {
    this.update(null);
    this.edited = false;
  }
}
