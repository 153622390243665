<template>
  <card
    :loading="loading"
    title=""
  >
    <template #default>
      <div class="bg-muted p-3">
        <div
          v-if="!entries.length && !loading"
          class="card-box"
        >
          Brak wpisów
        </div>
        <div
          v-for="entry in entries"
          :key="entry.continuousStayEntryId"
        >
          <div class="card-box">
            <div class="font-16">
              <span class="font-weight-bold">{{ entry.workerFullName }}</span>,
              dnia
              <date-time
                :value="parseDate(entry.creationDate, DATE_FORMAT.DATE_TIME_SEC)"
                :format="DATE_FORMAT.DATE_TIME"
              />
            </div>
            <read-more
              :text="entry.content"
              :max-chars="110"
              more-str="rozwiń"
              less-str="zwiń"
              btn-class="btn-sm"
            />
          </div>
        </div>
      </div>
    </template>
  </card>
</template>

<script>
import parseDate from "../../../../utils/date/parseDate";
import DATE_FORMAT from "../../../../utils/date/DATE_FORMAT";
import DateTime from "../../../DateTime";
import ReadMore from "../../../ReadMore";
import Card from "@/components/Card";

export default {
  components: {Card, ReadMore, DateTime},
  props: {
    entries: {type: Array, default: ()=>[]},
    loading: {type:Boolean, default: false},
    continuousStayStatus: {type: String, default: null},
    continuousStayId: {type: String, required: true},
  },
  data() {
    return {
      parseDate,
      DATE_FORMAT,
    };
  }
}
</script>
