









import {Component, Mixins} from "vue-property-decorator";
import IsGranted from "../IsGranted.vue";
import PatientInsuranceMixin from "../../mixins/PatientInsuranceMixin";

@Component({
  name: "PrescriptionInsurance",
  components: {IsGranted}
})
export default class PrescriptionInsurance extends Mixins(PatientInsuranceMixin) {
  get insuranceClass(): string {
    return this.insurance.insured? "text-success" : "text-danger";
  }
}
