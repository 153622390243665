












import Vue from "vue";
import ObjectSelect from "../Form/Select/ObjectSelect.vue";
import {Component, Prop} from "vue-property-decorator";
import type {CancelZlaCode} from "../../types/Zla";
import {CancelZlaCodeType} from "../../types/Zla";

@Component({
  name: "CancelZlaCodeSelect",
  components: {ObjectSelect},
})
export default class CancelZlaCodeSelect extends Vue {
  @Prop({type: Object, default: null}) readonly value!: CancelZlaCode;
  @Prop({type: Boolean, default: null}) readonly state!: boolean;
  @Prop({type: String, default: null}) readonly placeholder!: string;
  @Prop({type: Boolean, default: false}) readonly disabled!: boolean;

  get options(): CancelZlaCode[] {
    return [
      {
        value: CancelZlaCodeType.U,
        label: "b\u0142\u0105d w danych identyfikacyjnych ubezpieczonego",
        description: "Przyczyna o kodzie \"U\" ustawiana w przypadku b\u0142\u0119dnych danych identyfikacyjnych" +
          " ubezpieczonego"
      },
      {
        value: CancelZlaCodeType.P,
        label: "b\u0142\u0105d w danych p\u0142atnika sk\u0142adek",
        description: "Przyczyna o kodzie \"P\" ustawiana w przypadku b\u0142\u0119dnych danych p\u0142atnika" +
          " sk\u0142adek"
      },
      {
        value: CancelZlaCodeType.N,
        label: "b\u0142\u0105d w danych dotycz\u0105cych niezdolno\u015bci do pracy",
        description: "Przyczyna o kodzie \"N\" ustawiana w przypadku b\u0142\u0119dnych danych dotycz\u0105cych" +
          " niezdolno\u015bci do pracy"
      },
      {
        value: CancelZlaCodeType.M,
        label: "b\u0142\u0105d w danych miejsca udzielania \u015bwiadcze\u0144 zdrowotnych",
        description: "Przyczyna o kodzie \"M\" ustawiana w przypadku b\u0142\u0119dnych danych miejsca udzielania" +
          " \u015bwiadcze\u0144 zdrowotnych"
      },
      {
        value: CancelZlaCodeType.I,
        label: "inny pow\u00f3d",
        description: "Przyczyna o kodzie \"I\" ustawiana w przypadku je\u015bli pow\u00f3d anulowania jest inny" +
          " ni\u017c dost\u0119pnie wymienionych"
      },
      {
        value: CancelZlaCodeType.B,
        label: "b\u0142\u0105d systemu",
        description: "Przyczyna o kodzie \"B\" ustawiana w przypadku pojawienia si\u0119 b\u0142\u0119du systemu"
      },
      {
        value: CancelZlaCodeType.A,
        label: "b\u0142\u0105d w danych adresowych ubezpieczonego",
        description: "Przyczyna o kodzie \"A\" ustawiana w przypadku b\u0142\u0119dnych danych adresowych" +
          " ubezpieczonego"
      },
      {
        value: CancelZlaCodeType.E,
        label: "b\u0142\u0119dne dane",
        description: "Przyczyna o kodzie \"E\" ustawiana w przypadku b\u0142\u0119dnych danych - przypadek" +
          " elektronizacji b\u0142\u0119dnie wype\u0142nionego ZLA"
      },
      {
        value: CancelZlaCodeType.X,
        label: "b\u0142\u0119dna data wystawienia",
        description: "Przyczyna o kodzie \"X\" ustawiana w przypadku b\u0142\u0119dnej dacie wystawienia ZLA - " +
          "przypadek elektronizacji b\u0142\u0119dnie wype\u0142nionego ZLA"
      }
    ].map(code => ({
      ...code,
      name: `${code.value} - ${code.label}`
    }));
  }

  private update(option: CancelZlaCode|null): void {
    this.$emit("input", option);
  }
}
