<template>
  <div class="loading-mask-container">
    <div
      v-if="showMask"
      :style="loadingMaskStyle"
      class="loading-mask"
    >
      <div class="loading-message">
        <loading-icon 
          :loading="loading"
          vertical
          text
        />
      </div>
    </div>
    <slot />
  </div>
</template>
<script>
import LoadingIcon from "./LoadingIcon";

export default {
  name: "LoadingMask",
  components: {LoadingIcon},
  props: {
    loading: {type: Boolean, required: true},
    animationTime: {type: Number, default: 700},
    animationDelay: {type: Number, default: 300},
  },
  data() {
    return {
      showMask: this.loading,
      maskTimeout: null,
    };
  },
  computed: {
    loadingMaskStyle() {
      return {
        opacity: this.loading ? 1 : 0,
        transition: `opacity ${this.animationTime}ms ease ${this.animationDelay}ms`,
      };
    }
  },
  watch: {
    loading(loading) {
      clearTimeout(this.maskTimeout);
      this.maskTimeout = setTimeout(() => {
        this.showMask = loading;
      }, loading ? 1 : this.animationTime + this.animationDelay);
    }
  },
}
</script>
<style scoped lang="scss">
@import "../../styles/variables";

.loading-mask-container {
  display: block;
  position: relative;
  min-height: 70px;
}

.loading-mask {
  position: absolute;
  z-index: $zindex-dropdown;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, .8);
}

.loading-message {
  text-align: center;
  position: absolute;
  top: 50%;
  margin-top: -30px;
  width: 100%;
}
</style>
