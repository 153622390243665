var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-box"},[_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col font-weight-bold"},[_vm._v(" Zwolnienia ")]),_c('div',[(!_vm.readOnly)?_c('b-btn',{attrs:{"size":"sm","variant":"success"},on:{"click":_vm.fetchData}},[_c('i',{staticClass:"fa fa-sync-alt"}),_vm._v(" Odśwież listę zwolnień ")]):_vm._e(),(!_vm.readOnly)?_c('is-granted',{attrs:{"privileges":['ZLA_GET_SESSION_ID'],"subject":_vm.appointmentDocumentId,"component":"fragment"}},[(!_vm.readOnly)?_c('b-btn',{directives:[{name:"b-modal",rawName:"v-b-modal",value:(_vm.zlaModalId),expression:"zlaModalId"}],staticClass:"ml-2",attrs:{"size":"sm","variant":"success"}},[_c('i',{staticClass:"fa fa-plus"}),_vm._v(" Wystaw e-ZLA ")]):_vm._e()],1):_vm._e(),(!_vm.readOnly)?_c('is-granted',{attrs:{"privileges":['CREATE_SICK_LEAVE'],"subject":_vm.appointmentDocumentId,"component":"fragment"}},[(!_vm.readOnly)?_c('b-btn',{directives:[{name:"b-modal",rawName:"v-b-modal",value:(_vm.sickLeaveModalId),expression:"sickLeaveModalId"}],staticClass:"ml-2",attrs:{"size":"sm","variant":"success"}},[_c('i',{staticClass:"fa fa-plus"}),_vm._v(" Wystaw zwolnienie ")]):_vm._e()],1):_vm._e()],1)]),(_vm.sickLeaveItems.length > 0)?_c('loading-mask',{staticClass:"form-row",attrs:{"loading":_vm.loading}},[_c('error-message',{attrs:{"errors":_vm.errors}}),_c('b-table',{attrs:{"fields":_vm.fields,"items":_vm.sickLeaveItems,"hover":"","responsive":true},scopedSlots:_vm._u([{key:"cell(recognitions)",fn:function(ref){
var recognitions = ref.item.recognitions;
return _vm._l((recognitions),function(item,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(item.icd10Code)+" "),(item.comments)?[_vm._v(" / "+_vm._s(item.comments)+" ")]:_vm._e()],2)})}},{key:"cell(status)",fn:function(ref){
var status = ref.item.status;
return [(status)?_c('b-badge',{staticClass:"status-badge",attrs:{"variant":_vm.getBadgeVariant(status)}},[_c('i18n',{attrs:{"component":"fragment","msgid":("@sick-leave-status-" + status)}})],1):_vm._e()]}},{key:"cell(serialNumber)",fn:function(ref){
var ref_item = ref.item;
var type = ref_item.type;
var serialNumber = ref_item.serialNumber;
return [(type === _vm.SickLeaveType.ZLA && serialNumber)?_c('div',[_vm._v(" "+_vm._s(serialNumber.series)+_vm._s(serialNumber.number)+" ")]):_vm._e()]}},{key:"cell(options)",fn:function(ref){
var ref_item = ref.item;
var fileId = ref_item.fileId;
var id = ref_item.id;
var status = ref_item.status;
var type = ref_item.type;
return [(fileId)?[_c('b-btn',{staticClass:"m-1",attrs:{"href":("/api/files/" + fileId),"size":"sm","variant":"primary","target":"_blank"}},[_c('span',{staticClass:"fa fa-print"}),_vm._v(" Drukuj ")])]:_vm._e(),(!_vm.readOnly
            && type === _vm.SickLeaveType.ZLA
            && status === _vm.SickLeaveStatus.CREATED)?_c('is-granted',{attrs:{"component":"fragment","privileges":['ZLA_GET_SESSION_ID'],"subject":_vm.appointmentDocumentId}},[_c('b-btn',{staticClass:"m-1",attrs:{"size":"sm","variant":"light"},on:{"click":function($event){return _vm.cancelZla(id)}}},[_c('span',{staticClass:"fa fa-trash"}),_vm._v(" Anuluj ")])],1):_vm._e()]}}],null,false,373836119)})],1):_vm._e(),(!_vm.readOnly)?_c('create-sick-leave',{attrs:{"modal-id":_vm.sickLeaveModalId,"patient-id":_vm.patientId,"appointment-document-id":_vm.appointmentDocumentId},on:{"created":_vm.fetchData}}):_vm._e(),(!_vm.readOnly)?_c('create-zla-modal',{attrs:{"appointment-id":_vm.appointmentId,"appointment-document-id":_vm.appointmentDocumentId,"modal-id":_vm.zlaModalId,"patient-id":_vm.patientId},on:{"saveCreate":_vm.saveCreate}}):_vm._e(),(!_vm.readOnly)?_c('cancel-zla-modal',{attrs:{"modal-id":_vm.cancelZlaModalId,"sick-leave-id":_vm.selectedId},on:{"cancelled":_vm.onZlaCancel}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }