const noParallel = <R = void, A = any>() => {
  let promise: Promise<R>|null = null;
  return (callback: (...args: A[]) => Promise<R>)  => {
    return async (...args: A[]): Promise<R> => {
      try {
        await promise;
      } catch (e) {
        console.error(e);
      }
      promise = new Promise(async (resolve, reject) => {
        try {
          const result = await callback(...args);
          resolve(result);
        } catch (e) {
          reject(e);
        }
      });

      return await promise;
    }
  }
}

export default noParallel;
