<template>
  <card
    :title="title"
    :loading="loading"
    :description="description"
  >
    <pagination-description
      :items-count="items.length"
      :page="page"
      :per-page="pagination.perPage"
      :total-count="pagination.totalRows"
    />
    <appointment-table
      :items="items"
      :columns="columns"
      :patient-id="patientId"
      :show-details="showDetails"
      :go-to-appointment="goToAppointment"
      :order-desc="orderDesc"
      :empty-text="emptyText"
      :display-unreported-treatments="true"
      @appointmentUpdated="loadItems"
    />
    <pagination-description
      :items-count="items.length"
      :page="page"
      :per-page="pagination.perPage"
      :total-count="pagination.totalRows"
    >
      <b-pagination
        v-model="page"
        :total-rows="pagination.totalRows"
        :per-page="pagination.perPage"
        align="right"
        class="mb-0"
      />
    </pagination-description>
    <template #control>
      <slot name="control" />
    </template>
  </card>
</template>

<script>
import {mapState} from "vuex";
import Card from "../Card";
import AppointmentTable from "./AppointmentTable";
import read from "../../rest/read";
import parseDate from "../../utils/date/parseDate";
import PaginationDescription from "../Pagination/PaginationDescription";
import {isGranted} from "../../security/isGranted";

export default {
  name: "AppointmentsCard",
  components: {PaginationDescription, AppointmentTable, Card},
  props: {
    title: {type: String, required: true},
    description: {type: String, default: null},
    columns: {type: Array, required: true},
    patientId: {type: String, default: null},
    filters: {type: Object, default: () => ({})},
    showDetails: {type: Boolean, default: false},
    goToAppointment: {type: Boolean, default: false},
    paginationData: {type: Object, default: ()=>({perPage: 5, totalRows: 0})},
    orderDesc: {type: Boolean, default: null},
    emptyText: {type: String, default: "Brak umówionych wizyt"},
  },
  data() {
    return {
      loading: false,
      page: 1,
      pagination: this.paginationData,
      items: [],
    }
  },
  computed: {
    ...mapState("currentUser", ["user"]),
  },
  watch: {
    page() {
      this.loadItems();
    }
  },
  mounted() {
    this.loadItems();
  },
  methods: {
    async loadItems() {
      this.loading = true;
      this.items = [];
      const workerIds = await this.getWorkerIds();
      const {items, pagination} = await read("/api/appointments", {
        workerIds,
        ...this.filters,
        page: this.page,
        perPage: this.pagination.perPage,
      });
      this.items = items.map((item) => this.processItem(item));
      this.pagination = pagination;
      this.loading = false;
    },
    processItem(item) {
      return {
        ...item,
        date: parseDate(item.date),
      };
    },
    async getWorkerIds() {
      if (!this.filters.hasOwnProperty("workerIds")) {
        return await isGranted(["ROLE_RECEPTION", "ROLE_ADMIN"]) ? null : [this.user.workerId];
      }
      return this.filters.workerIds;
    },
  },
}
</script>

