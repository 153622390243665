<template>
  <div>
    <card
      :loading="loading"
      title="Asystenci Zdrowienia"
    >
      <template #control>
        <is-granted
          :privileges="['ADD_RECOVERY_ASSISTANT']"
        >
          <b-btn
            :disabled="loading"
            variant="primary"
            class="btn-sm"
            @click="openModal()"
          >
            <i class="fa fa-pencil-alt" />
          </b-btn>
        </is-granted>
      </template>
      <p
        v-for="member in recoveryAssistants"
        :key="member.id"
      >
        {{ member.workerName }}
      </p>
      <p v-if="recoveryAssistants.length < 1">
        Brak
      </p>
    </card>
    <b-modal
      id="recoveryAssitantsModal"
      :visible="modalVisible"
      hide-footer
      no-close-on-backdrop
      title="Edycja asystentów zdrowienia"
      title-tag="h3"
      size="lg"
      @hide="closeModal"
    >
      <error-message
        :errors="errors"
        class="m-t-20"
      />
      <form>
        <loading-mask :loading="loading">
          <worker-select
            v-model="newRecoveryAssistant"
            :clearable="false"
            :roles="['ROLE_RECOVERY_ASSISTANT']"
            @input="addWorker"
            @open="clearActionMessage"
          />
          <transition name="fade">
            <div
              v-if="actionMessage"
              :class="actionMessage.class"
              class="mt-2"
            >
              {{ actionMessage.text }}
            </div>
          </transition>
          <div class="card m-t-20">
            <b-table
              :fields="fields"
              :items="recoveryAssistants"
              empty-text="Brak asystentów zdrowienia"
              hover
              responsive
              show-empty
            >
              <template #cell(deleteRow)="row">
                <b-button
                  class="btn-sm"
                  @click="remove(row.item)"
                >
                  <i class="fa fa-trash" />
                </b-button>
              </template>
            </b-table>
          </div>
        </loading-mask>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            @click="closeModal"
          >
            Zamknij
          </button>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import create from "../../../rest/create";
import remove from "../../../rest/remove";
import read from "../../../rest/read";
import WorkerSelect from "../../Worker/WorkerSelect";
import processResponseException from "../../../utils/errors/processResponseException";
import Card from "../../Card";
import {errorsMixin} from "../../../mixins/errorsMixin.js";
import modify from "../../../rest/modify";
import LoadingMask from "../../Loading/LoadingMask";
import ErrorMessage from "../../Form/ErrorMessage";
import IsGranted from "../../IsGranted";

export default {
  name: "PatientCardRecoveryAssistants",
  components: {
    IsGranted,
    ErrorMessage,
    LoadingMask,
    Card,
    WorkerSelect,
  },
  mixins: [errorsMixin],
  props: {
    patientId: {type: String, required: true}
  },
  data() {
    return {
      recoveryAssistants: [],
      loading: false,
      modalVisible: false,
      newRecoveryAssistant: null,
      actionMessage: null,
    };
  },
  computed: {
    fields() {
      return [
        {key: "workerName", label: "Specjalista"},
        {key: "deleteRow", label: "Usuń"},
      ];
    }
  },
  watch: {
    async patientId() {
      await this.fetchData();
    },
  },
  async mounted() {
    await this.fetchData();
  },
  methods: {
    async fetchData() {
      this.loading = true;
      try {
        await this.getRecoveryAssistants();
      } catch(e) {
        this.errors = processResponseException(e);
      }
      this.loading = false;
    },
    async addWorker(worker) {
      if (!worker) {
        return;
      }
      this.loading = true;
      this.errors = [];

      const duplicated = null != this.recoveryAssistants
        .find((member) => member.workerId === worker.workerId);

      if (duplicated) {
        this.actionMessage = {
          class: "text-danger",
          text: `Specjalista ${worker.name} ${worker.surname} znajduje się już na liście`,
        };
        this.loading = false;
        return;
      }
      try {
        await create(`/api/recovery-assistants/${this.patientId}`, {
          workerId: worker.workerId,
        });
        this.recoveryAssistants.push({
          workerName: `${worker.name} ${worker.surname}`,
          workerId: worker.workerId,
        });
        this.actionMessage = {
          class: "text-success",
          text: `Specjalista ${worker.name} ${worker.surname} został dodany do sieci wsparcia`,
        };
      } catch (exception) {
        this.errors = processResponseException(exception);
      }
      this.loading = false;
    },
    async getRecoveryAssistants(){
      const response = await read(`/api/recovery-assistants/${this.patientId}`);
      this.recoveryAssistants = response.items;
    },
    closeModal() {
      this.modalVisible = false;
    },
    openModal() {
      this.modalVisible = true;
      this.newRecoveryAssistant = null;
      this.errors = [];
      this.clearActionMessage();
    },
    async remove(worker) {
      this.loading = true;
      this.errors = [];
      try {
        await remove(`/api/recovery-assistants/${this.patientId}`, {
          workerId: worker.workerId,
        });
        this.recoveryAssistants = this.recoveryAssistants.filter(member => member.workerId !== worker.workerId);
        this.actionMessage = {
          class: "text-success",
          text: `Specjalista ${worker.workerName} został usunięty z listy asystentów zdrowienia`,
        };
      } catch (exception) {
        this.errors = processResponseException(exception);
      }
      this.loading = false;
    },
    clearActionMessage() {
      this.actionMessage = null;
    },
  },
};
</script>


<style scoped>
  .card-box {
    overflow: hidden;
  }
  .collapsed > .when-opened,
  :not(.collapsed) > .when-closed {
    display: none;
  }
</style>
