<template>
  <div>
    <div
      v-for="(day, dayIndex) in itemsByDay"
      :key="dayIndex"
    >
      <h4 class="text-center font-weight-bold">
        {{ day.label }}
      </h4>
      <appointment-box
        v-if="!loading"
        :items="day.items"
        @updateAppointmentList="getAppointmentsList"
      />
    </div>
  </div>
</template>

<script>
import AppointmentBox from "@/components/Appointment/AppointmentBox/AppointmentBox";
export default {
  name: "AppointmentList",
  components: {AppointmentBox},
  props: {
    itemsByDay: {type: Array, default: ()=>[]},
    loading: {type: Boolean, default: false},
  },
  methods: {
    getAppointmentsList() {
      this.$emit("refresh");
    },
  },
}
</script>

<style scoped>

</style>
