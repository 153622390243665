<template>
  <div class="card-box">
    <form
      class="row"
      @submit.prevent="submit"
    >
      <b-form-group
        class="col-lg-6 col-xl-3"
        label="Wybrany dzień"
      >
        <date-picker
          v-model="datePickerDate"
        />
      </b-form-group>
      <b-form-group
        class="col-lg-6 col-xl-3"
        label="Komórka"
      >
        <branch-select
          v-model="branch"
          :check-if-any-branch-exists="!!branch"
        />
      </b-form-group>
      <b-form-group
        class="col-lg-6 col-xl-3"
        label="Typ pokoju"
      >
        <room-type-select
          v-model="type"
          clearable
        />
      </b-form-group>
      <b-form-group
        class="col-lg-6 col-xl-3"
        label="Numer pokoju"
      >
        <b-input
          v-model="number"
        />
      </b-form-group>
      <b-form-group
        class="col-lg-6 col-xl-3"
        label="Piętro"
      >
        <b-input
          v-model="floor"
        />
      </b-form-group>
      <b-form-group
        class="col-lg-6 col-xl-3"
        label="Opis pokoju"
      >
        <b-input
          v-model="description"
        />
      </b-form-group>
      <b-form-group
        class="col text-right"
      >
        <button class="btn btn-primary m-t-30">
          <i class="fa fa-search" /> Szukaj
        </button>
      </b-form-group>
    </form>
    <div class="d-flex justify-content-between align-items-start flex-nowrap mb-3">
      <button
        class="btn btn-secondary"
        @click="previousDay"
      >
        <i class="fas fa-backward" />
        Poprzedni dzień
      </button>
      <button
        class="btn btn-secondary"
        @click="nextDay"
      >
        Następny dzień
        <i class="fas fa-forward" />
      </button>
    </div>
  </div>
</template>

<script>

import DatePicker from "../Form/DatePicker/DatePicker";
import parseDate from "../../utils/date/parseDate";
import moveDateByDays from "../../utils/moveDateByDays";
import stringifyDate from "../../utils/date/stringifyDate";
import {updatePageQuery} from "../../utils/pageUrl/handlePageQuery";
import isEqual from "lodash/isEqual";
import BranchSelect from "../Branch/BranchSelect";
import RoomTypeSelect from "../Rooms/RoomTypeSelect";

export default {
  name: "RoomOccupancyFilters",
  components: {RoomTypeSelect, BranchSelect, DatePicker},
  props: {
    filters: {type: Object, default: ()=>({})},
  },
  data() {
    const date = this.filters.start ? parseDate(this.filters.start) : new Date();
    date.setHours(0, 0, 0, 0);

    return {
      startDate: date,
      datePickerDate: date,
      branch: this.filters.branch,
      type: this.filters.type,
      number: this.filters.number,
      description: this.filters.description,
      floor: this.filters.floor,
    };
  },
  computed: {
    startDateString() {
      return stringifyDate(this.startDate);
    },
  },
  watch: {
    "filters.start"(val) {
      const date = val ? parseDate(val) : new Date();
      date.setHours(0, 0, 0, 0);
      this.startDate = date;
      this.datePickerDate = date;
    },
    "filters.branch"(val) {
      this.branch = val;
    },
    "filters.type"(val) {
      this.type = val;
    },
    "filters.number"(val) {
      this.number = val;
    },
    "filters.description"(val) {
      this.description = val;
    },
    "filters.floor"(val) {
      this.floor = val;
    },
  },
  methods: {
    submit() {
      this.startDate = this.datePickerDate;
      this.updateSchedule();
    },
    async updateSchedule() {
      this.datePickerDate = this.startDate;

      const query = {
        start: this.startDateString,
        branch: this.branch || undefined,
        type: this.type || undefined,
        number: this.number || undefined,
        description: this.description || undefined,
        floor: this.floor || undefined,
      };
      if (isEqual(this.$route.query, query)) {
        this.$emit("reload");
        return;
      }
      await updatePageQuery(query);
    },
    async previousDay() {
      this.startDate = moveDateByDays(this.startDate, -1);
      await this.updateSchedule()
    },
    async nextDay() {
      this.startDate = moveDateByDays(this.startDate, 1);
      await this.updateSchedule()
    },
  }
}
</script>
