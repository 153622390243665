























































import {Component, Mixins} from "vue-property-decorator";
import ErrorsMixin from "../../mixins/ErrorsMixin";
import update from "../../rest/update";
import read from "../../rest/read";
import processResponseException from "../../utils/errors/processResponseException";
import {mapState} from "vuex";
import ErrorMessage from "../Form/ErrorMessage.vue";
import ReimbursementContractForm from "./ReimbursementContractForm.vue";
import LoadingMask from "../Loading/LoadingMask.vue";
import {RootState} from "../../store/types";

interface Contract {
  branchId: string|null;
  pkcs12FileId: string|null;
  typeSuffix: string|null;
  contractNumber: string|null;
  hasContract?: boolean;
}

const nfzContractInitialState = {
  branchId: null,
  pkcs12FileId: null,
  typeSuffix: null,
  contractNumber: null,
}
const allowedProfessions = ["doctor", "nurse", "physiotherapist"];

@Component({
  name: "NfzContractSettings",
  components: {LoadingMask, ReimbursementContractForm, ErrorMessage},
  computed: {
    ...mapState({
      patientAnonymous: (state: RootState): boolean => state.clinicParameters!.parameters!.patientAnonymous,
    })
  }
})
export default class NfzContractSettings extends Mixins(ErrorsMixin) {

  private loading: boolean = false;
  private mainProfession: string|null = null;
  private professionType: string|null = null;
  private professionNumber: string|null = null;
  private savedNfzContract: Contract = {...nfzContractInitialState};
  private nfzContract: Contract = {...nfzContractInitialState};

  private get canHaveNfzContract(): boolean {
    if (this.patientAnonymous) {
      return false;
    }

    if (!this.mainProfession || !allowedProfessions.includes(this.mainProfession)) {
      return false;
    }

    return !!this.professionNumber && "pwz_number" === this.professionType;
  }

  private async loadData(): Promise<void> {
    this.loading = true;
    try {
      const {
        mainProfession,
        professionNumber,
        professionType,
      } = await read("/api/worker/professionData");
      this.mainProfession = mainProfession;
      this.professionNumber = professionNumber;
      this.professionType = professionType;
      if (this.canHaveNfzContract) {
        const {branchId, pkcs12FileId, typeSuffix, contractNumber} = await read("/api/worker/reimbursementContract");
        const contract = {
          branchId,
          pkcs12FileId,
          typeSuffix,
          contractNumber,
          hasContract: !!(branchId || pkcs12FileId || typeSuffix || contractNumber),
        };
        this.savedNfzContract = contract;
        this.nfzContract = contract;
      }
    } catch(e) {
      this.errors = processResponseException(e);
    }
    this.loading = false;
  }

  private clearErrors(): void {
    this.errors = [];
  }

  private close(): void {
    this.$bvModal.hide("nfz-contract-settings");
  }

  private onHide():void {
    this.nfzContract = this.savedNfzContract;
    this.clearErrors();
  }

  private async submit(): Promise<void> {
    this.clearErrors();
    this.loading = true;
    try {
      const data: Contract = {
        ...this.nfzContract,
        hasContract: undefined,
      };
      await update<Contract>("api/worker/reimbursementContract", data);
      this.savedNfzContract = this.nfzContract;
      this.close();
    } catch(e) {
      this.errors = processResponseException(e);
    }
    this.loading = false;
  }
}
