<template>
  <div class="card-box">
    <div class="row">
      <div class="col">
        <h4>Osoby powiązane</h4>
      </div>
      <is-granted
        :privileges="['SET_PERSONS_ELIGIBLE_TO_PATIENT']"
        component="div"
        class="col"
      >
        <router-link
          :to="{name: `viewEligiblePersons`, params: {patientId}}"
          class="btn btn-primary btn-sm float-right m-b-10"
        >
          <i class="fa fa-plus" />
          Dodaj / Usuń osobę
        </router-link>
      </is-granted>
    </div>
    <persons-eligible-table
      :patient-id="patientId"
    />
  </div>
</template>

<script>
import IsGranted from "../../../IsGranted";
import PersonsEligibleTable from "./EligiblePersonsTable";

export default {
  components: {
    PersonsEligibleTable,
    IsGranted,
  },
  props: {
    patientId: {type: String, required: true},
  },
}
</script>
