<template>
  <div>
    <eligible-person-type-select
      :disabled="disabled"
      :state="state('eligiblePerson')"
      :errors="errors"
      class="my-3"
      :clearable="false"
      @input="addEligiblePerson"
    />
    <error-message
      :root="true"
      :errors="getSubErrors('eligiblePerson')"
      field="type"
    />
    <transition-group
      name="fade"
    >
      <eligible-person-form
        v-for="(person, index) in eligiblePersons"
        :key="person.id"
        :person="person"
        :disabled="disabled"
        :with-address="withAddress"
        :errors="getErrorsEligiblePerson(index)"
        :form-expanded="formExpanded"
        :optional-pesel="optionalPesel"
        :index="index"
        :multiple="multiple"
        class="eligible-persons-form mt-4"
        @updateEligiblePerson="updateEligiblePerson($event, index)"
        @removeEligiblePerson="removeEligiblePerson($event, index)"
        @addPatientAddress="addPatientAddress($event)"
      />
    </transition-group>
  </div>
</template>

<script>
import EligiblePersonTypeSelect from "./EligiblePersonTypeSelect";
import EligiblePersonForm from "./EligiblePersonForm";
import subErrors from "../../utils/errors/subErrors";
import {generateUuid} from "../../utils/uuid/generateUuid";
import ErrorMessage from "../Form/ErrorMessage";
const addressInitialData = {
  country: "PL",
};
export default {
  components: {
    ErrorMessage,
    EligiblePersonForm,
    EligiblePersonTypeSelect,
  },
  props: {
    value: {type: Array, default: () => []},
    disabled: {type: Boolean, default: false},
    withAddress: {type: Boolean, default: true},
    errors: {type: Array, default: () => []},
    formExpanded: {type: Boolean, default: false},
    optionalPesel: {type: Boolean, default: false},
    multiple: {type: Boolean, default: true},
  },
  data() {
    return {
      eligiblePersons: this.value,
      visibleErrors: this.errors,
    };
  },
  watch: {
    value() {
      this.eligiblePersons = this.value;
    },
    errors() {
      this.visibleErrors = this.errors;
    }
  },
  methods: {
    getErrorsEligiblePerson(index) {
      const subErrorField = this.multiple
        ? `eligiblePerson[${index}]`
        : "eligiblePerson";

      return this.getSubErrors(subErrorField);
    },
    addEligiblePerson(person) {
      if(this.multiple || this.eligiblePersons.length === 0) {
        const eligiblePerson = {
          id: generateUuid(),
          type: person.value,
          address : this.withAddress ? {...addressInitialData} : null
        };
        this.eligiblePersons = [eligiblePerson].concat(this.eligiblePersons);
      } else {
        if(person !== null) {
          this.eligiblePersons[0].type = person.value;
        }
      }
      this.updateVisibleErrors()
      this.$emit("input", this.eligiblePersons);
    },
    addPatientAddress(event){
      this.$emit("addPatientAddress", event);
    },
    updateEligiblePerson(event, index) {
      this.eligiblePersons = this.eligiblePersons.map((person, idx) => (idx === index) ? event : person);
      this.$emit("input", this.eligiblePersons);
    },
    removeEligiblePerson(event, index) {
      this.eligiblePersons = this.eligiblePersons.filter((person, idx) => idx !== index);
      this.updateVisibleErrors(index)
      if(this.eligiblePersons.length === 1 && this.eligiblePersons[0].type === "") {
        this.eligiblePersons = [];
      }
      this.$emit("input", this.eligiblePersons);
    },
    getSubErrors(field) {
      return subErrors(this.visibleErrors, field);
    },
    state(field) {
      return this.visibleErrors.find((error) => error.field === field) ? false : null;
    },
    updateVisibleErrors (idToRemove = null) {
      const removedEligiblePersonErrorRegExp = new RegExp(`^eligiblePerson\\[${idToRemove}]`);
      const eligiblePersonErrorsRegExp = /(^eligiblePerson\[)(\d)(].*$)/;

      this.visibleErrors = this.visibleErrors.reduce((errorArr, currentError) => {
        if (removedEligiblePersonErrorRegExp.test(currentError.field)) {
          return errorArr
        } else {
          const field = currentError.field.replace(eligiblePersonErrorsRegExp, (match, p1, p2, p3) => {
            const currentIdx = Number(p2);
            let newId = currentIdx;
            if (idToRemove === null) { // when ADD eligible person
              newId++;
            } else if (idToRemove < currentIdx) { // when REMOVE person and that idx is lower than currentError idx
              newId--;
            }

            return `${p1}${newId}${p3}`;
          });

          return errorArr.concat([
            {
              ...currentError,
              field
            }
          ]);
        }
      }, []);
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../../styles/variables";

  .eligible-persons-form {
    border-bottom: 1px solid $dark;

    &:last-of-type {
      border: none;
    }
  }
</style>
