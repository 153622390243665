












































































import {Component, Prop} from "vue-property-decorator";
import ErrorsMixin from "../../../mixins/ErrorsMixin.ts";
import {mixins} from "vue-class-component";
import ErrorMessage from "../../Form/ErrorMessage.vue";
import TargetStatusSelect from "./TargetStatusSelect.vue";
import {generateUuid} from "../../../utils/uuid/generateUuid";
import create from "../../../rest/create";
import processResponseException from "../../../utils/errors/processResponseException";
import ResizableTextarea from "../../Form/Textarea/ResizableTextarea.vue";
import type {TargetFormData} from "../../../types/TargetsAndTasks";

@Component({
  name: "CreateTaskModal",
  components: {ResizableTextarea, TargetStatusSelect, ErrorMessage},
})

export default class CreateTaskModal extends mixins(ErrorsMixin) {
  @Prop({type: String, required: true}) readonly ipzId!: string;
  @Prop({type: String, required: true}) readonly targetId!: string;

  loading: boolean = false;
  name: TargetFormData["name"]|null = null;
  description: TargetFormData["description"]|null = null;
  status: TargetFormData["status"]|null = null;


  async submit(): Promise<void> {
    this.resetErrors();
    this.loading = true;
    try {
      const data = {
        taskId: generateUuid(),
        name: this.name ? this.name.trim() : this.name,
        description: this.description ? this.description.trim() : this.description,
        status: this.status,
      };
      await create(`/api/targets/${this.targetId}/tasks`, data);
      this.$emit("created");
      this.closeModal();
    } catch (e) {
      this.errors = processResponseException(e);
    }
    this.loading = false;
  }

  resetErrors(): void {
    this.errors = [];
  }

  resetData(): void {
    this.resetErrors();
    this.name = null;
    this.description = null;
    this.status = null;
  }

  closeModal(): void {
    this.$bvModal.hide("create-task-modal");
  }
}
