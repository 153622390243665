

























































































































































































import Vue from "vue";
import {Component, Prop, Watch} from "vue-property-decorator";
import Card from "../../Card.vue";
import ContinuousStayInformationCardEdit
  from "../../Patient/PatientCard/ContinuousStay/ContinuousStayInformationCardEdit.vue";
import TextareaCounter from "../../Form/Textarea/TextareaCounter.vue";
import ErrorMessage from "../../Form/ErrorMessage.vue";
import TreatmentTypeName from "../../TreatmentType/TreatmentTypeName.vue";
import WorkerTreatmentTypes from "../../Worker/WorkerTreatmentTypes.vue";
import read from "../../../rest/read";
import processResponseException from "../../../utils/errors/processResponseException";
import ModuleErrorMessage from "../../Form/ModuleErrorMessage.vue";
import {Error} from "../../../rest";
import type {ContinuousStayInformationCardModuleState, InformationCardData,} from "../../../types/InformationCard";
import Tooltip from "../../Tooltip.vue";
import DATE_FORMAT from "../../../utils/date/DATE_FORMAT";
import stringifyDate from "../../../utils/date/stringifyDate";
import Icd10CodeSelect from "../../Form/Select/Icd10CodeSelect.vue";
import type {Icd10Option} from "../../../types/IcdCodes";
import {code2option} from "../../../utils/icd10Code/code2option";

@Component({
  name: "ContinuousStayInformationCardModule",
  components: {
    Icd10CodeSelect,
    Tooltip,
    ModuleErrorMessage,
    WorkerTreatmentTypes,
    TreatmentTypeName,
    ErrorMessage,
    TextareaCounter,
    ContinuousStayInformationCardEdit,
    Card,
  },
})
export default class ContinuousStayInformationCardModule extends Vue {
  @Prop({type: Number, required: true}) readonly idx!: number;
  @Prop({type: Object, required: true}) readonly module!: ContinuousStayInformationCardModuleState;
  @Prop({type: String, default: null}) readonly continuousStayId!: string | null;
  @Prop({type: Boolean, required: true}) readonly readOnly!: boolean;

  private loading: boolean = false;
  loadErrors: Error[] = [];
  private reason: string = this.module.reason || "";
  private startState: string = this.module.startState || "";
  private stayDescription: string = this.module.stayDescription || "";
  private recognitions: Array<Icd10Option> = this.module.recognitions.map(code2option) || [];
  sessions: string[] = this.module.sessions || [];
  private administeredDrugs: string = this.module.administeredDrugs || "";
  private leaveState: string = this.module.leaveState || "";
  private recommendations: string = this.module.recommendations || "";
  private forceUpdate: boolean = false;
  private updatedAt: string|null = this.module.updatedAt;

  @Watch("continuousStayId", {immediate: true})
  async onContinuousStayChange(newVal: string|null, oldVal: string|null): Promise<void> {
    this.forceUpdate = false;
    if (newVal && newVal !== oldVal) {
      await this.fetchData();
    } else if (!newVal) {
      this.clearData();
    }
  }

  private async fetchData() {
    this.loadErrors = [];
    this.loading = true;
    try {
      const {
        reason,
        startState,
        stayDescription,
        recognitions,
        sessions,
        administeredDrugs,
        leaveState,
        recommendations,
        updatedAt,
      } = await read<InformationCardData>(`/api/continuous-stays/${this.continuousStayId}/information-card`);
      this.reason = reason || "";
      this.startState = startState || "";
      this.stayDescription = stayDescription || "";
      this.recognitions = recognitions.map(code2option) || [];
      this.sessions = sessions || [];
      this.administeredDrugs = administeredDrugs || "";
      this.leaveState = leaveState || "";
      this.recommendations = recommendations || "";
      this.updatedAt = updatedAt || stringifyDate(new Date(), DATE_FORMAT.DEFAULT);
      this.updateModule();
    } catch(e) {
      this.loadErrors = processResponseException();
    }
    this.loading = false;
  }

  private update($event: string|string[], field: string): void {
    const module: ContinuousStayInformationCardModuleState = {
      ...this.module,
      forceUpdate: false,
      [field]: $event,
    };
    this.$emit("input", module);
  }

  private updateModule(): void {
    const module = {
      ...this.module,
      reason: this.reason,
      startState: this.startState,
      stayDescription: this.stayDescription,
      recognitions: this.recognitions.map(({code, description}) => ({code, description})),
      sessions: this.sessions,
      administeredDrugs: this.administeredDrugs,
      leaveState: this.leaveState,
      recommendations: this.recommendations,
      forceUpdate: this.forceUpdate,
      continuousStayId: this.continuousStayId,
      updatedAt: this.updatedAt,
    }
    this.$emit("input", module);
  }

  private clearData(): void {
    this.forceUpdate = false;
    this.loading = false;
    this.reason = "";
    this.startState = "";
    this.stayDescription = "";
    this.recognitions = [];
    this.sessions = [];
    this.administeredDrugs = "";
    this.leaveState = "";
    this.recommendations = "";
    this.updatedAt = null;
    this.updateModule();
  }

  private state(field: string): boolean|null {
    return (this.module.errors && this.module.errors[field] ? false : null);
  }
}
