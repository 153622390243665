<template>
  <div>
    <div class="page-title-box">
      <h4 class="page-title">
        {{ $route.meta.title }}
        <template v-if="workerFullName">
          - {{ workerFullName }}
        </template>
      </h4>
      <div class="clearfix" />
    </div>
    <last-edited-appointment />
    <b-tabs>
      <b-tab
        title="Kreator grafiku tygodniowego"
        active
      >
        <weekly-working-hours-form
          :worker-id="workerId"
          @rangeSubmitted="getWorkerWorkingHoursList()"
        />
      </b-tab>
      <b-tab title="Dodaj zakres - widok zaawansowany">
        <worker-working-hours-form
          :worker-id="workerId"
          @rangeSubmitted="getWorkerWorkingHoursList()"
        />
      </b-tab>
      <b-tab
        title="Nadpisywanie grafiku"
      >
        <overriding-rule-form
          :worker-id="workerId"
          @rangeSubmitted="getWorkerWorkingHoursList()"
        />
      </b-tab>
    </b-tabs>
    <div class="card-box">
      <worker-working-hours-list
        :worker-id="workerId"
        :recurring-ranges-list="recurringRangesList"
        :ranges-list="rangesList"
        @rangeDeleted="getWorkerWorkingHoursList()"
      />
      <transition name="fade">
        <div
          v-if="errorGettingWorkingHoursList"
          class="alert alert-danger alert-dismissible"
        >
          <button
            type="button"
            class="close"
            @click="errorGettingWorkingHoursList = false"
          >
            <span>&times;</span>
          </button>
          {{ errorMessage }}
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import WorkerWorkingHoursForm from "../../components/Worker/WorkingHours/WorkerWorkingHoursForm.vue";
import WeeklyWorkingHoursForm from "../../components/Worker/WorkingHours/WeeklyWorkingHoursForm.vue";
import WorkerWorkingHoursList from "../../components/Worker/WorkingHours/WorkerWorkingHoursList.vue";
import read from "../../rest/read";
import processResponseException from "../../utils/errors/processResponseException";
import OverridingRuleForm from "../../components/Worker/WorkingHours/OverridingRuleForm";
import LastEditedAppointment from "../../components/Appointment/LastEditedAppointment";

export default {
  name: "WorkerWorkingHours",
  components: {
    LastEditedAppointment,
    OverridingRuleForm,
    WorkerWorkingHoursForm,
    WeeklyWorkingHoursForm,
    WorkerWorkingHoursList,
  },
  props: {
    workerId: {type: String, required: true},
  },
  data() {
    return {
      recurringRangesList: [],
      rangesList: [],
      errorGettingWorkingHoursList: false,
      errorMessage: "",
      workerFullName: null,
    }
  },
  async mounted() {
    const {workerFullName} = await read(`/api/workers/${this.workerId}/working-hours-page`);
    this.workerFullName = workerFullName;
    this.getWorkerWorkingHoursList();
  },
  methods: {
    async getWorkerWorkingHoursList() {
      try {
        const data = await read(`/api/workers/${this.workerId}/working-hours`);
        this.recurringRangesList = data.recurringRanges;
        this.rangesList = data.ranges;
      } catch (exception) {
        this.errorGettingWorkingHoursList = true;
        const errors = processResponseException(exception);
        this.errorMessage = errors.map(error => error.message).join();
      }
    },
  },
}
</script>
