import getPushNotificationPermissionState from "./getPushNotificationPermissionState";
import getPushNotificationSubscription from "./getPushNotificationSubscription";
import subscribePushNotification from "./subscribePushNotification";
import {generateUuid} from "../utils/uuid/generateUuid";
import create from "../rest/create";

const getSubscription = async () => {
  const permissionState = await getPushNotificationPermissionState();

  switch (permissionState) {
    case "prompt":
      return subscribePushNotification();
    case "granted": {
      let subscription = getPushNotificationSubscription();
      //in spite of status "granted" browser can falsely return "null"
      if (!subscription) {
        subscription = await subscribePushNotification();
      }
      return subscription;
    }
    default:
      return null;
  }
};

const withRegister = (retrieveSubscription) => async () => {
  const subscription = await retrieveSubscription();
  if (subscription) {
    const subscriptionId = generateUuid();
    await create(`/api/web-pushes/subscriptions/${subscriptionId}`, subscription);
  }
  return subscription;
};

export default withRegister(getSubscription);
