













import Vue from "vue";
import {Component, Prop, Ref} from "vue-property-decorator";
import RegistrationSearchFilters from "../../components/RegistrationSearch/RegistrationSearchFilters.vue";
import RegistrationSearchWidget from "../../components/RegistrationSearch/RegistrationSearchWidget.vue";
import moveDateByDays from "../../utils/moveDateByDays";

const getStartDate = (): Date => {
  const date = new Date();
  date.setHours(0, 0, 0, 0);
  return date;
}

@Component({
  name: "RegistrationSearch",
  components: {RegistrationSearchWidget, RegistrationSearchFilters}
})
export default class RegistrationSearch extends Vue {
  @Prop({type: Date, default: null}) readonly start!: Date;
  @Prop({type: Date, default: null}) readonly end!: Date;
  @Prop({type: Array, default: () => []}) readonly workers!: Array<string>;

  @Ref("widget") readonly widget!: RegistrationSearchWidget;

  private get startDate(): Date {
    return this.start || getStartDate();
  }

  private get endDate(): Date {
    return this.end || this.getEndDate();
  }

  private getEndDate(): Date {
    return this.end || moveDateByDays(this.startDate, 7);
  }

  private async fetchData(): Promise<void> {
    setTimeout(async () => {this.widget && await this.widget.fetchData()});
  }
}
