<template>
  <div>
    <p>Autoryzacja dwuetapowa</p>
    <button
      :class="btnClass"
      class="btn btn-sm w-100 d-block"
      @click="openAuthenticationMethodModal"
    >
      <i
        :class="multiFactorAuth ? 'fa-ban': 'fa-check'"
        class="fa"
      />
      {{ btnText }}
    </button>
    <b-modal
      :visible="modalOpened"
      :size="multiFactorAuth ? 'md' : 'lg'"
      hide-footer
      no-close-on-backdrop
      title="Zmiana metody autoryzacji"
      title-tag="h3"
      @hide="closeAuthenticationMethodModal"
    >
      <form @submit.prevent="submit">
        <p>{{ modalContent }}</p>
        <error-message
          :errors="errors"
        />
        <template v-if="!multiFactorAuth">
          <b-form-group label="Metoda autoryzacji dwuetapowej">
            <authentication-type-select
              :value="selectedType"
              :state="state('type')"
              @input="selectedTypeUpdate"
            />
            <error-message
              :errors="errors"
              field="type"
            />
          </b-form-group>
        </template>
        <transition name="fade">
          <div v-if="selectedType && selectedType.value === 'totp'">
            <div class="card-box">
              <h4 class="font-weight-bold">
                Instrukcja
              </h4>
              <p>
                Zainstaluj na swoim urządzeniu aplikację Google Authenticator dostępną w
                <a
                  href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
                  target="_blank"
                >
                  Google Play Store</a> lub w
                <a
                  href="https://apps.apple.com/us/app/google-authenticator/id388497605"
                  target="_blank"
                >
                  Apple App Store</a>.
              </p>
              <p>
                Poniżej znajduje się jednorazowy kod QR i jednorazowy klucz. Na podstawie kodu QR lub klucza
                będą generowane 6-cyfrowe kody weryfikacyjne.
              </p>
              <p>
                Zeskanuj kod QR lub przepisz klucz w aplikacji, a następnie przepisz otrzymany
                kod weryfikacyjny w pole formularza<br>
                Kod weryfikacyjny automatycznie zmienia się co 30 sekund.
              </p>
              <p>Przy następnym logowaniu do systemu podaj aktualnie wygenerowany kod z aplikacji</p>
              <p>
                Otrzymujesz 5 8-cyfrowych kodów zapasowych. Należy je zapisać na wypadek utraty urządzenia generującego
                kody weryfikacyjne. Kody nie będą dostępne po zamknięciu tego okna.
              </p>
            </div>
            <div class="row">
              <b-form-group
                class="col-md-6"
                label="Twój kod QR"
              >
                <!-- eslint-disable -->
                <div
                  class="qr-code"
                  v-html="qrCodeSvg"
                />
                <small>Kod QR należy zeskanować w aplikacji Google Authenticator</small>
              </b-form-group>
              <div class="col-md-6">
                <b-form-group label="Twój klucz">
                  <b-input
                    :value="formattedSecret"
                    :state="state('secret')"
                    readonly
                  />
                  <small>Klucz należy przekazać aplikacji Google Authenticator</small>
                  <error-message
                    :errors="errors"
                    field="secret"
                  />
                </b-form-group>
                <b-form-group
                  label="Twoje kody zapasowe"
                >
                  <b-input
                    v-for="recoveryCode in recoveryCodes"
                    :key="recoveryCode"
                    :value="recoveryCode"
                    readonly
                  />
                  <small>Kody należy zapisać w bezpiecznym miejscu.<br>
                    <span class="font-weight-bold">Po zamknięciu okna kody nie będą dostępne</span>
                  </small>
                </b-form-group>
              </div>
            </div>
          </div>
        </transition>
        <b-form-group label="Hasło logowania">
          <b-input
            v-model="password"
            :state="state('password')"
            type="password"
          />
          <error-message
            :errors="errors"
            field="password"
          />
        </b-form-group>
        <transition name="fade">
          <b-form-group
            v-if="selectedType && selectedType.value === 'totp'"
            label="Kod weryfikacyjny"
          >
            <b-input
              v-model="options.code"
              :state="state('code')"
              autocomplete="off"
            />
            <small>Kod wygenerowany przez aplikację Google Authenticator</small>
            <error-message
              :errors="errors"
              field="code"
            />
          </b-form-group>
        </transition>
        <footer class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            @click="closeAuthenticationMethodModal"
          >
            Zamknij
          </button>
          <button
            :disabled="loading || !password || !selectedType"
            type="submit"
            class="btn btn-primary"
          >
            <i
              :class="loading ? 'fa-spin fa-spinner' : 'fa-check'"
              class="fa"
            />
            Zmień
          </button>
        </footer>
      </form>
    </b-modal>
  </div>
</template>

<script>
import {mapState, mapActions} from "vuex";
import update from "../../rest/update";
import AuthenticationTypeSelect from "./AuthenticationTypeSelect";
import {errorsMixin} from "../../mixins/errorsMixin.js";
import ErrorMessage from "../Form/ErrorMessage";
import processResponseException from "../../utils/errors/processResponseException";


export default {
  components: {ErrorMessage, AuthenticationTypeSelect},
  mixins: [errorsMixin],
  data() {
    return {
      modalOpened: false,
      loading: false,
      options: {},
      selectedType: null,
      password: "",
      qr: null,
    };
  },
  computed: {
    ...mapState({
      multiFactorAuth: state => state.currentUser.user.settings.multiFactorAuth,
    }),
    btnText() {
      return this.multiFactorAuth ? "Wyłącz" : "Włącz"
    },
    formattedSecret() {
      const {secret} = this.options;
      if (!secret) {
        return "";
      }
      return secret.toLowerCase().match(/(.{4})/g).join(" ");
    },
    recoveryCodes() {
      const {recovery} = this.options;
      if (!recovery) {
        return [];
      }
      return recovery.map(code => code.match(/(.{4})/g).join(" "));
    },
    qrCodeSvg() {
      if (this.options && this.options.secret && this.qr) {
        return this.qr.imageSync(`otpauth://totp/Auxilio?secret=${this.options.secret}`, {type: "svg"});
      }
      return null;
    },
    btnClass() {
      return this.multiFactorAuth ? "btn-danger" : "btn-success";
    },
    modalContent() {
      return this.multiFactorAuth
        ? "Aby wyłączyć autoryzację dwuetapową, podaj hasło"
        : "Aby włączyć autoryzację dwuetapową, wybierz typ autoryzacji i podaj hasło:";
    }
  },
  watch: {
    multiFactorAuth() {
      this.selectedType = this.multiFactorAuth ? {value: "none"} : null;
    },
  },
  mounted() {
    this.selectedType = this.multiFactorAuth ? {value: "none"} : null;
  },
  methods: {
    ...mapActions("currentUser", ["toggleMultiFactorAuth"]),
    openAuthenticationMethodModal() {
      this.modalOpened = true;
    },
    closeAuthenticationMethodModal() {
      this.modalOpened = false;
      this.selectedType = this.multiFactorAuth ? {value: "none"} : null;
      this.password = "";
      this.clearErrors();
    },
    selectType(type) {
      this.selectedType = type;
      this.options = {};
      if (null == type) {
        return;
      }
      if ("totp" === type.value) {
        const secret = this.generateKey();
        const recovery = this.generateRecoveryCodes(5);
        this.options = {secret, code: "", recovery};
      }
    },
    generateKey() {
      const chars = "ABCDEFGHIJKLMNPRSTUVWXYZ234567";
      const f = () => chars.charAt(Math.random() * chars.length);
      return new Array(32).fill(f).map((s) => s()).join("");
    },
    generateRecoveryCodes(codeCount) {
      return new Array(codeCount).fill(this.generateRecoveryCode).map(func => func());
    },
    generateRecoveryCode() {
      const codeLength = 8;
      const randomDigit = () => Math.floor(Math.random() * 9) + 1;
      return new Array(codeLength).fill(randomDigit).map(func => func()).join("");
    },
    async getQrImage() {
      if (this.options && this.options.secret && !this.qr) {
        this.qr = await import("qr-image");
      }
    },
    selectedTypeUpdate(type) {
      this.selectType(type);
      this.getQrImage();
    },
    async submit() {
      if (this.loading || !this.password || !this.selectedType) {
        return;
      }
      const data = {
        password: this.password,
        type: this.selectedType ? this.selectedType.value : null,
        ...this.options,
      };
      try {
        this.loading = true;
        await update("/api/user/2fa-method", data);
        this.toggleMultiFactorAuth();
        this.closeAuthenticationMethodModal();
      } catch (exception) {
        this.errors = processResponseException(exception);
      }
      this.loading = false;
    },
    clearErrors() {
      this.errors = [];
    },
  },
}
</script>

<style scoped lang="scss">
  $qr-dimension: 200px;

  .qr-code {
    margin: auto;
    max-width: $qr-dimension;
    max-height: $qr-dimension;
  }
</style>
