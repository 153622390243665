

























































import {Component, Mixins} from "vue-property-decorator";
import I18n from "../../i18n.vue";
import Card from "../../Card.vue";
import ErrorMessage from "../../Form/ErrorMessage.vue";
import Tooltip from "../../Tooltip.vue";
import IsGranted from "../../IsGranted.vue";
import PatientInsuranceMixin from "../../../mixins/PatientInsuranceMixin";
import PatientInsuranceHistoryModal from "./PatientInsuranceHistoryModal.vue";

@Component({
  name: "PatientInsurance",
  components: {Tooltip, ErrorMessage, I18n, Card, IsGranted, PatientInsuranceHistoryModal}
})
export default class PatientInsurance extends Mixins(PatientInsuranceMixin) {
  get insuranceClass(): string {
    if (null === this.insurance.insured) {
      return "bg-secondary";
    }
    return this.insurance.insured ? "bg-success" : "bg-danger";
  }
}
