<template>
  <div class="row card-box">
    <div class="col-xl-3">
      <geolocalization-workers-table
        :items="items"
        @highlight="highlight"
      />
    </div>
    <div class="col-xl-9">
      <geolocalization-map
        :localizations="items"
        :highlighted="highlighted"
      />
    </div>
  </div>
</template>

<script>
import read from "../../rest/read";
import GeolocalizationWorkersTable from "../../components/Geolocalization/GeolocalizationWorkersTable";
import GeolocalizationMap from "../../components/Geolocalization/GeolocalizationMap";

export default {
  name: "GeolocalizationList",
  components: {GeolocalizationWorkersTable, GeolocalizationMap},
  data() {
    return {
      items: [],
      loading: false,
      highlighted: null
    }
  },

  async mounted(){
    await this.fetchData();
  },

  methods: {
    async fetchData() {
      try{
        this.loading = true;
        this.items = [];
        const {items} = await read("/api/workers-geolocalization");
        this.items = items;
        this.loading = false;
      } catch (e) {
        console.error(e);
      }

    },
    highlight(value){
      this.highlighted = value;
    }
  }
}
</script>