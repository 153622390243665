<template>
  <div class="ue-footer">
    <img
      src="../../images/FE_POWER.jpg"
      class="ue-image mx-auto"
      @click="openUeModal"
    >
  </div>
</template>

<script>
export default {
  name: "UeFooter",
  methods: {
    openUeModal() {
      this.$bvModal.show("ue-modal");
    },
  },
}
</script>

<style scoped lang="scss">

  .ue-footer {
    width: 100%;
    background-color: #fff;
    text-align: center;
  }

  .ue-image {
    max-width: 1000px;
    width: 100%;
    cursor: pointer;
  }
</style>
