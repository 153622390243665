<template>
  <is-granted
    :privileges="['INTERVENTION_NOTIFICATION_LIST']"
    component="div"
  >
    <counter-widget
      :filters="{startDate, endDate}"
      resource="/api/intervention-notifications"
      go-to-url="/intervention-notifications"
      title="Dzisiejsze zgłoszenia"
      icon="phone"
    />
  </is-granted>
</template>
<script>
import CounterWidget from "../CounterWidget";
import stringifyDate from "../../utils/date/stringifyDate";
import DATE_FORMAT from "../../utils/date/DATE_FORMAT";
import IsGranted from "../IsGranted";

export default {
  components: {
    IsGranted,
    CounterWidget,
  },
  computed: {
    startDate() {
      const date = new Date();
      date.setHours(0, 0, 0);
      return stringifyDate(date, DATE_FORMAT.DEFAULT);
    },
    endDate() {
      const date = new Date();
      date.setHours(23, 59, 59);
      return stringifyDate(date, DATE_FORMAT.DEFAULT);
    },
  }
}
</script>
