<template>
  <b-modal
    :id="modalId"
    :title="title"
    lazy
    no-close-on-backdrop
    title-tag="h3"
    size="lg"
    @hide="resetData"
    @show="getAdditionalWorkersData"
  >
    <loading-mask :loading="loading">
      <error-message
        :errors="errors"
        class="m-t-20"
      />
      <div
        v-for="(worker) in scheduledWorkers"
        :key="worker.workerId"
        class="treatment-worker"
      >
        <div class="worker">
          <b-input
            :value="worker.name"
            readonly
          />
        </div>
        <div class="treatment-type disabled-data card-box">
          <treatment-type-name
            :value="worker.treatmentTypeId"
          />
        </div>
      </div>
      <treatment-worker-select
        v-model="editableWorkers"
        :errors="errors"
        :exclude-worker-ids="excludeWorkerIds"
      />
    </loading-mask>
    <template #modal-footer>
      <button
        type="button"
        class="btn btn-secondary"
        @click="closeModal"
      >
        Anuluj
      </button>
      <button
        :disabled="loading"
        type="button"
        class="btn btn-primary"
        @click="save"
      >
        <span
          :class="loading ? 'fa-spin fa-spinner' : 'fa-check'"
          class="fas"
        />
        Zapisz
      </button>
    </template>
  </b-modal>
</template>

<script>
import TreatmentWorkerSelect from "../../Worker/TreatmentWorkerSelect";
import processResponseException from "../../../utils/errors/processResponseException";
import LoadingMask from "../../Loading/LoadingMask";
import ErrorMessage from "../../Form/ErrorMessage";
import read from "../../../rest/read";
import TreatmentTypeName from "../../TreatmentType/TreatmentTypeName";
import modify from "../../../rest/modify";

export default {
  name: "ChangeAdditionalWorkers",
  components: {TreatmentTypeName, ErrorMessage, LoadingMask, TreatmentWorkerSelect},
  props: {
    appointmentId: {required: true, type: String},
    patientId: {required: true, type: String},
    mainWorkerId: {required: true, type: String},
    title: {required: true, type: String},
  },
  data() {
    return {
      loading: false,
      errors: [],
      workers: [],
      editableWorkers: [],
    };
  },
  computed: {
    modalId() {
      return `changeAdditionalWorkers-${this.appointmentId}`;
    },
    scheduledWorkers() {
      return this.workers.filter(worker => !worker.addedLater);
    },
    excludeWorkerIds() {
      const scheduledWorkerIds = this.scheduledWorkers.map(({workerId})=> workerId);
      return [this.mainWorkerId, ...scheduledWorkerIds];
    },
  },
  watch: {
    workers() {
      this.editableWorkers = this.workers.filter(worker => worker.addedLater);
    },
  },
  methods: {
    async getAdditionalWorkersData() {
      this.loading = true;
      try {
        const {items} = await read(
          `/api/appointments/${this.appointmentId}/workers/with-added-later`,
          {patientId: this.patientId}
        );
        this.workers = items;
      } catch(e) {
        this.errors = processResponseException(e);
      }
      this.loading = false;
    },
    resetData() {
      this.errors = [];
      this.additionalWorkers = [];
    },
    closeModal() {
      this.$bvModal.hide(this.modalId);
    },
    async save() {
      this.loading = true;
      try {
        await modify(`/api/appointments/${this.appointmentId}/workers/with-added-later`, {
          additionalWorkers: this.editableWorkers,
        });
        this.closeModal();
        this.$emit("appointmentUpdated");
      } catch (e) {
        this.errors = processResponseException(e);
      }
      this.loading = false;
    },
  },
}
</script>


<style scoped lang="scss">
  $margin: 5px;

  .treatment-worker {
    display: flex;
    align-content: center;

    .worker {
      width: 35%;
      margin-right: $margin;
      margin-bottom: $margin;
    }

    .treatment-type {
      width: 65%;
      margin-left: $margin;
      margin-bottom: $margin;
    }
  }
</style>
