<template>
  <div class="row m-b-30">
    <div class="col-2 text-right">
      {{ field.entryType.name }}
    </div>
    <div class="col-10">
      <div class="row m-b-10">
        <div class="col">
          <input
            v-model="value"
            :disabled="readOnly"
            :state="state('content.value')"
            class="form-control"
            required
            type="number"
            min="0"
            step="1"
          >
          <ErrorMessage
            :errors="errors"
            field="content.value"
          />
          <error-message :errors="errors" />
        </div>
        <div class="col-auto">
          <button
            v-if="!readOnly"
            :disabled="saveLoading || isSavingNotAllowed()"
            class="btn-success btn"
            @click="saveEntry"
          >
            <i
              v-show="saveLoading"
              class="fa fa-spin fa-spinner"
            />
            <i
              v-show="!saveLoading"
              class="fa fa-plus"
            />
            Zapisz
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {generateUuid} from "@/utils/uuid/generateUuid";
import create from "../../../rest/create";
import stringifyDate from "../../../utils/date/stringifyDate";
import {default as getEntry} from "./EntryField";
import ErrorMessage from "../../Form/ErrorMessage";
import {errorsMixin} from "../../../mixins/errorsMixin.js";
import processResponseException from "../../../utils/errors/processResponseException";
import {mapState} from "vuex";

export default {
  name: "NumberField",
  components: {
    ErrorMessage,
  },
  mixins: [errorsMixin],
  props: {
    diagnosisId: {type: String, required: true},
    field: {type: Object, required: true},
    readOnly: {type: Boolean, required: true},
  },
  data() {
    const entries = this.field.entries || [];
    const entry = getEntry(entries);

    return {
      saveLoading: false,
      entries: entries,
      value: entry ? entry.content.value : null,
    };
  },
  computed: {
    ...mapState("currentUser", ["user"]),
    entry() {
      return getEntry(this.entries);
    },
  },
  methods: {
    isSavingNotAllowed() {
      if (this.readOnly) {
        return true;
      }

      if (this.value == null) {
        return true;
      }

      return this.entry !== null
        && this.entry.content.value === this.value;
    },
    async saveEntry() {
      if (this.isSavingNotAllowed()) {
        return;
      }
      this.saveLoading = true;
      try {
        const newEntry = {
          content: {
            type: "number",
            value: this.value,
          },
          entryId: generateUuid(),
          type: this.field.entryType.key,
          createdAt: stringifyDate(new Date()),
          workerId: this.user.workerId,
        };
        await create(`/api/diagnosis/${this.diagnosisId}/entry`, newEntry);
        const entry = {
          ...newEntry,
          creator: {
            name: this.user.name,
            surname: this.user.surname,
          },
        };
        this.entries = this.entries.concat([entry]);

        this.errors = [];
        this.$emit("new-entry-added");
      } catch (exception) {
        this.errors = processResponseException(exception);
      }
      this.saveLoading = false;
    },
  },
}
</script>

<style scoped>

</style>
