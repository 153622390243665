<template>
  <object-select
    :value="selectedContinuousStayCategory"
    :options="options"
    :state="state"
    placeholder="Wybierz kategorię pobytu"
    @input="update"
  />
</template>

<script>
import t from "../../../../i18n";
import ObjectSelect from "../../../Form/Select/ObjectSelect";

export default {
  components: {ObjectSelect},
  props: {
    continuousStayCategory: {type: String, default: null},
    state: ObjectSelect.props.state, //eslint-disable-line
  },
  data() {
    const options = [
      {name: t("@continuousStay.daily"), value: "daily"},
      {name: t("@continuousStay.daily_up_to_4_hours"), value: "daily_up_to_4_hours"},
      {name: t("@continuousStay.full_day"), value: "full_day"},
      {name: t("@continuousStay.in_secured_flat"), value: "in_secured_flat"},
      {name: t("@continuousStay.overnight_intervention_spaces"), value: "overnight_intervention_spaces"},
      {name: t("@continuousStay.rehabilitation_sanatorium"), value: "rehabilitation_sanatorium"},
      {name: t("@continuousStay.in_hostel"), value: "in_hostel"},
      {name: t("@continuousStay.away_workshops"), value: "away_workshops"},
    ];

    return {
      options
    };
  },
  computed: {
    selectedContinuousStayCategory () {
      return this.options.find(element => {
        return element.value === this.continuousStayCategory;
      });
    },
  },
  methods: {
    update(continuousStayCategory) {
      this.$emit("input", continuousStayCategory ? continuousStayCategory.value : null)
    }
  },
}
</script>
