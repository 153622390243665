<template>
  <loading-mask :loading="loading">
    <icf-creator
      v-if="overview"
      :read-only="overview.readonly"
      :ipz-id="ipzId"
      :status="overview.status"
    />
  </loading-mask>
</template>
<script>
import read from "../../../rest/read";
import IcfCreator from "../../../components/Ipz/IcfCreator/IcfCreator";
import LoadingMask from "../../../components/Loading/LoadingMask";

export default {
  components: {LoadingMask, IcfCreator},
  props: {
    ipzId: {type: String, required: true},
  },
  data() {
    return {
      overview: null,
      loading: false,
    }
  },
  watch: {
    async ipzId() {
      await this.loadData();
    },
  },
  async mounted() {
    await this.loadData();
  },
  methods: {
    async loadData() {
      this.loading = true;
      const {overview} = await read(`/api/ipzs/${this.ipzId}/icf/creator`);
      this.overview = overview;
      this.loading = false;
    }
  }
}
</script>
