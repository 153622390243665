<template>
  <b-modal
    id="chronic-disease-update-modal"
    no-close-on-backdrop
    title="Edycja choroby przewlekłej"
    size="lg"
    title-tag="h3"
    @hide="onHide"
  >
    <div class="card-box">
      <error-message
        :errors="errors"
        field="chronicDiseaseId.ChronicDisease"
      />
      <FormRow
        :errors="errors"
        label="Kod ICD-10"
        field="icd10Code"
      >
        <icd10-code-select
          v-model="icd10Code"
        />
      </FormRow>
      <FormRow
        :errors="errors"
        label="Data rozpoznania choroby"
        field="diagnosisDate"
      >
        <incomplete-date-input
          v-model="diagnosisDate"
          :state="state('diagnosisDate')"
        />
      </FormRow>
      <FormRow
        :errors="errors"
        label="Notatka"
        field="note"
      >
        <textarea-counter
          id="note"
          v-model="note"
          :rows="6"
          :state="state('note')"
          :max-characters-count="1500"
        />
      </FormRow>
      <error-message :errors="errors" />
    </div>
    <template #modal-footer>
      <button
        class="btn btn-secondary"
        @click="hideModal"
      >
        Anuluj
      </button>
      <button
        :disabled="isLoading"
        class="btn btn-primary"
        @click="updateChronicDisease"
      >
        <i
          :class="isLoading ? 'fa-spin fa-spinner' : 'fa-check'"
          class="fa"
        />
        Zapisz
      </button>
    </template>
  </b-modal>
</template>

<script>
import FormRow from "../../../Form/FormRow";
import Icd10CodeSelect from "../../../Form/Select/Icd10CodeSelect";
import update from "../../../../rest/update";
import ErrorMessage from "../../../Form/ErrorMessage";
import TextareaCounter from "../../../Form/Textarea/TextareaCounter";
import {errorsMixin} from "../../../../mixins/errorsMixin.js";
import processResponseException from "../../../../utils/errors/processResponseException";
import IncompleteDateInput from "../../../Form/Input/IncompleteDateInput";
import subErrors from "../../../../utils/errors/subErrors";


export default {
  components: {
    IncompleteDateInput,
    TextareaCounter,
    ErrorMessage,
    Icd10CodeSelect,
    FormRow
  },
  mixins: [errorsMixin],
  props: {
    patientId: {type: String, required: true},
    chronicDisease: {type: Object, default: null},
  },
  data() {
    return {
      icd10Code: null,
      diagnosisDate: null,
      note: null,
      isLoading: false,
    };
  },
  watch: {
    chronicDisease() {
      this.restartFormData();
    },
  },
  methods: {
    async updateChronicDisease() {
      this.isLoading = true;
      this.errors = [];
      try {
        await update(`/api/patient/${this.patientId}/chronic-diseases/${this.chronicDisease.chronicDiseaseId}`, {
          patientId: this.patientId,
          icd10Code: this.icd10Code === null ? null : this.icd10Code.value,
          diagnosisDate: this.diagnosisDate,
          note: this.note ? this.note.trim() : null,
        });
        this.$emit("chronicDiseaseUpdated");
        this.hideModal();
      } catch (exception) {
        this.errors = processResponseException(exception);
      }
      this.isLoading = false;
    },
    hideModal() {
      this.$bvModal.hide("chronic-disease-update-modal");
    },
    onHide() {
      this.errors = [];
      this.restartFormData();
    },
    restartFormData() {
      this.icd10Code = this.chronicDisease ?
        {
          value: this.chronicDisease.icd10Code,
          name: `${this.chronicDisease.icd10Code} / ${this.chronicDisease.icd10Description}`,
        } : null;
      this.diagnosisDate = this.chronicDisease ? this.chronicDisease.diagnosisDate : null;
      this.note = this.chronicDisease ? this.chronicDisease.note : null;
    },
    getSubErrors(field) {
      return subErrors(this.errors, field);
    }
  },
}
</script>
