import {isGranted} from "../security/isGranted";

export const registerPatientButtonMixin = {
  data() {
    return {
      patientCreateAllowed: null,
      childPatientCreateAllowed: null,
    };
  },
  computed: {
    bothPermissions() {
      return this.patientCreateAllowed && this.childPatientCreateAllowed;
    },
  },
  async mounted() {
    await Promise.all([
      this.checkCreatePatientPermission(),
      this.checkCreateChildPatientPermission(),
    ]);
  },
  methods: {
    async checkCreatePatientPermission() {
      this.patientCreateAllowed = await isGranted("CREATE_PATIENT");
    },
    async checkCreateChildPatientPermission() {
      this.childPatientCreateAllowed = await isGranted("CREATE_CHILD_PATIENT")
    },
  },
};
