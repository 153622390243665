<template>
  <b-modal
    :id="modalId"
    no-close-on-backdrop
    size="lg"
    title="Wystaw zwolnienie"
    title-tag="h3"
    @hide="onHide"
  >
    <div class="card-box col-xs-12">
      <error-message
        :errors="errors"
      />
      <div class="form-row">
        <b-form-group
          class="col-md-6"
          label="Od"
          label-for="sick-leave-from"
        >
          <date-picker
            id="sick-leave-from"
            v-model="dateFrom"
            :format="'YYYY-MM-DD'"
            :state="state('dateFrom')"
            :type="'day'"
          />
          <error-message
            :errors="errors"
            field="dateFrom"
          />
        </b-form-group>
        <b-form-group
          class="col-md-6"
          label="Do"
          label-for="sick-leave-to"
        >
          <date-picker
            id="sick-leave-to"
            v-model="dateTo"
            :format="'YYYY-MM-DD'"
            :state="state('dateTo')"
            :type="'day'"
          />
          <error-message
            :errors="errors"
            field="dateTo"
          />
        </b-form-group>
        <b-form-group
          class="col-md-6"
          label="ICD-10"
          label-for="sick-leave-code"
        >
          <icd10-code-select
            id="sick-leave-code"
            v-model="icd10"
            :state="state('recognitions')"
          />
          <error-message
            :errors="errors"
            field="recognitions"
          />
        </b-form-group>
        <b-form-group
          class="col-md-6"
          label="Komentarz"
          label-for="sick-leave-comment"
        >
          <textarea-counter
            id="sick-leave-comment"
            v-model="icd10Comment"
            :max-characters-count="200"
            :state="state('comment')"
            placeholder="Komentarz"
          />
          <error-message
            :errors="errors"
            field="comment"
          />
        </b-form-group>
      </div>
    </div>

    <template #modal-footer>
      <button
        class="btn btn-secondary"
        @click="closeModal"
      >
        Anuluj
      </button>
      <button
        :disabled="loading"
        class="btn btn-primary"
        @click="sendSickLeave"
      >
        <i
          :class="loading ? 'fa-spin fa-spinner' : 'fa-check'"
          class="fa"
        />
        Wystaw zwolnienie
      </button>
    </template>
  </b-modal>
</template>

<script>
import DatePicker from "../Form/DatePicker/DatePicker";
import Icd10CodeSelect from "../Form/Select/Icd10CodeSelect";
import create from "../../rest/create";
import DATE_FORMAT from "../../utils/date/DATE_FORMAT";
import stringifyDate from "../../utils/date/stringifyDate";
import {generateUuid} from "../../utils/uuid/generateUuid";
import ErrorMessage from "../Form/ErrorMessage";
import {mapState} from "vuex";
import TextareaCounter from "../Form/Textarea/TextareaCounter";
import {errorsMixin} from "../../mixins/errorsMixin.js";
import processResponseException from "../../utils/errors/processResponseException";

export default {
  components:{
    TextareaCounter,
    ErrorMessage,
    Icd10CodeSelect,
    DatePicker
  },
  mixins: [errorsMixin],
  props: {
    modalId: {type: String, required: true},
    patientId: {required: true, type: String},
    appointmentDocumentId: {required: true, type: String},
  },
  data() {
    return {
      dateFrom: null,
      dateTo: null,
      icd10: null,
      icd10Comment: null,
      loading: false
    };
  },
  computed: {
    ...mapState("currentUser", ["user"]),
  },
  methods: {
    onHide() {
      this.clearForm();
      this.errors = []
    },
    async sendSickLeave() {
      this.loading = true;
      this.errors = [];
      try {
        const sickLeaveItem = {
          sickLeaveId: generateUuid(),
          dateFrom: null != this.dateFrom ? stringifyDate(this.dateFrom, DATE_FORMAT.DATE): null,
          dateTo: null != this.dateTo ? stringifyDate(this.dateTo, DATE_FORMAT.DATE): null,
          recognitions: null != this.icd10
            ? [{
              icd10Code: this.icd10.value,
              comments: this.icd10.description
            }]
            : null,
          comment: this.icd10Comment,
        };
        await create("/api/sick-leave", {
          ...sickLeaveItem,
          patientId: this.patientId,
          appointmentDocumentId: this.appointmentDocumentId,
          workerId: this.user.workerId,
        });
        this.$emit("created", sickLeaveItem);
        this.closeModal();
      } catch (exception) {
        this.errors = processResponseException(exception);
      }
      this.loading = false;
    },
    clearForm() {
      this.icd10 = null;
      this.icd10Comment = null;
      this.dateTo = null;
      this.dateFrom = null;
      this.errors = [];
    },
    closeModal() {
      this.$bvModal.hide(this.modalId);
    }
  },
}
</script>
