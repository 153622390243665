<template>
  <b-tabs>
    <loading-mask :loading="loading">
      <b-tab :title-link-class="{'bg-danger text-white': basicDataTabErrors.length}">
        <template #title>
          Dane podstawowe
          <span
            v-show="basicDataTabErrors.length"
            class="fas fa-exclamation-circle"
          />
        </template>
        <worker-personal-data-form
          v-model="personalData"
          :errors="errors"
        />
        <div class="card-box">
          <h4>Adres zamieszkania</h4>
          <hr>
          <address-form
            v-model="livingAddress"
            :errors="subErrors('livingAddress')"
          />
          <div class="row">
            <b-form-checkbox
              id="differentAddressCheckbox"
              v-model="differentAddress"
            >
              Adres korespondencyjny jest inny niż zamieszkania
            </b-form-checkbox>
          </div>
        </div>
        <div
          v-if="differentAddress"
          class="card-box"
        >
          <h4>Adres korespondencyjny</h4>
          <hr>
          <address-form
            v-model="correspondenceAddress"
            :errors="subErrors('correspondenceAddress')"
          />
        </div>
      </b-tab>
      <b-tab :title-link-class="{'bg-danger text-white': treatmentTabErrors.length}">
        <template #title>
          Wykonywane usługi
          <span
            v-show="treatmentTabErrors.length"
            class="fas fa-exclamation-circle"
          />
        </template>
        <div class="card-box">
          <h4>Domyślna usługa specjalisty</h4>
          <hr>
          <worker-default-treatment-type-select
            v-model="treatmentTypeDefaultId"
            :selected-treatment-types="treatmentTypes"
            :state="state('treatmentTypeDefaultId')"
          />
          <error-message
            :errors="errors"
            field="treatmentTypeDefaultId"
          />
        </div>
        <div class="card-box">
          <h4>Wykonywane usługi</h4>
          <hr>
          <worker-treatment-types
            v-model="treatmentTypes"
            col-class="col-11 col-lg-6 col-xl-5"
            :errors="errors"
          />
        </div>
      </b-tab>
      <b-tab
        v-if="canHaveReimbursementContract"
        :title-link-class="{'bg-danger text-white': nfzTabErrors.length}"
      >
        <template #title>
          Umowa z NFZ
          <span
            v-show="nfzTabErrors.length"
            class="fas fa-exclamation-circle"
          />
        </template>
        <div class="card-box">
          <h4>Umowa z NFZ</h4>
          <hr>
          <reimbursement-contract-form
            v-model="reimbursementContract"
            :errors="errors"
          />
        </div>
      </b-tab>
    </loading-mask>
    <error-message :errors="errors" />
    <div class="text-right">
      <router-link
        :to="{name: 'worker_list'}"
        class="btn btn-secondary"
      >
        Anuluj
      </router-link>
      <button
        :disabled="loading"
        type="submit"
        class="btn btn-primary ml-2"
        @click="submit"
      >
        <i
          :class="loading ? 'fa-spin fa-spinner' : 'fa-check'"
          class="fa"
        />
        Zapisz
      </button>
    </div>
  </b-tabs>
</template>

<script>
import workerPersonalDataForm from "../../components/Worker/WorkerPersonalData.vue";
import create from "../../rest/create";
import {generateUuid} from "../../utils/uuid/generateUuid";
import AddressForm from "../../components/Address/AddressForm";
import getSubErrors from "../../utils/errors/subErrors";
import WorkerTreatmentTypes from "../../components/Worker/WorkerTreatmentTypes";
import processResponseException from "../../utils/errors/processResponseException";
import ErrorMessage from "../../components/Form/ErrorMessage";
import {BadRequestException} from "../../rest";
import ReimbursementContractForm from "../../components/Worker/ReimbursementContractForm";
import {mapActions, mapState} from "vuex";
import WorkerDefaultTreatmentTypeSelect from "../../components/TreatmentType/WorkerDefaultTreatmentTypeSelect";
import {errorsMixin} from "../../mixins/errorsMixin.js";
import LoadingMask from "../../components/Loading/LoadingMask";

const allowedProfessions = ["doctor", "nurse", "physiotherapist"];

const basicDataTabFields = [
  "name",
  "surname",
  "academicDegreeTitle",
  "medicalSpecializations",
  "professionalTitle",
  "mainProfession",
  "additionalProfessions",
  "role",
  "roleInProcess",
  "pesel",
  "phoneNumber",
  "personalEmail",
  "branchId",
  "professionType",
  "professionNumber",
  "cooperatingUnitId",
  "teamCoordinator",
  "coordinator",
  "establishments",
  "livingAddress",
  "correspondenceAddress",
];

const treatmentTabFields = [
  "treatmentTypeDefaultId",
  "treatmentTypes",
];

const nfzTabFields = [
  "reimbursementContract",
];

export default {
  name: "WorkerCreate",
  components: {
    LoadingMask,
    WorkerDefaultTreatmentTypeSelect,
    ErrorMessage,
    WorkerTreatmentTypes,
    AddressForm,
    workerPersonalDataForm,
    ReimbursementContractForm,
  },
  mixins: [errorsMixin],
  data() {
    return {
      personalData: {
        professionType: null, // property used by canHaveReimbursementContract
      },
      treatmentTypes: [],
      livingAddress: {},
      correspondenceAddress: {},
      reimbursementContract: {},
      differentAddress: false,
      loading: false,
      treatmentTypeDefaultId: null,
    };
  },
  computed: {
    ...mapState({
      clinicParameters: state => state.clinicParameters.parameters,
    }),
    canHaveReimbursementContract() {
      if (this.clinicParameters && this.clinicParameters.patientAnonymous === true) {
        return false;
      }

      if (!allowedProfessions.includes(this.personalData.mainProfession)) {
        return false;
      }

      return "pwz_number" === this.personalData.professionType;
    },
    basicDataTabErrors() {
      return this.errors
        .filter(error => basicDataTabFields
          .findIndex(tabField => error.field.startsWith(tabField)) !== -1);
    },
    treatmentTabErrors() {
      return this.errors
        .filter(error => treatmentTabFields
          .findIndex(tabField => error.field.startsWith(tabField)) !== -1);
    },
    nfzTabErrors() {
      return this.errors
        .filter(error => nfzTabFields
          .findIndex(tabField => error.field.startsWith(tabField)) !== -1);
    },
  },
  methods: {
    ...mapActions("toastNotification", ["showToast"]),
    subErrors(field) {
      return getSubErrors(this.errors, field);
    },
    async submit() {
      this.loading = true;
      const personalData = {
        ...this.personalData,
        roleInProcess: this.personalData.roleInProcess || [],
        additionalProfessions: this.personalData.additionalProfessions || [],
        cooperatingUnitId: this.personalData.cooperatingUnitId,
        role: this.personalData.role ? this.personalData.role.value: null,
        professionalTitle: this.personalData.professionalTitle,
        specializations: this.personalData.specializations,
      };
      try {
        this.errors = [];
        const workerId = generateUuid();
        const reimbursementContract = this.canHaveReimbursementContract && this.reimbursementContract.hasContract
          ? {
            ...this.reimbursementContract,
            hasContract: undefined,
          }
          : null;
        await create("/api/worker/create", {
          workerId,
          ...personalData,
          coordinator: personalData.coordinator ? personalData.coordinator.workerId : null,
          treatmentTypes: this.treatmentTypes,
          treatmentTypeDefaultId: this.treatmentTypeDefaultId,
          livingAddress: this.isLivingAddressNotEmpty() || this.differentAddress
            ? {...this.livingAddress} : null,
          correspondenceAddress: this.differentAddress
            ? {...this.correspondenceAddress}
            : this.isLivingAddressNotEmpty() ? {...this.livingAddress} : null,
          differentAddresses: this.differentAddress,
          establishments: personalData.establishments ? personalData.establishments.map((esta) => esta.value): [],
          reimbursementContract,
        });
        const message = `Wysłano link do ustawienia hasła na adres: ${this.personalData.personalEmail}`;
        const variant = "info";
        this.showToast({message, variant});
        await this.$router.push({name: "worker_list"});
      } catch (exception) {
        this.errors = processResponseException(exception);
        if(!this.differentAddress) {
          this.clearCorrespondenceAddressErrors();
        }
        if (exception instanceof BadRequestException) {
          const message = "Formularz nie został wypełniony prawidłowo.\nZmiany nie zostały zapisane.";
          const variant = "danger";
          this.showToast({message, variant});
        }
      }
      this.loading = false;
    },
    isLivingAddressNotEmpty() {
      return !(this.livingAddress
        && !this.livingAddress.city && !this.livingAddress.zipCode && !this.livingAddress.street);
    },
    clearCorrespondenceAddressErrors(){
      const prefix ="correspondenceAddress";
      this.errors = this.errors.filter((error) => !error.field || !error.field.startsWith(prefix));
    },
  }
}
</script>
