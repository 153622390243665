














































import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";
import EligiblePersonDetails from "../../../EligiblePersons/EligiblePersonDetails.vue";
import {mapState} from "vuex";
import type {SurveyItem} from "../../../../types/SurveyTypes";

@Component({
  name: "SurveyTokenModal",
  components: {EligiblePersonDetails},
  computed: {
    ...mapState("currentUser", ["user"]),
  }
})
export default class SurveyTokenModal extends Vue {
  @Prop({type: Object, default: null}) readonly survey!: SurveyItem;

  get title(): string {
    return this.survey ? this.survey.name : "";
  }

  get surveyUrl(): string {
    return `${this.user.settings.surveyBuilderUrl}/?token=${this.survey.token}`;
  }

  goToInvitationModal(): void {
    this.$bvModal.hide("survey-token-modal");
    const _survey = this.survey;
    this.$nextTick(() => {
      this.$emit("openInvitation", _survey);
    });
  }
}
