<template>
  <b-modal
    id="report-treatment-type-modal"
    :title="title"
    no-close-on-backdrop
    title-tag="h4"
    size="xl"
    @shown="getAppointmentWorkersWithTreatments"
    @hide="resetModalData"
  >
    <loading-mask :loading="loading">
      <ErrorMessage
        :errors="errors"
        class="m-t-20"
      />
      <table class="table table-lg table-bordered">
        <thead class="thead-light">
          <tr>
            <th class="w-15">
              Specjalista
            </th>
            <th class="w-40">
              Usługa pierwotna
            </th>
            <th class="w-40">
              Usługa aktualna
            </th>
            <th class="w-5">
              Opcje
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in workersWithTreatments"
            :key="item.workerId"
            class="p-3"
          >
            <td>{{ item.workerFullName }}</td>
            <td>
              <treatment-type-name
                :value="item.primaryTreatmentType"
                class="d-block"
              />
            </td>
            <td>
              <treatment-type-name
                v-if="!displayUpdate[item.workerId]"
                :value="item.treatmentTypeId"
                class="d-block"
              />
              <treatment-type-select
                v-else
                :check-if-any-treatment-type-exists="true"
                :scope="['ambulatory']"
                :without-undefined="true"
                @input="updateTreatment($event, item)"
              />
            </td>
            <td>
              <button
                v-if="!displayUpdate[item.workerId]"
                type="button"
                class="btn btn-primary"
                @click="showUpdate(item)"
              >
                <i class="fa fa-pencil-alt" />
              </button>
              <button
                v-else
                type="button"
                class="btn btn-danger"
                @click="closeUpdate(item)"
              >
                <i class="fas fa-times" />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="m-t-20" />
      <div
        v-show="reportedTreatmentHistory.length"
        v-b-toggle="'reportedHistory'"
        class="text-center m-2 cursor-pointer"
      >
        ---
        <span class="when-closed">Pokaż historię</span>
        <span class="when-opened">Ukryj historię</span>
        ---
      </div>
      <b-collapse id="reportedHistory">
        <table class="table table-sm table-bordered">
          <thead class="thead-light">
            <tr>
              <th>Data zmiany</th>
              <th>Specjalista</th>
              <th>Usługa</th>
              <th>Zmienione przez</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(historyReport, index) in reportedTreatmentHistory"
              :key="index"
              class="p-3"
            >
              <td class="text-nowrap">
                {{ historyReport.dateOfChange }}
              </td>
              <td>{{ historyReport.workerFullName }}</td>
              <td>
                <treatment-type-name
                  :value="historyReport.treatmentName"
                  class="d-block"
                />
              </td>
              <td>{{ historyReport.changedByFullName }}</td>
            </tr>
          </tbody>
        </table>
      </b-collapse>
    </loading-mask>
    <template #modal-footer>
      <button
        type="button"
        class="btn btn-secondary"
        @click="closeModal"
      >
        Anuluj
      </button>
      <button
        :disabled="!canSave || loading"
        type="button"
        class="btn btn-primary"
        @click="save"
      >
        <span
          :class="loading ? 'fa-spin fa-spinner' : 'fa-check'"
          class="fas"
        />
        Zapisz
      </button>
    </template>
  </b-modal>
</template>

<script>
import LoadingMask from "../../Loading/LoadingMask";
import read from "../../../rest/read";
import update from "../../../rest/update";
import ErrorMessage from "../../Form/ErrorMessage";
import processResponseException
  from "../../../utils/errors/processResponseException";
import TreatmentTypeSelect from "../../TreatmentType/TreatmentTypeSelect.vue";
import TreatmentTypeName from "../../TreatmentType/TreatmentTypeName";

export default {
  name: "ReportTreatmentType",
  components: {LoadingMask, ErrorMessage, TreatmentTypeSelect, TreatmentTypeName},
  props: {
    appointmentId: {required: true, type: String},
    title: {required: true, type: String},
  },
  data() {
    return {
      workersWithTreatments: [],
      reportedTreatmentHistory: [],
      updatedWorkersWithTreatments: [],
      loading: false,
      errors: [],
      canSave: false,
      displayUpdate: {},
      updatedTreatmentType: null
    };
  },
  methods: {
    async getAppointmentWorkersWithTreatments() {
      this.loading = true;
      const data = await read(`/api/appointments/${this.appointmentId}/workers-with-treatments`);
      this.workersWithTreatments = [...data.items];
      this.reportedTreatmentHistory = [...data.history];
      this.updatedWorkersWithTreatments = [];
      this.canSave = false;
      this.loading = false;
    },
    closeModal() {
      this.$bvModal.hide("report-treatment-type-modal");
    },
    updateTreatment(treatmentId, workerWithTreatment){
      if (treatmentId)
      {
        const existWwt = this.updatedWorkersWithTreatments.find(function(wwt){
          return wwt.workerId === workerWithTreatment.workerId
        });

        if(existWwt){
          this.updatedWorkersWithTreatments.map(function(wwt) {
            if (wwt.workerId === workerWithTreatment.workerId){
              return wwt.treatmentTypeId = treatmentId
            }
          });
        } else {
          this.updatedWorkersWithTreatments.push({
            ...workerWithTreatment,
            treatmentTypeId: treatmentId
          });
        }
        this.canSave = true;
      }
    },
    async save() {
      this.loading = true;
      try {
        await update(`/api/appointments/${this.appointmentId}/report-treatments`, {
          reportedTreatments: {...this.updatedWorkersWithTreatments}
        });
        this.closeModal();
        this.$emit("reportedTreatment");
      } catch (e) {
        this.errors = processResponseException(e);
      }
      this.loading = false;
    },
    showUpdate(item){
      this.displayUpdate = {
        ...this.displayUpdate,
        [item.workerId]: true,
      };
    },
    closeUpdate(item) {
      this.displayUpdate = {
        ...this.displayUpdate,
        [item.workerId]: false,
      };
    },
    resetModalData(){
      this.errors = [];
      this.displayUpdate = {};
      this.canSave = false;
      this.workersWithTreatments = [];
      this.reportedTreatmentHistory = [];
      this.updatedWorkersWithTreatments = [];
    },
  },
}
</script>

<style scoped lang="scss">
  ::v-deep .modal-dialog.modal-xl {
    max-width: 95vw;
  }

  $remove-width: 36px;
  $select-margin: 5px;

  .w-40 {
    width:40%;
  }
  .w-5 {
    width:5%;
  }
  .w-15 {
    width: 15%;
  }
  .collapsed > .when-opened,
  :not(.collapsed) > .when-closed {
    display: none;
  }
</style>
