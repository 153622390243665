<template>
  <object-select
    :value="selectedValue"
    :options="options"
    :state="state"
    :placeholder="placeholder"
    @input="update"
  />
</template>

<script>
import ObjectSelect from "./ObjectSelect";

export default {
  components: {
    ObjectSelect,
  },
  props: {
    value: {type: String, default: null},
    state: ObjectSelect.props.state, // eslint-disable-line
    placeholder: {type: String, default: "Wybierz typ"},
  },
  data() {
    return {
      options: [
        {value: "pwz_number", name: "PWZ"},
      ],
    };
  },
  computed: {
    selectedValue() {
      if (!this.value) {
        return null
      } else {
        return this.options.find((option) => option.value === this.value);
      }
    },
  },
  methods: {
    update(option) {
      this.$emit("input", option ? option.value : null);
    },
  },
}
</script>
