<template>
  <div class="d-flex justify-content-between">
    <a
      class="btn btn-sm btn-link pl-0"
      :href="`/api/files/${attachmentId}`"
      :title="`Pobierz plik ${value}`"
      download
    >
      <i class="fa fa-download text-primary" />
    </a>
    <attachment-name-input
      :value="value"
      :clearable="false"
      class="w-100"
      :readonly="!editable"
      @input="update"
      @click.native="editable = true"
    />
    <tooltip
      :disabled="loading || saved"
      :tooltip-content="tooltipContent"
      class="btn btn-link btn-sm"
      component="button"
      type="button"
      @click="editable = !editable"
    >
      <span
        :class="iconClass"
        class="fa"
      />
    </tooltip>
  </div>
</template>

<script>
import update from "../../../../../rest/update";
import Tooltip from "../../../../Tooltip";
import AttachmentNameInput from "./AttachmentNameInput";


export default {
  name: "AttachmentNameForm",
  components: {AttachmentNameInput, Tooltip},
  props: {
    value: {type: String, required: true},
    attachmentId: {type: String, required: true},
  },
  data() {
    return {
      editable: false,
      saved: false,
      loading: false,
    };
  },
  computed: {
    tooltipContent() {
      if(this.saved) {
        return "Zapisano zmiany";
      } else if (this.loading) {
        return "Zapisywanie zmian";
      } else if (this.editable) {
        return "Zamknij edycję";
      }
      return "Edytuj nazwę pliku";
    },
    iconClass() {
      if(this.saved) {
        return "fa-check";
      } else if (this.loading) {
        return "fa-spinner fa-spin";
      } else if (this.editable) {
        return "fa-times";
      }
      return "fa-pencil-alt";
    },
  },
  methods: {
    async update(name) {
      const value = this.value;
      this.loading = true;
      try {
        this.$emit("input", name);
        await update(`/api/attachments/${this.attachmentId}/name`, {name});
      } catch (e) {
        this.$emit("input", value);
      }
      this.saved = true;
      setTimeout(() => this.saved = false, 800);
      this.loading = false;
      this.editable = false;
    }
  },
}
</script>
