import {ActionTree} from "vuex";
import {CurrentUserState} from "./types";
import read from "../../../rest/read";
import {RootState} from "../../types";

export const actions: ActionTree<CurrentUserState, RootState> = {
  async loadCurrentUser({commit, dispatch}) {
    commit("loading");
    try {
      const currentUser = await read("/api/current-user");
      commit("setCurrentUser", currentUser);
      dispatch("geolocalization/trackLocalization", null, {root: true});
      dispatch("splashScreen/sendOfflineNotes", null, {root: true});
    } catch (e) {
      commit("clear");
      console.error("Cannot get current user", e);
    }
  },
  toggleMultiFactorAuth({commit}) {
    commit("toggleMultiFactorAuth");
  }
};
