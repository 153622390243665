import {ActionTree} from "vuex";
import {RootState} from "../../types";
import {GeolocalizationState, Coordinates} from "./types";
import create from "../../../rest/create";

const INTERVAL_DURATION = 1000 * 60 * 5; // ms * s * m

const options =  {
  enableHighAccuracy: true,
  timeout: 5000
};

export const actions: ActionTree<GeolocalizationState, RootState> = {
  trackLocalization({commit, state, dispatch}) {
    if(state.interval !== null) {
      clearInterval(state.interval);
    }
    dispatch("saveWorkerCurrentLocalization");
    commit("setGeolocalizationInterval",
      window.setInterval(() => {dispatch("saveWorkerCurrentLocalization")}, INTERVAL_DURATION)
    );
  },

  saveWorkerCurrentLocalization({state, commit}){
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async function (position) {
          commit("setGeolocalizationPermission", true);
          const coordinates = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          };
          await sendWorkerCurrentLocalization(coordinates);
        },
        function (error) {
          if(error.code === 1) {
            commit("setGeolocalizationPermission", false);
          }
        },options
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  },
  clearLocalization({state, commit}){
    if(state.interval !== null){
      window.clearInterval(state.interval);
    }
    commit("setGeolocalizationPermission", false);
  }
};

async function sendWorkerCurrentLocalization(coordinates: Coordinates) {
  try {
    await create("/api/worker-geolocalization", {
      coordinates: {...coordinates}
    });
  } catch (e) {
    console.error(e);
  }
}