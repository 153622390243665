import {Error} from "../../rest";

export default function subErrors(errors: Array<Error>, field: string): Array<Error> {
  const prefix =`${field}.`;
  return errors
    .filter((error) => error.field && error.field.startsWith(prefix))
    .map((error) => ({
      ...error,
      field: error.field ? error.field.substring(prefix.length) : "",
    }));
}
