






















import ErrorsMixin from "../mixins/ErrorsMixin.ts";
import ErrorMessage from "./Form/ErrorMessage.vue";
import {Component, Mixins} from "vue-property-decorator";
import processResponseException from "../utils/errors/processResponseException";
import read from "../rest/read";
import create from "../rest/create";

interface EmailNotificationsData {
  unclosedAppointmentsEmailNotification: boolean;
}

@Component({
  name: "UnclosedAppointmentsNotifications",
  components: {ErrorMessage},
})
export default class UnclosedAppointmentsNotifications extends Mixins(ErrorsMixin) {

  private loading: boolean = true;
  private subscribed: boolean = false;

  private get iconClass(): string {
    if (this.loading) {
      return "fa-spin fa-spinner";
    }
    if (this.subscribed) {
      return "fa-ban";
    }
    return "fa-check";
  }

  private get btnText(): string {
    if (this.loading) {
      return "";
    }
    return this.subscribed ? "Wyłącz" : "Włącz";
  }

  private get btnClass():string {
    if (this.loading) {
      return "btn-secondary";
    }
    return this.subscribed ? "btn-danger" : "btn-success";
  }

  async mounted(): Promise<void> {
    this.loadSubscriptionState();
  }

  private async loadSubscriptionState(): Promise<void> {
    this.loading = true;
    this.errors = [];
    try {
      const {unclosedAppointmentsEmailNotification} = await read<EmailNotificationsData>(
        "/api/worker-email-notification-settings");
      this.subscribed = unclosedAppointmentsEmailNotification;
    } catch(e) {
      this.errors = processResponseException(e);
    }
    this.loading = false;
  }

  private async changeSubscriptionState(): Promise<void> {
    this.loading = true;
    this.errors = [];
    const data = {
      unclosedAppointmentsEmailNotification: !this.subscribed,
    }
    try {
      await create<EmailNotificationsData>("/api/worker-update-email-notification-settings", data);
      this.subscribed = !this.subscribed;
    } catch(e) {
      this.errors = processResponseException(e);
    }
    this.loading = false;
  }
}
