




































































































import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";
import type {ChildIpzItem} from "../../../types/ChildIpzs";
import Card from "../../Card.vue";
import DateTime from "../../DateTime.vue";
import parseDate from "../../../utils/date/parseDate";
import DATE_FORMAT from "../../../utils/date/DATE_FORMAT";
import IsGranted from "../../IsGranted.vue";
import {createUrl} from "../../../utils/pageUrl/createUrl";
import modify from "../../../rest/modify";
import ChildIpzStatusBadge from "./ChildIpzStatusBadge.vue";

@Component({
  name: "ChildIpzDetails",
  components: {ChildIpzStatusBadge, IsGranted, DateTime, Card}
})

export default class ChildIpzWidget extends Vue {
  @Prop({type: String, required: true}) readonly ipzId!: string;
  @Prop({type: Object, default: null}) readonly item!: ChildIpzItem;
  @Prop({type: Boolean, default: false}) readonly readonly!: boolean;

  parseDate = parseDate;
  DATE_FORMAT = DATE_FORMAT;

  get printUrl(): string {
    const id: string = this.item ? this.item.childIpzId : "";
    return createUrl(`/api/child-ipz/${id}/print`, {});
  }

  async changePrintStatus(): Promise<void> {
    if (this.item && !this.item.printed) {
      try {
        const childIpzId = this.item && this.item.childIpzId;
        await modify("/api/child-ipzs/mark-as-printed", {childIpzId});
      } catch (e) {
        console.error(e);
      }
    }
  }

  openIpz(): void {
    this.$emit("open");
  }

  closeIpz(): void {
    this.$emit("close");
  }

  editIpz(): void {
    this.$emit("edit");
  }
}
