<template>
  <div
    v-if="isAuthenticated"
    id="wrapper"
    :class="{enlarged: !menuOpened}"
  >
    <top-bar />
    <side-bar />
    <logout-modal />
    <div class="content-page">
      <div class="content">
        <div class="container-fluid">
          <div
            v-if="renderTitleBox"
            class="row"
          >
            <div class="col-sm-12">
              <div class="page-title-box">
                <h4 class="page-title">
                  {{ title }}
                </h4>
                <div class="clearfix" />
              </div>
              <last-edited-appointment />
            </div>
          </div>
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideBar from "../SideBar/SideBar";
import TopBar from "../TopBar";
import {mapState} from "vuex";
import LogoutModal from "../Logout/LogoutModal";
import LastEditedAppointment from "../Appointment/LastEditedAppointment";

export default {
  name: "BaseLayout",
  components: {LastEditedAppointment, LogoutModal, SideBar, TopBar},
  computed: {
    ...mapState("sidebar",["menuOpened",]),
    ...mapState("session",["isAuthenticated",]),
    version() {
      return process.env.APP_VERSION;
    },
    renderTitleBox() {
      return this.$route.meta.renderTitleBox;
    },
    title() {
      const {title} = this.$route.meta;
      return typeof title === "string" ? title : title(this.$route);
    }
  },
}
</script>
