<template>
  <report-container report-id="specialist_list">
    <div class="card-box text-right no-label">
      <back-button :to="{name: 'reportsMainView'}" />

      <a
        href="/api/reports/specialist-list-print"
        class="btn btn-primary m-1"
      >
        <i class="fas fa-file-excel" />
        Generuj
      </a>
    </div>
  </report-container>
</template>

<script>
import BackButton from "../../components/BackButton";
import ReportContainer from "../../components/Report/ReportContainer";
export default {
  name: "SpecialistList",
  components: {ReportContainer, BackButton},
}
</script>
