<template>
  <card
    :loading="loading"
    title="Lista pacjentów z OPS"
  >
    <pagination-description
      :items-count="items.length"
      :page="currentPage"
      :per-page="pagination.perPage"
      :total-count="pagination.totalRows"
    />
    <div class="table-responsive">
      <b-table
        :fields="fields"
        :items="itemsWithDetails"
        empty-text="Brak pacjentów"
        hover
        :responsive="true"
        show-empty
      >
        <template
          #cell(show_details)="{item: {patientId}}"
        >
          <is-granted
            :privileges="['GET_PATIENT_CASE_MANAGER_OPS', 'CHANGE_PATIENT_CASE_MANAGER_OPS']"
            condition="AND"
            component="fragment"
          >
            <b-btn
              :disabled="loading"
              size="sm"
              variant="primary"
              @click="editCaseManagerOps(patientId)"
            >
              <i class="fa fa-pencil-alt" />
              Case Manager OPS
            </b-btn>
          </is-granted>
          <is-granted
            :privileges="['FIND_SOCIAL_ACTIVITIES']"
            class="d-inline-block ml-2"
          >
            <router-link
              :to="{name: 'socialActivity', params: {patientId}}"
              class="btn btn-secondary btn-sm m-1"
            >
              Działania OPS
            </router-link>
          </is-granted>
        </template>
      </b-table>
    </div>
    <pagination-description
      :items-count="items.length"
      :page="currentPage"
      :per-page="pagination.perPage"
      :total-count="pagination.totalRows"
    >
      <b-pagination
        :value="currentPage"
        :per-page="pagination.perPage"
        :total-rows="pagination.totalRows"
        align="right"
        class="mb-0"
        @input="changePage"
      />
    </pagination-description>
    <case-manager-ops-modal
      :modal-id="caseManagerOpsModalId"
      :patient-id="selectedPatientId"
      @changed="fetchData"
      @close="selectedPatientId = null"
    />
  </card>
</template>
<script>
import read from "../../rest/read";
import Card from "../../components/Card";
import isEqual from "lodash/isEqual";
import {mapState} from "vuex";
import PaginationDescription from "../../components/Pagination/PaginationDescription";
import {changePageQuery} from "@/utils/pageUrl/handlePageQuery";
import IsGranted from "../IsGranted";
import CaseManagerOpsModal from "@/components/CaseManagerOps/CaseManagerOpsModal";

export default {
  name: "PatientOpsList",
  components: {
    PaginationDescription,
    Card,
    IsGranted,
    CaseManagerOpsModal
  },
  props: {
    page: {type: Number, default: null},
    sort: {type: String, default: null}
  },
  data() {
    return {
      currentPage: this.page || 1,
      pagination: {},
      loading: false,
      items: [],
      fields:
        [
          {key: "displayName", label: "numer teczki / numer zewnętrzny"},
          {key: "registeredDate", label: "Data rejestracji"},
          {key: "caseManager", label: "Case Manager"},
          {key: "caseManagerOps", label: "Case Manager Ops"},
          {key: "firstIpzOpenedDate", label: "Data otwarcia IPZ"},
          {key: "lastIpzClosedDate", label: "Data zamknięcia IPZ"},
          {key: "show_details", label: "Opcje"}
        ],
      visitFields:[
        {key: "date", label: "Data wizyty"},
        {key: "specialists", label: "Specjaliści"},
        {key: "status", label: "Status"}
      ],
      selectedPatientId: null,
    };
  },
  computed: {
    ...mapState({
      clinicParameters: state => state.clinicParameters.parameters,
    }),
    itemsWithDetails() {
      return this.items.map(item => ({
        ...item,
        _showDetails: false,
      }))
    },
    caseManagerOpsModalId() {
      return "case-manager-ops-modal";
    },
    query() {
      return {
        opsPage: this.currentPage,
      };
    },
  },
  watch: {
    page(val) {
      this.currentPage = val || 1;
    },
    async "$route"() {
      await this.fetchData();
    },
  },
  async mounted() {
    await this.fetchData();
  },
  methods: {
    async fetchData() {
      this.loading = true;
      const {items, pagination} = await read("/api/patient-ops-list", {page: this.currentPage});
      this.items = items;
      this.pagination = pagination;
      this.loading = false;
    },
    async updatePageUrl() {
      await changePageQuery(this.query);
    },
    async changePage(page) {
      this.currentPage = page;
      await this.fetchData();
      await this.updatePageUrl();
    },
    async submit() {
      this.currentPage = 1;
      if (isEqual(this.$route.query, this.query)) {
        await this.fetchData();
        return;
      }
      await this.updatePageUrl();
    },
    editCaseManagerOps(patientId) {
      this.selectedPatientId = patientId;
      this.$bvModal.show(this.caseManagerOpsModalId);
    }
  }
}
</script>

<style scoped lang="scss">
  .order-btn {
    cursor: pointer;
    white-space: nowrap;
  }

  .icon {
    transition: transform 1s;
    transform: rotate(0deg);

    &.upside-down {
      transform: rotate(180deg);
    }
  }
</style>
