













import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";
import ObjectSelect from "../Form/Select/ObjectSelect.vue";
import t from "../../i18n";
import {ReferralType} from "../../types/Referrals";
import type {Option} from "../../types/MultiSelectTypes";

@Component({
  name: "ReferralTypeSelect",
  components: {
    ObjectSelect,
  },
})
export default class ReferralTypeSelect extends Vue {
  @Prop({type: Boolean, default: true}) readonly clearable!: boolean;
  @Prop({type: Boolean, default: false}) readonly disabled!: boolean;
  @Prop({type: Boolean, default: false}) readonly loading!: boolean;
  @Prop({type: String , default: "Wybierz"}) readonly placeholder!: string;
  @Prop({type: Boolean, default: null}) readonly state!: boolean;
  @Prop({type: String, default: null}) readonly value!: string;

  private get options(): Option[] {
    return Object.values(ReferralType).map(value => ({
      value,
      name: t(`@referral-type.${value}`)
    }));
  }

  private get selectedValue(): Option|null {
    return this.options.find(option => option.value == this.value) || null;
  }

  private update(option: Option): void {
    this.$emit("input", option.value || null);
  }
}
