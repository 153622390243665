<template>
  <div class="d-inline-block">
    <b-modal
      :id="`change-branch-modal`"
      no-close-on-backdrop
      title="Zmień komórke organizacyjną"
      title-tag="h3"
      size="lg"
    >
      <template #default>
        <error-message :errors="errors" />
        <form>
          <form-row
            label="Komórka"
            class="row"
            :errors="errors"
            field="branchId"
          >
            <branch-select
              v-model="newBranchId"
              :disabled="loading"
              :check-if-any-branch-exists="true"
              :state="state('branchId')"
            />
          </form-row>
        </form>
      </template>
      <template #modal-footer>
        <b-btn @click="closeModal">
          Zamknij
        </b-btn>
        <b-btn
          :disabled="loading"
          variant="primary"
          @click="submit"
        >
          <span
            :class="loading ? 'fa-spin fa-spinner' : 'fa-check'"
            class="fas"
          />
          Zapisz
        </b-btn>
      </template>
    </b-modal>
  </div>
</template>

<script>

import BranchSelect from "@/components/Branch/BranchSelect";
import processResponseException from "@/utils/errors/processResponseException";
import update from "@/rest/update";
import FormRow from "@/components/Form/FormRow";
import {errorsMixin} from "../../../mixins/errorsMixin.js";
import ErrorMessage from "../../Form/ErrorMessage";

export default {
  components: {ErrorMessage, FormRow, BranchSelect},
  mixins: [errorsMixin],
  props: {
    appointmentDocumentId: {type: String, required: true},
    branchId: {type: String, default: null},
  },
  data() {
    return {
      newBranchId: this.branchId,
      loading: false,
    };
  },
  watch: {
    branchId(newVal) {
      this.newBranchId = newVal;
    },
  },
  methods: {
    closeModal() {
      this.$bvModal.hide("change-branch-modal");
    },
    async submit() {
      this.loading = true;
      try {
        const resource = `/api/${this.appointmentDocumentId}/change-branch`;
        await update(resource, {branchId: this.newBranchId});
        this.$emit("changed", this.newBranchId);
        this.closeModal();
        this.errors = [];
      } catch (exception) {
        this.errors = processResponseException(exception);
      }
      this.loading = false;
    }
  },
};
</script>


<style scoped>
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}
</style>
