<template>
  <div class="card-box">
    <div
      v-if="moduleVisible(readOnly, therapyDescription)"
      class="form-group"
    >
      <h4>Przebieg terapii</h4>
      <textarea-counter
        :id="'therapyDescription-'+id"
        v-model="therapyDescription"
        :max-characters-count="10000"
        :disabled="readOnly"
        :state="state('therapyDescription')"
        rows="3"
        @focus="clearErrors('therapyDescription')"
        @input="input($event, 'therapyDescription')"
      />
      <module-error-message :messages="errors && errors.therapyDescription" />
    </div>
    <div
      v-if="moduleVisible(readOnly, recommendation)"
      class="form-group"
    >
      <h4>Zalecenia</h4>
      <textarea-counter
        :id="'recommendation-'+id"
        v-model="recommendation"
        :max-characters-count="10000"
        :disabled="readOnly"
        :state="state('recommendation')"
        rows="3"
        @focus="clearErrors('recommendation')"
        @input="input($event, 'recommendation')"
      />
      <module-error-message :messages="errors && errors.recommendation" />
    </div>
  </div>
</template>
<script>
import TextareaCounter from "../../Form/Textarea/TextareaCounter";
import {documentMixin} from "../../../mixins/Appointment/documentMixin";
import ModuleErrorMessage from "../../Form/ModuleErrorMessage";

export default {
  components: {ModuleErrorMessage, TextareaCounter},
  mixins: [documentMixin],
  props: {
    idx: {required: true, type: Number},
    module: {required: true, type: Object},
    readOnly: {required: true, type: Boolean}
  },
  data() {
    return {
      therapyDescription: this.module.therapyDescription,
      recommendation: this.module.recommendation,
      id: this.idx,
      vueReadOnly: this.readOnly,
      errors: this.module.errors,
    };
  },
  watch: {
    "module.errors"() {
      this.errors = this.module.errors;
    },
  },
  methods: {
    clearErrors: function(field) {
      if (this.errors) {
        this.errors[field] = null;
      }
    },
    input(event, field) {
      const module = {...this.module};
      module[field] = this[field];
      this.$emit("input", module)
    },
    state(field) {
      return (this.errors && this.errors[field] ? false : null);
    },
  },
}
</script>
