
































import type {IdsTarget, Target} from "../../../types/TargetsAndTasks";
import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";
import TasksCheckboxGroup from "./TasksCheckboxGroup.vue";

@Component({
  name: "TargetCheckbox",
  components: {TasksCheckboxGroup}
})
export default class TargetCheckbox extends Vue {
  @Prop({type: String, required: true}) readonly targetId!: Target["targetId"];
  @Prop({type: String, default: null}) readonly name!: Target["name"];
  @Prop({type: String, default: null}) readonly description!: Target["description"];
  @Prop({type: Array, default: ()=>[]}) readonly tasks!: Target["tasks"];
  @Prop({type: Array, default: ()=>[]}) readonly checkedTargets!: Array<IdsTarget["targetId"]>;
  @Prop({type: Array, default: ()=>[]}) readonly checked!: IdsTarget["taskIds"];
  @Prop({type: Boolean, default: false}) readonly readOnly!: boolean;

  private get isTargetChecked(): boolean {
    return this.checkedTargets.includes(this.targetId);
  }

  private update(taskIds: IdsTarget["taskIds"]): void {
    this.$emit("change", {taskIds, targetId: this.targetId});
  }
}
