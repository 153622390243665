<template>
  <dl>
    <template v-for="param in parameters">
      <i18n
        :key="`${param.key}-key`"
        :msgid="`@appointment-params.${param.key}`"
        component="dt"
      />
      <i18n
        :key="`${param.key}-value`"
        :msgid="`@appointment-params-${param.key}.${param.value}`"
        component="dd"
      />
    </template>
  </dl>
</template>

<script>
import I18n from "../i18n";

export default {
  name: "AppointmentParametersList",
  components: {I18n},
  props: {
    params: {type: Object, required: true},
  },
  computed: {
    parameters() {
      return Object.entries(this.params)
        .reduce((list, [key, value]) => {
          if (key === "oda" && value) {
            value = "oda";
          }
          if (value) {
            list.push({key, value});
          }
          return list
        }, []);
    },
  },
}
</script>
