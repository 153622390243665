<template>
  <form @submit.prevent="submit">
    <div class="row">
      <b-form-group
        class="col-lg-6 col-xl-2"
        label="Data początkowa"
      >
        <date-picker v-model="formData.startDate" />
      </b-form-group>
      <b-form-group
        class="col-lg-6 col-xl-2"
        label="Data końcowa"
      >
        <date-picker
          v-model="formData.endDate"
          clearable
        />
      </b-form-group>
      <b-form-group
        class="col-lg-6 col-xl-4"
        label="Typ usługi"
      >
        <treatment-type-select
          v-model="formData.treatmentType"
          check-if-any-treatment-type-exists
        />
      </b-form-group>
      <b-form-group
        class="col-lg-6 col-xl-4"
        label="Status"
      >
        <appointment-status-select
          v-model="formData.statuses"
          :checked-in-enabled="false"
        />
      </b-form-group>
      <b-form-group
        label="Pacjent"
        class="col-lg-6 col-xl-4"
      >
        <patient-select
          v-model="formData.patient"
        />
      </b-form-group>
      <b-form-group
        class="col-lg-6 col-xl-4"
        label="Specjaliści"
      >
        <worker-select
          v-model="formData.workers"
          multiple
        />
      </b-form-group>
      <b-form-group
        class="col-lg-12 col-xl-4"
        label="Plan wizyty"
      >
        <b-form-input
          v-model.trim="formData.plan"
          placeholder="Wpisz fragment tekstu planu"
        />
      </b-form-group>
      <div class="col-lg-4 col-xl-2">
        <patient-options-checkbox
          v-model="formData.patientOptions"
        />
      </div>
      <div class="col-lg-4 col-xl-2">
        <meeting-type-checkbox v-model="formData.remote" />
      </div>
      <div class="col-lg-4 col-xl-2">
        <multiple-specialists-and-patients-options-checkbox
          v-model="formData.multiplePresence"
        />
      </div>
      <div class="col-lg-12 col-xl-6 text-right no-label">
        <a
          v-if="!loading"
          :href="exportUrl"
          class="btn btn-warning mr-2"
          target="_blank"
        >
          <i class="fa fa-atom" />
          Eksportuj
        </a>
        <a
          :href="printUrl"
          class="btn btn-secondary"
          target="_blank"
        >
          <i class="fa fa-print" />
          Drukuj
        </a>
        <button
          class="btn btn-danger ml-2"
          type="button"
          @click="clearFilters"
        >
          <i class="fa fa-ban" />
          Wyczyść
        </button>
        <button
          class="btn btn-primary ml-2"
          type="submit"
        >
          <i class="fa fa-search" />
          Szukaj
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import DatePicker from "../Form/DatePicker/DatePicker.vue";
import AppointmentStatusSelect from "./AppointmentStatusSelect.vue";
import TreatmentTypeSelect from "../TreatmentType/TreatmentTypeSelect.vue";
import PatientSelect from "../Patient/PatientSelect";
import WorkerSelect from "../Worker/WorkerSelect";
import MeetingTypeCheckbox from "../Form/Checkbox/MeetingTypeCheckbox";
import {mapState, mapMutations} from "vuex";
import stringifyDate from "../../utils/date/stringifyDate";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import PatientOptionsCheckbox from "../PatientPresenceOptions/PatientOptionsCheckbox";
import {changePageQuery, resetPageQuery} from "../../utils/pageUrl/handlePageQuery";
import {isGranted} from "../../security/isGranted";
import {specialistRoles} from "../../utils/roles/specialistRoles";
import MultipleSpecialistsAndPatientsOptionsCheckbox
  from "../MultipleSpecialistsAndPatientsPresenceOptions/MultipleSpecialistsAndPatientsOptionsCheckbox";

const defaultStatuses = ["created", "checkedin", "opened", "canceled", "finished"];

export default {
  components: {
    MultipleSpecialistsAndPatientsOptionsCheckbox,
    PatientOptionsCheckbox,
    MeetingTypeCheckbox,
    WorkerSelect,
    PatientSelect,
    AppointmentStatusSelect,
    DatePicker,
    TreatmentTypeSelect,
  },
  props: {
    startDate: {type: Date, default: null},
    endDate: {type: Date, default: null},
    treatmentType: {type: String, default: null},
    statuses: {type: Array, default: null},
    patientId: {type: String, default: null},
    workerIds: {type: Array, default: null},
    remote: {type: Boolean, default: null},
    plan: {type: String, default: null},
    printUrl: {type: String, required: true},
    exportUrl: {type: String, required: true},
    loading: {type: Boolean, required: true},
    patientOptions: {type: Array, default: null},
    multiplePresence: {type: Array, default: null},
  },
  data() {
    return {
      formData: {
        startDate: this.startDate,
        endDate: this.endDate,
        treatmentType: this.treatmentType,
        statuses: this.statuses ? this.statuses : defaultStatuses,
        patient: this.patientId,
        workers: this.workerIds ? this.workerIds : [],
        remote: this.remote,
        plan: this.plan,
        patientOptions: this.patientOptions ? this.patientOptions : [],
        multiplePresence: this.multiplePresence ? this.multiplePresence: []
      },
      currentUserIsSpecialist: null,
    };
  },
  computed: {
    ...mapState("currentUser", ["user"]),
    ...mapState("appointmentListFilters", ["savedFilters",]),
    formDataWorkerIds() {
      if ("object" === typeof this.formData.workers[0]) {
        return this.formData.workers.map(option => option.value);
      }
      return this.formData.workers;
    },
    filters() {
      const patientId =(this.formData.patient && this.formData.patient.patientId)
        || this.formData.patient;
      return {
        startDate: this.formData.startDate ? stringifyDate(this.formData.startDate) : undefined,
        endDate: this.formData.endDate ? stringifyDate(this.formData.endDate) : undefined,
        treatmentType: this.formData.treatmentType || undefined,
        statuses: this.formData.statuses.join(",") || undefined,
        patientId: (!this.formData.patientOptions.includes("noPatients") && patientId) || undefined,
        workerIds: this.formDataWorkerIds.join(",") || undefined,
        remote: this.formData.remote !== null ? this.formData.remote : undefined,
        plan: this.formData.plan || undefined,
        patientOptions: this.formData.patientOptions.join(",") || undefined,
        multiplePresence: this.formData.multiplePresence.join(",") || undefined,
      };
    },
  },
  watch: {
    startDate(val) {
      this.formData.startDate = val;
    },
    endDate(val) {
      this.formData.endDate = val;
    },
    treatmentType(val) {
      this.formData.treatmentType = val;
    },
    statuses(val) {
      this.formData.statuses = val ? val : defaultStatuses;
    },
    patientId(val) {
      this.formData.patient = val;
    },
    workerIds(val) {
      this.formData.workers = val ? val : [];
    },
    remote(val) {
      this.formData.remote = val;
    },
    plan(val) {
      this.formData.plan = val;
    },
    patientOptions(val) {
      this.formData.patientOptions = val ? val : [];
    },
    multiplePresence(val) {
      this.formData.multiplePresence = val ? val : [];
    },
    "$route": {
      immediate: true,
      async handler() {
        if (isEmpty(this.$route.query) && this.savedFilters) {
          this.$router.replace({
            path: this.$route.path,
            query: this.savedFilters,
          });
          return;
        }
        this.$emit("reloadList");
      }
    },
  },
  async mounted() {
    this.currentUserIsSpecialist = await isGranted(specialistRoles);
    if (!this.savedFilters && (!this.workerIds || !this.workerIds.length)) {
      this.formData.workers = this.currentUserIsSpecialist ? [this.user.workerId] : [];
      this.submit();
    }
  },
  methods: {
    ...mapMutations("appointmentListFilters", ["saveAppointmentListFilters",]),
    submit() {
      if (isEqual(this.$route.query, this.filters)) {
        this.$emit("reloadList");
        return;
      }
      changePageQuery(this.filters);
      this.saveAppointmentListFilters(this.filters);
    },
    async clearFilters() {
      const startDate = new Date();
      startDate.setHours(0,0,0,0);
      this.formData = {
        startDate,
        endDate: null,
        treatmentType: null,
        statuses: defaultStatuses,
        patient: null,
        workers: [],
        remote: null,
        plan:null,
        patientOptions: [],
        multiplePresence: [],
      };
      if (this.currentUserIsSpecialist) {
        this.formData.workers = [this.user.workerId];
        const query = {workerIds: this.user.workerId};
        this.saveAppointmentListFilters(query);
        await changePageQuery(query);
      } else if (!isEmpty(this.$route.query)) {
        this.saveAppointmentListFilters(null);
        await resetPageQuery();
      }
    },
  },
}
</script>
