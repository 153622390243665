export default (rules, root = null, errors = []) => (data) => errors.concat(
  rules
    .map(({field, rule, subvalue}) => ({
      message: rule(data[field], subvalue),
      field: null == root ? field : `${root}.${field}`,
      code: clientValidationCode,
    }))
    .filter((error) => error.message)
    .filter(({field, message}) => {
      const prevError = errors.find(
        (error) => error.field === field && error.message === message
      );

      return null == prevError;
    })
);

const clientValidationCode = "clientValidationCode";

export const notEmptyRule = (value) => {
  if (null != value && "" !== value) {
    return null;
  }

  return "Ta wartość nie powinna być pusta.";
};

export const notEmptySubvalueRule = (value, subvalue) => {
  if (null != value && null != value[subvalue] && "" !== value[subvalue]) {
    return null;
  }
  return "Ta wartość nie powinna być pusta.";
};
