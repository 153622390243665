
























import {Component, Ref, Vue} from "vue-property-decorator";
import {mapActions, mapState} from "vuex";
import OfflineNotes from "./OfflineNotes.vue";
import NoteForm from "./NoteForm.vue";
import {NoteItem} from "../../types/Notes";

@Component({
  name: "OfflineNotesWidget",
  components: {
    NoteForm,
    OfflineNotes,
  },
  computed: {
    ...mapState({
      userId: state => state.currentUser.user.userId,
    }),
  },
  methods: {
    ...mapActions({
      sendOfflineNotes: "splashScreen/sendOfflineNotes",
    })
  },
})
export default class OfflineNotesWidget extends Vue {
  @Ref("noteForm") readonly noteForm!: NoteForm;

  private notes: NoteItem[] = [];

  private get storageKey(): string {
    if (!this.userId) {
      return "offlineNotes";
    }
    return `${this.userId}-offlineNotes`
  }

  private addOfflineNote(note: NoteItem): void {
    let notes: NoteItem[] = [];
    const notesJson = localStorage.getItem(this.storageKey);
    if (notesJson) {
      notes = JSON.parse(notesJson);
    }
    localStorage.setItem(this.storageKey, JSON.stringify([...notes, note]));
    this.notes.push(note);
    this.noteForm.clearNote();
    this.sendOfflineNotes();
  }
}
