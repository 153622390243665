






































import {Component, Mixins, Prop} from "vue-property-decorator";
import ErrorMessage from "../Form/ErrorMessage.vue";
import ErrorsMixin from "../../mixins/ErrorsMixin.ts";
import read from "../../rest/read";
import processResponseException from "../../utils/errors/processResponseException";
import LoadingMask from "../Loading/LoadingMask.vue";
import IsGranted from "../IsGranted.vue";

interface SocialActivityData {
  socialActivityId: string;
  treatmentTypeId: string;
  dateFrom: string;
  dateTo: string;
  description: string;
}

@Component({
  name: "ShowSocialActivityDescriptionModal",
  components: {IsGranted, LoadingMask, ErrorMessage}
})

export default class ShowSocialActivityDescriptionModal extends Mixins(ErrorsMixin) {
  @Prop({type: String, required: true}) readonly socialActivityId!: string;
  @Prop({type: String, required: true}) readonly patientId!: string;

  private loading: boolean = false;
  private description: string | null = null;

  private get modalId(): string {
    return `show-social-activity-description-${this.socialActivityId}`
  }

  async loadData(): Promise<void> {
    this.loading = true;
    this.errors = []
    try {
      const {description} = await read<SocialActivityData>(`/api/social-activity/${this.socialActivityId}`);
      this.description = description;
    } catch (e) {
      this.errors = processResponseException(e);
    }
    this.loading = false;
  }

  hideModal(): void {
    this.$bvModal.hide(this.modalId);
  }
}
