



































import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";
import type {Recognition} from "../../types/Referrals";
import Icd10CodeSelect from "../Form/Select/Icd10CodeSelect.vue";
import {Error} from "../../rest";
import ErrorMessage from "../Form/ErrorMessage.vue";

@Component({
  name: "RecognitionForm",
  components: {ErrorMessage, Icd10CodeSelect},
})
export default class RecognitionForm extends Vue {
  @Prop({type: Boolean, default: false}) readonly disabled!: boolean;
  @Prop({type: String, default: "Rozpoznanie"}) readonly codeLabel!: string;
  @Prop({type: Array, default: ()=>[]}) readonly errors!: Error[];
  @Prop({type: Object, default: ()=>({})}) readonly value!: Recognition;
  @Prop({type: Boolean, default: null}) readonly componentState!: null;

  private get codeState(): boolean|null {
    const codeFieldState = this.state("code");
    return this.componentState === null
      ? codeFieldState
      : this.componentState;
  }

  private updateCode(code: string|null): void {
    let newValue = {...this.value, code};
    if (code === null) {
      newValue.comment = "";
    }
    this.$emit("input", newValue);
  }

  private updateComment(comment: string|null):void {
    this.$emit("input", {...this.value, comment});
  }

  private state(field: string): boolean|null {
    return this.errors.find((error) => error.field === field) ? false : null;
  }
}
