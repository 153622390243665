<template>
  <vue-multi-select
    ref="select"
    v-tabindex
    :internal-search="false"
    :value="value"
    :class="{invalid: state === false}"
    :options="options"
    :loading="loading"
    :allow-empty="false"
    :show-no-results="false"
    deselect-label=""
    hide-selected
    preserve-search
    tag-placeholder=""
    select-label="Wybierz"
    label="label"
    track-by="id"
    placeholder=""
    @open="presetPhrase"
    @input="select"
    @search-change="searchChange"
  >
    <template #caret>
      <span />
    </template>
    <template #noOptions>
      <span />
    </template>
  </vue-multi-select>
</template>

<script>
import VueMultiSelect from "vue-multiselect";
import rest from "../../rest";

export default {
  components: {VueMultiSelect},
  directives: {
    tabindex: {
      inserted(el) {
        el.setAttribute("tabindex", 0);
      },
    },
  },
  props: {
    state: {type: Boolean, default: null},
    city: {type: String, default: ""},
  },
  data() {
    return {
      loading: false,
      typingTimeout: null,
      options: [],
    };
  },
  computed: {
    value() {
      return this.city ? {
        label: this.city,
      } : null;
    },
  },
  watch: {
    city() {
      this.options = [];
    },
  },
  methods: {
    searchChange(phrase) {
      this.$emit("input", phrase);
      this.loadOptions(phrase);
    },
    async loadOptions(phrase) {
      if (!phrase || phrase.length <= 3 || phrase === this.city) {
        return;
      }
      clearTimeout(this.typingTimeout);
      this.typingTimeout = setTimeout(async () => {
        this.loading = true;
        try {
          this.options = await this.fetchData(phrase);
          this.options = this.options.reduce((list, option) => {
            const listedOption = list.find(({label}) => label === option.label);
            if (!listedOption && option.label !== this.city) {
              return list.concat([option]);
            }
            return list;
          }, []);
          this.options.unshift({
            id: phrase,
            label: phrase,
          });
        } catch (e) {
          console.error(e);
        }
        this.loading = false;
      }, 500);
    },
    async fetchData(phrase) {
      const params = {
        phrase,
        skip: 0,
        take: 5,
      };
      const {data} = await rest.read("/api/cities", params);

      return data.map((city) => {
        return Object.freeze({
          id: city.id,
          label: city.attributes.name,
        });
      });
    },
    presetPhrase() {
      this.$refs.select.search = this.city;
    },
    select(option) {
      if (!option) {
        this.presetPhrase();
        return;
      }
      this.$emit("input", option.label);
      setTimeout(() => this.$refs.select.search = option.label, 0);
    },
  },
}
</script>
