










































import {Component, Prop} from "vue-property-decorator";
import TextareaCounter from "../Form/Textarea/TextareaCounter.vue";
import ErrorMessage from "../Form/ErrorMessage.vue";
import Vue from "vue";
import {Error} from "../../rest";
import {
  InternalReferralComment, InternalReferralSaveComment,
} from "../../types/InternalReferral";
import processResponseException from "../../utils/errors/processResponseException";
import create from "../../rest/create";
import read from "../../rest/read";
import LoadingMask from "../Loading/LoadingMask.vue";
import t from "../../i18n";

interface Data {
  items: InternalReferralComment[],
}

@Component({
  name: "InternalReferralComments",
  components: {TextareaCounter, ErrorMessage, LoadingMask}
})
export default class InternalReferralComments extends Vue {
  @Prop({type: String, required: true}) readonly internalReferralId!: string;

  private newComment: string = "";
  private comments: InternalReferralComment[] = [];
  private errors: Error[] = [];
  private loading: Boolean = false;
  private saving: Boolean = false;

  mounted() {
    this.fetchData();
  }

  private async saveComment(): Promise<void> {
    this.saving = true;
    this.errors = [];
    const data = {
      comment: this.newComment
    };
    try {
      await create<InternalReferralSaveComment>(`/api/internal-referrals/${this.internalReferralId}/comments`, data);
      this.newComment="";
      await this.fetchData();
    } catch (e) {
      this.errors = processResponseException(e);
    }
    this.saving = false;
  }

  private async fetchData(): Promise<void> {
    this.loading = true;
    this.errors = [];
    this.comments = [];
    try {
      const {items} = await read<Data>(`/api/internal-referrals/${this.internalReferralId}/comments`);
      this.comments = items;
    } catch (e) {
      this.errors = processResponseException(e);
    }
    this.loading = false;
  }


  private state(field: Error["field"]) {
    return this.errors.find(error => error.field === field) ? false : null;
  }

  private commentLabel(direction: string, date: string, author: string) {
    let translatedDirection = t(`@internal-referral-direction.${direction}`);
    return `${date} ${author} (${translatedDirection})`;
  }

}

