<template>
  <b-modal
    id="finish-empty-appointment-modal"
    :title="title"
    no-close-on-backdrop
    title-tag="h3"
  >
    <template #default>
      <appointment-details
        :appointment-id="appointmentId"
        :date="date"
        :patients="patients"
        :main-worker="mainWorker"
      />
    </template>
    <template #modal-footer>
      <button
        type="button"
        class="btn btn-secondary"
        @click="closeModal"
      >
        Anuluj
      </button>
      <button
        type="submit"
        class="btn btn-primary"
        @click="finishEmptyAppointment"
      >
        Zamknij wizytę bez otwierania dokumentu
      </button>
    </template>
  </b-modal>
</template>

<script>
import AppointmentDetails from "../AppointmentDetails";
import update from "../../../rest/update";
import {errorsMixin} from "../../../mixins/errorsMixin.js";
import processResponseException
  from "../../../utils/errors/processResponseException";

export default {
  components: {
    AppointmentDetails,
  },
  mixins: [errorsMixin],
  props: {
    id: {type: String, default: null},
    appointmentId: {required: true, type: String},
    title: {required: true, type: String},
    date: {type: Date, default: null},
    group: {type: Boolean, default: false},
    patients: {type: Array, default: () => []},
    patientId: {type: String, default: null},
    mainWorker: {type: Object, default: null},
  },
  data() {
    return {
      checkInWithdrawalReason: "",
    }
  },
  methods: {
    async finishEmptyAppointment() {
      try {
        await update(`/api/appointment/${this.appointmentId}/finish-empty`, {});
        this.$emit("emptyAppointmentFinished");
        this.closeModal();
      } catch (exception) {
        this.errors = processResponseException(exception)
      }
    },
    closeModal() {
      this.$bvModal.hide("finish-empty-appointment-modal");
    },
  },
}
</script>
