<template>
  <b-modal
    id="cancelEvaluationModal"
    no-close-on-backdrop
    title="Anuluj ewaluację"
    title-tag="h3"
    class="text-left"
  >
    <error-message :errors="errors" />
    <form-row
      :errors="errors"
      label="Podaj powód anulacji ewaluacji"
      field="cancelReason"
    >
      <textarea-counter
        id="reason"
        v-model.trim="cancelReason"
        :state="state('cancelReason')"
        :max-characters-count="1000"
        rows="3"
      />
    </form-row>
    <template #modal-footer>
      <button
        :disabled="loading"
        class="btn btn-secondary"
        @click="hideModal"
      >
        Powrót
      </button>
      <button
        :disabled="loading"
        class="btn btn-primary"
        @click="cancelEvaluation"
      >
        <i
          :class="loading ? 'fa-spinner fa-spin' : 'fa-check'"
          class="fa"
        />
        Anuluj ewaluację
      </button>
    </template>
  </b-modal>
</template>

<script>
import {errorsMixin} from "../../../mixins/errorsMixin.js";
import FormRow from "../../Form/FormRow";
import update from "../../../rest/update";
import processResponseException from "../../../utils/errors/processResponseException";
import TextareaCounter from "../../Form/Textarea/TextareaCounter";
import ErrorMessage from "../../Form/ErrorMessage";

export default {
  components: {FormRow, TextareaCounter, ErrorMessage},
  mixins: [errorsMixin],
  props: {
    ipzId: {type: String, required: true},
    evaluationId: {type: String, required: true},
  },
  data() {
    return {
      cancelReason: null,
      loading: false,
    };
  },
  methods: {
    async cancelEvaluation() {
      try {
        this.loading = true;
        await update(`/api/ipzs/${this.ipzId}/evaluations/${this.evaluationId}/cancel`, {
          cancelReason: this.cancelReason ? this.cancelReason : ""
        });
        await this.$router.push({name: "ipz_evaluations_list", params: {ipzId: this.ipzId}});
      } catch (exception) {
        this.loading = false;
        this.errors = processResponseException(exception);
      }
    },
    hideModal() {
      this.$bvModal.hide("cancelEvaluationModal");
    }
  }
}
</script>
