import { render, staticRenderFns } from "./AdditionalProfesssionSelect.vue?vue&type=template&id=00527b22&"
import script from "./AdditionalProfesssionSelect.vue?vue&type=script&lang=js&"
export * from "./AdditionalProfesssionSelect.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports