




















































































































































import {Component, Mixins, Prop} from "vue-property-decorator";
import ErrorsMixin from "../../../mixins/ErrorsMixin";
import CreateZlaModal from "../../Zla/CreateZlaModal.vue";
import read from "../../../rest/read";
import ErrorMessage from "../../Form/ErrorMessage.vue";
import processResponseException from "../../../utils/errors/processResponseException";
import LoadingMask from "../../Loading/LoadingMask.vue";
import IsGranted from "../../IsGranted.vue";
import CreateSickLeave from "../CreateSickLeave.vue";
import {BvTableFieldArray} from "bootstrap-vue/src/components/table";
import type {SickLeaveItem} from "../../../types/SickLeave";
import {SickLeaveStatus, SickLeaveType} from "../../../types/SickLeave";
import I18n from "../../i18n.vue";
import CancelZlaModal from "../../Zla/CancelZlaModal.vue";

interface Module {
  type: string;
  sickLeaveItems: SickLeaveItem[];
}
@Component({
  name: "SickLeaveModule",
  components: {
    CancelZlaModal,
    I18n,
    IsGranted,
    LoadingMask,
    ErrorMessage,
    CreateZlaModal,
    CreateSickLeave,
  },
})
export default class ZlaModule extends Mixins(ErrorsMixin) {
  @Prop({type: Number, required: true}) readonly idx!: number;
  @Prop({type: Object, required: true}) readonly module!: Module;
  @Prop({type: Boolean, required: true}) readonly readOnly!: boolean;
  @Prop({type: String, required: true}) readonly patientId!: string;
  @Prop({type: String, required: true}) readonly appointmentId!: string;
  @Prop({type: String, required: true}) readonly appointmentDocumentId!: string;

  private loading: boolean = false;
  private sickLeaveItems: SickLeaveItem[] = this.module.sickLeaveItems || [];
  private selectedId: string|null = null;
  private SickLeaveType = SickLeaveType;
  private SickLeaveStatus = SickLeaveStatus;

  private get fields(): BvTableFieldArray {
    let fields = [
      {key: "dateFrom", label: "Od"},
      {key: "dateTo", label: "Do"},
      {key: "recognitions", label: "ICD-10"},
    ];
    if (this.sickLeaveItems.some(item => item.type === SickLeaveType.STANDARD)) {
      fields = fields.concat([{key: "comment", label: "Komentarz"},]);
    }
    if (this.sickLeaveItems.some(item => item.type === SickLeaveType.ZLA)) {
      fields = fields.concat([
        {key: "status", label: "Status"},
        {key: "serialNumber", label: "Numer Seryjny"},
        {key: "options", label: "", class: "text-right"},
      ]);
    }
    return fields;
  }


  get zlaModalId(): string {
    return "create-zla-modal";
  }

  get sickLeaveModalId(): string {
    return "create-sick-leave-modal";
  }

  get cancelZlaModalId(): string {
    return "cancel-zla-modal";
  }

  async mounted(): Promise<void> {
    await this.fetchData();
  }

  private async fetchData(): Promise<void> {
    this.loading = true;
    this.errors = [];
    try {
      const {items} = await read<{items: SickLeaveItem[]}>("/api/sick-leave", {
        appointmentDocumentId: this.appointmentDocumentId
      });
      this.sickLeaveItems = this.module.sickLeaveItems.concat(items);
    } catch (e) {
      this.errors = processResponseException(e);
    }
    this.loading = false;
  }

  private saveCreate(href: Location): void {
    this.$emit("saveCreate", href);
  }

  private cancelZla(id: string): void {
    this.selectedId = id;
    this.$bvModal.show(this.cancelZlaModalId);
  }

  private onZlaCancel(): void {
    this.selectedId = null;
    this.fetchData();
  }

  getBadgeVariant(status: SickLeaveStatus): string {
    switch (status) {
      case SickLeaveStatus.CREATED:
        return "success";
      case SickLeaveStatus.CANCELED:
        return "danger";
      default:
        throw Error(`Not supported status: ${status}`);
    }
  }
}
