import {ActionTree} from "vuex";
import {SidebarState} from "./types";
import {RootState} from "../../types";

export const actions: ActionTree<SidebarState, RootState> = {
  toggleMenu(context) {
    context.commit("toggleMenu");
  },
  closeMenu(context) {
    context.commit("closeMenu");
  },
};
