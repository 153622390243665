


















import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";
import read from "../../rest/read";
import ObjectSelect from "../Form/Select/ObjectSelect.vue";
import type {CodeItem, Icd9CodeOption} from "../../types/IcdCodes";
import {code2option} from "../../utils/icd10Code/code2option";

interface Data {
  items: CodeItem[];
}

@Component({
  name: "Icd9CodeSelect",
  components: {ObjectSelect},
})
export default class Icd9CodeSelect extends Vue {
  @Prop({type: Boolean, default: false}) readonly clearAfterSelect!: boolean;
  @Prop({type: Boolean, default: false}) readonly disabled!: boolean;
  @Prop({type: String, default: "Zacznij wpisywać kod ICD-9"}) readonly placeholder!: string;
  @Prop({type: String, default: null}) readonly name!: string;
  @Prop({type: Boolean, default: null}) readonly state!: boolean|null;
  @Prop({type: [Object, Array], default: null}) readonly value!: Icd9CodeOption|Icd9CodeOption[]| string|string[];
  @Prop({type: Boolean, default: false}) readonly multiple!: boolean;

  public options: Icd9CodeOption[] = [];
  private loading: boolean = false;
  private typingTimeout: number = 0;

  private get selected(): Icd9CodeOption|Icd9CodeOption[]|string|string[]|null {
    if (!this.value) {
      return this.value;
    } else if (Array.isArray(this.value)) {
      if ("string" === typeof this.value[0]) {
        this.loadSelectedValues(this.value);
      } else {
        return this.value;
      }
    } else if ("string" === typeof this.value) {
      this.loadSelectedValue(this.value);
    }
    return this.clearAfterSelect ? null : this.value;
  }

  private async loadOptions(phrase: string =""): Promise<void> {
    window.clearTimeout(this.typingTimeout);
    if ("" === phrase || 2 > phrase.length) {
      return;
    }
    this.typingTimeout = window.setTimeout(async () => {
      this.loading = true;
      const query = {phrase};
      const {items} = await read<Data>("/api/icd9-codes", query);
      this.options = items.map<Icd9CodeOption>(code2option);
      this.loading = false;
    }, 1000);
  }

  private cancelLoading() {
    this.loading = false;
    window.clearTimeout(this.typingTimeout);
  }

  private update(option: Icd9CodeOption): void {
    this.$emit("input", option);
  }


  private select(option: Icd9CodeOption): void {
    this.$emit("select", option);
    this.$emit("onSelectCode", option);
  }

  private async loadSelectedValue(value: string): Promise<void> {
    await this.loadOptions(value);
    const newValue = this.options.find(option => option.value === value) || null;
    this.$emit("input", newValue);
  }

  private async loadSelectedValues(value: string[]): Promise<void> {
    const responses = await Promise.all(value.map(phrase => read<Data>("/api/icd9-codes", {phrase})));
    const values = responses
      .reduce<CodeItem[]>((icd9Codes, {items}) => [...icd9Codes, ...items], [])
      .map<Icd9CodeOption>(code2option);
    this.options = values;
    this.update(values);
  }
}
