import { render, staticRenderFns } from "./StickyPatientSelect.vue?vue&type=template&id=bdba8040&scoped=true&"
import script from "./StickyPatientSelect.vue?vue&type=script&lang=js&"
export * from "./StickyPatientSelect.vue?vue&type=script&lang=js&"
import style0 from "./StickyPatientSelect.vue?vue&type=style&index=0&id=bdba8040&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bdba8040",
  null
  
)

export default component.exports