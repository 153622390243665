export interface WithUuid {
  uuid: {
    value: string;
  };
}

export interface Recognition {
  Icd10code: string;
  comments: string;
}

export interface SerialNumber {
  series: string;
  number: string;
}

export enum SickLeaveType {
  ZLA = "zla",
  STANDARD = "standard"
}

export enum SickLeaveStatus {
  CREATED = "created",
  CANCELED = "canceled",
}

export interface SickLeaveItem {
  id: string;
  patient : WithUuid;
  appointmentDocumentId: WithUuid;
  dateFrom: string;
  dateTo: string;
  recognitions: Recognition[];
  serialNumber: SerialNumber;
  type: SickLeaveType | null;
  status: SickLeaveStatus | null;
  fileId: string | null;
}
