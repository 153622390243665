<template>
  <span>{{ countryName }}</span>
</template>

<script>

import countriesJson from "../../countries.json"

export default {

  props: {
    countryCode: {default: null, type: String},
  },

  computed: {
    countryName: function () {
      if (this.countryCode)
      {
        const country = countriesJson.find( (c) => c.code === this.countryCode);
        return country.name;
      }
      return null;
    }
  },
}
</script>
