<template>
  <div
    :class="[dragOver ? 'dragOver' : '']"
    class="box"
  >
    <div class="message">
      {{ message }}
    </div>
    <input
      ref="file"
      :key="i"
      :accept="accept"
      :multiple="multiple"
      :disabled="disabled"
      type="file"
      class="input"
      @dragenter="dragOver = true"
      @dragleave="dragOver = false"
      @mouseout="dragOver = false"
      @change="handleChange"
    >
  </div>
</template>

<script>
export default {
  name: "FileSelect",
  props: {
    multiple: {type: Boolean, default: false},
    disabled: {type: Boolean, default: false},
    accept: {type: String, default: "*/*"},
  },
  data() {
    return {
      dragOver: false,
      i: 0,
    };
  },
  computed: {
    message() {
      return this.dragOver
        ? "Upuść tutaj plik aby go wgrać"
        : "Przeciągnij tutaj plik, lub kliknij i wybierz go z komputera";
    }
  },
  mounted() {
    document.body.addEventListener("dragover", this.preventEvent);
    document.body.addEventListener("drop", this.preventEvent);
  },
  destroyed() {
    document.body.removeEventListener("dragover", this.preventEvent);
    document.body.removeEventListener("drop", this.preventEvent);
  },
  methods: {
    handleChange(event) {
      event.preventDefault();
      this.active = false;
      const target = event.currentTarget;
      this.$emit("input", [...target.files]);
      this.i++;
    },
    preventEvent(e) {
      if (e.target !== this.$refs.file) {
        e.preventDefault();
      }
    }
  },}
</script>

<style scoped lang="scss">
@import "../../styles/variables";

.box {
  display: block;
  position: relative;
  border-radius: $border-radius;
  overflow: hidden;
  border: $input-border-width dashed $input-border-color;
  padding: 3rem $btn-padding-x;

  &.dragOver,
  &:hover {
    transition: border-color .5s;
    border-color: $dark;
  }
  & > .message {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    font-size: $font-size-lg;
    text-align: center;
    transform: translate(0, -50%);
  }
  & > .input {
    cursor: pointer;
    width: 100%;
    opacity: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
  }
}

</style>
