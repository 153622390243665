













































import {Component, Mixins, Prop} from "vue-property-decorator";
import ErrorMessage from "../Form/ErrorMessage.vue";
import ErrorsMixin from "../../mixins/ErrorsMixin";
import processResponseException from "../../utils/errors/processResponseException";
import update from "../../rest/update";

@Component({
  name: "CancelReferralModal",
  components: {ErrorMessage}
})
export default class CancelReferralModal extends Mixins(ErrorsMixin) {
  @Prop({type: String, required: true}) readonly referralId!: string;

  private loading: boolean = false;
  private password: string = "";

  private onHide():void {
    this.password = "";
    this.errors = [];
  }

  private hideModal(): void {
    this.$bvModal.hide(`cancel-referral-${this.referralId}`);
  }

  private async submit(): Promise<void> {
    this.errors = [];
    this.loading = true;
    try {
      await update(`/api/cancel-ereferral/${this.referralId}`, {
        password: this.password
      });
      this.$emit("canceled");
      this.hideModal();
    } catch (e) {
      this.errors = processResponseException(e);
    }
    this.loading = false;
  }
}
