<template>
  <div class="mb-4">
    <appointment-box-item
      v-for="item in items"
      :key="item.appointmentId"
      :item="item"
      :patient-id="patientId"
      @updateAppointmentList="updateAppointmentList"
    />
  </div>
</template>

<script>
import AppointmentBoxItem from "./AppointmentBoxItem";

export default {
  components: {AppointmentBoxItem},
  props: {
    items: {type: Array, required: true},
    patientId: {type: String, default: null},
  },
  methods: {
    updateAppointmentList() {
      this.$emit("updateAppointmentList");
    },
  }
}
</script>
