var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"card p-b-10"},[_c('div',{staticClass:"card-header",attrs:{"id":"headingOne"}},[_c('diagnosis-title',{attrs:{"entries":_vm.entries,"title":"Oddział całodobowy"}})],1),_c('div',{staticClass:"card-body"},_vm._l(({
          BRANCH_24H_EPIC: 'Epikryza',// name: item
          BRANCH_24H_PSYCHOLOGICAL_EXAMINATION: 'Badania psychologiczne',
          BRANCH_24H_THERAPEUTIC_INTERACTIONS: 'Oddziaływania terapeutyczne',
          BRANCH_24H_OTHERS: 'Inne',
        }),function(item,name){return _c('diagnosis-field',{key:name,attrs:{"read-only":_vm.overview.readonly,"injected-entries":_vm.getField(name).entries,"type":name,"type-trans":item.toUpperCase(),"create-entry-url":("/api/ipzs/24-hour-branch/" + _vm.branch24hId + "/entries")},on:{"new-entry-added":_vm.entryAdded}})}),1)]),_c('div',{staticClass:"card p-b-10 mt-5"},[_c('div',{staticClass:"card-header",attrs:{"id":"headingSecond"}},[_c('diagnosis-title',{attrs:{"entries":_vm.entries,"title":"Oddział Dzienny"}})],1),_c('div',{staticClass:"card-body"},_vm._l(({
          BRANCH_DAILY_EPIC: 'Epikryza',// name: item
          BRANCH_DAILY_PSYCHOLOGICAL_EXAMINATION: 'Badania psychologiczne',
          BRANCH_DAILY_THERAPEUTIC_INTERACTIONS: 'Oddziaływania terapeutyczne',
          BRANCH_DAILY_OTHERS: 'Inne',
        }),function(item,name){return _c('diagnosis-field',{key:name,attrs:{"read-only":_vm.overview.readonly,"injected-entries":_vm.getField(name).entries,"type":name,"type-trans":item.toUpperCase(),"create-entry-url":("/api/ipzs/24-hour-branch/" + _vm.branch24hId + "/entries")},on:{"new-entry-added":_vm.entryAdded}})}),1)])])}
var staticRenderFns = []

export { render, staticRenderFns }