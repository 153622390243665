<template>
  <item-carousel
    :items="documents"
    :value="value"
    @input="update"
  >
    <template #content>
      <div
        v-if="documents.length > 1"
        class="text-center"
      >
        Dokument {{ value + 1 }} z {{ documents.length }}
        <br>
        {{ documents[value].mainWorker.name }}
        {{ documents[value].mainWorker.surname }}
        <br>
        Usługa: {{ documents[value].treatmentName }}
      </div>
      <document-preview
        :item="documents[value]"
        :idx="value"
      />
    </template>
  </item-carousel>
</template>

<script>
import ItemCarousel from "../ItemCarousel";
import DocumentPreview from "../../views/Appointment/DocumentPreview";

export default {
  name: "AppointmentDocumentCarousel",
  components: {DocumentPreview, ItemCarousel},
  props: {
    documents: {type: Array, required: true},
    value: {type: Number, default: 0},
  },
  methods: {
    update(index) {
      this.$emit("input", index);
    }
  }
}
</script>
