<template>
  <div>
    <b-btn
      v-for="category in categories"
      :key="category.categoryId"
      block
      :variant="category.categoryId === value ? 'primary' : 'light'"
      style="white-space: normal"
      class="d-flex flex-nowrap align-items-baseline text-left mb-1"
      @click="$emit('input', category.categoryId)"
    >
      <i
        :class="{
          'fa-folder': category.categoryId !== value,
          'fa-folder-open': category.categoryId === value,
        }"
        class="fa"
      />
      <span class="mx-1">
        {{ category.label }}
      </span>
      <b-badge
        class="ml-auto"
        :variant="loading || category.categoryId === value ? 'light' : 'primary'"
      >
        <i
          v-if="loading"
          class="fas fa-spin fa-spinner"
        />
        <template v-else-if="category.count">
          {{ category.count }}
        </template>
      </b-badge>
    </b-btn>
  </div>
</template>

<script>
import t from "../../../../i18n";
import read from "../../../../rest/read";
import {mapState} from "vuex";
import {dimPartners} from "@/utils/partners/partners";
import {attachmentCategories} from "../../../../utils/attachmentCategories";

export default {
  name: "AttachmentsCategories",
  props: {
    value: {type: String, default: null},
    patientId: {type: String, required: true},
    appointmentId: {type: String, required: false, default: null},
    appointmentDocumentId: {type: String, required: false, default: null},
    continuousStayId: {type: String, default: null},
    scope: {type: Array, default: null},
  },
  data() {
    return {
      items: [],
      loading: false,
      categoryIds: attachmentCategories,
    };
  },
  computed: {
    categories() {
      return this.categoryIds.map(id =>{
        const correspondingItem = this.items.find(item => item.category === id);
        return {
          categoryId: id,
          label: t(`@patient_attachment_category.${id}`),
          count: correspondingItem ? correspondingItem.count : 0,
        }});
    },
    ...mapState({
      partner: state => state.clinicParameters.parameters.partner
    }),
  },
  watch: {
    patientId() {
      this.getAttachmentsCount();
    },
    continuousStayId() {
      this.getAttachmentsCount();
    },
  },
  mounted() {
    this.getAttachmentsCount();
  },
  methods: {
    async getAttachmentsCount() {
      this.loading = true;

      const query = {
        patientId: this.patientId,
        continuousStayId: this.continuousStayId,
        scope: this.scope,
      };
      if (!dimPartners.includes(this.partner)) {
        query.appointmentId = this.appointmentId;
        query.appointmentDocumentId = this.appointmentDocumentId;
      }
      const {items} = await read("/api/attachments-categories", query);
      this.items = items || [];

      this.loading = false;
    },
  },
};
</script>
