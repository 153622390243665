<template>
  <textarea
    :id="id"
    ref="textarea"
    :class="{invalid: isInvalid}"
    :disabled="disabled"
    :maxlength="maxlength"
    :placeholder="placeholder"
    :required="required"
    :rows="rows"
    :state="state"
    :value="value"
    class="form-control"
    @blur="blur"
    @input="input"
    @focus="resizeTextarea"
  />
</template>

<script>
export default {
  name: "ResizableTextarea",
  props: {
    value: {type: String, default: ""},
    disabled: {type: Boolean, default: false},
    maxlength: {type: Number, default: null},
    placeholder: {type: String, default: null},
    required: {type: Boolean, default: false},
    rows: {type: [Number, String], default: null},
    state: {type: Boolean, default: null},
    id: {type: String, default: null},
  },
  computed: {
    isInvalid() {
      return false === this.state;
    },
    minHeight() {
      const lineHeight = 24;
      const paddingHeight = 12;
      return this.rows * lineHeight + paddingHeight;
    },
  },
  watch: {
    value() {
      this.$nextTick(() => {
        this.resizeElement();
      });
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.resizeElement();
    });
    this.$el.addEventListener("input", this.resizeTextarea);
  },
  beforeDestroy() {
    this.$el.removeEventListener("input", this.resizeTextarea);
  },
  methods: {
    resizeElement() {
      if (!this.value || this.minHeight >= this.$el.scrollHeight) {
        this.$el.setAttribute("style", "height:auto; overflow-y:hidden;");
      } else {
        this.$el.setAttribute("style", `height:${this.$el.scrollHeight}px;overflow-y:hidden;`);
      }
    },
    resizeTextarea(event) {
      event.target.style.height = "auto";
      if (event.target.scrollHeight > event.target.clientHeight) {
        event.target.style.height = `${event.target.scrollHeight}px`;
      }
    },
    input($event) {
      this.$emit("input", $event.target.value);
    },
    blur() {
      this.$emit("blur");
    },
    focus() {
      this.$refs.textarea.focus();
    },
  },
}
</script>

<style scoped lang="scss">
@import "../../../styles/variables";

.invalid {
  border-color: $red;
}
</style>
