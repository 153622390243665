<template>
  <div>
    <card
      :loading="loading"
      title="Oddział NFZ"
    >
      <template #control>
        <is-granted
          :privileges="['CHANGE_PATIENT_NFZ_BRANCH']"
        >
          <b-btn
            :disabled="loading"
            variant="primary"
            class="float-right btn-sm"
            @click="openModal"
          >
            <i class="fa fa-pencil-alt" />
          </b-btn>
        </is-granted>
      </template>
      <p v-if="nfzBranch && nfzBranch.branchId">
        {{ nfzBranch.name }} ({{ nfzBranch.branchId }})
      </p>
      <p v-else>
        Brak
      </p>
    </card>
    <b-modal
      id="nfzBranchModal"
      title="Wybór oddziału NFZ"
      title-tag="h3"
      no-close-on-backdrop
    >
      <template #default>
        <error-message 
          :errors="errors"
          field="branchId"
        />
        <nfz-branch-select
          v-model="newNfzBranch"
        />
      </template>
      <template #modal-footer>
        <button
          type="button"
          class="btn btn-secondary"
          @click="closeModal"
        >
          Anuluj
        </button>
        <button
          :disabled="loading"
          class="btn btn-primary"
          @click="submit"
        >
          <i
            :class="loading ? 'fa-spin fa-spinner' : 'fa-check'"
            class="fa"
          />
          Zapisz
        </button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import update from "../../../rest/update";
import NfzBranchSelect from "../../Form/Select/NfzBranchSelect";
import read from "../../../rest/read";
import processResponseException from "../../../utils/errors/processResponseException";
import ErrorMessage from "../../Form/ErrorMessage";
import {NotFoundException} from "../../../rest";
import Card from "../../Card";
import IsGranted from "../../IsGranted";
export default {
  name: "PatientCardNfzBranchId",
  components: {
    IsGranted,
    Card,
    ErrorMessage,
    NfzBranchSelect,
  },
  props: {
    patientId: {type: String, required: true}
  },
  data() {
    return {
      errors: [],
      loading: false,
      newNfzBranch: null,
      nfzBranch: null,
    }
  },
  watch: {
    patientId() {
      this.fetchData();
    },
  },
  async mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.loading = true;
      try {
        this.nfzBranch = await read(`/api/patients/${this.patientId}/nfz_branch`);
      } catch (exception) {
        if (!(exception instanceof NotFoundException)) {
          throw exception;
        }
        this.nfzBranch = null;
      }
      this.resetData();
      this.loading = false;
    },
    async submit() {
      this.loading = true;
      try {
        const resource = `/api/patients/${this.patientId}/nfz_branch`;
        await update(resource, {
          branchId: this.newNfzBranch && this.newNfzBranch.branchId ? this.newNfzBranch.branchId : null
        });
        this.nfzBranch = {...this.newNfzBranch};
        this.closeModal();
      } catch (exception) {
        this.errors = processResponseException(exception);
      }
      this.loading = false;
    },
    async openModal() {
      this.resetData();
      this.loading = false;
      this.$bvModal.show("nfzBranchModal");
    },
    closeModal() {
      this.$bvModal.hide("nfzBranchModal");
    },
    resetData() {
      this.newNfzBranch = {...this.nfzBranch};
    }
  }
}
</script>
