















































import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";
import RecognitionForm from "./RecognitionForm.vue";
import {Recognition} from "../../types/Referrals";
import {Error} from "../../rest";
import subErrors from "../../utils/errors/subErrors";

@Component({
  name: "ReferralRecognitions",
  components: {RecognitionForm},
})
export default class ReferralRecognitions extends Vue {
  @Prop({type: Boolean, default: false}) readonly disabled!: boolean;
  @Prop({type: Array, default: ()=>[]}) readonly errors!: Error[];
  @Prop({type: String, default: "Zacznij wpisywać kod ICD-9"}) readonly placeholder!: string;
  @Prop({type: String, default: null}) readonly name!: string;
  @Prop({type: Boolean, default: null}) readonly state!: boolean|null;
  @Prop({type: Array, required: true}) readonly value!: Recognition[];

  private update($event: Recognition, index: number): void {
    const newValue = [...this.value];
    newValue[index] = $event;
    this.$emit("input", newValue);
  }

  private add(): void {
    const newValue = [...this.value, {}];
    this.$emit("input", newValue);
  }

  private remove(index: number): void {
    const newValue = this.value.filter((recognition, idx) => idx !== index);
    this.$emit("input", newValue);
  }

  private getSubErrors(field: string): Error[] {
    return subErrors(this.errors, field);
  }
}
