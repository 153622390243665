<template>
  <b-modal
    :visible="modalOpen && !!client"
    title="Przekierowanie do karty pacjenta"
    no-close-on-backdrop
    size="lg"
    title-tag="h3"
    @hide="closeModal"
  >
    <div v-if="client">
      Pacjent z numerem PESEL
      <strong>{{ client.pesel }}</strong>
      już istnieje, czy chcesz otworzyć jego kartę?
    </div>
    <template #modal-footer>
      <button
        :disabled="loadingClient"
        type="button"
        class="btn btn-secondary mr-2"
        @click="closeModal"
      >
        Anuluj
      </button>
      <router-link
        :disabled="loadingClient"
        :to="{name: 'patientCard', params: {patientId: client.personId}}"
        tag="button"
        class="btn btn-primary"
      >
        <i
          v-if="loadingPatient"
          class="fa fa-spin fa-spinner"
        />
        Otwórz kartę
      </router-link>
    </template>
  </b-modal>
</template>

<script>
export default {
  props: {
    modalOpen: {type: Boolean, required: true},
    client: {type: Object, default: null},
    loadingClient: {type: Boolean, required: true},
  },
  data() {
    return {
      loadingPatient: false,
      selectedAgreements: [],
    };
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
  },
}
</script>
