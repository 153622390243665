<template>
  <div class="card-box">
    <div class="form-group">
      <h4>{{ fieldLabel }}</h4>
      <textarea-counter
        :id="'description-'+id"
        v-model="description"
        :max-characters-count="10000"
        :disabled="readOnly"
        :state="state('description')"
        rows="3"
        @focus="clearErrors('description')"
        @input="input($event, 'description')"
      />
      <module-error-message :messages="errors && errors.description" />
    </div>
  </div>
</template>
<script>
import TextareaCounter from "../../Form/Textarea/TextareaCounter";
import ModuleErrorMessage from "../../Form/ModuleErrorMessage";

export default {
  components: {ModuleErrorMessage, TextareaCounter},

  props: {
    idx: {required: true, type: Number},
    module: {required: true, type: Object},
    readOnly: {required: true, type: Boolean}
  },
  data() {
    return {
      description: this.module.description,
      id: this.idx,
      vueReadOnly: this.readOnly,
      fieldLabel: (this.module.options && this.module.options["field-label"]) ?
        this.module.options["field-label"] :
        "Przebieg wizyty",
      errors: this.module.errors || [],
    };
  },
  watch: {
    "module.errors"() {
      this.errors = this.module.errors;
    },
  },
  methods: {
    input(event, field) {
      const module = {...this.module};
      module[field] = this[field];
      this.$emit("input", module)
    },
    clearErrors: function(field) {
      if (this.errors) {
        this.errors[field] = null;
      }
    },
    state(field) {
      return (this.errors && this.errors[field] ? false : null);
    },
  },
}
</script>
