var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('loading-mask',{attrs:{"loading":_vm.loading}},[_c('media-query-collapse',{attrs:{"message-close":"Zwiń filtry","message-open":"Rozwiń filtry","collapse-media-query":"(max-width: 991.98px)","collapse-open":""},scopedSlots:_vm._u([{key:"collapseContent",fn:function(){return [_c('calendar-filter',{attrs:{"id":"calendar-filter","view":_vm.view,"treatment-type":_vm.treatmentType,"appointment-id":_vm.appointmentId,"copy-id":_vm.copyId,"workers":_vm.workers,"start-date":_vm.startDate},on:{"reload":_vm.reloadCalendar}})]},proxy:true}])})],1),(!_vm.loading && _vm.startDate && _vm.endDate)?_c('calendar',{ref:"calendar",attrs:{"filters":{
      treatmentType: _vm.treatmentType,
      appointmentId: _vm.appointmentId,
      copyId: _vm.copyId,
      startDate: _vm.startDate,
      endDate: _vm.endDate,
      workers: _vm.workers,
      continuousStay: _vm.continuousStay
    },"view":_vm.view,"preferred-patient":_vm.stickyPatient}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }