<template>
  <div class="card-box">
    <div class="form-row">
      <div class="form-group col font-weight-bold">
        Osoby towarzyszące
      </div>
      <EligiblePersonTypeSelect
        v-if="!readOnly"
        v-model="personSelected"
        class="w-100"
        @input="addPerson"
      />
    </div>
    <div
      v-if="module.persons.length"
      class="table-responsive"
    >
      <b-table
        :fields="fields"
        :items="module.persons"
        hover
        :responsive="true"
      >
        <template #cell(description)="person">
          <textarea-counter
            v-if="!readOnly"
            v-model="person.item.description"
            :max-characters-count="1000"
            placeholder="Opis"
            :state="state(person.index, 'description')"
            @input="updateDescription($event, person.index)"
            @focus="clearErrors(person.index, 'description')"
          />
          <div v-else>
            {{ person.item.description }}
          </div>
          <module-error-message
            :messages="errors
              && errors[person.index]
              && errors[person.index].description"
          />
        </template>
        <template #cell(deleteRow)="person">
          <b-btn
            v-if="!readOnly"
            size="sm"
            variant="outline-secondary"
            @click="deleteRow(person.index)"
          >
            <i class="fa fa-trash" /> Usuń
          </b-btn>
        </template>
      </b-table>
    </div>
  </div>
</template>
<script>
import EligiblePersonTypeSelect from "../../EligiblePersons/EligiblePersonTypeSelect";
import TextareaCounter from "../../Form/Textarea/TextareaCounter";
import ModuleErrorMessage from "../../Form/ModuleErrorMessage";

export default {
  components: {ModuleErrorMessage, TextareaCounter, EligiblePersonTypeSelect},
  props: {
    idx: {required: true, type: Number},
    module: {required: true, type: Object},
    readOnly: {required: true, type: Boolean},
  },
  data() {
    return {
      id: this.idx,
      personSelected: null,
      errors: this.module.errors?.persons || [],
    };
  },
  computed: {
    fields() {
      const fields = [
        {key: "name", label: "Osoba"},
        {key: "description", label: "Opis", class: "w-75"}
      ];
      if (!this.readOnly) {
        fields.push({key: "deleteRow", label: "", class: "text-right", thStyle: "width: 5.8rem"});
      }

      return fields;
    }
  },
  watch: {
    "module.errors"() {
      this.errors = (this.module.errors && this.module.errors.persons) ? this.module.errors.persons: null;
    },
  },
  methods: {
    addPerson() {
      if (!this.personSelected) {
        return;
      }
      const module = {
        ...this.module,
        persons: this.module.persons.concat([
          {
            value: this.personSelected.value,
            name: this.personSelected.name,
            description: "",
          }
        ]),
      };
      this.$emit("input", module);
      this.personSelected = null;
    },
    deleteRow(index) {
      const module = {
        ...this.module,
        persons: this.module.persons.filter((person, idx) => idx !== index),
      };
      this.$emit("input", module);
    },
    updateDescription(event, index) {
      const module = {
        ...this.module,
        persons: this.module.persons.map((person, idx) => {
          return (idx !== index)
            ? person
            : {
              ...person,
              description: event.trim(),
            };
        }),
      };
      this.$emit("input", module);
    },
    state(index, field) {
      return this.errors
      && this.errors[index]
      && this.errors[index][field]
        ? false
        : null;
    },
    clearErrors: function(index, field) {
      if (this.errors && this.errors[index]) {
        this.errors[index][field] = null;
      }
    },
  },
}
</script>
