<template>
  <b-modal
    id="unblockConfirmation"
    title="Potwierdzenie odblokowania pracownika"
    no-close-on-backdrop
    size="lg"
  >
    <template #default>
      <span class="font-18 m-b-20">
        Czy napewno chcesz odblokować konto pracownika {{ worker.name }} {{ worker.surname }}?
      </span>
    </template>
    <template #modal-footer>
      <button
        class="btn btn-primary"
        @click="submit"
      >
        Odblokuj
      </button>
      <button
        type="button"
        class="btn btn-secondary"
        @click="closeModal"
      >
        Anuluj
      </button>
    </template>
  </b-modal>
</template>

<script>

import update from "../../rest/update";

export default {
  props: {
    worker: {type: Object, required: true}
  },
  methods: {
    async submit()
    {
      await update(`/api/user/unblock/${this.worker.userId}`,{});
      this.closeModal();
      this.$emit("workerUnblocked");
    },
    closeModal()
    {
      this.$bvModal.hide("unblockConfirmation");
    }
  },
}
</script>
