<template>
  <div>
    <h3 class="m-b-20">
      {{ headerTitle }}
    </h3>
    <h2>Podsumowanie ewaluacji</h2>
    <h4>Data stworzenia: <b>{{ creationDate }}</b></h4>
    <h4>Imię i nazwisko pacjenta: <b>{{ patientFullName }}</b></h4>
    <h4>Imię i nazwisko osoby przeprowadzającej badanie: <b>{{ workerFullName }}</b></h4>
    <h4
      v-if="evaluatorType"
    >
      Osoba ewaluująca: <b><i18n :msgid="evaluatorType" /></b>
    </h4>

    <evaluation-result-component
      v-for="component in components" 
      :key="component.symbol"
      v-bind="component"
    />
  </div>
</template>

<script>
import EvaluationResultComponent from "./EvaluationResultComponent";
import I18n from "../../i18n";
import t from "../../../i18n";

export default {
  components: {
    I18n,
    EvaluationResultComponent
  },
  props: {
    creationDate: {type: String, required: true},
    patientFullName: {type: String, required: true},
    workerFullName: {type: String, required: true},
    evaluatorType: {type: String, required: true},
    components: {type: Array, required: true},
    coreSetNames: {type: Array, required: true},
  },
  computed: {
    headerTitle() {
      return `Ewaluacja ICF - ${this.creationDate} - ${
        this.coreSetNames.map(name => t(name)).join(", ")
      }`;
    },
  },
}
</script>
