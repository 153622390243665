

























































import ErrorsMixin from "../../mixins/ErrorsMixin";
import {Component, Prop} from "vue-property-decorator";
import ErrorMessage from "../Form/ErrorMessage.vue";
import ReferralSelect from "./ReferralSelect.vue";
import {AcceptedReferral} from "../Patient/PatientCard/PatientCardAcceptedReferrals.vue";
import create from "../../rest/create";
import processResponseException from "../../utils/errors/processResponseException";

@Component({
  name: AssignReferralModal.name,
  components: {ErrorMessage,ReferralSelect,}
})
export default class AssignReferralModal extends (ErrorsMixin) {
  @Prop({type: String, default: null}) readonly patientId!: string;
  @Prop({type: String, default: null}) readonly appointmentDocumentId!: string;

  private loading: boolean = false;
  private acceptedReferral: AcceptedReferral | null = null;

  private async update(referral: AcceptedReferral): Promise<void>
  {
    this.acceptedReferral = referral;
  }

  private async assign(): Promise<void> {
    this.loading = true;
    try{
      await create("/api/assign-referral", {
        acceptReferralId: this.acceptedReferral?.id,
        appointmentDocumentId: this.appointmentDocumentId
      });
      this.$emit("created");
      this.hideModal();
    }catch(e) {
      this.errors = processResponseException(e);
    }
    this.loading = false;
  }

  hideModal(): void {
    this.errors = [];
    this.$bvModal.hide("assign-referral");
  }



}
