<template>
  <object-select
    :id="id"
    :options="options"
    :value="selectedValue"
    :placeholder="placeholder"
    :state="state"
    :disabled="disabled"
    :name="name"
    :clearable="clearable"
    @input="update"
  />
</template>

<script>
import ObjectSelect from "./ObjectSelect";
import t from "../../../i18n";
import upperFirst from "lodash/upperFirst";

export default {
  components: {
    ObjectSelect
  },
  props: {
    value: {type: [String, Boolean], default: null},
    state: {type: Boolean, default: null},
    id: {type: String, default: null},
    placeholder: {type: String, default: "Wybierz"},
    disabled: {type: Boolean, default: false},
    name: {type: String, default: null},
    onlyBoolean: {type: Boolean, default: false},
    clearable: {default: true, type: Boolean},
    refusalValue: {type: String, default: "information_refusal"},
  },
  data() {
    const options = [
      {value: "yes"},
      {value: "no"},
    ];
    if (!this.onlyBoolean) {
      options.push({value: this.refusalValue});
    }

    return {
      options : options.map(option => ({
        ...option,
        name: upperFirst(t(`${option.value}`)),
      })),
    };
  },
  computed: {
    selectedValue() {
      if (this.value === null) {
        return null;
      }

      let value = this.value;
      if (this.onlyBoolean) {
        value = value ? "yes": "no";
      }

      return this.options.find((option) => option.value === value);
    },
  },
  methods: {
    update(option) {
      if (option === null) {
        this.$emit("input", null);
        return;
      }

      if (this.onlyBoolean) {
        this.$emit("input", option.value === "yes");
      } else {
        this.$emit("input", option.value);
      }
    },
  },
}
</script>
