<template>
  <b-form-group
    id="calendarTypeSelect"
    :description="description"
    label="Typ widoku"
    label-for="calendarTypeSelectInput"
  >
    <b-form-radio-group
      id="calendarTypeSelectInput"
      v-model="selected"
      :options="options"
      :name="name"
      buttons
      @change="change"
    />
  </b-form-group>
</template>
<script>
export default {
  props: {
    value: {type: String, required: true},
    name: {type: String, default: undefined},
  },
  data() {
    return {
      selected: this.value
    };
  },
  computed: {
    options() {
      return [
        {icon: "calendar", value: "day", label: "Widok dnia"},
        {icon: "list", value: "list", label: "Harmonogram"},
        {icon: "calendar-alt", value: "month", label: "Widok miesiąca"},
      ].map((item) => ({
        html: `<i title="${item.label}" class="fa fa-${item.icon}"></i>`,
        description: item.label,
        value: item.value
      }));
    },
    description() {
      const option = this.options.find((item) => item.value === this.selected);
      if (!option) {
        return null;
      }

      return option.description;
    }
  },
  watch: {
    value() {
      this.selected = this.value;
    },
  },
  methods: {
    change(option) {
      this.$emit("change", option);
    },
  }
}
</script>
