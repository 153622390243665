

















import WeeklyScheduleWorkerDay from "./WeeklyScheduleWorkerDay.vue";
import moveDateByDays from "../../utils/moveDateByDays";
import WeeklyScheduleWorkerName from "./WeeklyScheduleWorkerName.vue";
import stringifyDate from "../../utils/date/stringifyDate";
import DATE_FORMAT from "../../utils/date/DATE_FORMAT";
import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";
import type {WeeklyScheduleWeekDay, WeeklyScheduleItem, WorkerObject} from "../../types/WeeklySchedule";

@Component({
  name: "WeeklyScheduleWorker",
  components: {WeeklyScheduleWorkerName, WeeklyScheduleWorkerDay},
})

export default class WeeklyScheduleWorker extends Vue {
  @Prop({type: Object, default: ()=>[]}) readonly worker!: WorkerObject;
  @Prop({type: Array, default: ()=>[]}) readonly items!: Array<WeeklyScheduleItem>;
  @Prop({type: Array, default: ()=>[]}) readonly canceledItems!: Array<WeeklyScheduleItem>;
  @Prop({type: Date, required: true}) readonly startDate!: Date;
  @Prop({type: Number, required: true}) readonly visibleDays!: number;

  get week(): Array<WeeklyScheduleWeekDay> {
    return Array(this.visibleDays)
      .fill(moveDateByDays)
      .map((func, index) => ({
        date: func(this.startDate, index),
        items: []
      }));
  }

  get itemsByDay(): Array<WeeklyScheduleWeekDay> {
    return this.items.reduce((days, item) => {
      const itemStart = new Date(item.start);
      itemStart.setHours(0, 0, 0, 0);

      return days.map(day => {
        return day.date.getTime() === itemStart.getTime()
          ? {...day, items: day.items.concat([item])}
          : day;
      });
    }, this.week);
  }

  canceledItemsByDay(date: Date): Array<WeeklyScheduleItem> {
    return this.canceledItems.filter(item =>
      stringifyDate(item.start, DATE_FORMAT.DATE) === stringifyDate(date, DATE_FORMAT.DATE)
    );
  }
}
