<template>
  <object-select
    :options="options"
    :value="selectedValue"
    :placeholder="placeholder"
    :state="state"
    :disabled="disabled"
    @input="update"
  />
</template>

<script>
import ObjectSelect from "./ObjectSelect";

export default {
  components: {ObjectSelect},
  props: {
    value: {type: [Boolean, Object], default: null},
    state: ObjectSelect.props.state, // eslint-disable-line
    placeholder: {type: String, default: "Wybierz"},
    disabled: {type: Boolean, default: false},
  },
  data() {
    return {
      options: [
        {value: true, name: "TAK"},
        {value: false, name: "NIE"}
      ],
    };
  },
  computed: {
    selectedValue() {
      if (this.value === null) {
        return null
      } else {
        return this.options.find((option) => option.value === this.value);
      }
    },
  },
  methods: {
    update(option) {
      this.$emit("input", option ? option.value : null);
    },
  },
}
</script>
