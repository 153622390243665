<template>
  <div>
    <card
      :loading="loading"
      title="Dane osobowe"
    >
      <template #control>
        <is-granted
          :privileges="['CHANGE_PATIENT_PERSONAL_DATA']"
        >
          <b-btn
            v-b-modal.personalData
            :disabled="loading || errors.length > 0"
            class="btn-sm"
            variant="primary"
          >
            <i class="fa fa-pencil-alt" />
          </b-btn>
        </is-granted>
      </template>
      <error-message :errors="errors" />
      <p>{{ patient.name }} {{ patient.surname }}</p>
      <p v-if="patient.familyName">
        Nazwisko Rodowe: {{ patient.familyName }}
      </p>
      <p>Data urodzenia: {{ patient.birthdate }}</p>
      <p>PESEL: {{ patient.pesel }}</p>
      <p v-if="patient.emailAddress">
        Adres email: {{ patient.emailAddress }}
      </p>
      <p>Telefon: {{ patient.phoneNumber }}</p>
      <p v-if="patient.ice">
        Kontakt w razie wypadku: {{ patient.ice }}
      </p>
    </card>
    <b-modal
      id="personalData"
      size="lg"
      hide-footer
      no-close-on-backdrop
      title="Edycja danych osobowych"
      title-tag="h3"
    >
      <personal-data-form
        :patient-id="patientId"
        :personal-data="patient"
        :modal-layout="true"
        @updatePersonalData="updatePersonalData"
        @cancel="cancel"
      />
    </b-modal>
  </div>
</template>

<script>
import read from "../../../rest/read";
import ErrorMessage from "../../Form/ErrorMessage";
import {errorsMixin} from "../../../mixins/errorsMixin.js";
import processResponseException from "../../../utils/errors/processResponseException";
import PersonalDataForm from "../PersonalDataForm";
import Card from "../../Card";
import IsGranted from "../../IsGranted";

export default {
  components: {
    IsGranted,
    Card,
    PersonalDataForm,
    ErrorMessage,
  },
  mixins: [errorsMixin],
  props: {
    patientId: {type: String, default: null},
  },
  data() {
    return {
      patient: {},
      loading: false,
    };
  },
  watch: {
    patientId() {
      this.loadPatientData();
    },
  },
  async mounted() {
    await this.loadPatientData();
  },
  methods: {
    async loadPatientData() {
      this.loading = true;
      try {
        this.patient = await read(`/api/patients/${this.patientId}/personal_data`);
      } catch (exception) {
        this.errors = processResponseException(exception);
      }
      this.loading = false;
    },
    updatePersonalData(personalData) {
      this.patient = personalData;
      this.$emit("patientUpdated");
    },
    cancel() {
      this.$bvModal.hide("personalData");
      this.errors = [];
    },
  },
};
</script>

<style scoped>
  .overflow-hidden {
    overflow: hidden;
  }
</style>
