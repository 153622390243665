










import {Component, Prop} from "vue-property-decorator";
import SingleValueCheckboxGroup from "../../Form/Checkbox/SingleValueCheckboxGroup.vue";
import t from "../../../i18n";
import Vue from "vue";
import type {SimplifiedIpzEntry} from "../../../types/SimplifiedIpzTypes";

interface Option {
  text: string;
  value: string;
}

@Component({
  name: "SimplifiedIpzFilters",
  components: {SingleValueCheckboxGroup},
})

export default class SimplifiedIpzFilters extends Vue {
  @Prop({type: String, default: null}) readonly value!: string;
  @Prop({type: Array, default: () => []}) readonly entries!: Array<SimplifiedIpzEntry>;


  get types(): Array<string> {
    return [
      "appointments",
      "manual",
      "continuous_stay_created",
      "survey_created",
    ];
  }

  get options(): Array<Option> {
    return this.types.map(value => {
      const translation:string = t(`@simplified-ipz-entry-type-${value}`);
      const typeCount: number = this.entries.filter(entry => entry.type === value).length;
      return {
        value,
        text: `${translation} (${typeCount})`,
      }
    });
  }

  input(option: Option): void {
    this.$emit("input", option);

  }
}
