<template>
  <b-form-group
    id="displayModeSelect"
    :description="description"
    label-for="displayModeSelectInput"
  >
    <template #label>
      <span class="col-form-label">
        Tryb wyświetlania
      </span>
    </template>
    <b-form-radio-group
      id="displayModeSelectInput"
      :checked="value"
      :options="options"
      buttons
      @input="input"
    />
  </b-form-group>
</template>

<script>

export default {
  name: "CoordinatedTeamDisplayModeRadio",
  props: {
    value: {type: Boolean, default: true},
  },
  computed: {
    options() {
      return [
        {
          html: "<i class=\"fa fa-users\" title=\"Zespoły\"></i>",
          value: false,
          label: "Zespoły",
        },
        {
          html: "<i class=\"fa fa-user-md\" title=\"Specjaliści\"></i>",
          value: true,
          label: "Specjaliści",
        },
      ];
    },
    description() {
      const option = this.options.find((option) => option.value === this.value);
      return option ? option.label : null;
    },
  },
  methods: {
    input(option) {
      this.$emit("input", option);
    },
  },
}
</script>
