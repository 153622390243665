var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('transition',{attrs:{"name":"fade"}},[(!_vm.attachments.length)?_c('div',{staticClass:"text-center m-3"},[_c('no-attachments-panel',{attrs:{"icon-class":"fa-file","panel-text":"Brak załączników powiązanych z tym pacjentem"}})],1):_vm._e()]),(_vm.attachments.length)?_c('b-table',{attrs:{"fields":_vm.fields,"hover":"","items":_vm.attachments,"responsive":true},scopedSlots:_vm._u([{key:"cell(name)",fn:function(ref){
var ref_item = ref.item;
var attachmentId = ref_item.attachmentId;
var name = ref_item.name;
return [_c('tooltip',{attrs:{"tooltip-content":("Pobierz plik " + name)}},[_c('a',{staticClass:"text-body attachment-name-link",attrs:{"href":("/api/files/" + attachmentId),"download":""}},[_vm._v(" "+_vm._s(name)+" ")])])]}},{key:"cell(size)",fn:function(ref){
var size = ref.item.size;
return [_c('file-size',{attrs:{"size":size}})]}},{key:"cell(uploadDate)",fn:function(ref){
var uploadDate = ref.item.uploadDate;
return [_c('date-time',{attrs:{"value":uploadDate}})]}},{key:"cell(options)",fn:function(ref){
var item = ref.item;
return [_c('is-granted',{attrs:{"privileges":['UPDATE_PATIENT_ATTACHMENT'],"component":"fragment"}},[_c('tooltip',{staticClass:"btn btn-sm btn-primary btn-block m-1",attrs:{"component":"button","tooltip-content":"Edytuj nazwę i kategorię wizyty"},on:{"click":function($event){return _vm.openEditAttachmentModal(item)}}},[_c('span',{staticClass:"fas fa-pencil-alt"}),_vm._v(" Edytuj ")])],1),(!_vm.hideAppointmentRefOptions && !item.continuousStayId)?[(item.appointmentId)?_c('tooltip',{staticClass:"btn btn-sm btn-outline-primary btn-block m-1",attrs:{"component":"router-link","to":{
            name: 'viewDocumentAppointment',
            params: {appointmentId : item.appointmentId},
            hash: ("#appointmentDocumentId=" + (item.appointmentDocumentId)),
          },"tooltip-content":"Przejdź do karty wizyty"}},[_vm._v(" Karta wizyty ")]):_vm._e(),_c('tooltip',{staticClass:"btn btn-sm btn-outline-primary btn-block m-1",attrs:{"component":"button","tooltip-content":item.appointmentId ? 'Zmień powiązany dokument' : 'Dodaj informację o wizycie',"type":"button"},on:{"click":function($event){return _vm.openAddAttachmentAppointment(item.attachmentId, item.appointmentDocumentId)}}},[_vm._v(" "+_vm._s(item.appointmentId ? "Zmień" : "Przypisz")+" wizytę ")])]:_vm._e(),_c('is-granted',{attrs:{"privileges":['DELETE_ATTACHMENT'],"component":"fragment"}},[_c('tooltip',{staticClass:"btn btn-sm btn-outline-secondary btn-block m-1",attrs:{"component":"button","tooltip-content":"Usuń załącznik","type":"button"},on:{"click":function($event){return _vm.openDeleteAttachmentModal(item.attachmentId)}}},[_vm._v(" Usuń ")])],1)]}}],null,false,1798485636)}):_vm._e(),_c('add-attachment-appointment',{attrs:{"attachment-id":_vm.selectedAttachment ? _vm.selectedAttachment.attachmentId : null,"appointment-document-id":_vm.selectedAttachment ? _vm.selectedAttachment.appointmentDocumentId : null,"patient-id":_vm.patientId},on:{"close":function($event){_vm.selectedAttachment = null},"input":function($event){return _vm.updateAppointment($event)}}}),_c('delete-attachment-modal',{attrs:{"attachment-id":_vm.deleteAttachmentId},on:{"attachmentDeleted":function($event){return _vm.attachmentDeleted($event)}}}),_c('edit-attachment-modal',{attrs:{"id":_vm.editAttachmentModalId,"attachment":_vm.selectedAttachment},on:{"close":function($event){_vm.selectedAttachment = null},"updated":function($event){return _vm.updateAttachment($event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }