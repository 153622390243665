<template>
  <b-modal
    id="delete-institution-modal"
    no-close-on-backdrop
    title="Usuwanie instytucji"
    title-tag="h3"
    @hide="clearErrors"
  >
    <template #default>
      <p>Czy na pewno chcesz usunąć tę instytucję?</p>
      <error-message
        :errors="errors"
        field="institutionId.institutionId"
      />
    </template>
    <template #modal-footer>
      <button
        class="btn btn-secondary"
        @click="hideModal"
      >
        Anuluj
      </button>
      <button
        :disabled="loading"
        class="btn btn-primary"
        @click="deleteInstitution"
      >
        <i
          :class="loading ? 'fa-spin fa-spinner' : 'fa-check'"
          class="fa"
        />
        Zapisz
      </button>
    </template>
  </b-modal>
</template>

<script>
import ErrorMessage from "../Form/ErrorMessage";
import {errorsMixin} from "../../mixins/errorsMixin.js";
import remove from "../../rest/remove";

export default {
  components: {ErrorMessage},
  mixins: [errorsMixin],
  props: {
    institutionId: {type: String, default: null},
  },
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    async deleteInstitution() {
      this.clearErrors();
      this.loading = true;
      try {
        await remove(`/api/institutions/${this.institutionId}`, {});
        this.$emit("institutionDeleted");
        this.hideModal();
      } catch (exception) {
        this.errors = exception.response.data.errors;
      }
      this.loading = false;
    },
    hideModal() {
      this.$bvModal.hide("delete-institution-modal");
    },
    clearErrors() {
      this.errors = [];
    },
  }
}
</script>
