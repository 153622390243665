<template>
  <loading-mask :loading="loading">
    <template v-if="overview">
      <template v-if="!therapeuticPlanId">
        <button
          :disabled="overview.readonly"
          type="submit"
          class="btn btn-primary btn-sm"
          @click="createNewTherapeuticPlan"
        >
          Nowy Plan Terapeutyczny
        </button>
      </template>
      <template v-if="therapeuticPlanId">
        <therapeutic-plan-details
          :ipz-id="ipzId"
          :fields="fields"
          :overview="overview"
          :therapeutic-plan-id="therapeuticPlanId"
          @update="loadData"
        />
      </template>
    </template>
  </loading-mask>
</template>
<script>
import read from "../../../rest/read";
import create from "../../../rest/create";
import TherapeuticPlanDetails from "../../../components/Ipz/TherapeuticPlan/TherapeuticPlanDetails";
import {generateUuid} from "../../../utils/uuid/generateUuid";
import LoadingMask from "../../../components/Loading/LoadingMask";

export default {
  components: {LoadingMask, TherapeuticPlanDetails},
  props: {
    ipzId: {type: String, required: true},
  },
  data() {
    return {
      overview: null,
      therapeuticPlanId: null,
      fields: null,
      structureVersion: null,
      loading: false,
    }
  },
  watch:{
    async ipzId() {
      await this.loadData();
    },
  },
  async mounted() {
    await this.loadData();
  },
  methods: {
    async createNewTherapeuticPlan(){
      this.loading = true;
      await create(`/api/ipzs/${this.ipzId}/therapeutic-plans`, {
        ipzId: this.ipzId,
        therapeuticPlanId: generateUuid(),
        interventionCardId: generateUuid()
      });
      await this.loadData();
      this.loading = false;
    },
    async loadData() {
      this.loading = true;
      const {
        overview, fields, structureVersion, therapeuticPlanId
      } = await read(`/api/ipzs/${this.ipzId}/therapeutic-plans`);
      this.overview = overview;
      this.fields = fields;
      this.structureVersion = structureVersion;
      this.therapeuticPlanId = therapeuticPlanId;
      this.loading = false;
    }
  }
}
</script>
