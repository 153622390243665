<template>
  <object-select
    :value="selectedCourseTypes"
    :options="options"
    :state="state"
    multiple
    placeholder="Wybierz rodzaj kursu"
    @input="update"
  />
</template>

<script>
import ObjectSelect from "../Form/Select/ObjectSelect";
import t from "../../i18n";
export default {
  components: {
    ObjectSelect
  },
  model: {
    prop: "courseTypes",
  },
  props: {
    courseTypes: {type: Array, default: () => []},
    state: ObjectSelect.props.state, //eslint-disable-line
  },
  computed: {
    selectedCourseTypes () {
      return this.options.filter((option) => this.courseTypes.includes(option.value));
    },
    options() {
      return [
        {name: t("@prevention.for_specialists"), value: "for_specialists"},
        {name: t("@prevention.class_branches"), value: "class_branches"},
        {name: t("@prevention.for_parents"), value: "for_parents"},
        {name: t("@prevention.ozdkp_relatives"), value: "ozdkp_relatives"},
        {name: t("@prevention.social_campaign"), value: "social_campaign"}
      ];
    },
  },
  methods: {
    update(courseTypes) {
      this.$emit("input", courseTypes.map(option => option.value));
    },
  },
}
</script>
