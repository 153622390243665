<template>
  <vue-multiselect
    v-tabindex
    :value="selectedOption"
    :options="options"
    :multiple="multiple"
    :state="state"
    :class="{invalid: isInvalid}"
    :placeholder="placeholder"
    :loading="loading"
    :disabled="disabled"
    :clear-on-select="!multiple"
    :internal-search="false"
    label="displayName"
    close-on-select
    allow-empty
    deselect-group-label="Odznacz grupę"
    deselect-label="Odznacz"
    select-group-label="Wybierz grupę"
    tag-placeholder="Utwórz tag"
    select-label="Wybierz"
    selected-label="Wybrany"
    track-by="patientId"
    @input="update"
    @search-change="searchChange"
    @open="open"
  >
    <template #noResult>
      <span>Brak wyników wyszukiwania</span>
    </template>
    <template #noOptions>
      <span />
    </template>
  </vue-multiselect>
</template>

<script>
import read from "../../rest/read";
import VueMultiselect from "vue-multiselect/src/Multiselect";
import {mapState} from "vuex";

export default {
  components: {VueMultiselect},
  directives: {
    tabindex: {
      inserted(el) {
        el.setAttribute("tabindex", 0);
      },
    },
  },
  props: {
    value: {type: [Array, Object, String], default: null},
    multiple: {type: Boolean, default: false},
    clearOnClick: {type: Boolean, default: false},
    state: {type: Boolean, default: null},
    disabled: {type: Boolean, default: false},
  },
  data() {
    let options;
    if (this.value == null || "string" === typeof this.value) {
      options = [];
    } else if (Array.isArray(this.value)) {
      options = this.value;
    } else {
      options = [this.value]
    }

    return {
      typingTimeout: null,
      options,
      selectedOption: null,
      loading: false,
    };
  },
  computed: {
    ...mapState({
      clinicParameters: state => state.clinicParameters.parameters,
    }),
    isInvalid() {
      return false === this.state;
    },
    placeholder() {
      if (!this.clinicParameters) {
        return "Wyszukaj pacjenta";
      }
      return this.clinicParameters.patientAnonymous
        ? "Numer Teczki / Numer Zewnętrzny"
        : "Nazwisko, PESEL lub Numer Teczki";
    },
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.loadSelectedOption();
      },
    },
  },
  methods: {
    update(option) {
      this.$emit("input", option);
    },
    searchChange(phrase) {
      clearTimeout(this.typingTimeout);
      if (phrase.length) {
        this.typingTimeout = setTimeout(async () => {
          this.loading = true;
          const {items} = await read("/api/patients", {phrase});
          this.options = items;
          this.loading = false;
        }, 500);
      }
    },
    async loadSelectedOption() {
      if (null === this.value) {
        this.selectedOption = null;
        return;
      }

      if (Array.isArray(this.value) && "string" === typeof this.value[0]) {
        //Array<string>
        const responses = await Promise.all(this.value.map(patientId => read("/api/patients", {patientId})));
        this.selectedOption = responses.reduce((patients, {items}) => [...patients, ...items], []);
        this.options = this.selectedOption;
        this.update(this.selectedOption);

      } else if (Array.isArray(this.value)) {
        //Array<object>
        this.selectedOption = this.value;
        this.options = this.selectedOption;

      } else {
        if ("string" === typeof this.value) {
          // string
          const {items} = await read("/api/patients", {patientId: this.value});
          this.selectedOption = items[0];
          this.update(this.selectedOption);
        } else {
          // object
          this.selectedOption = this.value;
        }
        this.options = [this.selectedOption];
      }
    },
    open() {
      this.$emit("open");
      if (this.clearOnClick) {
        this.options = [];
      }
    },
  },
};
</script>
