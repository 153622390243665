














import Vue from "vue";
import ObjectSelect from "../Form/Select/ObjectSelect.vue";
import {Component, Prop, Ref} from "vue-property-decorator";
import type {Option} from "../../types/MultiSelectTypes";

@Component({
  name: "AdditionalRightsSelect",
  components: {ObjectSelect}
})
export default class AdditionalRightsSelect extends Vue {
  @Prop({type: Boolean, default: false}) readonly clearable!: boolean;
  @Prop({type: Boolean, default: null}) readonly state!: boolean;
  @Prop({type: String, default: "Wybierz szczególne uprawnienie"}) readonly placeholder!: string;
  @Prop({type: String, default: null}) readonly value!: Option["value"];

  @Ref("multiselect") readonly multiselect!: ObjectSelect;

  get options(): Array<Option> {
    return [
      {value: "AZ", name: "AZ"},
      {value: "BW", name: "BW"},
      {value: "CN", name: "CN"},
      {value: "DN", name: "DN"},
      {value: "IB", name: "IB"},
      {value: "IN", name: "IN"},
      {value: "IW", name: "IW"},
      {value: "PO", name: "PO"},
      {value: "S", name: "S"},
      {value: "ZK", name: "ZK"},
      {value: "WP", name: "WP"},
    ];
  }

  get selected(): Option|null {
    if (!this.value) {
      return null;
    }
    return this.options.find(option => option.value === this.value) || null;
  }

  private update(option: Option): void {
    this.$emit("input", option ? option.value : null);
  }

  public activate(): void {
    this.multiselect.activate();
  }

  public deactivate(): void {
    this.multiselect.deactivate();
  }

  select(option: Option): void {
    this.$emit("select", option);
  }

  blur():void {
    this.$emit("blur");
  }
}
