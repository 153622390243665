




















































import ErrorsMixin from "../../mixins/ErrorsMixin";
import {Component, Mixins, Prop, Watch} from "vue-property-decorator";
import ErrorMessage from "../Form/ErrorMessage.vue";
import WorkerDefaultTreatmentTypeSelect from "./WorkerDefaultTreatmentTypeSelect.vue";
import read from "../../rest/read";
import processResponseException from "../../utils/errors/processResponseException";
import update from "../../rest/update";

interface TreatmentType {
  value: string;
  name: string;
  active: boolean;
  appointmentDocumentTemplate: string;
  durationLocally: number;
  durationRemotely: number;
  description: string;
  parentId: string|null;
  isDisabled: boolean;
  children?: Array<TreatmentType>;
  classification: string|null;
  label: string;
  id: string;
}

interface SubmittedData {
  defaultTreatmentType?: string|null;
}

@Component({
  name: "DefaultTreatmentTypeSettings",
  components: {WorkerDefaultTreatmentTypeSelect, ErrorMessage},
})
export default class DefaultTreatmentTypeSettings extends Mixins(ErrorsMixin) {
  @Prop({type: String, default: null}) readonly workerId!: string;

  private loading: boolean = false;
  private defaultTreatmentType: string|null = null;
  private savedDefaultTreatmentType: string|null = null;
  private treatmentTypes: Array<TreatmentType> = [];

  @Watch("workerId", {immediate: true}) async handleData(value: string|null): Promise<void> {
    if (!!value) {
      await this.loadTreatmentTypes();
    } else {
      this.treatmentTypes = [];
      this.savedDefaultTreatmentType = null;
      this.defaultTreatmentType = null;
    }
  }

  private async loadTreatmentTypes(): Promise<void> {
    this.loading = true;
    try {
      const {
        defaultTreatmentType,
        treatmentTypes,
      } = await read(`/api/worker/${this.workerId}/treatmentTypes`);
      this.treatmentTypes = treatmentTypes;
      this.savedDefaultTreatmentType = defaultTreatmentType;
      this.defaultTreatmentType = defaultTreatmentType;
    } catch(e) {
      this.errors = processResponseException(e);
    }
    this.loading = false;
  }

  private clearErrors(): void {
    this.errors = [];
  }

  private close(): void {
    this.$bvModal.hide("default-treatment-type-settings");
  }

  private onHide():void {
    this.defaultTreatmentType = this.savedDefaultTreatmentType;
    this.clearErrors();
  }

  private async submit(): Promise<void> {
    this.clearErrors();
    this.loading = true;
    try {
      const data = {
        defaultTreatmentType: this.defaultTreatmentType,
      };
      await update<SubmittedData>(`/api/worker/${this.workerId}/defaultTreatmentType`, data);
      this.savedDefaultTreatmentType = this.defaultTreatmentType;
      this.close();
    } catch(e) {
      this.errors = processResponseException(e);
    }
    this.loading = false;
  }
}
