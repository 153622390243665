<template>
  <div
    v-if="notifications.length"
    class="col-12"
  >
    <card title="Informacje systemowe">
      <system-notifications-list
        :notifications="notifications"
      />
    </card>
  </div>
</template>
<script>
import SystemNotificationsList from "./SystemNotificationsList";
import read from "../../rest/read";
import Card from "../Card";

export default {
  components: {Card, SystemNotificationsList},
  data() {
    return {
      notifications: [],
    };
  },
  async mounted() {
    const data = await read("/api/system-notifications", {limit: 5});
    this.notifications = data.items;
  },
}
</script>
