































import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";
import {Error} from "../../../rest";
import ErrorMessage from "../ErrorMessage.vue";
import type {HospitalizationValues} from "../../../types/HospitalizationTypes";

@Component({
  name: "InputOrNotRememberRadio",
  components: {ErrorMessage}
})
export default class InputOrNotRememberRadio extends Vue {
  @Prop({type: Object, default: () => ({})}) readonly value!: HospitalizationValues;
  @Prop({type: Array, default: () => []}) readonly errors!: Array<Error>;
  @Prop({type: String, default: "Rok"}) readonly placeholder!: string;
  @Prop({type: Boolean, default: false}) readonly number!: boolean;

  get inputValue(): HospitalizationValues["value"] {
    return this.value && this.value.value || null;
  }
  get doesRemember(): HospitalizationValues["doesRemember"] {
    return this.value && (this.value.doesRemember !== undefined)
      ? this.value.doesRemember
      : null;
  }

  state(field: string): boolean|null {
    return this.errors.find((error) => error.field === field) ? false : null;
  }

  updateRadio(radioVal: HospitalizationValues["doesRemember"]): void {
    const updateValue = {
      doesRemember: radioVal,
      value: this.inputValue || null,
    };
    this.$emit("input", updateValue);
  }

  updateComponent(componentVal: HospitalizationValues["value"]): void {
    let value: HospitalizationValues["value"] = "string" === typeof componentVal ? componentVal.trim() : componentVal;

    if (value != null && this.number) {
      const numVal = parseFloat(value);
      value = isNaN(numVal) ? value : numVal;
    }

    const updateValue = {
      value,
      doesRemember: true,
    };
    this.$emit("input", updateValue);
  }
}
