





























































import {Component} from "vue-property-decorator";
import ErrorMessage from "../../components/Form/ErrorMessage.vue";
import DatePicker from "../../components/Form/DatePicker/DatePicker.vue";
import {mixins} from "vue-class-component";
import ErrorsMixin from "../../mixins/ErrorsMixin.ts";
import stringifyDate from "../../utils/date/stringifyDate";
import {createUrl} from "../../utils/pageUrl/createUrl";
import DATE_FORMAT from "../../utils/date/DATE_FORMAT";
import {isGranted} from "../../security/isGranted";
import BackButton from "../../components/BackButton.vue";
import processResponseException from "../../utils/errors/processResponseException";
import ReportContainer from "../../components/Report/ReportContainer.vue";

@Component({
  name: "OpenServicesBySpecialistsReport",
  components: {BackButton, ErrorMessage, DatePicker, ReportContainer},
})
export default class OpenServicesBySpecialistsReport extends mixins(ErrorsMixin) {

  startDate: string = stringifyDate(new Date());
  endDate: string = stringifyDate(new Date());
  isLoading: boolean = false;
  canUseReport: boolean = false;

  async mounted() {
    await this.loadUser();
  }

  async loadUser() {
    this.isLoading = true;
    try {
      await Promise.all([
        this.canUseThisReport(),
      ]);
    } catch (exception) {
      this.errors = processResponseException(exception);
    }
    this.isLoading = false;
  }

  get parsedStartDate() {
    const date = new Date(this.startDate);
    date.setHours(0, 0, 0, 0);
    return this.startDate ? date : null;
  };

  get parsedEndDate() {
    const date = new Date(this.endDate);
    date.setHours(23, 59, 59);
    return this.endDate ? date : null;
  };

  get reportUrl() {
    let baseUrl = "/api/report/open-services-by-specialist";
    return createUrl(baseUrl, this.filters);
  };

  get filters() {
    return {
      startDate: this.parsedStartDate ? stringifyDate(this.parsedStartDate, DATE_FORMAT.DATE) : null,
      endDate: this.parsedEndDate ? stringifyDate(this.parsedEndDate, DATE_FORMAT.DATE) : null
    }
  }
  async canUseThisReport() {
    this.canUseReport = await isGranted(["GET_OPEN_SERVICES_BY_SPECIALISTS_REPORT"]);
  }

  validateReportDates() {
    this.errors = [];
    if(!this.parsedStartDate){
      this.errors = [
        {
          message: "Data początkowa nie może być pusta",
          field: "startDate"
        },
      ];
    }
    if(!this.parsedEndDate){
      this.errors = [
        {
          message: "Data końcowa nie może być pusta",
          field: "endDate"
        },
      ];
    }
    if (this.parsedStartDate && this.parsedEndDate
        && this.parsedStartDate.getTime() >= this.parsedEndDate.getTime()) {
      this.errors = [
        {
          message: "Data początkowa powinna być wcześniejsza niż data końcowa",
          field: "startDate"
        },
        {
          message: "Data końcowa powinna być późniejsza niż data początkowa",
          field: "endDate"
        },
      ];
    }
    return;
  };

}
