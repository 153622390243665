import Vuex, {StoreOptions} from "vuex";
import createPersistedState from "vuex-persistedstate";
import sidebarModule from "./modules/sidebar";
import scheduleAppointmentModule from "./modules/scheduleAppointment";
import currentUser from "./modules/currentUser";
import clinicParameters from "./modules/clinicParameters";
import stickyPatient from "./modules/stickyPatient";
import security from "./modules/security";
import session from "./modules/session";
import lastEditedAppointment from "./modules/lastEditedAppointment";
import appointmentListFilters from "./modules/appointmentListFilters";
import registrationFilters from "./modules/registrationFilters";
import appointmentListItem from "./modules/appointmentListItem";
import interventionNotifications from "./modules/interventionNotifications";
import toastNotification from "./modules/toastNotification";
import splashScreen from "./modules/splashScreen";
import {RootState} from "./types";
import geolocalization from "./modules/geolocalization";

export function createStore() {
  const store: StoreOptions<RootState> = {
    actions: {
      clearPersistedState({commit, dispatch}) {
        dispatch("lastEditedAppointment/clearAppointmentDetails");
        commit("appointmentListFilters/saveAppointmentListFilters", null);
        commit("registrationFilters/saveRegistrationFilters", null);
        commit("clinicParameters/clear");
        localStorage.removeItem("vuex");
      },
    },
    getters: {},
    modules: {
      sidebar: sidebarModule,
      scheduleAppointment: scheduleAppointmentModule,
      currentUser,
      clinicParameters: clinicParameters,
      stickyPatient,
      security,
      session,
      lastEditedAppointment,
      appointmentListFilters,
      registrationFilters,
      appointmentListItem,
      interventionNotifications,
      toastNotification,
      splashScreen,
      geolocalization,
    },
    mutations: {},
    plugins: [createPersistedState({
      reducer: state => ({
        sidebar: state.sidebar,
        lastEditedAppointment: state.lastEditedAppointment,
        appointmentListFilters: state.appointmentListFilters,
        registrationFilters: state.registrationFilters
      }),
    })],
    state: {},
  };
  return new Vuex.Store<RootState>(store);
}
