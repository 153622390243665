import {Module} from "vuex";
import {SidebarState} from "./sidebar/types";
import {actions} from "./sidebar/actions";
import {getters} from "./sidebar/getters";
import {mutations} from "./sidebar/mutations";
import {state} from "./sidebar/state";
import {RootState} from "../types";

const sidebar: Module<SidebarState, RootState> = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
}

export default sidebar;
