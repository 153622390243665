





































































import {Component, Emit, Prop, Watch} from "vue-property-decorator";
import ErrorMessage from "../../../Form/ErrorMessage.vue";
import ErrorsMixin from "../../../../mixins/ErrorsMixin.ts";
import processResponseException from "../../../../utils/errors/processResponseException";
import create from "../../../../rest/create";
import {generateUuid} from "../../../../utils/uuid/generateUuid";
import read from "../../../../rest/read";
import debounce from "lodash/debounce";
import type {SurveyItem} from "../../../../types/SurveyTypes";

interface PatientPersonalData {
  emailAddress: null|string;
}

const deferred = debounce(f => f(), 2000, {maxWait: 3000});

@Component({
  name: "SendSurveyInvitationModal",
  components: {ErrorMessage,},
})
export default class SendSurveyInvitationModal extends ErrorsMixin {
  @Prop({type: Object, default: null}) readonly survey!: SurveyItem;
  @Prop({type: String, required: true}) readonly patientId!: string;

  defaultEmail: null|string = null;
  recipientEmail: null|string = null;
  loading: boolean = false;
  showConfirmation: boolean = false;

  async send() {
    this.loading = true;
    try {
      const surveyInvitationId = generateUuid();

      await create(`/api/surveys/${this.survey.surveyId}/invitations`, {
        surveyInvitationId,
        recipientEmail: this.recipientEmail,
      });

      this.input({...this.survey, recipientEmail: this.recipientEmail});
      this.showConfirmation = true;
      deferred(() => {
        this.closeModal();
        this.showConfirmation = false;
      });
      this.resetModalData();
    } catch (exception) {
      this.errors = processResponseException(exception);
    }
    this.loading = false;
  }

  @Watch("patientId")
  async onPatientIdChange(): Promise<void>
  {
    await this.loadModalData();
  }

  async mounted() {
    await this.loadModalData();
  }

  async loadModalData() {
    this.loading = true;
    const {emailAddress} = await read<PatientPersonalData>(`/api/patients/${this.patientId}/personal_data`);
    this.recipientEmail = this.defaultEmail = emailAddress;
    this.loading = false;
  }

  resetModalData() {
    this.recipientEmail = this.defaultEmail;
    this.errors = [];
  }

  closeModal() {
    this.$bvModal.hide("send-survey-invitation-modal");
  }

  @Emit()
  input(value: Survey|null): Survey|null {
    return value;
  }
}
