import {mapActions, mapMutations} from "vuex";
import read from "../rest/read";
import parseDate from "../utils/date/parseDate";
import {ModalType} from "../store/modules/scheduleAppointment/types";

export const appointmentItemActionsMixin = {
  props: {
    status: {required: true, type: String},
    mobile: {required: true, type: Boolean},
    appointmentId: {required: true, type: String},
    mainWorkerId: {required: true, type: String},
    workers: {required: true, type: Array},
    patients: {required: true, type: Array},
    group: {required: true, type: Boolean},
    date: {required: true, type: Date},
    cancelAllowedFromGroup: {required: true, type: Boolean},
    changePatientsAllowedFromGroup: {required: true, type: Boolean},
    removePatientAllowedFromGroup: {required: true, type: Boolean},
    patientId: {type: String, default: null},
    continuousStay: {type: Boolean, default: false},
    patientContinuousStays: {type: Array, default: ()=>[]},
    branchId: {type: String, required: true},
    branchName: {type: String, required: true},
  },
  data() {
    return {
      scheduleDataLoading: false,
    };
  },
  computed: {
    currentItem() {
      return {
        status: this.status,
        mobile: this.mobile,
        appointmentId: this.appointmentId,
        mainWorkerId: this.mainWorkerId,
        workers: this.workers,
        patients: this.patients,
        group: this.group,
        date: this.date,
        cancelAllowedFromGroup: this.cancelAllowedFromGroup,
        changePatientsAllowedFromGroup: this.changePatientsAllowedFromGroup,
        removePatientAllowedFromGroup: this.removePatientAllowedFromGroup,
        patientId: this.patientId,
        mainWorker: this.mainWorker,
        patient: this.patient,
        continuousStay: this.continuousStay,
        patientContinuousStays: this.patientContinuousStays,
        branchId: this.branchId,
        branchName: this.branchName,
      };
    },
    mainWorker() {
      return this.workers.find(worker => worker.workerId === this.mainWorkerId);
    },
    patient() {
      return this.patientId
        ? this.patients.find(patient => patient.patientId === this.patientId)
        : this.patients[0];
    },
  },
  methods: {
    ...mapActions({
      setStickyPatient: "stickyPatient/load",
      clearStickyPatient: "stickyPatient/clear",
      setScheduleAppointmentData: "scheduleAppointment/setAppointmentData",
      openScheduleAppointmentModal: "scheduleAppointment/openModal",
    }),
    ...mapMutations("appointmentListItem", ["saveAppointmentListItem",]),
    openReportTreatmentTypeModal() {
      this.saveAppointmentListItem(this.currentItem);
      setTimeout(() => this.$bvModal.show("report-treatment-type-modal"));
    },
    openChangeAppointmentParametersModal() {
      this.saveAppointmentListItem(this.currentItem);
      setTimeout(() => this.$bvModal.show("change-appointment-parameters-modal"));
    },
    openCancelAppointmentDeferredModal() {
      this.saveAppointmentListItem(this.currentItem);
      setTimeout(() => this.$bvModal.show("cancel-reason-deferred-modal"));
    },
    openCancelAppointmentModal() {
      this.saveAppointmentListItem(this.currentItem);
      setTimeout(() => this.$bvModal.show("cancel-reason-modal"));
    },
    openCheckInModal() {
      this.saveAppointmentListItem(this.currentItem);
      setTimeout(() => this.$bvModal.show("check-in-appointment-modal"));
    },
    async editAppointment() {
      if (this.scheduleDataLoading) {
        return;
      }
      this.scheduleDataLoading = true;
      this.saveAppointmentListItem(this.currentItem);
      const scheduledData = await read(`/api/appointment/${this.appointmentId}/schedule`);
      try {
        this.setScheduleAppointmentData({
          ...scheduledData,
          appointmentStart: parseDate(scheduledData.appointmentStart),
          appointmentEnd: parseDate(scheduledData.appointmentEnd),
          reschedule: true,
          patientContinuousStays: this.currentItem.patientContinuousStays,
        });
        this.openScheduleAppointmentModal(this.currentItem.continuousStay
          ? ModalType.CONTINUOUS_STAY
          : ModalType.DEFAULT);

        if (this.patient) {
          await this.setStickyPatient(this.patient.patientId);
        } else {
          this.clearStickyPatient();
        }
      } catch(exception) {
        this.error = true;
      }
      this.scheduleDataLoading = false;
    },
    openReopenCanceledAppointmentModal() {
      this.saveAppointmentListItem(this.currentItem);
      setTimeout(() => this.$bvModal.show("reopen-canceled-appointment-modal"));
    },
    openAppointmentInfoModal() {
      this.saveAppointmentListItem(this.currentItem);
      setTimeout(() => this.$bvModal.show("appointment-info-modal"));
    },
    openCheckInWithdrawalModal() {
      this.saveAppointmentListItem(this.currentItem);
      setTimeout(() => this.$bvModal.show("check-in-withdrawal-modal"));
    },
    openOpenAppointmentModal() {
      if (this.status === "opened") {
        this.$router.push({name: "viewDocumentAppointment", params: {appointmentId: this.appointmentId}});
        return;
      }
      this.saveAppointmentListItem(this.currentItem);
      setTimeout(() => {
        this.$bvModal.show("open-appointment-modal");
      }, 0);
    },
    showDocument() {
      this.$router.push({name: "viewDocumentAppointment", params: {appointmentId: this.appointmentId}});
    },
    showPatientCard() {
      if(!this.patientId && this.patients.length === 1) {
        this.$router.push({name: "patientCard", params: {patientId: this.patients[0].patientId}});
      }
    },
    openRemovePatientFromAppointmentModal() {
      this.saveAppointmentListItem(this.currentItem);
      setTimeout(() => this.$bvModal.show("remove-appointment-patient-modal"));
    },
    openChangePatientsModal() {
      this.saveAppointmentListItem(this.currentItem);
      setTimeout(() => this.$bvModal.show("edit-appointment-patients-modal"));
    },
    updateAppointmentList() {
      this.$emit("appointmentUpdated");
    },
  }
}
