














import type {WorkerObject} from "../../types/WeeklySchedule";
import {Component, Prop} from "vue-property-decorator";
import Vue from "vue";

@Component({
  name: "WeeklyScheduleWorkerName",
})

export default class WeeklyScheduleWorkerName extends Vue {
  @Prop({type: Object, default: null}) readonly worker!: WorkerObject;

  workerHeight: number|string = 0;

  mounted(): void {
    const workerNameHeading: HTMLHeadingElement|null = document.querySelector(`#worker-name-${this.worker.workerId}`);
    if (workerNameHeading) {
      this.workerHeight = `${workerNameHeading.scrollWidth + 80}px`;
    }
  }
}
