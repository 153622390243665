export interface Drug {
  externalId?: number;
  ean?: number;
  name?: string;
  internationalName?: string;
  wrapper?: string;
  form?: string;
  dose?: string;
  size?: number;
  activeSubstance?: Array<ActiveSubstance>
  description?: string,
  availabilityCategory?: AvailabilityCategory;
  confirmedDoseRequired?: boolean;
  cannotReplace?: boolean;
  additionalInformation?: string;
  ignoreWarnings?: boolean;
  refunds?: Array<string>;
  yearlyPrescription?: boolean;
}

export interface ActiveSubstance {
  name: string;
  value: number;
  unit: string;
  denominator: null|Denominator;
  confirmedDose?: string;
  confirmedDoseDescription?: string;
}

export interface Denominator {
  value: number;
  unit: string;
}

export interface Patient {
  patientId: string;
  name: string;
  surname: string;
  pesel: string;
  displayName: string;
  fileNumber?: string;
}

export interface Repayment {
  value: string;
  name: string;
}

export interface Refund {
  value: string;
  hasIndications: boolean;
}

export interface Dosage {
  doseQuantity?: number;
  rateQuantity?: number;
  rateQuantityUnit?: RateQuantityUnit;
}

export enum RateQuantityUnit {
  DAY = "day",
  HOUR = "hour",
}

export interface DosageFormData {
  quantity: number|null;
  rate: string|null;
  hours?: number|null;
}

export interface DosageFormOption {
  value: string|number;
  name: string;
}

export interface NfzBranch {
  branchId: string;
  name: string;
}

export interface PrescriptionDrug extends Drug, Dosage {
  quantity?: number,
  repayment?: string,
  uuid?: string
}

export enum AvailabilityCategory {
  RP = "Rp",
  RPW = "Rpw",
  RPZ = "Rpz"
}

export interface AdditionalRightsType {
  code?: string|null;
  documentSymbol?: string;
}

export interface Package {
  nfzBranch: NfzBranch|null;
  additionalRights: AdditionalRightsType;
  drugs: Array<PrescriptionDrug>;
}

export interface PackageData {
  nfzBranchId?: string;
  additionalRights?: AdditionalRightsType|{};
  prescriptionId: string;
  patientId: string;
  appointmentDocumentId: string;
  drug?: PrescriptionDrug;
}

export interface PrescriptionData {
  password?: string;
  package: Array<PackageData>;
  ignoreWarnings: boolean;
}


export interface PrescriptionStatus {
  status: PrescriptionStatuses;
  code?: string;
  fileId?: string;
}

export enum PrescriptionStatuses {
  CREATED = "created",
  QUEUED = "queued",
  VERIFIED = "verified",
  COMPLETED = "completed",
  REJECTED = "rejected",
}

export interface PrescriptionListFilters {
  continuousStayId?: string;
}
