import Vue from "vue";
import {store} from "./main";
import Router from "vue-router";
import Home from "./views/Dashboards/Home";
import DirectorReports from "./views/Dashboards/DirectorReports";
import InterventionNotificationList from "./views/InterventionNotification/InterventionNotificationList";
import AppointmentList from "./views/Appointment/AppointmentListPage";
import CalendarPage from "./views/Calendar/CalendarPage";
import WeeklySchedule from "./views/Calendar/WeeklySchedule";
import RoomOccupancySchedule from "./views/Calendar/RoomOccupancySchedule";
import PatientList from "./views/Patient/PatientList";
import SupportNetworkPatientList from "./views/SupportNetwork/SupportNetworkPatientList";
import IpzList from "./views/Ipz/IpzList";
import WorkerList from "./views/Worker/WorkerList";
import WorkingHoursList from "./views/Worker/WorkingHoursList";
import CooperatingUnitList from "./views/CooperatingUnit/CooperatingUnitList";
import ReportsMainView from "./views/Reports/ReportsMainView";
import SystemNotifications from "./views/SystemNotifications/SystemNotifications";
import RoomList from "./views/Room/RoomList";
import CourseList from "./views/Prevention/CourseList";
import InstitutionList from "./views/Prevention/InstitutionList";
import DataUnification from "./views/Patient/DataUnification";
import AuditLogList from "./views/Audit/AuditLogList";
import TreatmentTypeListView from "./views/TreatmentTypeList/TreatmentTypeListView";
import Changelog from "./views/State/Changelog";
import WorkerWorkingHours from "./views/Worker/WorkerWorkingHours";
import WorkerAbsence from "./views/Worker/WorkerAbsence";
import WorkerUpdate from "./views/Worker/WorkerUpdate";
import WorkerCreate from "./views/Worker/WorkerCreate";
import PatientPage from "./views/Patient/PatientPage";
import IpzDetailsPanel from "./views/Ipz/Tabs/IpzDetailsPanel";
import MonthlyIpzAppointmentRequirements from "./views/Ipz/MonthlyIpzAppointmentRequirements";
import PatientCreate from "./views/Patient/PatientCreate";
import InstitutionUpdate from "./views/Prevention/InstitutionUpdate";
import InstitutionCreate from "./views/Prevention/InstitutionCreate";
import CourseUpdate from "./views/Prevention/CourseUpdate";
import CourseCreate from "./views/Prevention/CourseCreate";
import PatientReportCard from "./views/Patient/PatientReportCard";
import InterventionNotificationSingle from "./views/InterventionNotification/InterventionNotificationSingle";
import Document from "./views/Appointment/Document";
import ContinuousStayEditView from "./views/ContinuousStay/ContinuousStayEditView";
import EvaluationFormPanel from "./views/Ipz/Tabs/EvaluationFormPanel";
import IcfCreatorPanel from "./views/Ipz/Tabs/IcfCreatorPanel";
import EvaluationResultPanel from "./views/Ipz/Tabs/EvaluationResultPanel";
import EvaluationsPanel from "./views/Ipz/Tabs/EvaluationsPanel";
import CooperatingUnitUpdate from "./views/CooperatingUnit/CooperatingUnitUpdate";
import RoomCreate from "./views/Room/RoomCreate";
import RoomUpdate from "./views/Room/RoomUpdate";
import CooperatingUnitCreate from "./views/CooperatingUnit/CooperatingUnitCreate";
import ChildPatientCreate from "./views/Patient/ChildPatientCreate";
import BaseLayout from "./components/Layout/BaseLayout";
import Settings from "./views/Settings/Settings";
import ScheduleSpecialist from "./views/Reports/ScheduleSpecialist";
import IKUPChildrenView from "./views/Reports/IKUP/IKUPChildrenView";
import IKUPAdultsView from "./views/Reports/IKUP/IKUPAdultsView";
import SpecialistList from "./views/Reports/SpecialistList";
import InterventionsReportForm from "./views/Reports/InterventionsReportForm";
import PatientMedicalCareReportForm from "./views/Reports/PatientMedicalCareReportForm";
import InterventionNotificationUpdate from "./views/InterventionNotification/InterventionNotificationUpdate";
import EligliblePersonsPage from "./views/EligiblePersons/EligliblePersonsPage";
import CrisisPlanPanel from "./views/Ipz/Tabs/CrisisPlanPanel";
import AppointmentMonthlyStats from "./views/Reports/AppointmentMonthlyStats";
import Branch24HPanel from "./views/Ipz/Tabs/Branch24HPanel";
import SetupDataPage from "./views/Setup/SetupDataPage";
import MedicalDiagnosisPanel from "./views/Ipz/Tabs/MedicalDiagnosisPanel";
import PediatricDiagnosisPanel from "./views/Ipz/Tabs/PediatricDiagnosisPanel";
import FunctionalDiagnosisPanel from "./views/Ipz/Tabs/FunctionalDiagnosisPanel";
import TherapeuticPlanPanel from "./views/Ipz/Tabs/TherapeuticPlanPanel";
import SimplifiedIpzPage from "./views/Ipz/Tabs/SimplifiedIpzPage";
import NeurologicalDiagnosisPanel from "./views/Ipz/Tabs/NeurologicalDiagnosisPanel";
import FamilyInterviewDiagnosisPanel from "./views/Ipz/Tabs/FamilyInterviewDiagnosisPanel";
import PsychiatricDiagnosisPanel from "./views/Ipz/Tabs/PsychiatricDiagnosisPanel";
import LoginPage from "./views/Auth/LoginPage"
import RequestPasswordChange from "./views/Auth/RequestPasswordChange";
import ResetPasswordForm from "./views/Auth/ResetPasswordForm";
import Health from "./views/State/Health";
import PageNotFound from "./views/Errors/PageNotFound";
import parseDate from "./utils/date/parseDate";
import DATE_FORMAT from "./utils/date/DATE_FORMAT";
import IpzBaseLayout from "./components/Layout/IpzBaseLayout";
import IKUPAdultsSummary from "./views/Reports/IKUP/IKUPAdultsSummary";
import IKUPChildrenSummary from "./views/Reports/IKUP/IKUPChildrenSummary";
import IKUPSummaryReport from "./views/Reports/IKUP/IKUPSummaryReport";
import CancelReasons from "./views/Reports/CancelReasons";
import ChildIpzMonitoringReport from "./views/Reports/ChildIpzMonitoringReport";
import TargetsAndTasksPanel from "./views/Ipz/Tabs/TargetsAndTasksPanel";
import WorkerEwusAccess from "./views/Worker/WorkerEwusAccess";
import EstablishmentPage from "./views/Establishment/EstablishmentPage";
import ChildIpzPanel from "./views/Ipz/Tabs/ChildIpzPanel";
import PersonListReport from "./views/Reports/PersonListReport";
import MissingDataReport from "./views/Reports/MissingDataReport";
import ContinuousStayReport from "./views/Reports/ContinuousStayReport";
import GeolocalizationList from "./views/Geolocalization/GeolocalizationList";
import Registration from "./views/Calendar/Registration";
import RegistrationSearch from "./views/Calendar/RegistrationSearch";
import TypeOfServicesPerPatient from "./views/Reports/TypeOfServicesPerPatient";
import PatientsSupport from "./views/Reports/PatientsSupport";
import PdfLayout from "./components/Layout/PdfLayout";
import RoomOccupancyReport from "./views/Pdf/RoomOccupancyReport";
import MHCSurveys from "./views/Reports/MHCSurveys";
import OpenServicesBySpecialistsReport from "./views/Reports/OpenServicesBySpecialistsReport";
import Zla from "@/views/Zla/Zla";
import SocialActivityPage from "@/views/SocialActivity/SocialActivityPage";
import MzReportView from "@/views/Reports/MzReportView";
import AppointmentsInBranchesReportForm from "@/views/Reports/AppointmentsInBranchesReportForm";
import InternalReferralsPage from "@/views/InternalReferral/InternalReferralsPage";
import ReferralConsultation from "./views/Referral/ReferralConsultation";
import ReferralHospital from "./views/Referral/ReferralHospital";
import RequestListPage from "./views/Request/RequestListPage";
import WorkOnIpzReport from "./views/Reports/IPZ/WorkOnIpzReport";
import SLReportView from "./views/Reports/SLReportView";
import UserRequestPasswordChange from "@/views/Auth/UserRequestPasswordChange";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      component: BaseLayout,
      children: [
        {
          path: "",
          name: "home",
          component: Home,
          meta: {
            renderTitleBox: true,
            title: "Strona główna",
          },
          props: ({query}) => ({
            ...query,
            opsPage: query.opsPage ? Number(query.opsPage) : null,
          })
        },
        {
          path: "/registration",
          name: "registration",
          component: Registration,
          meta: {
            renderTitleBox: true,
            title: "Rejestracja",
          },
          props: ({query}) => ({
            ...query,
            dayView: query.dayView !== undefined ? JSON.parse(query.dayView) : undefined
          })
        },
        {
          path: "/registration-search",
          name: "registration_search",
          component: RegistrationSearch,
          meta: {
            renderTitleBox: true,
            title: "Wolne terminy",
          },
          props: ({query}) => ({
            ...query,
            start: query.start ? parseDate(query.start) : null,
            end: query.end ? parseDate(query.end) : null,
            workers: query.workers ? query.workers.split(",") : [],
          }),
        },
        {
          path: "/continuous-stays/:continuousStayId",
          name: "continuous_stay_edit",
          component: ContinuousStayEditView,
          meta: {
            renderTitleBox: false,
            title: "Pobyt ciągły",
          },
          props: ({params, query}) => ({
            ...params,
            csaPage: query.csaPage ? Number(query.csaPage) : null,
          }),
        },
        {
          path: "/courses",
          name: "course_list",
          component: CourseList,
          meta: {
            renderTitleBox: true,
            title: "Lista kursów",
          },
          props: ({query}) => ({
            ...query,
            page: query.page ? Number(query.page) : null,
            institutions: query.institutions ? query.institutions.split(",") : [],
            courseTypes: query.courseTypes ? query.courseTypes.split(",") : [],
          }),
        },
        {
          path: "/course_update_view/:courseId",
          name: "course_update_view",
          component: CourseUpdate,
          meta: {
            renderTitleBox: true,
            title: "Edytuj kurs",
          },
          props: ({params}) => ({...params}),
        },
        {
          path: "/institutions",
          name: "institution_list",
          component: InstitutionList,
          meta: {
            renderTitleBox: true,
            title: "Lista instytucji",
          },
          props: ({query}) => ({...query}),
        },
        {
          path: "/institution_update_view/:institutionId",
          name: "institution_update_view",
          component: InstitutionUpdate,
          meta: {
            renderTitleBox: true,
            title: "Edytuj instytucję",
          },
          props: ({params}) => ({...params}),
        },
        {
          path: "/patients/data-unification",
          name: "patients_data_unification_view",
          component: DataUnification,
          meta: {
            renderTitleBox: true,
            title: "Unifikacja danych pacjentów",
          },
          props: ({query}) => ({
            ...query,
            page: query.page ? Number(query.page) : undefined,
            statuses: query.statuses ? query.statuses.split(",") : [],
            workers: query.workers ? query.workers.split(",") : [],
            caseManagers: query.caseManagers ? query.caseManagers.split(",") : [],
          }),
        },
        {
          path: "/room/create",
          name: "room_create_view",
          component: RoomCreate,
          meta: {
            renderTitleBox: true,
            title: "Nowy pokój",
          },
        },
        {
          path: "/ipzs/list",
          name: "ipzs_list",
          component: IpzList,
          meta: {
            renderTitleBox: true,
            title: "Lista IPZ",
          },
          props: ({query}) => ({
            ...query,
            page: query.page ? Number(query.page) : null,
            workerIds: query.workerIds ? query.workerIds.split(",") : [],
            mobileTeamMembersIds: query.mobileTeamMembersIds ? query.mobileTeamMembersIds.split(",") : []
          }),
        },
        {
          path: "/settings",
          name: "settings",
          component: Settings,
          meta: {
            renderTitleBox: true,
            title: "Ustawienia",
          },
        },
        {
          path: "/course_create_view",
          name: "get_course_create_view",
          component: CourseCreate,
          meta: {
            renderTitleBox: true,
            title: "Dodaj kurs",
          },
        },
        {
          path: "/institution_create_view",
          name: "get_institution_create_view",
          component: InstitutionCreate,
          meta: {
            renderTitleBox: true,
            title: "Dodaj instytucję",
          },
        },
        {
          path: "/appointments/list",
          name: "appointment_list_view",
          component: AppointmentList,
          meta: {
            renderTitleBox: true,
            title: "Plan wizyt",
          },
          props: ({query}) => ({
            ...query,
            statuses: query.statuses ? query.statuses.split(",") : null,
            patientOptions: query.patientOptions ? query.patientOptions.split(",") : null,
            workerIds: query.workerIds ? query.workerIds.split(",") : null,
            page: query.page ? Number(query.page) : null,
            remote: query.remote !== undefined ? JSON.parse(query.remote) : null,
            multiplePresence: query.multiplePresence ? query.multiplePresence.split(",") : null,
          }),
        },
        {
          path: "/reports/schedule-specialist-view",
          name: "scheduleSpecialistView",
          component: ScheduleSpecialist,
          meta: {
            renderTitleBox: false,
            title: "Raport - Harmonogram specjalisty",
          },
        },
        {
          path: "/reports/cancel-reasons",
          name: "cancelReasons",
          component: CancelReasons,
          meta: {
            renderTitleBox: false,
            title: "Raport - Odwołane wizyty",
          },
        },
        {
          path: "/reports/child-ipz-monitoring-report",
          name: "childIpzMonitoringReport",
          component: ChildIpzMonitoringReport,
          meta: {
            renderTitleBox: false,
            title: "Raport - Monitoring IPZ Dziecięcego",
          },
        },
        {
          path: "/reports/main-view",
          name: "reportsMainView",
          component: ReportsMainView,
          meta: {
            renderTitleBox: true,
            title: "Raporty",
          },
        },
        {
          path: "/worker/create",
          name: "worker_create_view",
          component: WorkerCreate,
          meta: {
            renderTitleBox: true,
            title: "Dodaj nowego pracownika",
          },
        },
        {
          path: "/patient/child/create",
          name: "child_patient_create_view",
          component: ChildPatientCreate,
          meta: {
            renderTitleBox: true,
            title: "Rejestracja niepełnoletniego pacjenta",
          },
        },
        {
          path: "/reports/ikup-children",
          name: "ikup_children_view",
          component: IKUPChildrenView,
          meta: {
            renderTitleBox: false,
            title: "Raport - IKUP dziecięcy",
          },
        },
        {
          path: "/reports/ikup-adults",
          name: "ikup_adults_view",
          component: IKUPAdultsView,
          meta: {
            renderTitleBox: false,
            title: "Raport - IKUP dla dorosłych",
          },
        },
        {
          path: "/reports/ikup-children-multiple",
          name: "ikup_children_summary_view",
          component: IKUPChildrenSummary,
          meta: {
            renderTitleBox: false,
            title: "Raport zbiorczy - IKUP dziecięcy",
          },
          props: ({query}) => ({
            ...query,
            page: query.page ? Number(query.page) : null,
          }),
        },
        {
          path: "/reports/ikup-summary-report",
          name: "ikup-summary-report",
          component: IKUPSummaryReport,
          meta: {
            renderTitleBox: false,
            title: "Raport sumaryczny w oparciu o IKUPy",
          },
          props: ({query}) => ({
            ...query,
            page: query.page ? Number(query.page) : null,
          }),
        },
        {
          path: "/reports/mzs",
          name: "mz_report_view",
          component: MzReportView,
          meta: {
            renderTitleBox: false,
            title: "Raport MZ",
          },
          props: ({query}) => ({
            ...query,
            page: query.page ? Number(query.page) : null,
          }),
        },
        {
          path: "/reports/ikup-adults-multiple",
          name: "ikup_adults_summary_view",
          component: IKUPAdultsSummary,
          meta: {
            renderTitleBox: false,
            title: "Raport zbiorczy - IKUP dla dorosłych",
          },
          props: ({query}) => ({
            ...query,
            page: query.page ? Number(query.page) : null,
          }),
        },
        {
          path: "/weekly-schedule",
          name: "weekly_schedule",
          component: WeeklySchedule,
          meta: {
            renderTitleBox: true,
            title: "Harmonogram tygodniowy",
          },
          props: ({query}) => ({
            ...query,
            worker: query.worker ? query.worker.split(",") : [],
            canceled: query.canceled !== undefined ? JSON.parse(query.canceled) : false,
          }),
        },
        {
          path: "/cooperating-unit/create",
          name: "createCooperatingUnitView",
          component: CooperatingUnitCreate,
          meta: {
            renderTitleBox: true,
            title: "Dodawanie jednostki współpracującej",
          },
        },
        {
          path: "/reports/specialist-list-view",
          name: "report_specialist_list",
          component: SpecialistList,
          meta: {
            renderTitleBox: false,
            title: "Raport - Specjaliści",
          },
        },
        {
          path: "/reports/interventions",
          name: "reportInterventions",
          component: InterventionsReportForm,
          meta: {
            renderTitleBox: false,
            title: "Raport - Interwencje",
          },
        },
        {
          path: "/reports/patient-medical-care",
          name: "reportPatientMedicalCare",
          component: PatientMedicalCareReportForm,
          meta: {
            renderTitleBox: false,
            title: "Raport -  Lista pacjentów pod opieką specjalistyczną",
          },
        },
        {
          path: "/reports/appointments-in-branches",
          name: "reportAppointmentsInBranches",
          component: AppointmentsInBranchesReportForm,
          meta: {
            renderTitleBox: false,
            title: "Raport -  Usługi w poradniach",
          },
        },
        {
          path: "/reports/patient-list-report-view",
          name: "report_patient_list_report",
          component: PersonListReport,
          meta: {
            renderTitleBox: false,
            title: "Raport - Lista pacjentów",
          },
        },
        {
          path: "/reports/missing-data-view",
          name: "missing_data_report",
          component: MissingDataReport,
          meta: {
            renderTitleBox: false,
            title: "Raport - Braki w danych",
          },
        },
        {
          path: "/reports/continuous-stay-view",
          name: "continuous_stay_report",
          component: ContinuousStayReport,
          meta: {
            renderTitleBox: false,
            title: "Raport - Pobyty ciągłe",
          },
        },
        {
          path: "/reports/mhc-surveys-report-view",
          name: "mhc_surveys_report",
          component: MHCSurveys,
          meta: {
            renderTitleBox: false,
            title: "Raport - Monitoring MHC",
          },
        },

        {
          path: "/reports/type-of-services-per-patient-report-view",
          name: "type_of_services_per_patient_report",
          component: TypeOfServicesPerPatient,
          meta: {
            renderTitleBox: false,
            title: "Raport - Rodzaje usług w podziale na pacjenta",
          },
        },
        {
          path: "/reports/patients-support-report-view",
          name: "patients_support_report",
          component: PatientsSupport,
          meta: {
            renderTitleBox: false,
            title: "Raport - Wsparcie pacjentów",
          },
          props: ({query}) => ({
            ...query,
            page: query.page ? Number(query.page) : null,
          }),
        },
        {
          path: "/reports/open-services-by-specialists",
          name: "open_services_by_specialists_report",
          component: OpenServicesBySpecialistsReport,
          meta: {
            renderTitleBox: false,
            title: "Raport - Liczba otwartych usług u specjalistów",
          },
        },
        {
          path: "/patient/create",
          name: "createPatientView",
          component: PatientCreate,
          meta: {
            renderTitleBox: true,
            title: "Rejestracja pełnoletniego pacjenta",
          },
        },
        {
          path: "/intervention-notifications",
          name: "intervention_notification_list",
          component: InterventionNotificationList,
          meta: {
            renderTitleBox: true,
            title: "Zgłoszenia interwencyjne",
          },
          props: ({query}) => ({
            ...query,
            page: query.page ? Number(query.page) : null,
          }),
        },
        {
          path: "/intervention-notifications/:interventionNotificationId",
          name: "intervention_notification_single",
          component: InterventionNotificationSingle,
          meta: {
            renderTitleBox: false,
            title: "Zgłoszenie interwencyjne",
          },
          props: ({params}) => ({...params}),
        },
        {
          path: "/intervention-notifications/:interventionNotificationId/update",
          name: "intervention_notification_update_view",
          component: InterventionNotificationUpdate,
          meta: {
            renderTitleBox: false,
            title: "Edycja Zgłoszenia interwencyjnego",
          },
          props: ({params}) => ({...params}),
        },
        {
          path: "/rooms",
          name: "room_list",
          component: RoomList,
          meta: {
            renderTitleBox: true,
            title: "Pokoje",
          },
          props: ({query}) => ({...query}),
        },
        {
          path: "/audit",
          name: "listAudit",
          component: AuditLogList,
          meta: {
            renderTitleBox: true,
            title: "Audyt log",
          },
          props: ({query}) => ({
            ...query,
            page: query.page ? Number(query.page) : null,
          }),
        },
        {
          path: "/treatment-types-list",
          name: "treatments_list_view",
          component: TreatmentTypeListView,
          meta: {
            renderTitleBox: true,
            title: "Lista usług",
          },
        },
        {
          path: "/worker/list",
          name: "worker_list",
          component: WorkerList,
          meta: {
            renderTitleBox: true,
            title: "Lista pracowników",
          },
          props: ({query}) => ({
            ...query,
            page: query.page ? Number(query.page) : null,
            active: query.active ? JSON.parse(query.active) : null,
            blocked: query.blocked ? JSON.parse(query.blocked) : null,
          })
        },
        {
          path: "/patients",
          name: "listPatients",
          component: PatientList,
          meta: {
            renderTitleBox: true,
            title: "Lista pacjentów",
          },
          props: ({query}) => ({
            ...query,
            page: query.page ? Number(query.page) : null,
            workers: query.workers ? query.workers.split(",") : [],
            caseManagers: query.caseManagers ? query.caseManagers.split(",") : [],
            mobileTeam: query.mobileTeam ? query.mobileTeam.split(",") : [],
          }),
        },
        {
          path: "/patients-without-permissions",
          name: "listPatientsWithoutPermissionForProcessing",
          component: PatientList,
          meta: {
            renderTitleBox: true,
            title: "Lista pacjentów bez zgód na przetwarzanie danych osobowych",
          },
          props: ({query}) => ({
            ...query,
            page: query.page ? Number(query.page) : null,
            workers: query.workers ? query.workers.split(",") : [],
            caseManagers: query.caseManagers ? query.caseManagers.split(",") : [],
            mobileTeam: query.mobileTeam ? query.mobileTeam.split(",") : [],
            permissionForProcessingPersonalData: false
          }),
        },
        {
          path: "/support-network-patient-list",
          name: "supportNetworkPatientList",
          component: SupportNetworkPatientList,
          meta: {
            renderTitleBox: true,
            title: "Wspierani pacjenci",
          },
          props: ({params}) => ({...params}),
        },
        {
          path: "/referral/:appointmentDocumentId/hospital",
          name: "referralHospital",
          component: ReferralHospital,
          meta: {
            renderTitleBox: true,
            title: "Skierowanie do szpitala psychiatrycznego",
          },
          props: ({params}) => ({...params}),
        },
        {
          path: "/referral/:appointmentDocumentId/consultation",
          name: "referralConsultation",
          component: ReferralConsultation,
          meta: {
            renderTitleBox: true,
            title: "Skierowanie na konsultację",
          },
          props: ({params}) => ({...params}),
        },
        {
          path: "internal-referrals",
          name: "internalReferrals",
          component: InternalReferralsPage,
          meta: {
            renderTitleBox: true,
            title: "Skierowania wewnętrzne"
          },
          props: ({query}) => ({
            ...query,
            page: query.page ? Number(query.page) : null,
          }),
        },
        {
          path: "/zla/:sessionId/:patientId",
          name: "zla",
          component: Zla,
          meta: {
            renderTitleBox: false,
            title: "e-ZLA - zwolnienie elektroniczne",
          },
          props: ({params, query}) => ({...params, ...query}),
        },
        {
          path: "/worker/:workerId/update",
          name: "workerUpdateView",
          component: WorkerUpdate,
          meta: {
            renderTitleBox: true,
            title: "Edycja pracownika",
          },
          props: ({params}) => ({...params}),
        },
        {
          path: "/worker/:workerId/ewus-access",
          name: "workerEwusAccess",
          component: WorkerEwusAccess,
          meta: {
            renderTitleBox: true,
            title: "Ustawienia danych dostępowych do systemu eWUŚ",
          },
          props: ({params}) => ({...params}),
        },
        {
          path: "/patients/:patientId/report-card",
          name: "patientReportCard",
          component: PatientReportCard,
          meta: {
            renderTitleBox: true,
            title: "Karta raportowa pacjenta",
          },
          props: ({params}) => ({...params}),
        },
        {
          path: "/patients/:patientId/persons-eligible",
          name: "viewEligiblePersons",
          component: EligliblePersonsPage,
          meta: {
            renderTitleBox: true,
            title: "Powiązania pacjenta",
          },
          props: ({params}) => ({...params}),
        },
        {
          path: "/system-notifications",
          name: "system_notifications_list",
          component: SystemNotifications,
          meta: {
            renderTitleBox: true,
            title: "Zdarzenia systemowe",
          },
        },
        {
          path: "/cooperating-unit/:cooperatingUnitId/update",
          name: "updateCooperatingUnitView",
          component: CooperatingUnitUpdate,
          meta: {
            renderTitleBox: true,
            title: "Edycja jednostki współpracującej",
          },
          props: ({params}) => ({...params}),
        },
        {
          path: "/cooperating-units",
          name: "cooperatingUnits",
          component: CooperatingUnitList,
          meta: {
            renderTitleBox: true,
            title: "Jednostki współpracujące",
          },
        },
        {
          path: "/room/update/:roomId",
          name: "roomUpdateView",
          component: RoomUpdate,
          meta: {
            renderTitleBox: true,
            title: "Edycja pokoju",
          },
          props: ({params}) => ({...params}),
        },
        {
          path: "/patient/:patientId/details",
          name: "patientCard",
          component: PatientPage,
          meta: {
            renderTitleBox: false,
            title: "Karta Pacjenta",
          },
          props: ({params, query}) => ({
            ...params,
            ...query,
            atcDesc: query.atcDesc !== undefined ? JSON.parse(query.atcDesc) : null,
            atcPage: query.atcPage ? Number(query.atcPage) : null,
            atcPerPage: query.atcPerPage ? Number(query.atcPerPage) : null,
            atcStatuses: query.atcStatuses ? query.atcStatuses.split(",") : null,
            atcContinuousStay: query.continuousStay !== undefined ? JSON.parse(query.continuousStay) : null,
          }),
        },
        {
          path: "/workers-working-hours",
          name: "listWorkingHoursOfWorker",
          component: WorkingHoursList,
          meta: {
            renderTitleBox: true,
            title: "Harmonogramy pracowników",
          },
        },
        {
          path: "/workers/:workerId/working-hours-page",
          name: "workersWorkingHoursPage",
          component: WorkerWorkingHours,
          meta: {
            renderTitleBox: false,
            title: "Harmonogram",
          },
          props: ({params}) => ({...params}),
        },
        {
          path: "/workers/:workerId/define-absences",
          name: "workersAbsence",
          component: WorkerAbsence,
          meta: {
            renderTitleBox: false,
            title: "Nieobecności pracownika",
          },
          props: ({params}) => ({...params}),
        },
        {
          path: "/calendar",
          name: "calendarView",
          component: CalendarPage,
          meta: {
            renderTitleBox: true,
            title: ({query: {appointmentId, copyId}}) => {
              if (appointmentId) {
                return "Edycja wizyty";
              }

              if (copyId) {
                return "Kopiowanie wizyty";
              }

              return "Kalendarz";
            },
          },
          props: ({params, query}) => ({
            ...params,
            ...query,
            startDate: query.startDate ? parseDate(query.startDate, DATE_FORMAT.DATE_TIME) : null,
            endDate: query.endDate ? parseDate(query.endDate, DATE_FORMAT.DATE_TIME) : null,
            workers: query.workers ? query.workers.split(",") : [],
            continuousStay: query.continuousStay !== undefined ? JSON.parse(query.continuousStay) : undefined
          }),
        },
        {
          path: "/appointment/:appointmentId/appointment",
          name: "viewDocumentAppointment",
          component: Document,
          meta: {
            renderTitleBox: false,
            title: "Dokument",
          },
          props: ({params, query}) => ({...params, ...query}),
        },
        {
          path: "/reports/appointment-monthly-stats",
          name: "viewReportAppointmentMonthlyStats",
          component: AppointmentMonthlyStats,
          meta: {
            renderTitleBox: false,
            title: "Raport - Usługi w miesiącu",
          },
          props: ({query}) => ({
            ...query,
            statuses: query.statuses ? query.statuses.split(",") : [],
          }),
        },
        {
          path: "/setup",
          name: "setup_data_page",
          component: SetupDataPage,
          meta: {
            renderTitleBox: true,
            title: "Dane konfiguracji",
          },
        },
        {
          path: "/changelog",
          name: "get_changelog",
          component: Changelog,
          meta: {
            renderTitleBox: true,
            title: "Historia zmian w aplikacji",
          },
        },
        {
          path: "/ipzs/:ipzId/appointment-requirements",
          name: "ipz_appointment_requirements_monthly",
          component: MonthlyIpzAppointmentRequirements,
          meta: {
            renderTitleBox: true,
            title: "Umawianie wizyt w ramach IPZ",
          },
          props: ({params, query}) => ({
            ...params,
            ...query,
            year: query.year ? Number(query.year) : undefined,
            month: query.month ? Number(query.month) : undefined,
          }),
        },
        {
          path: "/room-occupancy",
          name: "room_occupancy",
          component: RoomOccupancySchedule,
          meta: {
            renderTitleBox: true,
            title: "Obłożenie pokoi",
          },
          props: ({query}) => ({
            ...query,
          })
        },
        {
          path: "/ipzs/:ipzId",
          props: ({params}) => ({...params}),
          component: IpzBaseLayout,
          children: [
            {
              path: "details/24-hour-branch",
              name: "ipz_details_24_hour_branch",
              component: Branch24HPanel,
              meta: {
                renderTitleBox: false,
                title: "IPZ - Oddział całodobowy / dzienny",
              },
              props: ({params}) => ({...params}),
            },
            {
              path: "crisis-plans",
              name: "ipzDetailsCrisisPlan",
              component: CrisisPlanPanel,
              meta: {
                renderTitleBox: false,
                title: "IPZ - Plan kryzysowy",
              },
              props: ({params}) => ({...params}),
            },
            {
              path: "evaluations/:evaluationId",
              name: "ipz_evaluation_view",
              component: EvaluationFormPanel,
              meta: {
                renderTitleBox: false,
                title: "Arkusze ICF / Ewaluacja",
              },
              props: ({params}) => ({...params}),
            },
            {
              path: "evaluations/:evaluationId/result",
              name: "ipz_evaluation_result",
              component: EvaluationResultPanel,
              meta: {
                renderTitleBox: false,
                title: "Arkusze ICF / Ewaluacja",
              },
              props: ({params}) => ({...params}),
            },
            {
              path: "details/evaluations",
              name: "ipz_evaluations_list",
              component: EvaluationsPanel,
              meta: {
                renderTitleBox: false,
                title: "Arkusze ICF / Ewaluacja",
              },
              props: ({params}) => ({...params}),
            },
            {
              path: "details/family-interview",
              name: "ipz_details_family_interview_diagnosis",
              component: FamilyInterviewDiagnosisPanel,
              meta: {
                renderTitleBox: false,
                title: "IPZ - Wywiad rodzinny",
              },
              props: ({params}) => ({...params}),
            },
            {
              path: "details/functional-diagnosis",
              name: "ipz_details_functional_diagnosis",
              component: FunctionalDiagnosisPanel,
              meta: {
                renderTitleBox: false,
                title: "IPZ - Diagnoza funkcjonalna",
              },
              props: ({params}) => ({...params}),
            },
            {
              path: "icf/creator",
              name: "icf_creator",
              component: IcfCreatorPanel,
              meta: {
                renderTitleBox: false,
                title: "Kreator ICF",
              },
              props: ({params}) => ({...params}),
            },
            {
              path: "details",
              name: "ipz_details",
              component: IpzDetailsPanel,
              meta: {
                renderTitleBox: false,
                title: "IPZ",
              },
              props: ({params}) => ({...params}),
            },
            {
              path: "details/medical-diagnosis",
              name: "ipz_details_medical_diagnosis",
              component: MedicalDiagnosisPanel,
              meta: {
                renderTitleBox: false,
                title: "IPZ - Diagnoza medyczna",
              },
              props: ({params}) => ({...params}),
            },
            {
              path: "details/neurological",
              name: "ipz_details_neurological_diagnosis",
              component: NeurologicalDiagnosisPanel,
              meta: {
                renderTitleBox: false,
                title: "IPZ - Badanie neurologiczne",
              },
              props: ({params}) => ({...params}),
            },
            {
              path: "details/pediatric",
              name: "ipz_details_pediatric_diagnosis",
              component: PediatricDiagnosisPanel,
              meta: {
                renderTitleBox: false,
                title: "IPZ - Badanie somatyczne",
              },
              props: ({params}) => ({...params}),
            },
            {
              path: "details/psychiatric",
              name: "viewIpzDetailsPsychiatricDiagnosis",
              component: PsychiatricDiagnosisPanel,
              meta: {
                renderTitleBox: false,
                title: "IPZ - Badanie psychiatryczne",
              },
              props: ({params}) => ({...params}),
            },
            {
              path: "therapeutic-plans",
              name: "ipz_details_therapeutic_plan",
              component: TherapeuticPlanPanel,
              meta: {
                renderTitleBox: false,
                title: "IPZ - Plan terapeutyczny",
              },
              props: ({params}) => ({...params}),
            },
            {
              path: "simplified-ipz",
              name: "ipz_details_simplified_ipz",
              component: SimplifiedIpzPage,
              meta: {
                renderTitleBox: false,
                title: "IPZ - Uproszczony",
              },
              props: ({params}) => ({...params}),
            },
            {
              path: "targets-and-tasks",
              name: "ipz_details_targets",
              component: TargetsAndTasksPanel,
              meta: {
                renderTitleBox: false,
                title: "IPZ - Cele i zadania",
              },
              props: ({params}) => ({...params}),
            },
            {
              path: "child-ipzs",
              name: "child_ipzs",
              component: ChildIpzPanel,
              meta: {
                renderTitleBox: false,
                title: "IPZ Dziecięcy",
              },
              props: ({params}) => ({...params}),
            },
          ],
        },
        {
          path: "social-activity/:patientId",
          name: "socialActivity",
          component: SocialActivityPage,
          meta: {
            renderTitleBox: false,
            title: "Działania OPS",
          },
          props: ({params}) => ({...params}),
        },
        {

          path: "/workers-localizations",
          name: "workers_localizations",
          component: GeolocalizationList,
          meta: {
            renderTitleBox: true,
            title: "Lokalizacja",
          },
          props: ({query}) => ({
            ...query,
          })
        },
        {

          path: "/director/dashboard",
          name: "director_dashboard",
          component: DirectorReports,
          meta: {
            renderTitleBox: true,
            title: "Panel dyrektora",
          },
          props: ({query}) => ({
            ...query,
          })
        },
        {
          path: "establishments",
          name: "establishments",
          component: EstablishmentPage,
          meta: {
            renderTitleBox: true,
            title: "Lista podmiotów",
          },
        },
        {
          path: "request-list",
          name: "requestList",
          component: RequestListPage,
          meta: {
            renderTitleBox: true,
            title: "Lista wniosków",
          },
          props: ({query}) => ({
            ...query,
            page: query.page ? Number(query.page) : undefined,
            status: query.status ? query.status.split(",") : [],
            creator: query.creator ? query.creator.split(",") : [],
            requestType: query.requestType ? query.requestType.split(",") : [],
          }),
        },
        {
          path: "/reports/work-on-ipz-report",
          name: "work-on-ipz-report",
          component: WorkOnIpzReport,
          meta: {
            renderTitleBox: false,
            title: "Raport - Praca z IPZ",
          },
        },
        {
          path: "/reports/sl",
          name: "SL-report",
          component: SLReportView,
          meta: {
            renderTitleBox: false,
            title: "Raport SL",
          },
          props: ({query}) => ({
            ...query,
            page: query.page ? Number(query.page) : null,
          }),
        },
      ]
    },
    {
      path: "/pdf",
      component: PdfLayout,
      children: [
        {
          path: "room-occupancy",
          component: RoomOccupancyReport,
          props: ({query}) => ({
            ...query,
          })
        }
      ]
    },
    {
      path: "/login",
      name: "login",
      component: LoginPage,
      meta: {
        title: "Logowanie",
      },
    },
    {
      path: "/password/reset/request/:workerId",
      name: "request_password_change",
      component: RequestPasswordChange,
      meta: {
        title: "Resetuj hasło",
      },
      props: ({params}) => ({...params}),
    },
    {
      path: "/password/reset",
      name: "user_request_password_change",
      component: UserRequestPasswordChange,
      meta: {
        title: "Resetuj hasło",
      },
      props: ({params}) => ({...params}),
    },
    {
      path: "/password/reset/confirm/:token",
      name: "reset_password_confirm_view",
      component: ResetPasswordForm,
      props: ({params}) => ({...params}),
    },
    {
      path: "/health",
      name: "stateHealth",
      component: Health,
      meta: {
        title: "Stan zdrowia",
      },
    },
    {
      path: "*",
      name: "pageNotFound",
      component: PageNotFound,
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (to.name === "patientCard") {
      return savedPosition;
    }
    return {x: 0, y: 0}
  },
});

router.beforeEach((to, from, next) => {
  if ("reset_password_confirm_view" === to.name) {
    next();
    return;
  }
  if ("user_request_password_change" === to.name) {
    next();
    return;
  }
  if ("login" !== to.name && !store.state.session.isAuthenticated) {
    if (to.query.referrer) {
      const redirect = {
        path: to.path,
        hash: to.hash
      };
      localStorage.setItem("auxilio.referrer", JSON.stringify(redirect));
    }
    next({name: "login"});
    return;
  }
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
  if (nearestWithTitle) {
    const {meta: {title}} = nearestWithTitle;
    document.title = typeof title === "string" ? title : title(to);
  }
  next();
});

export default router;
