var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',[_c('b-form-group',{attrs:{"label":"Czy klient/ka był(a) wcześniej hospitalizowany/a na oddziale\n        psychiatrycznym z powodu zaburzeń psychicznych?","label-for":"wasHospitalized"}},[_c('boolean-refusal-select',{attrs:{"id":"wasHospitalized","value":_vm.value.wasHospitalized,"disabled":_vm.loading,"only-boolean":false,"state":_vm.state('wasHospitalized'),"refusal-value":'does_not_remember'},on:{"input":function($event){return _vm.update({wasHospitalized: $event})}}}),_c('error-message',{attrs:{"errors":_vm.errors,"field":"wasHospitalized"}})],1),_c('transition-group',[(_vm.value.wasHospitalized === 'yes')?[_c('b-form-group',{key:"whenFirst",attrs:{"label":"W którym roku klient/ka był(a) po raz pierwszy hospitalizowany/a na\n          oddziale psychiatrycznym z powodu zaburzeń psychicznych?","label-for":"whenFirst"}},[_c('input-or-not-remember-radio',{attrs:{"id":"whenFirst","value":_vm.value.whenFirstHospitalized,"disabled":_vm.loading,"errors":_vm.getSubErrors('whenFirstHospitalized'),"number":"","placeholder":"Rok"},on:{"input":function($event){return _vm.update({
            whenFirstHospitalized: $event,
          })}}}),_c('error-message',{attrs:{"errors":_vm.errors,"root":true,"field":"whenFirstHospitalized"}})],1),_c('b-form-group',{key:"whenLast",attrs:{"label":"W którym roku klient/ka był(a) ostatnio hospitalizowany/a na oddziale\n        psychiatrycznym z powodu zaburzeń psychicznych?","label-for":"whenLast"}},[_c('input-or-not-remember-radio',{attrs:{"id":"whenLast","value":_vm.value.whenLastHospitalized,"disabled":_vm.loading,"errors":_vm.getSubErrors('whenLastHospitalized'),"number":"","placeholder":"Rok"},on:{"input":function($event){return _vm.update({
            whenLastHospitalized: $event,
          })}}}),_c('error-message',{attrs:{"errors":_vm.errors,"root":true,"field":"whenLastHospitalized"}})],1),_c('b-form-group',{key:"howMany",attrs:{"label":"Ile razy klient/ka był(a) hospitalizowany/a z powodu zaburzeń psychicznych\n         na oddziale psychiatrycznym w ciągu ostatnich 12 miesięcy?","label-for":"howMany"}},[_c('input-or-not-remember-radio',{attrs:{"id":"howMany","value":_vm.value.howManyHospitalizationsLastPeriod,"disabled":_vm.loading,"errors":_vm.getSubErrors('howManyHospitalizationsLastPeriod'),"number":"","placeholder":"Liczba"},on:{"input":function($event){return _vm.update({
            howManyHospitalizationsLastPeriod: $event,
          })}}}),_c('error-message',{attrs:{"errors":_vm.errors,"root":true,"field":"howManyHospitalizationsLastPeriod"}}),_c('b-alert',{attrs:{"show":_vm.showHowManyHospitalizationsWarning,"variant":"warning"}},[_vm._v(" Wartość zostanie zapisana ale wykracza poza ustalony limit ("+_vm._s(_vm.howManyHospitalizationsWarningValue)+"), Prosimy o weryfikację. ")])],1),_c('b-form-group',{key:"howLong",attrs:{"label":"Ile dni klient/ka spędził/a podczas hospitalizacji z powodu zaburzeń\n        psychicznych na oddziale psychiatrycznym w ciągu ostatnich 12 miesięcy?","label-for":"howLong"}},[_c('input-or-not-remember-radio',{attrs:{"id":"howLong","value":_vm.value.howLongHospitalizedLastPeriod,"disabled":_vm.loading,"errors":_vm.getSubErrors('howLongHospitalizedLastPeriod'),"number":"","placeholder":"Liczba"},on:{"input":function($event){return _vm.update({
            howLongHospitalizedLastPeriod: $event,
          })}}}),_c('error-message',{attrs:{"errors":_vm.errors,"root":true,"field":"howLongHospitalizedLastPeriod"}}),_c('b-alert',{attrs:{"show":_vm.showHowLongHospitalizedWarning,"variant":"warning"}},[_vm._v(" Wartość zostanie zapisana ale wykracza poza ustalony limit ("+_vm._s(_vm.howLongHospitalizedWarningValue)+"), Prosimy o weryfikację. ")])],1)]:_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }