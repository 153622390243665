<template>
  <b-form-group
    id="meetingTypeSelect"
    :description="description"
    :disabled="value === null"
    label-for="meetingTypeSelectInput"
  >
    <template #label>
      <span
        v-if="label"
        class="col-form-label"
      >
        Typ wizyty
      </span>
    </template>
    <b-form-radio-group
      id="meetingTypeSelectInput"
      v-model="selected"
      :options="options"
      :name="name"
      buttons
      @change="change"
    />
  </b-form-group>
</template>
<script>
export default {
  name: "MeetingTypeRadio",
  props: {
    value: {type: Boolean, default: null},
    name: {type: String, default: undefined},
    available: {type: Object, required: true},
    label: {type: String, default: "Typ wizyty"},
  },
  data() {
    return {
      selected: this.value,
    }
  },
  computed: {
    options() {
      return [
        {
          html: "<i class=\"fa fa-hospital\" title=\"Lokalna\"></i>",
          value: false,
          label: "Lokalna",
          disabled: !this.available.local
        },
        {
          html: "<i class=\"fa fa-car\" title=\"Zdalna\"></i>",
          value: true,
          label: "Zdalna",
          disabled: !this.available.remote
        },
      ];
    },
    description() {
      const option = this.options.find((option) => option.value === this.selected);
      return option ? option.label : null;
    }
  },
  watch: {
    value() {
      this.selected = this.value;
    },
  },
  methods: {
    change(option) {
      this.$emit("input", option);
    },
  },
}
</script>
