<template>
  <div>
    <div
      class="page-title-box"
      style="min-height: 70px;"
    >
      <div class="d-flex justify-content-between align-items-baseline">
        <span class="font-16 m-r-5">
          {{ title }}
        </span>
        <div class="text-right">
          <is-granted
            v-if="!overview.readonly"
            :privileges="['CANCEL_IPZ']"
            component="fragment"
          >
            <b-btn
              v-b-modal="`cancel-ipz-${overview.ipzId}`"
              variant="light"
              size="sm"
              class="m-1"
            >
              <i class="fa fa-ban" /> Odwołaj IPZ
            </b-btn>
          </is-granted>
          <is-granted
            v-if="!overview.readonly"
            :privileges="['CLOSE_IPZ']"
            component="fragment"
          >
            <b-btn
              v-b-modal.close-ipz
              class="m-1"
              size="sm"
              variant="primary"
            >
              <i class="fas fa-lock mr-1" /> Zamknij IPZ
            </b-btn>
          </is-granted>
        </div>
      </div>
      <h4 v-if="overview.patientId">
        <router-link
          v-if="hasAccessToPatientCard"
          :to="{name: 'patientCard', params: {patientId: overview.patientId}}"
        >
          {{ overview.displayName.toUpperCase() }}
        </router-link>
        <template v-else>
          {{ overview.displayName.toUpperCase() }}
        </template>
        <patient-bmi-alert
          class="ml-1"
          :patient-id="overview.patientId"
        />
      </h4>
    </div>
    <last-edited-appointment />
    <cancel-ipz
      :ipz-id="overview.ipzId"
      @ipzCanceled="reloadData"
    />
    <close-ipz
      :ipz-id="overview.ipzId"
      @ipzClosed="reloadData"
    />
  </div>
</template>

<script>
import CloseIpz from "./CloseIpz";
import IsGranted from "../IsGranted";
import LastEditedAppointment from "../Appointment/LastEditedAppointment";
import CancelIpz from "./CancelIpz";
import {isGranted} from "@/security/isGranted";
import PatientBmiAlert from "@/components/Patient/PatientBmiAlert";

export default {
  name: "IpzCustomTitleBox",
  components: {PatientBmiAlert, CancelIpz, LastEditedAppointment, IsGranted, CloseIpz},
  props: {
    title: {type: String, required: true},
    overview: {type: Object, required: true},
  },
  data() {
    return {
      hasAccessToPatientCard: false
    }
  },
  async mounted() {
    await Promise.all([this.checkAccessToPatientCard()]);
  },
  methods: {
    reloadData() {
      this.$emit("reloadData");
    },
    async checkAccessToPatientCard() {
      this.hasAccessToPatientCard = await isGranted(["PATIENT_CARD"]);
    }
  },
}
</script>
