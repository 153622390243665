<template>
  <loading-mask :loading="permissionState === ''">
    <p>Powiadomienia</p>
    <template v-if="permissionState === 'prompt' || permissionState === 'granted'">
      <button
        :class="btnClass"
        :disabled="loading"
        class="btn btn-sm w-100 d-block"
        @click="handleSubscriptionState"
      >
        <i
          :class="iconClass"
          class="fa"
        />
        {{ btnText }}
      </button>
      <error-message
        :errors="errors"
        class="mt-3"
      />
    </template>
    <p
      v-else-if="permissionState === 'denied'"
      class="text-muted"
    >
      Powiadomienia zostały zablokowane w ustawieniach przeglądarki
    </p>
    <p
      v-else-if="permissionState === 'noSupport'"
      class="text-muted"
    >
      Przeglądarka nie obsługuje powiadomień
    </p>
    <p
      v-else-if="permissionState.length"
      class="text-danger"
    >
      Wystąpił błąd
    </p>
  </loading-mask>
</template>

<script>
import read from "../../rest/read";
import remove from "../../rest/remove";
import getPushNotificationSubscription from "../../webPush/getPushNotificationSubscription";
import handlePushNotificationSubscriptions from "../../webPush/handlePushNotificationSubscriptions";
import getPushNotificationPermissionState from "../../webPush/getPushNotificationPermissionState";
import processResponseException from "../../utils/errors/processResponseException";
import {errorsMixin} from "../../mixins/errorsMixin.js";
import ErrorMessage from "../Form/ErrorMessage";
import LoadingMask from "../Loading/LoadingMask";

export default {
  components: {LoadingMask, ErrorMessage},
  mixins: [errorsMixin],
  data() {
    return {
      loading: false,
      permissionState: "",
      webPushSubscription: null,
    };
  },
  computed: {
    iconClass() {
      if (this.loading) {
        return "fa-spin fa-spinner";
      }
      if (this.webPushSubscription) {
        return "fa-ban";
      }
      return "fa-check";
    },
    btnText() {
      if (this.loading) {
        return "";
      }
      return this.webPushSubscription ? "Wyłącz" : "Włącz";
    },
    btnClass() {
      if (this.loading) {
        return "btn-secondary";
      }
      return this.webPushSubscription ? "btn-danger" : "btn-success";
    },
    subscriptionId() {
      return this.webPushSubscription ? this.webPushSubscription.subscriptionId : null;
    }
  },
  async mounted() {
    if (!("PushManager" in window)) {
      this.permissionState = "noSupport";
      return;
    }

    try {
      this.loading = true;
      this.permissionState = await getPushNotificationPermissionState();
      if (this.permissionState === "granted") {
        const browserSubscription = await getPushNotificationSubscription();
        this.webPushSubscription = await this.getWebPushSubscription(browserSubscription);
      }
    } catch (exception) {
      this.permissionState = "error";
      console.error(exception);
    }
    this.loading = false;
  },
  methods: {
    async subscribe() {
      this.loading = true;
      try {
        const browserSubscription = await handlePushNotificationSubscriptions();
        this.webPushSubscription = await this.getWebPushSubscription(browserSubscription);
        this.permissionState = await getPushNotificationPermissionState();
      } catch (exception) {
        if (exception instanceof DOMException) {
          this.permissionState = await getPushNotificationPermissionState();
        }
        this.errors = processResponseException(exception);
      }
      this.loading = false;
    },
    async unsubscribe() {
      this.loading = true;
      try {
        await remove(`/api/web-pushes/subscriptions/${this.subscriptionId}`);
        this.webPushSubscription = null;
      } catch (exception) {
        this.errors = processResponseException(exception);
      }
      this.loading = false;
    },
    handleSubscriptionState() {
      this.errors = [];
      if (this.webPushSubscription) {
        this.unsubscribe();
      } else {
        this.subscribe();
      }
    },
    async getWebPushSubscription(browserSubscription) {
      if (!browserSubscription) {
        return null;
      }
      const {items} = await read("/api/web-pushes/subscriptions");
      return items.find(subscription => subscription.endpoint === browserSubscription.endpoint);
    }
  }
}
</script>
