<template>
  <b-form-checkbox-group
    :id="id"
    :checked="checked"
    :options="options"
    :stacked="stacked"
    :buttons="buttons"
    :button-variant="buttonVariant"
    @input="input"
  />
</template>

<script>
export default {
  name: "SingleValueCheckboxGroup",
  props: {
    value: {type: [String, Boolean], default: null},
    options: {type: Array, default: ()=>[]},
    stacked: {type: Boolean, default: false},
    buttons: {type: Boolean,  default: false},
    buttonVariant: {type: String, default: null},
    id: {type: String, default: null},
  },
  data() {
    const checked = (null === this.value) ? [] : [this.value];
    return {
      checked,
    };
  },
  watch: {
    value() {
      this.checked = (null === this.value) ? [] : [this.value];
    }
  },
  methods: {
    input(selected) {
      let emitValue;
      switch (selected.length) {
        case 0:
          emitValue = null;
          break;
        case 1:
          emitValue = selected[0];
          break;
        default:
          emitValue = selected[1];
          break;
      }
      this.$emit("input", emitValue);
    },
  },
}
</script>
