<template>
  <b-form-group>
    <template #label>
      <i18n
        v-if="'manual' !== category"
        :msgid="`@simplified-ipz-entry-part-category.${category}`"
      />
    </template>
    <textarea-counter
      :disabled="disabled"
      :state="state(`parts[${category}].content`)"
      :value="value"
      :max-characters-count="10000"
      @input="update"
    />
    <error-message
      :errors="errorProps"
      :field="`parts[${category}].content`"
    />
  </b-form-group>
</template>

<script>
import ErrorMessage from "../../Form/ErrorMessage";
import TextareaCounter from "../../Form/Textarea/TextareaCounter";
import I18n from "../../i18n";

export default {
  name: "SimplifiedIpzEntryTextarea",
  components: {I18n, TextareaCounter, ErrorMessage},
  props: {
    category: {type: String, required: true},
    value: {type: String, default: null},
    errorProps: {type: Array, default: ()=>[]},
    disabled: {type: Boolean, default: null},
  },
  methods: {
    update($event) {
      const content = $event ? $event.trim() : null;
      this.$emit("input", content);
    },
    state(field) {
      return this.errorProps.find((error) => error.field === field) ? false : null;
    },
  },
}
</script>
