<template>
  <div class="panel-container">
    <div class="panel">
      <div class="panel-message">
        <div class="panel-icon-wrapper">
          <span
            :class="iconClass"
            class="fas panel-icon"
          />
        </div>
        <p class="panel-text h2 m-4">
          {{ panelText }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NoAttachmentsPanel",
  props: {
    panelText: {type: String, default: null},
    iconClass: {type: String, required: true},
  }
}
</script>

<style scoped lang="scss">
@import "../../../../styles/variables";

.panel-container {
  display: block;
  position: relative;
  height: 100%;
}

.panel {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.panel-message {
  text-align: center;
  position: absolute;
  margin-top: 3rem;
  width: 100%;
}

.panel-icon-wrapper {
  background-color: $light;
  border-radius: 50%;
  width: 20rem;
  height: 20rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.panel-icon {
  font-size: 10rem;
  color: $white;
}

.panel-text {

}
</style>
