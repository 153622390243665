<template>
  <div>
    <div
      v-for="(notification, index) in notifications"
      :key="index"
      :class="`alert-${ notification.category }`"
      class="alert"
    >
      <span>{{ stringifyDate(notification.createdAt) }}</span>
      <h3>{{ notification.subject }}</h3>
      <read-more
        :text="notification.content"
        :max-chars="250"
        class="text-justify"
        more-str="Czytaj dalej"
        less-str="Mniej"
      />
      <div class="text-right m-t-5">
        <span>{{ notification.author }}</span>
      </div>
    </div>
  </div>
</template>


<script>
import DATE_FORMAT from "../../utils/date/DATE_FORMAT";
import stringifyDate from "../../utils/date/stringifyDate";
import ReadMore from "../ReadMore";

export default {
  components: {ReadMore},
  props: {
    notifications: {required: true, type: Array},
  },
  methods: {
    stringifyDate(date) {
      return stringifyDate(new Date(date), DATE_FORMAT.DATE);
    },
  },
};
</script>
