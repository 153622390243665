import {ActionTree} from "vuex";
import {SplashScreenState} from "./types";
import {RootState} from "../../types";
import {CreateData, NoteItem} from "../../../types/Notes";
import create from "../../../rest/create";

const TIMEOUT_DURATION = 500;

export const actions: ActionTree<SplashScreenState, RootState> = {
  showNetworkFailure({commit, state}) {
    commit("setAnimate", true);
    clearTimeout(state.timeout);
    commit("setSplashScreenTimeout",
      window.setTimeout(() => {commit("showNetworkFailure")}, TIMEOUT_DURATION)
    );
  },
  showOffline({commit, state}) {
    commit("setAnimate", true);
    clearTimeout(state.timeout);
    commit("setSplashScreenTimeout",
      window.setTimeout( () => {commit("setOffline", true)}, TIMEOUT_DURATION)
    );
  },
  hideOffline({commit, state, dispatch}) {
    commit("setAnimate", false);
    clearTimeout(state.timeout);
    commit("setSplashScreenTimeout",
      window.setTimeout(() => {commit("setOffline", false)}, TIMEOUT_DURATION)
    );
    dispatch("sendOfflineNotes");
  },
  async sendOfflineNotes({rootState}) {
    const userId = rootState?.currentUser?.user.userId;
    if (!userId) {
      return;
    }
    const storageKey = `${userId}-offlineNotes`;
    const notesJson = localStorage.getItem(storageKey);
    if (!notesJson) {
      return;
    }
    const notes: NoteItem[] = JSON.parse(notesJson);
    try {
      await create<CreateData>("/api/notes", {notes});
      localStorage.removeItem(storageKey);
    } catch (e) {
      console.error(e);
    }
  },
};
