<template>
  <b-modal
    id="edit-evaluation-modal"
    title="Edycja daty ewaluacji"
    no-close-on-backdrop
    size="lg"
    title-tag="h3"
    @hide="resetModalData"
  >
    <template #default>
      <loading-mask :loading="loading">
        <error-message :errors="errors" />
        <FormRow
          :errors="errors"
          label="Data ewaluacji"
          field="evaluationCreatedAt"
        >
          <date-picker
            v-model="evaluationDate"
            type="datetime"
            :format="'YYYY-MM-DD HH:mm:ss'"
            :minute-step="5"
            :state="state('evaluationCreatedAt')"
          />
        </FormRow>
      </loading-mask>
    </template>
    <template #modal-footer>
      <button
        type="button"
        class="btn btn-secondary"
        @click="closeModal"
      >
        Anuluj
      </button>
      <button
        class="btn btn-primary"
        @click="submit"
      >
        Zapisz
      </button>
    </template>
  </b-modal>
</template>

<script>
import ErrorMessage from "../../../components/Form/ErrorMessage";
import LoadingMask from "../../Loading/LoadingMask";
import {mapActions} from  "vuex";
import DatePicker from "../../Form/DatePicker/DatePicker.vue";
import update from "../../../rest/update";
import processResponseException from "../../../utils/errors/processResponseException";
import FormRow from "../../Form/FormRow"
import stringifyDate from "../../../utils/date/stringifyDate";
import DATE_FORMAT from "../../../utils/date/DATE_FORMAT";
import {errorsMixin} from "../../../mixins/errorsMixin.js";

export default {
  components: {
    ErrorMessage,
    LoadingMask,
    DatePicker,
    FormRow
  },
  mixins: [errorsMixin],
  props: {
    evaluationId: {type: String, default: null},
    evaluationCreatedAt: {type: String, required: true}
  },
  data() {
    return {
      loading: false,
      evaluationDate: null,
      errors: [],
    }
  },
  watch: {
    evaluationCreatedAt: {
      immediate: true,
      handler(val) {
        if (val) {
          this.evaluationDate = val;
        }
      },
    },
  },
  methods: {
    ...mapActions({
      showToast: "toastNotification/showToast",
    }),
    resetModalData() {
      this.errors = [];
      this.loading = false;
      this.evaluationDate = this.evaluationCreatedAt;
    },
    async submit() {
      this.errors = [];
      this.loading = true;
      try {
        await update(`/api/evaluation/${this.evaluationId}`, {
          evaluationCreatedAt: this.evaluationDate
            ? stringifyDate(new Date(this.evaluationDate), DATE_FORMAT.DATE_TIME_SEC)
            : undefined,
        });
        this.closeModal();
        this.showToast({
          message: "Dane zostały zapisane",
          variant: "success",
        });
        this.$emit("editEvaluationModalEvent");
      } catch (e) {
        this.errors = processResponseException(e);
      }
      this.loading = false;
    },
    closeModal() {
      this.resetModalData();
      this.$bvModal.hide("edit-evaluation-modal");
    },
  },
}
</script>
