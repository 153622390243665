<template>
  <object-select
    :value="selectedValues"
    :options="options"
    :state="state"
    :placeholder="placeholder"
    @input="update"
  />
</template>

<script>
import ObjectSelect from "./ObjectSelect";
import t from "../../../i18n";

export default {
  components: {
    ObjectSelect,
  },
  props: {
    value: {type: Array, required: true},
    state: ObjectSelect.props.state, // eslint-disable-line
    placeholder: {type: String, default: "Wybierz rolę w procesie zdrowienia"},
  },
  data() {
    return {
      optionValues: [
        {value: "psychiatrist"},
        {value: "psychotherapist"},
        {value: "nurse"},
        {value: "recovery_assistant"},
        {value: "family_assistant"},
        {value: "case_manager"},
        {value: "occupational_therapist"},
        {value: "environmental_therapist"},
        {value: "social_worker"},
        {value: "mobile_team"},
        {value: "reception"},
        {value: "career_counselor"},
      ],
    };
  },
  computed: {
    options() {
      return this.optionValues.map(option => ({
        ...option,
        name: t(`@role-in-process.${option.value}`),
      }));
    },
    selectedValues() {
      return this.value.map(item => this.options.find(option => option.value === item));
    },
  },
  methods: {
    update(selectedOption) {
      const selectedValue = [];
      if(null !== selectedOption) {
        selectedValue.push(selectedOption.value);
      }
      this.$emit("input", selectedValue);
    },
  },
}
</script>
