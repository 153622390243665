

interface Permission {
  createLabel: string,
  displayLabel: string,
  updateLabel: string,
  updateDescription: null|string,
  updateDisabledDescription: null|string
  propertyName: string,
  types: Array<string>,
}

enum PermissionType {
  ADULT = "adult",
  CHILDREN = "child"
}

const permissions: Array<Permission> = [
  {
    createLabel: "Zgoda na przetwarzanie danych osobowych. (wymagane)",
    displayLabel: "Zgoda na przetwarzanie danych osobowych.",
    updateLabel: "Pacjent wyraża zgodę na przetwarzanie danych osobowych.",
    updateDescription: "W przypadku cofnięcia zgody, Karta Pacjenta wraz z danymi zostanie przeniesiona do archiwum.",
    updateDisabledDescription: "Cofnięcie zgody nie jest możliwe. Pacjent posiada zaplanowane lub rozpoczęte wizyty.",
    propertyName: "permissionForProcessingPersonalData",
    types: [PermissionType.ADULT, PermissionType.CHILDREN],
  },
  {
    createLabel: "Zgoda na utrwalenie wizerunku. (opcjonalne)",
    displayLabel: "Zgoda na utrwalenie wizerunku.",
    updateLabel: "Zgoda na utrwalenie wizerunku.",
    updateDescription: null,
    updateDisabledDescription: null,
    propertyName: "permissionForImagePreservation",
    types: [PermissionType.ADULT, PermissionType.CHILDREN],
  },
  {
    createLabel: "Zgoda na przetwarzanie wizerunku. (opcjonalne)",
    displayLabel: "Zgoda na przetwarzanie wizerunku.",
    updateLabel: "Zgoda na przetwarzanie wizerunku.",
    updateDescription: null,
    updateDisabledDescription: null,
    propertyName: "permissionForImageProcessing",
    types: [PermissionType.ADULT, PermissionType.CHILDREN],
  },
  {
    createLabel: "Oświadczenie o wyrażeniu zgody na przetwarzanie danych osobowych w celach ewaluacji świadczeń." +
      " (opcjonalne)",
    displayLabel: "Oświadczenie o wyrażeniu zgody na przetwarzanie danych osobowych w celach ewaluacji świadczeń",
    updateLabel: "Oświadczenie o wyrażeniu zgody na przetwarzanie danych osobowych w celach ewaluacji świadczeń.",
    updateDescription: null,
    updateDisabledDescription: null,
    propertyName: "permissionForProcessingPersonalDataForEvaluation",
    types: [PermissionType.CHILDREN],
  },
  {
    createLabel: "Wniosek obydwojga rodziców o zdiagnozowanie i/lub leczenie dziecka. (opcjonalne)",
    displayLabel: "Wniosek obydwojga rodziców o zdiagnozowanie i/lub leczenie dziecka.",
    updateLabel: "Wniosek obydwojga rodziców o zdiagnozowanie i/lub leczenie dziecka.",
    updateDescription: null,
    updateDisabledDescription: null,
    propertyName: "permissionBothParentsForDiagnosisAndTreatment",
    types: [PermissionType.CHILDREN],
  },
  {
    createLabel: "Wyrażam zgodę na nagrywanie sesji terapeutycznych w celach szkoleniowych. (opcjonalne)",
    displayLabel: "Zgoda na nagrywanie sesji terapeutycznych w celach szkoleniowych.",
    updateLabel: "Zgoda na nagrywanie sesji terapeutycznych w celach szkoleniowych.",
    updateDescription: null,
    updateDisabledDescription: null,
    propertyName: "permissionForRecordRTherapeuticSessionForTrainingPurposes",
    types: [PermissionType.CHILDREN],
  },
  {
    createLabel: "Wyrażam zgodę na obecność stażystów podczas procesu diagnozy i leczenia mojego dziecka. (opcjonalne)",
    displayLabel: "Zgoda na obecność stażystów podczas procesu diagnozy i leczenia dziecka.",
    updateLabel: "Zgoda na obecność stażystów podczas procesu diagnozy i leczenia dziecka.",
    updateDescription: null,
    updateDisabledDescription: null,
    propertyName: "permissionForPresenceOfInternsDuringDiagnosisAndTreatment",
    types: [PermissionType.CHILDREN],
  },
  {
    createLabel: "Wyrażam zgodę na udzielene dokumentacji (opcjonalne)",
    displayLabel: "Zgoda na udzielenie dokumentacji.",
    updateLabel: "Zgoda na udzielenie dokumentacji.",
    updateDescription: null,
    updateDisabledDescription: null,
    propertyName: "permissionForGrantedDocumentation",
    types: [PermissionType.ADULT, PermissionType.CHILDREN],
  },
  {
    createLabel: "Wyrażam zgodę na wydawanie dokumentacji (opcjonalne)",
    displayLabel: "Zgoda na wydawanie dokumentacji.",
    updateLabel: "Zgoda na wydawanie dokumentacji.",
    updateDescription: null,
    updateDisabledDescription: null,
    propertyName: "permissionForIssueDocumentation",
    types: [PermissionType.ADULT, PermissionType.CHILDREN],
  },
];


export default permissions;

