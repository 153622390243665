<template>
  <anonymous-layout>
    <form
      class="form-horizontal m-t-20 text-white"
      @submit.prevent="submit"
    >
      <div class="logotype-wrapper m-b-20 mx-auto text-center">
        <logotype
          :width="290"
          class="img-fluid"
        />
      </div>
      <env-name class="text-white" />
      <p
        v-if="!oneFactorAuthentication || periodicPassChange"
        class="text-center font-18"
      >
        Witaj <b>{{ username }}</b>
      </p>
      <b-alert
        v-for="(error,index) in errors"
        :key="index"
        :show="true"
        dismissible
        variant="danger"
        @dismissed="clearErrors"
      >
        <i18n :msgid="error.message" />
      </b-alert>
      <b-alert
        :show="userBlocked"
        dismissible
        variant="danger"
      >
        Konto użytkownika zablokowane -
        <a
          href="/password/reset"
          class="reset-link"
        >
          zresetuj hasło
        </a>
        aby je odblokować.
      </b-alert>
      <template v-if="!oneFactorAuthentication && !periodicPassChange">
        <label
          class="col-form-label"
          for="code"
        >
          Kod autoryzacyjny
        </label>
        <div class="input-group mb-2">
          <div class="input-group-prepend">
            <div class="input-group-text">
              <i class="fa fa-key" />
            </div>
          </div>
          <input
            id="code"
            v-model="code"
            class="form-control"
            name="_code"
            placeholder="Kod autoryzacyjny"
            required
          >
        </div>
      </template>
      <template v-else-if="periodicPassChange">
        <label
          class="col-form-label"
          for="change_password"
        >
          Nowe hasło
        </label>
        <div class="input-group mb-2">
          <div class="input-group-prepend">
            <div class="input-group-text">
              <i class="fa fa-key" />
            </div>
          </div>
          <input
            id="change_password"
            v-model.trim="changePassword"
            type="password"
            class="form-control"
            name="_change_password"
            placeholder="Nowe hasło"
            required
          >
        </div>
        <label
          class="col-form-label"
          for="confirm_change_password"
        >
          Powtórz nowe hasło
        </label>
        <div class="input-group mb-2">
          <div class="input-group-prepend">
            <div class="input-group-text">
              <i class="fa fa-key" />
            </div>
          </div>
          <input
            id="confirm_change_password"
            v-model.trim="confirmChangePassword"
            type="password"
            class="form-control"
            name="_confirm_change_password"
            placeholder="Powtórz nowe hasło"
            required
          >
        </div>
      </template>
      <template v-else>
        <div class="input-group mb-2">
          <div class="input-group-prepend">
            <label
              class="input-group-text"
              for="email"
            >
              <i class="fa fa-user" />
            </label>
          </div>
          <input
            id="email"
            v-model.trim="username"
            :autofocus="!lastUsername"
            type="email"
            class="form-control"
            name="_email"
            required
            placeholder="E-mail użytkownika"
          >
        </div>
        <div class="input-group mb-2">
          <div class="input-group-prepend">
            <label
              class="input-group-text"
              for="password"
            >
              <i class="fa fa-key" />
            </label>
          </div>
          <input
            id="password"
            v-model="password"
            :autofocus="!!lastUsername"
            type="password"
            class="form-control"
            name="_password"
            required
            placeholder="Hasło"
          >
        </div>
      </template>
      <div class="d-flex">
        <a
          class="forgot-password"
          href="/password/reset"
        >
          Zapomniane hasło?
        </a>
        <button
          :disabled="disabled"
          class="btn btn-light w-md waves-effect waves-light ml-auto"
          type="submit"
        >
          <loading-icon
            :loading="loading"
            ready-icon="fa-unlock"
            size="sm"
          />
          Zaloguj się
        </button>
      </div>
    </form>
  </anonymous-layout>
</template>
<script>
import create from "../../rest/create";
import I18n from "../../components/i18n";
import LoadingIcon from "../../components/Loading/LoadingIcon";
import Logotype from "../../components/Layout/Logotype";
import processResponseException from "../../utils/errors/processResponseException";
import {mapActions} from "vuex";
import AnonymousLayout from "../../components/Layout/AnonymousLayout";
import EnvName from "../../components/EnvName";

export default {
  name: "LoginPage",
  components: {EnvName, AnonymousLayout, Logotype, LoadingIcon, I18n},
  props: {
    lastUsername: {type: String, default: ""},
  },
  data() {
    return {
      username: this.lastUsername,
      password: "",
      code: "",
      errors: [],
      loading: false,
      oneFactorAuthentication: true,
      periodicPassChange: false,
      changePassword: "",
      confirmChangePassword: "",
      userBlocked: false
    };
  },
  computed: {
    disabled() {
      return this.loading || "" === this.username || "" === this.password;
    },
  },
  created() {
    this.clearPersistedState();
  },
  methods: {
    ...mapActions(["clearPersistedState"]),
    ...mapActions("session", ["authenticate"]),
    ...mapActions("clinicParameters", ["loadClinicParameters"]),
    async submit() {
      this.loading = true;
      this.userBlocked = false;
      try {
        await this.login();
      } catch (exception) {
        this.errors = processResponseException(exception);

        if (this.oneFactorAuthentication && !!this.errors.find(error => error.field === "code")) {
          this.oneFactorAuthentication = false;
          this.clearErrors();
        }
        if (!this.periodicPassChange && !!this.errors.find(error => error.field === "changePassword")) {
          this.periodicPassChange = true;
          this.clearErrors();
        }

        if(!!this.errors.find(error => error.field === "userBlocked")){
          this.userBlocked = true;
          this.clearErrors();
        }
      }
      this.loading = false;

      if (!this.oneFactorAuthentication && !this.periodicPassChange) {
        this.code = "";
      } else if (this.periodicPassChange) {
        this.changePassword = "";
        this.confirmChangePassword = "";
      } else {
        this.password = "";
      }
    },
    async login() {
      const data = {
        username: this.username,
        password: this.password,
      };

      if (!this.oneFactorAuthentication) {
        data.code = this.code.replace(/\s/g, "");
      }

      if (this.periodicPassChange) {
        data.changePassword = this.changePassword;
        data.confirmChangePassword = this.confirmChangePassword;
      }

      await create("/api/sessions", data);
      await this.authenticate();
      await this.loadClinicParameters();
      const inStorage = localStorage.getItem("auxilio.referrer");
      const redirect = inStorage ? JSON.parse(inStorage) : {name: "home"}
      localStorage.removeItem("auxilio.referrer");
      await this.$router.push(redirect);
    },
    clearErrors() {
      this.errors = [];
    },
  }
};
</script>

<style scoped lang="scss">
  .logotype-wrapper {
    @media (max-width: 1000px) {
      width: 40vw;
    }
  }
  .forgot-password {
    color: #fff;
    font-size: 15px;
    margin-top: 5px;
    margin-left: 5px;
  }
  .reset-link {
    font-weight: bold;
    color: #d20000;
  }
</style>
