<template>
  <b-modal
    id="create-procedure"
    no-close-on-backdrop
    title="Uruchom procedurę"
    title-tag="h3"
    @hide="resetData"
  >
    <error-message :errors="errors" />
    <procedure-select
      v-model="procedure"
      :state="state('type')"
    />
    <error-message
      :errors="errors"
      field="type"
    />
    <div
      v-show="procedure"
      v-b-toggle="'procedureDetails'"
      class="text-center m-2 cursor-pointer"
    >
      ---
      <span class="when-closed">Pokaż opis</span>
      <span class="when-opened">Ukryj opis</span>
      ---
    </div>
    <b-collapse
      v-if="procedure"
      id="procedureDetails"
    >
      {{ procedure.description }}
      <template v-if="procedure.jobsList && procedure.jobsList.length">
        <div class="my-2">
          Lista zadań
        </div>
        <ul>
          <li
            v-for="(job, index) in procedure.jobsList"
            :key="index"
          >
            {{ job.name }} - {{ job.description }}
          </li>
        </ul>
      </template>
    </b-collapse>
    <template #modal-footer>
      <button
        type="button"
        class="btn btn-secondary"
        @click="close"
      >
        Anuluj
      </button>
      <button
        :disabled="loading"
        class="btn btn-primary"
        type="submit"
        @click="submit"
      >
        <i
          :class="loading ? 'fa-spin fa-spinner' : 'fa-check'"
          class="fa"
        />
        Uruchom
      </button>
    </template>
  </b-modal>
</template>

<script>
import ProcedureSelect from "./ProcedureSelect";
import ErrorMessage from "../../../Form/ErrorMessage";
import {errorsMixin} from "../../../../mixins/errorsMixin.js";
import create from "../../../../rest/create";
import {generateUuid} from "../../../../utils/uuid/generateUuid";
import processResponseException from "../../../../utils/errors/processResponseException";

export default {
  name: "ProcedureCreateModal",
  components: {ErrorMessage, ProcedureSelect},
  mixins: [errorsMixin],
  props: {
    patientId: {type: String, required: true},
  },
  data() {
    return {
      procedure: null,
      loading: false,
    };
  },
  methods: {
    close() {
      this.$bvModal.hide("create-procedure");
    },
    async submit() {
      this.errors = [];
      this.loading = true;
      const type = this.procedure ? this.procedure.value : null;
      const procedureId = generateUuid();
      try {
        await create("/api/procedures", {
          patientId: this.patientId,
          procedureId,
          type,
        });
        this.$emit("update");
        this.close();
      } catch(e) {
        this.errors = processResponseException(e);
      }
      this.loading = false;
    },
    resetData() {
      this.errors = [];
      this.procedure = null;
    },
  },
}
</script>


<style scoped>
  .collapsed > .when-opened,
  :not(.collapsed) > .when-closed {
    display: none;
  }
</style>
