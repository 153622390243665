



















import Vue from "vue";
import Component from "vue-class-component";
import {Prop, Ref, Watch} from "vue-property-decorator";
import DatePicker from "../Form/DatePicker/DatePicker.vue";
import stringifyDate from "../../utils/date/stringifyDate";
import DATE_FORMAT from "../../utils/date/DATE_FORMAT";

@Component({
  name: "PrescriptionDate",
  components: {DatePicker},
})
export default class PrescriptionDate extends Vue {
  @Prop({type: Date, default: null}) readonly value!: Date;
  @Prop({type: Boolean, default: false}) readonly disabled!: boolean;
  @Prop({type: String, default: "Wybierz datę"}) readonly placeholder!: string;

  @Ref("datepicker") readonly datepicker!: DatePicker;

  private edited: boolean = false;

  private get dateStr(): string {
    return this.value ? stringifyDate(this.value, DATE_FORMAT.DATE) : "";
  }

  @Watch("value", {immediate: true}) onValueChange(val: Date|null): void {
    if (!val) {
      this.update(new Date());
    }
  }

  private update(date: Date|null): void {
    this.$emit("input", date);
    this.stopEdit();
  }

  private startEdit(): void {
    if (!this.disabled) {
      this.edited = true;
      this.$nextTick(() => this.datepicker.showPopup());
    }
  }

  private stopEdit(): void {
    this.edited = false;
  }
}
