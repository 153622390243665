













import I18n from "../../i18n.vue";
import {Component, Prop} from "vue-property-decorator";
import Vue from "vue";

@Component({
  name: "ChildIpzStatusBadge",
  components: {I18n}
})

export default class ChildIpzStatusBadge extends Vue {
  @Prop({type: String, required: true}) readonly status!: string;

  get badgeVariant(): string {
    switch (this.status) {
      case "edit":
        return "primary";
      case "opened":
        return "success";
      case "closed":
        return "danger";
      default:
        return "secondary"
    }
  }
}

