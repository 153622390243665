<template>
  <div class="row">
    <div class="col-12 text-right m-b-10">
      <button
        class="btn btn-sm btn-secondary m-1"
        @click="showAll"
      >
        Rozwiń wszystko
      </button>
      <a
        v-if="atLeastOneEntry"
        :href="`/api/ipzs/therapeutic-plans/${therapeuticPlanId}/print`"
        class="btn btn-sm btn-primary m-1"
        target="_blank"
      >
        <i class="fa fa-print" />
        Drukuj Plan Terapeutyczny
      </a>
    </div>
    <div class="col-12">
      <diagnosis-collapse
        v-model="visibleDetails"
        :entries="getFieldsEntries(['THERAPEUTIC_RESOURCES', 'THERAPEUTIC_NEEDS'])"
        :index="0"
        :title="titles[0]"
      >
        <diagnosis-field
          v-for="(item, name) in {
            THERAPEUTIC_RESOURCES: 'Zasoby',
            THERAPEUTIC_NEEDS: 'Potrzeby',
          }"
          :key="name"
          :read-only="overview.readonly"
          :injected-entries="getField(name).entries"
          :type="name"
          :type-trans="item.toUpperCase()"
          :create-entry-url="createEntryUrl"
          @new-entry-added="entryUpdated"
        />
      </diagnosis-collapse>
      <diagnosis-collapse
        v-model="visibleDetails"
        :entries="getFieldsEntries(['THERAPEUTIC_MAIN_TARGETS', 'THERAPEUTIC_TARGETS'])"
        :index="1"
        :title="titles[1]"
      >
        <diagnosis-field
          v-for="(item, name) in {
            THERAPEUTIC_MAIN_TARGETS: 'Cele główne',
          }"
          :key="name"
          :read-only="overview.readonly"
          :injected-entries="getField(name).entries"
          :type="name"
          :type-trans="item.toUpperCase()"
          :create-entry-url="createEntryUrl"
          @new-entry-added="entryUpdated"
        />
        <editable-diagnosis-field
          v-for="(item, name) in {
            THERAPEUTIC_TARGETS: 'Cele pracy terapeutycznej',
          }"
          :key="name"
          :read-only="overview.readonly"
          :injected-entries="getField(name).entries"
          :type="name"
          :type-trans="item.toUpperCase()"
          :create-entry-url="createEntryUrl"
          @new-entry-added="entryUpdated"
        />
      </diagnosis-collapse>
      <diagnosis-collapse
        v-model="visibleDetails"
        :entries="therapeuticTaskEntries"
        :index="2"
        :title="titles[2]"
      >
        <TherapeuticTasks
          :read-only="overview.readonly"
          :therapeutic-plan-id="therapeuticPlanId"
          @entry-updated="therapeuticTaskEntryUpdated"
        />
      </diagnosis-collapse>
      <diagnosis-collapse
        v-model="visibleDetails"
        :entries="meetingRequirementsEntries"
        :index="3"
        :title="titles[3]"
      >
        <MeetingRequirements
          :read-only="overview.readonly"
          :ipz-id="overview.ipzId"
          @entry-updated="meetingRequirementsEntryUpdated"
        />
      </diagnosis-collapse>
    </div>
  </div>
</template>

<script>
import MeetingRequirements from "./MeetingRequirements/MeetingRequirements";
import TherapeuticTasks from "./TherapeuticTasks/TherapeuticTasks";
import EditableDiagnosisField from "./EditableDiagnosisField";
import DiagnosisField from "../DiagnosisField/DiagnosisField";
import DiagnosisCollapse from "../DiagnosisCollapse";

export default {
  name: "TherapeuticPlanDetails",
  components: {DiagnosisCollapse, DiagnosisField, EditableDiagnosisField, TherapeuticTasks, MeetingRequirements},
  props: {
    therapeuticPlanId: {type: String, required: true},
    overview: {type: Object, required: true},
    fields: {type: [Object, Array], required: true},
  },
  data() {
    return {
      visibleDetails: [],
      therapeuticTaskEntries: [],
      meetingRequirementsEntries: [],
    };
  },
  computed: {
    createEntryUrl() {
      return `/api/ipzs/therapeutic-plans/${this.therapeuticPlanId}/entries`;
    },
    atLeastOneEntry() { return Object.keys(this.fields).length > 0},
    titles() {
      return [
        "Analiza zasobów, potrzeb",
        "Plan terapeutyczny odpowiedzialnego za realizację celów IPZ przy wsparciu zespołu ŚCZP" +
          " i sieci oparcia społecznego",
        "Cele i plan działań terapeutycznych",
        "Karta interwencji",
      ]
    },
  },
  methods: {
    getField(key) {
      return undefined !== this.fields[key] ? this.fields[key] : {entries: []};
    },
    entryUpdated() {
      this.$emit("update");
    },
    showAll() {
      this.visibleDetails = this.titles.map((item, index) => index);
    },
    getFieldsEntries(fieldsNames) {
      return Object.entries(this.fields).reduce((entriesList, [key, value]) => {
        if (fieldsNames.includes(key)) {
          return [...entriesList, ...value.entries];
        }
        return entriesList;
      }, []);
    },
    therapeuticTaskEntryUpdated(entries) {
      this.therapeuticTaskEntries = entries;
      this.entryUpdated();
    },
    meetingRequirementsEntryUpdated(entries) {
      this.meetingRequirementsEntries = entries;
      this.entryUpdated();
    },
  },
}
</script>
