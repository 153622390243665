<template>
  <anonymous-layout>
    <b-alert
      :show="showMessage"
      dismissible
      variant="info"
      @dismissed="goToLoginPage"
    >
      Hasło zostało zmienione
    </b-alert>
    <router-link
      class="logotype-wrapper m-b-20 mx-auto text-center"
      :to="{name: 'login'}"
    >
      <logotype
        :width="290"
        class="img-fluid"
      />
    </router-link>
    <env-name class="text-white" />
    <form
      class="form-horizontal m-t-20 text-white"
      @submit.prevent="submit"
    >
      <h2 class="text-center m-b-25 text-white">
        Ustaw nowe hasło
      </h2>
      <b-alert
        :show="errors.length > 0"
        dismissible
        fade
        variant="danger"
        @dismissed="clearErrors"
      >
        <i18n
          v-for="(error,index) in errors"
          :key="index"
          :msgid="error.message"
          component="div"
        />
      </b-alert>
      <div class="input-group mb-2">
        <div class="input-group-prepend">
          <label
            class="input-group-text"
            for="password"
          >
            <i class="fa fa-key" />
          </label>
        </div>
        <input
          id="password"
          v-model.trim="password"
          type="password"
          class="form-control"
          required
          placeholder="Nowe hasło"
        >
      </div>
      <div class="input-group mb-2">
        <div class="input-group-prepend">
          <label
            class="input-group-text"
            for="confirmPassword"
          >
            <i class="fa fa-key" />
          </label>
        </div>
        <input
          id="confirmPassword"
          v-model.trim="confirmedPassword"
          type="password"
          class="form-control"
          required
          placeholder="Potwierdź hasło"
        >
      </div>
      <div class="d-flex">
        <button
          :disabled="disabled || loading || disabledButton"
          class="btn btn-light w-md waves-effect waves-light ml-auto"
          type="submit"
        >
          <loading-icon
            :loading="loading"
            ready-icon="fa-unlock"
            size="sm"
          />
          Zatwierdź
        </button>
      </div>
    </form>
  </anonymous-layout>
</template>

<script>
import LoadingIcon from "../../components/Loading/LoadingIcon";
import update from "../../rest/update";
import read from "../../rest/read";
import processResponseException from "../../utils/errors/processResponseException";
import Logotype from "../../components/Layout/Logotype";
import {errorsMixin} from "../../mixins/errorsMixin.js";
import I18n from "../../components/i18n";
import AnonymousLayout from "../../components/Layout/AnonymousLayout";
import EnvName from "../../components/EnvName";

export default {
  name: "ResetPasswordForm",
  components: {EnvName, AnonymousLayout, Logotype, LoadingIcon, I18n},
  mixins: [errorsMixin],
  props: {
    token: {type: String, required: true},
  },
  data() {
    return {
      password: "",
      confirmedPassword: "",
      loading: false,
      tokenStatus: {},
      disabledButton: false,
      showMessage: false,
    };
  },
  computed: {
    disabled() {
      return !this.password || !this.confirmedPassword;
    },
  },
  async mounted() {
    await this.isTokenValid();
  },
  methods: {
    clearErrors() {
      this.errors = [];
    },
    async submit() {
      this.loading = true;
      try {
        const data = {
          confirmPassword: {
            password: this.password,
            confirmedPassword: this.confirmedPassword,
          },
        };
        await update(`/api/password/reset/confirm/${this.token}`, data);
        this.clearErrors();
        this.showMessage = 5;
      } catch (exception) {
        this.errors = processResponseException(exception);
      }
      this.loading = false;
    },
    goToLoginPage() {
      this.$router.push({name: "login"});
    },
    async isTokenValid(){
      this.tokenStatus = await read(`/api/password/reset/token/${this.token}`);
      this.tokenStatusMessages();
    },
    tokenStatusMessages(){
      if (!this.tokenStatus.tokenValid){
        this.errors = [{
          message: "Niepoprawne żądanie zresetowania hasła.",
        }];
        this.disabledButton = true;
      }
      if (this.tokenStatus.tokenExpired){
        this.errors = [{
          message: "Żądanie zresetowania hasła straciło swoją ważność.",
        }];
        this.disabledButton = true;
      }
    }
  },
}
</script>

<style scoped lang="scss">
  .logotype-wrapper {
    @media (max-width: 1000px) {
      width: 40vw;
    }
  }
</style>
