<template>
  <div class="card-box">
    <form>
      <h3>Nadpisywanie grafiku dziennego</h3>

      <b-form-group
        label="Data do nadpisania:"
        label-for="date"
      >
        <date-picker
          id="date"
          v-model="chosenDate"
          class="w-auto m-b-10"
          data-test="chosen-date"
        />
      </b-form-group>

      <div class="d-flex flex-wrap">
        <b-form-group
          class="mr-sm-5"
          label="Od godziny:"
          label-for="hour-from"
        >
          <hour-select
            id="hour-from"
            v-model="hourFrom"
            :state="state('rangeStart')"
            class="select"
            data-test="hour-from"
            placeholder="Godzina"
          />
          <error-message
            :errors="errors"
            field="rangeStart"
          />
        </b-form-group>
        <b-form-group
          label="Do godziny:"
          label-for="hour-to"
        >
          <hour-select
            id="hour-to"
            v-model="hourTo"
            :state="state('rangeEnd')"
            class="select"
            data-test="hour-to"
            placeholder="Godzina"
          />
          <error-message
            :errors="errors"
            field="rangeEnd"
          />
        </b-form-group>
      </div>
      <b-form-group
        label="Komórka organizacyjna:"
        label-for="branch"
      >
        <branch-select
          id="branch"
          v-model="selectedBranch"
          data-test="selected-branch"
          :state="state('branchId')"
        />
        <error-message
          :errors="errors"
          field="branchId"
        />
      </b-form-group>

      <transition name="fade">
        <div
          v-if="errorMessage.length > 0"
          class="alert alert-danger alert-dismissible form-errors"
        >
          <button
            type="button"
            class="close"
            @click="errorMessage = ''"
          >
            <span>&times;</span>
          </button>
          {{ errorMessage }}
        </div>
      </transition>

      <div class="d-flex justify-content-end flex-wrap">
        <button
          class="btn btn-secondary"
          data-test="go-back"
          @click.prevent="goBackToList"
        >
          Powrót
        </button>
        <button
          :disabled="submittingInProgress"
          class="btn btn-primary ml-2"
          data-test="submit"
          @click.prevent="submit"
        >
          <i
            :class="submittingInProgress ? 'fa-spinner fa-spin' : 'fa-check'"
            class="fa"
          />
          Zapisz
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import DatePicker from "../../Form/DatePicker/DatePicker";
import HourSelect from "../../Form/Select/HourSelect";
import create from "../../../rest/create";
import processResponseException from "../../../utils/errors/processResponseException";
import stringifyDate from "../../../utils/date/stringifyDate";
import ErrorMessage from "../../Form/ErrorMessage";
import {errorsMixin} from "../../../mixins/errorsMixin.js";
import BranchSelect from "../../Branch/BranchSelect";

export default {
  components: {
    BranchSelect,
    HourSelect,
    DatePicker,
    ErrorMessage,
  },
  mixins: [errorsMixin],
  props: {
    workerId: {type: String, required: true},
  },
  data() {
    return {
      selectedBranch: null,
      submittingInProgress: false,
      chosenDate: new Date(),
      hourFrom: {HH: "", mm: ""},
      hourTo: {HH: "", mm: ""},
      errorMessage: "",
    };
  },
  computed: {
    rangeStart() {
      if (this.hourFrom.HH === "" || this.hourFrom.mm === "") {
        return;
      }
      return stringifyDate(this.chosenDate.setHours(this.hourFrom.HH, this.hourFrom.mm, 0, 0), "YYYY-MM-DD HH:mm")
    },
    rangeEnd() {
      if (this.hourTo.HH === "" || this.hourTo.mm === "") {
        return;
      }
      const date = new Date(this.chosenDate);
      if (Number(this.hourTo.HH) === 0 && Number(this.hourTo.mm) === 0) {
        date.setDate(date.getDate() + 1);
      }
      return stringifyDate(date.setHours(this.hourTo.HH, this.hourTo.mm, 0, 0), "YYYY-MM-DD HH:mm")
    }
  },
  methods: {
    goBackToList() {
      this.$router.push({name: "listWorkingHoursOfWorker"});
    },
    async submit() {
      try {
        this.submittingInProgress = true;
        this.errors = [];
        this.errorMessage = "";
        await create(`/api/workers/${this.workerId}/working-hours`, {
          isOverriding: true,
          rangeStart: this.rangeStart,
          rangeEnd: this.rangeEnd,
          branchId: this.selectedBranch,
        });
        this.submittingInProgress = false;
        this.$emit("rangeSubmitted");
      } catch(exception) {
        this.submittingInProgress = false;
        const errors = processResponseException(exception);
        this.errors = errors;
        const errorsWithoutField = errors.filter(error => error.field === undefined || error.field === "");
        this.errorMessage = errorsWithoutField.map(error => error.message).join();
      }
    }
  },
}
</script>

<style scoped lang="scss">
  .select {
    width: 228px;
    max-width: 100%;
  }
</style>
