<template>
  <li
    v-show="menuItem.dropdownItems.length"
    :class="{active: containsActiveLink}"
  >
    <a
      :aria-controls="`dropdown${index}`"
      :aria-expanded="isVisibleDropdown(index)"
      class="waves-effect waves-primary"
      @click="onClick(index)"
    >
      <i :class="menuItem.icon" />
      <span>{{ menuItem.name }}</span>
      <span
        :class="{opened: isVisibleDropdown(index)}"
        class="fa fa-caret-down toggle-submenu"
      />
    </a>
    <ul
      :id="`dropdown${index}`"
      :class="{visible: isVisibleDropdown(index)}"
    >
      <b-nav-item
        v-for="(dropdownItem, idx) in menuItem.dropdownItems"
        :key="idx"
        :data-test="`menu-item_${dropdownItem.id}`"
        :class="{active: isActiveLink(dropdownItem.link)}"
        :to="dropdownItem.link"
        exact
      >
        <span>{{ dropdownItem.name }}</span>
      </b-nav-item>
    </ul>
  </li>
</template>

<script>
import {mapState} from "vuex";

export default {
  props: {
    index: {type: Number, required: true},
    menuItem: {type: Object, required: true},
    visibleDropdownIndex: {type: Number, default: null},
  },
  computed: {
    ...mapState("sidebar", ["menuOpened",]),
    containsActiveLink() {
      return this.menuItem.dropdownItems.some(item => this.isActiveLink(item.link));
    },
  },
  methods: {
    isActiveLink(link) {
      return link === this.$route.path;
    },
    isVisibleDropdown(index) {
      if (this.menuOpened) {
        return index === this.visibleDropdownIndex;
      } else {
        return true;
      }
    },
    onClick(index) {
      this.$emit("showDropdown", index);
    },
  },
}
</script>

<style lang="scss" scoped>
  @import "../../styles/variables";

  #sidebar-menu ul {

    li.active > a {
      background: $lightdark5 !important;
      border-left: 3px solid $primary;
    }

    ul.visible {
      display: block;
    }
  }

  .toggle-submenu {
    transition: transform .3s;
    position: absolute;
    right: 20px;
    display: inline-block;
    text-rendering: auto;
    line-height: 1.5rem;
    font-size: 1rem;
    transform: translate(0, 0);
    color: $muted;

  &.opened {
     transform: rotate(180deg);
   }
  }
</style>
