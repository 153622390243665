<template>
  <form @submit.prevent="onSubmit">
    <patient-participant-status-data
      v-model="formData"
      :errors="subErrors('patientParticipantStatusData')"
    />
    <error-message :errors="errors" />
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-secondary"
        @click="cancel"
      >
        Anuluj
      </button>
      <button
        :disabled="loading"
        type="submit"
        class="btn btn-primary"
      >
        <i
          :class="loading ? 'fa-spin fa-spinner' : 'fa-check'"
          class="fa"
        />
        Zapisz
      </button>
    </div>
  </form>
</template>

<script>
import update from "../../rest/update";
import read from "../../rest/read";
import getSubErrors from "../../utils/errors/subErrors";
import processResponseException from "../../utils/errors/processResponseException";
import ErrorMessage from "../Form/ErrorMessage";
import PatientParticipantStatusData from "./PatientParticipantStatusData";
import validate, {notEmptyRule} from "../../utils/validate";

export default {
  components: {
    PatientParticipantStatusData,
    ErrorMessage,
  },
  props: {
    patientId: {type: String, required: true},
    status: {type: Object, default: null},
  },
  data() {
    return {
      participantStatus: this.status ? this.status : {},
      formData: {},
      errors: [],
      loading: false,
    };
  },
  watch: {
    status() {
      this.participantStatus = {...this.status};
      this.resetData();
    },
    async patientId() {
      if (!this.status) {
        await this.getParticipantStatus();
        this.resetData();
      }
    },
  },
  async mounted() {
    if (!this.status) {
      await this.getParticipantStatus();
    }
    this.resetData();
  },
  methods: {
    async getParticipantStatus() {
      this.loading = true;
      try {
        const {patientParticipantDTO} = await read(`/api/patients/${this.patientId}/participant_status`);
        this.participantStatus = patientParticipantDTO;

      } catch (exception) {
        this.errors = processResponseException(exception);
      }
      this.loading = false;
    },
    translate(string) {
      return t(string);
    },
    validateUnificationData() {
      const rules = [
        {field: "minorityBelonging", rule: notEmptyRule},
        {field: "homeless", rule: notEmptyRule},
        {field: "disabilitiesPerson", rule: notEmptyRule},
        {field: "badSituation", rule: notEmptyRule},
      ];

      const root = "patientParticipantStatusData";
      const data = this.participantStatus || {};
      this.errors = validate(rules, root, this.errors)(data);
    },
    subErrors(field) {
      return getSubErrors(this.errors, field);
    },
    async onSubmit() {
      this.loading = true;
      try{
        await update(`/api/patients/${this.patientId}/participant_status`, {
          patientParticipantStatusData: this.formData,
        });
        this.participantStatus = {...this.formData};
        this.$emit("updateStatus", this.participantStatus);
        this.cancel();
      } catch (exception) {
        this.errors = processResponseException(exception);
        this.validateUnificationData();
      }
      this.loading = false;
    },
    cancel() {
      this.resetData();
      this.$emit("cancel");
    },
    resetData(){
      this.formData = {...this.participantStatus};
      this.errors = [];
      this.validateUnificationData();
    },
  },
};
</script>
