<template>
  <div>
    <calendar-color
      class="m-1"
      :color-class="getColorClass('created')"
      :color-name="getColorName('created')"
    />
    <calendar-color
      class="m-1"
      :color-class="getColorClass('opened')"
      :color-name="getColorName('opened')"
    />
    <calendar-color
      class="m-1"
      :color-class="getColorClass('finished')"
      :color-name="getColorName('finished')"
    />
  </div>
</template>

<script>
import CalendarColor from "../Calendar/CalendarColor";

export default {
  name: "RegistrationLegend",
  components: {CalendarColor},
  methods: {
    getColorClass(status) {
      switch (status) {
        case "created":
          return "bg-warning";
        case "opened":
          return "bg-success";
        case "finished":
          return "bg-inverse";
        default:
          throw new Error(`Unknown appointment status '${status}'`);
      }
    },
    getColorName(status) {
      switch (status) {
        case "created":
          return "Wizyta utworzona";
        case "opened":
          return "Wizyta rozpoczęta";
        case "finished":
          return "Wizyta zakończona";
        default:
          throw new Error(`Unknown appointment status '${status}'`);
      }
    },
  },
}
</script>
