<template>
  <is-granted
    :privileges="['APPOINTMENT_LIST']"
    component="div"
  >
    <appointments-card
      :filters="{startDate, statuses}"
      :columns="['hour','date','specialistTypeStatus','patientsLinks']"
      title="Najbliższe wizyty"
      description="Poniżej znajdziesz umówione wizyty oraz te które jeszcze trwają."
      show-details
    />
  </is-granted>
</template>
<script>
import AppointmentsCard from "./AppointmentsCard";
import IsGranted from "../IsGranted";
import stringifyDate from "../../utils/date/stringifyDate";
import DATE_FORMAT from "../../utils/date/DATE_FORMAT";

export default {
  components: {AppointmentsCard, IsGranted},
  computed: {
    startDate() {
      return stringifyDate(new Date(), DATE_FORMAT.DEFAULT);
    },
    statuses() {
      return ["created", "checkedin", "opened"];
    },
  }

}
</script>
