<template>
  <object-select
    :value="selected"
    :options="options"
    :state="state"
    placeholder="Wybierz osobę ewaluującą"
    @input="update"
  />
</template>

<script>
import ObjectSelect from "../../Form/Select/ObjectSelect";
import t from "../../../i18n";

export default {
  components: {
    ObjectSelect
  },
  props: {
    value: {type: String, default: null},
    state: ObjectSelect.props.state, // eslint-disable-line
  },
  data() {
    return {
      options: [
        {name: t("family"), value:"family"},
        {name: t("colleague"), value:"colleague"},
        {name: t("friend"), value:"friend"},
        {name: t("institution"), value:"institution"},
        {name: t("professional"), value:"professional"},
        {name: t("outsider"), value:"outsider"},
      ],
    };
  },
  computed: {
    selected() {
      return this.options.find(option => option.value === this.value) || null;
    },
  },
  methods: {
    update(evaluatorType) {
      this.$emit("input", evaluatorType ? evaluatorType.value : null);
    },
  },
}
</script>
