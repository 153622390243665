<template>
  <div>
    <loading-mask :loading="loading">
      <media-query-collapse
        message-close="Zwiń filtry"
        message-open="Rozwiń filtry"
        collapse-media-query="(max-width: 991.98px)"
        collapse-open
      >
        <template #collapseContent>
          <calendar-filter
            id="calendar-filter"
            :view="view"
            :treatment-type="treatmentType"
            :appointment-id="appointmentId"
            :copy-id="copyId"
            :workers="workers"
            :start-date="startDate"
            @reload="reloadCalendar"
          />
        </template>
      </media-query-collapse>
    </loading-mask>

    <calendar
      v-if="!loading && startDate && endDate"
      ref="calendar"
      :filters="{
        treatmentType,
        appointmentId,
        copyId,
        startDate,
        endDate,
        workers,
        continuousStay
      }"
      :view="view"
      :preferred-patient="stickyPatient"
    />
  </div>
</template>
<script>
import CalendarFilter from "../../components/Calendar/CalendarFilter.vue";
import Calendar from "../../components/Calendar/Calendar.vue";
import MediaQueryCollapse from "../../components/MediaQueryCollapse";
import {mapState} from "vuex";
import read from "../../rest/read";
import DATE_FORMAT from "../../utils/date/DATE_FORMAT";
import LoadingMask from "../../components/Loading/LoadingMask";
import stringifyDate from "../../utils/date/stringifyDate";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import {isGranted} from "@/security/isGranted";
import {specialistRoles} from "../../utils/roles/specialistRoles";

export default {
  name: "CalendarPage",
  components: {
    LoadingMask,
    MediaQueryCollapse,
    CalendarFilter,
    Calendar,
  },
  props: {
    view: {type: String, default: "month"},
    treatmentType: {type: String, default: null},
    appointmentId: {type: String, default: null},
    copyId: {type: String, default: null},
    startDate: {type: Date, default: null},
    endDate: {type: Date, default: null},
    workers: {type: Array, default: null},
    continuousStay: {type: Boolean, default: null},
  },
  data() {
    return {
      loading: true,
      currentUserIsSpecialist: null,
    }
  },
  computed: {
    ...mapState({
      stickyPatient: state => state.stickyPatient.patient,
      appointmentScheduled: state => !!state.scheduleAppointment.appointmentDetails,
      user: state => state.currentUser.user,
    })
  },
  watch: {
    "$route"(to) {
      if (isEmpty(to.query)) {
        this.getCalendarData();
      }
    },
  },
  async mounted() {
    this.currentUserIsSpecialist = await isGranted(specialistRoles);
    await this.getCalendarData();
  },
  methods: {
    async getCalendarData() {
      const params = {
        view: this.view,
        treatmentType: this.treatmentType,
        appointmentId: this.appointmentId,
        startDate: this.startDate ? stringifyDate(this.startDate, DATE_FORMAT.DATE_TIME) : null,
        endDate: this.endDate ? stringifyDate(this.endDate, DATE_FORMAT.DATE_TIME) : null,
        workers: this.workers,
      };
      this.loading = true;
      const {startDate, endDate, workers, treatmentType} = await read("/api/calendar", params);
      this.loading = false;

      const appointmentTreatmentType = (this.appointmentId || this.copyId) ? this.treatmentType : treatmentType;

      const query = {
        ...this.$route.query,
        startDate: startDate || undefined,
        endDate: endDate || undefined,
        workers: workers.join(",") || (this.currentUserIsSpecialist && this.user.workerId) || undefined,
        treatmentType: appointmentTreatmentType || undefined,
      };
      if (isEqual(this.$route.query, query)) {
        return;
      }
      this.$router.replace({
        path: this.$route.path,
        query
      });
    },
    reloadCalendar() {
      this.$refs.calendar.load();
    }
  },
}
</script>
