<template>
  <loading-mask :loading="loading">
    <error-message :errors="errors" />
    <template v-if="overview">
      <template v-if="!branch24hId">
        <b-btn
          :disabled="overview.readonly || loading"
          class="btn btn-primary btn-sm"
          @click="new24hBranch"
        >
          <i
            :class="loading ? 'fa-spin fa-spinner' : 'fa-plus'"
            class="fa"
          />
          Nowy oddział całodobowy
        </b-btn>
      </template>
      <template v-else>
        <branch-24-h-details
          :fields="fields"
          :overview="overview"
          :branch24h-id="branch24hId"
          @update="loadData"
        />
      </template>
    </template>
  </loading-mask>
</template>

<script>
import ErrorMessage from "../../../components/Form/ErrorMessage";
import read from "../../../rest/read";
import create from "../../../rest/create";
import {errorsMixin} from "../../../mixins/errorsMixin.js";
import processResponseException from "../../../utils/errors/processResponseException";
import {generateUuid} from "../../../utils/uuid/generateUuid";
import Branch24HDetails from "../../../components/Ipz/Branch24/Branch24HDetails";
import LoadingMask from "../../../components/Loading/LoadingMask";

export default {
  name: "Branch24HPanel",
  components: {LoadingMask, ErrorMessage, Branch24HDetails},
  mixins: [errorsMixin],
  props: {
    ipzId: {type: String, required: true},
  },
  data() {
    return {
      overview: null,
      fields: null,
      branch24hId: null,
      loading: false,
    };
  },
  watch: {
    async ipzId() {
      await this.loadData();
    },
  },
  async mounted () {
    await this.loadData();
  },
  methods: {
    async loadData() {
      this.loading = true;
      const {overview, fields, branch24hId} = await read(`/api/ipzs/${this.ipzId}/details/24-hour-branch`);
      this.overview = overview;
      this.fields = fields;
      this.branch24hId = branch24hId;
      this.loading = false;
    },
    async new24hBranch() {
      this.loading = true;
      this.errors = [];
      try {
        const branch24hId = generateUuid();
        await create(`/api/ipzs/${this.ipzId}/24-hour-branch`, {
          branch24hId,
        });
        await this.loadData();
      } catch (e) {
        this.errors = processResponseException(e);
      }
      this.loading = false;
    },
  },
}
</script>
