<template>
  <div class="card-box">
    <is-granted
      :privileges="['EDIT_INSURANCE_ACCESS_PROVIDER']"
      :subject="workerId"
      component="fragment"
    >
      <loading-mask
        :loading="loadingMask"
        class="card-box"
      >
        <ewus-access-form
          v-model="access"
          :display-info="displayInfo"
          :prop-errors="errors"
        />
        <error-message :errors="errors" />
      </loading-mask>
    </is-granted>
    <div class="text-right">
      <router-link
        :to="{name: 'worker_list'}"
        class="btn btn-secondary"
      >
        Powrót
      </router-link>
      <is-granted
        :privileges="['EDIT_INSURANCE_ACCESS_PROVIDER']"
        :subject="workerId"
        component="fragment"
      >
        <button
          :disabled="loading || loadingMask"
          type="submit"
          class="btn btn-primary ml-2"
          @click="submit"
        >
          <i
            :class="loading ? 'fa-spin fa-spinner' : 'fa-check'"
            class="fa"
          />
          Zapisz
        </button>
      </is-granted>
    </div>
  </div>
</template>

<script>
import {errorsMixin} from "../../mixins/errorsMixin.js";
import read from "../../rest/read";
import LoadingMask from "../../components/Loading/LoadingMask";
import {generateUuid} from "../../utils/uuid/generateUuid";
import create from "../../rest/create";
import {mapActions} from "vuex";
import processResponseException from "../../utils/errors/processResponseException";
import ErrorMessage from "../../components/Form/ErrorMessage";
import IsGranted from "../../components/IsGranted";
import EwusAccessForm from "../../components/Ewus/EwusAccessForm";

export default {
  name: "WorkerEwusAccess",
  components: {
    EwusAccessForm,
    IsGranted,
    ErrorMessage,
    LoadingMask,
  },
  mixins: [errorsMixin],
  props: {
    workerId: {type: String, required: true},
  },
  data() {
    return {
      loading: false,
      loadingMask: true,
      displayInfo: false,
      access: {
        login: null,
        password: null,
        operatorType: null,
        domain: null
      }
    };
  },
  async mounted() {
    this.loadData();
  },
  methods: {
    ...mapActions({
      showToast: "toastNotification/showToast",
    }),
    async loadData() {
      try {
        const data = await read(`/api/worker/${this.workerId}/ewus-access`);
        this.access = {
          login: data.ewusLogin,
          password: data.ewusPassword,
          operatorType: data.ewusOperatorType,
          domain: data.ewusDomain
        };
        this.displayInfo = true;
      } catch (exception) {
        this.errors = processResponseException(exception);
      }
      this.loading = false;
      this.loadingMask = false;
    },
    async submit() {
      this.loading = true;
      const data = {
        id: generateUuid(),
        ewusLogin: this.access.login,
        ewusPassword: this.access.password ? this.access.password : "",
        ewusOperatorType: this.access.operatorType,
        ewusDomain: this.access.domain,
        workerId: this.workerId
      };

      try {
        await create(`/api/worker/${this.workerId}/ewus-access`, data);
        this.access.password = null;
        await this.$router.push({name: "worker_list"});
        this.showToast({
          message: "Dane zostały zapisane",
          variant: "success"
        });
        this.errors = [];
      } catch(e) {
        this.errors = processResponseException(e);
      }

      this.loading = false;
    },
  }
}
</script>

