<template>
  <div>
    <template v-if="!isEmpty">
      <h2 class="text-center p-2">
        {{ dateString }}
      </h2>
      <timetable-worker
        v-for="worker in workers"
        :key="`${worker.workerId}-${worker.branchId}`"
        :worker="worker"
        :items="filteredItems(worker.workerId, worker.branchId)"
        :treatment-duration="treatmentDuration"
        :filters="filters"
        :preferred-patient="preferredPatient"
      />
    </template>
    <div
      v-else
      class="p-5"
    >
      Brak wolnych wizyt w wybranym terminie
    </div>
    <appointment-box-modals @appointmentScheduled="updateCalendar" />
  </div>
</template>
<script>
import DATE_FORMAT from "../../utils/date/DATE_FORMAT";
import stringifyDate from "../../utils/date/stringifyDate";
import TimetableWorker from "./TimetableWorker";
import AppointmentBoxModals from "../Appointment/AppointmentBox/AppointmentBoxModals";

export default {
  components: {
    AppointmentBoxModals,
    TimetableWorker,
  },
  props: {
    filters: {type: Object, required: true},
    workers: {type: Array, required: true},
    items:  {type: Array, required: true},
    treatmentDuration: {type: Number, required: true},
    preferredPatient: {type: Object, default: null},
  },
  computed: {
    isEmpty() {
      return null == this.items.find((item) => item.clickable || item.editable);
    },
    dateString() {
      return stringifyDate(this.filters.startDate, DATE_FORMAT.DATE);
    },
  },
  methods: {
    filteredItems(workerId, branchId) {
      return this.items.filter(item => item.workerId === workerId && item.branchId === branchId);
    },
    updateCalendar() {
      this.$emit("updateCalendar");
    },
  },
}
</script>

