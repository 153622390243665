<template>
  <div class="card-box">
    <div class="row">
      <h4 class="col">
        Aktualnie dostępne raporty
      </h4>
    </div>
    <hr>
    <div class="d-flex row justify-content-around">
      <is-granted
        v-for="report in reportItems"
        :key="report.id"
        :privileges="report.permissionAttributes"
        component="fragment"
      >
        <div class="col-11 col-lg-6 col-xl-5 card m-b-20 card-body report-card">
          <h4 class="card-title">
            {{ report.name }}
          </h4>

          <button
            type="button"
            class="btn btn-secondary btn-sm position-absolute tooltip-button"
            title="Opis i legenda raportu"
            @click="openReportSettingsModal(report)"
          >
            <i class="fa fa-question" />
          </button>

          <p class="card-text">
            {{ report.description }}
          </p>

          <router-link
            :to="report.path"
            class="btn btn-primary mt-auto"
          >
            Idź do raportu
          </router-link>
        </div>
      </is-granted>
    </div>

    <tooltip-modal
      :report="selectedReport"
      @close="deselectReport"
    />
  </div>
</template>

<script>
import IsGranted from "../../components/IsGranted";
import TooltipModal from "../../components/Report/ReportTooltipModal";
import reportItems from "../../components/Report/reportsData.ts";

export default {
  components: {TooltipModal, IsGranted},
  data() {
    return {
      reportItems,
      selectedReport: null,
    }
  },
  methods: {
    openReportSettingsModal(report) {
      this.selectedReport = report;
    },
    deselectReport() {
      this.selectedReport = null;
    },
  },
}
</script>

<style scoped lang="scss">
  @import "../../styles/variables";

  .report-card {
    @include media-breakpoint-up(lg) {
      &:last-child:nth-child(odd) {
        margin-right: 50%;
      }
    }
  }

  .tooltip-button {
    top: 15px;
    right: 10px;
  }
</style>
