




































import Component from "vue-class-component";
import Vue from "vue";
import {Prop, Ref} from "vue-property-decorator";
import NfzBranchSelect from "../Form/Select/NfzBranchSelect.vue";
import type {NfzBranch} from "../../types/Prescription";
import {Error} from "../../rest";
import blur from "../../directives/blur";

@Component({
  name: "NfzBranch",
  directives: {
    blur,
  },
  components: {NfzBranchSelect}
})
export default class PrescriptionNfzBranch extends Vue {
  @Prop({type: Object, default: null}) readonly value!: NfzBranch;
  @Prop({type: Boolean, default: false}) readonly disabled!: boolean;
  @Prop({type: Array, default: ()=>[]}) readonly errors!: Array<Error>

  @Ref("multiselect") readonly multiselect!: NfzBranchSelect;

  private edited: boolean = false;

  private get isInvalid(): boolean {
    return !!this.errors.find((error) => error.field === "nfzBranchId");
  }

  private update(newValue: NfzBranch|null): void {
    this.$emit("input", newValue);
  }

  private startEdit(): void {
    if (!this.disabled) {
      this.edited = true;
      this.$nextTick(() => this.multiselect.activate());
    }
  }

  private stopEdit(): void {
    this.edited = false;
  }

  private clearValue() :void {
    this.update(null);
    this.edited = false;
  }
}
