import {
  BadRequestException,
  ForbiddenException,
  NotFoundException,
  ServiceUnavailableException,
  ConflictException,
  UnauthorizedException, NetworkFailureException
} from "../../rest";

export default function processResponseException(exception) {
  if (exception instanceof BadRequestException) {
    return exception.errors;
  }
  if (exception instanceof NotFoundException) {
    return [{
      message: "Nie znaleziono",
    }];
  }
  if (exception instanceof ForbiddenException) {
    return [{
      message: "Brak dostępu",
    }];
  }
  if (exception instanceof UnauthorizedException) {
    return [{
      message: "Nieautoryzowany dostęp",
    }];
  }
  if (exception instanceof ServiceUnavailableException) {
    return [{
      message: "Przerwa techniczna",
    }];
  }
  if (exception instanceof ConflictException) {
    return [{
      message: "Konflikt",
    }];
  }

  if (exception instanceof NetworkFailureException) {
    return [{
      message: "Brak połączenia z internetem",
    }];
  }

  return [{
    message: `Wystąpił nieznany błąd (${exception.status})`,
  }];
}
