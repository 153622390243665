<template>
  <b-modal
    :visible="visible"
    no-close-on-backdrop
    no-close-on-esc
    @hide="onModalHide"
  >
    <template #modal-header>
      <h3>Wizyta umówiona</h3>
      <b-btn
        class="close"
        @click="closeSummaryModal"
      >
        &times;
      </b-btn>
    </template>
    <template #default>
      <appointment-details
        v-if="visible"
        :date="date"
        :patients="patientNames"
        :patient-presence="patientPresence"
        :main-worker="mainWorker"
        :treatment-type="treatmentType"
        :branch-name="branchName"
        :room="room"
        :address="address"
      />
    </template>
    <template #modal-footer>
      <is-granted
        :privileges="['OPEN_APPOINTMENT']"
        :subject="appointmentId"
        component="fragment"
      >
        <button
          class="btn btn-success mr-auto"
          @click="openAppointment"
        >
          Otwórz wizytę
        </button>
      </is-granted>
      <button
        class="btn btn-secondary"
        @click="closeSummaryModal"
      >
        Zamknij
      </button>
      <button
        v-if="patients.length === 1"
        class="btn btn-primary"
        @click="goToPatientCard"
      >
        Karta Pacjenta
      </button>
    </template>
  </b-modal>
</template>

<script>
import {mapActions} from "vuex";
import AppointmentDetails from "./AppointmentDetails";
import IsGranted from "../IsGranted";

export default {
  components: {
    IsGranted,
    AppointmentDetails,
  },
  props: {
    visible: {type: Boolean, default: true},
    appointmentId: {type: String, required: true},
    date: {type: Date, required: true},
    patients: {type: Array, default: () => []},
    patientPresence: {type: Boolean, default: true},
    mainWorker: {type: Object, required: true},
    treatmentType: {type: String, required: true},
    branchName: {type: String, default: null},
    room: {type: Object, default: null},
    address: {type: Object, default: null},
    continuousStay: {type: Boolean, default: false},
    patientContinuousStays: {type: Array, default: ()=>[]},
  },
  computed: {
    patientNames() {
      return this.patients.map(patient => {
        const name = `${patient.name} ${patient.surname || ""}`;
        return {...patient, name};
      });
    },
  },
  destroyed() {
    this.resetAppointmentDetails();
  },
  methods: {
    ...mapActions("scheduleAppointment", [
      "closeSummaryModal",
      "resetAppointmentDetails",
    ]),
    goToPatientCard() {
      this.$router.push({
        name: "patientCard",
        params: {patientId: this.patients[0].patientId},
      });
      this.closeSummaryModal();
    },
    onModalHide() {
      this.resetAppointmentDetails();
      this.$emit("appointmentScheduled");
    },
    openAppointment() {
      this.closeSummaryModal();
      this.$bvModal.show("open-appointment-modal");
    },
  },
}
</script>
