import {ActionTree} from "vuex";
import {ToastNotificationState} from "./types";
import {RootState} from "../../types";

export const actions: ActionTree<ToastNotificationState, RootState> = {
  showToast({commit}, {message, variant}) {
    commit("hideToast");
    commit("showToast", {message, variant});
  },
};
