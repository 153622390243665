import {MutationTree} from "vuex";
import {Privilege, SecurityState} from "./types";

const compare = (a: Privilege) => (b: Privilege) => {
  return a.subject === b.subject && a.attribute === b.attribute;
};

const unique = (a: Array<Privilege>) =>
  (b: Array<Privilege>=[]): Array<Privilege> =>
    a.reduce((c: Array<Privilege>, d: Privilege) => {
      return c.find(compare(d)) ? c : c.concat([d]);
    }, b.length ? unique(b)() : []);

const freeze = ({attribute, subject}: Privilege) => Object.freeze({attribute,subject});
const freezeResult = ({attribute, subject, access}: Privilege) => Object.freeze({attribute,subject, access});

export const mutations: MutationTree<SecurityState> = {
  subscribe(state, items) {
    const subscriptions = items.map(freeze);
    state.subscriptions = subscriptions.concat(state.subscriptions);
    const newSubs = subscriptions
      .filter((a: Privilege) => !state.queue.find(compare(a)))
      .filter((a: Privilege) => !state.privileges.find(compare(a)));
    state.queue = unique(state.queue)(newSubs);
  },
  unsubscribe(state, subscriptions) {
    state.subscriptions = subscriptions.reduce((list: Privilege[], sub: Privilege) => {
      const index = list.findIndex(compare(sub));

      return 0 > index ? list : [
        ...list.slice(0, index),
        ...list.slice(index + 1),
      ];
    }, state.subscriptions);

    //clear privileges
    state.privileges = state.privileges.filter((p) => {
      return state.subscriptions.find(compare(p));
    });
  },
  queue(state, queue) {
    state.queue = unique(state.queue)(queue.map(freeze));
  },
  registerCallback(state, {attribute, subject, callback}) {
    const item = Object.freeze({attribute, subject, callback});
    const privilege = state.privileges.find(compare(item));
    if (privilege) {
      callback(privilege.access);

      return;
    }
    state.callbacks = state.callbacks.concat([item]);

    state.queue = unique(state.queue)([freeze({attribute, subject})]);
  },
  fetched(state, result) {
    state.privileges = unique(state.privileges)(result).map(freezeResult);
    state.callbacks = state.callbacks.filter((callback) => {
      const item = state.privileges.find(compare(callback));
      if (!item) {
        return true;
      }
      callback.callback(item.access);
      return false;
    });
    state.queue = state.queue.filter((a) => !result.find(compare(a)));
  },
};
