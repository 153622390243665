<template>
  <div class="row">
    <div class="col">
      <resizable-textarea
        v-model="content"
        :disabled="loading"
        :state="state('content.text')"
        :rows="2"
        :placeholder="placeholder"
        required
      />
      <ErrorMessage
        :errors="errors"
        field="content.text"
      />
      <error-message :errors="errors" />
    </div>
    <div class="col-auto d-flex flex-column">
      <button
        :disabled="loading"
        class="btn-success btn"
        @click="addEntry"
      >
        <i
          v-show="loading"
          class="fa fa-spin fa-spinner"
        />
        <i
          v-show="!loading"
          class="fa fa-plus"
        />
        Zapisz
      </button>
      <button
        :disabled="loading"
        class="btn-secondary btn"
        @click="cancelEntry"
      >
        Anuluj
      </button>
    </div>
  </div>
</template>

<script>
import {generateUuid} from "@/utils/uuid/generateUuid";
import create from "../../../../rest/create";
import stringifyDate from "../../../../utils/date/stringifyDate";
import t from "../../../../i18n";
import ErrorMessage from "../../../Form/ErrorMessage";
import {errorsMixin} from "../../../../mixins/errorsMixin.js";
import processResponseException from "../../../../utils/errors/processResponseException";
import {mapState} from "vuex";
import ResizableTextarea from "../../../Form/Textarea/ResizableTextarea";

export default {
  name: "StringEntryAdd",
  components: {ResizableTextarea, ErrorMessage},
  mixins: [errorsMixin],
  props: {
    diagnosisId: {type: String, required: true},
    previousEntry: {type: Object, default: null},
    type: {type: String, required: true},
    typeName: {type: String, required: true},
  },
  data() {
    return {
      content: null,
      loading: false,
    };
  },
  computed: {
    placeholder() {
      return `Treść nowego opisu do pola ${t(this.typeName)}`;
    },
    ...mapState("currentUser", ["user"]),
  },
  mounted() {
    this.content = this.previousEntry ? this.previousEntry.content.text : null;
  },
  methods: {
    cancelEntry() {
      this.content = null;
      this.$emit("cancel-adding-entry");
    },
    async addEntry() {
      if (this.previousEntry && this.content === this.previousEntry.content.text) {
        this.$emit("cancel-adding-entry");

        return;
      }
      this.loading = true;
      try {
        const newEntry = {
          content: {
            type: "text",
            text: this.content,
          },
          entryId: generateUuid(),
          type: this.type,
          createdAt: stringifyDate(new Date()),
          workerId: this.user.workerId,
        };
        await create(`/api/diagnosis/${this.diagnosisId}/entry`, newEntry);
        const entry = {
          ...newEntry,
          creator: {
            name: this.user.name,
            surname: this.user.surname,
          },
        };
        this.$emit("new-entry-added", entry);

        this.content = null;
        this.errors = [];
      } catch (exception){
        this.errors = processResponseException(exception);
      }
      this.loading = false;
    },
  },
}
</script>
