import {actions} from "./splashScreen/actions";
import {getters} from "./splashScreen/getters";
import {mutations} from "./splashScreen/mutations";
import {state} from "./splashScreen/state";
import {Module} from "vuex";
import {SplashScreenState} from "./splashScreen/types";
import {RootState} from "../types";

const splashScreen: Module<SplashScreenState, RootState> = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};

export default splashScreen;
