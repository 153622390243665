import { render, staticRenderFns } from "./CertificateFileSelect.vue?vue&type=template&id=1184bfe5&scoped=true&"
import script from "./CertificateFileSelect.vue?vue&type=script&lang=ts&"
export * from "./CertificateFileSelect.vue?vue&type=script&lang=ts&"
import style0 from "./CertificateFileSelect.vue?vue&type=style&index=0&id=1184bfe5&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1184bfe5",
  null
  
)

export default component.exports