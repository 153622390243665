<template>
  <loading-mask
    :loading="loading"
  >
    <AsyncSelect
      :disabled="disabled"
      :clearable="clearable"
      :placeholder="placeholder"
      :options-provider="loadOptions"
      :value="value"
      :name="name"
      :multiple="multiple"
      :state="state"
      @input="update"
    />
  </loading-mask>
</template>

<script>
import AsyncSelect from "./AsyncSelect";
import read from "../../../rest/read";
import LoadingMask from "../../Loading/LoadingMask";

export default {
  components: {LoadingMask, AsyncSelect},
  props: {
    name: AsyncSelect.props.name, // eslint-disable-line
    value: {type: Object, default: null},
    patientId: {type: String, default: null},
    disabled: AsyncSelect.props.disabled, // eslint-disable-line
    clearable: AsyncSelect.props.clearable, // eslint-disable-line
    placeholder: {type: String, default: "Wybierz badanie"},
    multiple: AsyncSelect.props.multiple, // eslint-disable-line
    state: AsyncSelect.props.state, // eslint-disable-line
  },
  data() {
    return {
      loading: false,
    };
  },

  methods: {
    async loadOptions() {
      this.loading = true;

      const {items} = await read("/api/surveys", this.patientId ? {"patientId": this.patientId} : undefined);
      const map = items.map(item => ({...item, value: item.id}));

      this.loading = false;

      return map;
    },
    update(option) {
      this.$emit("input", option);
    },
  }
}
</script>

<style scoped>

</style>
