<template>
  <div>
    <b-btn
      v-b-modal.laborMarketStatus
      variant="primary"
      class="btn-sm"
    >
      Status pacjenta na rynku pracy
    </b-btn>
    <b-modal
      id="laborMarketStatus"
      hide-footer
      size="lg"
      no-close-on-backdrop
      title="Edycja statusu na rynku pracy"
      title-tag="h3"
    >
      <labor-market-status-form
        :patient-id="patientId"
        @cancel="cancel"
      />
    </b-modal>
  </div>
</template>

<script>
import LaborMarketStatusForm from "../LaborMarketStatusForm";

export default {
  components: {LaborMarketStatusForm},
  props: {
    patientId: {type: String, required: true},
  },
  methods: {
    cancel() {
      this.$emit("patientUpdated");
      this.$bvModal.hide("laborMarketStatus");
    },
  }
}
</script>
