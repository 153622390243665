<template>
  <div
    v-if="showEnvironment"
    class="text-center font-weight-bold font-18 mb-3"
  >
    {{ environmentName }}
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "EnvName",
  props: {
    small: {type: Boolean, default: false},
  },
  computed: {
    ...mapState({
      clinicParameters: state => state.clinicParameters.parameters,
    }),
    showEnvironment() {
      return this.clinicParameters && this.clinicParameters.environment === "uat";
    },
    environmentName() {
      if (this.showEnvironment) {
        const env = this.clinicParameters.environment.toUpperCase();
        return this.small ? env : `Środowisko ${env}`;
      }
      return null;
    },
  },
}
</script>
