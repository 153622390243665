import eventBus, {EVENT} from "../eventBus";
import {
  BadRequestException,
  defaults,
  ForbiddenException,
  NotFoundException,
  ServiceUnavailableException,
  ConflictException,
  UnauthorizedException,
  UnknownServerException,
  NetworkFailureException,
} from "./index";
import {store} from "../main";
import {storage, TOKEN} from "../store/modules/session";

export default async function create<Model extends object>(
  collection: string,
  model: Model
): Promise<string> {
  const body = JSON.stringify(model);
  const method = "POST";
  let response;

  try {
    const token = storage.getItem(TOKEN);
    if(token && "null" !== token && defaults.headers instanceof Headers){
      defaults.headers.set("x-authorization", `Bearer ${token}`);
    }
    response = await fetch(collection, {...defaults, method, body,});
  } catch (e) {
    await store.dispatch("splashScreen/showNetworkFailure");
    throw new NetworkFailureException();
  }

  const location = response.headers.get("Location");

  switch (response.status) {
    case 503:
      throw new ServiceUnavailableException();
    case 409:
      throw new ConflictException();
    case 400:
      throw new BadRequestException(await response.json());
    case 404:
      throw new NotFoundException();
    case 403:
      throw new ForbiddenException();
    case 401:
      throw new UnauthorizedException();
    case 200:
      eventBus.emit(EVENT.SAVE);
      return location as string;
    case 201:
      eventBus.emit(EVENT.SAVE);
      return location as string;
    default:
      throw new UnknownServerException(response);
  }
}
