<template>
  <div class="card-box">
    <b-form-group
      label="Nazwa"
    >
      <b-form-input
        id="name"
        v-model.trim="institution.institutionName"
        :state="state('name')"
      />
      <error-message
        :errors="errors"
        field="name"
      />
    </b-form-group>

    <address-form
      v-model="institution.address"
      :errors="getSubErrors('address')"
    />

    <error-message :errors="errors" />
    <div class="text-right">
      <button
        class="btn btn-secondary waves-effect"
        @click="redirect"
      >
        Anuluj
      </button>
      <button
        :disabled="loading"
        class="btn btn-primary waves-effect ml-2"
        @click="updateInstitution"
      >
        <i
          :class="loading ? 'fa-spin fa-spinner' : 'fa-check'"
          class="fa"
        />
        Zapisz
      </button>
    </div>
  </div>
</template>

<script>
import update from "../../rest/update";
import {errorsMixin} from "../../mixins/errorsMixin.js";
import processResponseException from "../../utils/errors/processResponseException";
import ErrorMessage from "../../components/Form/ErrorMessage";
import AddressForm from "../../components/Address/AddressForm";
import subErrors from "../../utils/errors/subErrors";
import read from "../../rest/read";

export default {
  components: {
    AddressForm,
    ErrorMessage,
  },
  mixins: [errorsMixin],
  props: {
    institutionId: {type: String, required: true},
  },
  data() {
    return {
      institution : {
        institutionId: this.institutionId,
        institutionName: null,
        address: {},
      },
      loading: false,
    };
  },
  mounted() {
    this.loadInstitutionData();
  },
  methods: {
    async updateInstitution() {
      try {
        this.loading = true;
        this.errors = [];
        await update(`/api/institutions/${this.institution.institutionId}`, {
          institutionId: this.institution.institutionId,
          name: this.institution.institutionName,
          address: this.institution.address,
        });
        this.redirect();
      } catch (exception) {
        this.errors = processResponseException(exception);
      }
      this.loading = false;
    },
    redirect() {
      this.$router.push({name: "institution_list"});
    },
    getSubErrors(field) {
      return subErrors(this.errors, field);
    },
    async loadInstitutionData() {
      this.loading = true;
      const data = await read(`/api/institution_update_view/${this.institution.institutionId}`);
      this.institution.institutionName = data.institutionName;
      this.institution.address = data.address;
      this.loading = false;
    },
  },
}
</script>
