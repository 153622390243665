var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('card',{attrs:{"loading":_vm.loading,"title":"Wnioski o wydanie dokumentacji medycznej"},scopedSlots:_vm._u([{key:"control",fn:function(){return [_c('b-btn',{directives:[{name:"b-modal",rawName:"v-b-modal.create-request-medical-record",modifiers:{"create-request-medical-record":true}}],staticClass:"m-1",attrs:{"size":"sm","variant":"primary"}},[_c('i',{staticClass:"fa fa-plus"}),_vm._v(" Dodaj wniosek ")])]},proxy:true}])},[_c('b-table',{attrs:{"fields":_vm.fields,"items":_vm.items,"responsive":true,"show-empty":"","empty-text":"Brak wniosków o wydanie dokumentacji medycznej","hover":""},scopedSlots:_vm._u([{key:"cell(createdAt)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.createdAt))+" ")]}},{key:"cell(requester)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.requester)+" ")]}},{key:"cell(branches)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.branchesNames(item.branches))+" ")]}},{key:"cell(includedContinuousStay)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.includedContinuousStay(item.type))+" ")]}},{key:"cell(continuousStay)",fn:function(ref){
var item = ref.item;
return [(item.continuousStay)?[_vm._v(" "+_vm._s(item.continuousStay.category)+" "),_c('br'),_vm._v(" "+_vm._s(_vm.continuousStayTime(item.continuousStay))+" ")]:_vm._e()]}},{key:"cell(options)",fn:function(ref){
var item = ref.item;
return [(item.status==='completed')?_c('b-btn',{attrs:{"href":item.password ? undefined : ("/api/files/" + (item.id)),"size":"sm","variant":"primary"},on:{"click":function($event){return _vm.openModal(item)}}},[_c('i',{staticClass:"fas fa-file-download"}),_vm._v(" Pobierz dokumentację ")]):_vm._e(),(item.status==='empty_documentation')?_c('span',{staticClass:"text-danger"},[_c('i',{staticClass:"fa fa-exclamation-triangle"}),_vm._v(" Brak dokumentacji medycznej ")]):_vm._e(),(item.status==='rejected')?_c('span',{staticClass:"text-danger"},[_c('i',{staticClass:"fa fa-exclamation-triangle"}),_vm._v(" Wygenerowanie dokumentacji nie powiodło się. ")]):_vm._e(),(item.status==='pending')?[_c('p',[_c('i',{staticClass:"fas fa-spin fa-spinner"}),_vm._v(" Dokumentacja w trakcie przygotowania ")]),_c('b-btn',{attrs:{"size":"sm","variant":"light"},on:{"click":_vm.loadRequestMedicalRecordsList}},[_c('i',{staticClass:"fas fa-sync-alt"}),_vm._v(" Odśwież status wniosku ")])]:_vm._e()]}}])}),_c('pagination-description',{attrs:{"items-count":_vm.items.length,"page":_vm.page,"per-page":_vm.pagination.perPage,"total-count":_vm.pagination.totalRows}},[_c('b-pagination',{staticClass:"mb-0",attrs:{"value":_vm.page,"total-rows":_vm.pagination.totalRows,"per-page":_vm.pagination.perPage,"align":"right"},on:{"input":_vm.changePage}})],1),_c('create-request-medical-record',{attrs:{"patient":_vm.patient,"request-type":_vm.requestType,"continuous-stay-id":_vm.continuousStayId},on:{"addRequestMedicalRecord":_vm.addRequestMedicalRecord}}),(_vm.record)?_c('request-medical-record-modal',{attrs:{"id":_vm.record.id,"password":_vm.record.password}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }