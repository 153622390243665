<template>
  <div class="row m-b-30">
    <div class="col-2 text-right">
      {{ field.entryType.name }}
    </div>
    <div class="col-10">
      <div
        v-if="!!entries.length"
        class="card m-b-10"
      >
        <div class="card-body">
          <div
            v-for="entry in entries"
            :key="entry.entryId"
            class="card-text m-b-15"
          >
            <entry
              :entry="entry"
              :read-only="readOnly"
              @entry-edited="entryEdited"
            />
          </div>
        </div>
      </div>
      <text-entry-add
        v-if="!readOnly"
        :diagnosis-id="diagnosisId"
        :type="field.entryType.key"
        :type-name="field.entryType.name"
        :max-entry-length="textEntryMaxLength"
        @new-entry-added="entryAdded"
      />
    </div>
  </div>
</template>

<script>
import TextEntryAdd from "./TextEntryAdd";
import Entry from "./Entry";

export default {
  name: "TextField",
  components: {
    Entry,
    TextEntryAdd,
  },
  props: {
    diagnosisId: {type: String, required: true},
    field: {type: Object, required: true},
    readOnly: {type: Boolean, required: true},
    textEntryMaxLength: {type: Number, required: true},
  },
  data() {
    return {
      entries: this.field.entries || [],
    };
  },
  methods: {
    entryAdded(entry) {
      this.entries = this.entries.concat([entry]);
      this.$emit("new-entry-added");
    },
    entryEdited(entry) {
      const index = this.entries.findIndex(
        (oldEntry) => oldEntry.entryId === entry.entryId
      );
      if (index > -1) {
        this.entries = [
          ...this.entries.slice(0, index),
          entry,
          ...this.entries.slice(index + 1)
        ];
        this.$emit("entry-edited");
      }
    },
  },
}
</script>
