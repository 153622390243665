export interface RequestItem {
  changeRequestId: string;
  command: RequestCommand;
  createdAt: string; //YYYY-MM-DD HH:mm:ss
  creator: string;
  creatorNote: string;
  acceptor: string | null;
  acceptorNote: string | null;
  status: RequestStatus;
  subjectId: string;
}

export interface SingleRequest {
  changeRequestId: string;
  params: RequestParams;
  status: RequestStatus;
  completedAt: string|null;
  subjectId: string;
}

export interface RequestParams {
  deferred: string; //???
  cancelReason: CancelReason;
  deferredNote: string;
  appointmentId: string;
  cancelComment: string;
}

export enum CancelReason {
  PATIENT_DID_NOT_SHOW_UP ="patient_did_not_show_up",
  PATIENT_CANCELED = "patient_canceled",
  APPEAL_DUE_TO_THE_FAULT_OF_A_SPECIALIST = "appeal_due_to_the_fault_of_a_specialist",
  RECEPTION_MISTAKE = "reception_mistake",
  DOUBLED_VISIT = "doubled_visit",
  OTHER = "other",
}

export enum RequestStatus {
  CREATED = "created",
  ACCEPTED = "accepted",
  CANCELED = "canceled",
}

export enum RequestCommand {
  CANCEL_APPOINTMENT_COMMAND = "CancelAppointmentCommand",
}

export interface RequestFilters {
  status: string[],
  creator: string[],
  requestType: string[]
}

export interface UrlRequestFilters {
  status: string|undefined,
  creator: string|undefined,
  requestType: string|undefined
}
