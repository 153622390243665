<template>
  <ul class="list-group">
    <li
      :class="{'active': highlighted === null, 'cursor-pointer': highlighted !== null}"
      class="list-group-item"
      @click="highlight(null)"
    >
      Pokaż wszystkich na mapie
    </li>
    <li
      v-for="item in items"
      :key="item.workerId"
      :class="{'active': highlighted === item.workerId, 'cursor-pointer': highlighted !== item.workerId}"
      class="list-group-item"
      @click="highlight(item.workerId)"
    >
      <div>{{ item.workerFullName }}</div>
      <div>{{ item.time }}</div>
    </li>
  </ul>
</template>

<script>

export default {
  props: {
    items: {type: Array, default: () => []}
  },
  data() {
    return {
      highlighted: null,
    }
  },
  methods: {
    highlight(value) {
      this.highlighted = value;
      this.$emit("highlight", value);
    },
  }
}
</script>
