<template>
  <b-modal
    id="finished-appointments-modal"
    :title="`Poprzednie wizyty pacjenta - ${patientDisplayName}`"
    no-close-on-backdrop
    title-tag="h3"
    size="xl"
    @show="onModalShow"
    @hide="onModalHide"
  >
    <loading-mask :loading="loading">
      <error-message :errors="errors" />
      <finished-appointment-preview
        v-for="item in items"
        :key="item.appointmentId"
        v-model="displayedDocumentsAppointmentId"
        :item="item"
        :current-appointment-id="currentAppointmentId"
        :patient-id="patientId"
      />
      <div
        v-if="!items.length"
        class="alert text-center"
      >
        Nie znaleziono wcześniejszych wizyt pacjenta
      </div>
    </loading-mask>
    <template #modal-footer>
      <pagination-description
        :items-count="items.length"
        :page="pagination.currentPage"
        :per-page="pagination.perPage"
        :total-count="pagination.totalRows"
      >
        <b-pagination
          v-model="pagination.currentPage"
          :total-rows="pagination.totalRows"
          :per-page="pagination.perPage"
          align="right"
          class="mb-0"
          @input="getAppointmentsList"
        />
      </pagination-description>
      <b-btn
        class="ml-auto"
        @click="closeModal"
      >
        Zamknij
      </b-btn>
    </template>
  </b-modal>
</template>

<script>
import read from "../../../rest/read";
import PaginationDescription from "../../Pagination/PaginationDescription";
import LoadingMask from "../../Loading/LoadingMask";
import processResponseException from "../../../utils/errors/processResponseException";
import ErrorMessage from "../../Form/ErrorMessage";
import FinishedAppointmentPreview from "../AppointmentBox/FinishedAppointmentPreview";

export default {
  name: "FinishedAppointmentsListModal",
  components: {
    FinishedAppointmentPreview,
    ErrorMessage,
    PaginationDescription,
    LoadingMask
  },
  props: {
    id: {type: String, default: null},
    patientId: {type: String, required: true},
    patientDisplayName: {type: String, required: true},
    currentAppointmentId: {type: String, default: null},
  },
  data() {
    return {
      errors: [],
      items: [],
      loading: false,
      pagination: {
        currentPage: 1,
        perPage: 5,
      },
      displayedDocumentsAppointmentId: null,
    };
  },
  computed: {
    filters() {
      return {
        statuses: ["finished"],
        patientId: this.patientId,
        perPage: this.pagination.perPage,
        page: this.pagination.currentPage,
        orderDesc: true,
      };
    },
  },
  methods: {
    closeModal() {
      this.$bvModal.hide("finished-appointments-modal");
    },
    async getAppointmentsList() {
      this.loading = true;
      try {
        const response = await read("/api/appointments", this.filters);
        this.pagination = response.pagination;
        this.items = response.items;
      } catch (e) {
        this.errors = processResponseException(e);
      }
      this.loading = false;
    },
    async onModalShow() {
      await this.getAppointmentsList();
    },
    onModalHide() {
      this.displayedDocumentsAppointmentId = null;
    },
  },
};
</script>

<style lang="scss" scoped>
  ::v-deep .modal-dialog.modal-xl {
    max-width: 95vw;
  }
</style>
