


















































import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";
import type {InsuranceHistory} from "../../../types/Insurance";

@Component({
  name: "PatientInsuranceHistoryModal",
})
export default class PatientInsurance extends Vue{
  @Prop({type: Object, default: ()=>[]}) readonly insurance !: InsuranceHistory | null;
  
  hideModal(): void {
    this.$bvModal.hide("insuranceHistoryModal");
  }
}
