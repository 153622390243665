import {isGranted} from "./security/isGranted";

const menuItems = [
  {
    icon: "fas fa-home",
    link: "/",
    name: "Strona główna",
    access() {return true},
    id: "home-page",
  },
  {
    icon: "fas fa-notes-medical",
    link: "/registration",
    name: "Rejestracja",
    async access() {return isGranted(["REGISTRATION_CALENDAR"])},
    id: "registration",
  },
  {
    icon: "fas fa-search",
    link: "/registration-search",
    name: "Wolne terminy",
    async access() {return isGranted(["REGISTRATION_SEARCH"])},
    id: "registration_search",
  },
  {
    icon: "fas fa-calendar-plus",
    link: "/appointments/list",
    name: "Plan wizyt",
    async access() {return isGranted("APPOINTMENT_LIST")},
    id: "appointment-list",
  },
  {
    icon: "fa fa-sticky-note",
    link: "/internal-referrals",
    name: "Skierowania wewnętrzne",
    async access() {return isGranted("INTERNAL_REFERRAL_LIST")},
    id: "internal-referrals",
  },
  {
    icon: "fas fa-headset",
    link: "/intervention-notifications",
    name: "Interwencje",
    async access() {return isGranted("INTERVENTION_NOTIFICATION_LIST")},
    id: "intervention-notifications",
  },
  {
    icon: "fas fa-hospital",
    link: "/room-occupancy",
    name: "Obłożenie pokoi",
    async access() {
      return isGranted("ROOM_OCCUPANCY");
    },
    id: "room-occupancy",
  },
  {
    icon: "fas fa-hospital",
    link: "/rooms",
    name: "Pokoje",
    async access() {
      const roomList = await isGranted("ROOM_LIST");
      const roleAdmin = await isGranted("ROLE_ADMIN");
      const roleManager = await isGranted("ROLE_MANAGER");
      const roleHRAdmin = await isGranted("ROLE_HR_ADMINISTRATOR");
      const roleReception = await isGranted("ROLE_RECEPTION");
      return roomList && (roleAdmin || roleManager || roleHRAdmin || roleReception);
    },
    id: "room-list",
  },
  {
    icon: "fas fa-hand-pointer",
    link: "/request-list",
    name: "Wnioski",
    async access() {
      return isGranted("CHANGE_REQUEST_LIST");
    },
    id: "request-list",
  },
  {
    icon: "fas fa-th-list",
    link: "#",
    name: "Ustawienia",
    async access() {return async () => {return true}},
    id: "options",
    "dropdownItems": [
      {
        icon: "fas fa-calendar",
        link: "/calendar",
        name: "Kalendarz",
        async access() {return isGranted([
          "SCHEDULE_APPOINTMENT", "SCHEDULE_APPOINTMENT_SPECIALIST", "SCHEDULE_APPOINTMENT_RECOVERY_ASSISTANT"
        ])},
        id: "calendar",
      },
      {
        icon: "fas fa-clock",
        link: "/weekly-schedule",
        name: "Plan tygodnia",
        async access() {return isGranted("SHOW_WEEKLY_SCHEDULE")},
        id: "weekly-schedule",
      },
      {
        icon: "fas fa-users",
        link: "/patients",
        name: "Lista pacjentów",
        async access() {
          const patientList = await isGranted("PATIENT_LIST");
          const patientCard = await isGranted("PATIENT_CARD");
          return patientList && patientCard;
        },
        id: "patient-list",
      },
      {
        icon: "fas fa-heartbeat",
        link: "/ipzs/list",
        name: "IPZ pacjentów",
        async access() {return isGranted("CREATE_IPZ")},
        id: "ipz-list",
      },
      {
        icon: "fas fa-heartbeat",
        link: "/support-network-patient-list",
        name: "Wspierani pacjenci",
        async access() {return isGranted("SUPPORT_NETWORK_PATIENT_LIST")},
        id: "support-network-patient-list",
      },
      {
        icon: "fas fa-user-tie",
        link: "/worker/list",
        name: "Pracownicy",
        async access() {return isGranted("WORKER_LIST")},
        id: "worker-list",
      },
      {
        icon: "fas fa-calendar-alt",
        link: "/workers-working-hours",
        name: "Harmonogramy",
        async access() {return isGranted("WORKING_HOURS_OF_WORKERS_LIST")},
        id: "working-hours",
      },
      {
        icon: "fas fa-users-cog",
        link: "/cooperating-units",
        name: "Jednostki współpracujące",
        async access() {return isGranted("FIND_COOPERATING_UNITS")},
        id: "cooperating-units",
      },
      {
        icon: "fas fa-envelope",
        link: "/system-notifications",
        name: "Zdarzenia systemowe",
        async access() {return isGranted("GET_SYSTEM_NOTIFICATION_LIST")},
        id: "system-notifications",
      },
      {
        icon: "fas fa-child",
        link: "/courses",
        name: "Kursy",
        async access() {return isGranted("COURSE_LIST")},
        id: "course-list",
      },
      {
        icon: "fas fa-child",
        link: "/institutions",
        name: "Instytucje",
        async access() {return isGranted("INSTITUTION_LIST")},
        id: "institution-list",
      },
      {
        icon: "fas fa-calendar-check",
        link: "/reports/main-view",
        name: "Raporty",
        async access() {
          const reportAppointmentMonthlyStats = await isGranted("REPORT_APPOINTMENT_MONTHLY_STATS");
          const getOwnSchedulerReport = await isGranted("GET_OWN_SCHEDULER_REPORT");
          const getScheduleSpecialist = await isGranted("GET_SCHEDULE_SPECIALIST");
          const createRequestIKUPChildrenRecords = await isGranted("CREATE_REQUEST_IKUP_CHILDREN_RECORDS");
          const createRequestIKUPAdultsRecords = await isGranted("CREATE_REQUEST_IKUP_ADULTS_RECORDS");
          const ikupChildren = await isGranted("IKUP_CHILDREN");
          const ikupAdults = await isGranted("IKUP_ADULTS");
          const reportSpecialistList = await isGranted("REPORT_SPECIALIST_LIST");
          const patientMedicalCareReport = await isGranted("PATIENT_MEDICAL_CARE_REPORT");
          const interventionsReport = await isGranted("INTERVENTIONS_REPORT");
          const getCancelReasonReport = await isGranted("GET_CANCEL_REASON_REPORT");
          const getChildIPZMonitoringReport = await isGranted("GET_CHILD_IPZ_MONITORING_REPORT");
          const getPatientListReport = await isGranted("GET_PATIENT_LIST_REPORT");
          return reportAppointmentMonthlyStats ||
            getOwnSchedulerReport ||
            createRequestIKUPAdultsRecords ||
            createRequestIKUPChildrenRecords ||
            ikupChildren ||
            ikupAdults ||
            reportSpecialistList ||
            getScheduleSpecialist ||
            patientMedicalCareReport ||
            interventionsReport ||
            getCancelReasonReport ||
            getChildIPZMonitoringReport ||
            getPatientListReport;
        },
        id: "reports",
      },
      {
        icon: "fas fa-user-cog",
        link: "/patients/data-unification",
        name: "Unifikacja danych",
        async access() {return isGranted("PATIENTS_UNIFICATION")},
        id: "data-unification",
      },
      {
        icon: "fas fa-list",
        link: "/treatment-types-list",
        name: "Lista usług",
        access() {return true},
        id: "treatment-types-list",
      },
      {
        icon: "fas fa-history",
        link: "/audit",
        name: "Audyt log",
        async access() {return isGranted("FIND_AUDIT_LOG")},
        id: "audit-list",
      },
      {
        icon: "fas fa-map-marker-alt",
        link: "/workers-localizations",
        name: "Lokalizacja",
        async access() {return isGranted("GET_WORKERS_LAST_GEOLOCALIZATION")},
        id: "geolocalization",
      },
      // {
      //   icon: "fas fa-user-lock",
      //   link: "/director/dashboard",
      //   name: "Panel dyrektora",
      //   async access() {return isGranted("SHOW_DIRECTOR_DASHBOARD")},
      //   id: "director-dashboard",
      // },
      {
        icon: "fas fa-city",
        link: "/establishments",
        name: "Lista podmiotów",
        async access() {return isGranted("CREATE_BRANCH")},
        id: "establishments",
      },
    ],
  },
];

export default menuItems;
