<template>
  <patient-search
    :loading="loading"
    :sticky-patient="patient"
    class="search-box-container"
    @change="onPatientSelect"
    @patientRemove="clear"
  />
</template>
<script>
import patientSearch from "./PatientSearch";
import {mapActions, mapState} from "vuex";
import {isEqual} from "lodash";

export default {
  name: "StickyPatientSelect",
  components: {
    "patient-search": patientSearch,
  },
  computed: {
    ...mapState("stickyPatient", ["patient", "loading"]),
  },
  mounted() {
    if (this.loading) {
      this.init();
    }
  },
  methods: {
    ...mapActions("stickyPatient", ["set", "clear", "init"]),
    onPatientSelect(patient) {
      this.set(patient);
      this.$nextTick(() => {
        const patientCardRoute = {
          name: "patientCard",
          params: {patientId: patient.patientId},
        };
        const currentLocation = {
          name: this.$route.name,
          params: this.$route.params,
        };
        if (!isEqual(currentLocation, patientCardRoute)) {
          this.$router.push(patientCardRoute);
        }
      })
    },
  },
};
</script>
<style scoped>
  .search-box-container {
    max-width: 700px;
  }
</style>
