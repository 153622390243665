


















































































































































import Vue from "vue";
import {Error} from "../../rest"
import {Component, Prop} from "vue-property-decorator";
import type {InternalReferralFormData} from "../../types/InternalReferral";
import PatientSelect from "../Patient/PatientSelect.vue";
import ErrorMessage from "../Form/ErrorMessage.vue";
import TextareaCounter from "../Form/Textarea/TextareaCounter.vue";
import EstablishmentSelect from "../Branch/EstablishmentSelect.vue";
import FileUpload from "../File/FileUpload.vue";
import {UploadedFile} from "../../types/HospitalizationTypes";
import IsGranted from "../IsGranted.vue";

@Component({
  name: "InternalReferralForm",
  components: {EstablishmentSelect, TextareaCounter, ErrorMessage, PatientSelect, FileUpload}
})
export default class InternalReferralForm extends Vue {
  @Prop({type: Array, default: ()=>[]}) readonly propErrors!: Error[];
  @Prop({type: Array, default: ()=> []}) readonly files!: File[];
  @Prop({type: Object, required: true}) readonly value!: InternalReferralFormData;
  @Prop({type: Boolean, default: null}) readonly disabled!: boolean;
  @Prop({type: String, default: null}) readonly patientDisplayName!: string;
  @Prop({type: Boolean, default: false}) readonly displayUploadButton!: boolean;
  @Prop({type: Boolean, default: false}) readonly uploadInProgress!: boolean;

  private state(field: Error["field"]) {
    return this.propErrors.find(error => error.field === field) ? false : null;
  }

  private update(value: InternalReferralFormData): void {
    this.$emit("input", value);
  }

  private addFile(file: UploadedFile) {
    const files = this.value?.files || [];
    this.update({...this.value, files: [...files, file.fileId]})
  }

  private removeFile(file: UploadedFile) {
    const files = this.value.files.filter((fileId: string) => fileId !== file.fileId);
    this.update({...this.value, files: files})
  }

  private sendFiles(){
    this.$emit("sendFiles");
  }
}
