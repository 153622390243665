import {actions} from "./interventionNotifications/actions";
import {getters} from "./interventionNotifications/getters";
import {mutations} from "./interventionNotifications/mutations";
import {state} from "./interventionNotifications/state";
import {Module} from "vuex";
import {InterventionNotificationsState} from "./interventionNotifications/types";
import {RootState} from "../types";

const interventionNotifications: Module<InterventionNotificationsState, RootState> = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
}

export default interventionNotifications;
