<template>
  <div class="left side-menu cover-content">
    <vue-perfect-scrollbar
      :settings="scrollBarSettings"
      class="sidebar-inner"
    >
      <div id="sidebar-menu">
        <env-name
          :small="!menuOpened"
          class="text-muted"
        />
        <ul>
          <template v-for="(menuItem, index) in accessibleItems">
            <template v-if="menuItem.dropdownItems !== undefined">
              <sidebar-dropdown
                v-if="menuOpened"
                :key="index"
                :index="index"
                :menu-item="menuItem"
                :visible-dropdown-index="visibleDropdownIndex"
                @showDropdown="showDropdown"
              />
              <sidebar-item
                v-for="(dropdownItem, itemIndex) in menuItem.dropdownItems"
                v-else
                :key="`${index}-${itemIndex}`"
                :menu-item="{
                  link: dropdownItem.link,
                  name: dropdownItem.name,
                  icon: dropdownItem.icon || menuItem.icon,
                  id: dropdownItem.id,
                }"
              />
            </template>
            <sidebar-item
              v-else
              :key="index"
              :menu-item="menuItem"
            />
          </template>
          <li>
            <logout-button class="waves-effect waves-primary" />
          </li>
        </ul>
        <div class="clearfix" />
      </div>
    </vue-perfect-scrollbar>
    <div
      class="bottom-fixed m-l-10 d-flex align-items-center"
    >
      <ue-logotype class="mr-2" />
      <tooltip
        v-show="menuOpened"
        :style="{display: 'inline-block'}"
        :tooltip-content="`Wersja aplikacji: ${version}`"
      >
        <router-link
          :to="{name: 'get_changelog'}"
          class="small text-white-50"
          style="white-space: normal"
        >
          &copy;{{ new Date().getFullYear() }}&nbsp;Auxilio&nbsp;
          {{ version }}
        </router-link>
      </tooltip>
    </div>
    <div class="clearfix" />
  </div>
</template>

<script>
import {mapState} from "vuex";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import SidebarItem from "./SidebarItem";
import SidebarDropdown from "./SidebarDropdown";
import LogoutButton from "../Logout/LogoutButton";
import Tooltip from "../Tooltip";
import UeLogotype from "../UEinformation/UeLogotype";
import menuItems from "../../menuItems";
import EnvName from "../EnvName";
import menuItemsForReception from "../../menuItemsForReception";
import {isGranted} from "../../security/isGranted";
import {dimPartners} from "@/utils/partners/partners";

export default {
  name: "SideBar",
  components: {
    EnvName,
    UeLogotype,
    Tooltip,
    LogoutButton,
    VuePerfectScrollbar,
    SidebarItem,
    SidebarDropdown,
  },
  data() {
    return {
      body: null,
      sidebar: null,
      windowHeight: null,
      visibleDropdownIndex: null,
      scrollBarSettings: {
        maxScrollbarLength: 60,
        suppressScrollX: true,
      },
      items: [],
      itemsWithDropdownAccess: [],
      isRoleReception: null,
    };
  },
  computed: {
    ...mapState({
      partner: state => state.clinicParameters.parameters.partner,
      menuOpened: state => state.sidebar.menuOpened,
    }),
    version() {
      return process.env.APP_VERSION;
    },
    accessibleItems() {
      return this.itemsWithDropdownAccess.reduce((list, item) => {
        if (item.dropdownItems) {
          item.dropdownItems = item.dropdownItems.filter(({access}) => access !== false);
        }
        if (item.access !== false) {
          list = [...list, item];
        }
        return list;
      }, []);
    },
  },
  watch: {
    accessibleItems(val) {
      val.forEach((menuItem, index) => {
        if (menuItem.dropdownItems && menuItem.dropdownItems.findIndex(item => item.link === this.$route.path) > -1) {
          this.visibleDropdownIndex = index;
        }
      });
    },
    partner: {
      immediate: true,
      async handler(val) {
        if (val) {
          let items = menuItems;
          if (dimPartners.includes(this.partner)) {
            this.isRoleReception = await isGranted(["ROLE_RECEPTION"]);
            items = this.isRoleReception
              ? menuItemsForReception
              : menuItems;
          }

          this.items = this.handleMenuItemsAccess(items);
        }
      },
    },
  },
  created () {
    this.body = document.body;
    this.body.classList.add("fixed-left");
  },
  methods: {
    reload() {
      this.itemsWithDropdownAccess = this.items.map((item) => {
        if(item.dropdownItems) {
          return {...item, dropdownItems: [...item.dropdownItems]};
        }
        return {...item};
      });
    },
    showDropdown(index) {
      this.visibleDropdownIndex = (this.visibleDropdownIndex === index) ? null : index;
    },
    handleMenuItemsAccess(items) {
      return items.reduce((list,item) => {
        let dropdownItems;
        if (item.dropdownItems) {
          dropdownItems = this.handleMenuItemsAccess(item.dropdownItems);
        }
        const element = {
          ...item,
          access: false,
        };
        const access = item.access();
        if (access === true || access === false) {
          element.access = access;
          element.dropdownItems = dropdownItems;
        } else {
          access.then((result) => {
            element.access = result;
            element.dropdownItems = dropdownItems;
            this.reload();
          });
        }

        return [...list, element];
      }, []);
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../styles/variables";

$bottom-fixed-height: 2.5rem;
$top-bar-height: 4.375rem;

.side-menu {
  transition: width .3s;
  white-space: nowrap;

  &.left {
    top: $top-bar-height;
  }
}

.cover-content {
  z-index: $zindex-fixed !important;
}

#sidebar-menu {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.sidebar-inner {
  min-height: calc(100vh - #{$top-bar-height} - #{$bottom-fixed-height});
  height: calc(100% - #{$bottom-fixed-height});
}

.bottom-fixed {
  position: absolute;
  bottom: $top-bar-height;
  left: 0;
  right: 0;
  height: $bottom-fixed-height;
}
</style>

<style lang="scss">
$top-bar-height: 4.375rem;

.content-page, .navbar-custom {
  transition: margin-left .3s;
}

.content-page > .content {
  margin-top: $top-bar-height;
}

.topbar-left {
  height: $top-bar-height;
  transition: width .3s;
  white-space: nowrap;
  overflow: hidden;
  user-select: none;
  .logo>span {
    display: inline !important;
  }
}
.side-menu a {
  min-height: 2.8125rem;
  transition: min-height .3s;

  i {
    transition: font-size .3s;
  }
}
</style>
