



















import DateTime from "../DateTime.vue";
import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";
import type {WeeklyScheduleItem} from "../../types/WeeklySchedule";

@Component({
  name: "ScheduleBreakButton",
  components: {DateTime},
})

export default class ScheduleBreakButton extends Vue {
  @Prop({type: Boolean, default: false}) readonly isToday!: boolean;
  @Prop({type: Object, required: true}) readonly item!: WeeklyScheduleItem;
}
