var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"text-right m-b-10"},[(_vm.atLeastOneEntry)?_c('a',{staticClass:"btn btn-sm btn-primary",attrs:{"href":("/api/ipzs/crisis-plans/" + _vm.crisisPlanId + "/print"),"target":"_blank"}},[_c('i',{staticClass:"fa fa-print"}),_vm._v(" Drukuj Plan Kryzysowy ")]):_vm._e()]),_c('div',{staticClass:"card p-b-10"},[_c('div',{staticClass:"card-header",attrs:{"id":"headingOne"}},[_c('diagnosis-title',{attrs:{"entries":_vm.entries,"title":"Plan kryzysowy"}})],1),_c('div',{staticClass:"card-body"},[_vm._l(({
            CP_01: 'Kogo dotyczy kryzys?',// name: item
            CP_02: 'Jaki jest problem, co się wydarzyło, jakie występują trudności? \
(co, gdzie, kiedy miało miejsce)',
            CP_03: 'Kto wie o problemie',
            CP_04: 'Kto nie wie o problemie z ważnych dla Pana/Pani osób?',
            CP_05: 'Kto powinien być Pana/Pani zdaniem zaangażowany w pomoc, współpracę?',
            CP_06: 'Czego oczekuje lub potrzebuje Pan/Pani od osób pomagających lub \
wspierających? (osoba - działanie/forma pomocy)',
            CP_07: 'Czego nie chciał(a)by Pan/Pani, aby ktoś dla Ciebie robił ze strony pomagających?',
            CP_08: 'Jakie są oznaki w Pana/Pani zachowaniu, przeżywaniu, myśleniu, \
które wskazują na potrzebę pomocy? Jak to się zmieniało w czasie? (proces)',
            CP_09: 'Czy w ostatnim czasie zaobserwował(a) Pan/Pani następujące objawy, zmiany?',
          }),function(item,name){return _c('diagnosis-field',{key:name,attrs:{"read-only":_vm.overview.readonly,"injected-entries":_vm.getField(name).entries,"type":name,"type-trans":item.toUpperCase(),"create-entry-url":_vm.createEntryUrl},on:{"new-entry-added":_vm.entryAdded}})}),_vm._m(0),_vm._l(({
            CP_10: 'Czy to Pana/Pani pierwszy kryzys?',
            CP_11: 'Czy miały miejsce w przeszłości podobne trudności? Kiedy, jakie?',
            CP_12: 'Czy w przeszłości występowały u Pana/Pani myśli rezygnacyjne, \
próby samobójcze, zachowania agresywne wobec otoczenia, autoagresja? Opisz je:',
            CP_13: 'Co powinny zrobić osoby wspierające Pana/Panią, gdyby wystąpiło \
zagrożenie życia i/lub zdrowia własnego lub otoczenia?',
            CP_14: 'Jak udało się Panu/Pani poradzić z tymi kryzysami lub trudnymi \
sytuacjami? Co było najbardziej pomocne?',
            CP_15: 'Czy korzysta lub korzystał(a) Pan/Pani z pomocy specjalistów? Kto \
jest Pana/Pani lekarzem, psychologiem, psychoterapeutą lub inną osobą z \
kręgu specjalistów? (czas, miejsce, osoba)',
            CP_16: 'Czy i jakie zażywa Pan/Pani leki?',
            CP_17: 'Jakie formy pomocy specjalistycznej najbardziej pomagały (leki, oddziaływania)?',
            CP_18: 'Jakie są preferowane przez Pana/Panią placówki/formy pomocy?',
            CP_19: 'Jakie są nieakceptowalne przez Pana/Panią placówki, formy pomocy?',
            CP_20: 'Biorąc pod uwagę wszystkie omówione kwestie i Pana/Pani aktualne \
możliwości i zasoby społeczne, jak uważa Pan/Pani, jakie pierwsze kroki i \
działania można teraz podjąć? (powiadomienie, terapia, spotkanie, \
szpital, kontakt z placówkami, inne)',
            CP_21: 'Jakie będą pierwsze oznaki zmian, które wskazują, \
iż radzi Pan/Pani sobie już z sytuacją i swoimi problemami?',
            CP_22: 'Jeśli wystąpią jakie objawy powinien Pan/Pani szukać pomocy w przyszłości?',
          }),function(item,name){return _c('DiagnosisField',{key:name,attrs:{"read-only":_vm.overview.readonly,"injected-entries":_vm.getField(name).entries,"type":name,"type-trans":item.toUpperCase(),"create-entry-url":_vm.createEntryUrl},on:{"new-entry-added":_vm.entryAdded}})})],2)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"m-b-30"},[_c('span',{staticClass:"font-italic"},[_vm._v("Obniżony nastrój, smutek, przygnębienie, niepokój, strach, napięcie, złość, wściekłość, agresję, apatię, wycofanie, lęk, poczucie krzywdy, rozpacz, poczucie winy, zaburzenia snu, zaburzenia odżywiania, spadek lub przybieranie na wadze, zaburzenia seksualne, objawy zmęczenia i wyczerpania, brak motywacji, trudności decyzyjne, płaczliwość, sięganie po alkohol i inne używki, używanie substancji psychoaktywnych, kłótliwość, konfliktowość, spadek efektywności w pracy, inne")])])}]

export { render, staticRenderFns }