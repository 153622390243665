<template>
  <loading-mask :loading="loading">
    <error-message :errors="errors" />
    <template v-if="overview">
      <template v-if="!diagnosisId">
        <b-btn
          :disabled="overview.readonly || loading"
          class="btn btn-primary btn-sm"
          @click="newPediatricDiagnosis"
        >
          <i
            :class="loading ? 'fa-spin fa-spinner' : 'fa-plus'"
            class="fa"
          />
          Nowe badanie somatyczne
        </b-btn>
      </template>
      <template v-else>
        <diagnosis-structure
          :read-only="!!overview.readonly"
          :diagnosis-id="diagnosisId"
          :items="fields"
          :structure-version="structureVersion"
          diagnosis-type="pediatric_child"
          @load="loadData"
        />
      </template>
    </template>
  </loading-mask>
</template>

<script>
import ErrorMessage from "../../../components/Form/ErrorMessage";
import read from "../../../rest/read";
import create from "../../../rest/create";
import {errorsMixin} from "../../../mixins/errorsMixin.js";
import processResponseException from "../../../utils/errors/processResponseException";
import {generateUuid} from "../../../utils/uuid/generateUuid";
import DiagnosisStructure from "../../../components/Ipz/DiagnosisStructure/DiagnosisStructure";
import LoadingMask from "../../../components/Loading/LoadingMask";

export default {
  name: "PediatricDiagnosis",
  components: {LoadingMask, ErrorMessage, DiagnosisStructure},
  mixins: [errorsMixin],
  props: {
    ipzId: {type: String, required: true},
  },
  data() {
    return {
      loading: false,
      overview: null,
      fields: null,
      diagnosisId: null,
      structureVersion: null,
    };
  },
  watch: {
    async ipzId() {
      await this.loadData();
    },
  },
  async mounted () {
    await this.loadData();
  },
  methods: {
    async loadData() {
      this.loading = true;
      const {overview, fields, diagnosisId, structureVersion} = await read(`/api/ipzs/${this.ipzId}/details/pediatric`);
      this.overview = overview;
      this.fields = fields;
      this.diagnosisId = diagnosisId;
      this.structureVersion = structureVersion;
      this.loading = false;
    },
    async newPediatricDiagnosis() {
      this.loading = true;
      this.errors = [];
      try {
        const diagnosisId = generateUuid();
        await create(`/api/ipzs/${this.ipzId}/pediatric`, {
          diagnosisId,
        });
        await this.loadData();
      } catch (e) {
        this.errors = processResponseException(e);
      }
      this.loading = false;
    },
  },
}
</script>
