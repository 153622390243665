



































































import ErrorsMixin from "../../mixins/ErrorsMixin";
import {Component, Mixins, Prop, Watch} from "vue-property-decorator";
import Card from "../../components/Card.vue";
import type {InternalReferralItem, InternalReferralPageQuery} from "../../types/InternalReferral";
import CreateInternalReferralModal from "../../components/InternalReferrals/CreateInternalReferralModal.vue";
import {Pagination} from "../../types/Pagination";
import PaginationDescription from "../../components/Pagination/PaginationDescription.vue";
import {updatePageQuery} from "../../utils/pageUrl/handlePageQuery";
import ErrorMessage from "../../components/Form/ErrorMessage.vue";
import processResponseException from "../../utils/errors/processResponseException";
import IsGranted from "../../components/IsGranted.vue";
import read from "../../rest/read";
import InternalReferralsList from "../../components/InternalReferrals/InternalReferralsList.vue";
import InternalReferralsFilters from "../../components/InternalReferrals/InternalReferralsFilters.vue";
import {InternalReferralDirection, InternalReferralStatus, Patient, Worker} from "../../types/InternalReferral";

interface Data {
  items: InternalReferralItem[],
  pagination: Pagination,
}

@Component({
  name: "InternalReferralsPage",
  components: {
    InternalReferralsFilters,
    InternalReferralsList,
    IsGranted,
    ErrorMessage,
    PaginationDescription,
    CreateInternalReferralModal,
    Card,
  }
})
export default class InternalReferralsPage extends Mixins(ErrorsMixin) {
  @Prop({type: Number, default: 1}) readonly page!: number;
  @Prop({type: String, default: null}) readonly direction!: InternalReferralDirection;
  @Prop({type: String, default: null}) readonly status!: InternalReferralStatus;
  @Prop({type: String, default: null}) readonly patientId!: Patient["patientId"];
  @Prop({type: String, default: null}) readonly workerId!: Worker["workerId"];

  private loading: boolean = false;
  public items: InternalReferralItem[] = [];
  public pagination: Pagination = {
    perPage: 10,
    currentPage: this.page || 1,
    totalRows: 0,
  };

  @Watch("$route", {immediate: true}) async onRouteChange(): Promise<void> {
    await this.fetchData();
  }

  private async fetchData(): Promise<void> {
    this.loading = true;
    this.errors = [];
    this.items = [];
    const query: InternalReferralPageQuery = {
      direction: this.direction,
      page: this.pagination.currentPage,
      perPage: this.pagination.perPage,
      patientId: this.patientId,
      status: this.status,
      workerId: this.workerId,
    }
    try {
      const {items, pagination} = await read<Data>("/api/internal-referrals", query);
      this.items = items;
      this.pagination = pagination;
    } catch (e) {
      this.errors = processResponseException(e);
    }
    this.loading = false;
  }

  private async changePage(page: number): Promise<void> {
    this.pagination.currentPage = page;
    await updatePageQuery({page});
  }
}
