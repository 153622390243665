<template>
  <report-container
    report-id="patient_medical_care_report"
  >
    <div class="row card-box">
      <b-form-group
        class="col-lg-6 col-xl-3"
        label="Data od"
        label-for="start-date"
      >
        <date-picker
          id="start-date"
          v-model="startDate"
          :disabled-date="disabledStartDateDays"
        />
      </b-form-group>

      <b-form-group
        class="col-lg-6 col-xl-3"
        label="Data do"
        label-for="end-date"
      >
        <date-picker
          id="end-date"
          v-model="endDate"
          :disabled-date="disabledEndDateDays"
        />
      </b-form-group>
      <b-form-group
        class="col-lg-6 col-xl-3"
        label="Komórki organizacyjne"
        label-for="branch-ids"
        description="Pole jest wymagane"
      >
        <branch-select
          v-model="branchIds"
          :multiple="true"
        />
      </b-form-group>

      <div class="col text-right no-label">
        <back-button :to="{name: 'reportsMainView'}" />

        <b-btn
          class="m-1"
          :disabled="generationDisabled"
          download
          :href="reportUrl"
          variant="primary"
        >
          <i class="fas fa-file-excel" />
          Generuj
        </b-btn>
      </div>
    </div>
  </report-container>
</template>

<script>
import DatePicker from "../../components/Form/DatePicker/DatePicker";
import stringifyDate from "../../utils/date/stringifyDate";
import DATE_FORMAT from "../../utils/date/DATE_FORMAT";
import {createUrl} from "../../utils/pageUrl/createUrl";
import BackButton from "../../components/BackButton";
import ReportContainer from "../../components/Report/ReportContainer";
import BranchSelect from "@/components/Branch/BranchSelect";

export default {
  name: "AppointmentInBranchesReportForm",
  components: {BranchSelect, ReportContainer, BackButton, DatePicker},
  data() {
    const startDate = new Date();
    startDate.setDate(1);
    startDate.setHours(0,0,0,0);
    const endDate = new Date(startDate.getTime());
    endDate.setMonth(endDate.getMonth() + 1);
    endDate.setDate(endDate.getDate() - 1);
    return {
      startDate,
      endDate,
      branchIds: [],
      scope: null
    };
  },
  computed: {
    startDateString() {
      return this.startDate ? stringifyDate(this.startDate, DATE_FORMAT.DATE) : null;
    },
    endDateString() {
      return this.endDate ? stringifyDate(this.endDate, DATE_FORMAT.DATE) : null;
    },
    generationDisabled() {
      return !this.startDateString || !this.endDateString || !this.branchIds.length
    },
    reportUrl() {
      if (this.generationDisabled) {
        return null;
      }
      return createUrl("/api/report/appointments-in-branches", {
        startDate: this.startDateString,
        endDate: this.endDateString,
        branchIds: this.branchIds,
      });
    },
  },
  methods: {
    disabledEndDateDays(endDate) {
      return (this.startDate.getTime() - endDate.getTime() > 1000);
    },
    disabledStartDateDays(start) {
      return (start.getTime() - this.endDate.getTime() > 1000);
    },
  },
}
</script>
