


































import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";
import type {ZlaDurationFormData} from "../../types/Zla";
import DatePicker from "../Form/DatePicker/DatePicker.vue";
import {Error} from "../../rest";
import ErrorMessage from "../Form/ErrorMessage.vue";

@Component({
  name: "ZlaDurationForm",
  components: {
    ErrorMessage,
    DatePicker,
  }
})
export default class ZlaDurationForm extends Vue {
  @Prop({type: Object, required: true}) readonly value!: ZlaDurationFormData;
  @Prop({type: Array, default: ()=>[]}) readonly errorsProp!: Error[];

  private updateFrom(date: Date): void {
    this.$emit("input", {
      ...this.value,
      from: date,
    });
  }

  private updateTo(date: Date): void {
    this.$emit("input", {
      ...this.value,
      to: date,
    });
  }

  disabledDate(date: Date) {
    const now = new Date();
    return date > now && date < this.value.from;
  }

  private state(field: string): boolean|null {
    return this.errorsProp.find((error) => error.field === field) ? false : null;
  }
}
